import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
  Grid,
  TextField,
} from "@material-ui/core";

import { getSearchClient } from "utils/algolia";
//InstantSearch
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  RefinementList,
  Highlight,
  Stats,
} from "react-instantsearch-dom";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();

  const {
    loading,
    onPressedItem,
    tickets,
    toggleAddTicketForm,
    user,
    refreshAlgolia,
  } = props;

  const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const Pagination = ({
    currentRefinement,
    nbPages,
    refine,
    createURL,
    padding = 3,
  }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {range(
            Math.max(1, currentRefinement - padding),
            Math.min(nbPages, currentRefinement + padding)
          ).map((page) => {
            // const page = index + 1;
            return (
              <li
                key={page}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a student"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, currentRefinement, refine, createURL }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let created = new Date(row.createdAt);
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.title}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.student.studentName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.type}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {moment(created).format("DD MMM YYYY")}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  const RefinementListCustom = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementListCustom);

  //End Filtering

  useEffect(() => {
    searchClient.clearCache();
  }, []);

  return (
    <Section
      title="MANAGE TICKETS"
      loading={!tickets}
      actionName="Add Ticket"
      handleAction={toggleAddTicketForm}
    >
      {tickets.length != 0 && (
        <InstantSearch
          indexName="Tickets"
          searchClient={searchClient}
          refresh={refreshAlgolia}
        >
          <RefinementList
            attribute="status"
            operator="or"
            defaultRefinement={"Pending"}
          />
          <RefinementList
            attribute="type"
            operator="or"
          />
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <CustomSearchBox />
            </Grid>
            <Grid item xs={2}>
              <Stats
                translations={{
                  stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                    return (
                      <Typography className={classes.stat}>
                        {nbHits} tickets in {processingTimeMS} ms
                      </Typography>
                    );
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Ticket Title
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Student Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Created At
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <CustomHits />
            </Table>
          </TableContainer>
          <CustomPagination />
        </InstantSearch>
      )}
      {tickets.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          You don't have any pending tickets yet.
        </Typography>
      )}
    </Section>
  );
}
