import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleCreateExtensionForm,
    extensions,
    onPressedDeleteItem,
    sectionDescription,
    getStudent,
    setStudent,
    student,
    editStudent
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);


  
  const handleResolveExtension = async(row, index) => {

    var ext = extensions.find(obj => {
      return obj.cohortId === row.cohortId
    })

    let resolvedExtensions = student.resolvedExtensions ? student.resolvedExtensions:[]
    resolvedExtensions.push(extensions[index])

    let newExtensions = student.extensions ? student.extensions:[]
    newExtensions.splice(index,1)

    let payload = {
      extensions:newExtensions,
      resolvedExtensions:resolvedExtensions
    }
    await editStudent(student._id, payload).then(()=>{
      getStudent(student._id).then((result)=>{
        setStudent(result)
      })
    })
  }


  return (
    <Section
      title="Extensions"
      loading={loading}
      actionName="Add extension"
      sectionDescription={sectionDescription}
      handleAction={toggleCreateExtensionForm}
    >
      {extensions.length > 0 && (
        <TableContainer  component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Cohort</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Date Added
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Original Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Revised Due Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Duration
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extensions.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.labelText} align="left">
                      {row.cohortTitle}
                    </TableCell>
                    <TableCell className={classes.labelText} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.createdAt}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.labelText} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.from}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.labelText} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.to}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.labelText} align="left">
                      <Typography className={classes.text} align="left">
                        {row.duration}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.labelText} align="left">
                      <Typography className={classes.text} align="left">
                        {row.title}
                      </Typography>
                    </TableCell>
                    
                    <TableCell className={classes.cellType} align="right">
                    <Button style={{backgroundColor:"#f7f7f7"}} onClick={()=>{handleResolveExtension(row, index)}}>Resolve</Button>
                     
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>{" "}
          </Table>
        </TableContainer>
      )}
      {extensions.length === 0 && (
        <Typography className={classes.emptyText} align="left">
          There are no associated extensions with this student.
        </Typography>
      )}
    </Section>
  );
}
