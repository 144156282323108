import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { user, leaves, loading, sectionDescription } = props;

  return (
    <Panel
      title="Up coming leaves (next 2 months)"
      loading={loading}
      sectionDescription={sectionDescription}
    >
      <List>
        {leaves.map((item, index) => {
          let days = moment(item.timestamp).diff(moment(), 'days')
          let diff = days == 0? "today" : `in ${days} days`
          return (
            <ListItem key={`leave-${index}`}>
              <ListItemAvatar>
                <Avatar
                  alt={item.user.firstName}
                  src={item.user.displayPhoto.url}
                />
              </ListItemAvatar>
              <ListItemText
                id={`leave-text-${index}`}
                primary={
                  <React.Fragment>
                    <Typography style={{ color: "#564181", fontWeight: 700, fontSize:16 }}>
                      {`${item.duration} hrs @ ${item.startTime} ${moment(item.timestamp).format("ddd DD MMM YYYY")}`}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                     {`${item.user.firstName} `}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Panel>
  );
}
