import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllLeadsContext } from "repository/stores/dashboard/leads/leads-all-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";

//InstantSearch
import { getSearchClient } from "utils/algolia";

// Children
import LeadsView from "./leads-all-leads-view";

// Actions
import {
  // findAllLeads,
  getSingleLead,
} from "repository/actions/data/dashboard/leads";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

//Dialogues
import DialogueCreateLead from "routes/dashboard/dialogues/leads/create-lead";
import DialogueEditLead from "routes/dashboard/dialogues/leads/edit-lead";
import DialogueDeleteLead from "routes/dashboard/dialogues/leads/delete-lead";
import DialogueEditNotes from "routes/dashboard/dialogues/leads/edit-notes";
import DialogueEditFollowUps from "routes/dashboard/dialogues/leads/edit-followups";
import DialogueEditDesignatedUser from "routes/dashboard/dialogues/leads/edit-designated-user";

export default withRouter(function (props) {
  const {leads, setLeads, getLeads, createNewLead, editLead, deleteLead} = useContext(AllLeadsContext);
  const {courses, setCourses, getCourses} = useContext(AllCoursesContext);
  const { user } = useContext(AuthenticationContext);
  const {users, setUsers, findAllUsers} = useContext(AllUsersContext);

  const [userMap, setUserMap] = useState({});

  const [showNewLeadDialogue, setShowNewLeadDialogue] = useState(false);
  const [showEditLeadDialogue, setShowEditLeadDialogue] = useState(false);
  const [showDeleteLeadDialogue, setShowDeleteLeadDialogue] = useState(false);
  const [showEditNotesDialogue, setShowEditNotesDialogue] = useState(false);
  const [showEditFollowUpsDialogue, setShowEditFollowUpsDialogue] = useState(false);
  const [showEditDesignatedUserDialogue, setShowEditDesignatedUserDialogue] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const searchClient = getSearchClient();

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (leads == null) {
      getLeads(user.idToken);  
    } else {
      searchClient.clearCache();
    }
  }, [leads]);


  useEffect(() => {
    if (courses == null) {
      getCourses( user.idToken);
    }
  }, [courses]);

  // Editing Item
  useEffect(() => {
    if (leads) {
      if (selectedId && editingItem == null) {
        getSingleLead(selectedId, user.idToken).then((result) => {
          setEditingItem(result);
        });
      }
    }
  }, [selectedId]);

  useEffect(() => {
    if(users && users != null){
      let newUserMap = {};
      for(let u of users){
        newUserMap[u._id]=u;
      }
      setUserMap(newUserMap);
    }
  },[users])

  return (
    <div>
      <LeadsView
        loading={!leads}
        onPressedItem={(id) => props.history.push(`all/${id}`)}
        toggleAddLeadForm={() => setShowNewLeadDialogue(true)}
        sectionDescription={"You can manage all of your leads below."}
        leads={leads || []}
        courses={courses || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditLeadDialogue(true);
        }}
        onPressedDeleteItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowDeleteLeadDialogue(true);
        }}
        onPressedEditNotesItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditNotesDialogue(true);
        }}
        onPressedEditFollowUpsItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditFollowUpsDialogue(true);
        }}
        onPressedEditDesignatedUserItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditDesignatedUserDialogue(true);
        }}
        user={user}
        searchClient={searchClient}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
        userMap={userMap}
        refreshAlgolia={refreshAlgolia}
      />
      <DialogueCreateLead
        isOpen={showNewLeadDialogue}
        setClose={() => {
          setShowNewLeadDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        user={user}
        leads={leads || []}
        setLeads={setLeads}
        createNewLead={createNewLead}
        courses={courses}
        getCourses={getCourses}
        setRefreshAlgolia={setRefreshAlgolia}
      />
      <DialogueEditLead
        isOpen={showEditLeadDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditLeadDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        user={user}
        leads={leads || []}
        setLeads={setLeads}
        data={editingItem}
        itemId={selectedId}
        editLead={editLead}
        courses={courses}
        setCourses={setCourses}
        getCourses={getCourses}
      />
      <DialogueDeleteLead
        isOpen={showDeleteLeadDialogue}
        setClose={() => {
          setShowDeleteLeadDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        itemId={selectedId}
        setLeads={setLeads}
        user={user}
        deleteLead={deleteLead}
        setRefreshAlgolia={setRefreshAlgolia}
      />
      <DialogueEditNotes
        isOpen={showEditNotesDialogue}
        setClose={() => {
          setShowEditNotesDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        leadId={selectedId}
        lead={editingItem}
        setLeads={setLeads}
        user={user}
        editLead={editLead}
      />
      <DialogueEditFollowUps
        isOpen={showEditFollowUpsDialogue}
        setClose={() => {
          setShowEditFollowUpsDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        leadId={selectedId}
        lead={editingItem}
        setLeads={setLeads}
        user={user}
        editLead={editLead}
      />
      <DialogueEditDesignatedUser
        isOpen={showEditDesignatedUserDialogue}
        setClose={() => {
          setShowEditDesignatedUserDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        leadId={selectedId}
        lead={editingItem}
        setLeads={setLeads}
        user={user}
        editLead={editLead}
        users={users}
        findAllUsers={findAllUsers}
      />

    </div>
  );
});
