import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { TextField, FormControl } from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "components/popup";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  const { setClose, isOpen, broadcasts, setBroadcasts, cohort, user, createNewBroadcast } = props;

  const [title, setTitle] = React.useState(null);
  const [body, setBody] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [bodyError, setBodyError] = React.useState(null);

  const handleSaveOwnerDetails = () => {
    if (user && user != null) {
      if (title == null || body == null) {
        toast.error(
          `You have missing information for creating a new broadcast`
        );
        if (title === null) {
          setTitleError("Title is required.");
        }
        if (body == null) {
          setBodyError("Body is required.");
        }
      } else {
        let data = {
          cohortId: cohort._id,
          subject: title,
          body: body,
        };

        setSaveLoading(true);
        toast.dark(`Creating ${data.subject}`);

        createNewBroadcast( data, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Created broadcast : ${data.subject}`);
            setTitle(null);
            setBody(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setTitleError(null);
    setBodyError(null);
  }, [title, body, isOpen]);

  return (
    <Popup
      title="E-Mail all students in this cohort"
      subtitle="Please fill in the details below to create a broadcast."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Send"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Email Subject"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Subject"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Content text you want to email students..."}
              disabled={saveLoading}
              required
              helperText={bodyError}
              error={bodyError != null}
              label="Body"
              multiline
              value={body}
              onChange={(event) => setBody(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
        </div>
      }
    />
  );
}
