import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SingleStudentCommentsView from "./single-student-comments-view";

import DialogueEditComments from "routes/dashboard/dialogues/students/edit-comments";
import DialogueDeleteComment from "routes/dashboard/dialogues/students/delete-comment"

export default withRouter(function (props) {
  let { student, setStudent, user, getStudent, editStudent } = props;

  const [comments, setComments] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showEditCommentsDialogue, setShowEditCommentsDialogue] = useState(false);
  const [showDeleteCommentDialogue, setShowDeleteCommentDialogue] = useState(false);

  useEffect(() => {
    if (student && student != null) {
      setComments(student.comments ? student.comments : []);
    }
  }, [student]);

  return (
    <div>
      <SingleStudentCommentsView
        loading={!student}
        toggleEditCommentsForm={() => setShowEditCommentsDialogue(true)}
        sectionDescription={"Comments about this student"}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        comments={comments}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteCommentDialogue(true);
        }}
      />

      <DialogueEditComments
        loading={!student}
        isOpen={showEditCommentsDialogue}
        setClose={() => setShowEditCommentsDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
      />

      <DialogueDeleteComment 
        loading={!student}
        isOpen={showDeleteCommentDialogue}
        setClose={() => setShowDeleteCommentDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        commentIndex={selectedIndex}
        comments={comments}
      />
    </div>
  );
});
