import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllEnquiries from "./all-enquiries";
import SingleEnquiry from "./single-enquiry";

export default function () {
  return (
    <div>
      <Switch>
        <Route exact path="/enquiries/all" component={() => <AllEnquiries />} />
        <Route path="/enquiries/all/:id" component={() => <SingleEnquiry />} />
      </Switch>
    </div>
  );
}
