import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import MentorsView from "./single-student-mentors-view";

//Dialogues
import DialogueAssignMentor from "routes/dashboard/dialogues/students/assign-mentor";
import DialogueDeleteMentor from "routes/dashboard/dialogues/students/delete-mentor";

export default withRouter(function (props) {
  const { student, setStudent, user, getStudent, editStudent } = props;

  const [mentors, setMentors] = useState(null);
  const [showAssignMentorDialogue, setShowAssignMentorDialogue] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showDeleteMentorDialogue, setShowDeleteMentorDialogue] = useState(false);


  useEffect(() => {
    if (student != null) {
      setMentors(student.mentors);
    }
  }, [student]);

  return (
    <div>
      <MentorsView
        loading={!mentors}
        sectionDescription={
          "You can view and delete all related mentors with this student."
        }
        mentors={mentors || []}
        setMentors={setMentors}
        toggleAssignMentorForm={() => setShowAssignMentorDialogue(true)}
        user={user}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteMentorDialogue(true);
        }}
      />
      <DialogueAssignMentor
        isOpen={showAssignMentorDialogue}
        setClose={() => setShowAssignMentorDialogue(false)}
        mentors={mentors || []}
        setMentors={setMentors}
        student={student}
        user={user}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
      />

      <DialogueDeleteMentor
        loading={!student}
        isOpen={showDeleteMentorDialogue}
        setClose={() => setShowDeleteMentorDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        mentorIndex={selectedIndex}
        mentors={mentors}
        // getStudent={getStudent}
      />
    </div>
  );
});
