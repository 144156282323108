import React, { useContext } from 'react';
import { SideNavigationContext } from 'repository/stores/dashboard/navigation/side-navigation-provider';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

// Actions
import { showSideNavigation } from 'repository/actions/views/navigation';

// Styles
import styles from './app-bar-navigation-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const [state, setSideNavigationContext] = useContext(SideNavigationContext);
  
  return (
    <IconButton onClick={() => showSideNavigation(state, setSideNavigationContext)} className={classes.navIcon} color="inherit" aria-label="Menu">
        <MenuIcon />
    </IconButton>
  );
}