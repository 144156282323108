import React, { useEffect } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { editCohortModuleAssignments } from "repository/actions/data/dashboard/students";
import { getSingleCourse } from "repository/actions/data/dashboard/courses"

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    student,
    cohorts,
    cohortId,
    user,
    setStudent,
    getStudent,
    getCourseModules
  } = props;

  const [cohort, setCohort] = React.useState(null);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [exitPoints, setExitPoints] = React.useState([]);
  const [exitPoint, setExitPoint] = React.useState(null);



  useEffect(() => {
    if (cohortId && cohortId != null && cohorts && cohorts != null) {
      for (let c of cohorts) {
        if (c._id == cohortId) {
          setCohort(c);
          getSingleCourse(c.courseId,user.idToken).then((result)=>{
            handleExitPoints(result.mqfLevel)
          })
          break;
        }
      }
    }
  }, [cohortId, cohorts]);

  useEffect(() => {
    if (cohort && cohort != null) {
      let selectedModuleIds = [];
      getCourseModules(cohort.courseId, user.idToken).then((result)=>{
        setModuleOptions(result);
      })
     
      if (student && student != null) {
        if (student.cohortModuleMap[cohortId]) {
          setSelectedModules(
            JSON.parse(JSON.stringify(student.cohortModuleMap[cohortId]))
          );
        }
      }
    }
  }, [cohort]);

  const handleSaveModuleAssociations = () => {
    if (user && user != null) {
      setSaveLoading(true);
      editCohortModuleAssignments(
        student,
        cohortId,
        selectedModules,
        exitPoint,
        user.idToken
      )
        .then((result) => {
          getStudent(student._id, user.idToken).then((getStudentResult) => {
            setStudent(getStudentResult);
            toast.success(
              `New modules assigned for ${student.firstName} ${student.lastName}`
            );
            setSaveLoading(false);
            setClose();
          });
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  const handleSelectedModuleToggle = (moduleId, isChecked) => {
    if (isChecked) {
      if (selectedModules.indexOf(moduleId) < 0) {
        let newSelectedModules = selectedModules;
        newSelectedModules.push(moduleId);
        setSelectedModules(newSelectedModules);
      }
    } else {
      if (selectedModules.indexOf(moduleId) > 0) {
        let newSelectedModules = [];
        for (let s of selectedModules) {
          if (s != moduleId) {
            newSelectedModules.push(s);
          }
        }
        setSelectedModules(newSelectedModules);
      }
    }
  };


  let allExitPoints = [
    ["Award", "Certificate"],
    ["Certificate", "Diploma", "Higher Diploma"],
    ["Certificate", "Diploma", "Higher Diploma", "B.Sc"],
    ["Postgraduate Certificate", "Postgraduate Diploma", "M.Sc"],
  ]

  const handleExitPoints = (mqfLevel) => {
    if (mqfLevel == "4") {
      setExitPoints(allExitPoints[0])
    }
    if (mqfLevel == "5") {
      setExitPoints(allExitPoints[1])
    }
    if (mqfLevel == "6") {
      setExitPoints(allExitPoints[2])
    }
    if (mqfLevel == "7") {
      setExitPoints(allExitPoints[3])
    }
  }


  return (
    <Popup
      title="Edit assigned modules"
      subtitle="Select the modules from this cohort to assign to this student"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveModuleAssociations()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl
                className={classes.inputType}
              >
                <InputLabel id="membership-type-label">
                  Exit Point
                </InputLabel>
                <Select
                  labelId="membership-type-label"
                  id="membership-type"
                  value={exitPoint}
                  onChange={(event) => {
                    setExitPoint(event.target.value)
                  }
                  }
                >
                  {exitPoints.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
          {moduleOptions && moduleOptions != null && (
            <FormControl className={classes.formControl}>
              <List>
                {moduleOptions.map((mod) => {
                  return (
                    <ListItem key={mod._id}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          defaultChecked={selectedModules.indexOf(mod._id) > -1}
                          onChange={(event) => {
                            handleSelectedModuleToggle(
                              mod._id,
                              event.target.checked
                            );
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${mod.title}`} />
                    </ListItem>
                  );
                })}
              </List>
            </FormControl>
          )}
        </div>
      }
    ></Popup>
  );
});
