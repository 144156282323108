import React, { useContext, useState, useEffect } from "react";
import NavCrumbs from "./timesheets-all-timesheets-nav-crumbs";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";

// Children
import TimesheetsView from "./timesheets-all-timesheets-view";


export default withRouter(function (props) {
  const { users, findAllUsers, findActiveUsers } = useContext(AllUsersContext);
  const { user } = useContext(AuthenticationContext);
  const [activeUsers, setActiveUsers] = useState([]);


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      // findAllUsers(user.idToken)
      // findSortedUsers(user.idToken)
      findActiveUsers(user.idToken, true).then((res) => {
        setActiveUsers(res.data);
      });
    }
  }, [user]);


 
  return (
    <div>
      <TimesheetsView
        loading={!activeUsers}
        sectionDescription={"You can manage woking hours below."}
        users={activeUsers || []}
        user={user}

      />
    </div>
  );
});
