import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(styles);

function CreateExtensionDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, student, setStudent, editStudent } = props;

  const [title, setTitle] = useState(null);
  const [reason, setReason] = useState(null);

  const [type, setType] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [cohortId, setCohortId] = useState(null);
  const [newCohort, setNewCohort] = useState(null);
  const [isActive, setIsActive] = useState("true");


  const [descriptionError, setDescriptionError] = useState(null);
  const [fromError, setFromError] = useState(null);
  const [toError, setToError] = useState(null);
  const [cohortError, setCohortError] = useState(null);
  const [cohortOptionsError, setCohortOptionsError] = useState(null);

  const [cohortOptions, setCohortOptions] = React.useState([]);

  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [saveLoading, setSaveLoading] = React.useState(null);

  const handleSave = () => {
    
    if (
      title == null ||
      from == null ||
      to == null ||
      cohortId == null ||
      type == null
    ) {
      toast.error(`You have missing information for this extension.`);

      if (title == null) {
        setDescriptionError(`Title is required`);
      }

      if (from == null) {
        setFromError(`From Date is required`);
      }

      if (to == null) {
        setToError(`To Date is required`);
      }

      if (cohortId == null) {
        setCohortError(`Cohort is required`);
      }
      if (type == null) {
        toast.error(`Type is required.`);
      }
    } else {
      let newExtensions = student.extensions ? student.extensions : [];


      
      newExtensions.push({
        title,
        reason,
        type,
        cohortId,
        from,
        to,
        isActive
      });

      let patchData = {
        extensions: newExtensions,
      };

      setSaveLoading(true);
      toast.dark(`Creating Extension`);

      editStudent(student._id, patchData, user.idToken)
        .then((result) => {
          setStudent(result);
          setClose();
          setSaveLoading(false);
          toast.success(`Extension created`);
          setDescriptionError(null);
          setFromError(null);
          setToError(null);
          setCohortError(null);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    if (student && student != null) {
      if (student.cohorts) {
        setCohortOptions(student.cohorts);
      }
    }
  }, [student]);

  useEffect(() => {
    if (newCohort != null) {
      setCohortId(newCohort);
    }
  }, [newCohort]);

  return (
    <Popup
      title="Add extension"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSave()}
      actionButtonTitle="Create"
      disabled={saveLoading}
      content={
        <div className={classes.popup}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  className={classes.formControl}
                  error={cohortError}
                >
                  <InputLabel required disabled={saveLoading}>
                    Select Cohort
                  </InputLabel>
                  {cohortOptions && cohortOptions != null && (
                    <Select
                      elevation={0}
                      onChange={(event) => setNewCohort(event.target.value)}
                      value={newCohort}
                      required
                      error={cohortError != null}
                      disabled={saveLoading}
                      input={<Input />}
                    >
                      {cohortOptions.map((item) => {
                        return (
                          <MenuItem value={item._id}>{item.title}</MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  <FormHelperText>{cohortOptionsError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel required disabled={saveLoading}>
                    Assessment Type
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setType(event.target.value)}
                    value={type}
                    required
                    // error={cohortError != null}
                    disabled={saveLoading}
                    input={<Input />}
                  >
                    <MenuItem value={"Assignment"}>{"Assignment"}</MenuItem>
                    <MenuItem value={"Discussion"}>{"Discussion"}</MenuItem>
                    <MenuItem value={"Presentation"}>{"Presentation"}</MenuItem>
                    <MenuItem value={"Examination"}>{"Examination"}</MenuItem>
                    <MenuItem value={"Dissertation"}>{"Dissertation"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Assignment Title"}
                    disabled={saveLoading}
                    required
                    helperText={descriptionError}
                    error={descriptionError != null}
                    label="Title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Missed the deadline due to no WiFi"}
                    disabled={saveLoading}
                    required
                    // helperText={descriptionError}
                    // error={descriptionError != null}
                    label="Reason"
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    required
                    format="dd/MM/yyyy"
                    openTo="year"
                    views={["year", "month", "date"]}
                    margin="normal"
                    id="from-date-picker-inline"
                    label="Original Date"
                    value={from}
                    helperText={fromError}
                    error={fromError != null}
                    onChange={(date) => {
                      setFrom(date);
                    }}
                    onClick={() => setOpenFrom(true)}
                    onError={(error) => setFromError(error.message)}
                    onOpen={() => setOpenFrom(true)}
                    onClose={() => setOpenFrom(false)}
                    open={openFrom}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    required
                    format="dd/MM/yyyy"
                    openTo="year"
                    views={["year", "month", "date"]}
                    margin="normal"
                    id="from-date-picker-inline"
                    label="Revised Due Date"
                    value={to}
                    helperText={toError}
                    error={toError != null}
                    onChange={(date) => {
                      setTo(date);
                    }}
                    onClick={() => setOpenTo(true)}
                    onError={(error) => setToError(error.message)}
                    onOpen={() => setOpenTo(true)}
                    onClose={() => setOpenTo(false)}
                    open={openTo}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withRouter(CreateExtensionDialogue);
