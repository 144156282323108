import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleCourse } from "repository/actions/data/dashboard/courses";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";


// Navigation
import NavCrumbs from "./content-single-course-nav-crumbs";

// Components
import Details from "./details";
import Cohorts from "./cohorts";
import Modules from "./modules";
import Documents from "./documents";
import DiplomaSupplement from "./diploma-supplement";

export default withRouter(function (props) {
  const courseId = props.match.params.id;

  const [course, setCourse] = useState(null);
  const { user } = useContext(AuthenticationContext);
  const {editCourse}= useContext(AllCoursesContext);

  useEffect(() => {
    if (user && user != null) {
      getSingleCourse(courseId, user.idToken).then((result) => {
        setCourse(result);
      });
    }
  }, []);

  let title = "...";
  if (course) {
    title = course.title;
  }
  
  return (
    <NavCrumbs course={course}>
      <Details course={course} setCourse={setCourse} user={user} />
      <Cohorts course={course} setCourse={setCourse} user={user} />
      <DiplomaSupplement course={course} editCourse={editCourse} user={user} />
      <Modules course={course} setCourse={setCourse} user={user} />
      <Documents course={course} setCourse={setCourse} user={user} />
    </NavCrumbs>
  );
});
