import moment from "moment";
import React, { useState,useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectActualView from "./single-project-actual-view";
import DialogueProjectTime from "routes/dashboard/dialogues/projects/add-timings"

export default withRouter(function (props) {
  let { loading, project, findConsultantByEmail,createProjectTiming,setProject, isNewProject, user, consultants } = props;

  const [showProjectTimeDialogue, setShowProjectTimeDialogue] = useState(false);


  const [consultant, setConsultant] = useState(null);


  useEffect(()=>{
    findConsultantByEmail(user.email).then((result)=>{
      setConsultant(result.data)
    })

  },[])

  return (
    <div>
      {!isNewProject && (
        <div>
          <SingleProjectActualView
            loading={loading}
            panelDescription={"Manage Project Actual Hours"}
            user={user}
            project={project}
            setProject={setProject}
            consultants={consultants}
            setShowProjectTimeDialogue={setShowProjectTimeDialogue}

          />

          <DialogueProjectTime
            isOpen={showProjectTimeDialogue}
            setClose={() => setShowProjectTimeDialogue(false)}
            consultant={consultant}
            user={user}
            project={project}
            date={moment().format("YYYY-MM-DD") + "T10:00:00.000Z"}
            showProjectTimeDialogue={showProjectTimeDialogue}
            createProjectTiming={createProjectTiming}
            // findActiveProjectsbyConsultant={findActiveProjectsbyConsultant}
            // getActiveProjects={getActiveProjects}
          />
        </div>
      )}
    </div>
  );
});
