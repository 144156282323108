import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { getAssociatedCohorts } from "repository/actions/data/dashboard/courses";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import Moment from "react-moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { course, setCourse, user } = props;
  const classes = useStyles();
  const [cohorts, setCohorts] = useState(null);

  useEffect(() => {
    if (user && user != null) {
      if (course != null) {
        getAssociatedCohorts(course._id, user.idToken).then((result) => {
          setCohorts(result.data);
        });
      }
    }
  }, [course]);

  return (
    <Section
      title={"Associated Cohorts"}
      loading={!cohorts}
      sectionDescription={"All cohorts associated with this course."}
    >
      {cohorts != null && (
        <div>
          <div style={{fontWeight:700, marginBottom:10}}>Cohorts : {cohorts.length}</div>
          {cohorts.length > 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Assigned Course
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Start Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        End Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cohorts.map((row, index) => {
                    return (
                      <TableRow hover key={row._id} className={classes.row}>
                        <TableCell className={classes.cellType} align="left">
                          {row.title}
                        </TableCell>
                        <TableCell className={classes.cellType} align="left">
                          {row.course.title}
                        </TableCell>
                        <TableCell className={classes.cellName} align="left">
                          <Moment format="D MMMM, YYYY" date={row.startDate} />
                        </TableCell>
                        <TableCell className={classes.cellType} align="left">
                          <Moment format="D MMMM, YYYY" date={row.endDate} />
                        </TableCell>
                        <TableCell className={classes.cellType} align="left">
                          {row.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {cohorts.length === 0 && (
            <Typography className={classes.emptyText} align="center">
              There are no associated cohorts with this course.
            </Typography>
          )}
        </div>
      )}
    </Section>
  );
});
