import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let crumbs = [
        {name: 'Training', path: 'training'},
        {name: 'Sessions', path: 'training/sessions'}
  ];

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
