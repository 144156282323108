import apiClient from "utils/api";
import { getSearchClient } from "utils/algolia";
import { convertDateToUnixTimestamp } from "utils/helpers/date";
import { getIdToken } from "../authentication"




export const findAllPersonnelAlgolia = async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        const searchClient = getSearchClient();
        const personnelIndex = searchClient.initIndex("Personnel");
        personnelIndex.search().then(({ hits }) => {
          dispatch({
            type: "UpdatePersonnel",
            payload: hits,
          });
          resolve(hits);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getSinglePersonnel = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/personnel").get(id, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewPersonnel = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/personnel").create(data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createPersonnelDocument = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").create(data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createPersonnelLecturerContract = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        data.hourlyRate = parseFloat(data.hourlyRate);
        data.totalLectureSessionHours = parseFloat(data.totalLectureSessionHours);
        data.contactHours = parseFloat(data.contactHours);
        data.totalRemuneration = parseFloat(data.totalRemuneration);
        data.totalBillableHours = parseFloat(data.totalBillableHours);
        data.type = "personnel";
        data.subtype = "contract-lecturer";
        let result = await apiClient.service("v1/documents").create(data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createPersonnelSupervisorContract = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        data.type = "personnel";
        data.subtype = "contract-supervisor";
        let result = await apiClient.service("v1/documents").create(data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createPersonnelMentorContract = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      data.type = "personnel";
      data.subtype = "contract-mentor";
       
        let result = await apiClient.service("v1/documents").create(data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocuments = async (query, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        query.firebaseToken = token;
        let result = await apiClient.service("v1/documents").find({
          query: query,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const deletePersonnel = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/personnel").remove(id, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateNewPersonnel = async (id, data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/personnel").patch(id, data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedSessions = async (personnelId, cohortId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/...").find({
          query: {
            lecturerId: personnelId,
            cohortId: cohortId,
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getActiveRoleCount = async (
  personnelId,
  roleId,
  startDate,
  endDate,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        if (startDate && endDate && startDate != null && endDate != null) {
          //check with 'now' if no start and end date given
          let start = new Date(startDate);
          let startTimestamp = convertDateToUnixTimestamp(start);
          let end = new Date(endDate);
          let endTimestamp = convertDateToUnixTimestamp(end);

          let activeSupervisorRoleAmount = await apiClient
            .service("v1/personnel-role-associations")
            .find({
              query: {
                roleId: roleId,
                personnelId: personnelId,
                $limit: 0,
                startsAtTimestamp: {
                  $lte: endTimestamp,
                },
                expiresAtTimestamp: {
                  $gte: startTimestamp,
                },
                firebaseToken: token,
              },
            });
          resolve(activeSupervisorRoleAmount.total);
        } else {
          //check with 'now' if no start and end date given
          let now = new Date();
          let nowTimestamp = convertDateToUnixTimestamp(now);
          let activeSupervisorRoleAmount = await apiClient
            .service("v1/personnel-role-associations")
            .find({
              query: {
                roleId: roleId,
                personnelId: personnelId,
                $limit: 0,
                startsAtTimestamp: {
                  $lte: nowTimestamp,
                },
                expiresAtTimestamp: {
                  $gte: nowTimestamp,
                },
                firebaseToken: token,
              },
            });
          resolve(activeSupervisorRoleAmount.total);
        }
    } catch (e) {
      reject(e);
    }
  });
};
