import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllPayments from './routes/all-payments';
import PaymentGantt from './routes/payment-gantt';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/payments/all' component={() => <AllPayments />} />
                <Route exact path='/payments/payments-gantt' component={() => <PaymentGantt />} />
                <Route exact path='/payments' component={() => <Redirect to={`/payments/payments-gantt`} />} />
            </Switch>
            
        </div>
    )
}