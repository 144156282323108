import React, { useEffect, useState } from "react";
import Panel from "components/panel";
import { IconButton, Grid } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import CalendarView from "./calendar";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

export default function (props) {
  const {
    weekText,
    selectedWeek,
    handleWeekChange,
    handleWeekSelect,
    addNewLecture,
  } = props;

  let one = new Date();
  return (
    <Panel title={""} style={{ width: "100%" }}>
      <Grid container spacing={4} style={{ justifyContent: "space-between" }}>
        <Grid item></Grid>

        <Grid item style={{ display: "flex" }}>
          <IconButton
            onClick={() => {
              handleWeekChange(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <label
            for="weekSelection"
            style={{ cursor: "pointer", marginTop: 15 }}
          >
            {weekText ? weekText : ""}
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="weekSelection"
              value={selectedWeek}
              onChange={handleWeekSelect}
              style={{ display: "none" }}
            />
          </MuiPickersUtilsProvider>
          <IconButton
            onClick={() => {
              handleWeekChange(true);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton onClick={() => addNewLecture()}>
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Panel>
  );
}
