import React, { useContext, useState, useEffect } from "react";
import NavCrumbs from "./view-all-timesheets-nav-crumbs";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";
import { getSingleUser } from "repository/actions/data/dashboard/users";

// Children
import Calendar from "./calendar";
import Info from "./info";


export default withRouter(function (props) {

  const userId = props.match.params.id;
  const { users, findAllUsers } = useContext(AllUsersContext);
  const { user, admin } = useContext(AuthenticationContext);

  const [loadedUser, setLoadedUser] = useState(null);

  const [hourInfo, setHourInfo] = useState({working:0,break:0,leaveTaken:0})

  const [leaveTaken, setLeaveTaken] = useState(null)
  const [leaveRemaining, setLeaveRemaining] = useState(null)



  useEffect(() => {
    getSingleUser(userId,user.idToken).then((result) => {
      setLoadedUser(result);
      setLeaveRemaining(result.leave)


      if(!admin){
        if( userId != user._id){
          window.location.href = `/users/${user._id}`
        }
      }
      
    });
  }, []);


  return (
    <NavCrumbs user={loadedUser} authUser={user}>
      <Calendar
        loading={!loadedUser}
        sectionDescription={"You can manage woking hours below."}
        user={loadedUser}
        authUser={user}
        hourInfo={hourInfo}
        setHourInfo={setHourInfo}
        setLeaveTaken={setLeaveTaken}
        leaveRemaining={leaveRemaining}
        setLeaveRemaining={setLeaveRemaining}
       />
        <Info
        loading={!loadedUser}
        sectionDescription={"You can manage woking hours below."}
        user={loadedUser}
        authUser={user}
        leaveTaken={leaveTaken}
        leaveRemaining={leaveRemaining}
        hourInfo={hourInfo}
       />
    </NavCrumbs>
  );
});
