import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import VacancyView from "./single-candidate-vacancies-view";



export default withRouter(function (props) {

  let { user, candidate, candidateId } = props


  return (
    <div style={{height:"96%"}}>
      {candidate &&
        <VacancyView
          loading={!candidate}
          onPressedItem={(id) => {window.location.href = `/vacancies/${id}`}}
          sectionDescription={`${candidate.firstName}'s applications below.`}
          candidate={candidate || null}
          user={user}
          candidateId={candidateId}
        />
      }

    </div>
  );
});
