import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Popper,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Moment from "react-moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    onPressedItem,
    onPressedDeleteItem,
    toggleEditModulesForm,
    modules,
    sectionDescription,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Panel
      title="Modules"
      loading={loading}
      sectionDescription={sectionDescription}
      actionName="Edit Modules"
      handleAction={toggleEditModulesForm}
    >
      {modules.length > 0 && (
        <TableContainer className={classes.miniSection} component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Reference
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Title</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    MQF Level
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modules.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {row.referenceNumber}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.mqfLevel}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {modules.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          No modules for this cohort
        </Typography>
      )}
    </Panel>
  );
}
