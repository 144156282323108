import React from 'react';

// Parents
import ProposalsTabs from './dash-proposals-tabs';
import ProposalsRoutes from './dash-proposals-routes';

export default function (props) {

  return (
    <ProposalsTabs>
      <ProposalsRoutes />
    </ProposalsTabs>
  )
}
