import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { candidate } = props;

  let crumbs = [
        {name: 'Candidates', path: 'candidates'},
        {name: 'All Candidates', path: 'candidates/all'},
        {name: '...', path: '#'},
  ];

  if (candidate != null) {
    crumbs[2] =  { name: `${candidate.firstName} ${candidate.lastName}`, path: `candidates/${candidate._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
