import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllClients from './routes/all-clients';
import SingleClients from './routes/single-clients';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/advisory-clients/all' component={() => <AllClients />} />
                <Route exact path='/advisory-clients/:id' component={() => <SingleClients />} />
                <Route exact path='/advisory-clients' component={() => <Redirect to={`/advisory-clients/all`} />} />
            </Switch>
            
        </div>
    )
}