import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSinglePersonnel } from "repository/actions/data/dashboard/personnel";
import { toast } from "react-toastify";

// Navigation
import NavCrumbs from "./administration-single-person-nav-crumbs";
import Details from "./details";
import Roles from "./roles";
import Qualifications from "./qualifications";
import OtherQualifications from "./other-qualifications";
import InterestsLecturing from "./interests-lecturing";
import Experience from "./experience";
import Documents from "./documents"
import Comments from "./comments";

import { Grid } from "@material-ui/core";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";

export default withRouter(function (props) {
  const personId = props.match.params.id;

  const [person, setPerson] = useState(null);
  const { user } = useContext(AuthenticationContext);

  useEffect(() => {
    if (user && user != null) {
      getSinglePersonnel(personId, user.idToken)
        .then((result) => {
          setPerson(result);
        })
        .catch((error) => {
          console.error(error.message);
          toast.error("This person does not exist.");
          window.location.href = "/administration/personnel";
        });
    }
  }, []);

  let title = "...";
  if (person) {
    title = `${person.firstName} ${person.lastName}`;
  }

  return (
    <NavCrumbs person={person} setPerson={setPerson} user={user}>
      {person != null && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Details person={person} setPerson={setPerson} user={user} />
              <div style={{ marginTop: "30px" }}></div>

              <Documents person={person} setPerson={setPerson} user={user} />
              <div style={{ marginTop: "30px" }}></div>

              <Roles person={person} setPerson={setPerson} user={user} />
            </Grid>

            <Grid item xs={4}>
              <Qualifications
                person={person}
                setPerson={setPerson}
                user={user}
              />
              <div style={{ marginTop: "30px" }}></div>

              <InterestsLecturing
                person={person}
                setPerson={setPerson}
                user={user}
              />
              <div style={{ marginTop: "30px" }}></div>

              <OtherQualifications
                person={person}
                setPerson={setPerson}
                user={user}
              />
              <div style={{ marginTop: "30px" }}></div>

              <Experience person={person} setPerson={setPerson} user={user} />
              <div style={{ marginTop: "30px" }}></div>

              <Comments person={person} setPerson={setPerson} user={user} />
            </Grid>
          </Grid>
        </div>
      )}
    </NavCrumbs>
  );
});
