import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import { TextField, FormControl, Grid, InputAdornment, IconButton, List, ListItem, Divider } from "@material-ui/core";


// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    project,
    setProject,
    isNewProject,
    panelDescription
  } = props;
  const classes = useStyles();



  const [task, setTask] = useState(null)
  const [allTasks, setAllTasks] = useState([])

  const [estimatedHours, setEstimatedHours] = useState(0)


  const defaultTasks = [
    { title: "Kick Off", estimatedHours: 0, actualHours: {} },
    { title: "Desk Research", estimatedHours: 0, actualHours: {} },
    { title: "Data Collection", estimatedHours: 0, actualHours: {} },
    { title: "Analysis", estimatedHours: 0, actualHours: {} },
    { title: "Reporting", estimatedHours: 0, actualHours: {} },
    { title: "Review", estimatedHours: 0, actualHours: {} },
    { title: "Presentation", estimatedHours: 0, actualHours: {} },
    { title: "Internal Meeting", estimatedHours: 0, actualHours: {} },
    { title: "Project Meetings", estimatedHours: 0, actualHours: {} },

  ]


  useEffect(() => {
    if (!loading) {
      if (isNewProject) {
        setAllTasks(defaultTasks)
        setProject({ ...project, tasks: defaultTasks })
      }
    }
  }, [loading])

  useEffect(() => {
    if(project){
      
      setAllTasks(project.tasks?project.tasks:[])

      let estHours = project.tasks?
      project.tasks.reduce((accumulator, object) => {
        return accumulator + parseInt(object.estimatedHours);
      }, 0)
      :0;

      setEstimatedHours(estHours)
    }
  }, [project])



  const handleCreateTask = () => {
    let currentTasks = [...allTasks]

    let newTask = {
      title: task,
      estimatedHours: 0, 
      actualHours: {}
    }
    currentTasks.push(newTask)
    setAllTasks(currentTasks)
    setProject({ ...project, tasks: currentTasks })

    setTask("")
  }

  const removeTask = (index) => {
    let currentTasks = [...allTasks]

    let currentEstimatedHours = estimatedHours
    currentEstimatedHours = currentEstimatedHours - parseInt(currentTasks[index].estimatedHours)
    setEstimatedHours(currentEstimatedHours)


    currentTasks.splice(index, 1)
    setAllTasks(currentTasks)

    setProject({ ...project, tasks: currentTasks, estimatedHours: currentEstimatedHours })

  }


  const handleExistingTaskTitle = (value, index) => {

    let currentTasks = [...allTasks]
    currentTasks[index].title = value
    setAllTasks(currentTasks)

    setProject({ ...project, tasks: currentTasks })

  }

  const handleExistingTaskHour = (value, index) => {

    let currentTasks = [...allTasks]
    let currentEstimatedHours = estimatedHours

    currentEstimatedHours = currentEstimatedHours - parseInt(currentTasks[index].estimatedHours)
    currentEstimatedHours = currentEstimatedHours + parseInt(value)
    setEstimatedHours(currentEstimatedHours)
    
    currentTasks[index].estimatedHours = value
    setAllTasks(currentTasks)

    setProject({ ...project, tasks: currentTasks, estimatedHours: currentEstimatedHours })

  }


 

  return (
    <Panel
      title={`${estimatedHours} Estimated Hours`}
      loading={loading}
      panelDescription={panelDescription}
    >
      <div>
        {allTasks.map((item, index) => {
          return (
            <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
              <Grid item xs={7}>
                <FormControl className={classes.inputType}>
                  <TextField
                    placeholder="Task"
                    variant="outlined"
                    type="text"
                    value={item.title}
                    size="small"
                    onChange={(event) => { handleExistingTaskTitle(event.target.value, index) }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl className={classes.inputType}>
                  <TextField
                    placeholder="Hours"
                    variant="outlined"
                    type="number"
                    size="small"
                    value={item.estimatedHours}
                    onChange={(event) => { handleExistingTaskHour(event.target.value, index) }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2} >
                <IconButton edge="end" aria-label="delete" onClick={() => { removeTask(index) }} style={{ marginTop: 20 }}>
                  <ClearIcon />
                </IconButton>
              </Grid>

            </Grid>

          )
        })}

        <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
          <Grid item xs={10}>
            <FormControl className={classes.inputType}>
              <TextField
                placeholder="New Task"
                variant="outlined"
                type="text"
                value={task}
                onChange={(event) => { setTask(event.target.value) }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={1} >
            <IconButton color="primary"
              className={classes.iconButton}
              disabled={(task == null || task == "")}
              onClick={() => { handleCreateTask() }}
            >
              <DoneIcon />
            </IconButton>
          </Grid>
        </Grid>


      </div>
    </Panel>
  );
});
