const styles = theme => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    labelText: {
        fontFamily: 'proximaNova-bold',
        fontSize: 14
    },
    paperSectionStart: {
        margin: 24,
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)'
    },
    paperSection: {
        margin: 24,
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
        minHeight: 200
    },
    paperTitle: {
        fontFamily: 'proximaNova-bold',
        fontSize: 18,
        paddingTop: 14,
        paddingLeft: 6,
        marginTop: 24,
        marginLeft: 10
    },
    button: {
        marginLeft: 'auto',
        boxShadow: 'none',
        marginRight: 5,
        marginTop: 14,
        height: 40,
        fontSize: 12,
        width: 95,
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'proximaNova-bold',
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
    cellName: {
        width: 200
    },
   loading: {
       width: '100%',
       marginTop: 60,
       fontSize: 15,
       textAlign: 'center'
   },
   emptyText: {
        width: '100%',
        marginTop: 60,
        paddingBottom:15,
        fontSize: 15,
        textAlign: 'center'
   },
   headerButtons: {
       display: 'flex',
       flexDirection: 'row',
       
   }
});
  
export default styles;
  