const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    leave: {
        padding: "20px",
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',

    },
    photo: {
        marginTop: 0,
        width: 50,
        height: 50,
        minWidth: 50,
        minHeight: 50,
        maxWidth: 50,
        maxHeight: 50,
        borderRadius: 3,
        marginLeft: 12,
        objectFit: 'cover',
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)'
    },
    leaveButton: {
        padding: "7px 25px",
        marginRight: "10px",
        marginTop: "35px",
        color: "white"
    },
    title: {
        marginTop: 12,
        fontFamily: "proximaNova-bold",
        color: theme.palette.primary.light,
        fontSize: 22,
    },
    subtitle: {
        marginTop: 12,
        fontFamily: "proximaNova-bold",
        color: theme.palette.primary.light,
        fontSize: 20,
    },
    reason: {
        marginTop: 12,
        fontSize: 20,
        textAlign: "center",
        padding: "0 30px"
    },
    spacing: {
        margin: "30px 0",
    },
    noPaidLeave:{
        fontSize: 17,
        marginTop: 15,
        color: "#8b0000"
    }

});

export default styles;
