import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { Button } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import LooksOneIcon from '@material-ui/icons/LooksOne';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();
  const {
    setClose,
    isOpen,
    setShowAssignLecturerDialogue,
    setShowBulkAssignLecturerDialogue
  } = props;

 
  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleOption = (isBulk) => {
    if(isBulk){
      setClose()
      setShowBulkAssignLecturerDialogue(true)
    }else{
      setClose()
      setShowAssignLecturerDialogue(true)
    }
  };



  return (
    <Popup
      title="Upload Options"
      subtitle="Please select method of upload Lecturers."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      // handleAction={() => handleOptions()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root} style={{display:"flex", justifyContent:"center"}}>
          <div onClick={()=>handleOption(false)} style={{width:200, height: 200, borderRadius:"20px", border: "1px solid grey", margin:30, cursor:"pointer"}}>
            <div style={{textAlign:"center",margin:"auto", position: "relative", top:"50%", left:"50%", transform:"translate(-50%,-50%"}}>
            <LooksOneIcon style={{fontSize:"70px", fill:"gray"}}/>
            <p>Single Assign</p>
            </div>
          </div>
          <div onClick={()=>handleOption(true)} style={{width:200, height: 200, borderRadius:"20px", border: "1px solid grey", margin:30, cursor:"pointer"}}>
            <div style={{textAlign:"center",margin:"auto", position: "relative", top:"50%", left:"50%", transform:"translate(-50%,-50%"}}>
            <PlaylistAddIcon style={{fontSize:"70px", fill:"gray"}}/>
            <p>Bulk Assign</p>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
