import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import DocumentView from "./single-student-documents-view";

// Dialogues
import DialogueCreateDocument from "routes/dashboard/dialogues/students/create-document";
import DialogueDeleteDocument from "routes/dashboard/dialogues/students/delete-document";

// Actions
import { getAssociatedDocuments } from "repository/actions/data/dashboard/students";

export default withRouter(function (props) {
  const { student, setStudent, user, editStudent, getStudent } = props;

  const [documents, setDocuments] = useState([]);
  const [cohorts, setCohorts] = useState([]);

  const [showNewDocumentDialogue, setShowNewDocumentDialogue] = useState(false);
  const [showDeleteDocumentDialogue, setShowDeleteDocumentDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (student != null) {
        getAssociatedDocuments(student._id, user.idToken).then((result) => {
          setDocuments(result.data);
        });
        setCohorts(student.cohorts);
      }
    }
  }, [student]);

  // Editing Item
  let editingItem = null;
  if (documents) {
    if (documents[selectedIndex]) {
      editingItem = documents[selectedIndex];
    }
  }

  return (
    <div>
      <DocumentView
        loading={!documents}
        toggleAddCourseForm={() => setShowNewDocumentDialogue(true)}
        sectionDescription={
          "You can view and delete all related transcripts below."
        }
        documents={documents || []}
        setDocuments={setDocuments}
        student={student}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteDocumentDialogue(true);
        }}
        setStudent={setStudent}
        user={user}
        editStudent={editStudent}
        getStudent={getStudent}
      />
      <DialogueCreateDocument
        isOpen={showNewDocumentDialogue}
        setClose={() => setShowNewDocumentDialogue(false)}
        documents={documents || []}
        cohorts={cohorts || []}
        setDocuments={setDocuments}
        setCohorts={setCohorts}
        student={student}
        setStudent={setStudent}
        user={user}
        editStudent={editStudent}
        getStudent={getStudent}
      />
      <DialogueDeleteDocument
        isOpen={showDeleteDocumentDialogue}
        setClose={() => setShowDeleteDocumentDialogue(false)}
        itemId={selectedId}
        documents={documents || []}
        setDocuments={setDocuments}
        setStudent={setStudent}
        user={user}
        editStudent={editStudent}
        getStudent={getStudent}
      />
    </div>
  );
});
