import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// Children
import Navigation from './navigation';
import Account from './account';
import Logo from './logo';

// Styles
import styles from './businesses-app-bar-styles';
const useStyles = makeStyles(styles);

function ApplicationBar(props) {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} zDepth={0}>
        <Toolbar>
            <Navigation />
            <Logo />
            <Account />
        </Toolbar>
    </AppBar>
  );
}
export default ApplicationBar;