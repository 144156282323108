import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-create-supervisor-contract-styles";
import { TextField, FormControl, Paper, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { convertDateToUnixTimestamp } from "utils/helpers/date";
import DateFnsUtils from "@date-io/date-fns";
import Popup from "components/popup";
//Actions
import { createPersonnelSupervisorContract } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function AddSupervisorContract(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    contracts,
    setContracts,
    role,
    roleAssociation,
    personnelId,
    student,
    course,
    user,
  } = props;

  const [studentRate, setStudentRate] = React.useState(null);
  const [totalStudents, setTotalStudents] = React.useState(null);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [totalRemuneration, setTotalRemuneration] = React.useState(null);

  const [totalRemunerationError, setTotalRemunerationError] =
    React.useState(null);
  const [limitError, setLimitError] = React.useState(null);
  const [activeContractCount, setActiveContractCount] = React.useState(0);

  const handleSaveSupervisorContract = () => {
    if (user && user != null) {
      if (totalRemuneration == null) {
        toast.error(
          "You have missing information for creating a supervisor contract"
        );
        setTotalRemunerationError("Total Remuneration is required");
      }
      let data = {
        personnelId: personnelId,
        roleId: role._id,
        courseId: course._id,
        studentId: student._id,
        totalRemuneration: totalRemuneration,
        roleAssociationId: roleAssociation._id,
        startDate: roleAssociation.startsAt,
        expiryDate: roleAssociation.expiresAt,
      };

      setSaveLoading(true);
      createPersonnelSupervisorContract(data, user.idToken)
        .then((result) => {
          let currentContracts = [...contracts];
          currentContracts.push(result);
          setContracts(currentContracts);
          setClose();
          toast.success("Supervisor contract successfully created");
          setSaveLoading(false);
          setTotalRemunerationError(null);
          setTotalRemuneration(null);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    setTotalRemunerationError(null);

    let acc = 0;
    let now = new Date();
    let nowTimestamp = convertDateToUnixTimestamp(now);

    for (let c of contracts) {
      if (
        c.startDateTimestamp <= nowTimestamp &&
        c.expiryDateTimestamp >= nowTimestamp
      ) {
        acc++;
      }
    }

    if (acc >= 4) {
      setLimitError("Supervisors can only have 4 active students");
    }
    setActiveContractCount(acc);
  }, [isOpen]);

  return (
    <Popup
      title="Add a new supervisor contract"
      subtitle="Please fill in the details to create a new supervisor contract"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveSupervisorContract()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className={classes.fullColumn}>
              <Typography variant="body2">
                {activeContractCount} / 4 supervisor contracts are active with
                this person.
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={saveLoading}
                  required
                  helperText={totalRemunerationError}
                  error={totalRemunerationError != null}
                  label="Total Remuneration (€)"
                  value={totalRemuneration}
                  margin="normal"
                  type="number"
                  placeholder={"Ex: 50"}
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(event) =>
                    setTotalRemuneration(parseFloat(event.target.value))
                  }
                ></TextField>
              </FormControl>
            </Paper>
          </MuiPickersUtilsProvider>
        </div>
      }
    ></Popup>
  );
}

export default withSnackbar(AddSupervisorContract);
