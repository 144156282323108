import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-create-qualification-styles";
import { Grid, TextField, FormControl } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";

const useStyles = makeStyles(styles);

function CreateQualificationDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, student, editStudent, setStudent } = props;

  const [qualifications, setQualifications] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newQualification, setNewQualification] = React.useState(null);
  const [newCountry, setNewCountry] = React.useState(null);
  const [newQualificationError, setNewQualificationError] =
    React.useState(null);
  const [newCountryError, setNewCountryError] = React.useState(null);

  useEffect(() => {
    if (student && student != null) {
      if (student.qualifications) {
        setQualifications(student.qualifications);
      }
    }
  }, [student]);

  const handleAddSaveAndClose = () => {
    if (
      newQualification == null ||
      newCountry == null ||
      newQualification.trim().length == 0 ||
      newCountry.trim().length == 0
    ) {
      if (newQualification == null || newQualification.trim().length == 0) {
        setNewQualificationError("Cannot have an empty qualification");
      }

      if (newCountry == null || newCountry.trim().length == 0) {
        setNewCountryError("Cannot have an empty country");
      }
    } else {
      let newQualificationPayload = {
        content: newQualification,
        country: newCountry,
        createdAt: new Date(),
        createdBy: user._id,
        createdByFullName: `${user.firstName} ${user.lastName}`,
      };

      let newQualifications = [newQualificationPayload].concat(qualifications);
      setQualifications(newQualifications);

      let patchData = { ...student, qualifications: newQualifications };

      setSaveLoading(true);
      toast.dark(`Creating qualification`);
      editStudent(student._id, patchData, user.idToken)
        .then((result) => {
          setStudent(result);
          setClose();
          setSaveLoading(false);
          setNewQualificationError(null);
          setNewCountryError(null);
          toast.success(`New qualification created`);
          handleClose();
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="New Qualification"
      subtitle="Add a new qualification for this student below"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => handleClose()}
      handleAction={() => handleAddSaveAndClose()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: MQF Level 7"}
                  disabled={saveLoading}
                  required
                  helperText={newQualificationError}
                  error={newQualificationError != null}
                  label="Qualification"
                  value={newQualification}
                  onChange={(event) => setNewQualification(event.target.value)}
                  margin="normal"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Malta"}
                  disabled={saveLoading}
                  required
                  helperText={newCountryError}
                  error={newCountryError != null}
                  label="Country"
                  value={newCountry}
                  onChange={(event) => setNewCountry(event.target.value)}
                  margin="normal"
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(CreateQualificationDialogue);
