import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'leads',
      startPath: 'all',
      routes:[
        { name: 'All Leads', path: 'all', icon: 'notifications_active'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});