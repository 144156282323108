const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    width: '100%',
    height:'100vh',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: 8,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 16,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop:16,
    },
  },
  progress: {
    margin: theme.spacing(2),
    marginTop: '45vh',
    color: theme.palette.primary.light
  },
  rootSmall: {
    backgroundColor: 'none',
    position: 'relative',
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: 8,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 16,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop:16,
    },
    
  },
  progressSmall: {
    margin: theme.spacing(2),
    color: theme.palette.primary.light
  },
  progressSmallLight: {
    margin: theme.spacing(2),
    color: 'white'
  }
});

export default styles;
