const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rightRowButton: {
    width: 28,
    height: 28,
    position: "relative",
    backgroundColor: "rgba(230,230,230,0)",
  },
  iconRight: {
    marginTop: -10,
  },
  table: {
    marginTop: 0,
  },
  photo: {
    marginTop: 0,
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 3,
    marginLeft: 12,
    objectFit: "cover",
    boxShadow: "0 1px 3px 0px rgba(180, 180, 180, .6)",
  },
  miniSection: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 18,
    boxShadow: "0 1px 3px 0px rgba(180, 180, 180, .4)",
  },
  minPhotoHeight: {
    marginTop: 5,
    width: 20,
    height: 50,
    minWidth: 20,
    minHeight: 50,
    maxWidth: 20,
    maxHeight: 50,
  },
  cellDescription: {
    paddingRight: 0,
  },
  cellTitle: {
    width: 30,
  },
  cellStatus: {
    width: 70,
  },
  cellStandard: {
    width: 130,
  },
  cellPhoto: {
    width: 20,
  },
  cellEmail: {
    width: 120,
  },
  cellDate: {
    width: 100,
  },
  select: {
    width: 50,
  },
  selectRight: {
    width: 150,
  },
  menuList: {
    borderRadius: 20,
  },
  menuItemTop: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  menuItemBottom: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  menu: {
    borderRadius: 15,
    marginTop: 15,
    borderStyle: "solid",
    borderColor: "rgba(230,230,230,0.7)",
    borderWidth: 1,
  },
  emptyText: {
    marginTop: 40,
    fontSize: 14,
    fontType: "proximaNova-regular",
  },
  labelText: {
    fontFamily: "proximaNova-bold",
    fontSize: 14,
  },
  row: {
    cursor: "pointer",
  },
  rowGreen: {
    cursor: "pointer",
    backgroundColor: "rgba(203, 235, 176, 0.81)",
  },
  rowAmber: {
    cursor: "pointer",
    backgroundColor: "rgba(255, 227, 176, 1)",
  },
  rowBlue: {
    cursor: "pointer",
    backgroundColor: "rgba(178, 234, 247, 1)",
  },
  rowRed: {
    cursor: "pointer",
    backgroundColor: "rgba(255, 187, 187, 1)",
  },
  cellDropped: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },
  searchBar: {
    border: "none",
    boxShadow: "none",
    marginBottom: 20,
    fontSize: "1rem",
  },

  paginationList: {
    textAlign: "center",
  },
  paginationListItem: {
    display: "inline",
    marginRight: "1rem",
    "&& g": {
      stroke: theme.palette.primary.light,
    },
  },
  paginationListItemActive: {
    display: "inline",
    fontWeight: "bold",
    borderBottomWidth: 3,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.light,
    marginRight: "1rem",
  },
  header: {
    marginBottom: 36,
    padding: 24,
  },
  hiddenRefinementList: {
    display: "none",
  },
  filterHeading: {
    fontWeight: 900,
    letterSpacing: "0.06rem",
  },
  selectContainer: {
    fontFamily: "proximaNova-regular",
  },
});

export default styles;
