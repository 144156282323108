import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import ProjectHours from "./project-hours-view";


export default withRouter(function (props) {
let {user, projects, setProjects} = props

 
  return (
    <div>
        <ProjectHours
          user={user}
          setProjects={setProjects}
          projects={projects}
        />
    </div>
  );
});
