import React, { useContext } from 'react';
import { SideNavigationContext } from 'repository/stores/dashboard/navigation/side-navigation-provider';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Drawer } from '@material-ui/core';

// Children
import Items from './items';

// Actions
import { showSideNavigation } from 'repository/actions/views/navigation';

// Styles
import styles from './fixed-drawer-styles';
const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();
    const [state, dispatch] = useContext(SideNavigationContext);
  
    return (
      <div>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor="left"
              open={state.isOpen}
              onClose={() => showSideNavigation(state, dispatch)}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
                <Items />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
                <Items />
            </Drawer>
          </Hidden>
        </div>
    );
};