import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { FormControl, FormHelperText } from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getSearchClient } from "utils/algolia";
import AsyncSelect from "react-select/async";

//Actions
import { assignMentor } from "repository/actions/data/dashboard/students";
import { getActiveRoleCount } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);
const searchClient = getSearchClient();
const personnelIndex = searchClient.initIndex("Personnel");
const courseIndex = searchClient.initIndex("Courses");

function AssignMentorDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, student, user, setStudent, getStudent } = props;

  const [personnelOptions, setPersonnelOptions] = React.useState([]);
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [selectedPersonnelId, setSelectedPersonnelId] = React.useState(null);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [expiryDate, setExpiryDate] = React.useState(null);
  const [openExpiryDate, setOpenExpiryDate] = React.useState(false);
  const [selectedPersonnelIdError, setSelectedPersonnelIdError] =
    React.useState(null);
  const [personnelRoleCountMessage, setPersonnelRoleCountMessage] =
    React.useState(null);
  const [startDateError, setStartDateError] = React.useState(null);
  const [expiryDateError, setExpiryDateError] = React.useState(null);
  const [selectedCourseIdError, setSelectedCourseIdError] =
    React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleSaveAssignment = () => {
    if (user && user != null) {
      if (
        selectedPersonnelId == null ||
        selectedCourseId == null ||
        startDate == null ||
        expiryDate == null
      ) {
        toast.error(`There is missing information to assign a new mentor`);
        if (selectedPersonnelId == null) {
          setSelectedPersonnelIdError("A person needs to be chosen");
        }
        if (selectedCourseId == null) {
          setSelectedCourseIdError("A course needs to be chosen");
        }
        if (startDate == null) {
          setStartDateError("A start date to this role is required");
        }
        if (expiryDate == null) {
          setExpiryDateError("An expiry date to this role is required");
        }
      } else {
        setSaveLoading(true);

        assignMentor(
          student._id,
          selectedPersonnelId,
          selectedCourseId,
          startDate,
          expiryDate,
          user.idToken
        )
          .then((result) => {

            getStudent(student._id, user.idToken).then(getStudentResult => {
              setClose();
              setSaveLoading(false);
              toast.success(
                `A new mentor has been assigned to ${student.firstName} ${student.lastName}`
              );
              setSelectedPersonnelIdError(null);
              setSelectedCourseIdError(null);
              setStartDateError(null);
              setExpiryDateError(null);
              setPersonnelRoleCountMessage(null);
            })
            
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (user && user != null) {
      if (
        selectedPersonnelId != null &&
        startDate != null &&
        expiryDate != null
      ) {
        //get number of active mentor roles
        getActiveRoleCount(
          selectedPersonnelId,
          "mentor",
          startDate,
          expiryDate,
          user.idToken
        )
          .then((mentorrRoleCount) => {
            if (mentorrRoleCount >= 6) {
              setSelectedPersonnelIdError(
                "This person already has 6 active mentor roles."
              );
            } else {
              setPersonnelRoleCountMessage(
                `Mentor roles for this person : ${mentorrRoleCount}`
              );
            }
          })
          .catch((e) => {
            toast.error(e.message);
          });
      }
    }
  }, [selectedPersonnelId, startDate, expiryDate]);

  const handleSelectPersonnel = (personnel) => {
    return new Promise(async (resolve, reject) => {
      try {
        setSelectedPersonnelId(personnel.value);

        resolve(personnel.value);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleSelectCourse = (course) => {
    return new Promise(async (resolve, reject) => {
      try {
        setSelectedCourseId(course.value);
        resolve(course.value);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handlePersonnelSearchChange = (searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newPersonnelOptions = [];
        personnelIndex.search(searchQuery).then(({ hits }) => {
          for (let hit of hits) {
            newPersonnelOptions.push({
              label: `${hit.title} ${hit.firstName} ${hit.lastName}`,
              value: hit.objectID,
            });
          }

          setPersonnelOptions(newPersonnelOptions);
          resolve(newPersonnelOptions);
        });
      } catch (e) {
        reject([]);
      }
    });
  };

  const handleCourseSearchChange = (searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newCourseOptions = [];
        courseIndex.search(searchQuery).then(({ hits }) => {
          for (let hit of hits) {
            newCourseOptions.push({
              label: `${hit.title}`,
              value: hit.objectID,
            });
          }
          setCourseOptions(newCourseOptions);
          resolve(newCourseOptions);
        });
      } catch (e) {
        reject([]);
      }
    });
  };

  return (
    <Popup
      title="Assign a mentor"
      subtitle="Select a mentor and set the dates for the role"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveAssignment()}
      actionButtonTitle="Assign"
      disabled={saveLoading}
      content={
        <div className={classes.popup}>
          {
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handlePersonnelSearchChange}
                onChange={handleSelectPersonnel}
                placeholder="Select / Search for Personnel"
              />
              {personnelRoleCountMessage != null && (
                <FormHelperText>{personnelRoleCountMessage}</FormHelperText>
              )}
              {selectedPersonnelIdError != null && (
                <FormHelperText>{selectedPersonnelIdError}</FormHelperText>
              )}
              <br />
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handleCourseSearchChange}
                onChange={handleSelectCourse}
                placeholder="Select / Search for Course"
              />
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  required
                  format="dd/MM/yyyy"
                  margin="normal"
                  openTo="year"
                  views={["year", "month", "date"]}
                  id="start-date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  helperText={startDateError}
                  error={startDateError != null}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  onClick={() => setOpenStartDate(true)}
                  onError={(error) => setStartDateError(error.message)}
                  onOpen={() => setOpenStartDate(true)}
                  onClose={() => setOpenStartDate(false)}
                  open={openStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  required
                  format="dd/MM/yyyy"
                  margin="normal"
                  openTo="year"
                  views={["year", "month", "date"]}
                  id="expiry-date-picker-inline"
                  label="Expiry Date"
                  value={expiryDate}
                  helperText={expiryDateError}
                  error={expiryDateError != null}
                  onChange={(date) => {
                    setExpiryDate(date);
                  }}
                  onClick={() => setOpenExpiryDate(true)}
                  onError={(error) => setExpiryDateError(error.message)}
                  onOpen={() => setOpenExpiryDate(true)}
                  onClose={() => setOpenExpiryDate(false)}
                  open={openExpiryDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          }
        </div>
      }
    />
  );
}

export default withSnackbar(AssignMentorDialogue);
