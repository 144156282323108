import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";


export default withSnackbar(
  withRouter(function (props) {

    const { isOpen, setClose, projectId, user, deleteProject, setRefreshAlgolia } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteProject = () => {
      if (user && user != null) {

        setSaveLoading(true);

        deleteProject(projectId, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Project successfully deleted`);
           
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected Project"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteProject()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this Project?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
