import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { toast } from "react-toastify";
import { FormControl, TextField } from "@material-ui/core";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(styles);

function AssignCanvasUrlDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, cohort, setCohort, user, getCohort, editCohort } =
    props;

  const [canvasUrl, setCanvasUrl] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [canvasUrlError, setCanvasUrlError] = useState(null);
  const handleSaveUrl = () => {
    if (user && user != null) {
      if (canvasUrl && canvasUrl != null && canvasUrl.length > 0) {
        //savecohort
        setSaveLoading(true);
        toast.dark(`Assigning Canvas URL to ${cohort.title}`);

        let patchData = {
          canvasUrl : canvasUrl.indexOf('http') >= 0 ? canvasUrl : `https://${canvasUrl}`,
        };

        editCohort(cohort._id, patchData, user.idToken)
          .then((result) => {
            setSaveLoading(false);
            toast.success(`Canvas URL has been updated`);
            setClose();
          })
          .catch((e) => {
            toast.error(`Oops! Something went wrong. ${e.message}`);
          });
      } else {
        toast.error("You have missing information for assigning a Canvas URL");
        if (canvasUrl.length == 0 || canvasUrl == null) {
          setCanvasUrlError("Enter a valid url");
        }
      }
    }
  };

  useEffect(() => {
    if (cohort && user && cohort != null && user != null) {
      setCanvasUrl(cohort.canvasUrl);
    }
  }, [cohort, user]);

  return (
    <Popup
      title="Assign Canvas URL"
      subtitle="This is the URL to access this cohort on Canvas"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveUrl()}
      actionButtonTitle="Assign"
      disabled={saveLoading}
      content={
        <div className={classes.popup}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: https://ideaed.instructure.com/courses/XXXX "}
              disabled={saveLoading}
              required
              helperText={canvasUrlError}
              error={canvasUrlError != null}
              label="Canvas URL"
              value={canvasUrl}
              onChange={(event) => setCanvasUrl(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
        </div>
      }
    />
  );
}

export default withRouter(AssignCanvasUrlDialogue);
