import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
// import PaymentGantt from '../payments/routes/payment-gantt';
import ProjectsGantt from './routes/projects-gantt';


export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/advisory-dashboard' component={() => <ProjectsGantt />} />

                <Route exact path='/advisory-dashboard' component={() => <Redirect to={`/advisory-dashboard`} />} />
            </Switch>
            
        </div>
    )
}