
import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  paymentPlans: null,
  setPaymentPlans: {},
  courseOptions: null,
  setCourseOptions: {},
  getCourses: {},
  loadingCourses: false,
};

export const AllPaymentPlansContext = React.createContext(ContextProps);

export const AllPaymentPlansProvider = ({ children }) => {
  const [paymentPlans, setPaymentPlans] = useState(null);

  const { getIdToken } = useContext(IdTokenContext)

  
  const getAllPaymentPlans = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/paymentschedules").find({
          query: {
            firebaseToken: token,
          },
        });

        setPaymentPlans(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  
 const createNewPaymentPlan = async (paymentPlan, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      getIdToken().then(async (token) => {

      let result = await apiClient.service("v1/paymentschedules").create(paymentPlan, {
        query: {
          firebaseToken: token,
        },
      });

      let newPaymentPlans = paymentPlans
      newPaymentPlans.push(result)
      setPaymentPlans(newPaymentPlans);

      resolve(result);
    })
    } catch (e) {
      reject(e);
    }
  });
};


const editPaymentPlan = async (paymentPlan, paymentPlanId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      getIdToken().then(async (token) => {

      let result = await apiClient.service("v1/paymentschedules").create(paymentPlan, {
        query: {
          firebaseToken: token,
        },
      });

      let newData = paymentPlans
        for (const [i, v] of newData.entries()) {
          if (v._id === paymentPlanId) {
            newData[i] = paymentPlan;
          }
        }
        setPaymentPlans(newData);

      resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};


  return (
    <AllPaymentPlansContext.Provider
      value={{
        paymentPlans,
        setPaymentPlans,
        getAllPaymentPlans,
        createNewPaymentPlan,
        editPaymentPlan
      }}
    >
      {children}
    </AllPaymentPlansContext.Provider>
  );
};
