import React from 'react';

// Parents
import OutOfOfficeRoutes from './dash-out-of-office-routes';

export default function (props) {

  return (
      <OutOfOfficeRoutes />
  )
}
