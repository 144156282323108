export default theme => ({
    drawerPaper: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: 220,
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .2)',
        zIndex: 890,
        [theme.breakpoints.up('sm')]: {
          width: 220,
          borderTopRightRadiuds: 0,
          borderBottomRightRadius: 0
        },
        [theme.breakpoints.up('md')]: {
          width: 175,
          padding:0,
          marginTop: 50,
          position: 'fixed',
          height: `calc(100% - 50px)`,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
    },
});