import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Image
import BackgroundImage from 'assets/images/login-bg.jpg';

// Styles
import styles from './background-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.image} src={BackgroundImage}></img>
    </div>
  );
}