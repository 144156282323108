import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";


import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProgrammesContext } from "repository/stores/dashboard/programme-costings/programme-costings-all-provider";


// Children
import ProgrammesView from "./costings-all-costings-view";


export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { programmes, getProgrammes } = useContext(AllProgrammesContext);


  // const [selectedId, setSelectedId] = useState(null);
  // const [selectedIndex, setSelectedIndex] = useState(null);
  // const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (programmes == null) {
        getProgrammes(user.idToken);
      }
    }
  }, [programmes]);


  return (
    <div>
      <ProgrammesView
        loading={!programmes}
        onPressedItem={(id) => props.history.push(`${id}`)}
        handleNewProgramme={() => props.history.push(`${0}`)}
        sectionDescription={"You can manage all of your programme profiles below."}
        programmes={programmes || []}

        // onPressedDeleteItem={(id) => {
        //   setSelectedId(id);
        //   setShowDeleteClientDialogue(true);
        // }}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}

      />

    </div>
  );
});
