import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ListItem, List } from "@material-ui/core";
import styles from "routes/dashboard/dashboard-styles";
import Panel from "components/panel";
import DialogueEditExperience from "routes/dashboard/dialogues/personnel/edit-experience";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { person, setPerson, user } = props;
  const [experience, setExperience] = useState([]);
  const [showEditExperienceDialogue, setShowEditExperienceDialogue] =
    useState(false);

  useEffect(() => {
    setExperience(person.experience);
  }, [person]);

  return (
    <div>
      <Panel
        title="Experience"
        loading={false}
        sectionDescription="Experience this person has."
        actionName="Edit"
        handleAction={() => setShowEditExperienceDialogue(true)}
      >
        {experience != null && experience.length > 0 && (
          <List>
            {experience.map((role) => (
              <ListItem className={classes.labelText}>{role}</ListItem>
            ))}
          </List>
        )}

       
        {experience != null && (
          <div>
            {experience.length == 0 && (
              <Typography className={classes.loading}>
                This person has no experience listed to them
              </Typography>
            )}
          </div>
        )}

        <DialogueEditExperience
          isOpen={showEditExperienceDialogue}
          setClose={() => setShowEditExperienceDialogue(false)}
          personId={person._id}
          person={person}
          setExperience={setExperience}
          setPerson={setPerson}
          user={user}
        />
      </Panel>
    </div>
  );
}
