import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { capitalizeFirstLetter } from "utils/helpers/string";
import Moment from "react-moment";
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  Pagination,
  Panel,
  connectSearchBox,
  connectInfiniteHits,
  RefinementList,
} from "react-instantsearch-dom";
import NavCrumbs from "./administration-all-people-nav-crumbs";
import styles from "./administration-all-people-styles";
import "./administration-all-people-styles.css";

const useStyles = makeStyles(styles);
const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddPersonForm,
    onPressedItem,
    sectionDescription,
    user
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <div className={classes.searchBox}>
        <TextField
          type="search"
          variant="outlined"
          label="Search for a person"
          value={currentRefinement}
          fullWidth
          onChange={(event) => refine(event.currentTarget.value)}
        />
      </div>
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {

          let border = "0px solid red"
          if(row.isFlagged) border = "1px solid red"
          
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                style={{borderLeft:border}}
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellName}
                align="left"
              >
                {row.firstName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellName}
                align="left"
              >
                {row.lastName}
              </TableCell>
            
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellName}
                align="left"
              >
                <div className={classes.cellFlex}>
                  {row.roles.map((role) => {
                    return (
                      <div className={classes.chip}>
                        <Chip label={role.roleName} />
                      </div>
                    );
                  })}
                  {row.roles.length == 0 && (
                    <div className={classes.chip}>
                      <Chip label="N/A" />
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="view"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectInfiniteHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR PERSONNEL"
        loading={loading}
        actionName="Add person"
        handleAction={toggleAddPersonForm}
        sectionDescription={sectionDescription}
      >
        <InstantSearch indexName="Personnel" searchClient={searchClient}>
          <Paper className={classes.searchPaper}>
            <CustomSearchBox />
            <Panel header="Roles">
              <RefinementList
                attribute="roles.roleId"
                transformItems={(items) =>
                  items.map((item) => ({
                    ...item,
                    label: capitalizeFirstLetter(item.label),
                  }))
                }
              />
                <RefinementList
              attribute="mqfLevel"
              facetOrdering
              transformItems={(items) =>
                items.map((item) => {
                  let custom = "";
                  if (item.label == "8") {
                    custom = "PhD";
                  } 
                  if (item.label == "7") {
                    custom = "MSc";
                  } 
                  if (item.label == "6") {
                    custom = "BSc";
                  } 
                  return {
                    ...item,
                    label: custom,
                  };
                })
              }
            />
            </Panel>
          </Paper>
          <TableContainer className={classes.miniSection} component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                 
                  <TableCell align="left">
                    <Typography className={classes.labelText}>
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.labelText}>
                      Last Name
                    </Typography>
                  </TableCell>
                  
                  <TableCell align="left">
                    <Typography className={classes.labelText}>
                      Active Roles
                    </Typography>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <CustomHits />
            </Table>
          </TableContainer>

          <Panel>
            <div className={classes.pagination}>
              <Pagination
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
            </div>
          </Panel>
        </InstantSearch>
      </Section>
    </NavCrumbs>
  );
}
