import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllStudentsContext } from "repository/stores/dashboard/students/students-all-provider";
import { SingleStudentContext } from "repository/stores/dashboard/students/students-single-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";

// Children
import StudentsView from "./students-all-students-view";

// Dialogues
import DialogueCreateStudent from "routes/dashboard/dialogues/students/create-student";
import DialogueEditStudent from "routes/dashboard/dialogues/students/edit-student";
import DialogueDeleteStudent from "routes/dashboard/dialogues/students/delete-student";

// Actions
import {
  getSingleStudent,
} from "repository/actions/data/dashboard/students";

export default withRouter(function (props) {
  const {campus} = props
  const {students, setStudents, getAllStudents, deleteStudent, createNewStudent} = useContext(AllStudentsContext);
  const {editStudent} = useContext(SingleStudentContext);
  const {courses, setCourses, getCourses} = React.useContext(AllCoursesContext);

  const { user } = useContext(AuthenticationContext);

  const [showNewStudentDialogue, setShowNewStudentDialogue] = useState(false);
  const [showEditStudentDialogue, setShowEditStudentDialogue] = useState(false);
  const [showDeleteStudentDialogue, setShowDeleteStudentDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (students == null) {
        getAllStudents(user.idToken);
      }
    }
  }, [students]);

  // Editing Item
  useEffect(() => {
    if (user && user != null) {
      if (students) {
        if (selectedId && editingItem == null) {
          getSingleStudent(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);
  
  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, []);


  

  return (
    <div>
      <StudentsView
        loading={!students}
        onPressedItem={(id) => props.history.push(`extensions/${id}`)}
        sectionDescription={"You can manage all of your students below."}
        students={students || []}
     
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
      />
     
    </div>
  );
});
