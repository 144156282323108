import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectPaymentsView from "./single-project-payments-view";


export default withRouter(function (props) {
  let {
    loading,
    project,
    setProject,
    isNewProject,
    createProjectPayment,
    deleteProjectPayment,
    findProjectPaymentByProject,
    user,
  } = props;



  return (
    <div>
      <SingleProjectPaymentsView
        loading={loading}
        panelDescription={"Manage Project Payments"}
        user={user}
        project={project}
        setProject={setProject}
        isNewProject={isNewProject}
        createProjectPayment={createProjectPayment}
        deleteProjectPayment={deleteProjectPayment}
        findProjectPaymentByProject={findProjectPaymentByProject}
      />

    </div>
  );
});
