import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllReferralsContext } from "repository/stores/dashboard/referrals/referrals-all-provider";
import { SingleStudentContext } from "repository/stores/dashboard/students/students-single-provider";
import DialogueEditReferral from "routes/dashboard/dialogues/referrals/edit-referral";
import DialogueDeleteReferral from "routes/dashboard/dialogues/referrals/delete-referral";

import Referral from "./single-student-referral-view";

// Navigation
import NavCrumbs from "./students-single-referral-nav-crumbs";

export default withRouter(function (props) {
  const referralId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);

  const { getReferral, editReferral, deleteReferral } = useContext(AllReferralsContext);
  const { getStudent } = useContext(SingleStudentContext);

  const [referral, setReferral] = useState(null);
  const [student, setStudent] = useState(null);
  const [showEditReferralDialogue, setShowEditReferralDialogue] = useState(false);
  const [showDeleteReferralDialogue, setShowDeleteReferralDialogue] = useState(false);


  useEffect(() => {
    if (user && user != null) {
      if (referralId && referralId != null) {
        getReferral(referralId, user.idToken).then((res) => {
          setReferral(res);
          getStudent(res.studentId, user.idToken).then((res) => {
            setStudent(res);
          });
        });
      }
    }
  }, [user, referralId]);

  return (
    <NavCrumbs referral={referral}>
      <Referral
        referral={referral}
        student={student}
        editReferral={editReferral}
        toggleDeleteReferralForm={() => setShowDeleteReferralDialogue(true)}
        toggleEditReferralForm={() => setShowEditReferralDialogue(true)}
        user={user}
      />

      <DialogueEditReferral
        isOpen={showEditReferralDialogue}
        user={user}
        referral={referral}
        editReferral={editReferral}
        setClose={() => setShowEditReferralDialogue(false)}
      />
      <DialogueDeleteReferral
        isOpen={showDeleteReferralDialogue}
        user={user}
        referral={referral}
        deleteReferral={deleteReferral}
        setClose={() => setShowDeleteReferralDialogue(false)}
      />
    </NavCrumbs>
  );
});
