import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Link,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ImageFadeIn from "react-image-fade-in";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// Actions
// import { AllStudentsContext } from "repository/stores/dashboard/students/students-all-provider";
// import { editStudent } from "repository/actions/data/dashboard/students";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, setStudents, editStudent, courses, getCourses } = props;

  // const {courses, setCourses, getCourses} = React.useContext(AllCoursesContext);

  const [identificationNumber, setIdentificationNumber] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [mobile, setMobile] = React.useState(null);
  const [campus, setCampus] = React.useState(null);

  const [addressHouse, setAddressHouse] = React.useState(null);
  const [addressStreet, setAddressStreet] = React.useState(null);
  const [addressCity, setAddressCity] = React.useState(null);
  const [addressPostcode, setAddressPostcode] = React.useState(null);
  const [addressCountry, setAddressCountry] = React.useState(null);

  const [lead, setLead] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [gender, setGender] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  const [studentNumber, setStudentNumber] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [studentNumberError, setStudentNumberError] = React.useState(null);
  const [identificationNumberError, setIdentificationNumberError] =
    React.useState(null);
  const [titleError, setTitleError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [companyError, setCompanyError] = React.useState(null);
  const [roleError, setRoleError] = React.useState(null);
  const [addressHouseError, setAddressHouseError] = React.useState(null);
  const [addressStreetError, setAddressStreetError] = React.useState(null);
  const [addressCityError, setAddressCityError] = React.useState(null);
  const [addressCountryError, setAddressCountryError] = React.useState(null);
  const [addressPostcodeError, setAddressPostcodeError] = React.useState(null);
  const [leadError, setLeadError] = React.useState(null);
  const [dobError, setDobError] = React.useState(null);
  const [genderError, setGenderError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [isInternational, setIsInternational] = React.useState(false);
  const [receiveMarketing, setReceiveMarketing] = React.useState(false);

  const [mobileError, setMobileError] = React.useState(null);
  const [campusError, setCampusError] = React.useState(null);

  const [openDob, setOpenDob] = React.useState(false);



  useEffect(() => {
    if (data) {
      setStudentNumber(data.studentNumber);
      setIdentificationNumber(data.identificationNumber);
      setAddressHouse(data.addressHouse);
      setAddressCountry(data.addressCountry);
      setAddressCity(data.addressCity);
      setAddressStreet(data.addressStreet);
      setAddressPostcode(data.addressPostcode);
      setTitle(data.title);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setCompany(data.company);
      setRole(data.role);
      setLead(data.lead);
      setDob(data.dob);
      setGender(data.gender);
      setCampus(data.campus);
      setReceiveMarketing(data.receiveMarketing)
      setEmail(data.email);
      setMobile(data.mobile);
      setStudentNumber(data.studentNumber);
      setIsInternational(data.isInternational ? data.isInternational : false);
    }
  }, [data]);

  const handleEditStudent = () => {

    if (user && user != null) {
      if (
        firstName == null ||
        title == null ||
        lastName == null ||
        // addressHouse == null ||
        // addressStreet == null ||
        // addressCity == null ||
        // addressCountry == null ||
        // addressPostcode == null ||
        // lead == null ||
        // dob == null ||
        // gender == null ||
        email == null ||
        campus == null ||
        studentNumber == null 
        // identificationNumber == null
      ) {

        toast.error(`You have missing information for updating this student.`);
        if (identificationNumber == null) {
          setIdentificationNumberError("ID Number is required");
        }
        if (firstName == null) {
          setFirstNameError("First name is required");
        }
        if (lastName == null) {
          setLastNameError("Last name is required.");
        }
        // if (company == null) {
        //   setCompanyError("Company is required.");
        // }
        // if (role == null) {
        //   setRoleError("Role is required.");
        // }
        // if (addressHouse === null) {
        //   setAddressHouseError("House details are required.");
        // }
        // if (addressStreet === null) {
        //   setAddressStreetError("Street details are required.");
        // }
        // if (addressCity === null) {
        //   setAddressCityError("City details are required.");
        // }
        // if (addressCountry === null) {
        //   setAddressCountryError("Country details are required.");
        // }
        // if (addressPostcode === null) {
        //   setAddressPostcodeError("Postcode details are required.");
        // }
        // if (title == null) {
        //   setTitleError("Title is required.");
        // }
        // if (lead == null) {
        //   setLeadError("Lead is required.");
        // }
        // if (dob == null) {
        //   setDobError("Date of Birth is required.");
        // }
        // if (gender == null) {
        //   setGenderError("Gender is required.");
        // }
        if (email == null) {
          setEmailError("Email is required.");
        }
        if (campus == null) {
          setCampusError("Campus is required.");
        }
      } else {
        let saveData = {
          title: title,
          firstName: firstName,
          lastName: lastName,
          company: company,
          role: role,
          addressHouse: addressHouse,
          addressCity: addressCity,
          addressStreet: addressStreet,
          addressPostcode: addressPostcode,
          addressCountry: addressCountry,
          lead: lead,
          dob: dob,
          gender: gender,
          email: email,
          campus: campus,
          studentNumber: studentNumber,
          isInternational: isInternational,
          receiveMarketing: receiveMarketing,
          identificationNumber: identificationNumber,
          mobile: mobile,
        };

        setSaveLoading(true);

        editStudent(itemId, saveData, user.idToken)
          .then((result) => {
            setClose();
            toast.success(
              `Student successfully modified : ${saveData.firstName} ${saveData.lastName}`
            );
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Edit student"
      subtitle="You can edit the details of your student below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleEditStudent()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  className={classes.inputType}
                  error={studentNumberError != null}
                >
                  <TextField
                    placeholder={"Ex: S948392"}
                    disabled={saveLoading}
                    helperText={studentNumberError}
                    error={studentNumberError != null}
                    label="Student Number"
                    value={studentNumber}
                    onChange={(event) => setStudentNumber(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FormControl
                  className={classes.inputType}
                  error={titleError != null}
                >
                  <InputLabel disabled={saveLoading}>
                    Title
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setTitle(event.target.value)}
                    value={title}
                    // required
                    error={titleError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"mr"}>Mr.</MenuItem>
                    <MenuItem value={"ms"}>Ms.</MenuItem>
                    <MenuItem value={"ing"}>Ing.</MenuItem>
                    <MenuItem value={"dr"}>Dr.</MenuItem>
                  </Select>
                  <FormHelperText>{titleError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: John"}
                    disabled={saveLoading}
                    required
                    helperText={firstNameError}
                    error={firstNameError != null}
                    label="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Doe"}
                    disabled={saveLoading}
                    required
                    helperText={lastNameError}
                    error={lastNameError != null}
                    label="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"22389(M)"}
                    disabled={saveLoading}
                    // required
                    helperText={identificationNumberError}
                    error={identificationNumberError != null}
                    label="Identification Number"
                    value={identificationNumber}
                    onChange={(event) =>
                      setIdentificationNumber(event.target.value)
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: ABC Ltd."}
                    disabled={saveLoading}
                    helperText={companyError}
                    error={companyError != null}
                    label="Company"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Director"}
                    disabled={saveLoading}
                    helperText={roleError}
                    error={roleError != null}
                    label="Role"
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <span>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      defaultChecked={isInternational}
                      onChange={(event) => {
                        setIsInternational(event.target.checked);
                      }}
                    />
                    Is International
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <span>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      defaultChecked={receiveMarketing}
                      onChange={(event) => {
                        setReceiveMarketing(event.target.checked);
                      }}
                    />
                    Marketing Consent
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 1, Ave Maria"}
                    disabled={saveLoading}
                    // required
                    helperText={addressHouseError}
                    error={addressHouseError != null}
                    label="House & House Number"
                    value={addressHouse}
                    onChange={(event) => setAddressHouse(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Triq Sant Antnin"}
                    disabled={saveLoading}
                    // required
                    helperText={addressStreetError}
                    error={addressStreetError != null}
                    label="Street"
                    value={addressStreet}
                    onChange={(event) => setAddressStreet(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Mosta"}
                    disabled={saveLoading}
                    // required
                    helperText={addressCityError}
                    error={addressCityError != null}
                    label="City"
                    value={addressCity}
                    onChange={(event) => setAddressCity(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Malta"}
                    disabled={saveLoading}
                    // required
                    helperText={addressCountryError}
                    error={addressCountryError != null}
                    label="Country"
                    value={addressCountry}
                    onChange={(event) => setAddressCountry(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: MST 1234"}
                    disabled={saveLoading}
                    // required
                    helperText={addressPostcodeError}
                    error={addressPostcodeError != null}
                    label="Postcode"
                    value={addressPostcode}
                    onChange={(event) => setAddressPostcode(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl
              className={classes.inputType}
              error={leadError != null}
            >
              <InputLabel disabled={saveLoading}>
                Lead Source
              </InputLabel>
              <Select
                elevation={0}
                onChange={(event) => setLead(event.target.value)}
                value={lead}
                // required
                error={leadError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                <MenuItem value={"facebook"}>Facebook</MenuItem>
                <MenuItem value={"linkedIn"}>LinkedIn</MenuItem>
                <MenuItem value={"instagram"}>Instagram</MenuItem>
                <MenuItem value={"mumn"}>MUMN</MenuItem>
                <MenuItem value={"own research"}>Own Research</MenuItem>
                <MenuItem value={"word of mouth"}>Word of mouth</MenuItem>
              </Select>
              <FormHelperText>{leadError}</FormHelperText>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    // required
                    format="dd/MM/yyyy"
                    openTo="year"
                    views={["year", "month", "date"]}
                    margin="normal"
                    id="date-picker-inline"
                    label="Date of Birth"
                    value={dob}
                    helperText={dobError}
                    error={dobError != null}
                    onChange={(date) => {
                      setDob(date);
                    }}
                    onClick={() => setOpenDob(true)}
                    onError={(error) => setDobError(error.message)}
                    onOpen={() => setOpenDob(true)}
                    onClose={() => setOpenDob(false)}
                    open={openDob}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  className={classes.inputType}
                  error={genderError != null}
                >
                  
                  <InputLabel disabled={saveLoading} >
                    Gender
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setGender(event.target.value)}
                    value={gender}
                    required
                    error={genderError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                  </Select>
                  <FormHelperText>{genderError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.inputType}
                  error={campusError != null}>
                  <InputLabel required disabled={saveLoading}>
                    Campus
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setCampus(event.target.value)}
                    value={campus}
                    required
                    error={campusError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"Malta"}>Malta</MenuItem>
                    <MenuItem value={"Dubai"}>Dubai</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div></div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 79123123"}
                    disabled={saveLoading}
                    helperText={mobileError}
                    error={mobileError != null}
                    label="Mobile"
                    value={mobile}
                    onChange={(event) => setMobile(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: john@doe.com"}
                    disabled={saveLoading}
                    required
                    helperText={emailError}
                    error={emailError != null}
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
