import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllLeads from "./all-leads";
// import SingleLead from "./single-lead";

export default function () {
  return (
    <div>
      <Switch>
        <Route exact path="/leads/all" component={() => <AllLeads />} />
        {/* <Route path="/leads/all/:id" component={() => <SingleLead />} /> */}
      </Switch>
    </div>
  );
}
