import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let crumbs = [
        {name: 'Cohorts', path: 'cohorts'},
        {name: 'All Cohorts', path: 'cohorts/all'}
  ];

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
