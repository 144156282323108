import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { TextField, FormControl, Typography, Paper, Grid, Button, IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import { Delete } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";


import { createIndustry, deleteIndustry } from "repository/actions/data/dashboard/industries"

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    industries,
    getData,
    onPressDeleteNoteIdx
  } = props;
  const classes = useStyles();


  const [title, setTitle] = React.useState(null);
  const [saveLoading, setSaveLoading] = React.useState(false);



  const handleSaveIndustry = () => {
    if (
      title == null
    ) {
      toast.error("You have missing title for industry");

    } else {
      let data = {
        title: title,
      };
      setSaveLoading(true);
      toast.dark(`Creating Industry : ${data.title}`);
      createIndustry(data, user.idToken)
        .then((result) => {
          getData()

          setSaveLoading(false);
          toast.success(`Industry created : ${data.title}`);
          setTitle("");
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          console.log(error)
          setSaveLoading(false);
        });
    }
  };



  const handleDeleteIndustry = (item) => {

    setSaveLoading(true);
    toast.dark(`Deleting Industry : ${item.title}`);
    deleteIndustry(item._id, user.idToken)
      .then((result) => {
        getData()
        setSaveLoading(false);
        toast.success(`Industry deleted : ${item.title}`);
        setTitle("");
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
        console.log(error)
        setSaveLoading(false);
      });
  }



  return (
    <Section
      className={classes.root}
      title="Industries"
      loading={!industries}
      sectionDescription={"Manage all your industries."}
    >
      {industries != null &&
        <div>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <div>
                <div style={{flexGrow:1}}>
                  <FormControl className={classes.formControl} style={{width:"92%"}}>
                    <TextField
                      placeholder={"Title"}
                      disabled={saveLoading}
                      required
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      margin="normal"
                      className={classes.noteArea}
                    ></TextField>
                  </FormControl>

                </div>
                <Grid container style={{
                  justifyContent: "flex-end",
                  paddingRight: "70px"
                }}>
                  <Grid item xs={2}>
                    <Button onClick={() => { handleSaveIndustry() }} style={{ color: "white", backgroundColor: "#F3B903", padding: "7px 35px" }}>Save</Button>
                  </Grid>
                </Grid>
              </div>

            </Grid>
            <Grid item xs={8}>
              {industries.map((item, idx) => {
                return (
                  <Paper className={classes.miniSection}>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography className={classes.fontBold}>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          style={{ padding: "5px" }}
                          aria-label="delete"
                          color="primary"
                          onClick={(event) => {
                            handleDeleteIndustry(item);
                          }}
                        >
                          <Delete
                            style={{ fontSize: "20px" }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                )
              })}
            </Grid>
          </Grid>
        </div>
      }
    </Section>
  );
});
