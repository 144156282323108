import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider";

const ContextProps = {
  applicants: null,
  setApplicants: {},
  getAllApplicants: {},
  getApplicant:{},
  editApplicant:{},
  createNewApplicant: {},
  deleteApplicant: {},
  bulkExportApplicants:{}
};

export const AllApplicantsContext = React.createContext(ContextProps);

export const AllApplicantsProvider = ({ children }) => {
  const [applicants, setApplicants] = useState(null);
  const { getIdToken, refreshToken } = useContext(IdTokenContext);

  const getAllApplicants = (token) => {
    return new Promise(async (resolve, reject) => {
      try {

        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/applicants").find({
          query: {
            firebaseToken: token,
          },
        });
        setApplicants(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const getApplicant = (id,token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/applicants").get(id, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editApplicant = (id,data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/applicants").patch(id, data, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewApplicant = async (applicantData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // force refresh token
        await refreshToken();

        getIdToken().then(async (token) => {
          let result = await apiClient
            .service("v1/applicants")
            .create(applicantData, {
              query: {
                firebaseToken: token,
              },
            });
          if (applicants == null) {
            setApplicants(result);
          } else {
            let newData = applicants;
            newData.push(result);
            setApplicants(newData);
          }
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteApplicant = async (applicantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/applicants").remove(applicantId, {
          query: {
            provider:"convertToStudent",
            firebaseToken: token,
          },
        });

        let newData = applicants;

        for (const [i, v] of newData.entries()) {
          if (v._id === applicantId) {
            newData.splice(i, 1);
          }
        }
        setApplicants(newData);

        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };


  const bulkExportApplicants = async (filters) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/bulk").find({
            query: {
              firebaseToken: token,
              provider: "bulk-export-applicants"
            },
          });
          
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllApplicantsContext.Provider
      value={{
        applicants,
        setApplicants,
        getAllApplicants,
        getApplicant,
        editApplicant,
        createNewApplicant,
        deleteApplicant,
        bulkExportApplicants
      }}
    >
      {children}
    </AllApplicantsContext.Provider>
  );
};
