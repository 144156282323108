import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Paper, Typography } from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { course, setCourse, user } = props;
  const classes = useStyles();

  let title = "...";
  let description = "";
  let mqfLevel = "";
  let referenceNumber = "";

  if (course) {
    title = `${course.title}`;
    description = course.description;
    mqfLevel = course.mqfLevel;
    referenceNumber = course.referenceNumber;
  }
  return (
    <Section
      title={title}
      loading={!course}
      sectionDescription={"Information about the course."}
    >
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Title</Typography>
        <Typography className={classes.fontRegular}>{title}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Description</Typography>
        <Typography className={classes.fontRegular}>{description}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>MQF Level</Typography>
        <Typography className={classes.fontRegular}>{mqfLevel}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Reference Number</Typography>
        <Typography className={classes.fontRegular}>
          {referenceNumber}
        </Typography>
      </Paper>
    </Section>
  );
});
