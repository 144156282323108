import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { loading, lecturer, lectures, sectionDescription } = props;

  return (
    <Section
      title="Lectures"
      loading={loading}
      sectionDescription={sectionDescription}
    >
      {lectures.length > 0 && (
        <TableContainer className={classes.miniSection} component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Title</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Time</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Lecturer
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Location
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lectures.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="D MMMM, YYYY" date={row.lectureDate} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="HH:ss" date={row.lectureTime} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.lecturer.firstName} {row.lecturer.lastName}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.location.title}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {lectures.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          There are no associated lectures with this lecturer.
        </Typography>
      )}
    </Section>
  );
}
