import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllStudents from "./all-students";
import SingleStudent from "./single-student";

export default function (props) {
  const {campus} = props

  return (
    <div>
      <Switch>
        <Route exact path="/students/all-malta" component={() => <AllStudents campus={"malta"}/>} />
        <Route exact path="/students/all-dubai" component={() => <AllStudents campus={"dubai"}/>} />

        <Route path="/students/all-malta/:id" component={() => <SingleStudent />} />
        <Route path="/students/all-dubai/:id" component={() => <SingleStudent />} />


        {/* <Route path="/students/all-dubai/:id" component={() => <SingleStudent />} /> */}

      </Switch>
    </div>
  );
}
