import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Paper,
  Grid,
  Link,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";

import { styled } from "@material-ui/core/styles";

// import { getClient } from "repository/actions/data/dashboard/clients"
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    vacancy,
    vacancyId,
    user,
    loading,
    headCounts,
    editVacancy,
    setVacancy,
    setShowEditVacancyDialogue,
  } = props;
  const classes = useStyles();

  const { getClient } = useContext(AllClientsContext);

  const [title, setTitle] = useState("Vacancy");
  const [headCountPercentage, setHeadCountPercentage] = useState("Vacancy");
  const [positionFilledLabel, setPositionFilledLabel] =
    useState("Currently Open");

  const [client, setClient] = useState(null);

  useEffect(() => {
    if (vacancy) {
      setTitle(vacancy.positionName);
      getClient(vacancy.clientId, user.idToken).then((result) => {
        setClient(result);
      });

      if (vacancy.filledAt != null) {
        let date = new Date(vacancy.filledAt);
        date =
          date.getDate() +
          " " +
          date.toLocaleString("default", { month: "long" }) +
          " " +
          date.getFullYear();
        setPositionFilledLabel("Closed on " + date);
      } else {
        setPositionFilledLabel("Currently Open");
      }
    }
  }, [vacancy]);

  useEffect(() => {
    if (vacancy && headCounts) {
      setHeadCountPercentage(
        headCounts.toString() + " / " + vacancy.headCount.toString()
      );
    }
  }, [vacancy, headCounts]);

  const handleIsPublished = (event) => {
    let status = "Published";

    if (!event.target.checked) {
      status = "Unpublished";
    }
    let payload = {
      isPublished: event.target.checked,
      status: status,
    };

    editVacancy(vacancyId, payload, user.idToken)
      .then((result) => {
        setVacancy(result);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const handleIsFilled = (event) => {
    let filledAt = null;
    if (event.target.checked) {
      filledAt = moment().valueOf();
    }

    let payload = {
      filledAt: filledAt,
    };

    editVacancy(vacancyId, payload, user.idToken)
      .then((result) => {
        setVacancy(result);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#95CC97",
      "&:hover": {
        backgroundColor: "#95CC9780",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#95CC97",
    },
  }));

  return (
    <Section
      title={title}
      loading={!vacancy}
      chipTitle={headCountPercentage}
      actionName="Edit Vacancy"
      handleAction={() => {
        setShowEditVacancyDialogue(true);
      }}
      sectionDescription={"Detailed information about the cohort."}
    >
      {vacancy != null && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Published</Typography>
                <GreenSwitch
                  defaultChecked
                  className={classes.switch}
                  color="#95CC97"
                  onClick={(event) => {
                    handleIsPublished(event);
                  }}
                  checked={vacancy.isPublished}
                />
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Position Name
                </Typography>
                <Typography className={classes.fontRegular}>
                  {vacancy.positionName}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Industry</Typography>
                <Typography className={classes.fontRegular}>
                  {vacancy.industry}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Salary</Typography>
                <Typography className={classes.fontRegular}>
                  €{vacancy.minSalary}
                  {" - "}€{vacancy.maxSalary}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Location</Typography>
                <Typography className={classes.fontRegular}>
                  {vacancy.location}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Contract Type
                </Typography>
                <Typography className={classes.fontRegular}>
                  {vacancy.contractType}
                </Typography>
              </Paper>

              <Paper className={classes.miniSection}>
                <Link
                  underline="always"
                  onClick={() => {
                    window.location.href = `/resourcing-clients/${client._id}`;
                  }}
                >
                  <Typography className={classes.fontBold}>Client</Typography>
                  {client && (
                    <Typography className={classes.fontRegular}>
                      {client.title}
                    </Typography>
                  )}
                </Link>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Position Filled?
                </Typography>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      className={classes.switch}
                      color="#95CC97"
                      onClick={(event) => {
                        handleIsFilled(event);
                      }}
                      checked={vacancy.filledAt}
                    />
                  }
                  label={positionFilledLabel}
                />
              </Paper>
              {/* <Button className={classes.button} style={{width: "200px"}}>
                Edit Vacancy Description
              </Button> */}
            </Grid>
          </Grid>
        </div>
      )}
    </Section>
  );
});
