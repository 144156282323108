import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions
import { deleteDocument } from "repository/actions/data/dashboard/documents";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, itemId, documents, setDocuments, user } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteItem = () => {
      if (user && user != null) {
        setSaveLoading(true);

        deleteDocument(itemId, documents, setDocuments, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success("Document successfully deleted");
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected document"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteItem()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this document?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
