
import React, { useState, useContext } from "react";
import apiClient from "utils/api";

import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  modules: null,
  setModules: {},
  getAllModules: {},
  createNewModule: {},
  editModule: {},
  deleteModule: {},
  getCourseModules: {},
};

export const AllModulesContext = React.createContext(ContextProps);

export const AllModulesProvider = ({ children }) => {
  const [modules, setModules] = useState(null);

  const { getIdToken } = useContext(IdTokenContext)

  const getAllModules = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/modules").find({
            query: {
              firebaseToken: token,
            },
          });

          setModules(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const getCourseModules = (courseId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/modules").find({
            query: {
              assignedCourseId: courseId,
              firebaseToken: token,
            },
          });

          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewModule = async (moduleData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/modules").create(moduleData, {
            query: {
              firebaseToken: token,
            },
          });

          if (modules == null) {
            setModules(result);
          } else {
            let newData = modules
            newData.push(result)
            setModules(newData);
          }

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const editModule = async (data, moduleId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          await apiClient
            .service("v1/modules")
            .patch(moduleId, modules, {
              query: {
                firebaseToken: token,
              },
            });

          let newData = modules

          for (const [i, v] of newData.entries()) {
            if (v._id === moduleId) {
              newData[i] = data;
            }
          }
          setModules(newData);

          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteModule = async (moduleId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/modules").remove(moduleId, {
            query: {
              firebaseToken: token,
            },
          });


          let newData = modules

          for (const [i, v] of newData.entries()) {
            if (v._id === moduleId) {
              newData.splice(i, 1);
            }
          }
          setModules(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllModulesContext.Provider
      value={{
        modules,
        setModules,
        getAllModules,
        createNewModule,
        editModule,
        deleteModule,
        getCourseModules
      }}
    >
      {children}
    </AllModulesContext.Provider>
  );
};
