import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import Panel from "components/panel";

import {
  Typography,
  Button,
  IconButton,
  Divider,
  Grid,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleCreateExtensionForm,
    extensions,
    editStudent,
    getStudent,
    setStudent,
    onPressedDeleteItem,
    sectionDescription,
    student,
  } = props;


  const handleResolveExtension = async(row, index) => {

    // let 

    var ext = extensions.find(obj => {
      return obj.cohortId === row.cohortId
    })

    let resolvedExtensions = student.resolvedExtensions ? student.resolvedExtensions:[]
    resolvedExtensions.push(extensions[index])


    let newExtensions = student.extensions ? student.extensions:[]

    newExtensions.splice(index,1)


    let payload = {
      extensions:newExtensions,
      resolvedExtensions:resolvedExtensions
    }


    await editStudent(student._id, payload).then(()=>{
      getStudent(student._id).then((result)=>{
        setStudent(result)
      })
    })
  }

  return (
    <Section
      title={`Resolved Extensions`}
      loading={loading}
      sectionDescription={
        sectionDescription +
        ` Student Number - ${student ? student.studentNumber : ""}`
      }
    >
      {extensions.length > 0 && (
        <div>
          <Divider />

          <Grid container spacing={2}>
            {extensions.map((row, index) => {
              return (
                <Grid item xs={12}>
                  <div title={row?row.type:"N/A"} style={{ margin: "40px 0" }}>
                    <Grid container spacing={4}>
                      <Grid item xs={2} style={{ padding: 20 }}>
                        <Typography align="left">
                          <b>Cohort</b>
                        </Typography>
                        <Typography>{row?row.cohortTitle:"N/A"}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography align="left">
                          <b>Title</b>
                        </Typography>
                        <Typography>{row?row.title:"N/A"}</Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography align="left">
                          <b> Date Added</b>
                        </Typography>
                        <Typography>
                          <Moment format="D MMMM, YYYY">{row?row.createdAt:"N/A"}</Moment>
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography align="left">
                          <b>Original Date</b>
                        </Typography>
                        <Typography>
                          <Moment format="D MMMM, YYYY">{row?row.from:"N/A"}</Moment>
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography align="left">
                          <b>Revised Due Date</b>
                        </Typography>
                        <Typography>
                          <Moment format="D MMMM, YYYY">{row?row.to:"N/A"}</Moment>
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="left">
                          <b>Duration</b>
                        </Typography>
                        <Typography>{row?row.duration:"N/A"}</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={(event) => {
                            onPressedDeleteItem(index, row.objectID);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography>
                          <b>Reason</b>
                        </Typography>
                        <Typography>{row?row.reason:"N/A"}</Typography>
                      </Grid>

                     
                    </Grid>
                  </div>
                  <Divider />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      {extensions.length === 0 && (
        <Typography className={classes.emptyText} align="left">
          There are no associated extensions with this student.
        </Typography>
      )}
    </Section>
  );
}
