import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleDetailsView from "./single-details-cost-view";


export default withRouter(function (props) {
  let {
    loading,
    user,
    months,
    setMonths,
    years,
    setYears,
    programme,
    setProgramme,
    isNewProgramme,
    editProgramme,
    createNewProgramme,

    moduleTotalCost,
    studentTotalCost,
    programmeTotalCosts
  } = props;



  return (
    <div>
      <SingleDetailsView
        loading={loading}
        years={years}
        setYears={setYears}
        months={months}
        setMonths={setMonths}
        isNewProgramme={isNewProgramme}
        programme={programme}
        setProgramme={setProgramme}
        user={user}
        editProgramme={editProgramme}
        createNewProgramme={createNewProgramme}

        moduleTotalCost={moduleTotalCost}
        studentTotalCost={studentTotalCost}
        programmeTotalCosts={programmeTotalCosts}
      />

    </div>
  );
});
