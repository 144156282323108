

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  programmes: null,
  setProgramme: {},
  getProgrammes: {},
  getProgramme: {},
  loadingProgramme: false,
  deleteProgramme: {},
  createNewProgramme: {},
  editProgramme: {},
};

export const AllProgrammesContext = React.createContext(ContextProps);

export const AllProgrammesProvider = ({ children }) => {
  const [programmes, setProgrammes] = useState(null);
  const [loadingProgrammes, setLoadingProgrammes] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext)



  const getProgrammes = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProgrammes(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/programme-costings").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProgrammes(false);
          setProgrammes(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const getProgramme = (programmeId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingProgrammes(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/programme-costings").get(programmeId, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProgrammes(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewProgramme = async (programme, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/programme-costings").create(
            { ...programme },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editProgramme = async (programmeId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updated = await apiClient
            .service("v1/programme-costings")
            .patch(programmeId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve(updated);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteProgramme = async (programmeId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/programme-costings").remove(programmeId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  


  return (
    <AllProgrammesContext.Provider
      value={{
        programmes,
        setProgrammes,
        createNewProgramme,
        getProgrammes,
        getProgramme,
        editProgramme,
        deleteProgramme,
        loadingProgrammes
      }}
    >
      {children}
    </AllProgrammesContext.Provider>
  );
};
