import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  Typography,
  Select,
  FormControl,
  InputLabel,
  Paper,
  TextField,
  MenuItem,
  Grid,
  Chip,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import PropTypes from "prop-types";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  Toolbar,
  Resources,
  AppointmentForm,
} from "@devexpress/dx-react-scheduler-material-ui";

import { AllTimesheetsContext } from "repository/stores/dashboard/timesheets/timesheets-all-provider";
import { AllLeavesContext } from "repository/stores/dashboard/leaves/leaves-all-provider";

// import { findTimestampByUser, findLeavesByUser, createLeave } from "repository/actions/data/dashboard/timesheets"

import legend from "./legend.png";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import StyleOutlinedIcon from "@material-ui/icons/StyleOutlined";
import SubjectIcon from "@material-ui/icons/Subject";

import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        //   <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
        //   </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    user,
    authUser,
    hourInfo,
    setHourInfo,
    setLeaveTaken,
    leaveRemaining,
    setLeaveRemaining,
  } = props;

  const { findLeavesByUser, createLeave, leaveRequestHandling } =
    useContext(AllLeavesContext);
  const { findTimestampByUser } = useContext(AllTimesheetsContext);

  const currentDate = new Date().toISOString().slice(0, 10);

  const [schedulerData, setSchedulerData] = useState(null);
  const [timesheetData, setTimesheetData] = useState(null);

  const [currentMonth, setCurrentMonth] = useState(
    currentDate.split("-")[1] + "-" + currentDate.split("-")[0]
  );


  const [leaveDate, setLeaveDate] = useState(null);
  const [leaveHours, setLeaveHours] = useState(null);
  const [leaveMinutes, setLeaveMinutes] = useState(null);

  const [leaveType, setLeaveType] = useState(null);
  const [leaveReason, setLeaveReason] = useState(null);

  const [startLeaveTime, setStartLeaveTime] = useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const getData = async () => {
    let timesheetMap = null;
    let leavesMap = null;

    await findTimestampByUser(currentMonth, user._id, authUser.idToken).then(
      (result) => {
        timesheetMap = result.timesheetMap;
        setTimesheetData(timesheetMap);
        setHourInfo({
          ...hourInfo,
          working: result.info.working,
          break: result.info.break,
        });
      }
    );

    await findLeavesByUser(currentMonth, user._id, authUser.idToken).then(
      (result) => {
        leavesMap = result.leavesMap;
        setLeaveTaken(result.info.leaveTaken);
      }
    );

    setLeaveRemaining(user.leave);

    let newSchedulerData = timesheetMap.concat(leavesMap);
    setSchedulerData(newSchedulerData);
  };

  useEffect(() => {
    if (authUser && user) {
      getData();
    }
  }, [currentMonth, user]);

  const resourcesData = [
    {
      id: 1,
      color: "#F16690",
    },
    {
      id: 2,
      color: "#FFAE44",
    },
    {
      id: 3,
      color: "#2DC5A2",
    },
    {
      id: 4,
      color: "#E9EAEC",
    },
    {
      id: 5,
      color: "#727C85",
    },
    {
      id: 6,
      color: "#8b0000 ",
    },
  ];
  const resources = [
    {
      fieldName: "duration",
      instances: resourcesData,
    },
  ];

  const Appointment = ({ children, style, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        // backgroundColor: '#FFC107',
        borderRadius: "8px",
        textAlign: "center",
        fontWeight: "bold",
        width: "80%",
        maxHeight: "40px",
        display: "flex",
        paddingTop: "2px",
        alignItems: "center",
        justifyContent: "center",
        margin: " 0 10%",
      }}
    >
      {children}
    </Appointments.Appointment>
  );

  const handleLeaveSubmit = async () => {
    if (
      leaveType == "Paid" &&
      leaveHours + leaveMinutes / 10 > leaveRemaining
    ) {
      toast.error("Leave Request denied");
      toast.error(
        "You do not have enough leave remaining. Please select a different type of leave or reduce hours."
      );
    } else {
      let day = leaveDate.getDate().toString();
      let month = (leaveDate.getMonth() + 1).toString();

      if (month.length == 1) {
        month = "0" + (leaveDate.getMonth() + 1).toString();
      }
      if (day.length == 1) {
        day = "0" + leaveDate.getDate().toString();
      }


      let duration = leaveHours + leaveMinutes / 10



      if(durationValue == 1){
        let endRangeDate = new Date(endDate)
        var start = moment(leaveDate.getFullYear() + "-" + month + "-" + day);
        var end = moment(endRangeDate);
        let days = end.diff(start, 'days')   

        duration =(days+1) * 8
      }

      let reason = document.getElementById("leave-reason").value;
      let payload = {
        userId: user._id,
        date: leaveDate.getFullYear() + "-" + month + "-" + day,
        duration:duration,
        type: leaveType,
        reason: reason,
        status: "pending",
        startTime: startLeaveTime,
        isRange: durationValue == 1? true: false,
        endDate: endDate? endDate: null
      };
      

      await createLeave(payload, authUser.idToken).then(() => {
        toast.success(`Leave Pending Approval`);
        setLeaveType(null);
        setLeaveHours(null);
        setLeaveMinutes(null);
      });

      let leavesMap = [];
      await findLeavesByUser(currentMonth, user._id, authUser.idToken).then(
        (result) => {
          leavesMap = result.leavesMap;
          // setLeaveTaken(result.info.leaveTaken)
          // setLeaveRemaining(user.leave)
        }
      );

      // let oldSchedulerData = schedulerData
      let newSchedulerData = timesheetData.concat(leavesMap);
      setSchedulerData(newSchedulerData);

      // await getSingleUser(user._id, authUser.idToken).then((result) => {
      //     setLeaveRemaining(result.leave)
      // })
    }
  };

  const verifyLeaveRemaining = (value) => {
    if (value == "Paid" && leaveHours + leaveMinutes / 10 > leaveRemaining) {
      toast.error(
        "You do not have enough leave remaining. Please select a different type of leave or reduce hours."
      );
    } else {
      setLeaveType(value);
    }
  };

  const handleClick = (id) => {
    let chips = document.getElementsByClassName("timeChips");

    for (let c of chips) {
      if (c.id != id) {
        c.style.backgroundColor = "#ffffff";
        c.style.color = "#000000";
      }
    }

    setStartLeaveTime(id);

    setTimeout(() => {
      let chip = document.getElementById(id);
      chip.style.backgroundColor = "#564181";
      chip.style.color = "#ffffff";
    }, 200);
  };

  const handleCancelLeave = async (leaveId, currentStatus) => {

    let newStatus = ""
    if(currentStatus == "pending"){
      newStatus = "cancelled-pending"
    }

    if(currentStatus == "approved"){
      newStatus = "cancelled-approved"
    }



    let payload = {
      status: "cancelled",
      cancelled: newStatus,
      email: user.email
    };

    await leaveRequestHandling(leaveId, payload, user.idToken)
      .then(async () => {
        // await getData()
        toast.success("Leave Cancelled");
        window.location.reload();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const leaveTypes = [
    { id: "1", title: "Paid" },
    { id: "2", title: "Unpaid" },
    { id: "3", title: "Sick" },
    { id: "4", title: "Marriage" },
    { id: "5", title: "Parental" },
    { id: "6", title: "Bereavement" },
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [durationValue, setValue] = React.useState(0);

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    setLeaveDate(appointmentData.startDate);
    return (
      <div>
        <Tabs
          value={durationValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Hours" {...a11yProps(0)} />
          <Tab label="Multiple Days" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={durationValue} index={0} style={{ padding: "0 30px" }}>
          <div>
            <Grid container>
              {/* <Grid container> */}
              <Grid item xs={1}>
                <QueryBuilderIcon className={classes.inputIcon} />
              </Grid>

              <Grid item xs={9}>
                <FormControl
                  variant="outlined"
                  sx={{ m: 1 }}
                  className={classes.appointmentFormTimeInput}
                >
                  <InputLabel>Hours</InputLabel>
                  <Select
                    id="leave-hoursn"
                    variant="outlined"
                    value={leaveHours}
                    onChange={(event) => {
                      setLeaveHours(event.target.value);
                    }}
                    label="Leave Duration"
                  >
                    <MenuItem value={1}>1 &nbsp;&emsp; Hour</MenuItem>
                    <MenuItem value={2}>2 &emsp; Hours</MenuItem>
                    <MenuItem value={3}>3 &emsp; Hours</MenuItem>
                    <MenuItem value={4}>4 &emsp; Hours</MenuItem>
                    <MenuItem value={5}>5 &emsp; Hours</MenuItem>
                    <MenuItem value={6}>6 &emsp; Hours</MenuItem>
                    <MenuItem value={7}>7 &emsp; Hours</MenuItem>
                    <MenuItem value={8}>8 &emsp; Hours</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{ m: 1 }}
                  className={classes.appointmentFormTimeInput}
                >
                  <InputLabel>Minutes</InputLabel>
                  <Select
                    id="leave-minutes"
                    variant="outlined"
                    value={leaveMinutes}
                    onChange={(event) => {
                      setLeaveMinutes(event.target.value);
                    }}
                    label="Leave Duration"
                  >
                    <MenuItem value={0}>0 &emsp; mins</MenuItem>
                    <MenuItem value={5}>30 &ensp; mins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.leaveDuration}>
                  {`${leaveHours == null ? 0 : leaveHours}.${
                    leaveMinutes == null ? 0 : leaveMinutes
                  }hrs`}{" "}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={durationValue} index={1} style={{ padding: "0 30px" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container style={{ justifyContent: "end", alignItems:"end" }}>
              <Grid item xs={4}>
                <p>From {appointmentData.startDate.toString().substring(0,10)} till </p>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={endDate}
                    minDate = {appointmentData.startDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
               <Typography variant="caption" style={{color:"red"}}>
                  ** Do not select weekends **
                </Typography>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </TabPanel>
        <Grid container className={classes.appointmentForm}>
          <Grid ite xs={1}>
            <StyleOutlinedIcon className={classes.inputIcon} />
          </Grid>
          <Grid item xs={11}>
            <FormControl
              variant="outlined"
              sx={{ m: 1 }}
              className={classes.appointmentFormInput}
            >
              <InputLabel>Leave Type</InputLabel>
              <Select
                id="leave-type"
                variant="outlined"
                value={leaveType}
                onChange={(event) => {
                  verifyLeaveRemaining(event.target.value);
                }}
                label="Leave Type"
              >
                {leaveTypes.map((item) => {
                  if (item.title == "Paid") {
                    return (
                      <MenuItem disabled={(user.leave = 0)} value={item.title}>
                        Vacation
                      </MenuItem>
                    );
                  } else {
                    return <MenuItem value={item.title}>{item.title}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid ite xs={1}>
            <SubjectIcon className={classes.inputIcon} />
          </Grid>
          <Grid item xs={11}>
            <FormControl
              variant="standard"
              sx={{ m: 1 }}
              className={classes.appointmentFormInput}
            >
              <TextField
                placeholder="Reason (Optional) ..."
                multiline
                variant="outlined"
                rows={5}
                maxRows={4}
                id="leave-reason"
              />
            </FormControl>
          </Grid>
          <Typography className={classes.leaveDuration}>
            Select Leave Starting Time *
          </Typography>

          <Grid container>
            <ul className={classes.timeList}>
              <Chip
                label="08:00"
                id="08:00"
                onClick={() => handleClick("08:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="08:30"
                id="08:30"
                onClick={() => handleClick("08:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="09:00"
                id="09:00"
                onClick={() => handleClick("09:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="09:30"
                id="09:30"
                onClick={() => handleClick("09:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="10:00"
                id="10:00"
                onClick={() => handleClick("10:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="10:30"
                id="10:30"
                onClick={() => handleClick("10:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="11:00"
                id="11:00"
                onClick={() => handleClick("11:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
            </ul>

            <ul className={classes.timeList}>
              <Chip
                label="11:30"
                id="11:30"
                onClick={() => handleClick("11:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="12:00"
                id="12:00"
                onClick={() => handleClick("12:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="12:30"
                id="12:30"
                onClick={() => handleClick("12:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="13:00"
                id="13:00"
                onClick={() => handleClick("13:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="13:30"
                id="13:30"
                onClick={() => handleClick("13:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="14:00"
                id="14:00"
                onClick={() => handleClick("14:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="14:30"
                id="14:30"
                onClick={() => handleClick("14:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
            </ul>

            <ul className={classes.timeList}>
              <Chip
                label="15:00"
                id="15:00"
                onClick={() => handleClick("15:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="15:30"
                id="15:30"
                onClick={() => handleClick("15:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="16:00"
                id="16:00"
                onClick={() => handleClick("16:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="16:30"
                id="16:30"
                onClick={() => handleClick("16:30")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
              <Chip
                label="17:00"
                id="17:00"
                onClick={() => handleClick("17:00")}
                className="timeChips"
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
            </ul>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Content = ({ children, appointmentData, ...restProps }) => {
    let today = new Date();
    let leaveDate = new Date(appointmentData.startDate.split("T")[0]);
    return (
      <div className={classes.appointmentTooltip}>
        <Grid container alignItems="center">
          <Grid item xs={12} className={classes.textCenter}>
            <h2>{appointmentData.title}</h2>
          </Grid>
          {appointmentData.reason != "" && (
            <Grid item xs={10}>
              <h3>Reason:</h3>
              {appointmentData.reason}
            </Grid>
          )}
          {appointmentData.punchInReason && (
            <Grid item xs={10}>
              <h3>Punch In Reason:</h3>
              {appointmentData.punchInReason}
            </Grid>
          )}
          {appointmentData.punchOutReason && (
            <Grid item xs={10}>
              <h3>Punch Out Reason:</h3>
              {appointmentData.punchOutReason}
            </Grid>
          )}
        </Grid>
        {((appointmentData.status != "cancelled" && (today < leaveDate)) || (authUser.roles.indexOf("admin") >=0)  )&& (
          <div>
            <Button
              style={{ color: "#8b0000", border: "solid 1px #8b0000" }}
              onClick={() => {
                handleCancelLeave(appointmentData._id, appointmentData.status);
              }}
            >
              Delete
            </Button>
          </div>
        )}
        
      </div>
    );
  };

  const currentDateChange = (currentDate) => {
    setSchedulerData([]);
    var dateobj = new Date(currentDate);
    let data = dateobj.toISOString();
    
    let prefix = ""
    if((dateobj.getMonth()+1).toString().length == 1){
      prefix = "0"
    }
    
    setCurrentMonth(prefix+(dateobj.getMonth()+1).toString() + "-" + dateobj.getFullYear());
  };

  const isDisabled = () => {
    if (
      leaveHours == "" ||
      leaveType == null ||
      startLeaveTime == null ||
      leaveReason == ""
    ) {
      return true;
    }
    return false;
  };

  const CommandButton = ({ id, ...restProps }) => {
    return (
      <AppointmentForm.CommandButton
        id={id}
        {...restProps}
        disabled={isDisabled()}
      />
    );
  };

  const onCommitChanges = () => {
    handleLeaveSubmit();
  };

  const time = [
    {
      duration: 1,
      endDate: "2022-04-26T09:20:30",
      punchInReason: "",
      startDate: "2022-04-26T09:20:00",
      title: "9:20: - Missing",
    },
  ];

  return (
    <Paper loading={loading} style={{ padding: 20, marginBottom: 30 }}>
      <Grid container>
        <Grid item xs={6}>
          {user && (
            <Typography className={classes.title} display="inline">
              {user.firstName + " " + user.lastName}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
          <img src={legend} width="500" />
        </Grid>
      </Grid>

      {schedulerData && (
        <Scheduler data={schedulerData} className={classes.scheduler}>
          <ViewState
            defaultCurrentDate={currentDate}
            defaultCurrentViewName="Month"
            onCurrentDateChange={currentDateChange}
          />
          <MonthView />
          <Toolbar />
          <DateNavigator />
          <TodayButton />

          <EditingState
            onCommitChanges={onCommitChanges}
            // addedAppointment={addedAppointment}
            // onAddedAppointmentChange={onAddedAppointmentChange}
          />
          <Appointments
            appointmentComponent={Appointment}
            appointmentType="hortizontal"
          />
          <AppointmentTooltip contentComponent={Content} showCloseButton />
          <AppointmentForm
            basicLayoutComponent={BasicLayout}
            commandButtonComponent={CommandButton}
          />
          <Resources data={resources} mainResourceName="duration" />
        </Scheduler>
      )}
    </Paper>
  );
}
