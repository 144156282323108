import React, { useState, useEffect, useContext } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Button,
  Grid,
  Badge,
  Divider,
} from "@material-ui/core";

import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  Toolbar,
  Resources,
  AppointmentForm,
} from "@devexpress/dx-react-scheduler-material-ui";

import styles from "routes/dashboard/dashboard-styles";
import Panel from "components/panel";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    user,
    consultant,
    getProposals,
    getActiveProjects,
    setSelectedDate,
    setShowProposalTimeDialogue,
    setShowProjectTimeDialogue,
    findProjectTimingsbyConsultant,
    findProposalTimingsbyConsultant,
    findActiveProjectsbyConsultant,
    findCompleteProjectsbyConsultant,
  } = props;

  const currentDate = new Date().toISOString().slice(0, 10);

  const [consultantProjects, setConsultantProjects] = useState([]);
  const [consultantCompletedProjects, setConsultantCompletedProjects] =
    useState([]);

  const [proposalMap, setProposalMap] = useState(null);

  const [schedulerData, setSchedulerData] = useState([]);
  const [proposalTotal, setProposalTotal] = useState(null);
  const [projectTotal, setProjectTotal] = useState(null);

  const [currentMonth, setCurrentMonth] = useState(
    currentDate.split("-")[1] + "-" + currentDate.split("-")[0]
  );

  const projectCodes = [
    {
      id: 0,
      color: "#417D7A",
    },

    {
      id: 1,
      color: "#C97C5D",
    },
    {
      id: 2,
      color: "#56638A",
    },
    {
      id: 3,
      color: "#798478",
    },
    {
      id: 4,
      color: "#F3B700",
    },
    {
      id: 5,
      color: "#A23E48",
    },
    {
      id: 6,
      color: "#8FB571",
    },
    {
      id: 7,
      color: "#907074",
    },
    {
      id: 8,
      color: "#907074",
    },
    {
      id: 9,
      color: "#907074",
    },
    {
      id: 10,
      color: "#DA667B",
    },
    {
      id: 11,
      color: "#9d9c85",
    },
    {
      id: 12,
      color: "#f1e0c5",
    },
  ];
  const projects = [
    {
      fieldName: "projectCode",
      instances: projectCodes,
    },
  ];

  const getCalendarData = async () => {
    let projectTimings = await findProjectTimingsbyConsultant(
      currentMonth,
      consultant._id
    );
    let proposalTimings = await findProposalTimingsbyConsultant(
      currentMonth,
      consultant._id
    );

    let timeingsMap = projectTimings.timingsMap.concat(
      proposalTimings.timingsMap
    );
    setSchedulerData(timeingsMap);
    setProposalTotal(proposalTimings.proposalTotal);
    setProjectTotal(projectTimings.projectTotal);
  };

  const getProposalMap = async () => {
    let proposals = await getProposals();
    let newProposalMap = {};
    for (let p of proposals) {
      newProposalMap[p._id] = p;
    }
    setProposalMap(newProposalMap);
  };

  useEffect(() => {
    if (consultant && user) {
      getCalendarData();

      if (consultant.email == "sandy@ideamalta.com") {
        getActiveProjects().then((result) => {
          setConsultantProjects(result.data);
        });
      } else {
        findActiveProjectsbyConsultant(consultant._id).then((res) => {
          setConsultantProjects(res.data);
        });
        findCompleteProjectsbyConsultant(consultant._id).then((res) => {
          setConsultantCompletedProjects(res.data);
        });
      }

      getProposalMap();
    }
  }, [currentMonth, consultant, user]);

  const Appointment = ({ children, style, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        borderRadius: "8px",
        textAlign: "center",
        fontWeight: "bold",
        width: "90%",
        maxHeight: "35px",
        display: "flex",
        paddingTop: "2px",
        alignItems: "center",
        justifyContent: "center",
        margin: " 0 5%",
      }}
    >
      {children}
    </Appointments.Appointment>
  );

  const StyledBadge = styled(Badge)(({ theme, index }) => ({
    "& .MuiBadge-badge": {
      left: -10,
      top: 12,
      padding: "0 4px",
      backgroundColor: projectCodes[index > 11 ? 11 : index].color,
    },
  }));

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    setSelectedDate(appointmentData.startDate);
    return (
      <Grid container>
        <Grid item xs={6}>
          <div
            onClick={() => setShowProjectTimeDialogue(true)}
            style={{
              width: 200,
              height: 200,
              borderRadius: "20px",
              border: "1px solid grey",
              margin: 30,
              paddingTop: 30,
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "30px" }}>Project</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            onClick={() => setShowProposalTimeDialogue(true)}
            style={{
              width: 200,
              height: 200,
              borderRadius: "20px",
              border: "1px solid grey",
              margin: 30,
              paddingTop: 30,
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "30px" }}>Proposal</p>
          </div>
        </Grid>
      </Grid>
    );
  };

  const Content = ({ children, appointmentData, ...restProps }) => {
    if (appointmentData.proposalId) {
      let id = appointmentData.proposalId;
      return (
        <div className={classes.appointmentTooltip}>
          <h3>{proposalMap[id].title}</h3>
          <h4>{proposalMap[id].clientTitle}</h4>
          <h4>{appointmentData.title}</h4>
        </div>
      );
    }
    let title = appointmentData.title.split("-");
    return (
      <div className={classes.appointmentTooltip}>
        <h2>{title[0]}</h2>
        <h3>{title[1]}</h3>
      </div>
    );
  };

  const currentDateChange = (currentDate) => {
    setSchedulerData([]);
    var dateobj = new Date(currentDate);
    let data = dateobj.toISOString();
    setCurrentMonth(data.split("-")[1] + "-" + data.split("-")[0]);
  };

  const isDisabled = () => {
    return false;
  };

  const CommandButton = ({ id, ...restProps }) => {
    return (
      <AppointmentForm.CommandButton
        id={id}
        {...restProps}
        disabled={isDisabled()}
      />
    );
  };

  return (
    <div>
      <Panel loading={loading} style={{ padding: 20, marginBottom: 30 }}>
        <Grid container>
          <Grid item xs={6}>
            {consultant && (
              <Typography className={classes.title} display="inline">
                {consultant.firstName + " " + consultant.lastName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <Typography className={classes.title} display="inline">
              {((proposalTotal / (proposalTotal + projectTotal)) * 100).toFixed(
                0
              )}{" "}
              % on Proposals
            </Typography>
            <Typography className={classes.title} display="inline">
              {((projectTotal / (proposalTotal + projectTotal)) * 100).toFixed(
                0
              )}{" "}
              % on Projects
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ul>
              {consultant &&
                consultantProjects.map((item, index) => {
                  return (
                    <li style={{ float: "left", marginRight: 35 }}>
                      <StyledBadge
                        index={index}
                        variant="dot"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {item.title}
                      </StyledBadge>
                    </li>
                  );
                })}
            </ul>
          </Grid>
        </Grid>

        <Scheduler data={schedulerData} className={classes.scheduler}>
          <ViewState
            defaultCurrentViewName="Month"
            onCurrentDateChange={currentDateChange}
          />
          <MonthView />
          <Toolbar />
          <DateNavigator />
          <TodayButton />

          {/* <EditingState onCommitChanges={onCommitChanges} /> */}
          <Appointments
            appointmentComponent={Appointment}
            appointmentType="hortizontal"
          />
          <AppointmentTooltip contentComponent={Content} showCloseButton />
          <AppointmentForm
            basicLayoutComponent={BasicLayout}
            commandButtonComponent={CommandButton}
          />
          <Resources data={projects} mainResourceName="projectCode" />
        </Scheduler>
      </Panel>

      <p
        style={{
          marginTop: 40,
          fontSize: 20,
          fontWeight: 700,
          color: "#564181",
        }}
      >
        Active Projects ({consultantProjects.length})
      </p>
      {consultantProjects.map((item, index) => {
        return (
          <span
            onClick={() => {
              window.location.href = `/projects/${item._id}`;
            }}
            key={index}
            style={{
              border: "1px solid #564181",
              color: "#564181",
              padding: "7px 15px",
              marginRight: "10px",
              marginBottom: "70px",
              borderRadius: "15px",
              cursor: "pointer",
            }}
          >
            {item.title}
          </span>
        );
      })}
      <Divider style={{ marginTop: 40 }} />
      <p
        style={{
          fontSize: 20,
          fontWeight: 700,
          color: "#564181",
        }}
      >
        Completed Projects ({consultantCompletedProjects.length})
      </p>

      {consultantCompletedProjects.map((item, index) => {
        return (
          <a
            onClick={() => {
              window.location.href = `/projects/${item._id}`;
            }}
            key={index}
            style={{
              border: "1px solid #564181",
              color: "#564181",
              padding: "7px 15px",
              marginRight: "10px",
              marginBottom: "70px",

              borderRadius: "15px",
              cursor: "pointer",
            }}
          >
            {item.title}
          </a>
        );
      })}
    </div>
  );
}
