import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Button,
  Popper,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import Moment from "react-moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddLectureForm,
    onPressedItem,
    lectures,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    setShowBulkDeleteLecturesDialogue
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [sortedLectures, setSortedLectures] = React.useState(null);

  useEffect(() => {
    if (lectures) {
      const sortedLecturesList = lectures.sort(
        (a, b) => Number(a.lectureDate) - Number(b.lectureDate)
      );
      setSortedLectures(sortedLecturesList);
    }
  }, [lectures]);


  const handleDeleteAllLectures = () =>{
    setShowBulkDeleteLecturesDialogue(true)
  }

  
  return (
    <Panel
      title="Lecture Slots"
      loading={loading}
      actionName="Upload Lectures"
      handleAction={toggleAddLectureForm}
      sectionDescription={sectionDescription}
    >
      {sortedLectures && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Title</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Start Time
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    End Time
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Lecturer
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Location
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLectures.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {`${row.title} - ${row.module}`}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="D MMMM, YYYY" date={row.lectureDate} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="HH:mm" date={row.lectureStartTime} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="HH:mm" date={row.lectureEndTime} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.lecturer.firstName} {row.lecturer.lastName}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      {row.location? row.location.title: "no-room"}
                    </TableCell>
                    <TableCell className={classes.selectRight} align="right">
                      <IconButton
                        className={classes.rightRowButton}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setSelectedIndex(index);
                          setSelectedId(row._id);
                          setOpen(true);
                        }}
                        aria-owns={open ? "menu-list-grow" : null}
                        aria-haspopup="true"
                      >
                        <MoreVert className={classes.iconRight} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {lectures.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          You haven't created any lectures yet.
        </Typography>
      )}

      {lectures.length > 0 && (
        <Button
        onClick={()=>{handleDeleteAllLectures()}}
          style={{ backgroundColor: "#8b0000", marginTop: 20, color: "white" }}
        >
          DELETE ALL LECTURES
        </Button>
      )}
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={0} className={classes.menu}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList className={classes.menuList}>
                  <MenuItem
                    onClick={() => {
                      onPressedEditItem(selectedIndex, selectedId);
                      setOpen(false);
                    }}
                    className={classes.menuItemTop}
                  >
                    Edit
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      onPressedDeleteItem(selectedId);
                      setOpen(false);
                    }}
                    className={classes.menuItemTop}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Panel>
  );
}
