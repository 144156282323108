import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import { logOutWithFirebase } from "repository/actions/data/authentication";

// Styles
import styles from "./app-bar-account-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { user } = useContext(AuthenticationContext);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <div className={classes.root}>
      <Button
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
        className={classes.button}
      >
        <Avatar
          alt={user.firstName + " " + user.lastName}
          src={
            user && user != null && user.displayPhoto && user.displayPhoto.url
              ? user.displayPhoto.url
              : ""
          }
          className={classes.avatar}
        />
        <Typography className={classes.text}>
          {user && user != null && user.firstName}{" "}
          {user && user != null && user.lastName}
        </Typography>
        <KeyboardArrowDown className={classes.icon} />
      </Button>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: "bottom" }}
          >
            <Paper elevation={0} className={classes.menu}>
              <ClickAwayListener
                onClickAway={(event) => {
                  setOpen(false);
                }}
              >
                <MenuList className={classes.menuList}>
                  <MenuItem
                    onClick={() => {
                      window.location.href = `/users/${user._id}`;
                    }}
                    className={classes.menuItemTop}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => logOutWithFirebase()}
                    className={classes.menuItemTop}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
