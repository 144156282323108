const styles = theme => ({
  root: {
      flexGrow: 1,
  },
  rightRowButton: {
      width: 28,
      height: 28,
      position: 'relative',
      backgroundColor: 'rgba(230,230,230,0)'
  },
  formControl: {
    marginTop:16,
    marginBottom: 0,
    minWidth: '100%'
  },
  iconRight: {
      marginTop: -10
  },
  spanMarginRight:{
    marginRight: '10rem'
  },
  table: {
      marginTop: 0
  },
  photo: {
      marginTop: 0,
      width: 50,
      height: 50,
      minWidth: 50,
      minHeight: 50,
      maxWidth: 50,
      maxHeight: 50,
      borderRadius: 3,
      marginLeft: 12,
      objectFit: 'cover',
      boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)'
  },
  miniSection: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 18,
      boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
  },
  minPhotoHeight: {
      marginTop: 5,
      width: 20,
      height: 50,
      minWidth: 20,
      minHeight: 50,
      maxWidth: 20,
      maxHeight: 50
  },
  cellDescription: {
      paddingRight: 0
  },
  cellType: {
      width: 150
  },
  cellPhoto: {
      width: 20
  },
  cellName: {
      width: 150
  },
  cellTitle : {
    width : 80
  },
  select: {
      width: 50
  },
  selectRight: {
      width: 100,
  },
  menuList: {
      borderRadius: 20,
  },
  menuItemTop: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
  },
  menuItemBottom: {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
  },
  menu: {
      borderRadius: 15,
      marginTop: 15,
      borderStyle: 'solid',
      borderColor: 'rgba(230,230,230,0.7)',
      borderWidth: 1,
  },
  emptyText: {
      marginTop: 40,
      fontSize: 14,
      fontFamily: 'proximaNova-regular'
  },
  labelTextBold: {
      fontFamily: 'proximaNova-bold',
      fontSize: 12
  },
  labelTextRegular: {
    fontFamily: 'proximaNova-regular',
    fontSize: 12
},
  row: {
      cursor: 'pointer'
  },
  header: {
    marginBottom: 36,
    padding: 24
  },
  searchBar: {
    border: "none",
    boxShadow: "none",
    marginBottom: 20,
    fontSize: "1rem",
  },
  hiddenRefinementList : {
    display: 'none'
  },
  filterHeading : {
    fontWeight : 900,
    letterSpacing : '0.06rem'
  },
  selectContainer:{
    fontFamily : 'proximaNova-regular',
    zIndex: 12
  }
});

export default styles;
