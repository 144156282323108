import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Section from 'components/section';
import { Typography, Paper } from '@material-ui/core';
import { capitalizeFirstLetter } from 'utils/helpers/string';
import DialogueChangeStatus from 'routes/dashboard/dialogues/enquiries/change-status';
import Moment from 'react-moment';

// Styles
import styles from './single-enquiry-details-styles';
const useStyles = makeStyles(styles);

export default withRouter( function (props) {

  let { enquiry, user } = props;
  const classes = useStyles();

  const [showChangeStatusDialogue, setShowChangeStatusDialogue] = useState(false);

  let title = '...';
  let addressHouse, addressCity, addressStreet, addressPostcode, addressCountry, identificationNumber, mobile, gender, email, course, dob, role, lead, company, status, isInternational = '#';
  let createdAt = new Date();

  if (enquiry) {
    title = `${enquiry.title} ${enquiry.firstName} ${enquiry.lastName}`;
    addressHouse = enquiry.addressHouse;
    addressCity = enquiry.addressCity;
    addressCountry = enquiry.addressCountry;
    addressStreet = enquiry.addressStreet;
    addressPostcode = enquiry.addressPostcode;
    gender = capitalizeFirstLetter(enquiry.gender);
    email = enquiry.email;
    course = enquiry.course.title;
    dob = enquiry.dob;
    role = enquiry.role;
    identificationNumber = enquiry.identificationNumber;
    mobile = enquiry.mobile;
    isInternational = enquiry.isInternational;
    lead = capitalizeFirstLetter(enquiry.lead);
    company = enquiry.company;
    if (enquiry.status) {
      status = capitalizeFirstLetter(enquiry.status);
    }
    if (enquiry.createdAt) {
      createdAt = enquiry.createdAt;
    }

  }

  return (
    <div>
      <Section title={title} loading={!enquiry} handleAction={() => setShowChangeStatusDialogue(true)} actionName='Change Status' sectionDescription={'Detailed information about this enquiry'}>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Added on
            </Typography>
            <Typography className={classes.fontRegular}>
            <Moment format="D MMMM, YYYY" date={createdAt} />
            </Typography>
        </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Enquiry Status
            </Typography>
            <Typography className={classes.fontRegular}>
            {status}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            ID Number
            </Typography>
            <Typography className={classes.fontRegular}>
            {identificationNumber}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Mobile
            </Typography>
            <Typography className={classes.fontRegular}>
            {mobile}
            </Typography>
        </Paper>
         <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Address
            </Typography>
            <Typography className={classes.fontRegular}>
            {addressHouse}, {addressStreet}, {addressCity}, {addressCountry}, {addressPostcode}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Gender
            </Typography>
            <Typography className={classes.fontRegular}>
            {gender}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Email
            </Typography>
            <Typography className={classes.fontRegular}>
            {email}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Course
            </Typography>
            <Typography className={classes.fontRegular}>
            {course}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            DOB
            </Typography>
            <Typography className={classes.fontRegular}>
              <Moment format="D MMMM, YYYY" date={dob} />
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Is International
            </Typography>
            <Typography className={classes.fontRegular}>
            {isInternational ? 'Yes' : 'No'}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Company
            </Typography>
            <Typography className={classes.fontRegular}>
            {company}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Role
            </Typography>
            <Typography className={classes.fontRegular}>
            {role}
            </Typography>
        </Paper>
        <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
            Lead Source
            </Typography>
            <Typography className={classes.fontRegular}>
            {lead}
            </Typography>
        </Paper>
       
    </Section>
    <DialogueChangeStatus 
      isOpen={showChangeStatusDialogue}
      setClose={() => setShowChangeStatusDialogue(false)}
      enquiry={enquiry}
      user={user}
    />
    </div>
  )
});