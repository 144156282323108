const styles = theme => ({
    formControl: {
        minWidth: '100%',
        marginRight: 25,
        marginTop: 0
    },
    root: {
        minWidth: 500
    },
    inputType: {
        marginTop: 16,
        marginBottom: 0,
        minWidth: '100%'
    },
    formDescription: {
        minWidth: '100%',
        marginRight: 25,
        marginTop: -10
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    coverPhoto: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: 180,
        borderRadius: 4,
        marginTop: 12,
        marginBottom: 12,
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $overlay': {
                opacity: 1,
            },
            '& $icon': {
                opacity: 1,
            }
        }
    },
    photo: {
        display: 'block',
        borderRadius: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 7,
        maxWidth: '100%',
        width: 'auto',
        height: 150,
        objectFit: 'cover',
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)',
    },
    error: {
        textAlign: 'center',
        fontFamily: 'proximaNova-regular',
        fontSize: 13,
        marginTop: 2,
        color: 'red'
    },
    attachmentText: {
        textAlign: 'center',
        fontFamily: 'proximaNova-regular',
        fontSize: 14,
    },
    link: {
        fontFamily: 'proximaNova-italic',
        fontSize: 14,
        marginTop: 2,
        display: 'block',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    divider: {
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 10,
        width: 15,
        height: 1.5,
        backgroundColor: theme.palette.secondary.main
    },
    picker: {
        margin: theme.spacing(1),
        minWidth: 170,
        maxWidth: 190
    },
    secondHours: {
        display: 'none'
    },
    icon: {
        marginTop: 18,
        marginLeft: 2
    },
    bottomButtons: {
        marginTop: 30
    },
    dialog: {
        padding: 40
    },
    submitButton: {
        borderRadius: 18,
        fontSize: 12,
        marginBottom: 15,
        marginRight: 15,
        boxShadow: 'none'
    },
    cancelButton: {
        borderRadius: 18,
        fontSize: 12,
        marginBottom: 15,
        marginLeft: -15,
        boxShadow: 'none'
    },
    photo2: {
        display: 'inline-block',
        width: 90,
        height: 90,
        borderRadius: 5,
        objectFit: 'cover',
        border: '2px solid rgba(230,230,230,1)',
        marginTop: 24,
        marginLeft: 'calc(50% - 45px)',
    },
    inputLabel: {
        fontType: 'proximaNova-bold',
        fontSize: 14
    },
    input: {
        display: 'none',
    },
    inputField: {
        fontType: 'proximaNova-regular',
        fontSize: 14
    },
    button: {
        marginLeft: 'calc(50% - 32.5px)',
        marginRight: 'auto',
        boxShadow: 'none',
        marginTop: 3,
        fontSize: 12,
        borderRadius: 18,
        marginBottom: 12
    },
    photo: {
        display: 'block',
        borderRadius: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 7,
        maxWidth: '100%',
        width: 'auto',
        height: 150,
        objectFit: 'cover',
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)',
    },
    error: {
        textAlign: 'center',
        fontFamily: 'proximaNova-regular',
        fontSize: 13,
        marginTop: 2,
        color: 'red'
    },
    link: {
        fontFamily: 'proximaNova-italic',
        fontSize: 14,
        marginTop: 2,
        display: 'block',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    modulesList: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "245px",
        height: "400px",
    },

    picker: {
        margin: theme.spacing(1),
        minWidth: 170,
        maxWidth: 190
    },
    secondHours: {
        display: 'none'
    },
    icon: {
        marginTop: 18,
        marginLeft: 2
    },
    bottomButtons: {
        marginTop: 30
    },
    dialog: {
        padding: 40
    },
    submitButton: {
        borderRadius: 18,
        fontSize: 12,
        marginBottom: 15,
        marginRight: 15,
        boxShadow: 'none'
    },
    cancelButton: {
        borderRadius: 18,
        fontSize: 12,
        marginBottom: 15,
        marginLeft: -15,
        boxShadow: 'none'
    },
    miniSection: {
        padding: 20,
        paddingTop: 0,
        margin: "40px  0"
    },
    inputModels: {
        marginTop: 36,
        marginBottom: 0,
        minWidth: '100%'
    },

});

export default styles;
