import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { moduleData } = props;

  let crumbs = [
        {name: 'Content', path: 'content'},
        {name: 'Modules', path: 'content/modules'},
        {name: '...', path: '#'},
  ];

  if (moduleData != null) {
    crumbs[2] =  { name: `${moduleData.title}`, path: `content/modules/${moduleData._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
