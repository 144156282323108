import React, { useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import Panel from "components/panel";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import styles from "../../dialogue-styles";

//Actions
import { updateNewPersonnel } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function EditFilesDialogue(props) {
  const { setClose, isOpen, setPerson, person, user } = props;
  const classes = useStyles();

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [link, setLink] = React.useState("");

  useEffect(() => {
    if (person && person != null) {
      if (person.files) {
        setFiles(person.files ? person.files : []);
      }
    }
  }, [person]);

  const handleAddNewFiles = () => {
    let newFiles = [...files];
    newFiles.push({
      title: title,
      link: link,
    });
    setFiles(newFiles);

    setTitle("");
    setLink("");
  };

  const handleRemoveFile = (index) => {
    let newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleSaveFiles = () => {
    if (user && user != null) {
      let patchData = { ...person, files: files };
      updateNewPersonnel(person._id, patchData, user.idToken)
        .then((result) => {
          toast.success(
            `Documents for ${person.firstName} ${person.lastName} updated`
          );
          setFiles(files);
          handleClose();
          setSaveLoading(false);
     setPerson({ ...person, files: files });

        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information."
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Experience"
      subtitle="Edit this person's experience"
      isOpen={isOpen}
      maxWidth={"lg"}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveFiles()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div>
          <Panel>
            <Grid container spacing={3} style={{ justifyContent: "end" }}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Contract"}
                    disabled={saveLoading}
                    required
                    label="Title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: http://sharepoint"}
                    disabled={saveLoading}
                    required
                    label="Link"
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  disabled={title == "" || link == ""}
                  style={{ backgroundColor: "#f7f7f7" }}
                  onClick={() => {
                    handleAddNewFiles();
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Panel>
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={12}>
              <List>
                {files.length == 0 && (
                  <ListItem key={0}>• No Documents yet</ListItem>
                )}
                {files.map((entry, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <Grid container spacing={3}>
                            <Grid item xs={9}>
                              • {entry.title}
                            </Grid>
                            <Grid item xs={3}>
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={(event) => {
                                  handleRemoveFile(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditFilesDialogue);
