import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  Chip,
  TextField,

} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";

// Navigation
import NavCrumbs from "./clients-all-clients-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  Stats,
} from "react-instantsearch-dom";
import { MoreVert } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddCohortForm,
    onPressedItem,
    clients,
    onPressedDeleteItem,
    sectionDescription,
    user,
    refreshAlgolia,
    setRefreshAlgolia,
    onPressedEditItem
  } = props;

  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    searchClient.clearCache();
  },[])

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a client"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.title}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.vacancies ? row.vacancies.length : 0}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.proposals ? row.proposals.length : 0}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.projects ? row.projects.length : 0}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {[].map((role) => {
                  return (
                    <div className={classes.chip}>
                      <Chip label={role.roleName} />
                    </div>
                  );
                })}
              </TableCell>

              <TableCell className={classes.selectRight} align="right">
                {user.roles.indexOf("admin") >= 0 && (
                  <IconButton
                    className={classes.rightRowButton}
                    onClick={() => {
                      onPressedEditItem(row);
                    }}
                    aria-owns={open ? "menu-list-grow" : null}
                    aria-haspopup="true"
                  >
                    <MoreVert className={classes.iconRight} />
                  </IconButton>
               )} 

                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    if (row.vacancies.length > 0) {
                      toast.error(
                        "Delete client's vacancies before deleting client"
                      );
                    } else {
                      onPressedDeleteItem(row.objectID);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="IDEA GROUP CLIENTS"
        loading={loading}
        actionName="Add Client"
        handleAction={toggleAddCohortForm}
        sectionDescription={sectionDescription}
      >
        {clients.length > 0 && (
          <InstantSearch
            indexName="Clients"
            searchClient={searchClient}
            refresh={refreshAlgolia}
          >
            <Stats
              translations={{
                stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                  return (
                    <Typography className={classes.stat}>
                      {nbHits} Clients in {processingTimeMS} ms
                    </Typography>
                  );
                },
              }}
            />
            <CustomSearchBox />
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Vacancies
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Proposals
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Projects
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Business Lines
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {clients.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any clients yet.
          </Typography>
        )}

      
      </Section>
    </NavCrumbs>
  );
}
