import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-payment-plan-styles";
import {
  Button,
  TextField,
  FormControl,
  IconButton,
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Grid,
  Paper,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { AllPaymentPlansContext } from "repository/stores/dashboard/payment-plans/payment-plans-all-provider";
import { editPaymentPlan } from "repository/actions/data/dashboard/payment-plans";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EuroIcon from "@material-ui/icons/Euro";
import Moment from "react-moment";
import moment from "moment";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles(styles);

function EditPaymentPlanDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, student, data, itemId, user } = props;
  const {paymentPlans, setPaymentPlans, editPaymentPlan} = React.useContext(
    AllPaymentPlansContext
  );

  const [newEntry, setNewEntry] = React.useState({
    title: null,
    amount: null,
    date: null,
  });

  const [saveLoading, setSaveLoading] = React.useState(null);

  const [titleError, setTitleError] = React.useState(null);
  const [dateError, setDateError] = React.useState(null);
  const [amountError, setAmountError] = React.useState(null);
  const [openDate, setOpenDate] = React.useState(false);

  const [entries, setEntries] = React.useState([]);

  useEffect(() => {
    if (data && entries && entries.length == 0) {
      let uiEntries = [];

      for (let [i, e] of data.entries.entries()) {
        let newEntry = {};
        newEntry.title = e.title;
        let millis = e.date * 1000;
        newEntry.date = new Date(millis);
        newEntry.amount = e.amount + "";
        uiEntries.push(newEntry);
      }
      setEntries(uiEntries);
    }
  }, [data]);

  const onPressedDeleteEntry = (index) => {
    if (entries.length > index) {
      let newEntries = [];

      for (let [i, e] of entries.entries()) {
        if (i != index) {
          newEntries.push(e);
        }
      }

      setEntries(newEntries);
    }
  };

  const handleSavePaymentPlanDetails = () => {
    if (user && user != null) {
      if (entries.length <= 0) {
        toast.error("You need to at least add one payment entry.");
      } else {
        setSaveLoading(true);
        toast.dark(`Creating payment plan`);

        let paymentPlanPayload = {};

        paymentPlanPayload.entries = [];

        for (let [i, e] of entries.entries()) {
          let parsedEntry = {};

          parsedEntry.title = e.title;
          parsedEntry.amount = parseFloat(e.amount);
          let dueMoment = new moment(e.date);
          parsedEntry.date = dueMoment.unix();
          paymentPlanPayload.entries.push(parsedEntry);
        }

        editPaymentPlan(
          paymentPlanPayload,
          itemId,
          user.idToken
        )
          .then((result) => {
            toast.success(`Payment plan successfully created`);
            setClose();
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const addPaymentEntry = () => {
    if (
      newEntry.date == null ||
      newEntry.title == null ||
      newEntry.amount == null
    ) {
      toast.error("You have missing information for a new payment entry");
      if (newEntry.date == null) {
        setDateError("Payment date is required.");
      }
      if (newEntry.title == null) {
        setTitleError("Payment name is required.");
      }
      if (newEntry.amount == null) {
        setAmountError("Payment amount is required.");
      }
    } else {
      setEntries(entries.concat(newEntry));
    }
  };

  useEffect(() => {
    setNewEntry({
      title: "",
      amount: "",
      date: null,
    });
  }, [entries]);

  return (
    <Popup
      title="Add a new payment plan"
      subtitle="Please fill in the details below to add a new payment plan to this student."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => {
        setClose();
      }}
      handleAction={() => handleSavePaymentPlanDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <Typography variant="h6">
                  Cohort : {data ? data.cohort.title : ""}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      placeholder={"Ex: Module 1 Payment"}
                      disabled={saveLoading}
                      required
                      helperText={titleError}
                      error={titleError != null}
                      label="Title"
                      value={newEntry.title}
                      onChange={(event) =>
                        setNewEntry({ ...newEntry, title: event.target.value })
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      required
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Payment Date"
                      value={newEntry.date}
                      helperText={dateError}
                      error={dateError != null}
                      onChange={(date) => {
                        setNewEntry({ ...newEntry, date: date });
                        setOpenDate(false);
                      }}
                      onClick={() => setOpenDate(true)}
                      onError={(error) => setDateError(error.message)}
                      onOpen={() => setOpenDate(true)}
                      onClose={() => setOpenDate(false)}
                      open={openDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </FormControl>
                  <div></div>

                  <FormControl className={classes.formControl}>
                    <TextField
                      placeholder={"Ex: 300"}
                      disabled={saveLoading}
                      required
                      helperText={amountError}
                      error={amountError != null}
                      label="Amount (€)"
                      value={newEntry.amount}
                      onChange={(event) =>
                        setNewEntry({ ...newEntry, amount: event.target.value })
                      }
                      margin="normal"
                      defaultValue={newEntry.date}
                    ></TextField>
                  </FormControl>
                </MuiPickersUtilsProvider>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={() => addPaymentEntry()}
                >
                  Add Entry
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <List className={classes.root}>
                  {entries.map((entry, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <EuroIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={entry.title}
                          secondary={
                            <span>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                €{entry.amount} due on{" "}
                                <Moment
                                  format="D MMMM, YYYY"
                                  date={entry.date}
                                />
                              </Typography>
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={(event) => {
                                  onPressedDeleteEntry(index);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </span>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditPaymentPlanDialogue);
