import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllPaymentPlans = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/paymentschedules").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdatePaymentPlans",
          payload: result.data,
        });
        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewPaymentPlan = async (dispatch, paymentPlan, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/paymentschedules")
          .create(paymentPlan, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "AddPaymentPlan",
          payload: result,
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editPaymentPlan = async (dispatch, data, paymentPlanId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/paymentschedules")
          .patch(paymentPlanId, data, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "EditPaymentPlan",
          payload: {
            paymentPlanId: paymentPlanId,
            data: result,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const deletePaymentPlan = async (paymentPlanId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/paymentschedules")
          .remove(paymentPlanId, {
            query: {
              firebaseToken: token,
            },
          });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSinglePaymentPlan = async (paymentPlanId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/paymentschedules")
          .get(paymentPlanId, {
            query: {
              firebaseToken: token,
            },
          });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
