const styles = (theme) => ({
  formControl: {
    minWidth: "100%",
    marginRight: 25,
    marginTop: 0,
  },
  root: {
    minWidth: 500,
  },
  inputType: {
    marginTop: 16,
    marginBottom: 0,
    minWidth: "100%",
  },
  formDescription: {
    minWidth: "100%",
    marginRight: 25,
    marginTop: -10,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  divider: {
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 10,
    width: 15,
    height: 1.5,
    backgroundColor: theme.palette.secondary.main,
  },
  picker: {
    margin: theme.spacing(1),
    minWidth: 170,
    maxWidth: 190,
  },
  secondHours: {
    display: "none",
  },
  icon: {
    marginTop: 18,
    marginLeft: 2,
  },
  bottomButtons: {
    marginTop: 30,
  },
  dialog: {
    padding: 40,
  },
  submitButton: {
    borderRadius: 18,
    fontSize: 12,
    marginBottom: 15,
    marginRight: 15,
    boxShadow: "none",
  },
  cancelButton: {
    borderRadius: 18,
    fontSize: 12,
    marginBottom: 15,
    marginLeft: -15,
    boxShadow: "none",
  },
  photo: {
    display: "block",
    borderRadius: 4,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 7,
    maxWidth: "100%",
    width: "auto",
    height: 150,
    objectFit: "cover",
    boxShadow: "0 1px 3px 0px rgba(180, 180, 180, .6)",
  },
  error: {
    textAlign: "center",
    fontFamily: "proximaNova-regular",
    fontSize: 13,
    marginTop: 2,
    color: "red",
  },
  link: {
    fontFamily: "proximaNova-italic",
    fontSize: 14,
    marginTop: 2,
    display: "block",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  photo2: {
    display: "inline-block",
    width: 90,
    height: 90,
    borderRadius: 5,
    objectFit: "cover",
    border: "2px solid rgba(230,230,230,1)",
    marginTop: 24,
    marginLeft: "calc(50% - 45px)",
  },
  input: {
    display: "none",
  },
  button: {
    marginLeft: "calc(50% - 32.5px)",
    marginRight: "auto",
    boxShadow: "none",
    marginTop: 3,
    fontSize: 12,
    borderRadius: 18,
    marginBottom: 12,
  },
});

export default styles;
