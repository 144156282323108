import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  TextField,
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

// Navigation
import NavCrumbs from "./training-all-sessions-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
} from "react-instantsearch-dom";

// Styles
import styles from "./training-all-sessions-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddLocationForm,
    sessions,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    contacts,
    locations,
    lecturers,
    user,
  } = props;

  const [contactMap, setContactMap] = React.useState({});
  const [locationMap, setLocationMap] = React.useState({});
  const [lecturerMap, setLecturerMap] = React.useState({});

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  if (locations != null) {
    for (let l of locations) {
      if (l && l != null) {
        locationMap[l._id] = l;
      }
    }
  }

  if (contacts != null) {
    for (let c of contacts) {
      if (c && c != null) {
        contactMap[c._id] = c;
      }
    }
  }

  if (lecturers != null) {
    for (let l of lecturers) {
      if (l && l != null) {
        lecturerMap[l._id] = l;
      }
    }
  }

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a Session"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let contact = contactMap[row.contactId];
          let location = locationMap[row.locationId];
          let lecturer = lecturerMap[row.lecturerId];

          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell className={classes.cellName} align="left">
                {row.title}
              </TableCell>
              <TableCell className={classes.cellType} align="left">
                {row.date}
              </TableCell>
              <TableCell className={classes.cellType} align="left">
                {!contact || contact == null ? "No Contact" : contact.firstName}
              </TableCell>
              <TableCell className={classes.cellType} align="left">
                {!location || location == null ? "No Location" : location.title}
              </TableCell>
              <TableCell className={classes.cellType} align="left">
                {!lecturer || lecturer == null
                  ? "No Lecturer"
                  : lecturer.firstName}
              </TableCell>
              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedEditItem(index, row.objectID);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR TRAINING SESSIONS"
        loading={loading}
        actionName="Add session"
        handleAction={toggleAddLocationForm}
        sectionDescription={sectionDescription}
      >
        {sessions.length > 0 && (
          <InstantSearch indexName="Sessions" searchClient={searchClient}>
            <CustomSearchBox />
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Client
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Location
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Lecturer
                      </Typography>
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {sessions.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any sessions yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
