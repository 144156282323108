import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";

// Actions
import { getAssociatedModules } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function EditModulesDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, cohort, itemId, user, editCohort, setCohort, getCohort } = props;

  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [noModuleSelectedError, setNoModuleSelectedError] =
    React.useState(null);
  const [unlinkModuleError, setUnlinkModuleError] = React.useState(null);

  //Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (cohort) {
        if (cohort.courseId && cohort.courseId != null) {
          let selectedModuleIds = [];
          getAssociatedModules(cohort.courseId, user.idToken).then((result) => {
            setModuleOptions(result.data);

            if (cohort.moduleIds && cohort.moduleIds != null) {
              setSelectedModules(cohort.moduleIds);
            }
          });
        }
      }
    }
  }, [cohort]);

  const handleSelectedModuleToggle = (moduleId, isChecked) => {
    if (isChecked) {
      if (selectedModules.indexOf(moduleId) < 0) {
        let newSelectedModules = selectedModules;
        newSelectedModules.push(moduleId);
        setSelectedModules(newSelectedModules);
      }
    } else {
      if (selectedModules.indexOf(moduleId) >= 0) {
        let newSelectedModules = [];
        for (let s of selectedModules) {
          if (s != moduleId) {
            newSelectedModules.push(s);
          }
        }
        setSelectedModules(newSelectedModules);
      }
    }
  };

  const handleSaveNewModuleSelection = () => {
    if (user && user != null) {
      let patchData = {
        moduleIds: selectedModules,
      };

      setSaveLoading(true);

      //check if module was deleted and is still assigned to a student
      let unlinkModuleLinkedWithStudent = false;
      let noModuleSelected = false;
      let unlinkModuleErrorString = "";

      if (selectedModules.length > 0) {
        studentLoop: for (let stud of cohort.students) {
          let studentAssignedModules = stud.cohortModuleMap[cohort._id];

          for (let sam of studentAssignedModules) {
            if (selectedModules.indexOf(sam) < 0) {
              unlinkModuleLinkedWithStudent = true;
              cohortModuleLoop: for (let fm of cohort.modules) {
                if (fm._id == sam) {
                  unlinkModuleErrorString = `Cannot unlink module ${fm.title}. It is linked with ${stud.firstName} ${stud.lastName}`;
                  break cohortModuleLoop;
                }
              }
              break studentLoop;
            }
          }
        }
      } else {
        noModuleSelected = true;
      }

      if (noModuleSelected) {
        toast.error(`Please selected at least one module`);
        setNoModuleSelectedError(
          "Please select at least one module with this cohort"
        );
        setSaveLoading(false);
      } else if (unlinkModuleLinkedWithStudent) {
        setUnlinkModuleError(unlinkModuleErrorString);
        setSaveLoading(false);
      } else {
        editCohort(cohort._id,patchData, user.idToken).then(
          (result) => {
            toast.success(`Successfully assigned modules for ${cohort.title}`);
            setClose();
            setSaveLoading(false);
            setSelectedModules([]);
            setNoModuleSelectedError(null);
            setUnlinkModuleError(null);
          }
        ).catch(e => {
          toast.error(`Oops! Something went wrong. ${e.message}`);
        });
      }
    }
  };

  return (
    <Popup
      title="Edit Cohort Modules"
      subtitle="Tick the modules you would like to associate with this cohort"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveNewModuleSelection()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          {" "}
          {moduleOptions && moduleOptions != null && (
            <FormControl
              className={classes.formControl}
              error={noModuleSelectedError != null || unlinkModuleError != null}
            >
              <List>
                {" "}
                {moduleOptions.map((mod) => {
                  return (
                    <ListItem key={mod._id}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          defaultChecked={selectedModules.indexOf(mod._id) > -1}
                          onChange={(event) => {
                            handleSelectedModuleToggle(
                              mod._id,
                              event.target.checked
                            );
                          }}
                        />{" "}
                      </ListItemIcon>{" "}
                      <ListItemText primary={`${mod.title}`} />{" "}
                    </ListItem>
                  );
                })}{" "}
              </List>{" "}
              <FormHelperText>
                {noModuleSelectedError}
                {unlinkModuleError}
              </FormHelperText>
            </FormControl>
          )}{" "}
        </div>
      }
    />
  );
}

export default withSnackbar(EditModulesDialogue);
