import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Input,
  InputLabel,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, createNewProposal, setRefreshAlgolia } =
    props;

  const [title, setTitle] = React.useState(null);
  const [clientTitle, setClientTitle] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [submissionDate, setSubmissionDate] = React.useState(null);

  const [openDate, setOpenDate] = React.useState(false);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleSaveProposalDetails = () => {
    if (user && user != null) {
      if (title == null || clientTitle == null || type==null ) {
        toast.error("You have missing information for this course");
      } else {
        let data = {
          title: title,
          clientTitle: clientTitle,
          type: type,
          submissionDate: submissionDate
        };

        setSaveLoading(true);
        toast.dark(`Creating ${data.title}`);
        createNewProposal(data, user.idToken)
          .then((result) => {
            setTimeout(() => {
              setRefreshAlgolia(true);
            }, 2000);
            setTimeout(() => {
              setRefreshAlgolia(false);
            }, 2000);
            setClose();
            setSaveLoading(false);
            toast.success(`Successfully created ${data.title}`);
            setTitle(null);
            setClientTitle(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  return (
    <Popup
      title="Add a new Proposal"
      subtitle="Please fill in the details below to create a new proposal."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveProposalDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Proposal Title"}
              disabled={saveLoading}
              required
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: ABC Ltd."}
              disabled={saveLoading}
              required
              label="Client"
              value={clientTitle}
              onChange={(event) => setClientTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <FormControl className={classes.inputType}>
            <InputLabel required disabled={saveLoading}>
              Type
            </InputLabel>
            <Select
              elevation={0}
              onChange={(event) => setType(event.target.value)}
              value={type}
              required
              disabled={saveLoading}
              input={<Input id="type-menu" />}
            >
               <MenuItem value={"Direct Proposal"}>{"Direct Proposal"}</MenuItem>
               <MenuItem value={"Tender Cheapest"}>{"Tender Cheapest"}</MenuItem>
               <MenuItem value={"Tender BPQR"}>{"Tender BPQR"}</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                required
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Submission Date"
                value={submissionDate}
                onChange={(date) => {
                  setSubmissionDate(date);
                  setOpenDate(false);
                }}
                onClick={() => setOpenDate(true)}
                onOpen={() => setOpenDate(true)}
                onClose={() => setOpenDate(false)}
                open={openDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
