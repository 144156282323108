import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Typography, Paper, Button } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import Moment from "react-moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    cohort,
    setCohort,
    user,
    fabFunctions,
    loading,
    toggleAssignCanvasForm,
  } = props;
  const classes = useStyles();

  let title = "...";
  let startDate = "";
  let endDate = "";
  let status = "";
  let canvasUrl = "";
  let course = "";

  if (cohort) {
    title = cohort.title;
    startDate = cohort.startDate;
    endDate = cohort.endDate;
    status = cohort.status;
    canvasUrl = cohort.canvasUrl;
    course = cohort.course;
  }
  return (
    <Panel
      title={title}
      loading={loading}
      sectionDescription={"Detailed information about the cohort."}
      fabFunctions={fabFunctions}
    >
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Title</Typography>
        <Typography className={classes.fontRegular}>{title}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Course</Typography>
        <Typography className={classes.fontRegular}>{course.title}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Start Date</Typography>
        <Typography className={classes.fontRegular}>
          <Moment format="D MMMM, YYYY" date={startDate} />
        </Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>End Date</Typography>
        <Typography className={classes.fontRegular}>
          <Moment format="D MMMM, YYYY" date={endDate} />
        </Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Status</Typography>
        <Typography className={classes.fontRegular}>{status}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Canvas URL</Typography>
        <Typography className={classes.fontRegular}>
          {canvasUrl ? (
            <span>
              <a href={canvasUrl} target="_blank">
                {canvasUrl}
              </a>
              <Button
                onClick={() => {
                  toggleAssignCanvasForm();
                }}
                className={classes.button}
              >
                Assign
              </Button>
            </span>
          ) : (
            <Button
              onClick={() => {
                toggleAssignCanvasForm();
              }}
              color="primary"
            >
              Assign
            </Button>
          )}
        </Typography>
      </Paper>
    </Panel>
  );
});
