import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Navigation
import NavCrumbs from "./vacancies-single-vacancies-nav-crumbs";

// Components
import Details from "./details";
import CandidateStatus from "./candidate-status";


import { AllVacanciesContext } from "repository/stores/dashboard/vacancies/vacancies-all-provider";


import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const vacancyId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);


  const { editVacancy, getVacancy } = useContext(AllVacanciesContext);

  const [vacancy, setVacancy] = useState(null)
  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  const [headCounts, setHeadCounts] = useState("0")
  const classes = useStyles();



  const getVacancyData = async () => {
    await getVacancy(vacancyId, user.idToken).then((result) => {
      setVacancy(result);
    });
  }


  useEffect(() => {
    if (
      user &&
      user != null &&
      vacancyId &&
      vacancyId != null
    ) {
      try {
        getVacancyData()
      } catch (e) {
        alert("Can't get this Vacency.");
      }
    }
  }, []);


  return (
    <NavCrumbs vacancy={vacancy}>
      <Details
        user={user}
        vacancy={vacancy}
        vacancyId={vacancyId}
        headCounts={headCounts}
        setVacancy={setVacancy}
        editVacancy={editVacancy}
        getVacancy={getVacancy}
      />
      <Grid
        container spacing={0} 
        className={classes.candidateStatusContainer}>
        <Grid item xs={2}
          className={classes.candidateStatusItem}>
          <CandidateStatus 
          status={"interested"}
            vacancy={vacancy}
            vacancyId={vacancyId}
            user={user}
            refreshAlgolia={refreshAlgolia}
            setRefreshAlgolia={setRefreshAlgolia}
            setVacancy={setVacancy}
            editVacancy={editVacancy}
            getVacancy={getVacancy} />
        </Grid>

        <Grid item xs={2}          
        className={classes.candidateStatusItem}>
          <CandidateStatus 
          columnTitle={"Contacted / Interviewed by Us"}
          status={"contacted"} vacancy={vacancy} vacancyId={vacancyId} user={user} refreshAlgolia={refreshAlgolia} setRefreshAlgolia={setRefreshAlgolia} 
          setVacancy={setVacancy}
          editVacancy={editVacancy}
          getVacancy={getVacancy}/>
        </Grid>

        <Grid item xs={2}
        className={classes.candidateStatusItem}>
          <CandidateStatus 
          columnTitle={"First Interview With Client"}
          status={"interviewed"} vacancy={vacancy} vacancyId={vacancyId} user={user} refreshAlgolia={refreshAlgolia} setRefreshAlgolia={setRefreshAlgolia} 
          setVacancy={setVacancy}
          editVacancy={editVacancy}
          getVacancy={getVacancy}/>
        </Grid>

        <Grid item xs={2}
        className={classes.candidateStatusItem}>
          <CandidateStatus 
          columnTitle={"Second Interview With Client"}
          status={"refered"} vacancy={vacancy} vacancyId={vacancyId} user={user} refreshAlgolia={refreshAlgolia} setRefreshAlgolia={setRefreshAlgolia} 
          setVacancy={setVacancy}
          editVacancy={editVacancy}
          getVacancy={getVacancy}/>
        </Grid>

        <Grid item xs={2}
        className={classes.candidateStatusItem}>
          <CandidateStatus 
          columnTitle={"Hired"}
          status={"hired"} setHeadCounts={setHeadCounts} vacancy={vacancy} vacancyId={vacancyId} user={user} refreshAlgolia={refreshAlgolia} setRefreshAlgolia={setRefreshAlgolia} 
          setVacancy={setVacancy}
          editVacancy={editVacancy}
          getVacancy={getVacancy}/>
        </Grid>

      </Grid>

    </NavCrumbs>
  );
});
