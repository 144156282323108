import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

export default withSnackbar(
  withRouter(function (props) {
    const {
      isOpen,
      setClose,
      student,
      qualificationIndex,
      qualifications,
      user,
      setStudent,
      editStudent,
    } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteQualification = () => {
      if (user && user != null) {
        setSaveLoading(true);

        let newQualifications = [];

        for (let [i, c] of qualifications.entries()) {
          if (i != qualificationIndex) {
            newQualifications.push(c);
          }
        }

        let patchData = { ...student, qualifications: newQualifications };

        setSaveLoading(true);
        toast.dark(`Deleting qualification`);
        editStudent(student._id, patchData, user.idToken)
          .then((result) => {
            setStudent(result);
            setClose();
            setSaveLoading(false);
            toast.success(`Qualification deleted`);
            setClose();
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete qualification"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteQualification()}
        actionButtonTitle="Yes, I'm sure."
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to remove this qualification ?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
