import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import All from './routes/all';

export default function () {
    return(
        <div>
            <Switch>
                <Route path='/enquiries/all' component={() => <All />} />
                <Route exact path='/enquiries' component={() => <Redirect to={`/enquiries/all`} />} />
            </Switch>
        </div>
    )
}