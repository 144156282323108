import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllUsers from './routes/all-users';
import SingleUser from './routes/single-user';
import View from './routes/view';

import { AuthenticationContext } from "repository/stores/global/authentication-provider";


export default function () {

    const { admin, user } = React.useContext(AuthenticationContext);

    return (
        <div>
            <Switch>
                {admin && <Route exact path='/users/all' component={() => <AllUsers />} />}
                <Route exact path='/users/:id' component={() => <View />} />
                <Route exact path='/users' component={() => <Redirect to={`/users/${user._id}`} />} />
            </Switch>
        </div>
    )
}