import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import All from "./all";
import Single from "./single";

export default function (props) {

  return (
    <div>
      <Switch>
        <Route exact path="/general/tickets" component={() => <All />} />
        <Route exact path="/general/tickets/:id" component={() => <Single />} />

      </Switch>
    </div>
  );
}
