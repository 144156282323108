import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  Grid,
  Link,
  MenuList,
  MenuItem,
  Divider,
  Popper,
  TextField,
} from "@material-ui/core";
import { Visibility, Delete, Edit } from "@material-ui/icons";
import { capitalizeFirstLetter } from "utils/helpers/string";
import Moment from "react-moment";

//InstantSearch
import { getSearchClient } from "utils/algolia";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  Highlight,
} from "react-instantsearch-dom";
import Select from "react-select";

// Navigation
import NavCrumbs from "./enquiries-all-enquiries-nav-crumbs";

// Styles
import styles from "./enquiries-all-enquiries-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddEnquiryForm,
    onPressedItem,
    enquiries,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    courses,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [courseMap, setCourseMap] = React.useState({});
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [selectedCourses, setSelectedCourses] = React.useState([]);

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {/* {nbPages > 2 && currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(1);
                }}
              >
                ‹‹
              </Link>
            </li>
          )} */}
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {/* {nbPages > 2 &&
            currentRefinement <
              nbPages -
                1(
                  <li className={classes.paginationListItem}>
                    <Link
                      href={createURL(nbPages - 1)}
                      onClick={(event) => {
                        event.preventDefault();
                        refine(nbPages - 1);
                      }}
                    >
                      ‹
                    </Link>
                  </li>
                )} */}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for an enquiry"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          if (courseMap[row.courseId]) {
            return (
              <TableRow hover key={row.objectID} className={classes.row}>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellTitle}
                  align="left"
                >
                  {capitalizeFirstLetter(row.title)}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellStandard}
                  align="left"
                >
                  {row.firstName}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellStandard}
                  align="left"
                >
                  {row.lastName}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellDate}
                  align="left"
                >
                  <Moment format="D MMMM, YYYY" date={row.createdAt} />
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellEmail}
                  align="left"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellStandard}
                  align="left"
                >
                  {courseMap[row.courseId].title}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellStatus}
                  align="left"
                >
                  {row.status ? capitalizeFirstLetter(row.status) : "N/A"}
                </TableCell>

                <TableCell className={classes.selectRight} align="right">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={(event) => {
                      onPressedItem(row.objectID);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={(event) => {
                      onPressedEditItem(index, row.objectID);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={(event) => {
                      onPressedDeleteItem(row.objectID);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  //Begin Filtering

  React.useEffect(() => {
    if (courses != null) {
      let newCourseMap = {};
      let newCourseOptions = [];

      for (let l of courses) {
        newCourseMap[l._id] = l;

        let option = {
          value: l._id,
          label: l.title,
        };
        newCourseOptions.push(option);
      }
      setCourseMap(newCourseMap);
      setCourseOptions(newCourseOptions);
    }
  }, [courses]);

  const handleCourseSelectionChange = (selectedCourseOptions) => {
    let newSelectedCourses = [];
    let newSelectedCourseIds = [];
    if (
      selectedCourseOptions &&
      selectedCourseOptions != null &&
      selectedCourseOptions.length > 0
    ) {
      for (let sco of selectedCourseOptions) {
        newSelectedCourses.push(sco);
        newSelectedCourseIds.push(sco.value);
      }
    }

    setSelectedCourses(newSelectedCourseIds);
  };

  const RefinementList = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementList);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR ENQUIRIES"
        loading={loading}
        actionName="Add enquiry"
        handleAction={toggleAddEnquiryForm}
        sectionDescription={sectionDescription}
      >
        {enquiries && enquiries != null && enquiries.length > 0 && (
          <InstantSearch indexName="Enquiries" searchClient={searchClient}>
            <Paper elevation={3} className={classes.header}>
              <CustomSearchBox />
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="courseId"
                    operator="or"
                    defaultRefinement={selectedCourses}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Enquired Courses
                  </Typography>
                  <Select
                    options={courseOptions}
                    isMulti
                    isClearable
                    placeholder="Select courses"
                    onChange={handleCourseSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        First Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Last Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Added on
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Course
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {(enquiries == null || enquiries.length === 0) && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any enquries yet.
          </Typography>
        )}

        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      onClick={() => {
                        onPressedItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      View
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedEditItem(selectedIndex, selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedDeleteItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Section>
    </NavCrumbs>
  );
}
