import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllConsultants from './routes/all-consultants';
import SingleConsultant from './routes/single-consultant';
// import View from './routes/view';


export default function () {

    return (
        <div>
            <Switch>
                <Route exact path='/consultants/all' component={() => <AllConsultants />} />
                <Route exact path='/consultants/:id' component={() => <SingleConsultant />} />
                <Route exact path='/consultants' component={() => <Redirect to={`/consultants/all`} />} />
            </Switch>
        </div>
    )
}