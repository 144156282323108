import React from 'react';

// Parents
import ContentTabs from './dash-content-tabs';
import ContentRoutes from './dash-content-routes';

export default function (props) {

  return (
    <ContentTabs>
      <ContentRoutes />
    </ContentTabs>
  )
}
