import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Collapse,
  Chip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Popper,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Visibility } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styles from "./admin-roles-styles";
import Panel from "components/panel";
import Moment from "react-moment";
import DialogueCreateDocument from "routes/dashboard/dialogues/personnel/create-document";
import DialogueDeleteDocument from "routes/dashboard/dialogues/personnel/delete-document";
import DialogueCreateLecturerContract from "routes/dashboard/dialogues/personnel/create-lecturer-contract";
import DialogueCreateSupervisorContract from "routes/dashboard/dialogues/personnel/create-supervisor-contract";
import DialogueCreateMentorContract from "routes/dashboard/dialogues/personnel/create-mentor-contract";
import { getAssociatedLectureSlots } from "repository/actions/data/dashboard/lecturers";
import { getAssociatedDocuments } from "repository/actions/data/dashboard/personnel";
import { MoreVert, CloudDownload } from "@material-ui/icons";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";

const useStyles = makeStyles(styles);

function Row(props) {
  const { row, status, user } = props;
  const [open, setOpen] = React.useState(false);
  const [lectures, setLectures] = React.useState(null);
  const [documents, setDocuments] = React.useState(null);
  const [contracts, setContracts] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [showNewDocumentDialogue, setShowNewDocumentDialogue] = useState(false);
  const [showDeleteDocumentDialogue, setShowDeleteDocumentDialogue] =
    useState(false);
  const [showNewLecturerContractDialogue, setShowNewLecturerContractDialogue] =
    useState(false);
  const [
    showNewSupervisorContractDialogue,
    setShowNewSupervisorContractDialogue,
  ] = useState(false);
  const [showNewMentorContractDialogue, setShowNewMentorContractDialogue] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (user && user != null) {
      if (row.roleId === "lecturer") {
        getAssociatedLectureSlots(row.personnelId, user.idToken).then(
          (result) => {
            setLectures(result.data);
          }
        );
      }

      if (row.role) {
        getAssociatedDocuments(
          {
            personnelId: row.personnelId,
            roleAssociationId: row._id,
            type: "personnel",
            subtype: "general",
          },
          user.idToken
        ).then((result) => {
          setDocuments(result.data);
        });

        getAssociatedDocuments(
          {
            personnelId: row.personnelId,
            roleAssociationId: row._id,
            type: "personnel",
            subtype: {
              $in: [
                "contract-lecturer",
                "contract-supervisor",
                "contract-mentor",
              ],
            },
          },
          user.idToken
        ).then((result) => {
          setContracts(result.data);
        });
      }
    }
  }, []);

  const onPressedDeleteItem = (id) => { };

  const onPressedItem = (id) => {
    window.location.href = `/students/all${id}`
  };

  let role = "";
  if (row.role._id === "mentor" || row.role._id === "supervisor") {
    role = `${row.role.title} to ${row.student.firstName} ${row.student.lastName}`;
  }
  if (row.role._id === "lecturer") {
    role = `${row.role.title} to ${row.cohort.title}`;
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon onClick={() => setOpen(!open)} />
            ) : (
              <KeyboardArrowDownIcon onClick={() => setOpen(!open)} />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" onClick={(event) => { onPressedItem(row.student._id) }}>
          {row.role._id === "lecturer" && 
           <a href={`/cohorts/${row.cohort._id}`}>{role}</a>}
          {row.role._id === "mentor" || row.role._id === "supervisor" &&
            <a href={`/students/all/${row.student._id}`}>{role}</a>
          }
        </TableCell>
        <TableCell>
          <Moment format="D MMMM, YYYY">{row.createdAt}</Moment>
        </TableCell>
        <TableCell>
          <Moment format="D MMMM, YYYY">{row.startsAt}</Moment>
        </TableCell>
        <TableCell >
          <Moment format="D MMMM, YYYY">{row.expiresAt}</Moment>
        </TableCell>
        <TableCell>
          {status == "active" ? (
            <Chip label={"Active"} />
          ) : (
            <Chip label={"Inactive"} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper className={classes.paperSectionStart}>
                {row.roleId === "lecturer" && (
                  <div>
                    <Typography className={classes.paperTitle}>
                      Cohort
                    </Typography>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Title</TableCell>
                          <TableCell align="left">Assigned Course</TableCell>
                          <TableCell align="left">Start Date</TableCell>
                          <TableCell align="left">End Date</TableCell>
                          <TableCell align="left">Status</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{row.cohort.title}</TableCell>
                          <TableCell>{row.cohort.course.title}</TableCell>
                          <TableCell>
                            <Moment
                              format="D MMMM, YYYY"
                              date={row.cohort.startDate}
                            />
                          </TableCell>
                          <TableCell>
                            <Moment
                              format="D MMMM, YYYY"
                              date={row.cohort.endDate}
                            />
                          </TableCell>
                          <TableCell>{row.cohort.status}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="view"
                              color="primary"
                              onClick={(event) => {
                                window.location.href =
                                  "/cohorts/" + row.cohort._id;
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </TableCell>
                          
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
                {(row.roleId === "mentor" ||
                  row.roleId === "supervisor") && (
                    <div>
                      <Typography className={classes.paperTitle}>
                        Student
                      </Typography>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">First Name</TableCell>
                            <TableCell align="left">Last Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{row.student.title}</TableCell>
                            <TableCell>{row.student.firstName}</TableCell>
                            <TableCell>{row.student.lastName}</TableCell>
                            <TableCell>{row.student.email}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="view"
                                color="primary"
                                onClick={(event) => {
                                  window.location.href =
                                    "/students/all/" + row.student._id;
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}
              </Paper>

              {row.roleId === "lecturer" && (
                <Paper className={classes.paperSection}>
                  <Typography className={classes.paperTitle}>Slots</Typography>
                  {lectures != null && (
                    <TableContainer className={classes.miniSection}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Start Time</TableCell>
                            <TableCell align="left">End Time</TableCell>
                            <TableCell align="left">Lecturer</TableCell>
                            <TableCell align="left">Location</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lectures != null &&
                            lectures.length > 0 &&
                            lectures.map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  key={row._id}
                                  className={classes.row}
                                >
                                  <TableCell
                                    align="left"
                                    className={classes.cellName}
                                  >
                                    {row.title}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.cellType}
                                  >
                                    <Moment
                                      format="D MMMM, YYYY"
                                      date={row.lectureDate}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.cellType}
                                  >
                                    <Moment
                                      format="HH:ss"
                                      date={row.lectureStartTime}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.cellType}
                                  >
                                    <Moment
                                      format="HH:ss"
                                      date={row.lectureEndTime}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.cellType}
                                  >
                                    {row.lecturer.firstName}{" "}
                                    {row.lecturer.lastName}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.cellType}
                                  >
                                    {row.location.title}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {lectures === null && (
                    <Typography className={classes.loading}>
                      Loading...
                    </Typography>
                  )}
                  {lectures !== null && (
                    <div>
                      {lectures.length === 0 && (
                        <Typography className={classes.loading}>
                          This lecturer has no slots assigned to them.
                        </Typography>
                      )}
                    </div>
                  )}
                </Paper>
              )}
              {row.role && (
                <Paper className={classes.paperSection}>
                  <div className={classes.headerButtons}>
                    <Typography className={classes.paperTitle}>
                      Documents
                    </Typography>
                    <Button
                      onClick={() => {
                        setShowNewDocumentDialogue(true);
                      }}
                      variant="contained"
                      component="span"
                      color="primary"
                      disableElevation
                      className={classes.button}
                    >
                      Add
                    </Button>
                  </div>
                  {documents != null && (
                    <TableContainer className={classes.miniSection}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name </TableCell>
                            <TableCell align="center">Date Added</TableCell>
                            <TableCell align="center">Attachment</TableCell>
                            <TableCell align="right"> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documents.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                key={row._id}
                                className={classes.row}
                              >
                                <TableCell
                                  className={classes.cellName}
                                  align="left"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell
                                  className={classes.cellType}
                                  align="center"
                                >
                                  <Typography className={classes.text}>
                                    <Moment format="D MMMM, YYYY">
                                      {row.createdAt}
                                    </Moment>
                                  </Typography>
                                  <div></div>
                                  <Typography className={classes.textBold}>
                                    <Moment format="@HH:mm">
                                      {row.createdAt}
                                    </Moment>
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cellType}
                                  align="center"
                                >
                                  <IconButton
                                    onClick={() => {
                                      getSignedUrlFromAbsoluteUrl(
                                        row.attachment.url,
                                        user && user != null
                                          ? user.idToken
                                          : null
                                      ).then((signedUrl) =>
                                        window.open(signedUrl, "_blank")
                                      );
                                    }}
                                  >
                                    <CloudDownload />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  className={classes.selectRight}
                                  align="right"
                                >
                                  <IconButton
                                    className={classes.rightRowButton}
                                    onClick={(event) => {
                                      setAnchorEl(event.currentTarget);
                                      setSelectedIndex(index);
                                      setSelectedId(row._id);
                                    }}
                                    aria-owns={open ? "menu-list-grow" : null}
                                    aria-haspopup="true"
                                  >
                                    <MoreVert className={classes.iconRight} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {documents === null && (
                    <Typography className={classes.loading}>
                      Loading...
                    </Typography>
                  )}
                  {documents != null && (
                    <div>
                      {documents.length === 0 && (
                        <Typography className={classes.emptyText}>
                          There are no documents attached to this person.
                        </Typography>
                      )}
                    </div>
                  )}
                </Paper>
              )}
              {row.role && (
                <Paper className={classes.paperSection}>
                  <div className={classes.headerButtons}>
                    <Typography className={classes.paperTitle}>
                      Contracts
                    </Typography>
                    {row.roleId === "lecturer" && (
                      <Button
                        onClick={() => {
                          setShowNewLecturerContractDialogue(true);
                        }}
                        variant="contained"
                        component="span"
                        color="primary"
                        disableElevation
                        className={classes.button}
                      >
                        Add Contract
                      </Button>
                    )}
                    {row.roleId === "supervisor" && (
                      <Button
                        onClick={() => {
                          setShowNewSupervisorContractDialogue(true);
                        }}
                        variant="contained"
                        component="span"
                        color="primary"
                        disableElevation
                        className={classes.button}
                      >
                        Add Contract
                      </Button>
                    )}
                    {row.roleId === "mentor" && (
                      <Button
                        onClick={() => {
                          setShowNewMentorContractDialogue(true);
                        }}
                        variant="contained"
                        component="span"
                        color="primary"
                        disableElevation
                        className={classes.button}
                      >
                        Add Contract
                      </Button>
                    )}
                  </div>
                  {contracts != null && (
                    <TableContainer className={classes.miniSection}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name </TableCell>
                            <TableCell align="center">Date Added</TableCell>
                            <TableCell align="center">Attachment</TableCell>
                            <TableCell align="right"> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contracts.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                key={row._id}
                                className={classes.row}
                              >
                                <TableCell
                                  className={classes.cellName}
                                  align="left"
                                >
                                  {row.title}
                                </TableCell>
                                <TableCell
                                  className={classes.cellType}
                                  align="center"
                                >
                                  <Typography className={classes.text}>
                                    <Moment format="D MMMM, YYYY">
                                      {row.createdAt}
                                    </Moment>
                                  </Typography>
                                  <div></div>
                                  <Typography className={classes.textBold}>
                                    <Moment format="@HH:mm">
                                      {row.createdAt}
                                    </Moment>
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.cellType}
                                  align="center"
                                >
                                  <IconButton
                                    onClick={() => {
                                      getSignedUrlFromAbsoluteUrl(
                                        row.attachment.url,
                                        user && user != null
                                          ? user.idToken
                                          : null
                                      ).then((signedUrl) =>
                                        window.open(signedUrl, "_blank")
                                      );
                                    }}
                                  >
                                    <CloudDownload />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  className={classes.selectRight}
                                  align="right"
                                >
                                  <IconButton
                                    className={classes.rightRowButton}
                                    onClick={(event) => {
                                      setAnchorEl(event.currentTarget);
                                      setSelectedIndex(index);
                                      setSelectedId(row._id);
                                      setOpen(true);
                                    }}
                                    aria-owns={open ? "menu-list-grow" : null}
                                    aria-haspopup="true"
                                  >
                                    <MoreVert className={classes.iconRight} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {contracts === null && (
                    <Typography className={classes.loading}>
                      Loading...
                    </Typography>
                  )}
                  {contracts != null && (
                    <div>
                      {contracts.length === 0 && (
                        <Typography className={classes.emptyText}>
                          There are no contracts attached to this person.
                        </Typography>
                      )}
                    </div>
                  )}
                </Paper>
              )}
              <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper elevation={0} className={classes.menu}>
                      <ClickAwayListener>
                        <MenuList className={classes.menuList}>
                          <MenuItem
                            onClick={() => {
                              onPressedDeleteItem(selectedId);
                              setShowDeleteDocumentDialogue(true);
                            }}
                            className={classes.menuItemTop}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DialogueCreateDocument
        isOpen={showNewDocumentDialogue}
        setClose={() => setShowNewDocumentDialogue(false)}
        documents={documents || []}
        setDocuments={setDocuments}
        personnelId={row.personnelId}
        roleAssociation={row}
        role={row.role}
        user={user}
      />
      <DialogueDeleteDocument
        isOpen={showDeleteDocumentDialogue}
        setClose={() => setShowDeleteDocumentDialogue(false)}
        itemId={selectedId}
        documents={documents || []}
        setDocuments={setDocuments}
        personnelId={row.personnelId}
        roleAssociation={row}
        role={row.role}
        user={user}
      />
      <DialogueCreateLecturerContract
        isOpen={showNewLecturerContractDialogue}
        setClose={() => setShowNewLecturerContractDialogue(false)}
        personnelId={row.personnelId}
        roleAssociation={row}
        role={row.role}
        contracts={contracts || []}
        lectureSessions={lectures || []}
        cohort={row.cohort}
        setContracts={setContracts}
        user={user}
      />
      <DialogueCreateSupervisorContract
        isOpen={showNewSupervisorContractDialogue}
        setClose={() => setShowNewSupervisorContractDialogue(false)}
        personnelId={row.personnelId}
        roleAssociation={row}
        role={row.role}
        contracts={contracts || []}
        lectureSessions={lectures || []}
        setContracts={setContracts}
        course={row.course}
        student={row.student}
        user={user}
      />
      <DialogueCreateMentorContract
        isOpen={showNewMentorContractDialogue}
        setClose={() => setShowNewMentorContractDialogue(false)}
        personnelId={row.personnelId}
        roleAssociation={row}
        role={row.role}
        contracts={contracts || []}
        lectureSessions={lectures || []}
        setContracts={setContracts}
        course={row.course}
        student={row.student}
        user={user}
      />
    </React.Fragment>
  );
}

export default function (props) {
  const classes = useStyles();
  const { person, user } = props;
  const [roles, setRoles] = useState({ current: [], previous: [] });

  useEffect(() => {
    if (person && person != null) {
      setRoles({
        current: [...person.currentRoles],
        previous: [...person.previousRoles],
      });
    }
  }, [person]);

  return (
    <div>
      <Panel
        title="Roles"
        loading={false}
        sectionDescription="All current and previous roles related to the person above."
      >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.labelText}></TableCell>
                <TableCell className={classes.labelText}>Role</TableCell>
                <TableCell className={classes.labelText}>Assigned On</TableCell>
                <TableCell className={classes.labelText}>Start Date</TableCell>
                <TableCell className={classes.labelText}>End Date</TableCell>
                <TableCell className={classes.labelText}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.current && roles.current.length > 0 && roles.current.map((role) => {
                return (
                  <Row row={role} status="active" user={user} />

                )
              })}
              {roles.previous && roles.previous.length > 0 && roles.previous.map((role) => (
                <Row row={role} status="inactive" user={user} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Panel>
    </div>
  );
}
