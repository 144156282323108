import React from 'react';

// Parents
import TrainingTabs from './dash-training-tabs';
import TrainingRoutes from './dash-training-routes';

export default function (props) {

  return (
    <TrainingTabs>
      <TrainingRoutes />
    </TrainingTabs>
  )
}
