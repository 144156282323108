import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
} from "@material-ui/core";
import Moment from "react-moment";
import { Delete } from "@material-ui/icons";

//Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    lecturers,
    sectionDescription,
    toggleAssignLecturerForm,
    onPressedDeleteItem,
  } = props;

  return (
    <Panel
      title="Lecturers"
      loading={loading}
      sectionDescription={sectionDescription}
      actionName="Assign Lecturer"
      handleAction={toggleAssignLecturerForm}
    >
      {lecturers.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Name
                  </Typography>
                </TableCell>
               
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Expires</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lecturers.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                     { `${row.person.firstName}  ${row.person.lastName}`}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="D MMMM, YYYY" date={row.expiresAt} />
                    </TableCell>
                    <TableCell className={classes.cellType} align="right">
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {lecturers.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          No lecturers assigned to this cohort
        </Typography>
      )}
    </Panel>
  );
}
