import React, { useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
  Grid,
  Badge,
  TextField,
  Button,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { getSearchClient } from "utils/algolia";
//InstantSearch
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  RefinementList,
  Highlight,
  Stats,
} from "react-instantsearch-dom";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();

  const {
    user,
    referrals,
    loading,
    onPressedItem,
    refreshAlgolia,
    toggleAddReferralForm,
    generateUpcomingReferrals,
  } = props;

  

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      left: -10,
      top: 11,
      marginLeft: "4px",
      marginRight: "8px",
    },
  }));

  const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const Pagination = ({
    currentRefinement,
    nbPages,
    refine,
    createURL,
    padding = 3,
  }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {range(
            Math.max(1, currentRefinement - padding),
            Math.min(nbPages, currentRefinement + padding)
          ).map((page) => {
            // const page = index + 1;
            return (
              <li
                key={page}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, currentRefinement, refine, createURL }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let date = new Date(row.paymentDate);
          let month = moment(date).format("MMMM");
          let thisMonth = moment().format("MMMM");

          let isThisMonth = false;
          if (thisMonth == month) {
            isThisMonth = true;
          }
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {isThisMonth && (
                  <StyledBadge
                    variant="dot"
                    color="secondary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {row.firstName} {row.lastName}
                  </StyledBadge>
                )}
                {!isThisMonth && (
                  <span>
                    {" "}
                    {row.firstName} {row.lastName}{" "}
                  </span>
                )}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.studentName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {moment(row.paymentDate).format("DD MMM YYYY")}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                € {row.paymentValue}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  const RefinementListCustom = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementListCustom);
  //End Filtering

  const handleGenerateReferrals = () => {
    generateUpcomingReferrals(user.email, user.idToken)
      .then((res) => {
        toast.success("Referral list will be sent to your email");
      })
      .catch((e) => {
        toast.error("Error");
        console.log(e);
      });
  };

  return (
    <Section
      title="MANAGE REFERRALS"
      loading={loading}
      actionName="Add Refferal"
      handleAction={toggleAddReferralForm}
    >
      <InstantSearch
        indexName="Referrals"
        searchClient={searchClient}
        refresh={refreshAlgolia}
      >
        <RefinementList
          attribute="status"
          operator="or"
          defaultRefinement={"pending"}
        />

        <Grid container spacing={3}>
          <Grid item xs={10}>
            <CustomSearchBox />
          </Grid>

          <Grid item xs={2}>
              <Stats
                translations={{
                  stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                    return (
                      <Typography className={classes.stat}>
                        {nbHits} referrals in {processingTimeMS} ms
                      </Typography>
                    );
                  },
                }}
              />
            <div style={{display:"flex",justifyContent:"end"}}>

              <Button
                style={{marginRight:"15px"}}
                className={classes.actionButton}
                onClick={() => {
                  handleGenerateReferrals();
                }}
              >
                Generate List
              </Button>
            </div>
          </Grid>
        </Grid>
        {referrals.length > 0 && (
          <TableContainer component={Paper} style={{ marginTop: 70 }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Referrer
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Referrer Email
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Student
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Due Date
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Payment Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <CustomHits />
            </Table>
          </TableContainer>
        )}

        <CustomPagination />
      </InstantSearch>
      {referrals.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          No referrals yet.
        </Typography>
      )}
    </Section>
  );
}
