import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  Grid,
  Link,
  MenuList,
  MenuItem,
  Divider,
  Popper,
  TextField,
  Menu,
  Chip,
  Avatar,
} from "@material-ui/core";
import {
  Visibility,
  Delete,
  Edit,
  SpeakerNotes,
  PlaylistAddCheck,
} from "@material-ui/icons";
import { capitalizeFirstLetter } from "utils/helpers/string";

import Moment from "react-moment";

import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  Highlight,
} from "react-instantsearch-dom";
import Select from "react-select";

// Navigation
import NavCrumbs from "./leads-all-leads-nav-crumbs";

// Styles
import styles from "./leads-all-leads-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddLeadForm,
    onPressedItem,
    leads,
    onPressedEditItem,
    onPressedDeleteItem,
    onPressedEditNotesItem,
    onPressedEditFollowUpsItem,
    onPressedEditDesignatedUserItem,
    sectionDescription,
    courses,
    searchClient,
    editingItem,
    setEditingItem,
    selectedId,
    setSelectedId,
    selectedIndex,
    setSelectedIndex,
    userMap,
    refreshAlgolia
  } = props;
  const [open, setOpen] = React.useState(false);

  const [courseMap, setCourseMap] = React.useState({});
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [selectedCourses, setSelectedCourses] = React.useState([]);

  const [internationalOptions, setInternationalOptions] = React.useState([
    { value: "true", label: "International" },
    { value: "false", label: "Local" },
  ]);
  const [selectedInternationalOptions, setSelectedInternationalOptions] =
    React.useState([]);

  const [statusOptions, setStatusOptions] = React.useState([
    { value: "lead", label: "Lead" },
    { value: "awaiting-documents", label: "Awaiting Documents" },
    { value: "confirmed", label: "Confirmed" },
    { value: "dropped", label: "Dropped" },
  ]);
  const [selectedStatusOptions, setSelectedStatusOptions] = React.useState([]);

  const [colourOptions, setColourOptions] = React.useState([
    { value: "white", label: "White" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "amber", label: "Amber" },
    { value: "red", label: "Red" },
  ]);
  const [selectedColourOptions, setSelectedColourOptions] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [editStatus, setEditStatus] = React.useState(false);

  const onSetStatus = (rowIndex, id, val) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(rowIndex);
    setSelectedId(id);
    setEditStatus(true);
  };

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a lead"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          if (row.delegatedUserId) {
            row.delegatedUser = userMap[row.delegatedUserId];
          }

          if (courseMap[row.interestedCourseId]) {
            let rowClass = classes.row;

            switch (row.colour) {
              case "red":
                rowClass = classes.rowRed;
                break;
              case "blue":
                rowClass = classes.rowBlue;
                break;
              case "green":
                rowClass = classes.rowGreen;
                break;
              case "amber":
                rowClass = classes.rowAmber;
                break;
            }

            return (
              <TableRow hover key={row.objectID} className={rowClass}>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellTitle} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {capitalizeFirstLetter(row.title)}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellStandard} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {row.firstName}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellStandard} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {row.lastName}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellDate} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  <Moment format="D MMMM, YYYY" date={row.createdAt} />
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellEmail} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellStandard} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {courseMap[row.interestedCourseId].title}
                </TableCell>
                <TableCell
                  onClick={(event) => onPressedEditItem(index, row.objectID)}
                  className={`${classes.cellStatus} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {row.status ? capitalizeFirstLetter(row.status) : "N/A"}
                </TableCell>
                <TableCell
                  className={`${classes.cellStatus} ${
                    row.status && row.status == "dropped"
                      ? classes.cellDropped
                      : ""
                  }`}
                  align="left"
                >
                  {row.delegatedUser && (
                    <Chip
                      avatar={
                        <Avatar
                          alt={row.delegatedUser.firstName}
                          src={row.delegatedUser.displayPhoto.url}
                        />
                      }
                      label={`${row.delegatedUser.firstName} ${row.delegatedUser.lastName}`}
                      variant="outlined"
                      onClick={(event) => {
                        onPressedEditDesignatedUserItem(index, row.objectID);
                      }}
                    />
                  )}

                  {!row.delegatedUser && (
                    <Chip
                      label="Delegate a User"
                      onClick={(event) => {
                        onPressedEditDesignatedUserItem(index, row.objectID);
                      }}
                      variant="outlined"
                    />
                  )}
                </TableCell>
                
                <TableCell className={classes.selectRight} align="right">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={(event) => {
                      onPressedDeleteItem(index, row.objectID);
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    aria-label="edit-notes"
                    color="primary"
                    onClick={(event) => {
                      onPressedEditNotesItem(index, row.objectID);
                    }}
                  >
                    <SpeakerNotes />
                  </IconButton>
                  <IconButton
                    aria-label="edit-followups"
                    color="primary"
                    onClick={(event) => {
                      onPressedEditFollowUpsItem(index, row.objectID);
                    }}
                  >
                    <PlaylistAddCheck />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  //Begin Filtering

  React.useEffect(() => {
    if (courses != null) {
      let newCourseMap = {};
      let newCourseOptions = [];

      for (let l of courses) {
        newCourseMap[l._id] = l;

        let option = {
          value: l._id,
          label: l.title,
        };
        newCourseOptions.push(option);
      }
      setCourseMap(newCourseMap);
      setCourseOptions(newCourseOptions);
    }
  }, [courses]);

  const handleCourseSelectionChange = (selectedCourseOptions) => {
    let newSelectedCourses = [];
    let newSelectedCourseIds = [];
    if (
      selectedCourseOptions &&
      selectedCourseOptions != null &&
      selectedCourseOptions.length > 0
    ) {
      for (let sco of selectedCourseOptions) {
        newSelectedCourses.push(sco);
        newSelectedCourseIds.push(sco.value);
      }
    }

    setSelectedCourses(newSelectedCourseIds);
  };

  const handleInternationalSelectionChange = (
    updatedSelectedInternationalOptions
  ) => {
    let newSelectedInternationals = [];
    if (
      updatedSelectedInternationalOptions &&
      updatedSelectedInternationalOptions != null &&
      updatedSelectedInternationalOptions.length > 0
    ) {
      for (let si of updatedSelectedInternationalOptions) {
        newSelectedInternationals.push(si.value);
      }
    }
    setSelectedInternationalOptions(newSelectedInternationals);
  };

  const handleStatusSelectionChange = (updatedStatusSelectionOptions) => {
    let newSelectedStatuses = [];
    if (
      updatedStatusSelectionOptions &&
      updatedStatusSelectionOptions != null &&
      updatedStatusSelectionOptions.length > 0
    ) {
      for (let si of updatedStatusSelectionOptions) {
        newSelectedStatuses.push(si.value);
      }
    }
    setSelectedStatusOptions(newSelectedStatuses);
  };

  const handleColourSelectionChange = (updatedColourSelectedOptions) => {
    let newSelectedColours = [];
    if (
      updatedColourSelectedOptions &&
      updatedColourSelectedOptions != null &&
      updatedColourSelectedOptions.length > 0
    ) {
      for (let si of updatedColourSelectedOptions) {
        newSelectedColours.push(si.value);
      }
    }
    setSelectedColourOptions(newSelectedColours);
  };

  const RefinementList = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementList);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR LEADS"
        loading={loading}
        actionName="Add lead"
        handleAction={toggleAddLeadForm}
        sectionDescription={sectionDescription}
      >
        {leads && leads != null && leads.length > 0 && (
          <InstantSearch indexName="Leads" searchClient={searchClient} refresh={refreshAlgolia}>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={editStatus}
              onClose={() => setEditStatus(false)}
              PaperProps={{
                style: {
                  maxHeight: 45 * 4.5,
                  width: 200,
                },
              }}
            >
              <MenuItem>Interested</MenuItem>
              <MenuItem>Not Interested</MenuItem>
            </Menu>
            <Paper elevation={3} className={classes.header}>
              <CustomSearchBox />
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="interestedCourseId"
                    operator="or"
                    defaultRefinement={selectedCourses}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Interested Courses
                  </Typography>
                  <Select
                    options={courseOptions}
                    isMulti
                    isClearable
                    placeholder="Select courses"
                    onChange={handleCourseSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="isInternational"
                    operator="or"
                    defaultRefinement={selectedInternationalOptions}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    International / Local
                  </Typography>
                  <Select
                    options={internationalOptions}
                    isMulti
                    isClearable
                    placeholder="Select international status"
                    onChange={handleInternationalSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="status"
                    operator="or"
                    defaultRefinement={selectedStatusOptions}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Status
                  </Typography>
                  <Select
                    options={statusOptions}
                    isMulti
                    isClearable
                    defaultValue={selectedStatusOptions}
                    placeholder="Select status"
                    onChange={handleStatusSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="colour"
                    operator="or"
                    defaultRefinement={selectedColourOptions}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Colour
                  </Typography>
                  <Select
                    options={colourOptions}
                    isMulti
                    isClearable
                    defaultValue={selectedColourOptions}
                    placeholder="Select colour"
                    onChange={handleColourSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        First Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Last Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Added on
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Course
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Assigned To
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                    </TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {(leads == null || leads.length === 0) && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any leads yet.
          </Typography>
        )}

        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      onClick={() => {
                        onPressedItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      View
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedEditItem(selectedIndex, selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedDeleteItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Section>
    </NavCrumbs>
  );
}
