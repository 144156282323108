import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleCandidateDetailsView from "./single-candidate-details-view";
import { makeStyles } from "@material-ui/core/styles";


import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";
// Dialogues
import DialogueEditCandidate from "routes/dashboard/dialogues/candidates/edit-candidate";


// import { editCandidate } from "repository/actions/data/dashboard/candidates"





export default withRouter(function (props) {
  let {
    candidate,
    candidateId,
    user,
    setCandidate,
  } = props;


  const { editCandidate } = useContext(AllCandidatesContext);

 
  const [showEditCandidateDialogue, setShowEditCandidateDialogue] = useState(false);


  return (
    <div>
      <SingleCandidateDetailsView
        loading={!candidate}
        sectionDescription={"Detailed information about the candidate."}
        toggleEditCandidateForm={()=>{setShowEditCandidateDialogue(true)}}
        user={user}
        candidate={candidate}
        editCandidate={editCandidate}
        candidateId={candidateId}
        setCandidate={setCandidate}

      />

    <DialogueEditCandidate
        isOpen={showEditCandidateDialogue}
        setClose={() => setShowEditCandidateDialogue(false)}
        user={user}
        candidate={candidate}
        candidateId={candidateId}
        editCandidate={editCandidate}
        setCandidate={setCandidate}
      />


    </div>
  );
});
