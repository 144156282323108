import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let crumbs = [
        {name: 'Proposals', path: 'proposals'},
        {name: 'All Proposals', path: 'proposals/all'},
        {name: '...', path: '#'},
  ];


  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
