import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  Typography,
  Button,
  Grid,
  Paper
} from "@material-ui/core";
// import { leaveRequestHandling } from "../../../../../../../repository/actions/data/dashboard/timesheets";
import {AllLeavesContext } from "repository/stores/dashboard/leaves/leaves-all-provider"


import "./styles.css"
// Navigation
import NavCrumbs from "./leave-all-requests-nav-crumbs";

// Styles
import styles from "./leave-all-requests-styles";
const useStyles = makeStyles(styles);


export default function (props) {
  const classes = useStyles();
  const {
    loading,
    leaveRequests,
    getPendingLeaves,
    user,
    sectionDescription,
  } = props;

  const{leaveRequestHandling } = useContext(AllLeavesContext)



  const Requests = () => {
    return (
      <Grid container spacing={4} >
        {leaveRequests.map(((item, index) => {
          let src = item.user.displayPhoto?item.user.displayPhoto.url:""
          return (
            <Grid item xs={3}>
              <Paper className={classes.leave} class="leaveCard" id={index}>
                <Grid container >

                  
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor:"pointer" }}>
                    <Typography variant="h6" className={classes.title} onClick={()=>{window.location.href = "/users/"+item.user._id}}>
                      {item.user.firstName} {item.user.lastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {!item.isRange && <Typography variant="h6" className={classes.title}>
                        {item.duration} hrs
                      </Typography>}
                      {item.isRange && <Typography variant="h6" className={classes.title}>
                        {item.duration/8} days ({item.duration} hrs)
                      </Typography>}
                      
                    </Grid>
                  </Grid>
                </Grid>

                <hr class="hr-text" data-content={`${item.type} Leave`} align="center" />

                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.subtitle}>
                      {new Date(item.timestamp).toString().substring(0, 15)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.reason}>
                      {item.reason}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <Button className={classes.leaveButton} onClick={() => { handleLeaveRequest(item, "declined", index) }} style={{ backgroundColor: "#ED0D42" }}>
                      Decline
                    </Button>
                    <Button className={classes.leaveButton} onClick={() => { handleLeaveRequest(item, "approved", index) }} style={{ backgroundColor: "#2DC5A2" }}>
                      Accept
                    </Button>
                  </Grid>
                  {item.user.leave < item.duration && item.type == "Paid" && (<Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography className={classes.noPaidLeave}>
                      {item.user.firstName} has no more paid leave
                    </Typography>
                  </Grid>)
                  }
                </Grid>
              </Paper>
            </Grid>
          )
        }))}
      </Grid>
    )
  }

  const handleLeaveRequest = async (leave, value, index) => {

    let payload = {
      status : value,
      date : leave.leaveDay.replace("-","/").replace("-","/"),
      duration: leave.duration,
      type :leave.type,
      email: leave.user.email,
    }

    await leaveRequestHandling(leave._id,payload, user.idToken).then(() => {

      if (value == "declined") {
        toast.success(`${leaveRequests[index].user.firstName}'s Leave Declined`);
      }

      if (value == "approved") {
        toast.success(`${leaveRequests[index].user.firstName}'s Leave Approved`);
      }


      document.getElementById(index).classList.add("handled")
      setTimeout((() => {
        // let oldLeaveRequests = leaveRequests
        // console.log(oldLeaveRequests)

        // oldLeaveRequests.splice(index,1)

        // let newLeaveRequests = oldLeaveRequests
        // lii.current = newLeaveRequests
        // console.log(newLeaveRequests)
        getPendingLeaves(user)

      }), 1000)
    })
  }

  return (
    <NavCrumbs>
      <Paper
        title="Leave Requests"
        loading={loading}
        sectionDescription={sectionDescription}
      >

      </Paper>
      {leaveRequests && <Requests />}
      {leaveRequests.length == 0 &&
        <Grid container style={{marginTop:"50px"}}>
          <Grid item xs={12} style={{textAlign:"center"}}>

            <p>No Pending Leave Requests</p>
          </Grid>
        </Grid>}


    </NavCrumbs>
  );
}