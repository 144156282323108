import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./dialogues-delete-lead-styles";

//Actions
// import {deleteLead} from "repository/actions/data/dashboard/leads";

const useStyles = makeStyles(styles);


export default withSnackbar(
  withRouter(function (props) {
    const {isOpen, setClose, itemId, user,leads, setLeads,deleteLead,setRefreshAlgolia} = props;
    const [saveLoading, setSaveLoading] = React.useState(false);
    const classes = useStyles();


    const handleDeleteLead = () => {
      setSaveLoading(true);

      deleteLead(itemId, user.idToken).then(result => {
        setTimeout(() => { setRefreshAlgolia(true) }, 2000)
        setTimeout(() => { setRefreshAlgolia(false) }, 2000)
        setClose();
        setSaveLoading(false);
        toast.success(`Lead successfully deleted`);
      }).catch(error => {
        toast.error(`Oops! Something went wrong.`);
        toast.dark(error.message);
        setSaveLoading(false);
      });
    };

    return (
      <Popup
        title="Delete selected lead"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteLead()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this lead?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);