import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Assets
import LogoImage from 'assets/images/idea_group_white.png';

// Styles
import styles from './app-bar-logo-styles';
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <a href="/">
        <img src={LogoImage} alt="Logo" className={classes.logo} />
      </a>
    </div>
  );
}