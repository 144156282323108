import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Today from './routes/all/today';
import Custom from './routes/all/custom';



export default function () {
    return(
        <div>
            <Switch>
                <Route path='/timesheets/today' component={() => <Today />} />
                <Route path='/timesheets/custom' component={() => <Custom />} />

                <Route exact path='/timesheets' component={() => <Redirect to={`/timesheets/today`} />} />
            </Switch>
        </div>
    )
}