import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-notes-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import SubjectIcon from "@material-ui/icons/Subject";
import DeleteIcon from "@material-ui/icons/Delete";
import Moment from "react-moment";

//Actions
// import { editLead } from "repository/actions/data/dashboard/leads";

const useStyles = makeStyles(styles);

function EditNotesDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, leads, setLeads, lead, leadId, user, editLead } = props;
  const [notes, setNotes] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newNote, setNewNote] = React.useState(null);
  const [newNoteError, setNewNoteError] = React.useState(null);

  useEffect(() => {
    if (lead && lead != null) {
      if (lead.notes) {
        setNotes(lead.notes);
      }
    }
  }, [lead]);

  const handleAddNewNote = () => {
    if (newNote == null) {
      setNewNoteError("Cannot have an empty note");
    } else {
      let newNoteObject = {
        content: newNote,
        createdAt: new Date(),
      };
      let newNotes = [newNoteObject].concat(notes);
      setNotes(newNotes);
      setNewNoteError(null);
    }
  };

  const handleSaveNotes = () => {
    let patchData = { ...lead, notes: notes };

    editLead(patchData, leadId, user.idToken).then((result) => {
      toast.success(`Notes for ${lead.firstName} ${lead.lastName} updated`);
      handleClose();
      setSaveLoading(false);
    }).catch(error => {
      toast.error('Oops! Something went wrong. Check that you have the correct information.');
      toast.dark(error.message);
      setSaveLoading(false);
    });
  };

  const onPressedDeleteNote = index => {
    if(notes.length > index){
      let newNotes = [];

      for(let [i,e] of notes.entries()){
        if( i !== index){
          newNotes.push(e);
        }
      }
      setNotes(newNotes);
    }
  };

  const handleClose = () => {
    setClose();
  }

  return (
    <Popup
      title="Lead Notes"
      subtitle="Edit the notes for this lead below"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveNotes()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Prefers phonecalls not e-mails"}
                    disabled={saveLoading}
                    required
                    helperText={newNoteError}
                    error={newNoteError != null}
                    label="New Note"
                    value={newNote}
                    onChange={(event) => setNewNote(event.target.value)}
                    margin="normal"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    onClick={() => handleAddNewNote()}
                  >
                    Add Note
                  </Button>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <List className={classes.root}>
                  {notes.map((entry, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <SubjectIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={entry.content}
                          secondary={
                            <span>
                              <Moment
                                format="D MMMM, YYYY"
                                date={entry.createdAt}
                              />
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={(event) => {
                                  onPressedDeleteNote(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default withSnackbar(EditNotesDialogue);
