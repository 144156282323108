import React from "react";

// All Dashboard Contexts after basis. //

// Navigation
import { SideNavigationProvider } from "repository/stores/dashboard/navigation/side-navigation-provider";

// Dialogues
import { DashboardDialoguesProvider } from "repository/stores/dashboard/dialogues/dashboard-dialogues-provider";

// Descriptions
import { SectionDescriptionsProvider } from "repository/stores/descriptions/section-descriptions-provider";
import { PopupDescriptionsProvider } from "repository/stores/descriptions/popup-descriptions-provider";

// Data
import { AllUsersProvider } from "repository/stores/dashboard/users/users-all-provider";
import { AllContactsProvider } from "repository/stores/dashboard/contacts/contacts-all-provider";
import { AllLecturersProvider } from "repository/stores/dashboard/lecturers/lecturers-all-provider";
import { AllLocationsProvider } from "repository/stores/dashboard/locations/locations-all-provider";
import { TrainingProposalsProvider } from "repository/stores/dashboard/training/training-proposals-provider";
import { TrainingSessionsProvider } from "repository/stores/dashboard/training/training-sessions-provider";
import { AllModulesProvider } from "repository/stores/dashboard/modules/modules-all-provider";
import { AllCoursesProvider } from "repository/stores/dashboard/courses/courses-all-provider";
import { AllCohortsProvider } from "repository/stores/dashboard/cohorts/cohorts-all-provider";
import { SingleCohortContext } from "repository/stores/dashboard/cohorts/cohorts-single-provider";

import { AllEnquiriesProvider } from "repository/stores/dashboard/enquiries/enquiries-all-provider";
import { AllStudentsProvider } from "repository/stores/dashboard/students/students-all-provider";
import { SingleStudentProvider } from "repository/stores/dashboard/students/students-single-provider";
import { AllTicketsProvider } from "repository/stores/dashboard/tickets/tickets-all-provider";
import { AllReferralsProvider } from "repository/stores/dashboard/referrals/referrals-all-provider";

import { AllPaymentPlansProvider } from "repository/stores/dashboard/payment-plans/payment-plans-all-provider";
import { AllLecturesProvider } from "repository/stores/dashboard/lectures/lectures-all-provider";
import { AllPersonnelProvider } from "repository/stores/dashboard/personnel/personnel-all-provider";
import { AllLeadsProvider } from "repository/stores/dashboard/leads/leads-all-provider";
import { AllBroadcastsProvider } from "repository/stores/dashboard/broadcast/broadcast-all-provider";

import { AllClientsProvider } from "repository/stores/dashboard/clients/clients-all-provider";
import { AllVacanciesProvider } from "repository/stores/dashboard/vacancies/vacancies-all-provider";
import { AllCandidatesProvider } from "repository/stores/dashboard/candidates/candidates-all-provider";

import { AllProposalsProvider } from "repository/stores/dashboard/proposals/proposals-all-provider";
import { AllProjectsProvider } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllConsultantsProvider } from "repository/stores/dashboard/consultants/consultants-all-provider";

import { AllProgrammesProvider } from "repository/stores/dashboard/programme-costings/programme-costings-all-provider";

import { AllTimesheetsProvider } from "repository/stores/dashboard/timesheets/timesheets-all-provider";
import { AllLeavesProvider } from "repository/stores/dashboard/leaves/leaves-all-provider";

import { IdTokenProvider } from "repository/stores/global/token-provider";
import { AllApplicantsProvider } from "repository/stores/dashboard/applicants/applicants-all-provider";

// import { AllContactsProvider } from "repository/stores/dashboard/contacts/contacts-all-provider"

export default function (props) {
  return (
    <SideNavigationProvider>
      <DashboardDialoguesProvider>
        <SectionDescriptionsProvider>
          <PopupDescriptionsProvider>
            <IdTokenProvider>
              <AllUsersProvider>
                <AllContactsProvider>
                  <AllLecturersProvider>
                    <AllLocationsProvider>
                      <TrainingProposalsProvider>
                        <TrainingSessionsProvider>
                          <AllModulesProvider>
                            <AllCoursesProvider>
                              <AllStudentsProvider>
                                <SingleStudentProvider>
                                  <AllContactsProvider>
                                    <AllCohortsProvider>
                                      {/* <SingleCohortContext> */}
                                      <AllBroadcastsProvider>
                                        <AllEnquiriesProvider>
                                          <AllPaymentPlansProvider>
                                            <AllLecturesProvider>
                                              <AllPersonnelProvider>
                                                <AllTicketsProvider>
                                                  <AllReferralsProvider>
                                                    <AllApplicantsProvider>
                                                      <AllLeadsProvider>
                                                        <AllClientsProvider>
                                                          <AllVacanciesProvider>
                                                            <AllCandidatesProvider>
                                                              <AllProposalsProvider>
                                                                <AllProjectsProvider>
                                                                  <AllConsultantsProvider>
                                                                    <AllProgrammesProvider>
                                                                      <AllTimesheetsProvider>
                                                                        <AllLeavesProvider>
                                                                          {
                                                                            props.children
                                                                          }
                                                                        </AllLeavesProvider>
                                                                      </AllTimesheetsProvider>
                                                                    </AllProgrammesProvider>
                                                                  </AllConsultantsProvider>
                                                                </AllProjectsProvider>
                                                              </AllProposalsProvider>
                                                            </AllCandidatesProvider>
                                                          </AllVacanciesProvider>
                                                        </AllClientsProvider>
                                                      </AllLeadsProvider>
                                                    </AllApplicantsProvider>
                                                  </AllReferralsProvider>
                                                </AllTicketsProvider>
                                              </AllPersonnelProvider>
                                            </AllLecturesProvider>
                                          </AllPaymentPlansProvider>
                                        </AllEnquiriesProvider>
                                      </AllBroadcastsProvider>
                                      {/* </SingleCohortContext> */}
                                    </AllCohortsProvider>
                                  </AllContactsProvider>
                                </SingleStudentProvider>
                              </AllStudentsProvider>
                            </AllCoursesProvider>
                          </AllModulesProvider>
                        </TrainingSessionsProvider>
                      </TrainingProposalsProvider>
                    </AllLocationsProvider>
                  </AllLecturersProvider>
                </AllContactsProvider>
              </AllUsersProvider>
            </IdTokenProvider>
          </PopupDescriptionsProvider>
        </SectionDescriptionsProvider>
      </DashboardDialoguesProvider>
    </SideNavigationProvider>
  );
}
