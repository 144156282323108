import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import LettersView from "./single-student-letters-view";

// Dialogues
import DialogueCreateLetter from "routes/dashboard/dialogues/students/create-document-letter";
import DialogueDeleteLetter from "routes/dashboard/dialogues/students/delete-document";

// Actions
import { getAssociatedLetters } from "repository/actions/data/dashboard/students";

export default withRouter(function (props) {
  const { student, setStudent, user, editStudent, getStudent } = props;

  const [letters, setLetters] = useState([]);
  const [cohorts, setCohorts] = useState([]);

  const [showNewLetterDialogue, setShowNewLetterDialogue] = useState(false);
  const [showDeleteLetterDialogue, setShowDeleteLetterDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (student != null) {
        getAssociatedLetters(student._id, user.idToken).then((result) => {
          setLetters(result.data);
        });
        setCohorts(student.cohorts);
      }
    }
  }, [student]);

  // Editing Item
  let editingItem = null;
  if (letters) {
    if (letters[selectedIndex]) {
      editingItem = letters[selectedIndex];
    }
  }

  return (
    <div>
      <LettersView
        loading={!letters}
        toggleAddCourseForm={() => setShowNewLetterDialogue(true)}
        sectionDescription={
          "You can view and delete all related letters below."
        }
        letters={letters || []}
        setLetters={setLetters}
        student={student}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteLetterDialogue(true);
        }}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
      />
      <DialogueCreateLetter
        isOpen={showNewLetterDialogue}
        setClose={() => setShowNewLetterDialogue(false)}
        letters={letters || []}
        cohorts={cohorts || []}
        setLetters={setLetters}
        setCohorts={setCohorts}
        student={student}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
      />
      <DialogueDeleteLetter
        isOpen={showDeleteLetterDialogue}
        setClose={() => setShowDeleteLetterDialogue(false)}
        itemId={selectedId}
        documents={letters || []}
        setDocuments={setLetters}
        student={student}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
      />
    </div>
  );
});
