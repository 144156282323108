import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./proposal-new-proposal-nav-crumbs";

// Components
import Page from "./page";
import Details from "./details";


export default withRouter(function (props) {

  const { user } = useContext(AuthenticationContext);


  return (
    <NavCrumbs >
      <Page
        user={user}
      />
       {/* <Details
        user={Page}
      /> */}
    </NavCrumbs>
  );
});
