import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleProposalDetailsView from "./single-proposal-comments-view";


export default withRouter(function (props) {
  let {
    proposal,
    setProposal,
    user,
    editProposal
  } = props;


  return (
    <div>
      <SingleProposalDetailsView
        loading={!proposal}
        sectionDescription={"Detailed information about the proposal."}
        user={user}
        proposal={proposal}
        setProposal={setProposal}
        editProposal={editProposal}
      />



    </div>
  );
});
