import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllCourses = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/courses").find({
          query: {
            firebaseToken: token,
          },
        });

        resolve(result.data);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewCourse = async (dispatch, course, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/courses").create(course, {
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "AddCourse",
          payload: result,
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const editCourse = async (dispatch, data, courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let updatedCourse = await apiClient
          .service("v1/courses")
          .patch(courseId, data, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "EditCourse",
          payload: {
            courseId: courseId,
            data: updatedCourse,
          },
        });

        resolve();
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteCourse = async (dispatch, courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/courses").remove(courseId, {
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "RemoveCourse",
          payload: result,
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleCourse = async (courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/courses").get(courseId, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedModules = async (courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
        let result = await apiClient.service("v1/modules").find({
          query: {
            assignedCourseId: courseId,
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createCourseDocument = async (
  courseId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/documents").create(
          {
            attachmentURI: data.attachment,
            title: data.title,
            type: "course",
            courseId: courseId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = [...documents];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocuments = async (courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/documents").find({
          query: {
            courseId: courseId,
            type: "course",
            firebaseToken: token,
          },
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedCohorts = async (courseId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/cohorts").find({
          query: {
            courseId: courseId,
            firebaseToken: token,
          },
        });
        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};
