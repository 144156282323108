import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper } from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { moduleData, setModuleData, user } = props;
  const classes = useStyles();

  let title = "...";
  let description = "";
  let mqfLevel = "";
  let referenceNumber = "";
  let assignedCourse = "";

  if (moduleData) {
    title = `${moduleData.title}`;
    description = moduleData.description;
    mqfLevel = moduleData.mqfLevel;
    referenceNumber = moduleData.referenceNumber;
    assignedCourse = moduleData.assignedCourse;
  }
  return (
    <Section
      title={title}
      loading={!moduleData}
      sectionDescription={"Personal information about the lecturer."}
    >
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Title</Typography>
        <Typography className={classes.fontRegular}>{title}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Description</Typography>
        <Typography className={classes.fontRegular}>{description}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>MQF Level</Typography>
        <Typography className={classes.fontRegular}>{mqfLevel}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Reference Number</Typography>
        <Typography className={classes.fontRegular}>
          {referenceNumber}
        </Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Assigned Course</Typography>
        <Typography className={classes.fontRegular}>
          {assignedCourse.title}
        </Typography>
      </Paper>
    </Section>
  );
});
