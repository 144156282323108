import React from 'react';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

// Parents
import TabsView from './navigation-tabs-view';

export default withRouter( function (props) {
  let [value, setValue] = React.useState(props.tabs.startPath);

  let tabRoutes = props.tabs.routes;
  let transferPath = props.tabs.transferPath;

  for (let route of tabRoutes) {
      const match = matchPath(props.location.pathname, {
                        path: `/${transferPath}/${route.path}`,
                        exact: false
                    });

    if (match && (value !== route.path)) {
        setValue(route.path);
    }
  }

  function handleChange(event, newValue) {
      props.history.push(`/${transferPath}/`+newValue);
  }

  return <TabsView handleChange={handleChange} value={value} tabRoutes={tabRoutes}/>;
});