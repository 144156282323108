import React from 'react';

// Parents
// import VacanciesTabs from './dash-vacancies-tabs';
import VacanciesRoutes from './dash-vacancies-routes';

export default function (props) {

  return (
    // <VacanciesTabs>
      <VacanciesRoutes />
    // </VacanciesTabs>
  )
}
