import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'training',
      startPath: 'proposals',
      routes:[
        { name: 'Proposals', path: 'proposals', icon: 'receipt'},
        { name: 'Sessions', path: 'sessions', icon: 'hourglass_empty'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});