export default theme => ({
    root: {
        width:"100%",
        height:"80%",
        paddingLeft: 15,
        marginTop: 0
    },
    sectionSpacing: {
        paddingTop:0,
        paddingBottom:5,
    },
    textFontSizeBold: {
        fontSize: 12,
        marginLeft: 0,
        marginTop: 32,
        marginBottom: 12,
        color:'rgba(0,0,0,0.3)',
        fontFamily: 'proximaNova-bold',
        letterSpacing: '0.13em'
    },
    textFontSize: {
        fontSize: 13,
        marginLeft: -24,
        color:'rgba(7,7,7,0.9)',
        fontFamily: 'proximaNova-bold',
    },
    textFontSizeHighlighted: {
        fontSize: 14,
        marginLeft: -24,
        fontFamily: 'proximaNova-bold',
        color: theme.palette.primary.light
    },
    iconFontSize: {
        fontSize: 15,
        color: 'rgba(7,7,7,0.9)',
        marginLeft: 0,
        padding: 0
    },
    iconFontSizeHighlighted: {
        fontSize: 20,
        color: theme.palette.primary.light
    },
    listItem: {
        borderRadius: 4,
        borderHeight: 10,
        marginLeft: -10,
        height: 40,
        width:'100%',
        marginTop:2,
        marginBottom:2,
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(243, 243, 243, 0.5)',
            borderRadius: 4,
        }
    },
    comingSoon: {
        marginLeft: 135,
        fontSize: 10,
        fontFamily: 'proximaNova-bold',
        width: 80,
        height: 24,
        borderRadius: 18,
        padding: 0,
        color: 'white',
        marginTop: -34,
        textTransform: 'none',
        position: 'absolute',
        boxShadow: 'none',
        opacity: 1,
        '&:disabled': {
            color: 'rgba(199,199,199,1)',
            borderColor: 'rgba(199,199,199,1)',
            backgroundColor: 'white'
        }
    }
});