import React, { useEffect } from "react";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import Popup from "components/popup";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-lead-styles";
import {
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { toast } from "react-toastify";

//Actions
// import { editLead } from "repository/actions/data/dashboard/leads";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function EditLeadDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, leads, setLeads, editLead, courses, setCourses, getCourses } = props;

  // const {courses, setCourses} = React.useContext(AllCoursesContext);
  const [identificationNumber, setIdentificationNumber] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [mobile, setMobile] = React.useState(null);

  const [addressHouse, setAddressHouse] = React.useState(null);
  const [addressStreet, setAddressStreet] = React.useState(null);
  const [addressCity, setAddressCity] = React.useState(null);
  const [addressPostcode, setAddressPostcode] = React.useState(null);
  const [addressCountry, setAddressCountry] = React.useState(null);
  const [isInternational, setIsInternational] = React.useState(false);
  const [isGdprApproved, setIsGdprApproved] = React.useState(false);

  const [source, setSource] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [gender, setGender] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [interestedCourse, setInterestedCourse] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [colour, setColour] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const [companyError, setCompanyError] = React.useState(null);
  const [roleError, setRoleError] = React.useState(null);

  const [sourceError, setSourceError] = React.useState(null);

  const [statusError, setStatusError] = React.useState(null);
  const [colourError, setColourError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [interestedCourseError, setInterestedCourseError] =
    React.useState(null);
  const [mobileError, setMobileError] = React.useState(null);
  const [openDob, setOpenDob] = React.useState(false);

  useEffect(() => {
    if (courses == null) {
      getCourses(user.idToken);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setIdentificationNumber(data.identificationNumber);
      setAddressHouse(data.addressHouse);
      setAddressCountry(data.addressCountry);
      setAddressCity(data.addressCity);
      setAddressStreet(data.addressStreet);
      setAddressPostcode(data.addressPostcode);
      setTitle(data.title);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setCompany(data.company);
      setRole(data.role);
      setSource(data.source);
      setDob(data.dob);
      setGender(data.gender);
      setEmail(data.email);
      setInterestedCourse(data.interestedCourseId);
      setIsInternational(data.isInternational ? data.isInternational : false);
      setIsGdprApproved(data.isGdprApproved ? data.isGdprApproved : false);
      setMobile(data.mobile);
      setStatus(data.status);
      setColour(data.colour);
    }
  }, [data]);

  const handleEditLead = () => {
    if (
      firstName == null ||
      title == null ||
      lastName == null ||
      source == null ||
      email == null ||
      interestedCourse == null ||
      mobile == null ||
      status == null ||
      colour == null
    ) {
      toast.error(`You have missing information for this enquiry.`);

      if (firstName == null) {
        setFirstNameError("First name is required");
      }
      if (lastName == null) {
        setLastNameError("Last name is required.");
      }

      if (title == null) {
        setTitleError("Title is required.");
      }
      if (source == null) {
        setSourceError("Source is required.");
      }
      if (status == null) {
        setStatusError("Status is required.");
      }
      if (colour == null) {
        setColourError("Colour is required.");
      }
      if (email == null) {
        setEmailError("Email is required.");
      }
      if (interestedCourse == null) {
        setInterestedCourseError("Course is required.");
      }
    } else {
      let data = {
        title: title,
        firstName: firstName,
        lastName: lastName,
        company: company,
        role: role,
        addressHouse: addressHouse,
        addressCity: addressCity,
        addressStreet: addressStreet,
        addressPostcode: addressPostcode,
        addressCountry: addressCountry,
        source: source,
        dob: dob,
        gender: gender,
        email: email,
        interestedCourseId: interestedCourse,
        isInternational: isInternational,
        isGdprApproved: isGdprApproved,
        identificationNumber: identificationNumber,
        mobile: mobile,
        status: status,
        colour: colour,
      };

      setSaveLoading(true);
      toast.dark(`Editing Lead : ${data.firstName} ${data.lastName}`);
      editLead(data, itemId, user.idToken)
        .then((result) => {
          setClose();
          toast.success(
            `Lead successfully modified : ${data.firstName} ${data.lastName}`
          );
          setSaveLoading(false);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Edit Lead"
      subtitle="You can edit the details of your lead below"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => handleClose()}
      handleAction={() => handleEditLead()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FormControl
                  className={classes.inputType}
                  error={titleError != null}
                >
                  <InputLabel required disabled={saveLoading}>
                    Title
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setTitle(event.target.value)}
                    value={title}
                    required
                    error={titleError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"mr"}>Mr.</MenuItem>
                    <MenuItem value={"ms"}>Ms.</MenuItem>
                    <MenuItem value={"ing"}>Ing.</MenuItem>
                    <MenuItem value={"dr"}>Dr.</MenuItem>
                  </Select>
                  <FormHelperText>{titleError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: John"}
                    disabled={saveLoading}
                    required
                    helperText={firstNameError}
                    error={firstNameError != null}
                    label="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Doe"}
                    disabled={saveLoading}
                    required
                    helperText={lastNameError}
                    error={lastNameError != null}
                    label="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"22389(M)"}
                    disabled={saveLoading}
                    label="Identification Number"
                    value={identificationNumber}
                    onChange={(event) =>
                      setIdentificationNumber(event.target.value)
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: ABC Ltd."}
                    disabled={saveLoading}
                    helperText={companyError}
                    error={companyError != null}
                    label="Company"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Director"}
                    disabled={saveLoading}
                    helperText={roleError}
                    error={roleError != null}
                    label="Role"
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  className={classes.inputType}
                >
                  <InputLabel required disabled={saveLoading}>
                    Status
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"lead"}>Lead</MenuItem>
                    <MenuItem value={"awaiting-documents"}>
                      Awaiting Documents
                    </MenuItem>
                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                    <MenuItem value={"dropped"}>Dropped</MenuItem>
                  </Select>
                  <FormHelperText>{statusError}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <span>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      defaultChecked={isInternational}
                      onChange={(event) => {
                        setIsInternational(event.target.checked);
                      }}
                    />
                    Is International
                  </span>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <span>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      defaultChecked={isGdprApproved}
                      onChange={(event) => {
                        setIsGdprApproved(event.target.checked);
                      }}
                    />
                    Is GDPR Approved
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 1, Ave Maria"}
                    disabled={saveLoading}
                    label="House & House Number"
                    value={addressHouse}
                    onChange={(event) => setAddressHouse(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Triq Sant Antnin"}
                    disabled={saveLoading}
                    label="Street"
                    value={addressStreet}
                    onChange={(event) => setAddressStreet(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Mosta"}
                    disabled={saveLoading}
                    label="City"
                    value={addressCity}
                    onChange={(event) => setAddressCity(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Malta"}
                    disabled={saveLoading}
                    label="Country"
                    value={addressCountry}
                    onChange={(event) => setAddressCountry(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: MST 1234"}
                    disabled={saveLoading}
                    label="Postcode"
                    value={addressPostcode}
                    onChange={(event) => setAddressPostcode(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    openTo="year"
                    views={["year", "month", "date"]}
                    margin="normal"
                    id="date-picker-inline"
                    label="Date of Birth"
                    value={dob}
                    onChange={(date) => {
                      setDob(date);
                    }}
                    onClick={() => setOpenDob(true)}
                    onOpen={() => setOpenDob(true)}
                    onClose={() => setOpenDob(false)}
                    open={openDob}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  className={classes.inputType}
                >
                  <InputLabel required disabled={saveLoading}>
                    Gender
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setGender(event.target.value)}
                    value={gender}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"n/a"}>N/A</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl
                  className={classes.inputType}
                  error={sourceError != null}
                >
                  <InputLabel required disabled={saveLoading}>
                    Lead Source
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setSource(event.target.value)}
                    value={source}
                    required
                    error={sourceError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"facebook"}>Facebook</MenuItem>
                    <MenuItem value={"linkedIn"}>LinkedIn</MenuItem>
                    <MenuItem value={"instagram"}>Instagram</MenuItem>
                    <MenuItem value={"mumn"}>MUMN</MenuItem>
                    <MenuItem value={"own research"}>Own Research</MenuItem>
                    <MenuItem value={"word of mouth"}>Word of mouth</MenuItem>
                  </Select>
                  <FormHelperText>{sourceError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  className={classes.inputType}
                  error={interestedCourseError != null}
                >
                  <InputLabel required disabled={saveLoading}>
                    Interested Course
                  </InputLabel>
                  {courses != null && (
                    <Select
                      elevation={0}
                      onChange={(event) =>
                        setInterestedCourse(event.target.value)
                      }
                      value={interestedCourse}
                      required
                      error={interestedCourseError != null}
                      disabled={saveLoading}
                      input={<Input id="type-menu" />}
                    >
                      {courses.map((item) => {
                        return (
                          <MenuItem value={item._id}>{item.title}</MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  <FormHelperText>{interestedCourseError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  className={classes.inputType}
                >
                  <InputLabel required disabled={saveLoading}>
                    Colour
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setColour(event.target.value)}
                    value={colour}
                    error={colourError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"white"}>White</MenuItem>
                    <MenuItem value={"blue"}>Blue</MenuItem>
                    <MenuItem value={"green"}>Green</MenuItem>
                    <MenuItem value={"amber"}>Amber</MenuItem>
                    <MenuItem value={"red"}>Red</MenuItem>
                  </Select>
                  <FormHelperText>{colourError}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 79123123"}
                    disabled={saveLoading}
                    helperText={mobileError}
                    error={mobileError != null}
                    label="Mobile"
                    value={mobile}
                    onChange={(event) => setMobile(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: john@doe.com"}
                    disabled={saveLoading}
                    required
                    helperText={emailError}
                    error={emailError != null}
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(EditLeadDialogue));
