import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Popper,
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import Moment from "react-moment";
// import AssignCohort from 'routes/dashboard/dialogues/students/assign-cohort';

//Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    student,
    loading,
    toggleAssignCohortDialogue,
    cohorts,
    onPressedDeleteItem,
    onPressedEditModules,
    sectionDescription,
    user
  } = props;
  const classes = useStyles();

  const [showAssignCohortDialogue, setShowAssignCohortDialogue] =
    useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Section
      title="Cohorts"
      loading={loading}
      actionName="Assign Cohort"
      handleAction={toggleAssignCohortDialogue}
      sectionDescription={sectionDescription}
    >
      {cohorts && cohorts.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Title</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Course</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Start Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>End Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Membership Status
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <Typography className={classes.labelText}></Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cohorts.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.cellType} align="left">
                      {row.course.title}
                    </TableCell>
                    <TableCell className={classes.cellType} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.startDate}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellType} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.endDate}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellType} align="left">
                      {row.membershipStatus}
                    </TableCell>
                    <TableCell className={classes.cellType} align="right">
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        className={classes.inlineActionButton}
                        disableElevation
                        onClick={() => {
                          onPressedEditModules(index);
                        }}
                      >
                        Edit
                      </Button>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <HighlightOff />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {cohorts.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          This student is not assigned to any cohorts.
        </Typography>
      )}
      <Popper
        open={showAssignCohortDialogue}
        anchorEl={anchorEl}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={0} className={classes.menu}>
              <ClickAwayListener
                onClickAway={() => setShowAssignCohortDialogue(false)}
              >
                <MenuList className={classes.menuList}>
                  <MenuItem
                    onClick={() => {
                      onPressedDeleteItem(selectedId);
                    }}
                    className={classes.menuitemTop}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Section>
  );
});
