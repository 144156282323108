import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleUser } from "repository/actions/data/dashboard/users";
import { AuthenticationContext } from 'repository/stores/global/authentication-provider';

// Navigation
import NavCrumbs from "./users-single-user-nav-crumbs";

// Components
import Details from "./details";

export default withRouter(function (props) {
  const userId = props.match.params.id;
  const {user} = useContext(AuthenticationContext);

  const [loadedUser, setLoadedUser] = useState(null);

  useEffect(() => {
    getSingleUser(userId,user.idToken).then((result) => {
      setLoadedUser(result);
    });
  }, []);

  return (
    <NavCrumbs user={loadedUser} authUser={user}>
      <Details user={loadedUser} authUser={user} />
    </NavCrumbs>
  );
});
