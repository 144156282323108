import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Chip,
  CardContent,
  CardHeader,
  Card,
} from "@material-ui/core";
import Loader from "components/loading";
import FlashIcon from "@material-ui/icons/FlashOnOutlined";

// Styles
import styles from "./panel-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    title,
    loading,
    panelDescription,
    actionName,
    handleAction,
    fabFunctions,
  } = props;

  let titleClass = classes.title;
  if (actionName) {
    titleClass = classes.titleLeft;
  }
  return (
    <Card elevation={0} className={classes.smallCard}>
      {title && (
        <CardHeader
          className={classes.header}
          title={
            <span>
              <Typography className={titleClass}>
                {title.toUpperCase()}
              </Typography>

              <Typography className={classes.description}>
                {panelDescription}
              </Typography>
            </span>
          }
          action={
            <div>
              {actionName && !loading && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAction()}
                  className={classes.button}
                  disableElevation
                >
                  {actionName}
                </Button>
              )}
            </div>
          }
        />
      )}

      <CardContent>
        {loading && <Loader />}
        {!loading &&
          fabFunctions &&
          fabFunctions != null &&
          fabFunctions.length > 0 && (
            <div>
              {fabFunctions.map((fun) => {
                return (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={fun.handleAction}
                    className={classes.buttonLightweight}
                    style={{marginRight:5, marginBottom:5, textTransform:"lowercase", fontSize:"15px"}}
                  >
                    <FlashIcon sx={{ mr: 1 }} style={{fontSize:"small"}}/>
                    {fun.name}
                  </Button>
                );
              })}
            </div>
          )}
        {!loading && <div>{props.children}</div>}
      </CardContent>
    </Card>
  );
}
