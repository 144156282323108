import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Grid,
  TextField,
  Tooltip,
  Select,
  MenuItem,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
import moment from "moment";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    editCohort,
    user,
    cohort,
    bulkCertificate,
    bulkRecordOfWork,
    bulkTranscriptPdf,
    sectionDescription,
    bulkCompletionLetter,
    bulkDiplomaSupplement,
    bulkGenerateTranscript,
  } = props;

  const [cohortModules, setCohortModules] = useState([]);

  const [moduleEcts, setModuleEcts] = useState({});

  const [studentTranscript, setStudentTranscript] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState(false);
  const [cohortType, setCohortType] = useState("Full Time");

  const [selectedReport, setSelectedReport] = useState(0);
  const [page, setPage] = useState(1);

  const [selectedPassRange, setSelectedPassRange] = useState(2);
  let reports = [
    "Diploma Supplement",
    "Record of Work",
    "Completion Letter",
    "Transcript",
    "Certificate",
  ];
  let passRanges = [
    {
      option: "≥81",
      ranges: [
        { status: "Distinction", min: 81, max: 100 },
        { status: "Credit", min: 66, max: 80 },
        { status: "Merit", min: 56, max: 65 },
        { status: "Pass", min: 41, max: 55 },
        { status: "Fail", min: 0, max: 40 },
      ],
    },
    {
      option: "≥86",
      ranges: [
        { status: "Distinction", min: 86, max: 100 },
        { status: "Credit", min: 71, max: 85 },
        { status: "Merit", min: 56, max: 70 },
        { status: "Pass", min: 41, max: 55 },
        { status: "Fail", min: 0, max: 40 },
      ],
    },
    {
      option: "≥86 (new)",
      ranges: [
        { status: "Distinction", min: 86, max: 100 },
        { status: "Merit", min: 56, max: 85 },
        { status: "Pass", min: 50, max: 55 },
        { status: "Fail", min: 0, max: 49 },
      ],
    },
  ];

  const generatingStages = [
    "Loading Discussions & Assignments...",
    "Correcting students work...",
    "Just count to 10",
    "1 ..., 2 ..., 3 ...",
    "Almost done!",
    "2 seconds left",
  ];

  let downloadBtn = {
    backgroundColor: "#F3B903",
    color: "white",
    marginTop: 40,
    marginRight: 20,
  };

  let pdfBtn = {
    backgroundColor: "#F3B903",
    color: "white",
    width: "fit-content",
    borderRadius: 10,
    padding: 2,
    fontSize: 12,
    marginRight: 10,
  };

  useEffect(() => {
    if (cohort) {
      console.log(cohort);
      if (cohort.cohortModuleWeights) {
        let moduleEctsMap = {};
        for (let module of cohort.cohortModuleWeights) {
          moduleEctsMap[module.title.replaceAll('"', "").trim()] = module.ects;
        }
        setModuleEcts(moduleEctsMap);
        setCohortModules(cohort.cohortModuleWeights);
      } else if (cohort.moduleMap) {
        let modules = Object.values(cohort.moduleMap);
        let cohortModules = [];
        for (let m of modules) {
          cohortModules.push(parseModule(m));
        }
        setCohortModules(cohortModules);
      }

      if (cohort.studentTranscript) {
        setStudentTranscript(cohort.studentTranscript);
      }
    }
  }, [cohort]);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      setStage(generatingStages[count]);
      setInterval(() => {
        count = count + 1;
        setStage(generatingStages[count]);
      }, 2700);
    }
  }, [isLoading]);

  const parseModule = (mod) => {
    let newModule = {
      id: mod.id,
      title: mod.title,
      ects: 6,
      assignments: [],
      discussions: [],
      parentAssignmentWeight: 80,
      parentDiscussionWeight: 20,
    };

    for (let a of mod.assignments) {
      newModule.assignments.push({
        type: a.type,
        title: a.title,
        dueAt: a.dueAt,
        contentId: a.contentId,
        percentage: parseFloat((100 / mod.assignments.length).toFixed(2)),
      });
    }
    for (let d of mod.discussions) {
      newModule.discussions.push({
        type: d.type,
        title: d.title,
        dueAt: d.dueAt,
        contentId: d.contentId,
        percentage: parseFloat((100 / mod.discussions.length).toFixed(2)),
      });
    }

    return newModule;
  };

  const handleSaveWeights = () => {
    let payload = {
      cohortModuleWeights: cohortModules,
    };
    editCohort(cohort._id, payload, user.idToken)
      .then((result) => {
        toast.success(`Weights successfully edited`);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
      });
  };

  const handleRemoveWeights = () => {
    let payload = {
      cohortModuleWeights: null,
      studentTranscript: null,
    };
    editCohort(cohort._id, payload, user.idToken)
      .then((result) => {
        toast.success(`Weights successfully edited`);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
      });
  };

  const handlePercentages = (value, moduleIdx, contentIdx, type) => {
    let newModules = [...cohortModules];
    newModules[moduleIdx][type][contentIdx].percentage = value;
    setCohortModules(newModules);
  };

  const handleParentPercentages = (value, moduleIdx, type) => {
    let newModules = [...cohortModules];
    newModules[moduleIdx][type] = value;
    if (type == "parentAssignmentWeight")
      newModules[moduleIdx]["parentDiscussionWeight"] = 100 - value;
    if (type == "parentDiscussionWeight")
      newModules[moduleIdx]["parentAssignmentWeight"] = 100 - value;
    setCohortModules(newModules);
  };

  const handleEcts = (ects, moduleIdx) => {
    let newModules = [...cohortModules];
    newModules[moduleIdx].ects = ects;
    setCohortModules(newModules);
  };

  const generateTranscript = () => {
    let weightsMap = {};
    for (let m of cohortModules) {
      weightsMap[m.title.trim()] = {
        discussions: m.parentDiscussionWeight,
        assignments: m.parentAssignmentWeight,
      };

      for (let a of m.assignments) {
        weightsMap[m.title.trim()][a.title] = a.percentage;
      }
      for (let a of m.discussions) {
        weightsMap[m.title.trim()][a.title] = a.percentage;
      }
    }

    let data = {
      canvasUrl: cohort.canvasUrl,
      moduleMap: cohort.moduleMap,
      weightsMap: weightsMap,
      page: page,
    };

    setIsLoading(true);
    bulkGenerateTranscript(data, user.idToken)
      .then((result) => {
        toast.success(`Weights successfully edited`);
        constructTable(result);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
        console.log(error);
        setIsLoading(false);
      });
  };

  const constructTable = (result) => {
    let table = [];

    console.log("result");
    console.log(result);

    for (let s of result.transcript.students) {
      let totalMarks = 0;
      let totalModuleMarks = 0;
      let totalEcts = 0;
      let student = {
        name: s.name,
        sisId: s.sisId,
      };

      for (let t of s.transcript) {
        let score = 0;
        if (t.moduleScore) {
          score = parseInt(t.moduleScore.toFixed(0));
        }

        let modEcts = moduleEcts[t.title];
        student[t.title] = {
          score: score,
          ects: modEcts,
          submissions: [],
        };

        if (score > 0) {
          totalMarks = totalMarks + score * modEcts;
          totalModuleMarks = totalModuleMarks + parseFloat(t.moduleScore.toFixed(2));;
          totalEcts = totalEcts + modEcts;
        }

        for (let d of t.discussions) {
          student[t.title].submissions.push("discussion");
          if (d.submissionDate)
            student[t.title]["submissionDate"] = d.submissionDate;
        }
        for (let a of t.assignments) {
          student[t.title].submissions.push("assignment");
          if (a.submissionDate)
            student[t.title]["submissionDate"] = a.submissionDate;
        }
      }

      let averageMark = totalMarks / totalEcts;

      let averageModuleMark = totalModuleMarks / s.transcript.length;
      
      console.log("Total Marks: " + totalMarks);
      console.log("Total Module Marks: " + totalModuleMarks);
      console.log("Total Ects: " + totalEcts);
      console.log("Average Mark: " + averageMark);
      console.log("Final Mark: " + Math.round(averageMark,2));
      console.log("Final Module Mark: " + Math.round(averageModuleMark));
      
      // student["finalMark"] = Math.round(averageMark*10)/10 ;
      student["finalMark"] =Math.round(averageModuleMark) ;
      console.log(student);
      table.push(student);
    }

    setStudentTranscript(table);

    let payload = {
      studentTranscript: table,
    };

    editCohort(cohort._id, payload, user.idToken)
      .then(() => {
        toast.success(`Student Transcript successfully edited`);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
      });
  };

  const handleReportDownload = async (studentIdx) => {
    setIsLoading(true);
    toast.success(`Loading....`);

    let studentList = [studentTranscript[studentIdx]];
    if (reports[selectedReport] == "Diploma Supplement") {
      handleStudentDiplomaSupplement(studentList);
    }
    if (reports[selectedReport] == "Record of Work") {
      generateRecordOfWork(studentList);
    }
    if (reports[selectedReport] == "Transcript") {
      generateTranscriptPdf(studentList);
    }
    if (reports[selectedReport] == "Completion Letter") {
      generateCompletionLetter(studentList);
    }
    if (reports[selectedReport] == "Certificate") {
      generateCertificate(studentList);
    }
  };

  // const handleDownloadDiplomaSupplementZip = async (studentIdx = null) => {
  //   setIsLoading(true);
  //   toast.success(`Loading....`);
  //   let studentTranscriptList = [...studentTranscript];

  //   var i,
  //     j,
  //     page,
  //     pageLimit =
  //       studentTranscriptList.length > 12 ? 11 : studentTranscriptList.length;

  //   for (i = 0, j = studentTranscriptList.length; i < j; i += pageLimit) {
  //     page = studentTranscriptList.slice(i, i + pageLimit);
  //     let newStudentTranscript = await removeKeyDot(page);
  //     let payload = {
  //       courseId: cohort.courseId,
  //       cohortId: cohort._id,
  //       studentTranscript: newStudentTranscript,
  //       passRanges: passRanges[selectedPassRange].ranges,
  //       cohortType: cohortType,
  //     };

  //     bulkDiplomaSupplement(payload, user.idToken)
  //       .then((result) => {
  //         handleDownload(result.uri, "diploma_supplement");
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error(
  //           "Oops! Something went wrong. Check that you have the correct information"
  //         );
  //         // toast.dark(error.message);
  //         if (error.data) {
  //           toast.dark(error.data);
  //         }
  //       });
  //   }
  // };

  const handleStudentDiplomaSupplement = async (studentTranscriptList) => {
    let newStudentTranscript = await removeKeyDot(studentTranscriptList);
    let payload = {
      courseId: cohort.courseId,
      cohortId: cohort._id,
      studentTranscript: newStudentTranscript,
      passRanges: passRanges[selectedPassRange].ranges,
      cohortType: cohortType,
    };

    bulkDiplomaSupplement(payload, user.idToken)
      .then((result) => {
        let studentName = studentTranscriptList[0].name.replaceAll(" ", "_");
        handleDownload(result.uri, `diploma_supplement_${studentName}`);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        if (error.message) {
          toast.dark(error.message);
        }
        setIsLoading(false);

      });
  };

  const generateRecordOfWork = async (studentTranscriptList) => {
    let newStudentTranscript = await removeKeyDot(studentTranscriptList);

    let payload = {
      courseId: cohort.courseId,
      cohortId: cohort._id,
      studentTranscript: newStudentTranscript,
      passRanges: passRanges[selectedPassRange].ranges,
    };
    bulkRecordOfWork(payload, user.idToken)
      .then((res) => {
        let studentName = studentTranscriptList[0].name.replaceAll(" ", "_");

        handleDownload(res.uri, `record_of_work_${studentName}`);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.data) {
          toast.dark(error.data);
        }
        setIsLoading(false);
      });
  };

  const generateTranscriptPdf = async (studentTranscriptList) => {
    let newStudentTranscript = await removeKeyDot(studentTranscriptList);
    let payload = {
      courseId: cohort.courseId,
      cohortId: cohort._id,
      studentTranscript: newStudentTranscript,
      passRanges: passRanges[selectedPassRange].ranges,
    };

    bulkTranscriptPdf(payload, user.idToken)
      .then((res) => {
        let studentName = studentTranscriptList[0].name.replaceAll(" ", "_");
        handleDownload(res.uri, `transcript_${studentName}`);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.data) {
          toast.dark(error.data);
        }
        setIsLoading(false);
      });
  };

  const generateCompletionLetter = async (studentTranscriptList) => {
    let newStudentTranscript = await removeKeyDot(studentTranscriptList);
    let payload = {
      courseId: cohort.courseId,
      cohortId: cohort._id,
      studentTranscript: newStudentTranscript,
      passRanges: passRanges[selectedPassRange].ranges,
    };

    bulkCompletionLetter(payload, user.idToken)
      .then((res) => {
        let studentName = studentTranscriptList[0].name.replaceAll(" ", "_");
        handleDownload(res.uri, `completion_letter_${studentName}`);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.data) {
          toast.dark(error.data);
        }
        setIsLoading(false);
      });
  };

  const generateCertificate = async (studentTranscriptList) => {
    let newStudentTranscript = await removeKeyDot(studentTranscriptList);
    let payload = {
      courseId: cohort.courseId,
      cohortId: cohort._id,
      studentTranscript: newStudentTranscript,
      passRanges: passRanges[selectedPassRange].ranges,
    };

    bulkCertificate(payload, user.idToken)
      .then((res) => {
        let studentName = studentTranscriptList[0].name.replaceAll(" ", "_");
        handleDownload(res.uri, `certificates_${studentName}`);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.data) {
          toast.dark(error.data);
        }
        setIsLoading(false);
      });
  };
  const removeKeyDot = (students) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newStudentTranscript = [];
        for (let student of students) {
          let newStudent = {};

          for (let key of Object.keys(student)) {
            if (!key.includes("Module")) {
              newStudent[key] = student[key];
            } else {
              newStudent[key.replaceAll(".", "_")] = student[key];
            }
          }
          newStudentTranscript.push(newStudent);
        }

        resolve(newStudentTranscript);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleDownload = (uri, title) => {
    return new Promise(async (resolve, reject) => {
      try {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + uri;
        a.download =
          cohort.title + `_${title}_` + moment().format("DD_MM_YYYY") + ".pdf";
        a.click();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };
  return (
    <Panel
      title="Cohort Assignments"
      loading={!cohort}
      sectionDescription={sectionDescription}
      actionName={"Save Weights"}
      handleAction={handleSaveWeights}
    >
      <div>
        {cohort && cohort.canvasUrl == null && (
          <Typography className={classes.emptyText} align="center">
            ** Assign a canvas url to view related assignments **
          </Typography>
        )}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}
      >
        <span style={{ color: "#00cc00", marginRight: 20 }}>• Discussions</span>
        <span style={{ color: "#Ffa500" }}>• Assignments</span>
      </div>

      <Button
        style={{
          backgroundColor: "#ff000080",
          marginBottom: 10,
          textTransform: "capitalize",
          color: "white",
          fontSize: 12,
        }}
        onClick={()=>{handleRemoveWeights()}}
      >
        Refresh weights
      </Button>
      <Grid container spacing={2}>
        {cohortModules.map((item, modIdx) => {
          return (
            <Grid item xs={3} key={`modules-${modIdx}`}>
              <Panel style={{ border: "solid 1px black" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "bold" }}>{item.title}</p>
                  <TextField
                    placeholder={"ects"}
                    value={item.ects}
                    label="ects"
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(event) => {
                      handleEcts(parseInt(event.target.value), modIdx);
                    }}
                    margin="none"
                    InputProps={{
                      style: { fontSize: 12, padding: 0 },
                    }}
                    inputProps={{
                      style: {
                        padding: "10px 2px 10px 10px ",
                      },
                    }}
                    style={{ width: "20%", paddingRight: 0 }}
                  ></TextField>
                </div>
                {item.discussions.map((item, discussionIdx) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <p style={{ width: "80%", margin: "10px 0" }}>
                        <span style={{ color: "#00cc00", fontWeight: "bold" }}>
                          •{" "}
                        </span>
                        {item.title}
                      </p>
                      <TextField
                        placeholder={"%"}
                        required
                        value={item.percentage}
                        variant="outlined"
                        size="small"
                        type="number"
                        onChange={(event) => {
                          handlePercentages(
                            parseInt(event.target.value),
                            modIdx,
                            discussionIdx,
                            "discussions"
                          );
                        }}
                        margin="none"
                        InputProps={{
                          style: { fontSize: 12, padding: 0 },
                        }}
                        inputProps={{
                          style: {
                            padding: "10px 2px 10px 10px ",
                          },
                        }}
                        style={{ width: "20%", paddingRight: 0 }}
                      ></TextField>
                    </div>
                  );
                })}
                {item.assignments.map((item, assignmentIdx) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <p style={{ width: "80%", margin: "10px 0" }}>
                        <span style={{ color: "#Ffa500" }}>• </span>
                        {item.title}
                      </p>
                      <TextField
                        placeholder={"%"}
                        required
                        value={item.percentage}
                        variant="outlined"
                        size="small"
                        type="number"
                        className={{ padding: 0 }}
                        onChange={(event) =>
                          handlePercentages(
                            parseInt(event.target.value),
                            modIdx,
                            assignmentIdx,
                            "assignments"
                          )
                        }
                        margin="none"
                        InputProps={{
                          style: { fontSize: 12 },
                        }}
                        inputProps={{
                          style: {
                            padding: "10px 2px 10px 10px ",
                          },
                        }}
                        style={{ width: "20%" }}
                      ></TextField>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    placeholder={"%"}
                    required
                    value={item.parentDiscussionWeight}
                    variant="outlined"
                    size="small"
                    type="number"
                    className={{ padding: 0 }}
                    onChange={(event) =>
                      handleParentPercentages(
                        parseInt(event.target.value),
                        modIdx,
                        "parentDiscussionWeight"
                      )
                    }
                    margin="none"
                    InputProps={{
                      style: { fontSize: 12 },
                    }}
                    inputProps={{
                      style: {
                        padding: "5px 2px 5px 10px",
                        color: " #00cc00",
                      },
                    }}
                    style={{ width: "20%", marginRight: 10 }}
                  ></TextField>
                  <TextField
                    placeholder={"%"}
                    required
                    value={item.parentAssignmentWeight}
                    variant="outlined"
                    size="small"
                    type="number"
                    className={{ padding: 0 }}
                    onChange={(event) =>
                      handleParentPercentages(
                        parseInt(event.target.value),
                        modIdx,
                        "parentAssignmentWeight"
                      )
                    }
                    margin="none"
                    InputProps={{
                      style: { fontSize: 12 },
                    }}
                    inputProps={{
                      style: {
                        padding: "5px 2px 5px 10px",
                        color: " #Ffa500",
                      },
                    }}
                    style={{ width: "20%" }}
                  ></TextField>
                </div>
              </Panel>
            </Grid>
          );
        })}
      </Grid>

      {/* ----------------- */}
      <div style={{ marginTop: 30 }}></div>
      <Panel>
        <p style={{ fontWeight: 700 }}>Cohort Type</p>
        <Select
          value={cohortType}
          onChange={(event) => {
            setCohortType(event.target.value);
          }}
        >
          <MenuItem value={`Full Time`}> Full Time</MenuItem>
          <MenuItem value={`Part Time`}> Part Time</MenuItem>
        </Select>
        <p style={{ fontWeight: 700 }}>Ranges</p>
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "50%",
          }}
        >
          {passRanges.map((item, index) => {
            let color = selectedPassRange == index ? "#564181" : "#ffffff";
            let info = "";
            let space = item.ranges.length == 5 ? ` _____ ` : ` ________ `;
            for (let [i, r] of Object.entries(item.ranges)) {
              let separator = i < item.ranges.length - 1 ? space : " ";
              info = info + r.status + separator;
            }

            for (let [i, r] of Object.entries(item.ranges)) {
              let separator = i < item.ranges.length - 1 ? space : " ";
              info = info + r.max + "-" + r.min + separator;
            }

            return (
              <div
                key={`passRanges-${index}`}
                style={{
                  padding: "10px 0px",
                  width: "450px",
                  border: `1px solid ${color}`,
                  boxShadow: "0 10px 40px -10px rgba(0,0,0,0.15)",
                  borderRadius: "10px",
                  position: "relative",
                }}
                onClick={() => {
                  setSelectedPassRange(index);
                }}
              >
                <Tooltip
                  data-html="true"
                  style={{ width: "100px!important" }}
                  title={info}
                >
                  <InfoIcon
                    style={{
                      // fill: "#cecece",
                      fontSize: "small",
                      position: "absolute",
                      right: -5,
                      top: -5,
                    }}
                  />
                </Tooltip>

                <p style={{ margin: 0, textAlign: "center" }}>{item.option}</p>
              </div>
            );
          })}
        </div>

        <p style={{ fontWeight: 700 }}>Report</p>
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "70%",
          }}
        >
          {reports.map((item, index) => {
            let color = selectedReport == index ? "#564181" : "#ffffff";
            return (
              <div
                key={`report-${index}`}
                style={{
                  padding: "10px 0px",
                  width: "450px",
                  border: `1px solid ${color}`,
                  boxShadow: "0 10px 40px -10px rgba(0,0,0,0.15)",
                  borderRadius: "10px",
                  position: "relative",
                }}
                onClick={() => {
                  setSelectedReport(index);
                }}
              >
                <p style={{ margin: 0, textAlign: "center" }}>{item}</p>
              </div>
            );
          })}
        </div>
        {studentTranscript && (
          <div>
            {/* <Button
              style={downloadBtn}
              disabled={isLoading}
              onClick={() => {
                handleDownloadDiplomaSupplementZip();
              }}
            >
              Diploma Supplement
            </Button>
            <Button
              style={downloadBtn}
              disabled={isLoading}
              onClick={() => {
                generateCompletionLetter();
              }}
            >
              Completion Letter
            </Button>

            <Button
              style={downloadBtn}
              disabled={isLoading}
              onClick={() => {
                generateTranscriptPdf();
              }}
            >
              Transcript
            </Button>
            <Button
              style={downloadBtn}
              disabled={isLoading}
              onClick={() => {
                generateRecordOfWork();
              }}
            >
              Record Of Work
            </Button>
            <Button
              style={downloadBtn}
              disabled={isLoading}
              onClick={() => {
                generateCertificate();
              }}
            >
              Certificate
            </Button> */}

            {isLoading && (
              <div
                style={{
                  margin: 10,
                }}
              >
                <CircularProgress size={20} />
                Loading ...
              </div>
            )}
          </div>
        )}
      </Panel>
      {/* ----------------- */}

      <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
        <Button
          onClick={() => {
            generateTranscript();
          }}
          style={{ backgroundColor: "#F3B903", color: "white", marginTop: 20 }}
        >
          Generate Student Results
        </Button>
      </div>
      <div>
        <p>page: </p>
        <input
          style={{ height: "30px" }}
          value={page}
          onChange={(event) => setPage(event.target.value)}
        />
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
          <p
            style={{
              textAlign: "center",
              width: "fit-content",
              marginLeft: 20,
            }}
          >
            {stage}
          </p>
        </div>
      )}
      {/* ----------------- */}

      <div style={{ overflow: "scroll" }}>
        <Table
          className={classes.table}
          style={{ marginBottom: 40, width: "100%" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.labelText}>Download</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography className={classes.labelText}>Student</Typography>
              </TableCell>

              {cohortModules.map((module, idx) => {
                return (
                  <TableCell align="left" key={`module-score-${idx}`}>
                    <Typography
                      className={classes.labelText}
                      style={{ textAlign: "center" }}
                    >
                      {module.title.split("-")[0]}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell align="left">
                <Typography
                  className={classes.labelText}
                  style={{ textAlign: "center" }}
                >
                  Final Mark
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentTranscript.map((student, idx) => {
              return (
                <TableRow hover key={idx} className={classes.row}>
                  <TableCell style={{ padding: 0, textAlign: "center" }}>
                    <span
                      style={pdfBtn}
                      onClick={() => {
                        handleReportDownload(idx);
                      }}
                    >
                      ↓
                    </span>
                  </TableCell>

                  <TableCell style={{ padding: 5 }}>{student.name}</TableCell>
                  {cohortModules.map((item) => {
                    return (
                      <TableCell
                        style={{ padding: 5, textAlign: "center" }}
                        align="left"
                      >
                        <div>
                          <div style={{ textAlign: "center" }}>
                            {student[item.title.trim()]?.score}
                          </div>

                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                              merginTop: "-15px",
                            }}
                          >
                            {student[item.title.trim()]?.submissions.map(
                              (item) => {
                                let color = "#f7f7f7";
                                if (item == "discussion") color = "#00cc00";
                                if (item == "assignment") color = "#FFA500";
                                return (
                                  <div
                                    style={{
                                      backgroundColor: color,
                                      width: 5,
                                      height: 5,
                                      borderRadius: "50%",
                                      margin: 2,
                                    }}
                                  ></div>
                                );
                              }
                            )}
                          </span>
                        </div>
                      </TableCell>
                    );
                  })}
                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                    {student.finalMark}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Panel>
  );
}
