const styles = theme => ({
    root: {
        marginTop: -10,
        marginLeft: `auto`,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        },
        [theme.breakpoints.up('md')]: {
          display: 'block'
        }
    },
    avatar: {
      margin: 10,
      width: 30,
      height: 30,
      marginBottom: 15,
      border:'2px solid rgba(255,255,255,1)',
      boxShadow: '0 0 0px 0px white',
      overflow: 'hidden'
    },
    button: {
      borderRadius: 4,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 15
    },
    text: {
      marginLeft: 6,
      color: 'white',
      textColor: 'white',
      textTransform: 'none',
      fontFamily: 'proximaNova-bold',
      fontSize: 15
    },
    icon: {
      color: 'white'
    },
    menuList: {
      borderRadius: 4,
      boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .6)'
    },
    menuItemTop: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    menuItemBottom: {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    menu: {
      minWidth: 120,
      borderRadius: 4,
      marginTop: 8
    }
});
  
export default styles;
  