import React, {useContext} from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import deleteReferral from ".";



export default withSnackbar(
  withRouter(function (props) {

    const { isOpen, setClose, referral, deleteReferral, user } = props;


    const [saveLoading, setSaveLoading] = React.useState(false);


    const handleDelete = () => {
      if (user && user != null) {
        setSaveLoading(true);

        deleteReferral(referral._id, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Successfully deleted`);

            setTimeout(()=>{
              window.location.href="/general/referrals"
            },1000)
           
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete Referral"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDelete()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this referral?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
