

export default [
  // Admin

  {
    name: "Admin",
    enabled: true,
    items: [
      {
        name: "Users",
        icon: "people_outlined",
        dashboardPath: "users/all",
        enabled: true,
      },
      {
        name: "Backoffice",
        icon: "lock_open",
        dashboardPath: "backoffice",
        enabled: true,
      },
      {
        name: "Costings",
        icon: "savings",
        dashboardPath: "programme-costings",
        enabled: true,
      },
      {
        name: "Timesheets",
        icon: "schedule",
        dashboardPath: "timesheets",
        enabled: true,
      },
    ],
  },
  // Group
  {
    name: "Group",
    enabled: true,
    items: [
      {
        name: "Clients",
        icon: "contacts",
        dashboardPath: "clients",
        enabled: true,
      },
      {
        name: "Out of Office",
        icon: "self_improvement",
        dashboardPath: "out-of-office",
        enabled: true,
      },
    ],
  },
  {
    name: "Academy",
    enabled: true,
    items: [
      {
        name: "Dashboard",
        icon: "equalizer",
        dashboardPath: "academy-dashboard",
        enabled: true,
      },
      {
        name: "Today",
        icon: "calendar_today",
        dashboardPath: "today",
        enabled: true,
      },
      {
        name: "Applicants",
        icon: "subject_icon",
        dashboardPath: "applicants",
        enabled: true,
      },
      {
        name: "Students",
        icon: "subject_icon",
        dashboardPath: "students",
        enabled: true,
      },
      {
        name: "Content",
        icon: "school",
        dashboardPath: "content",
        enabled: true,
      },
      {
        name: "Cohorts",
        icon: "terrain",
        dashboardPath: "cohorts",
        enabled: true,
      },
      // {
      //   name: "Training",
      //   icon: "layers",
      //   dashboardPath: "training",
      //   enabled: true,
      // },
      {
        name: "Administration",
        icon: "supervisor_account",
        dashboardPath: "administration",
        enabled: true,
      },
      {
        name: "General",
        icon: "priority_high",
        dashboardPath: "general",
        enabled: true,
      }
    ],
  },
   
  // Sales

// Advisory Admin

  // Advisory
  {
    name: "Advisory",
    enabled: true,
    items: [
      {
        name: "Dashboard",
        icon: "equalizer",
        dashboardPath: "advisory-dashboard",
        enabled: true,
      },
      {
        name: "Clients",
        icon: "people",
        dashboardPath: "advisory-clients",
        enabled: true,
      },
      {
        name: "Proposals",
        icon: "bookmarks_outline",
        dashboardPath: "proposals",
        enabled: true,
      },
      {
        name: "Projects",
        icon: "work_outline",
        dashboardPath: "projects",
        enabled: true,
      },
      {
        name: "Consultants",
        icon: "subject_icon",
        dashboardPath: "consultants",
        enabled: true,
      }
     
    ],
  }, 
  {
    name: "Advisory Admin",
    enabled: true,
    items: [
      {
        name: "Payments",
        icon: "euro",
        dashboardPath: "payments",
        enabled: true,
      },
      {
        name: "Industries",
        icon: "subject_icon",
        dashboardPath: "industries",
        enabled: true,
      },
    ],
  }, 
  
  {
    name: "Sales",
    enabled: true,
    items: [
      {
        name: "Calculators",
        icon: "memory",
        dashboardPath: "calculators",
        enabled: true,
      },
      {
        name: "Leads",
        icon: "people",
        dashboardPath: "leads",
        enabled: true,
      },
    ],
  },
   


  // Resourcing

  {
    name: "Resourcing",
    enabled: true,
    items: [
      {
        name: "Dashboard",
        icon: "equalizer",
        dashboardPath: "resourcing-dashboard",
        enabled: true,
      },
      {
        name: "Clients",
        icon: "people",
        dashboardPath: "resourcing-clients",
        enabled: true,
      },
      {
        name: "Vacancies",
        icon: "work_outline",
        dashboardPath: "vacancies",
        enabled: true,
      },
      {
        name: "Candidates",
        icon: "emoji_people",
        dashboardPath: "candidates",
        enabled: true,
      },
      {
        name: "Industries",
        icon: "subject_icon",
        dashboardPath: "industries",
        enabled: true,
      },
    ],
  }, 

  
  // Intelligence

  {
    name: "Intelligence",
    enabled: false,
    items: [
      {
        name: "Users",
        icon: "people_outlined",
        dashboardPath: "users",
        enabled: true,
      },
    ],
  },

  
];
/*

 {
              'name': 'Overview',
              'icon': 'fiber_manual_record_outlined',
              'dashboardPath': 'overview',
              'enabled': false
          },

          
{
      'name': 'Marketing',
      'items': [
          {
            'name': 'Analytics',
            'icon': 'timeline_icon',
            'dashboardPath': 'analytics',
            'enabled': false
          },
          {
            'name': 'Outreach',
            'icon': 'wifi_tethering_outlined_icon',
            'dashboardPath': 'outreach',
            'enabled': false
          }
      ]
  },
*/
