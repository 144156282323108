import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import Dashboard from "../../routes/dashboard";
import Login from "routes/login";
import NotFound from "../../routes/not-found";
import ScrollToTop from "utils/helpers/scrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";

const AuthenticatedRoute = (props) => {
  if (props.authenticated) {
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};

const NonAuthenticatedRoute = (props) => {
  if (props.authenticated) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};

const routeProps = {
  authenticated: PropTypes.bool,
  exact: PropTypes.bool,
  path: PropTypes.string
};

const Routes = () => {
  const { authenticated } = useContext(AuthenticationContext);

  return (
    <Router>
      {/* <ScrollToTop> */}
        <Switch>
       
        <NonAuthenticatedRoute
            authenticated={authenticated}
            exact
            path="/login"
            component={() => <Login />}
            />
            
          <AuthenticatedRoute
            authenticated={authenticated}
            exact={false}
            path="/"
            component={() => <Dashboard />}
            />
           
          
          <Route exact path="/404" component={() => <NotFound />} />
          <Route component={() => <Redirect to="/404" />} />
        </Switch>
      {/* </ScrollToTop> */}
      <ToastContainer />
    </Router>
  );
};

AuthenticatedRoute.propTypes = routeProps;
NonAuthenticatedRoute.propTypes = routeProps;

export default Routes;
