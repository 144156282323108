import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllSessions = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/training/sessions").find({
          query: {
            firebaseToken: token,
          },
        });
        dispatch({
          type: "UpdateSessions",
          payload: result.data,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewSession = async (dispatch, location, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/training/sessions")
          .create(location, {
            query: {
              firebaseToken: token,
            },
          });
        dispatch({
          type: "AddSession",
          payload: result,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editSession = async (dispatch, data, locationId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let updatedProposal = await apiClient
          .service("v1/training/sessions")
          .patch(locationId, data, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "EditSession",
          payload: {
            proposalId: locationId,
            data: updatedProposal,
          },
        });

        resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteSession = async (dispatch, proposalId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/training/sessions")
          .remove(proposalId, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "RemoveSession",
          payload: result,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleSession = async (proposalId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient
          .service("v1/training/sessions")
          .get(proposalId, {
            query: {
              firebaseToken: token,
            },
          });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
