import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import PaymentGantt from "./payment-gantt-view";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import moment from "moment";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { getProjects, findProjectPaymentByYear, getProjectsByYearTag } =
    useContext(AllProjectsContext);

  const [projects, setProjects] = useState(null);
  const [payments, setPayments] = useState(null);
  const [projectPaymentMap, setProjectPaymentMap] = useState(null);

  const [currentYear, setCurrentYear] = useState(moment());

  // Data Fetching
  useEffect(() => {
    getPaymentData(currentYear);
  }, []);

  const getPaymentData = (currentYear) => {
    setProjectPaymentMap(null);

    let year = currentYear.format("YYYY");

    getProjectsByYearTag(year).then((result) => {
      setProjects(result);

      findProjectPaymentByYear(year).then((result) => {
        setPayments(result);

        let projectPaymentMap = result.reduce(function (rv, x) {
          (rv[x["projectId"]] = rv[x["projectId"]] || []).push(x);
          return rv;
        }, {});


        setTimeout(() => {
          setProjectPaymentMap(projectPaymentMap);
        }, 10);
      });
    });
  };

  const handleChangeYear = (isForward) => {
    let year = isForward
      ? currentYear.add(1, "years")
      : currentYear.subtract(1, "years");

    setCurrentYear(year);
    getPaymentData(year);
  };

  return (
    <div>
      {projectPaymentMap && payments && projects && (
        <PaymentGantt
          user={user}
          projects={projects}
          payments={payments}
          currentYear={currentYear}
          handleChangeYear={handleChangeYear}
          projectPaymentMap={projectPaymentMap}
        />
      )}
    </div>
  );
});
