import React from "react";

// Navigation
import NavCrumbs from "routes/dashboard/nav-crumbs";

export default function (props) {
  let { user, authUser } = props;

  let crumbs = [
    { name: "Users", path: "users" },
    { name: "All Users", path: "users/all" },
    { name: "...", path: "#" },
  ];

  if (user != null) {
    crumbs[2] = {
      name: `${user.firstName} ${user.lastName}`,
      path: `users/${user._id}`,
    };
  }

  return (
    <div>
      <NavCrumbs crumbs={crumbs} />
      {props.children}
    </div>
  );
}
