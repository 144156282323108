import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Card,
  Link,
  Checkbox,
  InputBase,
} from "@material-ui/core";
import { ArrowForwardRounded } from "@material-ui/icons";
// import { loginWithEmail } from 'repository/actions/data/authentication';
// import { AuthenticationContext } from 'repository/stores/global/authentication-provider';
import Loading from "components/loading";
import { toast } from "react-toastify";
// Assets
import LogoImage from "assets/images/IDEA Group.png";

// Styles
import styles from "./center-content-styles";
const useStyles = makeStyles(styles);

const LoginCenterContentView = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    onLogin,
    email,
    password,
    setEmail,
    setPassword,
    errorText,
    loading,
  } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <img src={LogoImage} className={classes.logo}></img>
        <Typography className={classes.title}>
          Sign in to your account 👋
        </Typography>
        <Typography className={classes.description}>
          Type in your credentials below.
        </Typography>
        <div className={classes.space}></div>
        <div className={classes.space}></div>
        <InputBase
          className={classes.textField}
          placeholder="Please enter your email"
          label="Email"
          disabled={isLoading}
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          fullWidth
          required
        />
        <div className={classes.space}></div>
        <InputBase
          className={classes.textField}
          placeholder="Please enter your password"
          label="Password"
          type="password"
          disabled={isLoading}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          fullWidth
          required
        />
        {errorText != null && (
          <Typography className={classes.verifyText}>{errorText}</Typography>
        )}
        {!loading && (
          <Button
            className={classes.facebookButton}
            onClick={() => onLogin()}
            variant="contained"
          >
            Login and continue
            <ArrowForwardRounded className={classes.facebookButtonIcon} />
          </Button>
        )}
        {loading && <Loading />}
      </Card>
    </div>
  );
};
export default LoginCenterContentView;
