import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import UsersView from "./users-all-users-view";

// Dialogues
import DialogueCreateUser from "routes/dashboard/dialogues/users/create-user";
import DialogueEditUser from "routes/dashboard/dialogues/users/edit-user";
import DialogueDeleteUser from "routes/dashboard/dialogues/users/delete-user";
import DialogueResetPassword from "routes/dashboard/dialogues/users/reset-password";
// Actions
// import { findAllUsers } from "repository/actions/data/dashboard/users";

export default withRouter(function (props) {
  const {
    users,
    setUsers,
    findActiveUsers,
    createNewUser,
    editUser,
    deleteUser,
    resetPassword,
  } = useContext(AllUsersContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewUsersDialogue, setShowNewUsersDialogue] = useState(false);
  const [showEditUsersDialogue, setShowEditUsersDialogue] = useState(false);
  const [showDeleteUsersDialogue, setShowDeleteUsersDialogue] = useState(false);

  const [showResetPasswordDialogue, setShowResetPasswordDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [editingItem, setEditingItem] = useState(null);

  const [activeUsers, setActiveUsers] = useState(null);
  const [inactiveUsers, setInactiveUsers] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (user.roles.indexOf("admin") >= 0) {
        if (users == null) {
          findActiveUsers(user.idToken, true).then((res) => {
            setActiveUsers(res.data);
          });
          findActiveUsers(user.idToken, false).then((res) => {
            setInactiveUsers(res.data);
          });

        }
      } else {
        window.location.href = "/";
      }
    }
  }, [users]);

  // useEffect(() => {
  //   // Editing Item
  //   if (users && user != null && selectedIndex && selectedIndex != null) {
  //     if (users[selectedIndex]) {
  //       setEditingItem(users[selectedIndex]);
  //     }
  //   }
  // }, [selectedIndex]);

  return (
    <div>
      <UsersView
        loading={!users}
        onPressedItem={(id) => (window.location.href = `/users/${id}`)}
        toggleAddUserForm={() => setShowNewUsersDialogue(true)}
        sectionDescription={"You can manage all of your users below."}
        activeUsers={activeUsers || []}
        inactiveUsers={inactiveUsers || []}
        setEditingItem={setEditingItem}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditUsersDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteUsersDialogue(true);
        }}
        onResetPassword={(row) => {
          console.log(`passing row : ${row.email}`);
          setEditingItem(row);
          setShowResetPasswordDialogue(true);
        }}
        user={user}
      />
      <DialogueCreateUser
        isOpen={showNewUsersDialogue}
        setClose={() => setShowNewUsersDialogue(false)}
        user={user}
        createNewUser={createNewUser}
      />
      <DialogueEditUser
        isOpen={showEditUsersDialogue}
        setClose={() => {
          setShowEditUsersDialogue(false);
          setSelectedIndex(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        editUser={editUser}
      />
      <DialogueDeleteUser
        isOpen={showDeleteUsersDialogue}
        setClose={() => {
          setShowDeleteUsersDialogue(false);
          setSelectedIndex(null);
        }}
        itemId={selectedId}
        user={user}
        deleteUser={deleteUser}
      />
      <DialogueResetPassword
        isOpen={showResetPasswordDialogue}
        setClose={() => {
          setShowResetPasswordDialogue(false);
          setSelectedIndex(null);
        }}
        userId={selectedId}
        user={editingItem}
        resetPassword={resetPassword}
      />
    </div>
  );
});
