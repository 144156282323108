import React, { useContext, useEffect, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../../stores/global/authentication-provider";
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  cohorts: null,
  setCohorts: {},
  getCohort: {},
  getCohorts: {},
  loadingCohorts: false,
  deleteCohort: {},
  createNewCohort: {},
  editCohort: {},
  bulkAssignLecturers:{}
};

export const AllCohortsContext = React.createContext(ContextProps);

export const AllCohortsProvider = ({ children }) => {
  const [cohorts, setCohorts] = useState(null);
  const [loadingCohorts, setLoadingCohorts] = useState(false);
  const { user } = useContext(AuthenticationContext);

  const { getIdToken } = useContext(IdTokenContext)


  const getCohorts = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        setLoadingCohorts(true);
        let result = await apiClient.service("v1/cohorts").find({
          query: {
            firebaseToken: token,
          },
        });

        setLoadingCohorts(false);
        setCohorts(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const bulkAssignLecturers = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-lecturers"
            },
          });
          
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewCohort = async (cohort, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/cohorts").create(
          { ...cohort, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        if (cohorts == null) {
          setCohorts(result);
        } else {
          let newCohorts = cohorts;
          newCohorts.push(result);
          setCohorts(newCohorts);
        }
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const getCohort = (id, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/cohorts").get(id, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editCohort = async (data, cohortId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let updatedCohort = await apiClient
          .service("v1/cohorts")
          .patch(cohortId, data, {
            query: {
              firebaseToken: token,
            },
          });

        let newData = cohorts;
        for (const [i, v] of newData.entries()) {
          if (v._id === cohortId) {
            newData[i] = data;
          }
        }
        setCohorts(newData);

        resolve();
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteCohort = async (cohortId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/cohorts").remove(cohortId, {
          query: {
            firebaseToken: token,
          },
        });

        let newData = cohorts;
        for (const [i, v] of newData.entries()) {
          if (v._id === cohortId) {
            newData.splice(i, 1);
          }
        }
        setCohorts(newData);

        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllCohortsContext.Provider
      value={{
        cohorts,
        setCohorts,
        loadingCohorts,
        getCohort,
        getCohorts,
        createNewCohort,
        editCohort,
        deleteCohort,
        bulkAssignLecturers
      }}
    >
      {children}
    </AllCohortsContext.Provider>
  );
};
