import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { deleteSupervisorMentor } from "repository/actions/data/dashboard/students";

export default withSnackbar(
  withRouter(function (props) {
    const {
      isOpen,
      setClose,
      cohort,
      lecturerIndex,
      lecturers,
      user,
      setCohort,
      getCohort,
      editCohort,
      deleteLecturer
    } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteLecturer = () => {
        
      if (user && user != null) {
        setSaveLoading(true);

        setSaveLoading(true);
        toast.dark(`Removing Lecturer`);
        deleteLecturer(
          cohort._id,
          lecturers[lecturerIndex].person._id,
          user.idToken
        ).then((result)=>{
            setSaveLoading(false);
            toast.success(`Lecturer Removed`);
            setClose();
            // setStudent(result);
            
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Remove Lecturer"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteLecturer ()}
        actionButtonTitle="Yes, I'm sure."
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to remove this lecturer ?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
