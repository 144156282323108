import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Link,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Input,
  InputLabel,
  Select,
  Radio,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { createStudentLetter } from "repository/actions/data/dashboard/students";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    documents,
    setLetters,
    student,
    setStudent,
    getStudent,
    cohorts,
    setDocuments,
    setCohorts,
    user,
  } = props;

  const [title, setTitle] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [attachmentName, setAttachmentName] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [acceptanceStatus, setAcceptanceStatus] = React.useState("pending");
  const [selectedCohortId, setSelectedCohortId] = React.useState(null);

  const [cohortHasPaymentSchedule, setCohortHasPaymentSchedule] =
    React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [attachmentError, setAttachmentError] = React.useState(null);
  const [selectedCohortIdError, setSelectedCohortIdError] =
    React.useState(null);

  const handleSaveDocument = () => {
    if (user && user != null) {
      let data = {
        title: title
          ? title
          : `${student.firstName} ${student.lastName} Acceptance Letter`,
        attachment: attachment,
        acceptanceStatus: acceptanceStatus,
      };

      setSaveLoading(true);

      createStudentLetter(
        student._id,
        selectedCohortId,
        data,
        documents,
        setLetters,
        user.idToken
      )
        .then((result) => {
          getStudent(student._id, user.idToken).then((getStudentResult) => {
            toast.success(
              `Document successfully added for ${student.firstName} ${student.lastName}`
            );
            setStudent(getStudentResult);
            setClose();
            setSaveLoading(false);
            setTitle(null);
            setAttachment(null);
            setAttachmentName(null);
            setSelectedCohortId(null);
          });
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    setTitleError(null);
    setAttachmentError(null);
    setAcceptanceStatus(null);
  }, [title, attachment, isOpen]);

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 15728640) {
      toast.error("File is too big. The maximum size allowed is 15MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setAttachment(e.target.result);
        setAttachmentName(target.files[0].name);
        setTitle(target.files[0].name);
      };
    }
  };

  const handleChangeCohortId = (cohortId) => {
    for (let ps of student.paymentSchedules) {
      if (ps.cohortId == cohortId) {
        setCohortHasPaymentSchedule(true);
      }
    }
    if (cohortHasPaymentSchedule) {
      setSelectedCohortId(cohortId);
      setSelectedCohortIdError(
        "This student does not have a payment schedule for this cohort"
      );
    } else {
      toast.error(
        "This student does not have a payment schedule for this cohort"
      );
      toast.dark("You can create payment schedules in this student page");
    }
  };

  return (
    <Popup
      title="Add a new document"
      subtitle="Please fill in the details below to create a new document."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveDocument()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.inputType}>
            <InputLabel required disabled={saveLoading}>
              Select Cohort
            </InputLabel>
            {cohorts && cohorts != null && (
              <Select
                elevation={0}
                onChange={(event) => handleChangeCohortId(event.target.value)}
                value={selectedCohortId}
                required
                error={selectedCohortIdError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {cohorts.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            )}
          </FormControl>
          <FormControl component="fieldset">
            <RadioGroup
              value={acceptanceStatus}
              onChange={(event) => setAcceptanceStatus(event.target.value)}
            >
              <FormControlLabel
                value="accepted Unconditionally"
                control={<Radio />}
                label="Accepted Unconditionally"
              />
              <FormControlLabel
                value="accepted Conditionally"
                control={<Radio />}
                label="Accepted Conditionally"
              />
            </RadioGroup>
          </FormControl>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
