import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions
// import { SingleCohortContext } from "repository/stores/dashboard/cohorts/cohorts-single-provider";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, cohort, user, generateMissingSubmissions } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);
    // const { generateMissingSubmissions } = React.useContext(SingleCohortContext);

    const handleGenerateMissingSubmissions = () => {
      setSaveLoading(true);

      let canvasCourseId = cohort.canvasUrl.substring(
        cohort.canvasUrl.lastIndexOf("/") + 1
      );

      generateMissingSubmissions(canvasCourseId, user.email, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(
            `Any missing submissions per student will be sent to your e-mail.`
          );
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong.");
          toast.dark(error.message);
          setSaveLoading(false);
        });
    };

    return (
      <Popup
        title="Generate Missing Submissions Report"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleGenerateMissingSubmissions()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Ready to generate a missing submissions report for this cohort?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
