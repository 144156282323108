import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllProposals from "./all-proposals";
import SingleProposal from "./single-proposal";

export default function () {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/training/proposals"
          component={() => <AllProposals />}
        />
        <Route
          exact
          path="/training/proposals/:id"
          component={() => <SingleProposal />}
        />
      </Switch>
    </div>
  );
}
