import React, { useContext } from "react";
import { SideNavigationContext } from "repository/stores/dashboard/navigation/side-navigation-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Icon,
} from "@material-ui/core";
import routes from "../fixed-drawer-routes";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";

// Actions
import { showSideNavigation } from "repository/actions/views/navigation";

// Styles
import clsx from "clsx";
import styles from "./fixed-drawer-items-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();
  const [nav, setNav] = useContext(SideNavigationContext);

  const { admin, academy, sales, advisory, resourcing } = useContext(
    AuthenticationContext
  );

  let textStyle, iconStyle;

  function onDashboardPath(path) {
    props.history.push(`/${path}/`);
    showSideNavigation(nav, setNav);
  }

  return (
    <List className={classes.root}>
      {routes
        .filter(function (section) {
          if (admin == true) {
            return section.enabled == true;
          }

          if (
            section.name == "Admin" ||
            section.name == "Advisory Admin"
          ) {
            section.enabled = admin;
          }

          if (section.name == "Academy") {
            section.enabled = academy ;
          }
          if (section.name == "Advisory") {
            section.enabled = advisory;
          }
          if (section.name == "Sales") {
            section.enabled = sales;
          }
          if (section.name == "Resourcing") {
            section.enabled = resourcing;
          }

          return section.enabled == true;
        })
        .map((section) => {
          return (
            <div>
              <Typography className={classes.textFontSizeBold} noWrap>
                {section.name.toUpperCase()}
              </Typography>
              {section.items.map((item) => {
                const match = matchPath(props.location.pathname, {
                  path: `/${item.dashboardPath}`,
                  exact: false,
                });

                if (match) {
                  iconStyle = classes.iconFontSizeHighlighted;
                  textStyle = classes.textFontSizeHighlighted;
                } else {
                  iconStyle = classes.iconFontSize;
                  textStyle = classes.textFontSize;
                }
                return (
                  <div>
                    <ListItem
                      onClick={() => onDashboardPath(item.dashboardPath)}
                      disabled={!item.enabled}
                      className={classes.listItem}
                      selected={false}
                      disableRipple
                      button
                    >
                      <ListItemIcon>
                        <Icon
                          className={clsx(iconStyle, "material-icons-outlined")}
                        >
                          {item.icon}
                        </Icon>
                      </ListItemIcon>
                      <Typography className={textStyle} variant="body2" noWrap>
                        {item.name}
                      </Typography>
                    </ListItem>
                    {!item.enabled && (
                      <Button
                        variant="outlined"
                        disabled
                        className={classes.comingSoon}
                      >
                        Coming Soon
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </List>
  );
});
