import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Panel from "components/panel";
import { getSearchClient } from "utils/algolia";
import { Grid } from "@material-ui/core";

const searchClient = getSearchClient();

const heading = {
  color: "#ffd280",
  fontSize: "60px",
  margin: 0,
  textAlign: "center",
  textShadow:
    " 1px 1px 0 #FFA500, 2px 2px 0 #FFA500, 3px 3px 0 #FFA500, 4px 4px 0 #FFA500, 5px 5px 0 #FFA500,  0 0 5px rgba(255, 165, 0, 0.05), 0 -1px 3px rgba(255, 165, 0, 0.2)",
};

const headingTotal = {
  color: "#00b300",
  fontSize: "60px",
  margin: 0,
  textAlign: "center",
  textShadow:
    " 1px 1px 0 #006700, 2px 2px 0 #006700, 3px 3px 0 #006700, 4px 4px 0 #006700, 5px 5px 0 #006700,  0 0 5px rgba(255, 165, 0, 0.05), 0 -1px 3px rgba(255, 165, 0, 0.2)",
};

export default withRouter(function (props) {
  let { currentYear, projects } = props;

  const [pendingProposals, setPendingProposals] = useState(0);
  const [successfulProposals, setSuccessfulProposals] = useState(0);
  const [revisedProposals, setRevisedProposals] = useState(0);
  const [unsuccessfulProposals, setUnsuccessfulProposals] = useState(0);
  const [totalProposals, setTotalProposals] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);
  const [completeProjects, setCompleteProjects] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);

  useEffect(() => {
    getData();
  }, [projects]);

  const getData = async () => {
    let start = currentYear.startOf("year").valueOf();
    let end = currentYear.endOf("year").valueOf();

    searchClient
      .initIndex("Projects")
      .search("", {
        hitsPerPage: 100,
        filters: `status:Active AND yearTags:${currentYear.format("YYYY")}`,
      })
      .then(({ nbHits }) => {
        setActiveProjects(nbHits);
      });

    searchClient
      .initIndex("Projects")
      .search("", {
        hitsPerPage: 100,

        filters: `status:Complete AND yearTags:${currentYear.format("YYYY")}`,
      })
      .then(({ nbHits }) => {
        setCompleteProjects(nbHits);
      });

    searchClient
      .initIndex("Projects")
      .search("", {
        hitsPerPage: 100,

        filters: `yearTags:${currentYear.format("YYYY")}`,
      })
      .then(({ nbHits }) => {
        setTotalProjects(nbHits);
      });

    searchClient
      .initIndex("Proposals")
      .search("", {
        hitsPerPage: 100,

        filters: `status:Pending AND createdAt:${start} TO ${end}`,
      })
      .then(({ nbHits }) => {
        setPendingProposals(nbHits);
      });

    searchClient
      .initIndex("Proposals")
      .search("", {
        hitsPerPage: 100,

        filters: `status:Successful AND createdAt:${start} TO ${end}`,
      })
      .then(({ nbHits }) => {
        setSuccessfulProposals(nbHits);
      });

      
    searchClient
    .initIndex("Proposals")
    .search("", {
      hitsPerPage: 100,

      filters: `status:Revised AND createdAt:${start} TO ${end}`,
    })
    .then(({ nbHits }) => {
      setRevisedProposals(nbHits);
    });

    searchClient
      .initIndex("Proposals")
      .search("", {
        hitsPerPage: 100,

        filters: `status:Unsuccessful AND createdAt:${start} TO ${end}`,
      })
      .then(({ nbHits }) => {
        setUnsuccessfulProposals(nbHits);
      });

    searchClient
      .initIndex("Proposals")
      .search("", {
        hitsPerPage: 100,
        filters: `createdAt:${start} TO ${end}`,
      })
      .then(({ nbHits }) => {
        setTotalProposals(nbHits);
      });

    searchClient
      .initIndex("Clients")
      .search("", {
        hitsPerPage: 100,
        filters: `businessLines:Advisory AND createdAt:${start} TO ${end}`,
      })
      .then(({ hits }) => {
        if (hits) setTotalClients(hits.length);
      });
  };

  return (
    <Panel title={`Stats - ${currentYear.format("YYYY")}`}>
      <Grid container spacing={4} style={{ textAlign: "center" }}>
        <Grid item xs={4}>
          <Panel title={"Projects"}>
            <Grid container>
              <Grid item xs={4}>
                <p style={heading}> {activeProjects}</p>
                <p>Active</p>
              </Grid>

              <Grid item xs={4}>
                <p style={heading}> {completeProjects}</p>
                <p>Completed</p>
              </Grid>
              <Grid item xs={4}>
                <p style={headingTotal}> {totalProjects}</p>
                <p>Total</p>
              </Grid>
            </Grid>
          </Panel>
        </Grid>
        <Grid item xs={6}>
          <Panel title={"Proposals"}>
            <Grid container>
              <Grid item xs={3}>
                <p style={heading}> {pendingProposals}</p>
                <p>Pending</p>
              </Grid>
              <Grid item xs={2}>
                <p style={heading}> {successfulProposals}</p>
                <p>Successful</p>
              </Grid>
              <Grid item xs={2}>
                <p style={heading}> {revisedProposals}</p>
                <p>Revised</p>
              </Grid>
              <Grid item xs={2}>
                <p style={heading}> {unsuccessfulProposals}</p>
                <p>Unsuccessful</p>
              </Grid>
              <Grid item xs={3}>
                <p style={headingTotal}> {totalProposals}</p>
                <p>Total</p>
              </Grid>
            </Grid>
          </Panel>
        </Grid>

        <Grid item xs={2}>
          <Panel title={"Clients"}>
            <p style={headingTotal}>{totalClients}</p>
            <p>Total</p>
          </Panel>
        </Grid>
      </Grid>
    </Panel>
  );
});
