import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import ProjectsGantt from "./projects-gantt-view";
import ProjectHours from "./project-hours";
import ProposalsPieView from "./proposals";
import StatsView from "./stats";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";
import { AllProposalsContext } from "repository/stores/dashboard/proposals/proposals-all-provider";
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

import { Grid } from "@material-ui/core";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { findClientsByQuery } = useContext(AllClientsContext);
  const { getProjectsByDate, getProjectsByYearTag } =
    useContext(AllProjectsContext);
  const { getProposalsByDate } = useContext(AllProposalsContext);

  const { getConsultants } = useContext(AllConsultantsContext);

  const [projects, setProjects] = useState(null);
  const [proposals, setProposals] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(moment());
  const [currentYear, setCurrentYear] = useState(moment());
  const [minDate, setMinDate] = useState(moment().startOf("year"));

  useEffect(() => {
    if (user && user != null) {
      getYearData(currentYear).then(() => {});
    }
  }, [user, currentYear]);

  const getYearData = async (currentYear) => {
    let yearStart = currentYear.startOf("year").valueOf();
    let yearEnd = currentYear.endOf("year").valueOf();

    getProjectsByYearTag(currentYear.format("YYYY")).then(async (result) => {
      let projects = await trimProjects(result, yearStart, yearEnd);
      setProjects(projects);
      setIsLoading(true);
    });

    // currentYear
    getProposalsByDate(yearStart, yearEnd).then((result) => {
      setProposals(result.data);
    });
  };

  const handleChangeYear = async (isForward) => {
    let year = isForward
      ? currentYear.add(1, "years")
      : currentYear.subtract(1, "years");

    setMinDate(year.startOf("year"));
    setCurrentYear(year);
    getYearData(year);
  };

  const handleCurrentQuarter = async () => {
    let quarterStart = moment()
      .quarter(moment().quarter())
      .startOf("quarter").valueOf();
    let quarterEnd = moment().endOf("year").valueOf();
      
    setMinDate(quarterStart);


    getProjectsByYearTag(moment().format("YYYY")).then(async (result) => {
      let projects = await trimProjects(result, quarterStart, quarterEnd);
      setProjects(projects);
      setIsLoading(true);
    });

  };

  const trimProjects = (projects, start, end) => {
    let startValue = start;
    let endValue = end;


    let trimmedProjects = []
    for (let proj of projects) {

      if(moment(proj.endDate).isAfter(start)){
        trimmedProjects.push(proj)
      }
    }
     
    for (let trimProj of trimmedProjects) {

      trimProj.ganttStartDate = trimProj.startDate;
      trimProj.ganttEndDate = trimProj.endDate;

      if (moment(trimProj.startDate).isBefore(start)) {
        trimProj.ganttStartDate = startValue;
      }

      if (moment(trimProj.endDate).isAfter(end)) {
        trimProj.ganttEndDate = end;
      }
    }

    return trimmedProjects;
  };

  return (
    <div>
      {isLoading && (
        <div>
          <StatsView currentYear={currentYear} projects={projects} />
          <div style={{ marginBottom: 20 }}></div>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ProposalsPieView
                user={user}
                title={"Direct Proposal"}
                type={"Direct Proposal"}
                currentYear={currentYear}
              />
            </Grid>
            <Grid item xs={4}>
              <ProposalsPieView
                user={user}
                title={"Tender BPQR"}
                type={"Tender BPQR"}
                currentYear={currentYear}
              />
            </Grid>
            <Grid item xs={4}>
              <ProposalsPieView
                user={user}
                title={"Tender Cheapest"}
                type={"Tender Cheapest"}
                currentYear={currentYear}
              />
            </Grid>
          </Grid>
          <div style={{ marginBottom: 20 }}></div>

          <ProjectsGantt
            user={user}
            loading={isLoading}
            setProjects={setProjects}
            projects={projects}
            getProjectsByDate={getProjectsByDate}
            getConsultants={getConsultants}
            findClientsByQuery={findClientsByQuery}
            handleChangeYear={handleChangeYear}
            handleQuarter={handleCurrentQuarter}
            minDate={minDate}
            currentYear={currentYear}
          />
          <div style={{ marginBottom: 20 }}></div>

          <ProjectHours
            user={user}
            loading={isLoading}
            setProjects={setProjects}
            projects={projects}
          />
        </div>
      )}
    </div>
  );
});
