import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleTotalsCostView from "./single-totals-cost-view";


export default withRouter(function (props) {
  let {
    loading,
    user,
    programme,
    setProgramme,
    studentTotalCost,
    moduleTotalCost,
    programmeTotalCosts
  } = props;



  return (
    <div>
      <SingleTotalsCostView
        loading={loading}
        programme={programme}
        setProgramme={setProgramme}
        user={user}
        studentTotalCost={studentTotalCost}
        moduleTotalCost={moduleTotalCost}
        programmeTotalCosts={programmeTotalCosts}
      />

    </div>
  );
});
