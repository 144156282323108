import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

//Children
import Day from "./day-timetables-view-new";
import DialoguesEditLecture from "routes/dashboard/dialogues/lectures/edit-lecture-new";

export default withRouter(function (props) {
  const {
    user,
    personnelMap,
    lectures,
    locationMap,
    cohortMap,
    loading,
    weekDay,
    editLecture,
    getCohort,
    locations,
    panelDescription,
    handleWeekSelect,
  } = props;

  const [showEditLectureDialogue, setShowEditLectureDialogue] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);




  // const [currentLectures, setCurrentLectures] = useState(null);

  return (
    <div>
      <Day
        loading={loading}
        personnelMap={personnelMap}
        user={user}
        locations={locations}
        locationMap={locationMap}
        cohortMap={cohortMap}
        lectures={lectures}
        weekDay={weekDay}
        panelDescription={panelDescription}
        onPressedItem={(index) => {
          setSelectedLecture(lectures[index]);
          setShowEditLectureDialogue(true);

        }}
      />

      <DialoguesEditLecture
        isOpen={showEditLectureDialogue}
        setClose={() => {
          setShowEditLectureDialogue(false);
          setSelectedLecture(null);
        }}
        user={user}
        data={selectedLecture}
        editLecture={editLecture}
        getCohort={getCohort}
        locations={locations}
        handleWeekSelect={handleWeekSelect}
      />
    </div>
  );
});
