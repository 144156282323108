import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllLocations from "./all-locations";
import SingleLocation from "./single-location";

export default function () {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/administration/locations"
          component={() => <AllLocations />}
        />
        <Route
          exact
          path="/administration/locations/:id"
          component={() => <SingleLocation />}
        />
      </Switch>
    </div>
  );
}
