import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllProposals from './routes/all-proposals';
import NewProposal from './routes/new-proposal';
import SingleProposal from './routes/single-proposal';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/proposals/pending' component={() => <AllProposals status={"pending"}/>} />
                <Route exact path='/proposals/successful' component={() => <AllProposals status={"successful"}/>} />
                <Route exact path='/proposals/revised' component={() => <AllProposals status={"revised"}/>} />
                <Route exact path='/proposals/unsuccessful' component={() => <AllProposals status={"unsuccessful"}/>} />
                <Route exact path='/proposals/:id' component={() => <SingleProposal />} />
                <Route exact path='/proposals' component={() => <Redirect to={`/proposals/pending`} />} />


            </Switch>
        </div>
    )
}