import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
// import { getSingleStudent } from "repository/actions/data/dashboard/students";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllModulesContext } from "repository/stores/dashboard/modules/modules-all-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AllReferralsContext } from "repository/stores/dashboard/referrals/referrals-all-provider";

import DialogueEditStudent from "routes/dashboard/dialogues/students/edit-student";

import {
  SingleStudentContext,
  SingleStudentProvider,
} from "repository/stores/dashboard/students/students-single-provider";
// Navigation
import NavCrumbs from "./students-single-student-nav-crumbs";

// Components
import Details from "./details";
import Documents from "./documents";
import Personal from "./personal";
import Letters from "./letters";
import PaymentPlans from "./payment-plans";
import Supervisors from "./supervisors";
import Mentors from "./mentors";
import Cohorts from "./cohorts";
import Extensions from "./extensions";
import ResolvedExtensions from "./resolved-extensions"
import Comments from "./comments";
import Qualifications from "./qualifications";

import DialogueDeleteStudent from "routes/dashboard/dialogues/students/delete-student";


export default withRouter(function (props) {
  const studentId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);
  const { courses,getCourses } = useContext(AllCoursesContext);
  const { getCourseModules } = useContext(AllModulesContext);
  const { student, setStudent, getStudent, editStudent, loadingStudent } =
    useContext(SingleStudentContext);

  const { findReferralByStudentId } = useContext(AllReferralsContext);

  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteStudentDialogue, setShowDeleteStudentDialogue] =
    useState(false);
  const [showEditStudentDialogue, setShowEditStudentDialogue] = useState(false);




  useEffect(() => {
    if (user && user != null) {
      if (studentId && studentId != null) {
        getStudent(studentId, user.idToken);
        
      }
    }

  }, [user, studentId]);

  return (
    <NavCrumbs student={student} setStudent={setStudent}>
      <Details
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        user={user}
        loadingStudent={loadingStudent}
        setShowEditStudentDialogue={setShowEditStudentDialogue}
        findReferralByStudentId={findReferralByStudentId}

        

      />
      <Cohorts
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
        getCourses={getCourses}
        getCourseModules={getCourseModules}
      />
      <Extensions
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
       <ResolvedExtensions
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Qualifications
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Comments
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Documents
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Personal
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
       <PaymentPlans
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Letters
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Supervisors
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Mentors
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />

      <DialogueEditStudent
        isOpen={showEditStudentDialogue && student && student != null}
        setClose={() => {
          setShowEditStudentDialogue(false);
        }}
        data={student}
        user={user}
        itemId={studentId}
        editStudent={editStudent}
        getCourses={getCourses}
        courses={courses}
      />


    </NavCrumbs>
  );
});

