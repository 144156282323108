import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-conultants-styles";
import {
  Link,
  TextField,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Box,
  Chip,
  MenuItem,
  Grid,
  Input,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import ImageFadeIn from "react-image-fade-in";
import EmptyProfileImage from "assets/images/empty-profile.png";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, consultant, editConsultant } = props;

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [surname, setSurname] = React.useState(null);
  const [color, setColor] = React.useState(null);
  const [type, setType] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [surnameError, setSurnameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [imageError, setImageError] = React.useState(null);

  const handleCreateUser = () => {
    if (user && user != null) {
      if (
        name == null ||
        surname == null ||
        email == null ||
        image == null ||
        type == null ||
        color == null
      ) {
        toast.error(`You have missing information to create a new user`);
        if (email === null) {
          setEmailError("Email is required.");
        }
        if (surname === null) {
          setSurnameError("Surname is required.");
        }
        if (name === null) {
          setNameError("Name is required.");
        }
        if (image == null) {
          setImageError("A photo is required.");
        }
        if (color == null) {
          setImageError("A color is required.");
        }
        if (type == null) {
          setImageError("A type is required.");
        }
      } else {
        let data = {
          firstName: name,
          lastName: surname,
          email: email,
          imageURI: image,
          color: color,
          type: type,
          activeProjects: [],
          completedProjects: [],
        };

        setSaveLoading(true);

        toast.dark(`Creating ${data.firstName} ${data.lastName}`);
        editConsultant(consultant._id,data, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(
              `Consultant edited :  ${data.firstName} ${data.lastName}`
            );
            setImage(null);
            setName(null);
            setSurname(null);
            setEmail(null);
            setColor(null);
          })
          .catch((e) => {
            setSaveLoading(false);
            console.log(e.message);
          });
      }
    }
  };

  useEffect(() => {
    if (consultant) {
      setName(consultant.firstName);
      setSurname(consultant.lastName);
      setImage(consultant.directURL);
      setColor(consultant.color);
      setEmail(consultant.email);
      setType(consultant.type);

    }
  }, [consultant]);

  useEffect(() => {
    setNameError(null);
    setSurnameError(null);
    setImageError(null);
    setEmailError(null);
  }, [name, surname, email, isOpen, image]);

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 5242880) {
      toast.error("File is too big. The maximum size allowed is 5MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setImage(e.target.result);
      };
    }
  };

  return (
    <Popup
      title="Add a new consultant"
      subtitle="Please fill in the details below to create a new consultant."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateUser()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <div className={classes.coverPhoto}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              disabled={saveLoading}
              onChange={handleCapture}
            />
            <label htmlFor="contained-button-file" disabled={saveLoading}>
              {image && <ImageFadeIn className={classes.photo} src={image} />}
              {!image && (
                <ImageFadeIn
                  className={classes.photo}
                  src={EmptyProfileImage}
                />
              )}
              <Typography className={classes.error}>{imageError}</Typography>
              <Link
                className={classes.link}
                disabled={saveLoading}
                component="span"
                variant="body2"
              >
                {image && <div>Change Photo (max: 5mb)</div>}
                {!image && <div>Add Photo (max: 5mb)</div>}
              </Link>
            </label>
          </div>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: John"}
                  disabled={saveLoading}
                  required
                  helperText={nameError}
                  error={nameError != null}
                  label="First Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Doe"}
                  disabled={saveLoading}
                  required
                  helperText={surnameError}
                  error={surnameError != null}
                  label="Last Name"
                  value={surname}
                  onChange={(event) => setSurname(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: johndoe@example.com"}
                  disabled={saveLoading}
                  required
                  helperText={emailError}
                  error={emailError != null}
                  label="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: #ffffff"}
                  disabled={saveLoading}
                  required
                  label="Color"
                  value={color}
                  onChange={(event) => setColor(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl className={classes.formControl}>
            <InputLabel required disabled={saveLoading}>
              Type
            </InputLabel>
            <Select
              elevation={0}
              onChange={(event) => setType(event.target.value)}
              value={type}
              required
              input={<Input id="type-menu" />}
            >
              <MenuItem value={"Internal"}>Internal</MenuItem>
              <MenuItem value={"External"}>External</MenuItem>
            </Select>
          </FormControl>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
