import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllEnquiriesContext } from "repository/stores/dashboard/enquiries/enquiries-all-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import EnquiriesView from "./enquiries-all-enquiries-view";

// Dialogues
import DialogueCreateEnquiry from "routes/dashboard/dialogues/enquiries/create-enquiry";
import DialogueEditEnquiry from "routes/dashboard/dialogues/enquiries/edit-enquiry";
import DialogueDeleteEnquiry from "routes/dashboard/dialogues/enquiries/delete-enquiry";

// Actions
import {
  // findAllEnquiries,
  getSingleEnquiry,
} from "repository/actions/data/dashboard/enquiries";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const {enquiries, setEnquiries, getEnquiries, createNewEnquiry, editEnquiry, deleteEnquiry} = React.useContext(AllEnquiriesContext);
  const {courses, setCourses, getCourses} = useContext(AllCoursesContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewEnquiryDialogue, setShowNewEnquiryDialogue] = useState(false);
  const [showEditEnquiryDialogue, setShowEditEnquiryDialogue] = useState(false);
  const [showDeleteEnquiryDialogue, setShowDeleteEnquiryDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (enquiries == null) {
        getEnquiries(user.idToken);
      }
    }
  }, [enquiries]);

  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, [courses]);

  // Editing Item
  useEffect(() => {
    if (user && user != null) {
      if (enquiries) {
        if (selectedId && editingItem == null) {
          getSingleEnquiry(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);

  return (
    <div>
      <EnquiriesView
        loading={!enquiries}
        onPressedItem={(id) => props.history.push(`all/${id}`)}
        toggleAddEnquiryForm={() => setShowNewEnquiryDialogue(true)}
        sectionDescription={"You can manage all of your enquiries below."}
        enquiries={enquiries || []}
        courses={courses || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditEnquiryDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteEnquiryDialogue(true);
        }}
        user={user}
      />
      <DialogueCreateEnquiry
        isOpen={showNewEnquiryDialogue}
        setClose={() => setShowNewEnquiryDialogue(false)}
        user={user}
        createNewEnquiry={createNewEnquiry}
        courses={courses}
        getCourses={getCourses}
        setCourses={setCourses}
      />
      <DialogueEditEnquiry
        isOpen={showEditEnquiryDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditEnquiryDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        courses={courses}
        getCourses={getCourses}
        editEnquiry={editEnquiry}
      />
      <DialogueDeleteEnquiry
        isOpen={showDeleteEnquiryDialogue}
        setClose={() => setShowDeleteEnquiryDialogue(false)}
        itemId={selectedId}
        user={user}
        deleteEnquiry={deleteEnquiry}
      />
    </div>
  );
});
