import React, { useEffect, useState,useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";

// Navigation
import NavCrumbs from "./timesheets-custom-timesheets-nav-crumbs";

// Components
import Custom from "./custom";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { users, findActiveUsers } = useContext(AllUsersContext);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (user && user != null) {
      findActiveUsers(user.idToken, true).then((res) => {
        setActiveUsers(res.data);
      });
    }
  }, [user]);

  return <Custom user={user} users={activeUsers} />;
});
