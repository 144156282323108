import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Panel from "components/panel";
import { IconButton, Grid, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Chart } from "react-google-charts";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import consultantsAllConsultantsView from "../../../consultants/routes/all-consultants/consultants-all-consultants-view";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    projects,
    isLoading,
    getConsultants,
    findClientsByQuery,
    handleChangeYear,
    handleQuarter,
    minDate,
    currentYear,
  } = props;
  const classes = useStyles();

  // const [currentYear, setCurrentYear] = useState(moment());
  const [clientMap, setClientMap] = useState(null);
  const [consultants, setConsultants] = useState(null);
  const [consultantMap, setConsultantMap] = useState(null);
  const [projectData, setProjectData] = React.useState(null);

  const [final, setFinal] = useState(null);

  let colorOrder = [];

  const html = (name, start, end, clientTitle) => {
    return `<div style="width:200px">
    <div style="margin:10px 20px"><b>${name}</b></div>
    <div style="margin:10px; padding:10px 20px;border-top:1px solid grey">
    <div><b>Client</b></div>
    ${clientTitle}
    </div>
    <div style="margin:10px; padding:10px 20px;border-top:1px solid grey">
    <div><b>Duration</b></div>
    ${moment(start).format("DD MMMM")} - ${moment(end).format("DD MMMM")}
    </div>
    </div>`;
  };

  useEffect(() => {
    getConsultants().then((result) => {
      setConsultants(result.data);
      let newConsultantMap = {};
      for (let c of result.data) {
        newConsultantMap[c._id] = c;
      }
      setConsultantMap(newConsultantMap);
    });

    findClientsByQuery({
      businessLines: {
        $in: ["Advisory"],
      },
    }).then((result)=>{
      let newClientMap = {};
      for (let c of result.data) {
        newClientMap[c._id] = c;
      }
      setClientMap(newClientMap);
    });
  }, []);

  const getRows = () => {
    return new Promise((resolve, reject) => {
      let rows = [];
      colorOrder = [];

      let year = currentYear.years();

      for (let p of projects) {
        let start = moment(p.ganttStartDate);
        let end = moment(p.ganttEndDate);
        let daySlices =
          end.diff(start, "days") / (p.consultants ? p.consultants.length : 1);

        let newEndDate = new Date(p.ganttStartDate);
        newEndDate.setDate(newEndDate.getDate() + daySlices);

        let currentEnd = null;
        if (p.consultants) {

          p.consultants.map((item, index) => {
            let newStartDate = currentEnd
              ? currentEnd
              : new Date(p.ganttStartDate);
            let newEndDate = new Date(p.ganttStartDate);
            newEndDate.setDate(newEndDate.getDate() + daySlices * (index + 1));
            currentEnd = newEndDate;

            let c = consultantMap[item];
            if (c.type == "Internal") {
              rows.push([
                p.title,
                c.firstName,
                html(c.firstName, p.startDate, p.endDate, clientMap[p.clientId]?.title),
                newStartDate,
                newEndDate,
              ]);

              if (colorOrder.indexOf(c.color) == -1) colorOrder.push(c.color);
            } else {
              rows.push([
                p.title,
                "External",
                html(c.firstName, p.startDate, p.endDate, clientMap[p.clientId]?.title),
                newStartDate,
                newEndDate,
              ]);

              if (colorOrder.indexOf("#c2185b") == -1)
                colorOrder.push("#c2185b");
            }
          });
        } else {
          rows.push([
            p.title,
            "No Consultant",
            html("No Consultant", p.startDate, p.endDate, clientMap[p.clientId]?.title),
            new Date(p.ganttStartDate),
            new Date(p.ganttEndDate),
          ]);

          if (colorOrder.indexOf("#4C4C4C") == -1) colorOrder.push("#4C4C4C");
        }
      }

      setFinal(colorOrder);

      let data = [
        [
          { type: "string", id: "Title" },
          { type: "string", id: "Name" },
          { type: "string", role: "tooltip", p: { html: true } },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ],
        ...rows,
      ];

      resolve(data);
    });
  };

  useEffect(() => {
    if (consultants && consultantMap && clientMap) {
      getRows().then((rows) => {
        setProjectData(rows);
      });
    }
  }, [consultants, consultantMap, clientMap, projects]);

  const options = {
    timeline: {
      groupByRowLabel: true,
    },
    hAxis: {
      minValue: minDate,
      maxValue: new Date(currentYear.years(), 11, 30),
    },
    colors: final,
    allowHtml: true,
    backgroundColor: "white",
    alternatingRowStyle: false,
  };
  return (
    <Panel loading={isLoading}>
      <Grid container style={{ textAlign: "left", padding: 20 }}>
        <Grid item xs={6}>
          <IconButton
            onClick={() => {
              handleChangeYear(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleChangeYear(true);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <span className={classes.title}>{currentYear.format("YYYY")}</span>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            className={classes.actionButton}
            onClick={() => {
              handleQuarter();
            }}
          >
            Current Quater
          </Button>
        </Grid>
      </Grid>
      {projectData && final && projects.length > 0 && (
        <Chart
          chartType="Timeline"
          data={projectData}
          width="100%"
          height={projects.length * 50}
          options={options}
        />
      )}
      {projects.length == 0 && (
        <p style={{ textAlign: "center" }}>No projects this year</p>
      )}
    </Panel>
  );
});
