import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SingleStudentQualificationsView from "./single-student-qualifications-view";

import DialogueAddQualification from "routes/dashboard/dialogues/students/create-qualification";
import DialogueDeleteQualification from "routes/dashboard/dialogues/students/delete-qualification"

export default withRouter(function (props) {
  let { student, setStudent, user, getStudent, editStudent } = props;

  const [qualifications, setQualifications] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showAddQualificationDialogue, setShowAddQualificationDialogue] = useState(false);
  const [showDeleteQualificationDialogue, setShowDeleteQualificationDialogue] = useState(false);

  useEffect(() => {
    if (student && student != null) {
      setQualifications(student.qualifications ? student.qualifications : []);
    }
  }, [student]);

  return (
    <div>
      <SingleStudentQualificationsView
        loading={!student}
        toggleAddQualificationForm={() => setShowAddQualificationDialogue(true)}
        sectionDescription={"Qualifications from this student"}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        qualifications={qualifications}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteQualificationDialogue(true);
        }}
      />

      <DialogueAddQualification
        loading={!student}
        isOpen={showAddQualificationDialogue}
        setClose={() => setShowAddQualificationDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
      />

      <DialogueDeleteQualification 
        loading={!student}
        isOpen={showDeleteQualificationDialogue}
        setClose={() => setShowDeleteQualificationDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        qualificationIndex={selectedIndex}
        qualifications={qualifications}
      />
    </div>
  );
});
