import React from "react";
// import { initClient } from "utils/api";
import { initSearchClient } from "utils/algolia";
import { AuthenticationProvider } from "repository/stores/global/authentication-provider";
import { IdTokenProvider } from "repository/stores/global/token-provider";

import { SingleStudentProvider } from "repository/stores/dashboard/students/students-single-provider";
import { SingleCohortProvider } from "repository/stores/dashboard/cohorts/cohorts-single-provider";
import { SingleContactProvider } from "repository/stores/dashboard/contacts/contact-single-provider"
import Theme from "utils/theme";

// Children
import AppView from "./app-view";
import AppAuthentication from "./app-authentication";

const AppContainer = () => {
  // Initialise API
  // initClient(function (err) {
  //   if (err && err !== null) {
  //     console.log(`Problem initialising api client`);
  //     console.log(err);
  //   }
  // });

  // Initialise Search
  initSearchClient(function (err) {
    if (err && err !== null) {
      console.log(`Problem initialising algolia client`);
      console.log(err);
    }
  });

  React.useEffect(() => {
    const initialValue = document.body.style.zoom;

    // Change zoom level on mount
    // document.body.style.zoom = "90%";
    document.body.style.fontSize = "50%!important";


  }, []);


  // Return Main App Component
  return (
      <AuthenticationProvider>
        <SingleStudentProvider>
          <SingleContactProvider>
            <SingleCohortProvider>
              <Theme>
                <AppAuthentication>
                  <AppView />
                </AppAuthentication>
              </Theme>
            </SingleCohortProvider>
          </SingleContactProvider>
        </SingleStudentProvider>
      </AuthenticationProvider>
  );
};

export default AppContainer;
