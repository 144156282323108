import React from 'react';

export const SectionDescriptionsContext = React.createContext();

const initialState = {
    "dashboard.information.basic.company": "The information below shows the basic company information that will be sent to people that message your business.",
    "dashboard.information.basic.chef": "Tell your customers more about the chef behind the business and all the awards and recognition they have received.",
    "dashboard.information.contact.details": "The contact details below will help your customers get in touch with you should they need to.",
    "dashboard.information.contact.location": "Your business location will be used to help your customers find your physical presence.",
    "dashboard.information.hours.standard": "Your businesses opening hours should be reflected below. To add a new set of opening hours simply tap the button above.",
    "dashboard.information.menus.all": "You can add, change or remove all of your businesses food & drink menus below.",
    "dashboard.information.menus.sections": "You can add in all of the specific menu sections you want your users to see below.",
    "dashboard.information.menus.items": "You can add in all of the specific menu items you want your users to see below.",
    "dashboard.preferences.deployment.facebook": "Changes in your messenger integration will be shown below.",
    "dashboard.preferences.localization.currency": "The currency of your business is automatically updated based on the location of your physical presence.",
    "dashboard.billing.subscription.payment": "Your subscription costs will be taken from the payment method below.",
    "dashboard.billing.subscription.status": "An active subscription is required in order to go live with your chatbot.",
    "dashboard.billing.history.overview": "You can check out your entire billing history below."

};

const reducer = (state, action) => {
    return state;
};

export const SectionDescriptionsProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <SectionDescriptionsContext.Provider value={contextValue}>
      {children}
    </SectionDescriptionsContext.Provider>
  );
};