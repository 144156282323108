import { useContext } from "react";
import { toast } from 'react-toastify';
import apiClient from '../../../utils/api';
import firebase from "../../../utils/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { IdTokenContext } from "repository/stores/global/token-provider"


export const authenticateUser = async (setAuthContext) => {

    // const apiClient = getClient();

    try {
        let authentication = await apiClient.reAuthenticate();

        setAuthContext({
            type: 'AuthenticateUser',
            payload: {
                'status': true,
                'user': authentication.user
            }
        });
    } catch (e) {
        setAuthContext({
            type: 'AuthenticateUser',
            payload: {
                'status': false,
                'user': {}
            }
        });
    }
}


export const loginWithFirebaseEmail = (email, password) => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();


        signInWithEmailAndPassword(auth, email, password)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e);
            });
    });
};

export const logOutWithFirebase = () => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();

        auth
            .signOut()
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e);
            });
    });
};

export const loginWithEmail = async (setAuthContext, email, password) => {
    // const apiClient = getClient();
    return new Promise(async (resolve, reject) => {
        try {
            let authentication = await apiClient.authenticate({
                "strategy": "local",
                "email": email,
                "password": password
            });
            resolve(authentication);
        } catch (e) {
            toast.error(`Oops! We cannot log you in at this moment.`)
            reject(e);
        }
    });
}

export const logOut = async (auth) => {
    // const apiClient = getClient();
    await apiClient.logout();
    window.location.href = "/";
}


export const getFirebaseToken = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const auth = getAuth();
            let refreshToken = auth.currentUser.getIdToken()
            resolve(refreshToken);
        } catch (e) {

            toast.error(`Oops! We cannot log you in at this moment.`)
            reject(e);
        }
    });
}

export const getIdToken = async () => {
    return new Promise(async (resolve, reject) => {
        try {

            const dbRequest = window.indexedDB.open("firebaseLocalStorageDb");

            dbRequest.onsuccess = () => {
                const db = dbRequest.result;
                const stores = ['firebaseLocalStorage'];

                const tx = db.transaction(stores);

                let req = tx.objectStore(stores[0]).getAll()

                req.onsuccess = () => {
                    let result = req.result;
                    let idToken = result[0].value.stsTokenManager.accessToken
                    resolve(idToken)
                };
            }
        } catch (e) {
            reject(e);
        }

    })
}