import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Actions
// import { editCohort } from "repository/actions/data/dashboard/cohorts";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, data, itemId, user, courses, setCohorts, editCohort } = props;

  const [title, setTitle] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [course, setCourse] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [courseError, setCourseError] = React.useState(null);
  const [startDateError, setStartDateError] = React.useState(null);
  const [endDateError, setEndDateError] = React.useState(null);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [openEndDate, setOpenEndDate] = React.useState(false);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setCourse(data.courseId);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  }, [data]);

  const handleSaveOwnerDetails = () => {
    if (user && user != null) {
      if (
        title == null ||
        startDate == null ||
        endDate == null ||
        course == null
      ) {
        toast.error(`You have missing information for editing this cohort`);
        if (title === null) {
          setTitleError("Title is required.");
        }

        if (startDate == null) {
          setStartDateError("Start date is required.");
        }

        if (endDate == null) {
          setEndDateError("End date is required.");
        }

        if (course == null) {
          setCourseError("Course is required.");
        }
      } else {
        let saveData = {
          title: title,
          startDate: startDate,
          endDate: endDate,
          courseId: course,
        };

        setSaveLoading(true);

        editCohort( saveData, itemId, user.idToken)
          .then((result) => {
            toast.success(`Cohort successfully edited`);
            setClose();
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };


  return (
    <Popup
      title="Edit existing cohort"
      subtitle="You can modify the following cohort details."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: Course Title"}
                disabled={saveLoading}
                required
                helperText={titleError}
                error={titleError != null}
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl
              className={classes.inputType}
              error={courseError != null}
            >
              <InputLabel required disabled={saveLoading}>
                Assigned Course
              </InputLabel>
              {courses && courses != null && (
                <Select
                  elevation={0}
                  onChange={(event) => setCourse(event.target.value)}
                  value={course}
                  required
                  error={courseError != null}
                  disabled={saveLoading}
                  input={<Input id="type-menu" />}
                >
                  {courses.map((item) => {
                    return <MenuItem value={item._id}>{item.title}</MenuItem>;
                  })}
                </Select>
              )}
              <FormHelperText>{courseError}</FormHelperText>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                required
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Start Date"
                value={startDate}
                helperText={startDateError}
                error={startDateError != null}
                onChange={(date) => {
                  setStartDate(date);
                  setOpenStartDate(false);
                }}
                onClick={() => setOpenStartDate(true)}
                onError={(error) => setStartDateError(error.message)}
                onOpen={() => setOpenStartDate(true)}
                onClose={() => setOpenStartDate(false)}
                open={openStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                required
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="End Date"
                value={endDate}
                helperText={endDateError}
                open={openEndDate}
                error={endDateError != null}
                onChange={(date) => {
                  setEndDate(date);
                  setOpenEndDate(false);
                }}
                onError={(error) => setEndDateError(error.message)}
                onClick={() => setOpenEndDate(true)}
                onOpen={() => setOpenEndDate(true)}
                onClose={() => setOpenEndDate(false)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
