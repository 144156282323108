import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllReferralsContext } from "repository/stores/dashboard/referrals/referrals-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import DialogueCreateReferral from "routes/dashboard/dialogues/referrals/create-referral";

// Children
import ReferralView from "./students-all-students-view";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { referrals, getReferrals, createNewReferral, findReferralByStudentId, generateUpcomingReferrals } =
    useContext(AllReferralsContext);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false);

  const [showNewReferralDialogue, setShowNewReferralDialogue] = useState(false);

  useEffect(() => {
    if (referrals == null) {
      getReferrals(user.idToken);
    } 
  }, [referrals]);

  return (
    <div>
      <ReferralView
        loading={!referrals}
        onPressedItem={(id) => props.history.push(`referrals/${id}`)}
        sectionDescription={"You can manage all of your referrals below."}
        referrals={referrals || []}
        user={user}
        generateUpcomingReferrals={generateUpcomingReferrals}
        toggleAddReferralForm={() => setShowNewReferralDialogue(true)}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueCreateReferral
        isOpen={showNewReferralDialogue}
        user={user}
        setClose={() => setShowNewReferralDialogue(false)}
        createNewReferral={createNewReferral}
        setRefreshAlgolia={setRefreshAlgolia}
        findReferralByStudentId={findReferralByStudentId}
      />
    </div>
  );
});
