import apiClient from "utils/api";
import { getIdToken } from "../authentication"

export const findAllLeads = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/leads").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdateLeads",
          payload: result.data,
        });

        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewLead = async (dispatch, lead, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/leads").create(lead, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "AddLead",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editLead = async (dispatch, data, leadId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/leads").patch(leadId, data, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "EditLead",
        payload: {
          leadId: leadId,
          data: result,
        },
      });
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteLead = async (dispatch, leadId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/leads").remove(leadId, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "RemoveLead",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const changeLeadStatus = async (leadId, status, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let leadResult = await apiClient.service("v1/leads").patch(
        leadId,
        {
          status: status,
        },
        {
          query: {
            firebaseToken: token,
          },
        }
      );

      resolve(leadResult);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleLead = async (leadId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/leads").get(leadId, {
        query: {
          firebaseToken: token,
        },
      });
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
