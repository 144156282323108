import React from 'react';

// Parents
import EnquiriesTabs from './dash-enquiries-tabs';
import EnquiriesRoutes from './dash-enquiries-routes';

export default function (props) {

  return (
    <EnquiriesTabs>
      <EnquiriesRoutes />
    </EnquiriesTabs>
  )
}
