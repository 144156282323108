import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllModulesContext } from "repository/stores/dashboard/modules/modules-all-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import ModulesView from "./content-all-modules-view-new";

// Dialogues
import DialogueCreateModule from "routes/dashboard/dialogues/modules/create-module";
import DialogueEditModule from "routes/dashboard/dialogues/modules/edit-module";
import DialogueDeleteModule from "routes/dashboard/dialogues/modules/delete-module";

// Actions
import {
  // findAllModules,
  getSingleModule,
} from "repository/actions/data/dashboard/modules";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const {modules, setModules, getAllModules, createNewModule, editModule, deleteModule} = useContext(AllModulesContext);
  const {courses, setCourses, getCourses} = useContext(AllCoursesContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewModuleDialogue, setShowNewModuleDialogue] = useState(false);
  const [showEditModuleDialogue, setShowEditModuleDialogue] = useState(false);
  const [showDeleteModuleDialogue, setShowDeleteModuleDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (modules == null) {
        getAllModules(user.idToken);
      }
    }
  }, [modules]);

  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, [courses]);

  // Editing Item
  useEffect(() => {
    if (user && user != null) {
      if (modules) {
        if (selectedId && editingItem == null) {
          getSingleModule(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);

  return (
    <div>
      <ModulesView
        loading={!modules}
        onPressedItem={(id) => props.history.push(`modules/${id}`)}
        toggleAddModuleForm={() => setShowNewModuleDialogue(true)}
        sectionDescription={"You can manage all of your modules below."}
        modules={modules || []}
        courses={courses || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditModuleDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteModuleDialogue(true);
        }}
        user={user}
      />
      <DialogueCreateModule
        isOpen={showNewModuleDialogue}
        setClose={() => setShowNewModuleDialogue(false)}
        user={user}
        courses={courses}
        getCourses={getCourses}
        createNewModule={createNewModule}
        setRefreshAlgolia={setRefreshAlgolia}
      />
      <DialogueEditModule
        isOpen={showEditModuleDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditModuleDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        editModule={editModule}
        courses={courses} 
        getCourses={getCourses}
      />
      <DialogueDeleteModule
        isOpen={showDeleteModuleDialogue}
        setClose={() => setShowDeleteModuleDialogue(false)}
        itemId={selectedId}
        user={user}
        deleteModule={deleteModule}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
