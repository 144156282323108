import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider";
import { IdTokenContext } from "repository/stores/global/token-provider";

const ContextProps = {
  referrals: null,
  setReferrals: {},
  getReferrals: {},
  getReferral: {},
  loadingReferrals: false,
  deleteReferral: {},
  createNewReferral: {},
  editReferral: {},
  findReferralByStudentId: {},
  generateUpcomingReferrals: {}
};

export const AllReferralsContext = React.createContext(ContextProps);

export const AllReferralsProvider = ({ children }) => {
  const [referrals, setReferrals] = useState(null);
  const [loadingReferrals, setLoadingReferrals] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext);

  const getReferrals = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingReferrals(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/referrals").find({
            query: {
              $sort: {
                paymentDate: -1,
              },
              firebaseToken: token,
            },
          });

          setLoadingReferrals(false);
          setReferrals(result.data);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const getReferral = (referralId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingReferrals(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/referrals").get(referralId, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingReferrals(false);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const findReferralByStudentId = (studentId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingReferrals(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/referrals").find({
            query: {
              studentId: studentId,
              firebaseToken: token,
            },
          });

          setLoadingReferrals(false);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewReferral = async (referral, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/referrals").create(
            { ...referral },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const editReferral = async (referralId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let updated = await apiClient
            .service("v1/referrals")
            .patch(referralId, data, {
              query: {
                firebaseToken: token,
              },
            });

          resolve(updated);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const generateUpcomingReferrals = (email,token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await apiClient.service("v1/autotasks").create(
          {
            type: "generate-upcoming-referrals",
            data: {
              sendTo: email,
            },
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteReferral = async (referralId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient
            .service("v1/referrals")
            .remove(referralId, {
              query: {
                firebaseToken: token,
              },
            });

          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllReferralsContext.Provider
      value={{
        referrals,
        setReferrals,
        getReferrals,
        getReferral,
        loadingReferrals,
        deleteReferral,
        createNewReferral,
        editReferral,
        findReferralByStudentId,
        generateUpcomingReferrals
      }}
    >
      {children}
    </AllReferralsContext.Provider>
  );
};
