import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";

// Navigation
import NavCrumbs from "./vacancies-all-vacancies-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  Stats,
} from "react-instantsearch-dom";

// Styles
// import styles from "./vacancies-all-vacancies-styles";
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    onPressedItem,
    vacancies,
    sectionDescription,
    user,
    refreshAlgolia,
    setRefreshAlgolia,
  } = props;

  const [published, setPublished] = useState(null);
  const [unpublished, setUnpublished] = useState(null);

  React.useEffect(() => {
    searchClient.clearCache();
    searchClient
      .initIndex("Vacancies")
      .searchForFacetValues("isPublished")
      .then(({ facetHits }) => {
        var published = facetHits.find((obj) => {
          return obj.value === "true";
        });
        var unpublished = facetHits.find((obj) => {
          return obj.value === "false";
        });
        setPublished(published ? published.count : 0);
        setUnpublished(unpublished ? unpublished.count : 0);
      });
  }, []);

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a Vacancy"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let isPublishedColor = "8b000090";
          if (row.isPublished) {
            isPublishedColor = "32CD32";
          }

          let minSalary = row.minSalary
            ? row.minSalary.substring(0, row.minSalary.length - 3) +
              "," +
              row.minSalary.substring(
                row.minSalary.length - 3,
                row.minSalary.length
              )
            : row.minSalary;
          let maxSalary = row.maxSalary
            ? row.maxSalary.substring(0, row.maxSalary.length - 3) +
              "," +
              row.maxSalary.substring(
                row.maxSalary.length - 3,
                row.maxSalary.length
              )
            : row.maxSalary;

          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
                style={{ borderLeft: `3px solid #${isPublishedColor}` }}
              >
                {row.clientTitle}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.positionName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.industry}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.contractType}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.candidates.length}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.headCount}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.location}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                €{minSalary}
                {" - "}€{maxSalary}
              </TableCell>
              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="view"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR VACANCIES"
        loading={loading}
        sectionDescription={sectionDescription}
      >
        {vacancies.length > 0 && (
          <InstantSearch
            indexName="Vacancies"
            searchClient={searchClient}
            refresh={refreshAlgolia}
          >
            <Stats
              translations={{
                stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                  return (
                    <>
                      <Typography className={classes.stat}>
                        {published} Published
                      </Typography>
                      <Typography className={classes.stat}>
                        {unpublished} Unpublished
                      </Typography>
                      <Typography className={classes.stat}>
                      ----------------------------
                      </Typography>
                      <Typography className={classes.stat}>
                        {nbHits} Total Vacancies
                      </Typography>
                    </>
                  );
                },
              }}
            />
            <CustomSearchBox />

            <Paper className={classes.tablePaper}>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Client
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Position Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Industry
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Contract Type
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Applicants
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Head Count
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Location
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Salary Range
                        </Typography>
                      </TableCell>

                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <CustomHits />
                </Table>
              </TableContainer>
            </Paper>
            <CustomPagination />
          </InstantSearch>
        )}
        {vacancies.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any vacancies yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
