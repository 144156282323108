const styles = theme => ({
  root: {
    zIndex:180,
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        marginTop: 0,
        width: '100%'
    },
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: 'rgba(230,230,230,0.0)',
    borderWidth: 1,
  },
  tabsRoot: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '0px solid #e8e8e8',
    borderRadius: 0,
    marginLeft: -14,
    paddingLeft: 0,
    marginTop: -48,
    paddingTop: 15,
    paddingBottom: 20
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
    '& > div': {
      maxWidth: 28,
      width: '100%',
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: 3
    }
  },
  tabIndicator: {

  },
  tabLabel: {
    marginTop: 0,
    marginBottom: -24,
    width: 110,
    zIndex: 1600,
    cursor: 'pointer',

  },
  tabsLabel: {
    color: 'black',
    marginTop: 10,
    width: 190,
    cursor: 'pointer',
    '&$tabSelected': {
      color: theme.palette.primary.light,
      opacity: 1
    },
  },
  tabIconRoot: {
    backgroundColor: 'none',
    paddingBottom: 3
  },
  tabIcon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    padding: 0,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    '&$tabSelected': {
      color: theme.palette.primary.light,
      opacity: 1
    },
  },
  extraSpace: {
    height: 20,
    width: '100%',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 110,
    minHeight: 110,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontFamily: 'proximaNova-bold',
    marginLeft: 0,
    textColor: 'black',
    color: 'black',
    marginRight: 10,
    opacity: 1,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$tabSelected': {
      color: theme.palette.primary.main,
      opacity: 1
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  icon: {
    color: 'white',
    marginTop: 6,
    marginLeft: 0,
    fontSize: 18
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  spacing: {
    paddingTop: 100
  }
});

export default styles;
