import React from 'react';

// Children
import Loading from '../loading';
import Routes from './app-routes';

export default function () {
    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading full/>}>
                <Routes />
            </React.Suspense>
        </React.Fragment>
    );
}