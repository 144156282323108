

import React, { useContext, useEffect, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../../stores/global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  clients: null,
  setClients: {},
  getClients: {},
  findClientsByQuery: {},
  getClient: {},
  loadingClients: false,
  deleteClient: {},
  createNewClient: {},
  editClient: {}

};

export const AllClientsContext = React.createContext(ContextProps);

export const AllClientsProvider = ({ children }) => {
  const [clients, setClients] = useState(null);
  const [loadingClients, setLoadingClients] = useState(false);
  const { user } = useContext(AuthenticationContext);

  const { getIdToken } = useContext(IdTokenContext)


  const getClients = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingClients(true);

        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/clients").find({
            query: {
              firebaseToken: token,
            },
          });

          setClients(result.data);
          resolve(result);
        })

      } catch (e) {
        setLoadingClients(false);
        reject(e);
      }
    });
  };

  const getClient = (clientId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingClients(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/clients").get(clientId, {
            query: {
              firebaseToken: token,
            },
          });
          setLoadingClients(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const findClientsByQuery = (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingClients(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/clients").find({
            query: {
              ...query,
              firebaseToken: token,
            },
          });
          setLoadingClients(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

 

  const createNewClient = async (client, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/clients").create(
            { ...client },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          if (clients == null) {
            setClients(result);
          } else {
            let newClients = clients
            newClients.push(result)
            setClients(newClients);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editClient = async (clientId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedCohort = await apiClient
            .service("v1/clients")
            .patch(clientId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteClient = async (clientId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/clients").remove(clientId, {
            query: {
              firebaseToken: token,
            },
          });

          let newData = clients
          for (const [i, v] of newData.entries()) {
            if (v._id === clientId) {
              newData.splice(i, 1);
            }
          }
          setClients(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllClientsContext.Provider
      value={{
        clients,
        setClients,
        findClientsByQuery,
        createNewClient,
        getClient,
        getClients,
        editClient,
        deleteClient,
        loadingClients
      }}
    >
      {children}
    </AllClientsContext.Provider>
  );
};
