import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { projects, payments, currentYear, handleChangeYear, projectPaymentMap } =
    props;
  const classes = useStyles();

  const [paymentData, setPaymentData] = useState(null);
  const [totalPayments, setTotalPayments] = useState(null);

  var month = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  let defaultTotals = {
    jan: 0,
    feb: 0,
    mar: 0,
    q1: 0,
    apr: 0,
    may: 0,
    jun: 0,
    q2: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    q3: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    q4: 0,
  };

  useEffect(() => {
    if (payments) {
      getData();
    }
  }, [payments]);

  const converEpochToMonth = (t) => {
    let date = new Date(t);
    var dt = moment(date).format("MMM").toLowerCase();
    return dt;
  };

  const getMonths = (project) => {
    let start = converEpochToMonth(project.startDate);
    let end = converEpochToMonth(project.endDate);

    if (moment(project.startDate).year() < currentYear.year()) {
      start = "jan";
    }

    if (moment(project.endDate).year() > currentYear.year()) {
      end = "dec";
    }

    return month.slice(month.indexOf(start), month.indexOf(end) + 1);
  };

  const getDefault = (project) => {
    return new Promise((resolve, reject) => {
      let defaultData = {
        jan: null,
        feb: null,
        mar: null,
        q1: null,
        apr: null,
        may: null,
        jun: null,
        q2: null,
        jul: null,
        aug: null,
        sep: null,
        q3: null,
        oct: null,
        nov: null,
        dec: null,
        q4: null,
      };

      let months = getMonths(project);
      let payments = projectPaymentMap[project._id]
        ? projectPaymentMap[project._id]
        : [];
        
      for (let p of payments) {
        defaultData[converEpochToMonth(p.date)] =
          defaultData[converEpochToMonth(p.date)] + parseFloat(p.amount);
        defaultTotals[converEpochToMonth(p.date)] =
          defaultTotals[converEpochToMonth(p.date)] + parseFloat(p.amount);
      }

      defaultData.q1 = defaultData.jan + defaultData.feb + defaultData.mar;
      defaultData.q2 = defaultData.apr + defaultData.may + defaultData.jun;
      defaultData.q3 = defaultData.jul + defaultData.aug + defaultData.sep;
      defaultData.q4 = defaultData.oct + defaultData.nov + defaultData.dec;

      defaultTotals.q1 =
        defaultTotals.q1 + defaultData.jan + defaultData.feb + defaultData.mar;
      defaultTotals.q2 =
        defaultTotals.q2 + defaultData.apr + defaultData.may + defaultData.jun;
      defaultTotals.q3 =
        defaultTotals.q3 + defaultData.jul + defaultData.aug + defaultData.sep;
      defaultTotals.q4 =
        defaultTotals.q4 + defaultData.oct + defaultData.nov + defaultData.dec;

      for (let p of Object.entries(defaultData)) {
        if (p[1]) {
          defaultData[p[0]] =
            "€" + p[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }

      for (let m of months) {
        if (defaultData[m] == null) defaultData[m] = "";
      }

      resolve(defaultData);
    });
  };

  const getData = async () => {
    let projectPaymentData = [];
    for (let p of projects) {
      projectPaymentData.push({ ...(await getDefault(p)), project: p.title });
    }

    setTotalPayments(
      defaultTotals.q1 + defaultTotals.q2 + defaultTotals.q3 + defaultTotals.q4
    );
    for (let p of Object.entries(defaultTotals)) {
      if (p[1]) {
        defaultTotals[p[0]] =
          "€" + p[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    projectPaymentData.push({ ...defaultTotals, project: "Totals" });

    setPaymentData(projectPaymentData);
  };

  const cellStyle = (cell, row) => {
    if ((cell || cell == "") && row && row._cells[0].data != "Totals") {
      return {
        style:
          "background-color: rgb(86, 65, 129, 0.5); color:white; padding:12px; text-align:center",
      };
    }
    if (row && row._cells[0].data == "Totals") {
      return {
        style:
          "border-top: 2px solid black; padding:12px 8px; text-align:center",
      };
    }
  };

  const columnStyle = (row, column) => {
    if (row && row._cells[0].data == "Totals") {
      return {
        style:
          "background-color: rgba(0, 0, 0, 0.025); font-weight:bold; color:green;width:30px; border-top: 2px solid black;",
      };
    }
    if (column) {
      return {
        style: "background-color: rgba(0, 0, 0, 0.025); color:green;width:30px",
      };
    }
  };

  const getColumns = () => {
    let columns = [
      {
        name: "Projects",
        id: "project",
        width: "25%",
        attributes: (cell, row) => {
          if (cell == "Totals") {
            return {
              style: "border-top: 2px solid black;",
            };
          }
        },
      },
      {
        name: "Jan",
        id: "jan",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Feb",
        id: "feb",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },

      {
        name: "Mar",
        id: "mar",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Q1",
        id: "q1",
        attributes: (cell, row, column) => {
          return columnStyle(row, column);
        },
      },
      {
        name: "Apr",
        id: "apr",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "May",
        id: "may",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Jun",
        id: "jun",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Q2",
        id: "q2",
        attributes: (cell, row, column) => {
          return columnStyle(row, column);
        },
      },
      {
        name: "Jul",
        id: "jul",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Aug",
        id: "aug",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Sep",
        id: "sep",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Q3",
        id: "q3",
        attributes: (cell, row, column) => {
          return columnStyle(row, column);
        },
      },
      {
        name: "Oct",
        id: "oct",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Nov",
        id: "nov",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Dec",
        id: "dec",
        attributes: (cell, row) => {
          return cellStyle(cell, row);
        },
      },
      {
        name: "Q4",
        id: "q4",
        attributes: (cell, row, column) => {
          return columnStyle(row, column);
        },
      },
    ];

    return columns;
  };

  return (
    <div>
      {paymentData && (
        <div>
          <IconButton
            onClick={() => {
              handleChangeYear(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <span className={classes.title} style={{ margin: "5px" }}>
            {currentYear.format("YYYY")}
          </span>

          <IconButton
            onClick={() => {
              handleChangeYear(true);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <Grid data={paymentData} columns={getColumns()} search={true} />
          <Typography className={classes.title} style={{ margin: 10 }}>
            {`Grand Total: € ${totalPayments.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
          </Typography>
        </div>
      )}
    </div>
  );
});
