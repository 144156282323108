import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'backoffice',
      startPath: 'leave-requests',
      routes:[
        { name: 'Leave Requests', path: 'leave-requests', icon: 'subject_icon'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});