import React from 'react';

// Children
import Background from './background';
import CenterContent from './center-content';


export default function () {
  return (
    <div>
      <Background />
      <CenterContent />
    </div>
  )
}