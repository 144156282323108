import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectDetailsView from "./single-project-details-view";


export default withRouter(function (props) {
  let {
    loading,
    project,
    setProject,
    projectId,
    isNewProject,
    createNewProject,
    editProject,
    findClientsByQuery,
    user,
  } = props;



  return (
    <div>
      <SingleProjectDetailsView
         loading={loading}
         user={user}
         project={project}
         setProject={setProject}
         isNewProject={isNewProject}
         createNewProject={createNewProject}
         editProject={editProject}
         findClientsByQuery={findClientsByQuery}
      />

    

    </div>
  );
});
