import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Panel from "components/panel";
import {
  Typography,
  FormControl,
  TextField,
  Button,
  MenuItem,
  Input,
  Grid,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Chart } from "react-google-charts";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    setYear,
    projects,
    setProjects,
    getProjectsByDate,
    getConsultants,
  } = props;
  const classes = useStyles();

  const [currentYear, setCurrentYear] = useState(moment());
  const [consultants, setConsultants] = useState(null);

  const colors = [
    {
      //yellow
      color: "#f2a600",
      dark: "#ee8100",
      light: "#fce8b2",
    },
    {
      //blue
      color: "#5c6bc0",
      dark: "#3949ab",
      light: "#c5cae9",
    },
    {
      //redd
      color: "#c2185b",
      dark: "#880e4f",
      light: "#f48fb1",
    },
    {
      //green
      color: "#00796b",
      dark: "#004d40",
      light: "#b2dfdb",
    },

    {
      //orange
      color: "#ff7043",
      dark: "#e64a19",
      light: "#ffccbc",
    },
  ];

  const html = (name, start, end) => {
    return `<div style="width:200px">
    <div style="margin:10px 20px"><b>${name}</b></div>
    <div style="margin:10px; padding:10px 20px;border-top:1px solid grey">
    <div><b>Duration</b></div>
    ${start.format("DD MMMM")} - ${end.format("DD MMMM")}
    </div>
    </div>`;
  };

  useEffect(() => {
    getConsultants().then((result) => {
      setConsultants(result.data);
    });
  }, []);

  const getRows = () => {
    let rows = [];
    let year = currentYear.years();

    consultants.map((item, index)=>{
      rows.push([
        "Projects",
        item.firstName,
        "",
        new Date(year, 0, 1, 12, 0, index),
        new Date(year, 0, 1, 12, 0, index+1),
      ]);
    })

    for (let p of projects) {
      let startDate = new Date(p.startDate);
      let endDate = new Date(p.endDate);

      let start = moment(startDate);
      let end = moment(endDate);
      let daySlices = end.diff(start, "days") / p.consultants.length;

      let newEndDate = new Date(p.startDate);
      newEndDate.setDate(newEndDate.getDate() + daySlices);

      let currentEnd = null;
      p.consultants.map((item, index) => {
        let newStartDate = currentEnd ? currentEnd : new Date(p.startDate);
        let newEndDate = new Date(p.startDate);
        newEndDate.setDate(newEndDate.getDate() + daySlices * (index + 1));
        currentEnd = newEndDate;

        rows.push([
          p.title,
          item.firstName,
          html(item.firstName, start, end),
          newStartDate,
          newEndDate,
        ]);
      });
    }

    if (projects.length == 0) {
      let year = currentYear.years();
      let borders = [
        [new Date(year, 0, 1, 12, 0, 0), new Date(year, 0, 1, 12, 0, 1)],
        [new Date(year, 11, 29), new Date(year, 11, 30)],
      ];

      borders.map((item, index) => {
        rows.push(["No Projects", "", "", item[0], item[1]]);
      });
    }

    return rows;
  };

  const [data, setData] = useState([]);

  const getData = () => {
    return [
      [
        { type: "string", id: "Position" },
        { type: "string", id: "Name" },
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
      ],
      ...getRows(),
    ];
  };

  useEffect(() => {
    if (consultants) {
      setData(getData());
    }
  }, [consultants, projects]);

  const handleChangeYear = (isFoward) => {
    let year = isFoward
      ? currentYear.add(1, "years")
      : currentYear.subtract(1, "years");

    let yearStart = year.startOf("year").valueOf();
    let yearEnd = year.endOf("year").valueOf();

    getProjectsByDate(yearStart, yearEnd).then((result) => {
      setProjects(result.data);
    });
  };

  const options = {
    timeline: {
      groupByRowLabel: true,
    },
    hAxis: {
      minValue: new Date(currentYear.years(), 0, 1),
      maxValue: new Date(currentYear.years(), 11, 30),
    },
    colors: colors,
    allowHtml: true,
    backgroundColor: "white",
    alternatingRowStyle: false,
  };
  return (
    <Panel>
      <div style={{ textAlign: "left", padding: 20 }}>
        <IconButton>
          <ArrowBackIosIcon
            onClick={() => {
              handleChangeYear(false);
            }}
          />
        </IconButton>
        <IconButton>
          <ArrowForwardIosIcon
            onClick={() => {
              handleChangeYear(true);
            }}
          />
        </IconButton>
        <span className={classes.title}>{currentYear.format("YYYY")}</span>
      </div>
      <Chart
        chartType="Timeline"
        data={data}
        width="100%"
        height={projects.length * 60}
        options={options}
      />
    </Panel>
  );
});
