import React from 'react';

// Children
import AppBar from './app-bar';
import FixedSideDrawer from './fixed-drawer';

export default function (props) {

  return (
    <div>
      <AppBar />
      <FixedSideDrawer />
      {props.children}
    </div>
  )
}
