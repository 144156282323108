import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import CoursesView from "./content-all-courses-view";

// Dialogues
import DialogueCreateCourse from "routes/dashboard/dialogues/courses/create-course";
import DialogueEditCourse from "routes/dashboard/dialogues/courses/edit-course";
import DialogueDeleteCourse from "routes/dashboard/dialogues/courses/delete-course";

// Actions
import {
  // findAllCourses,
  getSingleCourse,
} from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const {courses, setCourses, getCourses, createNewCourse, editCourse, deleteCourse} = useContext(AllCoursesContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewCourseDialogue, setShowNewCourseDialogue] = useState(false);
  const [showEditCourseDialogue, setShowEditCourseDialogue] = useState(false);
  const [showDeleteCourseDialogue, setShowDeleteCourseDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses( user.idToken);
      }
    }
  }, [courses]);

  // Editing Item

  useEffect(() => {
    if (user && user != null) {
      if (courses) {
        if (selectedId && editingItem == null) {
          getSingleCourse(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);

  return (
    <div>
      <CoursesView
        loading={!courses}
        onPressedItem={(id) => props.history.push(`courses/${id}`)}
        toggleAddCourseForm={() => setShowNewCourseDialogue(true)}
        sectionDescription={"You can manage all of your courses below."}
        courses={courses || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditCourseDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteCourseDialogue(true);
        }} 
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
        user={user}
      />
      <DialogueCreateCourse
        isOpen={showNewCourseDialogue}
        setClose={() => setShowNewCourseDialogue(false)}
        user={user}
        createNewCourse={createNewCourse}
        setRefreshAlgolia={setRefreshAlgolia}
      />
      <DialogueEditCourse
        isOpen={showEditCourseDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditCourseDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        editCourse={editCourse}
      />
      <DialogueDeleteCourse
        isOpen={showDeleteCourseDialogue}
        setClose={() => setShowDeleteCourseDialogue(false)}
        itemId={selectedId}
        user={user}
        deleteCourse={deleteCourse}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
