import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
// import { TrainingProposalsContext } from "repository/stores/dashboard/training/training-proposals-provider";
import styles from "./dialogues-edit-proposal-styles";
import { Typography, TextField, FormControl, Link } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions
// import { editProposal } from "repository/actions/data/dashboard/proposals";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, editProposal } = props;

  // const {proposals, setProposals, editProposal} = React.useContext(TrainingProposalsContext);

  const [description, setDescription] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [attachmentName, setAttachmentName] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [descriptionError, setDescriptionError] = React.useState(null);
  const [titleError, setTitleError] = React.useState(null);
  const [attachmentError, setAttachmentError] = React.useState(null);

  useEffect(() => {
    if (data) {
      setDescription(data.description);
      setTitle(data.title);
    }
  }, [data]);

  const handleEditProposal = () => {
    if (user && user != null) {
      if (description == null || title == null) {
        toast.error(`You have missing information for editing this proposal`);
        if (description === null) {
          setDescriptionError("Description is required.");
        }
        if (title == null) {
          setTitleError("Title is required.");
        }
      } else {
        let saveData = {
          description: description,
          title: title,
          attachment: attachment,
        };

        setSaveLoading(true);

        editProposal( saveData, itemId, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Proposal ${saveData.title} successfully edited`);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 15728640) {
      toast.error("File is too big. The maximum size allowed is 15MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setAttachment(e.target.result);
        setAttachmentName(target.files[0].name);
      };
    }
  };

  return (
    <Popup
      title="Edit location"
      subtitle="You can edit the details of your proposal below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleEditProposal()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <input
            accept="image/* pdf/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            disabled={saveLoading}
            onChange={handleCapture}
          />
          <label htmlFor="contained-button-file" disabled={saveLoading}>
            {!attachment && (
              <div className={classes.attachmentText}>
                <CloudUpload />
              </div>
            )}
            {attachmentName && (
              <Typography className={classes.attachmentText}>
                Attachment : {attachmentName}
              </Typography>
            )}
            {!attachmentName && (
              <Typography className={classes.attachmentText}>
                Click below to change the existing attachment
              </Typography>
            )}

            <Typography className={classes.error}>{attachmentError}</Typography>
            <Link
              className={classes.link}
              disabled={saveLoading}
              component="span"
              variant="body2"
            >
              <div>Change Attachment (Max: 15mb)</div>
            </Link>
          </label>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Teambuilding, HR sessions"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Proposal Description"}
              disabled={saveLoading}
              required
              helperText={descriptionError}
              error={descriptionError != null}
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
