import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";
import styles from "./dialogues-edit-highest-qualifications-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";

//Actions
import { updateNewPersonnel } from "repository/actions/data/dashboard/personnel";
const useStyles = makeStyles(styles);

function EditHighestQualificationsDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, personId, person, setQualifications, user } = props;
  const {personnel, setPersonnel} = React.useContext(AllPersonnelContext);

  const [highestQualifications, setHighestQualifications] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newQualification, setNewQualification] = React.useState(null);
  const [newQualificationError, setNewQualificationError] =
    React.useState(null);

  useEffect(() => {
    if (person && person != null) {
      if (person.highest_qualifications) {
        console.log(person.highest_qualifications)
        setHighestQualifications(person.highest_qualifications);
      }
    }
  }, [person]);

  const handleAddNewQualification = () => {
    if (newQualification == null) {
      setNewQualificationError("Cannot have an empty qualification");
    } else {
      let newHighestQualifications = [newQualification].concat(
        highestQualifications
      );
      setHighestQualifications(newHighestQualifications);
      setNewQualificationError(null);
    }
  };

  const handleSaveHighestQualifications = () => {
    if (user && user != null) {
      let patchData = {
        ...person,
        highest_qualifications: highestQualifications,
      };
      console.log(patchData)
      updateNewPersonnel( personId, patchData, user.idToken)
        .then((result) => {
          toast.success(
            `Highest qualifications for ${person.firstName} ${person.lastName} updated`
          );
          setQualifications(highestQualifications);
          handleClose();
          setSaveLoading(false);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information."
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const onPressedDeleteHighestQualification = (index) => {
    if (highestQualifications.length > index) {
      let newHighestQualifications = [];

      for (let [i, e] of highestQualifications.entries()) {
        if (i != index) {
          newHighestQualifications.push(e);
        }
      }
      setHighestQualifications(newHighestQualifications);
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Highest Qualifications"
      subtitle="Edit the highest qualifications for this person"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveHighestQualifications()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: M.Sc in Healthcare"}
                    disabled={saveLoading}
                    required
                    helperText={newQualificationError}
                    error={newQualificationError != null}
                    label="New Highest Qualification"
                    value={newQualification}
                    onChange={(event) =>
                      setNewQualification(event.target.value)
                    }
                    margin="normal"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    onClick={() => handleAddNewQualification()}
                  >
                    Add Qualification
                  </Button>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <List className={classes.root}>
                  {highestQualifications.map((entry, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <SubjectIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Grid container spacing={3}>
                              <Grid item xs={9}>
                                {entry}
                              </Grid>
                              <Grid item xs={3}>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={(event) => {
                                    onPressedDeleteHighestQualification(index);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditHighestQualificationsDialogue);
