import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Breadcrumbs, Paper } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./navigation-crumbs-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();
  const { crumbs } = props;
  let textStyle;

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs
          separator={
            <div>
              <NavigateNextIcon
                fontSize="small"
                className={classes.separator}
              />
            </div>
          }
          className={classes.breadcrumbs}
        >
          {crumbs.map((item, index) => {
            if (index === 0) {
              textStyle = classes.individualTextInitial;
            } else {
              textStyle = classes.individualText;
            }
            return (
              <Link
                className={textStyle}
                color="inherit"
                onClick={() => props.history.push(`/${item.path}`)}
                href="#"
              >
                {item.name}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Paper>
      <div className={classes.bottomContent}></div>
    </div>
  );
});
