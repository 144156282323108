import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import SupervisorsView from "./single-student-supervisors-view";

//Dialogues
import DialogueAssignSupervisor from "routes/dashboard/dialogues/students/assign-supervisor";
import DialogueDeleteSupervisor from "routes/dashboard/dialogues/students/delete-supervisor";


export default withRouter(function (props) {
  const { student, setStudent, user, editStudent, getStudent } = props;

  const [supervisors, setSupervisors] = useState(null);
  const [showAssignSupervisorDialogue, setShowAssignSupervisorDialogue] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showDeleteSupervisorDialogue, setShowDeleteSupervisorDialogue] = useState(false);


  useEffect(() => {
    if (student != null) {
      setSupervisors(student.supervisors);
    }
  }, [student]);

  return (
    <div>
      <SupervisorsView
        loading={!supervisors}
        sectionDescription={
          "You can view and delete all related supervisors with this student."
        }
        supervisors={supervisors || []}
        setSupervisors={setSupervisors}
        toggleAssignSupervisorForm={() => setShowAssignSupervisorDialogue(true)}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteSupervisorDialogue(true);
        }}
      />
      <DialogueAssignSupervisor
        isOpen={showAssignSupervisorDialogue}
        setClose={() => setShowAssignSupervisorDialogue(false)}
        supervisors={supervisors || []}
        setSupervisors={setSupervisors}
        student={student}
        setStudent={setStudent}
        user={user}
        editStudent={editStudent}
        getStudent={getStudent}
      />

    <DialogueDeleteSupervisor 
        loading={!student}
        isOpen={showDeleteSupervisorDialogue}
        setClose={() => setShowDeleteSupervisorDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
        supervisorIndex={selectedIndex}
        supervisors={supervisors}
        // getStudent={getStudent}
      />
    </div>
  );
});
