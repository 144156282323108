import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleProposal } from "repository/actions/data/dashboard/proposals";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./training-single-proposal-nav-crumbs";

export default withRouter(function (props) {
  const proposalId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);

  const [proposal, setProposal] = useState(null);

  useEffect(() => {
    if (user && user != null) {
      getSingleProposal(proposalId, user.idToken).then((result) => {
        setProposal(result);
      });
    }
  }, []);

  return <NavCrumbs proposal={proposal}></NavCrumbs>;
});
