import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  IconButton,
  Paper,
  Input,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { proposal, user, editProposal, setProposal } = props;
  const classes = useStyles();

  const [comment, setComment] = React.useState("");

  const handleAddComment = async () => {
    let newComments = [...proposal.comments];
    newComments.push(comment);
    let payload = {
      comments: newComments,
    };
    edit(payload);
  };

  const handleRemoveComment = (index) => {
    let newComments = [...proposal.comments];
    newComments.splice(index, 1);
    let payload = {
      comments: newComments,
    };
    edit(payload);
  };
  const edit = async (payload) => {
    await editProposal(proposal._id, payload)
      .then((result) => {
        setComment("");
        setProposal(result);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  return (
    <Panel title={"Comments"} loading={!proposal}>
      <div>
        <FormControl className={classes.inputType}>
          <Input
            label="Notes"
            placeholder="Notes"
            type="text"
            value={comment}
            style={{ marginBottom: 20 }}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  style={{
                    boxShadow: "0 2px 2px 0px rgba(0,0,0,0.1)",
                    marginTop: -20,
                  }}
                  disabled={comment == ""}
                  onClick={() => handleAddComment()}
                >
                  <DoneIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {proposal &&
          proposal.comments.map((item, index) => {
            return (
              <Paper
                key={`comment-${index}`}
                className={classes.miniSection}
                style={{
                  paddingRight: 10,
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {item}
                <IconButton
                  onClick={() => {
                    handleRemoveComment(index);
                  }}
                >
                  <CloseIcon
                    style={{
                      fontSize: 15,
                    }}
                  />
                </IconButton>
              </Paper>
            );
          })}
      </div>
    </Panel>
  );
});
