import React from 'react';

export const AllLecturersContext = React.createContext();

const initialState = null;

const reducer = (state, action) => {
    let obj = {};
    let arrayPayload = [];
    switch (action.type) {
      case 'UpdateLecturers':
        return action.payload;
      case 'AddLecturer':
        if (state === null) {
            return [
                action.payload
            ];
        }
        return [
            ...state,
            action.payload
        ];
      case 'EditLecturer': 
        if (state) {
          arrayPayload.push(...state);
          for (const [i, v] of arrayPayload.entries()) {
            if (v._id === action.payload.data._id) {
              const item = arrayPayload[i];
              arrayPayload[i] = {...item, ...action.payload.data};
            }
          }
          obj = arrayPayload;
        }
        return obj; 
      case 'RemoveLecturer':
          if (state) {
            arrayPayload.push(...state);
            for (const [i, v] of arrayPayload.entries()) {
              if (v._id === action.payload._id) {
                arrayPayload.splice(i, 1);
              }
            }
            obj = arrayPayload;
          }
        return obj;   
      default: throw new Error('Unexpected action');
    }
};

export const AllLecturersProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <AllLecturersContext.Provider value={contextValue}>
      {children}
    </AllLecturersContext.Provider>
  );
};