import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

//Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    supervisors,
    onPressedDeleteItem,
    sectionDescription,
    toggleAssignSupervisorForm,
    
  } = props;


  const onPressedItem = (id) => {
    window.location.href=`/administration/personnel/${id}`
  } 
   

  return (
    <Section
      title="Supervisors"
      loading={loading}
      actionName="Assign Supervisor"
      handleAction={toggleAssignSupervisorForm}
      sectionDescription={sectionDescription}
    >
      {supervisors.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.labelText}>
                    First Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.labelText}>
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.labelText}>Starts</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.labelText}>Expires</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supervisors.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left"  onClick={(event) => {onPressedItem(row.personnelId)}}>
                      {row.person.firstName}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left"  onClick={(event) => {onPressedItem(row.personnelId)}}>
                      {row.person.lastName}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left"  onClick={(event) => {onPressedItem(row.personnelId)}}>
                      <Moment format="D MMMM, YYYY" date={row.startsAt} />
                    </TableCell>
                    <TableCell className={classes.cellName} align="left"  onClick={(event) => {onPressedItem(row.personnelId)}}>
                      <Moment format="D MMMM, YYYY" date={row.expiresAt} />
                    </TableCell>
                    <TableCell className={classes.cellType} align="right" >
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {supervisors.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          No supervisors assigned to this student
        </Typography>
      )}
    </Section>
  );
}
