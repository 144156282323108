import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import All from './routes/all';
//import PaymentPlans from './routes/payment-plans';

export default function (props) {
  const {campus} = props

    return(
        <div>
            <Switch>
                {/*<Route exact path='/students/payment-plans' component={() => <PaymentPlans />} /> */}
                <Route path='/students/all-malta' component={() => <All />} />
                <Route path='/students/all-dubai' component={() => <All />} />

                <Route exact path='/students' component={() => <Redirect to={`/students/all-malta`} />} />
            </Switch>
        </div>
    )
}