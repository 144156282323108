import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";

import { TextField, FormControl, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import SubjectIcon from "@material-ui/icons/Subject";
import moment from "moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    project,
    setProject,
    isNewProject,
    panelDescription,
    showProjectTimeDialogue,
  } = props;
  const classes = useStyles();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [durationWeeks, setDurationWeeks] = useState(null);

  const handleDuration = (event) => {
    let duration = event.target.value;

    setDurationWeeks(duration);

    let endDate = moment(startDate).add(duration, "weeks").valueOf();
    setEndDate(endDate);

    setProject({ ...project, duration: duration, endDate: endDate });
  };

  useEffect(() => {
    if (endDate) {
      setProject({ ...project, endDate: endDate.valueOf() });
    }
  }, [endDate]);

  useEffect(() => {
    if (project) {
      if (!isNewProject) {
        setStartDate(project.startDate);
        setEndDate(project.endDate);
        setDurationWeeks(project.duration);
      }
    }
  }, [project]);

  return (
    <Panel
      title={"Timings"}
      loading={loading}
      panelDescription={panelDescription}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-between" }}
          >
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setProject({
                      ...project,
                      startDate: moment(date).valueOf(),
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.inputType}>
                <TextField
                  style={{ width: "100%" }}
                  placeholder="weeks"
                  label="Duration"
                  // variant="inline"
                  type="number"
                  value={durationWeeks}
                  onChange={(event) => {
                    handleDuration(event);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    // setProject({...project, endDate:moment(date).valueOf()})
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </Panel>
  );
});
