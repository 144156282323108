import React from 'react';

// Parents
// import CandidatesTabs from './dash-candidates-tabs';
import CandidatesRoutes from './dash-candidates-routes';

export default function (props) {

  return (
    // <CandidatesTabs>
      <CandidatesRoutes />
    // </CandidatesTabs>
  )
}
