import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllStudents = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdateStudents",
          payload: result.data,
        });

        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewStudent = async (dispatch, student, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").create(
          { ...student, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        dispatch({
          type: "AddStudent",
          payload: result,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createStudentDocumentPersonal = async (
  studentId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").create(
          {
            attachmentURI: data.attachment,
            title: data.title,
            type: "student",
            subtype: "personal",
            studentId: studentId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = [...documents];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editStudent = async (dispatch, data, studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").patch(
          studentId,
          { ...data, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        dispatch({
          type: "EditStudent",
          payload: {
            studentId: studentId,
            data: result,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editStudentFromSinglePage = async (
  dispatch,
  data,
  studentId,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").patch(
          studentId,
          { ...data, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        dispatch({
          type: "EditStudentFromSinglePage",
          payload: {
            studentId: studentId,
            data: result,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteStudent = async (dispatch, studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").remove(studentId, {
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "RemoveStudent",
          payload: result,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleStudent = async (studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").get(studentId, {
          query: {
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createStudentDocument = async (
  studentId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").create(
          {
            attachmentURI: data.attachment,
            title: data.title,
            type: "student",
            subtype: "transcript",
            studentId: studentId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = [...documents];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createStudentLetter = async (
  studentId,
  cohortId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").create(
          {
            // attachmentURI: data.attachment,
            title: data.title,
            acceptanceStatus: data.acceptanceStatus,
            type: "student",
            subtype: "letter",
            studentId: studentId,
            cohortId: cohortId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = documents ? [...documents] : [];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocumentsPersonal = async (studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").find({
          query: {
            studentId: studentId,
            type: "student",
            subtype: "personal",
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocuments = async (studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").find({
          query: {
            studentId: studentId,
            type: "student",
            subtype: "transcript",
            firebaseToken: token,
          },
        });
        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedLetters = async (studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/documents").find({
          query: {
            studentId: studentId,
            type: "student",
            subtype: "letter",
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const changeStudentCohort = async (studentId, cohortId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/students").patch(
          studentId,
          {
            cohortId: cohortId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedPaymentPlans = async (studentId, token) => {
  return new Promise(async (resolve, reject) => {
    try {

        let result = await apiClient.service("v1/paymentschedules").find({
          query: {
            studentId: studentId,
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const assignNewCohort = async (
  student,
  enrolment,
  newCohortId,
  newCohort,
  selectedModuleIds,
  membershipType,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let studentCohortIds = JSON.parse(JSON.stringify(student.cohortIds));
      let studentOnlineCohortIds = student.onlineCohortIds
        ? JSON.parse(JSON.stringify(student.onlineCohortIds))
        : [];
      let studentOnsiteCohortIds = student.onsiteCohortIds
        ? JSON.parse(JSON.stringify(student.onsiteCohortIds))
        : [];
      let studentBlendedCohortIds = student.blendedCohortIds
        ? JSON.parse(JSON.stringify(student.blendedCohortIds))
        : [];
      let cohortModuleMap = student.cohortModuleMap
        ? JSON.parse(JSON.stringify(student.cohortModuleMap))
        : {};

      let cohortStudentIds = newCohort.studentIds ? newCohort.studentIds : [];
      let cohortOnlineStudentIds = newCohort.onlineStudentIds
        ? newCohort.onlineStudentIds
        : [];
      let cohortOnsiteStudentIds = newCohort.onsiteStudentIds
        ? newCohort.onsiteStudentIds
        : [];
      let cohortBlendedStudentIds = newCohort.blendedStudentIds
        ? newCohort.blendedStudentIds
        : [];

      if (studentCohortIds.indexOf(newCohortId) >= 0) {
        reject("Cohort already assigned to student");
      } else {
        if (
          selectedModuleIds &&
          selectedModuleIds != null &&
          selectedModuleIds.length > 0
        ) {
          studentCohortIds.push(newCohortId);
          cohortModuleMap[newCohortId] = selectedModuleIds;

          if (membershipType == "online") {
            studentOnlineCohortIds.push(newCohortId);
            cohortOnlineStudentIds.push(student._id);
          } else if (membershipType == "onsite") {
            studentOnsiteCohortIds.push(newCohortId);
            cohortOnsiteStudentIds.push(student._id);
          } else if (membershipType == "blended") {
            studentBlendedCohortIds.push(newCohortId);
            cohortBlendedStudentIds.push(student._id);
          }

          let enrollments = student.enrollments;
          enrollments.push(enrolment);

          let result = await apiClient.service("v1/students").patch(
            student._id,
            {
              cohortIds: studentCohortIds,
              cohortModuleMap: cohortModuleMap,
              onlineCohortIds: studentOnlineCohortIds,
              onsiteCohortIds: studentOnsiteCohortIds,
              blendedCohortIds: studentBlendedCohortIds,
              enrollments: enrollments,
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          cohortStudentIds.push(student._id);

          let cohortPatchData = {
            studentIds: cohortStudentIds,
            onlineStudentIds: cohortOnlineStudentIds,
            onsiteStudentIds: cohortOnsiteStudentIds,
            blendedStudentIds: cohortBlendedStudentIds,
          };
          let cohortResult = await apiClient
            .service("v1/cohorts")
            .patch(newCohortId, cohortPatchData, {
              query: {
                firebaseToken: token,
              },
            });

          resolve(result);
        } else {
          reject("Please select at least 1 module");
        }
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const editCohortModuleAssignments = async (
  student,
  cohortId,
  selectedModules,
  exitPoint,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (student) {
        let patchData = {};
        patchData.cohortModuleMap = JSON.parse(
          JSON.stringify(student.cohortModuleMap)
        );
        patchData.cohortModuleMap[cohortId] = selectedModules;

        let idx = student.enrollments.findIndex((r) => {
          return r.cohortId === cohortId;
        });

        let enrollmentsStudent = student.enrollments;
        enrollmentsStudent[idx].exitPoint.title = exitPoint;
        patchData.enrollments = enrollmentsStudent;

        let result = await apiClient
          .service("v1/students")
          .patch(student._id, patchData, {
            query: {
              firebaseToken: token,
            },
          });
        resolve(result);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteCohortMembership = async (
  student,
  cohortId,
  fullCohort,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (student) {
        let newCohortIds = [];
        let newOnlineCohortIds = [];
        let newOnsiteCohortIds = [];
        let newBlendedCohortIds = [];

        for (let [cIndex, c] of student.cohortIds.entries()) {
          if (c !== cohortId) {
            newCohortIds.push(c);
          }
        }

        for (let [cIndex, c] of student.onlineCohortIds.entries()) {
          if (c !== cohortId) {
            newOnlineCohortIds.push(c);
          }
        }

        for (let [cIndex, c] of student.onsiteCohortIds.entries()) {
          if (c !== cohortId) {
            newOnsiteCohortIds.push(c);
          }
        }

        for (let [cIndex, c] of student.blendedCohortIds.entries()) {
          if (c !== cohortId) {
            newBlendedCohortIds.push(c);
          }
        }

        let studentCohortModuleMap = JSON.parse(
          JSON.stringify(student.cohortModuleMap)
        );
        studentCohortModuleMap[cohortId] = undefined;

        student = await apiClient.service("v1/students").patch(
          student._id,
          {
            cohortIds: newCohortIds,
            onlineCohortIds: newOnlineCohortIds,
            onsiteCohortIds: newOnsiteCohortIds,
            blendedCohortIds: newBlendedCohortIds,
            cohortModuleMap: studentCohortModuleMap,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let cohortStudentIds = fullCohort.studentIds
          ? fullCohort.studentIds
          : [];
        let cohortOnlineStudentIds = fullCohort.onlineStudentIds
          ? fullCohort.onlineStudentIds
          : [];
        let cohortOnsiteStudentIds = fullCohort.onsiteStudentIds
          ? fullCohort.onsiteStudentIds
          : [];
        let cohortBlendedStudentIds = fullCohort.blendedStudentIds
          ? fullCohort.blendedStudentIds
          : [];

        let newCohortStudentIds = [];
        let newCohortOnlineStudentIds = [];
        let newCohortOnsiteStudentIds = [];
        let newCohortBlendedStudentIds = [];

        for (let [csiIndex, csi] of cohortStudentIds.entries()) {
          if (csi != student._id) {
            newCohortStudentIds.push(csi);
          }
        }

        for (let [csiIndex, csi] of cohortOnlineStudentIds.entries()) {
          if (csi != student._id) {
            newCohortOnlineStudentIds.push(csi);
          }
        }

        for (let [csiIndex, csi] of cohortOnsiteStudentIds.entries()) {
          if (csi != student._id) {
            newCohortOnsiteStudentIds.push(csi);
          }
        }

        for (let [csiIndex, csi] of cohortBlendedStudentIds.entries()) {
          if (csi != student._id) {
            newCohortBlendedStudentIds.push(csi);
          }
        }

        let cohortPatchData = {
          studentIds: newCohortStudentIds,
          onlineStudentIds: newCohortOnlineStudentIds,
          onsiteStudentIds: cohortOnsiteStudentIds,
          blendedStudentIds: cohortBlendedStudentIds,
        };

        let cohortResult = await apiClient
          .service("v1/cohorts")
          .patch(cohortId, cohortPatchData, {
            query: {
              firebaseToken: token,
            },
          });

        resolve(student);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const assignMentor = async (
  studentId,
  personnelId,
  courseId,
  startDate,
  expiryDate,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient
        .service("v1/personnel-role-associations")
        .create(
          {
            roleId: "mentor",
            personnelId: personnelId,
            startsAt: startDate,
            expiresAt: expiryDate,
            studentId: studentId,
            courseId: courseId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const assignSupervisor = async (
  studentId,
  personnelId,
  courseId,
  startDate,
  expiryDate,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient
        .service("v1/personnel-role-associations")
        .create(
          {
            roleId: "supervisor",
            personnelId: personnelId,
            courseId: courseId,
            expiresAt: expiryDate,
            startsAt: startDate,
            studentId: studentId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteSupervisorMentor = async (
  studentId,
  personnelId,
  courseId,
  role,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient
        .service("v1/personnel-role-associations")
        .find({
          query: {
            personnelId: personnelId,
            courseId: courseId,
            studentId: studentId,
            role: {
              _id: role,
            },
            firebaseToken: token,
          },
        });

      if (result.data.length == 1) {
        let roleAssociationId = result.data[0]._id;
        let deletedResult = await apiClient
          .service("v1/personnel-role-associations")
          .remove(roleAssociationId, {
            query: {
              firebaseToken: token,
            },
          });
      }
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const changeStudentStatus = async (
  callback,
  studentId,
  newStatus,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/students").patch(
        studentId,
        {
          status: newStatus,
        },
        {
          query: {
            firebaseToken: token,
          },
        }
      );

      callback(result);

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
