import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import PaymentPlansView from "./single-student-payment-plans-view";

//Dialogues
import DialogueCreatePaymentPlan from "routes/dashboard/dialogues/payment-plans/create-payment-plan";
import DialogueEditPaymentPlan from "routes/dashboard/dialogues/payment-plans/edit-payment-plan";
import DialogueViewPaymentPlan from "routes/dashboard/dialogues/payment-plans/view-payment-plan";
import DialogueDeletePaymentPlan from "routes/dashboard/dialogues/payment-plans/delete-payment-plan";

export default withRouter(function (props) {
  const { student, setStudent, user, editStudent,getStudent } = props;
  const [showNewPaymentPlanDialogue, setShowNewPaymentPlanDialogue] =
    useState(false);
  const [showEditPaymentPlanDialogue, setShowEditPaymentPlanDialogue] =
    useState(false);
  const [showViewPaymentPlanDialogue, setShowViewPaymentPlanDialogue] =
    useState(false);
  const [showDeletePaymentPlanDialogue, setShowDeletePaymentPlanDialogue] =
    useState(false);

  const [paymentPlans, setPaymentPlans] = useState([]);
  const [cohorts, setCohorts] = useState(null);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (student != null) {
      setPaymentPlans(student.paymentSchedules ? student.paymentSchedules : []);
      setCohorts(student.cohorts);
    }
  }, [student]);

  // Editing Item
  let editingItem = null;
  if (paymentPlans) {
    if (paymentPlans[selectedIndex]) {
      editingItem = paymentPlans[selectedIndex];
    }
  }

  return (
    <div>
      <PaymentPlansView
        loading={!paymentPlans}
        toggleAddPaymentPlanForm={() => setShowNewPaymentPlanDialogue(true)}
        sectionDescription={"You can manage all of your payment plans below"}
        paymentPlans={paymentPlans || []}
        setPaymentPlans={setPaymentPlans}
        student={student}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditPaymentPlanDialogue(true);
        }}
        onPressedViewItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowViewPaymentPlanDialogue(true);
        }}
        onPressedDeleteItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowDeletePaymentPlanDialogue(true);
        }}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />
      <DialogueCreatePaymentPlan
        isOpen={showNewPaymentPlanDialogue}
        setClose={() => setShowNewPaymentPlanDialogue(false)}
        paymentPlans={paymentPlans || []}
        setPaymentPlans={setPaymentPlans}
        student={student}
        cohorts={cohorts || []}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />

      <DialogueEditPaymentPlan
        isOpen={
          showEditPaymentPlanDialogue && editingItem && editingItem != null
        }
        setClose={() => setShowEditPaymentPlanDialogue(false)}
        setPaymentPlans={setPaymentPlans}
        student={student}
        data={editingItem}
        itemId={editingItem ? editingItem._id : null}
        cohorts={cohorts || []}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />

      <DialogueDeletePaymentPlan
        isOpen={
          showDeletePaymentPlanDialogue && editingItem && editingItem != null
        }
        setClose={() => setShowDeletePaymentPlanDialogue(false)}
        setPaymentPlans={setPaymentPlans}
        student={student}
        data={editingItem}
        itemId={editingItem ? editingItem._id : null}
        cohorts={cohorts || []}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />

      <DialogueViewPaymentPlan
        isOpen={
          showViewPaymentPlanDialogue && editingItem && editingItem != null
        }
        setClose={() => setShowViewPaymentPlanDialogue(false)}
        setPaymentPlans={setPaymentPlans}
        student={student}
        data={editingItem}
        itemId={editingItem ? editingItem._id : null}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />
    </div>
  );
});
