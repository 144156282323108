

import React, { useContext, useEffect, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  candidates: null,
  setCandidates: {},
  getCandidate: {},
  getCandidates: {},
  loadingCandidates: false,
  deleteCandidate: {},
  createNewCandidate: {},
  findCandidatesByDate: {},
  findGeneralCandidatesTotals: {},
  findApplicationsCandidatesTotals: {},
  editCandidate: {},
  findCandidatesByQuery: {}
};

export const AllCandidatesContext = React.createContext(ContextProps);

export const AllCandidatesProvider = ({ children }) => {
  const [candidates, setCandidates] = useState(null);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const { user } = useContext(AuthenticationContext);

  const { getIdToken } = useContext(IdTokenContext)


  const getCandidate = async (candidateId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/candidates").get(candidateId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
          
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const getCandidates = (token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingCandidates(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/candidates").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingCandidates(false);
          setCandidates(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findCandidatesByDate = (epochDate) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCandidates(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/candidates").find({
            query: {
              firebaseToken: token,
              $limit: 100,
              $select: [ 'applications', 'createdAt' ],
              createdAt: {
                $gt: epochDate
              }
            },
          });
          setLoadingCandidates(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findGeneralCandidatesTotals = () => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/candidates").find({
            query: {
              firebaseToken: token,
              isActive:true,
              applications: [],
              $limit:0
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  const findApplicationsCandidatesTotals = () => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/candidates").find({
            query: {
              firebaseToken: token,
              isActive:true,
              applications: {
                $ne: []
              },
              $limit:0
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const findCandidatesByQuery = (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/candidates").find({
            query: {
              ...query,
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewCandidate = async (candidate, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/candidates").create(
            { ...candidate },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          if (candidates == null) {
            setCandidates(result);
          } else {
            let newCandidates = candidates
            newCandidates.push(result)
            setCandidates(newCandidates);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editCandidate = async (candidateId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedCandidate = await apiClient
            .service("v1/candidates")
            .patch(candidateId, data, {
              query: {
                firebaseToken: token,
              },
            });

          // let newData = candidates
          // for (const [i, v] of newData.entries()) {
          //   if (v._id === candidateId) {
          //     newData[i] = data;
          //   }
          // }
          // setCandidates(newData);

          resolve(updatedCandidate);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteCandidate = async (candidateId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/candidates").remove(candidateId, {
            query: {
              firebaseToken: token,
            },
          });

          // let newData = candidates
          // for (const [i, v] of newData.entries()) {
          //   if (v._id === candidateId) {
          //     newData.splice(i, 1);
          //   }
          // }
          // setCandidates(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllCandidatesContext.Provider
      value={{
        candidates,
        setCandidates,
        createNewCandidate,
        getCandidate,
        getCandidates,
        editCandidate,
        deleteCandidate,
        findCandidatesByDate,
        findGeneralCandidatesTotals,
        findApplicationsCandidatesTotals,
        loadingCandidates,
        findCandidatesByQuery
      }}
    >
      {children}
    </AllCandidatesContext.Provider>
  );
};
