import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DialogueGenerateTranscript from "routes/dashboard/dialogues/cohorts/generate-transcript";
import DialogueGenerateMissingSubmissions from "routes/dashboard/dialogues/cohorts/generate-missing-submissions";
import DialogueExtractAssignmentActivity from "routes/dashboard/dialogues/cohorts/generate-assignment-activity";
import DialogueAssignCanvasUrl from "routes/dashboard/dialogues/cohorts/assign-canvas-url";
import SingleCohortDetailsView from "./single-cohort-details-view";

export default withRouter(function (props) {
  let {
    cohort,
    setCohort,
    user,
    getCohort,
    editCohort,
    generateTranscript,
    generateMissingSubmissions,
    extractAssignmentActivity,
  } = props;

  const [showGenerateTranscriptDialogue, setShowGenerateTranscriptDialogue] =
    useState(false);
  const [
    showGenerateMissingSubmissionsDialogue,
    setShowGenerateMissingSubmissionsDialogue,
  ] = useState(false);

  const [
    showGenerateAssignmentActivityDialogue,
    setShowGenerateAssignmentActivityDialogue,
  ] = useState(false);

  const [showAssignCanvasUrlDialogue, setShowAssignCanvasUrlDialogue] =
    useState(false);

  const fabFunctions = [
    {
      name: `Generate Transcript`,
      handleAction: () => setShowGenerateTranscriptDialogue(true),
    },
    {
      name: `Generate Missing Submissions`,
      handleAction: () => setShowGenerateMissingSubmissionsDialogue(true),
    },
    {
      name: `Extract Assignment Activity`,
      handleAction: () => setShowGenerateAssignmentActivityDialogue(true),
    },
  ];

  return (
    <div>
      <SingleCohortDetailsView
        loading={!cohort}
        sectionDescription={"Detailed information about the cohort."}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        user={user}
        cohort={cohort}
        fabFunctions={fabFunctions}
        toggleAssignCanvasForm={() => setShowAssignCanvasUrlDialogue(true)}
      />

      <DialogueGenerateTranscript
        isOpen={showGenerateTranscriptDialogue}
        setClose={() => setShowGenerateTranscriptDialogue(false)}
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        generateTranscript={generateTranscript}
      />

      <DialogueGenerateMissingSubmissions
        isOpen={showGenerateMissingSubmissionsDialogue}
        setClose={() => setShowGenerateMissingSubmissionsDialogue(false)}
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        generateMissingSubmissions={generateMissingSubmissions}
      />

      <DialogueExtractAssignmentActivity
        isOpen={showGenerateAssignmentActivityDialogue}
        setClose={() => setShowGenerateAssignmentActivityDialogue(false)}
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        extractAssignmentActivity={extractAssignmentActivity}
      />

      <DialogueAssignCanvasUrl
        isOpen={showAssignCanvasUrlDialogue}
        setClose={() => setShowAssignCanvasUrlDialogue(false)}
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
      />
    </div>
  );
});
