import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-create-personnel-styles";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Popup from "components/popup";
import DateFnsUtils from "@date-io/date-fns";
import { createNewPersonnel } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function PersonnelDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user } = props;

  const [firstName, setFirstName] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [openDob, setOpenDob] = React.useState(false);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [lastNameError, setLastNameError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [addressError, setAddressError] = React.useState(null);
  const [mqfLevelError, setMqfLevelError] =
    React.useState(null);
  const [titleError, setTitleError] = React.useState(null);
  const [idError, setIdError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [dobError, setDobError] = React.useState(null);

  const handleCreatePersonnel = () => {
    if (user && user != null) {
      if (
        firstName == null ||
        lastName == null ||
        mqfLevel == null ||
        email == null 
      ) {
        toast.error("You have missing information to create a new personnel");
        if (firstName === null) {
          setFirstNameError("First Name is required.");
        }
        if (lastName === null) {
          setLastNameError("Last Name is required.");
        }
        if (mqfLevel === null) {
          setMqfLevelError("MQF Level is required.");
        }
        if (email == null) {
          setEmailError("Email is required.");
        }
       
      } else {
        let data = {
          id_card: id,
          title: title,
          firstName: firstName,
          lastName: lastName,
          mqfLevel: mqfLevel,
          address: address,
          email: email,
          dob: dob,
        };
        toast.dark(`Creating Personnel : ${data.firstName} ${data.lastName}`);
        setSaveLoading(true);

        createNewPersonnel(data, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(
              `New Personnel Created : ${data.firstName} ${data.lastName}`
            );
            setFirstName(null);
            setLastName(null);
            setMqfLevel(null);
            setTitle(null);
            setId(null);
            setAddress(null);
            setEmail(null);
            setDob(null);
            window.location.href = `/administration/personnel/${result._id}`;
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setFirstNameError(null);
    setLastNameError(null);
    setTitleError(null);
    setMqfLevelError(null);
    setIdError(null);
    setEmailError(null);
    setDobError(null);
    setAddressError(null);
  }, [isOpen]);

  return (
    <Popup
      title="Add a new person"
      subtitle="Please fill in the details below to create a new person."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreatePersonnel()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: 274596M"}
                disabled={saveLoading}
                helperText={idError}
                error={idError != null}
                label="ID Card"
                value={id}
                onChange={(event) => setId(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: John"}
                disabled={saveLoading}
                required
                helperText={firstNameError}
                error={firstNameError != null}
                label="First Name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: Doe"}
                disabled={saveLoading}
                helperText={lastNameError}
                error={lastNameError != null}
                label="Last Name"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                openTo="year"
                views={["year", "month", "date"]}
                margin="normal"
                id="date-picker-inline"
                label="Date of Birth"
                value={dob}
                helperText={dobError}
                error={dobError != null}
                onChange={(date) => {
                  setDob(date);
                }}
                onClick={() => setOpenDob(true)}
                onError={(error) => setDobError(error.message)}
                onOpen={() => setOpenDob(true)}
                onClose={() => setOpenDob(false)}
                open={openDob}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: Email"}
                disabled={saveLoading}
                required
                helperText={emailError}
                error={emailError != null}
                label="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 1, Ave Maria, Mosta, MST 1234, Malta"}
                    disabled={saveLoading}
                    helperText={addressError}
                    error={addressError != null}
                    label="Address"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <div />
            <FormControl
              className={classes.inputType}
              error={mqfLevelError != null}
            >
              <InputLabel required disabled={saveLoading}>
                Highest MQF Level
              </InputLabel>
              <Select
                elevation={0}
                onChange={(event) =>
                  setMqfLevel(event.target.value)
                }
                value={mqfLevel}
                required
                error={mqfLevelError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                <MenuItem value={"6"}>
                  Bachelor Degree (MQF6)
                </MenuItem>
                <MenuItem value={"7"}>
                  Masters Degree (MQF7)
                </MenuItem>
                <MenuItem value={"8"}>
                  Doctoral Degree (MQF8)
                </MenuItem>
              </Select>
              <FormHelperText>{mqfLevelError}</FormHelperText>
            </FormControl>
            <div></div>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(PersonnelDialogue);
