import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'students',
      startPath: 'all',
      routes:[
        { name: 'Malta Campus', path: 'all-malta', icon: 'subject_icon'},
        { name: 'Dubai Campus', path: 'all-dubai', icon: 'subject_icon'},

        //{ name: 'Payment Plans', path: 'payment-plans', icon: 'attach_money'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});