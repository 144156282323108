import React, { useContext, useState, useEffect } from "react";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";

// Children
import CalendarView from "./calendar-view";

export default withRouter(function (props) {
  let {user,authUser, hourInfo, setHourInfo,setLeaveTaken, leaveRemaining, setLeaveRemaining} = props
  // const { user } = useContext(AuthenticationContext);

  return (
    <div>
      <CalendarView
        loading={!user}
        user={user}
        authUser={authUser}
        hourInfo={hourInfo}
        setHourInfo={setHourInfo}
        setLeaveTaken={setLeaveTaken}
        leaveRemaining={leaveRemaining}
        setLeaveRemaining={setLeaveRemaining}
       />
    </div>
  );
});
