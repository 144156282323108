import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  users: null,
  setUsers: {},
  findAllUsers: {},
  findActiveUsers: {},
  findSortedUsers: {},
  createNewUser: {},
  deleteUser: {},
  editUser: {},
};

export const AllUsersContext = React.createContext(ContextProps);

export const AllUsersProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const { getIdToken } = useContext(IdTokenContext)

  const findAllUsers = async (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          try {
            let result = await apiClient.service("v1/users").find({
              query: {
                $limit: 100,
                firebaseToken: token,
              },
            });

            if (result.data.length > 0) {
              for (let u of result.data) {
                // if (u.displayPhoto) {
                //   let signedPhotoUrl =
                //     u.displayPhoto &&
                //       u.displayPhoto.url &&
                //       u.displayPhoto.url.indexOf("st-1") > -1
                //       ? await getSignedUrlFromAbsoluteUrl(u.displayPhoto.url, token)
                //       : u.displayPhoto.url;
                //   u.displayPhoto.url = signedPhotoUrl;
                // } else {
                //   u.displayPhoto = {
                //     url: null,
                //   };
                // }
              }
            }

            setUsers(result.data);

            resolve(result);
          } catch (e) {
            console.log(e)
            
          }
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findActiveUsers = async (token, status) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          try {
            let result = await apiClient.service("v1/users").find({
              query: {
                active: status,
                $limit: 100,
                firebaseToken: token,
              },
            });
            setUsers(result.data);
            resolve(result);
          } catch (e) {
            console.log(e)
          }
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const findSortedUsers = async (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/users").find({
            query: {
              $limit: 100,
              $sort: {
                department: -1
              },
              firebaseToken: token,
            },
          });

          if (result.data.length > 0) {
            for (let u of result.data) {
              if (u.displayPhoto) {

                // let signedPhotoUrl =
                //   u.displayPhoto &&
                //   u.displayPhoto.url &&
                //   u.displayPhoto.url.indexOf("st-1") > -1
                //     ? await getSignedUrlFromAbsoluteUrl(u.displayPhoto.url, token)
                //     : u.displayPhoto.url;
                // u.displayPhoto.url = signedPhotoUrl;
              } else {
                u.displayPhoto = {
                  url: null,
                };
              }
            }
          }

          setUsers(result.data);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editUser = async (data, userId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // let apiClient = getClient();
        getIdToken().then(async (token) => {

          let updatedUser = await apiClient
            .service("v1/users")
            .patch(userId, data, {
              query: {
                firebaseToken: token,
              },
            });

          let newData = users;
          for (const [i, v] of newData.entries()) {
            if (v._id === userId) {
              newData[i] = data;
            }
          }
          setUsers(newData);

          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewUser = async (user, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/users").create(user, {
            query: {
              firebaseToken: token,
            },
          });

          if (users == null) {
            setUsers(result);
          } else {
            let newData = users;
            newData.push(result);
            setUsers(newData);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteUser = async (userId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/users").remove(userId, {
            query: {
              firebaseToken: token,
            },
          });

          let newData = users;

          for (const [i, v] of newData.entries()) {
            if (v._id === userId) {
              newData.splice(i, 1);
            }
          }
          setUsers(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const resetPassword = async (userEmail, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/reset-password").create(
            {
              email: userEmail,
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllUsersContext.Provider
      value={{
        users,
        setUsers,
        findAllUsers,
        findSortedUsers,
        createNewUser,
        deleteUser,
        editUser,
        findActiveUsers,
        resetPassword,
      }}
    >
      {children}
    </AllUsersContext.Provider>
  );
};
