import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleCandidateNotesView from "./single-candidate-notes-view";
import { makeStyles } from "@material-ui/core/styles";


// Dialogues
import DialogueDeleteNote from "routes/dashboard/dialogues/candidates/delete-note";

import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";





export default withRouter(function (props) {
  let {
    candidate,
    candidateId,
    setCandidate,
    user,
  } = props;



  const { editCandidate } = useContext(AllCandidatesContext);

  const [showDeleteNoteDialogue, setShowDeleteNoteDialogue] = useState(false);
  const [selectedNoteIdx, setSelectedNoteIdx] = useState(false);



  return (
    <div>
      <SingleCandidateNotesView
        loading={!candidate}
        sectionDescription={"Notes about the candidate."}
        user={user}
        onPressDeleteNoteIdx={(idx) => {setSelectedNoteIdx(idx);
          setShowDeleteNoteDialogue(true)}}
        candidate={candidate}
        candidateId={candidateId}
        editCandidate={editCandidate}
      />


      <DialogueDeleteNote
        isOpen={showDeleteNoteDialogue}
        setClose={() => setShowDeleteNoteDialogue(false)}
        user={user}
        candidate={candidate}
        candidateId={candidateId}
        setCandidate={setCandidate}
        selectedNoteIdx={selectedNoteIdx}
      />

    </div>
  );
});
