import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Courses from './routes/courses';
import Modules from './routes/modules';

export default function () {
    return(
        <div>
            <Switch>
                <Route path='/content/courses' component={() => <Courses />} />
                <Route path='/content/modules' component={() => <Modules />} />
                <Route exact path='/content' component={() => <Redirect to={`/content/courses`} />} />
            </Switch>
        </div>
    )
}