import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
// import { AllEnquiriesContext } from "repository/stores/dashboard/enquiries/enquiries-all-provider";

// Actions
// import { deleteEnquiry } from "repository/actions/data/dashboard/enquiries";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, itemId, user, deleteEnquiry} = props;
    // const {enquiries, setEnquiries, deleteEnquiry} = React.useContext(AllEnquiriesContext);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteItem = () => {
      setSaveLoading(true);

      deleteEnquiry(itemId, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`Enquiry successfully deleted`);
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong.");
          toast.dark(error.message);
          setSaveLoading(false);
        });
    };

    return (
      <Popup
        title="Delete selected enquiry"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteItem()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this enquiry?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
