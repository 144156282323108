import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import DocumentView from "./single-course-documents-view";

// Dialogues
import DialogueCreateDocument from "routes/dashboard/dialogues/courses/create-document";
import DialogueDeleteDocument from "routes/dashboard/dialogues/courses/delete-document";

// Actions
import { getAssociatedDocuments } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const { course, setCourse, user } = props;

  const [documents, setDocuments] = useState(null);

  const [showNewDocumentDialogue, setShowNewDocumentDialogue] = useState(false);
  const [showDeleteDocumentDialogue, setShowDeleteDocumentDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (course != null) {
        getAssociatedDocuments(course._id, user.idToken).then((result) => {
          setDocuments(result.data);
        });
      }
    }
  }, [course]);

  // Editing Item
  let editingItem = null;
  if (documents) {
    if (documents[selectedIndex]) {
      editingItem = documents[selectedIndex];
    }
  }

  return (
    <div>
      <DocumentView
        loading={!documents}
        toggleAddCourseForm={() => setShowNewDocumentDialogue(true)}
        sectionDescription={
          "You can view and delete all of your accredited documents below."
        }
        documents={documents || []}
        setDocuments={setDocuments}
        course={course}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteDocumentDialogue(true);
        }}
        user={user}
        setCourse={setCourse}
      />
      <DialogueCreateDocument
        isOpen={showNewDocumentDialogue}
        setClose={() => setShowNewDocumentDialogue(false)}
        documents={documents || []}
        setDocuments={setDocuments}
        course={course}
        user={user}
        setCourse={setCourse}
      />
      <DialogueDeleteDocument
        isOpen={showDeleteDocumentDialogue}
        setClose={() => setShowDeleteDocumentDialogue(false)}
        itemId={selectedId}
        documents={documents || []}
        setDocuments={setDocuments}
        user={user}
        setCourse={setCourse}
      />
    </div>
  );
});
