import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import styles from './loading-styles.js';
const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();

    let { full, color } = props;

    if (full) {
      return (
        <div className={classes.root}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    } else {

      if (color === 'light') {
        return (
          <div className={classes.rootSmall}>
            <CircularProgress className={classes.progressSmallLight} />
          </div>
        );
      }
      return (
        <div className={classes.rootSmall}>
          <CircularProgress className={classes.progressSmall} />
        </div>
      );
    }
    
}