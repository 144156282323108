import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Button, Typography, Paper, Grid, Switch } from "@material-ui/core";
import DeletePersonnalDialogue from "routes/dashboard/dialogues/personnel/delete-personnel";
import EditPersonnelDialogue from "routes/dashboard/dialogues/personnel/edit-personnel";
import { updateNewPersonnel } from "repository/actions/data/dashboard/personnel";
import { styled } from "@material-ui/core/styles";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { person, setPerson, user } = props;
  const classes = useStyles();
  const [showDeletePersonDialogue, setShowDeletePersonDialogue] =
    useState(false);
  const [showEditPersoneDialogue, setShowEditPersoneDialogue] = useState(false);

  let title = "...";
  let qualification,
    id,
    address,
    email,
    phoneNumber,
    mobileNumber,
    workplace,
    currentPosition,
    createdAt = "";

  if (person) {
    title = `${person.firstName} ${person.lastName}`;
    qualification = person.mqfLevel;
    address = person.address;
    id = person.id_card;
    email = person.email;
    createdAt = person.createdAt;
    phoneNumber = person.phoneNumber;
    mobileNumber = person.mobileNumber;
    workplace = person.workplace;
    currentPosition = person.currentPosition;
  }

  const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FF0000",
      "&:hover": {
        backgroundColor: "#FF000080",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FF0000",
    },
  }));

  const handleIsFlagged = (event) => {
    let checked = event.target.checked;

    let payload = {
      isFlagged: checked,
    };

    updateNewPersonnel(person._id, payload, user.idToken)
      .then((result) => {
        console.log(result);
        setPerson({ ...person, isFlagged: checked });
        if (checked) {
          toast.success(
            "Personnel has been been flagged. Don't forget to add a comment!"
          );
        } else {
          toast.success("Personnel has been been unflagged.");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <div>
      <Panel
        title={title}
        loading={!person}
        sectionDescription={"Personal information about the person."}
        actionName="Edit"
        handleAction={() => setShowEditPersoneDialogue(true)}
      >
        {/* <Paper className={classes.miniSection}>
          <Typography className={classes.fontBold}>Created On</Typography>
          <Typography className={classes.fontRegular}>
            <Moment format="D MMMM, YYYY">{createdAt}</Moment>
          </Typography>
        </Paper> */}
        
        <Grid container spacing={4} style={{ padding: 10 }}>
          <Grid item xs={6}>
          <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Is Flagged</Typography>
              <RedSwitch
                className={classes.switch}
                color="#FF0000"
                onClick={(event) => {
                  handleIsFlagged(event);
                }}
                checked={person.isFlagged}
              />
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>ID Card</Typography>
              <Typography className={classes.fontRegular}>{id}</Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Email</Typography>
              <Typography className={classes.fontRegular}>{email}</Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Address</Typography>
              <Typography className={classes.fontRegular}>{address}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Phone Number</Typography>
              <Typography className={classes.fontRegular}>
                {phoneNumber}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Mobile Number
              </Typography>
              <Typography className={classes.fontRegular}>
                {mobileNumber}
              </Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Workplace</Typography>
              <Typography className={classes.fontRegular}>
                {workplace}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Current Position
              </Typography>
              <Typography className={classes.fontRegular}>
                {currentPosition}
              </Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Highest MQF Level
              </Typography>
              <Typography className={classes.fontRegular}>
                {qualification}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Button
          className={classes.deleteButton}
          onClick={() => setShowDeletePersonDialogue(true)}
          variant="outlined"
        >
          DELETE PERSON
        </Button>
      </Panel>
      <DeletePersonnalDialogue
        isOpen={showDeletePersonDialogue}
        setClose={() => setShowDeletePersonDialogue(false)}
        person={person}
        user={user}
      />
      <EditPersonnelDialogue
        isOpen={showEditPersoneDialogue}
        setClose={() => setShowEditPersoneDialogue(false)}
        person={person}
        user={user}
      />
    </div>
  );
});
