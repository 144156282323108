

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  proposals: null,
  setProposals: {},
  getProposals: {},
  getProposal: {},
  loadingProposals: false,
  deleteProposal: {},
  createNewProposal: {},
  getProposalsByDate: {},
  editProposal: {},
  createProposalTiming:{},
  findProposalTimings:{},
  findProposalTimingsbyConsultant: {}
};

export const AllProposalsContext = React.createContext(ContextProps);

export const AllProposalsProvider = ({ children }) => {
  const [proposals, setProposals] = useState(null);
  const [loadingProposals, setLoadingProposals] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext)



  const getProposals = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProposals(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/proposals").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProposals(false);
          setProposals(result.data);
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const getProposal = (proposalId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingProposals(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/proposals").get(proposalId, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProposals(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  
  const getProposalsByDate = (start, end) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProposals(true);

        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/proposals").find({
            query: {
              firebaseToken: token,
              createdAt: {
                $gt: start,
                $lt: end
              },
             
            },
          });
          setLoadingProposals(false);

          setProposals(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewProposal = async (proposal, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/proposals").create(
            { ...proposal },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createProposalTiming = async (payload, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/proposal-timings").create(payload, {
            query: {
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findProposalTimings = async (proposalId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/proposal-timings").find({
            query: {
              proposalId: proposalId,
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findProposalTimingsbyConsultant = async (currentMonth, consultantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/proposal-timings").find({
            query: {
              month:currentMonth,
              consultantId: consultantId,
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editProposal = async (proposalId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updated = await apiClient
            .service("v1/proposals")
            .patch(proposalId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve(updated);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteProposal = async (proposalId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/proposals").remove(proposalId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllProposalsContext.Provider
      value={{
        proposals,
        setProposals,
        createNewProposal,
        getProposals,
        getProposal,
        editProposal,
        deleteProposal,
        getProposalsByDate,
        loadingProposals,
        createProposalTiming,
        findProposalTimings,
        findProposalTimingsbyConsultant
      }}
    >
      {children}
    </AllProposalsContext.Provider>
  );
};
