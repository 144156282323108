import React from 'react';

// Routes
import Routes from './tickets-all-routes';

export default function (props) {

  
  return (
   <div>
       <Routes />
   </div>
  )
}
