import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";

import apiClient from "utils/api";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);


export default function (props) {
  const classes = useStyles();
  const {
    loading,
    onPressedItem,
    candidate,
    sectionDescription,
    user,
    candidateId,
  } = props;


  const [applications, setApplications] = useState([])

  const getApplications = async () => {
    setApplications([])
    let applicaitonIds = candidate.applications.map(a => a.vacancyId);

    applicaitonIds.map((item) => {
      apiClient.service("v1/vacancies").get(item, {
        query: {
          firebaseToken: user.idToken,
        },
      }).then((result) => {
        setApplications(applications => [...applications, result])
      }).catch(e => {
        console.log(e)
      });
    })


    return applications
  }

  useEffect(() => {
    if (candidate) {
      const getApplicationsData = async () => {
        if (candidate.applications.length > 0) {
          await getApplications()
        }
      }
      getApplicationsData()
    }
  }, [candidate])

  const Hits = () => {

    return (
      <TableBody>
        {applications.map((row, index) => {
          let minSalary = row.minSalary ? row.minSalary.substring(0, row.minSalary.length - 3) + "," + row.minSalary.substring(row.minSalary.length - 3, row.minSalary.length) : row.minSalary
          let maxSalary = row.maxSalary ? row.maxSalary.substring(0, row.maxSalary.length - 3) + "," + row.maxSalary.substring(row.maxSalary.length - 3, row.maxSalary.length) : row.maxSalary

          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row._id)}
                className={classes.cellType}
                align="left"
              >
                {row.positionName}
              </TableCell>
              <TableCell
                onClick={(event) => { onPressedItem(row._id) }}
                className={classes.cellType}
                align="left"
              >
                {row.industry}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row._id)}
                className={classes.cellType}
                align="left"
              >
                {row.contractType}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row._id)}
                className={classes.cellType}
                align="left"
              >
                €{minSalary}{" - "}€{maxSalary}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };


  return (
    <Section
      title="Applications"
      loading={loading}
      sectionDescription={sectionDescription}
      className={classes.root}
    // style={{height:"625px!important"}}

    >


      <TableContainer  component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {" "}
                <Typography className={classes.labelText}>
                  Position Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                {" "}
                <Typography className={classes.labelText}>
                  Industry
                </Typography>
              </TableCell>
              <TableCell align="left">
                {" "}
                <Typography className={classes.labelText}>
                  Contract Type
                </Typography>
              </TableCell>
              <TableCell align="left">
                {" "}
                <Typography className={classes.labelText}>
                  Salary Range
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          {applications.length > 0 &&
            <Hits />
          }
        </Table>
      </TableContainer>

      {candidate.applications.length == 0 && (
        <Typography className={classes.emptyText} align="center">
          {candidate.firstName} has no applications yet.
        </Typography>
      )}

    </Section>
  );
}
