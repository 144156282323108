import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllSessions from "./all-sessions";

export default function () {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/training/sessions"
          component={() => <AllSessions />}
        />
      </Switch>
    </div>
  );
}
