import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllApplicantsContext } from "repository/stores/dashboard/applicants/applicants-all-provider";

// Components
import Applicant from "./applicants-single-view";

// Dialogues
import DialogueEditApplicant from "routes/dashboard/dialogues/applicants/edit-applicant";

export default withRouter(function (props) {
  const applicantId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);
  const { getApplicant, editApplicant, deleteApplicant } = useContext(AllApplicantsContext);

  const [applicant, setApplicant] = useState(null);
  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false);
  const [showEditApplicantDialogue, setShowEditApplicantDialogue] =
    useState(false);

  useEffect(() => {
    if (user && user != null) {
      if (applicantId && applicantId != null) {
        getApplicant(applicantId, user.idToken).then((res) => {
          setApplicant(res);
        });
      }
    }
  }, [user, applicantId]);

  return (
    <div>
      <Applicant
        applicant={applicant}
        setApplicant={setApplicant}
        editApplicant={editApplicant}
        getApplicant={getApplicant}
        user={user}
        deleteApplicant={deleteApplicant}
        handleAction={() => setShowEditApplicantDialogue(true)}
      />

      <DialogueEditApplicant
        user={user}
        applicant={applicant}
        setApplicant={setApplicant}
        editApplicant={editApplicant}
        getApplicant={getApplicant}
        isOpen={showEditApplicantDialogue}
        setClose={() => setShowEditApplicantDialogue(false)}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
