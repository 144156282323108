
import React, { useState, useContext } from "react";
import { getSearchClient } from "utils/algolia";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  personnel: null,
  setPersonnel: {},
  findAllPersonnelAlgolia:{},
  findAllPersonnel:{},
  editPersonnel:{}
};

export const AllPersonnelContext = React.createContext(ContextProps);

export const AllPersonnelProvider = ({ children }) => {
  const [personnel, setPersonnel] = useState(null);

  const { getIdToken } = useContext(IdTokenContext)


  const findAllPersonnelAlgolia = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        const searchClient = getSearchClient();
        const personnelIndex = searchClient.initIndex("Personnel");
        personnelIndex.search().then(({ hits }) => {
          setPersonnel(hits)
          resolve(hits);
        });
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findAllPersonnel = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/personnel").find({
            query: {
              $select: [ 'firstName', 'lastName' ,"_id"],
              firebaseToken: token,
            },
          });
            
          setPersonnel(result.data);
          resolve(result.data);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
  

  const editPersonnel = async (data, id,  token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/personnel").patch(id, data, {
          query: {
            firebaseToken: token,
          },
        });

        let newData = personnel
        for (const [i, v] of newData.entries()) {
          if (v._id === id) {
            newData[i] = data;
          }
        }
        setPersonnel(newData);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };



  return (
    <AllPersonnelContext.Provider
      value={{
        personnel,
        setPersonnel,
        findAllPersonnelAlgolia,
        editPersonnel,
        findAllPersonnel
      }}
    >
      {children}
    </AllPersonnelContext.Provider>
  );
};
