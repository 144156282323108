import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import All from './routes/all-industries';



export default function () {
    return(
        <div>
            <Switch>
                <Route path='/industries/all' component={() => <All />} />
                {/* <Route path='/industries/:id' component={() => <Single />} /> */}
                <Route exact path='/industries' component={() => <Redirect to={`/industries/all`} />} />
            </Switch>
        </div>
    )
}