import React from 'react';

// Parents
import TimesheetsTabs from './dash-timesheets-tabs';
import TimesheetsRoutes from './dash-timesheets-routes';

export default function (props) {

  return (
    <TimesheetsTabs>
      <TimesheetsRoutes />
    </TimesheetsTabs>
  )
}
