import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { TextField, FormControl, Typography, Paper, Grid, Button, IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import { Delete } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";


const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    candidate,
    candidateId,
    user,
    loading,
    editCandidate,
    onPressDeleteNoteIdx
  } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState(null);
  const [content, setContent] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [notes, setNotes] = React.useState([]);

  useEffect(() => {
    if (candidate) {
      setNotes(candidate.notes)
    }
  }, [candidate])



  const handleSaveNotesDetails = () => {
    if (
      title == null ||
      content == null
    ) {
      toast.error("You have missing information for creating a new note");

    } else {

      let data = {
        title: title,
        content: content,

      };

      let newNotes = candidate.notes
      newNotes.push(data)
      setNotes(newNotes)


      setSaveLoading(true);
      toast.dark(`Creating Note : ${data.title}`);
      editCandidate(candidateId, { notes: newNotes }, user.idToken)
        .then((result) => {
          setSaveLoading(false);
          toast.success(`Note created : ${data.title}`);
          setTitle("");
          setContent("");
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          console.log(error)
          setSaveLoading(false);
        });
    }
  };


  return (
    <Section
      className={classes.root}
      title="NOTES"
      loading={!candidate}
      sectionDescription={"Notes about the candidate."}
    >
      {candidate != null &&
        <div>
          <Grid container  spacing={10}>
            <Grid item xs={4}>
              <div>
                <div >
                  <FormControl className={classes.formControlNotes}>
                    <TextField
                      placeholder={"Title"}
                      disabled={saveLoading}
                      required
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      margin="normal"
                      className={classes.noteArea}
                    ></TextField>
                  </FormControl>
                  <div></div>
                  <FormControl className={classes.formControlNotes}>
                    <TextField
                      placeholder={"Content..."}
                      disabled={saveLoading}
                      required
                      multiline={true}
                      rows={20}
                      value={content}
                      className={classes.noteContent}
                      onChange={(event) => setContent(event.target.value)}
                      margin="normal"
                    ></TextField >
                  </FormControl>
                </div>
                <Grid container style={{
                  justifyContent: "flex-end",
                  paddingRight: "70px"
                }}>
                  <Grid item xs={2}>
                    <Button onClick={() => { handleSaveNotesDetails() }} style={{ color: "white", backgroundColor: "#F3B903", padding: "7px 35px" }}>Save</Button>
                  </Grid>
                </Grid>
              </div>

            </Grid>
            <Grid item xs={8}>
              {notes.map((item, idx) => {
                return (
                  <Paper className={classes.miniSection}>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography className={classes.fontBoldNote}>{item.title}</Typography>
                        <Typography className={classes.fontRegularNote}>{item.content}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          style={{ padding: "5px", marginLeft:30 }}
                          aria-label="delete"
                          color="primary"
                          onClick={(event) => {
                            onPressDeleteNoteIdx(idx);
                          }}
                        >
                          <Delete
                            style={{ fontSize: "20px" }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>

                  </Paper>


                )
              })}
            </Grid>
          </Grid>
        </div>
      }
    </Section>
  );
});
