import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
// import { SingleCohortContext } from "repository/stores/dashboard/cohorts/cohorts-single-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import CohortsView from "./cohorts-all-cohorts-view";

// Dialogues
import DialogueCreateCohort from "routes/dashboard/dialogues/cohorts/create-cohort";
import DialogueEditCohort from "routes/dashboard/dialogues/cohorts/edit-cohort";
import DialogueDeleteCohort from "routes/dashboard/dialogues/cohorts/delete-cohort";


export default withRouter(function (props) {
  const { cohorts, setCohorts, getCohorts, getCohort, createNewCohort, editCohort, deleteCohort} = useContext(AllCohortsContext);
  const { courses, setCourses, getCourses } = useContext(AllCoursesContext);
  const {locations, setLocations, getAllLocations} = React.useContext(AllLocationsContext);

  const { user } = useContext(AuthenticationContext);
  // const {getCohort} = useContext(SingleCohortContext);


  const [showNewCohortDialogue, setShowNewCohortDialogue] = useState(false);
  const [showEditCohortDialogue, setShowEditCohortDialogue] = useState(false);
  const [showDeleteCohortDialogue, setShowDeleteCohortDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (cohorts == null) {
        getCohorts(user.idToken);
      }
    }
  }, [cohorts]);
  
  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken)
     
      }
    }
  }, [user]);


  useEffect(() => {
    if (user && user != null) {
      // Editing Item
      if (cohorts) {
        if (selectedId && editingItem == null) {
          getCohort(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);

  return (
    <div>
      <CohortsView
        loading={!cohorts}
        onPressedItem={(id) => props.history.push(`${id}`)}
        toggleAddCohortForm={() => setShowNewCohortDialogue(true)}
        sectionDescription={"You can manage all of your cohorts below."}
        cohorts={cohorts || []}
        courses={courses || []}
        setCohorts={setCohorts}
        getCohort={getCohorts}
        setCourses={setCourses}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditCohortDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteCohortDialogue(true);
        }}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}

      />
      <DialogueCreateCohort
        isOpen={showNewCohortDialogue}
        setClose={() => setShowNewCohortDialogue(false)}
        user={user}
        courses={courses || []}
        setCourses={setCourses}
        getCourses={getCourses}
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        getCohorts={getCohorts}
        createNewCohort={createNewCohort}
        getAllLocations={getAllLocations}
        setRefreshAlgolia={setRefreshAlgolia}
      />
      <DialogueEditCohort
        isOpen={showEditCohortDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditCohortDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        courses={courses}
        setCourses={setCourses}
        getCourses={getCourses}
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        editCohort={editCohort}
        getCohorts={getCohorts}
      />
      <DialogueDeleteCohort
        isOpen={showDeleteCohortDialogue}
        setClose={() => setShowDeleteCohortDialogue(false)}
        itemId={selectedId}
        user={user}
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        getCohorts={getCohorts}
        deleteCohort={deleteCohort}
        setRefreshAlgolia={setRefreshAlgolia}

      />
    </div>
  );
});
