import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";

import moment from "moment";
// Navigation
import NavCrumbs from "./costings-all-costings-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  Stats,
} from "react-instantsearch-dom";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    handleNewProgramme,
    onPressedItem,
    programmes,
    onPressedDeleteItem,
    sectionDescription,
    user,
    refreshAlgolia,
    setRefreshAlgolia,
  } = props;

  let courseMap = {};

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a projects"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const getLevelColor = (level) => {
    if (level == 4) return ["#F6AB7A26", "#F4873F"];
    if (level == 5) return ["#9ED8F726", "#55BDF4"];
    if (level == 6) return ["#A2CCAE26", "#62CB80"];
    if (level == 7) return ["#FED68926", "#FABC45"];
    if (level == 8) return ["#56418126", "#564181"];

  };

  const getNum = (num) => {
    if (num == 0) return "mqf4";
    if (num == 1) return "mqf5";
    if (num == 2) return "mqf5";
    if (num == 3) return "mqf6";
    if (num == 4) return "mqf6";
    if (num == 5) return "mqf7";
    if (num == 6) return "mqf8";

  };

  const Hits = ({ hits, refineNext }) => {
    let courseLevels = hits.reduce(function (r, a) {
      r[getNum(a.courseLevel)] = r[getNum(a.courseLevel)] || [];
      r[getNum(a.courseLevel)].push(a);
      return r;
    }, Object.create(null));
    return (
      <div>
        {Object.keys(courseLevels).length !== 0 && (
          <Grid
            container
            spacing={4}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={6}>
              <Typography className={classes.title}>MQF 4</Typography>
              <div
                style={{
                  border: "1px solid #ececec",
                  borderRadius: 10,
                  padding: 20,
                  height: "100%",
                  minHeight: 200,
                }}
              >
                {/* <Typography>MSc Level 7</Typography> */}
                {(courseLevels.mqf4 || []).map((row, index) => {
                  let pillColor = getLevelColor(4);
                  return (
                    <div
                      onClick={(event) => onPressedItem(row.objectID)}
                      style={{
                        backgroundColor: pillColor[0],
                        width: " fit-content",
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 15,
                        marginRight: 10,
                        float: "left",

                        color: pillColor[1],
                        cursor: "pointer",
                      }}
                    >
                      {row.title}
                    </div>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.title}>MQF 5</Typography>
              <div
                style={{
                  border: "1px solid #ececec",
                  borderRadius: 10,
                  padding: 20,
                  height: "100%",
                  minHeight: 200,
                }}
              >
                {(courseLevels.mqf5 || []).map((row, index) => {
                  let pillColor = getLevelColor(5);
                  return (
                    // <li >
                    <div
                      onClick={(event) => onPressedItem(row.objectID)}
                      style={{
                        backgroundColor: pillColor[0],
                        padding: 10,
                        width: " fit-content",
                        borderRadius: 10,
                        marginBottom: 15,
                        marginRight: 10,
                        float: "left",

                        color: pillColor[1],
                        cursor: "pointer",
                      }}
                    >
                      {row.title}
                    </div>
                    // </li>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.title}>MQF 6</Typography>
              <div
                style={{
                  border: "1px solid #ececec",
                  borderRadius: 10,
                  padding: 20,
                  height: "100%",
                  minHeight: 200,
                }}
              >
                {(courseLevels.mqf6 || []).map((row, index) => {
                  let pillColor = getLevelColor(6);
                  return (
                    <div
                      onClick={(event) => onPressedItem(row.objectID)}
                      style={{
                        backgroundColor: pillColor[0],
                        padding: 10,
                        width: " fit-content",
                        borderRadius: 10,
                        marginBottom: 15,
                        marginRight: 10,
                        color: pillColor[1],
                        cursor: "pointer",
                        float: "left",
                      }}
                    >
                      {row.title}
                    </div>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.title}>MQF 7</Typography>
              <div
                style={{
                  border: "1px solid #ececec",
                  borderRadius: 10,
                  padding: 20,
                  height: "100%",
                  minHeight: 200,
                }}
              >
                {(courseLevels.mqf7 || []).map((row, index) => {
                  let pillColor = getLevelColor(7);
                  return (
                    <div
                      onClick={(event) => onPressedItem(row.objectID)}
                      style={{
                        backgroundColor: pillColor[0],
                        padding: 10,
                        width: " fit-content",
                        borderRadius: 10,
                        marginBottom: 15,
                        marginRight: 10,
                        color: pillColor[1],
                        cursor: "pointer",
                        float: "left",
                      }}
                    >
                      {row.title}
                    </div>
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>MQF 8</Typography>
              <div
                style={{
                  border: "1px solid #ececec",
                  borderRadius: 10,
                  padding: 20,
                  height: "100%",
                  minHeight: 200,
                }}
              >
                {(courseLevels.mqf8 || []).map((row, index) => {
                  let pillColor = getLevelColor(8);
                  return (
                    <div
                      onClick={(event) => onPressedItem(row.objectID)}
                      style={{
                        backgroundColor: pillColor[0],
                        padding: 10,
                        width: " fit-content",
                        borderRadius: 10,
                        marginBottom: 15,
                        marginRight: 10,
                        color: pillColor[1],
                        cursor: "pointer",
                        float: "left",
                      }}
                    >
                      {row.title}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR PROGRAMMES"
        loading={loading}
        actionName="Add Costing"
        handleAction={handleNewProgramme}
        sectionDescription={sectionDescription}
      >
        {programmes.length > 0 && (
          <InstantSearch
            indexName="Programmes"
            searchClient={searchClient}
            refresh={refreshAlgolia}
          >
            <Stats
              translations={{
                stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                  return (
                    <Typography className={classes.stat}>
                      {nbHits} Projects in {processingTimeMS} ms
                    </Typography>
                  );
                },
              }}
            />
            <CustomSearchBox />
            {/* <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Client
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Consultants
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Est. Hrs
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Act. Hrs
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Days Left
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Due Date
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead> */}
            <CustomHits />
            {/* </Table> */}
            {/* // </TableContainer> */}
            <CustomPagination />
          </InstantSearch>
        )}
        {programmes.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any projects yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
