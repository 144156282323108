import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Section from "components/section";
import {
  FormControl,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  Select,
  Button,
  Paper,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

// Navigation
import NavCrumbs from "./calculators-payment-plans-nav-crumbs";

// Styles
import styles from "./calculators-payment-plans-styles";
import Panel from "components/panel";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { loading, courses, sectionDescription } = props;

  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const [courseValue, setCourseValue] = React.useState(null);
  const [durationMonths, setDurationMonths] = React.useState(null);
  const [copied, setCopied] = React.useState(false);

  const [resultPaymentPlan, setResultPaymentPlan] = React.useState([]);

  const [selectedCourseIdError, setSelectedCourseIdError] =
    React.useState(null);
  const [courseValueError, setCourseValueError] = React.useState(null);
  const [depositError, setDepositError] = React.useState(null);
  const [discountError, setDiscountError] = React.useState(null);
  const [paymentPeriodError, setPaymentPeriodError] = React.useState(null);
  const [durationMonthsError, setDurationMonthsError] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const depositOptions = [
    {
      value: 0.05,
      label: "5%",
    },
    {
      value: 0.1,
      label: "10%",
    },
    {
      value: 0.15,
      label: "15%",
    },
    {
      value: 0.2,
      label: "20%",
    },
    {
      value: 0.25,
      label: "25%",
    },
  ];

  const paymentPeriodOptions = [
    {
      value: "m",
      label: "Monthly",
    },
    {
      value: "q",
      label: "Quarterly",
    },
  ];

  function decimalToPercentage(value) {
    return `${value * 100}%`;
  }

  const [startDate, setStartDate] = React.useState(null);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [discount, setDiscount] = React.useState(null);
  const [deposit, setDeposit] = React.useState(depositOptions[0].value);
  const [cents, setRemainingCents] = React.useState(0);

  const [paymentPeriod, setPaymentPeriod] = React.useState(
    paymentPeriodOptions[0].value
  );

  useEffect(() => {
    if (resultPaymentPlan != null && resultPaymentPlan.length > 0) {
      copyTableContents()
        .then(() => {
          toast.info(`Payment plan copied to clipboard`);
        })
        .catch((error) => {
          toast.error(`Cannot copy payment plan`);
        });
    }
  }, [resultPaymentPlan]);

  const generatePaymentPlan = () => {
    if (paymentPeriod == "m") {
      toast.promise(generateMonthlyPaymentPlan, {
        pending: `Generating monthly payment plan`,
        success: `Monthly payment plan generated`,
        error: `Could not generate monthly payment plan`,
      });
    } else {
      toast.promise(generateQuarterlyPaymentPlan, {
        pending: `Generating quarterly payment plan`,
        success: `Quarterly payment plan generated`,
        error: `Could not generate quarterly payment plan`,
      });
    }
  };

  const generateMonthlyPaymentPlan = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          !courseValue ||
          courseValue == null ||
          discount == null ||
          !deposit ||
          deposit == null ||
          !durationMonths ||
          durationMonths == null ||
          !paymentPeriod ||
          paymentPeriod == null
        ) {
          if (!courseValue || courseValue == null) {
            setCourseValueError("Please enter a value for this course");
          }

          if (!deposit || deposit == null) {
            setDepositError("Please select a deposit amount");
          }

          if (discount == null) {
            setDiscountError("Please enter a value for the discount");
          }

          if (!durationMonths || durationMonths == null) {
            setDurationMonthsError("Please enter a duration in months");
          }

          if (!paymentPeriod || paymentPeriod == null) {
            setPaymentPeriodError("Please select a payment period");
          }
        } else {
          let subtotal = courseValue - discount;
          let depositAmount =
            Math.round((subtotal * deposit + Number.EPSILON) * 100) / 100;
          let remainder = subtotal - depositAmount;
          let monthlyPayment =
            Math.round(
              (remainder / (durationMonths - 1) + Number.EPSILON) * 100
            ) / 100;

          let trailingSubTotal = 0;
          let paymentCount = 0;

          let result = [];
          trailingSubTotal += depositAmount;
      

          let remainingCents = 0
          for (let i = 1; i < durationMonths; i++) {
            if (i < durationMonths - 1) {
              remainingCents = remainingCents + (monthlyPayment-Math.floor(monthlyPayment))

              result.push({
                month: i + 1,
                moment: new moment(startDate).add(i, "M"),
                amount: Math.floor(monthlyPayment),
                description: `Monthly Payment: ${i + 1}`,
              });
              trailingSubTotal += monthlyPayment;
            } else {
              let difference =
                Math.round(
                  (subtotal - trailingSubTotal + Number.EPSILON) * 100
                ) / 100;
              let lastPayment = Math.max(0, difference);

              if (lastPayment < 1) {
                let lastNonZeroIndex = 0;

                for (let [rIndex, r] of result.entries()) {
                  if (r.amount > 0) {
                    lastNonZeroIndex = rIndex;
                  }
                }

                result[lastNonZeroIndex].amount += difference;
                result[lastNonZeroIndex].trailingSubTotal += difference;
                trailingSubTotal += difference;

                result.push({
                  month: i + 1,
                  moment: new moment(startDate).add(i, "M"),
                  amount: 0,
                  description: `No Payment: ${i + 1}`,
                  trailingSubTotal: trailingSubTotal,
                });
              } else {
                remainingCents = remainingCents + (lastPayment-Math.floor(lastPayment))

                result.push({
                  month: i + 1,
                  moment: new moment(startDate).add(i, "M"),
                  amount:  Math.floor(lastPayment),
                  description: `Monthly Payment: ${i + 1}`,
                });
              }
            }
          }

          result.splice(0, 0,{
            month: 1,
            moment: new moment(startDate).add(0, "M"),
            amount: parseFloat(depositAmount.toFixed(2)) + parseFloat(remainingCents.toFixed(2)),
            description: `Deposit`,
          });


          let runningTotal = 0;
          let resultString = `Month \t Payment \t Amount \t Running Total\n`;
          for (let i = 0; i < durationMonths; i++) {
            runningTotal += result[i].amount;
            result[i].runningTotal = Math.round(runningTotal * 100) / 100;
            resultString += `${result[i].month} \t ${result[i].description} \t €${result[i].amount} \t €${result[i].runningTotal}\n`;
          }

          setCourseValueError(null);
          setDepositError(null);
          setDiscountError(null);
          setDurationMonthsError(null);
          setPaymentPeriodError(null);
          setSelectedCourseIdError(null);
          setResultPaymentPlan(result);

          resolve(result);
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const generateQuarterlyPaymentPlan = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          !courseValue ||
          courseValue == null ||
          discount == null ||
          !deposit ||
          deposit == null ||
          !durationMonths ||
          durationMonths == null ||
          !paymentPeriod ||
          paymentPeriod == null
        ) {
          if (!courseValue || courseValue == null) {
            setCourseValueError("Please enter a value for this course");
          }

          if (!deposit || deposit == null) {
            setDepositError("Please select a deposit amount");
          }

          if (discount == null) {
            setDiscountError("Please enter a value for the discount");
          }

          if (!durationMonths || durationMonths == null) {
            setDurationMonthsError("Please enter a duration in months");
          }

          if (!paymentPeriod || paymentPeriod == null) {
            setPaymentPeriodError("Please select a payment period");
          }
        } else {
          let subtotal = courseValue - discount;
          let depositAmount =
            Math.round((subtotal * deposit + Number.EPSILON) * 100) / 100;
          let remainder = subtotal - depositAmount;

          let quarters = Math.round(durationMonths / 3);
          let quarterlyPayment =
            Math.round((remainder / (quarters - 1) + Number.EPSILON) * 100) /
            100;

          let trailingSubTotal = 0;
          let paymentCount = 0;
          let result = [];
          result.push({
            month: 1,
            moment: new moment(startDate).add(0, "M"),
            amount: depositAmount,
            description: `Deposit`,
          });
          trailingSubTotal += depositAmount;
          paymentCount++;

          for (let i = 1; i < durationMonths; i++) {
            if (i % 3 == 0 || i == durationMonths - 1) {
              //quarter
              if (i < durationMonths - 1) {
                paymentCount++;
                result.push({
                  month: i + 1,
                  moment: new moment(startDate).add(i, "M"),
                  amount: quarterlyPayment,
                  description: `Quarterly Payment: ${paymentCount}`,
                });
                trailingSubTotal += quarterlyPayment;
              } else {
                let difference =
                  Math.round(
                    (subtotal - trailingSubTotal + Number.EPSILON) * 100
                  ) / 100;

                let lastPayment = Math.max(0, difference);

                if (lastPayment < 1) {
                  let lastNonZeroIndex = 0;

                  for (let [rIndex, r] of result.entries()) {
                    if (r.amount > 0) {
                      lastNonZeroIndex = rIndex;
                    }
                  }

                  result[lastNonZeroIndex].amount += difference;
                  result.push({
                    month: i + 1,
                    moment: new moment(startDate).add(i, "M"),
                    amount: 0,
                    description: `No Payment`,
                  });
                } else {
                  paymentCount++;

                  result.push({
                    month: i + 1,
                    moment: new moment(startDate).add(i, "M"),
                    amount: lastPayment,
                    description: `Quarterly Payment: ${paymentCount}`,
                  });
                }
              }
            } else {
              result.push({
                month: i + 1,
                amount: 0,
                moment: new moment(startDate).add(i, "M"),
                description: `No Payment`,
              });
            }
          }

          let runningTotal = 0;
          let resultString = `Month \t Payment \t Amount \t Running Total\n`;
          for (let i = 0; i < durationMonths; i++) {
            runningTotal += result[i].amount;
            result[i].runningTotal = Math.round(runningTotal * 100) / 100;
            resultString += `${result[i].month} \t ${result[i].description} \t ${result[i].amount} \t ${result[i].runningTotal}\n`;
          }

          setCourseValueError(null);
          setDepositError(null);
          setDiscountError(null);
          setDurationMonthsError(null);
          setPaymentPeriodError(null);
          setSelectedCourseIdError(null);
          setResultPaymentPlan(result);
          resolve(result);
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const copyTableContents = () => {
    return new Promise(async (resolve, reject) => {
      const elTable = document.querySelector("#clipboardTable");
      // create a Range object
      var range = document.createRange();
      // set the Node to select the "range"
      range.selectNode(elTable);
      // add the Range to the set of window selections
      window.getSelection().addRange(range);

      // execute 'copy', can't 'cut' in this case
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      resolve(true);
    });
  };

  return (
    <NavCrumbs>
      <Panel
        title="CALCULATE PAYMENT PLANS"
        loading={loading}
        sectionDescription={sectionDescription}
      >
        <Grid container spacing={4} style={{padding:10}}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  error={selectedCourseIdError != null}
                >
                  <InputLabel
                    required
                    disabled={saveLoading}
                    InputLabelProps={{ shrink: !!selectedCourseId }}
                  >
                    Course
                  </InputLabel>
                  {courses != null && (
                    <Select
                      elevation={0}
                      onChange={(event) =>
                        setSelectedCourseId(event.target.value)
                      }
                      value={selectedCourseId}
                      required
                      error={selectedCourseIdError != null}
                      disabled={saveLoading}
                      input={<Input id="type-menu" />}
                    >
                      {courses.map((item) => {
                        return (
                          <MenuItem value={item._id}>{item.title}</MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  <FormHelperText>{selectedCourseIdError}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      required
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Start Date"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      onClick={() => setOpenStartDate(true)}
                      onOpen={() => setOpenStartDate(true)}
                      onClose={() => setOpenStartDate(false)}
                      open={openStartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change start date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: €9,500"}
                    disabled={saveLoading}
                    required
                    type="number"
                    helperText={courseValueError}
                    error={courseValueError != null}
                    label="Course Value"
                    value={courseValue}
                    InputProps={{
                      inputProps: {
                        max: 100000,
                        min: 10,
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      },
                    }}
                    onChange={(event) =>
                      setCourseValue(parseFloat(event.target.value))
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: €500"}
                    disabled={saveLoading}
                    required
                    type="number"
                    helperText={discountError}
                    error={discountError != null}
                    label="Discount"
                    value={discount}
                    InputProps={{
                      inputProps: {
                        max: 100000,
                        min: 0,
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      },
                    }}
                    onChange={(event) =>
                      setDiscount(parseFloat(event.target.value))
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  error={depositError != null}
                >
                  <InputLabel
                    required
                    disabled={saveLoading}
                    InputLabelProps={{ shrink: !!deposit }}
                  >
                    Deposit
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) =>
                      setDeposit(parseFloat(event.target.value))
                    }
                    value={deposit}
                    defaultValue={depositOptions[0].value}
                    required
                    error={depositError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    {depositOptions.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{depositError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  error={paymentPeriodError != null}
                >
                  <InputLabel
                    required
                    disabled={saveLoading}
                    InputLabelProps={{ shrink: !!paymentPeriod }}
                  >
                    Payment Period
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setPaymentPeriod(event.target.value)}
                    value={paymentPeriod}
                    required
                    error={paymentPeriodError != null}
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    {paymentPeriodOptions.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{paymentPeriodError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 12"}
                    disabled={saveLoading}
                    required
                    type="number"
                    helperText={durationMonthsError}
                    error={durationMonthsError != null}
                    label="Duration Months"
                    value={durationMonths}
                    InputProps={{
                      inputProps: {
                        max: 100000,
                        min: 10,
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      },
                    }}
                    onChange={(event) =>
                      setDurationMonths(parseFloat(event.target.value))
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={() => generatePaymentPlan()}
              >
                Create Plan
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper id="clipboardTable">
              <TableContainer className={classes.miniSection} component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.row}>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelTextBold}>
                          Month
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelTextBold}>
                          Payment
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelTextBold}>
                          Amount
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <Typography className={classes.labelTextBold}>
                          Running Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultPaymentPlan.map((item) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            {" "}
                            <Typography className={classes.labelTextRegular}>
                              {item.moment.format("MMMM YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <Typography className={classes.labelTextRegular}>
                              {item.description}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <Typography className={classes.labelTextRegular}>
                              €{item.amount}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <Typography className={classes.labelTextRegular}>
                              €{item.runningTotal}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Panel>
    </NavCrumbs>
  );
}
