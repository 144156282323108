import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";

import { Typography, Paper, Grid, TextField } from "@material-ui/core";

import moment from "moment";
import paper from "./paper.png";
import advisoryImg from "assets/images/idea_advisory.png";



// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
  } = props;
  const classes = useStyles();


  const [client, setClient] = useState("")
  const [proposalTitle, setProposalTitle] = useState("")
  const [purpose, setPurpose] = useState(`IDEA Advisory Limited (hereinafter IDEA) was tasked with presenting a proposal to ${client} to carry out`)

  return (

    <div>

      <div className={classes.proposalPaper} style={{ backgroundImage: `url(${paper})` }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <img src={advisoryImg} width="200px" />
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <TextField variant="outlined" placeholder="Proposal Title" className={classes.proposalTitle} />
            <hr style={{ borderColor: "#564181" }} />
            <TextField 
            variant="outlined" 
            placeholder="Client"
            onChange={(event)=>{setClient(event.target.value)}}
            className={classes.proposalSubheading} />
          </Grid>
        </Grid>



        <div className={classes.proposalSection}>
          <Typography className={classes.proposalSubheadingText}>
            Introduction
          </Typography>
          <TextField variant="outlined" placeholder="Purpose" className={classes.proposalSubheading} />
          <TextField 
            variant="outlined" 
            placeholder="Purpose"
            multiline={true}
            rows={20}
            value={purpose}
            onChange={(event)=>{setPurpose(event.target.value)}}
            className={classes.proposalTextarea}
            />
        </div>
      </div>

    </div>

  );
});
