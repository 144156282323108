
import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  enquiries: null,
  setEnquiries: {},
  getEnquiries: {},
  createNewEnquiry: {},
  editEnquiry: {},
  deleteEnquiry: {}
};

export const AllEnquiriesContext = React.createContext(ContextProps);

export const AllEnquiriesProvider = ({ children }) => {
  const [enquiries, setEnquiries] = useState(null);
  const [loadingEnquiries, setLoadingEnquiries] = useState(false);

  const { getIdToken } = useContext(IdTokenContext)


  const getEnquiries = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingEnquiries(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/enquiries").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingEnquiries(false);
          setEnquiries(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };




  const createNewEnquiry = async (enquiry, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/enquiries").create(enquiry, {
            query: {
              firebaseToken: token,
            },
          });

          if (enquiries == null) {
            setEnquiries(result);
          } else {
            let newEnquiries = enquiries
            newEnquiries.push(result)
            setEnquiries(newEnquiries);
          }

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const getSingleEnquiry = async (enquiryId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/enquiries").get(enquiryId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const editEnquiry = async (data, enquiryId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient
            .service("v1/enquiries")
            .patch(enquiryId, data, {
              query: {
                firebaseToken: token,
              },
            });

          let newEnquiries = enquiries

          for (const [i, v] of newEnquiries.entries()) {
            if (v._id === enquiryId) {
              newEnquiries[i] = data;
            }
          }
          setEnquiries(newEnquiries);

          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteEnquiry = async (enquiryId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/enquiries").remove(enquiryId, {
            query: {
              firebaseToken: token,
            },
          });


          let newEnquiries = enquiries

          for (const [i, v] of newEnquiries.entries()) {
            if (v._id === enquiryId) {
              newEnquiries.splice(i, 1);
            }
          }
          setEnquiries(newEnquiries);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllEnquiriesContext.Provider
      value={{
        enquiries,
        setEnquiries,
        getEnquiries,
        createNewEnquiry,
        editEnquiry,
        deleteEnquiry
      }}
    >
      {children}
    </AllEnquiriesContext.Provider>
  );
};
