import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
// import { AllModulesContext } from "repository/stores/dashboard/modules/modules-all-provider";

// Actions
// import { deleteModule } from "repository/actions/data/dashboard/modules";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, itemId, user, deleteModule, setRefreshAlgolia } = props;
    // const {modules, setModules, deleteModule} = React.useContext(AllModulesContext);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteModule = () => {
      if (user && user != null) {
        setSaveLoading(true);

        deleteModule(itemId, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Module successfully deleted`);
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected module"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteModule()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this module?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
