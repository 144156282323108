import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";


import TicketsDetailsView from "./single-ticket-details-view";


export default withRouter(function (props) {
  let {
    ticket,
    ticketId,
    user,
    getCohort,
    editTicket,
    setTicket
  } = props;


  return (
    <div>
      <TicketsDetailsView
        loading={!ticket}
        sectionDescription={"Ticket Details."}
        user={user}
        ticket={ticket}
        ticketId={ticketId}
        getCohort={getCohort}
        editTicket={editTicket}
        setTicket={setTicket}
      />


     
    </div>
  );
});
