import React from 'react';

// Parents
import ClientsRoutes from './dash-clients-routes';

export default function (props) {

  return (
      <ClientsRoutes />
  )
}
