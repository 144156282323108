import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProposalsContext } from "repository/stores/dashboard/proposals/proposals-all-provider";

import DialogueCreateProposal from "routes/dashboard/dialogues/proposals/create"
import DialogueDeleteProposal from "routes/dashboard/dialogues/proposals/delete-proposal"

// Children
import ProposalsView from "./proposals-all-proposals-view-new";

export default withRouter(function (props) {
  let {status} = props

  const { user } = useContext(AuthenticationContext);

  const { proposals, getProposals, editProposal, createNewProposal, deleteProposal } =
    useContext(AllProposalsContext);

  const [showDeleteProposalDialogue, setShowDeleteProposalDialogue] =
    useState(false);
  const [showCreateProposalDialogue, setShowCreateProposalDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [nullProposal, setNullProposal] = useState(false);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (proposals == null) {
        getProposals(user.idToken);
      }
    }
  }, [proposals]);

  return (
    <div>
      <ProposalsView
        loading={!true}
        status={status}
        onPressedItem={(id) => props.history.push(`${id}`)}
        sectionDescription={"You can manage all of your Proposals below."}
        proposals={proposals || []}
        toggleAddProposalForm={() => setShowCreateProposalDialogue(true)}
        onPressedDeleteItem={(id) => {
          setSelectedId(id);
          setShowDeleteProposalDialogue(true);
        }}
        user={user}
        editProposal={editProposal}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
        nullProposal={nullProposal}
        setNullProposal={setNullProposal}
      />

      <DialogueCreateProposal
        isOpen={showCreateProposalDialogue}
        setClose={() => setShowCreateProposalDialogue(false)}
        user={user}
        createNewProposal={createNewProposal}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueDeleteProposal
        isOpen={showDeleteProposalDialogue}
        setClose={() => setShowDeleteProposalDialogue(false)}
        user={user}
        proposalId={selectedId}
        setNullProposal={setNullProposal}
        deleteProposal={deleteProposal}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
