const styles = theme => ({
    textField: {
        width: 'calc(100% - 85px)',
        borderRadius: 0,
    },
    spacing: {
        marginTop: 15,
        marginBottom: 15
    },
    miniSection: {
        minHeight: 65,
        paddingLeft: 32,
        paddingTop: 18,
        paddingBottom: 18,
        marginBottom: 18,
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
    },
    fontBold: {
        position: 'absolute',
        fontSize: 16,
        fontFamily: 'proximaNova-bold'
    },
    fontRegular: {
        marginLeft: 202,
        marginTop: 2,
        marginBottom: 5,
        fontSize: 15,
        color: 'rgba(0,0,0,0.5)'
    },
    fontRegularDescription: {
        marginLeft: 10,
        marginBottom: 5,
        fontSize: 16,
        color: 'rgba(0,0,0,0.7)'
    }
});

export default styles;
