import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllCourses from "./all-courses";
import SingleCourse from "./single-course";

export default function () {
  return (
    <div>
      <Switch>
        <Route exact path="/content/courses" component={() => <AllCourses />} />
        <Route
          exact
          path="/content/courses/:id"
          component={() => <SingleCourse />}
        />
      </Switch>
    </div>
  );
}
