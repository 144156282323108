const styles = theme => ({
    root: {
    },
    image: {
      minHeight: '100%',
      minWidth: 1024,
      width: '100%',
      height: 'auto',
      position: 'fixed',
      top: 0,
      left: 0
    }
});
  
export default styles;
  