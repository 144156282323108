import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Section from "components/section";
import { TextField, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Panel from "components/panel";
import { toast } from "react-toastify";

export default withRouter(function (props) {
  let { course, editCourse, user } = props;


  let details = {
    title: "Master of Science in Governance and Management",
    main_fields: [
      "Governance Principles, Practices and Issues",
      "Business Management",
      "Organisational and Ethical Issues Related to Governance",
      "Dissertation",
    ],
    level: "MQF/EFQ Level 7",
    dissertation: true,
    duration: "90 ECTS",
    credits: "90 ECTS",
    access_requirements: [
      "Level 6 Degree and 3 years' work experience",
      "OR",
      "Level 5 Diploma or Higher Diploma and 5 years’ work experience in a supervisory or managerial role.",
      "* Students whose first language is not English will be required to demonstrate English language capability at IELTS level 6.0 or equivalent.",
    ],
    learning_outcomes: [
      "Demonstrate a critical approach towards knowledge, concepts and theories of management in the context of governance and leadership.",
      "Show a range of critical analytical and evaluating skills and techniques, personal qualities, and attributes essential for successful performance when applying leadership and management theories in practice.",
      "Communicate and demonstrate knowledge, skills and ideas that contribute to and advance understandings and ethical practices in governance and leadership.",
      "Research and apply established theories to a range of governance and leadership practices and settings",
    ],
    access_further_studies:
      "Holders of this degree are eligible to register for third cycle programmes leading to Doctoral degrees in Malta and/or in Higher Education Institutions worldwide.",
    additional_information: [
      "IDEA College offers Masters Programmes designed on Taught and Research modes. The programmes are designed on a number of taught modules exposing the students to the knowledge and skills related to the building of competences in the leadership and management related to the field. Students will be exposed to both the theoretical aspects and models of leadership and management as well as their practical implications utilising case studies, review of literature and peer discussions and community of practice.",
      "The Masters programme provides students with an insight into modern day and future governance principles, practices and issues. It is designed to provide a sound understanding of governance and leadership from corporate, investor and stakeholder perspectives. It will illustrate the orgins of the current high profile debate and give students an insight into the major reports which have been published on the subject on the subject, together with an exposure to the related organisational and ethical issues which face directors, governors and organisations' stakeholders. This Master's degree enhances opportunities for acceptance into doctoral degree as well as career development.",
      "The dissertation is a compulsory component of this Masters Programme. The dissertation provides the student with an opportunity to independently identify and investigate a research topic related to the governance and management field. The aim is to produce an original research study enabling the student to develop not only deeper knowledge and understanding of the subject but also through research methodologies and the research journey, strengthening own cognitive critical scholarly competences in the context of the study programme.",
      "IDEA College's programmes adopt a teaching-learning-assessment strategy based on an andragogical model, whereby the student is empowered to pursue and achieve own goals, enhancing graduate attributes, employment opportunities and allow progression for further study.",
      "Application of knowledge contextualised to the work sector is driven by a porblem-solving approach rather than content oriented. Strengthening and enhancing this approach is the involvement of the adult learner with his/her growing reservoir of experience that becomes an increasing resource not only for own learning but also as a contributor to the peer-to-peer learning community. THis is further strengthened by the modular assessment mode applied, through formative online discussions which offer opportunities towards building a community of practice and through the summative assignment designed on a contextualisation approach enhancing their relevance and the student's development in critical thinking and problem-solving competences.",
    ],
  };

  const [diplomaSupplement, setDiplomaSupplement] = useState(details);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (course) {
      if (course.diplomaSupplement) {
        setDiplomaSupplement(course.diplomaSupplement);
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    }
  }, [course]);

  let removeButton = {
    fill: "#FF0000",
    fontSize: "small",
    position: "absolute",
    right: -5,
    top: -5,
    zIndex: 10,
    cursor: "pointer",
  };
  const handleInputListValues = (value, section, index) => {
    let newDiplomaSupplement = { ...diplomaSupplement };

    newDiplomaSupplement[section][index] = value;

    setDiplomaSupplement(newDiplomaSupplement);
  };

  const handleInputList = (section, operation, index) => {
    let newDiplomaSupplement = { ...diplomaSupplement };

    if (operation == "add") {
      newDiplomaSupplement[section].push("");
    }
    if (operation == "remove") {
      newDiplomaSupplement[section].splice(index, 1);
    }

    setDiplomaSupplement(newDiplomaSupplement);
  };

  const handleSaveDiplomaSupplement = () => {

    let payload = {
      diplomaSupplement: diplomaSupplement,
    };
    editCourse(payload, course._id, user.idToken)
      .then((res) => {
        toast.success("Updated");
      })
      .catch((e) => {
        toast.success("Error: ", e.message);
        console.log(e);
      });
  };

  return (
    <Section
      title={"Diploma Supplement"}
      loading={isLoading}
      sectionDescription={"Information about the course."}
      actionName={"Save"}
      handleAction={handleSaveDiplomaSupplement}
    >
      {!isLoading && (
        <div>
          <div style={{ marginBottom: 40 }}>
            <TextField
              id="title"
              label="Title"
              variant="outlined"
              size="small"
              defaultValue={diplomaSupplement.title}
              InputProps={{
                style: { fontSize: 12, borderRadius: 10, marginRight: 20 },
              }}
              onChange={(event) => {
                setDiplomaSupplement({
                  ...diplomaSupplement,
                  title: event.target.value,
                });
              }}
            />
            <TextField
              id="level"
              label="Level"
              variant="outlined"
              size="small"
              defaultValue={diplomaSupplement.level}
              InputProps={{
                style: { fontSize: 12, borderRadius: 10, marginRight: 20 },
              }}
              onChange={(event) => {
                setDiplomaSupplement({
                  ...diplomaSupplement,
                  level: event.target.value,
                });
              }}
            />
            <TextField
              id="duration"
              label="Duration"
              variant="outlined"
              size="small"
              defaultValue={diplomaSupplement.duration}
              InputProps={{
                style: { fontSize: 12, borderRadius: 10, marginRight: 20 },
              }}
              onChange={(event) => {
                setDiplomaSupplement({
                  ...diplomaSupplement,
                  duration: event.target.value,
                });
              }}
            />
            <TextField
              id="credits"
              label="Credits"
              variant="outlined"
              size="small"
              defaultValue={diplomaSupplement.credits}
              InputProps={{
                style: { fontSize: 12, borderRadius: 10, marginRight: 20 },
              }}
              onChange={(event) => {
                setDiplomaSupplement({
                  ...diplomaSupplement,
                  credits: event.target.value,
                });
              }}
            />
          </div>
          {!course.diplomaSupplement && <p style={{color:"#ff0000"}}>** This is a generic Diploma Supplement template **</p>}
          <div style={{ overflow: "scroll" }}>
            <div style={{ display: "flex", gap: 30, width: "1600px" }}>
              <Panel style={{ margin: 20 }}>
                <Typography style={{ fontWeight: 500, marginBottom: 10 }}>
                  Main Fields
                </Typography>
                {diplomaSupplement.main_fields.map((item, index) => {
                  return (
                    <div
                      style={{ marginBottom: 20, position: "relative" }}
                      key={`main_fields-${index}`}
                    >
                      <RemoveCircleIcon
                        style={removeButton}
                        onClick={() =>
                          handleInputList("main_fields", "remove", index)
                        }
                      />
                      <TextField
                        id={`main_fields-${index}`}
                        variant="outlined"
                        size="small"
                        defaultValue={item}
                        multiline
                        rows={4}
                        InputProps={{
                          style: {
                            fontSize: 12,
                            borderRadius: 10,
                            width: "300px",
                          },
                        }}
                        onChange={(event) => {
                          handleInputListValues(
                            event.target.value,
                            "main_fields",
                            index
                          );
                        }}
                      />
                    </div>
                  );
                })}
                <AddCircleIcon
                  fontSize="small"
                  onClick={() => handleInputList("main_fields", "add", 0)}
                />
              </Panel>
              <Panel style={{ marginBottom: 20 }}>
                <Typography style={{ fontWeight: 500, marginBottom: 10 }}>
                  Access Requirements
                </Typography>
                {diplomaSupplement.access_requirements.map((item, index) => {
                  return (
                    <div
                      style={{ marginBottom: 20, position: "relative" }}
                      key={`access_requirements-${index}`}
                    >
                      <RemoveCircleIcon
                        style={removeButton}
                        onClick={() =>
                          handleInputList(
                            "access_requirements",
                            "remove",
                            index
                          )
                        }
                      />
                      <TextField
                        id={`access_requirements-${index}`}
                        variant="outlined"
                        size="small"
                        defaultValue={item}
                        multiline
                        rows={4}
                        InputProps={{
                          style: {
                            fontSize: 12,
                            borderRadius: 10,
                            width: "300px",
                          },
                        }}
                        onChange={(event) => {
                          handleInputListValues(
                            event.target.value,
                            "access_requirements",
                            index
                          );
                        }}
                      />
                    </div>
                  );
                })}
                <AddCircleIcon
                  fontSize="small"
                  onClick={() =>
                    handleInputList("access_requirements", "add", 0)
                  }
                />
              </Panel>
              <Panel style={{ marginBottom: 20 }}>
                <Typography style={{ fontWeight: 500, marginBottom: 10 }}>
                  Learning Outcomes
                </Typography>
                {diplomaSupplement.learning_outcomes.map((item, index) => {
                  return (
                    <div
                      style={{ marginBottom: 20, position: "relative" }}
                      key={`learning_outcomes-${index}`}
                    >
                      <RemoveCircleIcon
                        style={removeButton}
                        onClick={() =>
                          handleInputList("learning_outcomes", "remove", index)
                        }
                      />
                      <TextField
                        id={`learning_outcomes-${index}`}
                        variant="outlined"
                        size="small"
                        defaultValue={item}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            fontSize: 12,
                            borderRadius: 10,
                            width: "300px",
                          },
                        }}
                        onChange={(event) => {
                          handleInputListValues(
                            event.target.value,
                            "learning_outcomes",
                            index
                          );
                        }}
                      />
                    </div>
                  );
                })}
                <AddCircleIcon
                  fontSize="small"
                  onClick={() => handleInputList("learning_outcomes", "add", 0)}
                />
              </Panel>
              <Panel style={{ marginBottom: 20, position: "relative" }}>
                <Typography style={{ fontWeight: 500, marginBottom: 10 }}>
                  Additional Information
                </Typography>
                {diplomaSupplement.additional_information.map((item, index) => {
                  return (
                    <div
                      style={{ marginBottom: 20, position: "relative" }}
                      key={`additional_information-${index}`}
                    >
                      <RemoveCircleIcon
                        style={removeButton}
                        onClick={() =>
                          handleInputList(
                            "additional_information",
                            "remove",
                            index
                          )
                        }
                      />
                      <TextField
                        id={`additional_information-${index}`}
                        variant="outlined"
                        size="small"
                        defaultValue={item}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            fontSize: 12,
                            borderRadius: 10,
                            width: "500px",
                          },
                        }}
                        onChange={(event) => {
                          handleInputListValues(
                            event.target.value,
                            "additional_information",
                            index
                          );
                        }}
                      />
                    </div>
                  );
                })}
                <AddCircleIcon
                  fontSize="small"
                  onClick={() =>
                    handleInputList("additional_information", "add", 0)
                  }
                />
              </Panel>
            </div>
          </div>
        </div>
      )}
    </Section>
  );
});
