import React from 'react';

// Parents
import CostingsRoutes from './dash-costings-routes';

export default function (props) {

  return (
      <CostingsRoutes />
  )
}
