import React from 'react';

//Parents
// import TodayTabs from './dash-today-tabs';
import TodayRoutes from './dash-today-routes';

export default function (props) { 
  return (
    // <TodayTabs>
      <TodayRoutes />
    // </TodayTabs>
  )
}