import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import {
  IconButton,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

//Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    comments,
    onPressedDeleteItem,
    sectionDescription,
    toggleEditCommentsForm,
  } = props;

  return (
    <Section
      title="Comments"
      loading={loading}
      actionName="Add Comment"
      handleAction={toggleEditCommentsForm}
      sectionDescription={sectionDescription}
    >
      {comments && comments.length > 0 && (
        <TableContainer  component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.labelText}>Comment</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.labelText}>
                    Created On
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <Typography className={classes.labelText}></Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comments.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {row.content}
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      <Moment format="D MMMM, YYYY" date={row.createdOn} />
                    </TableCell>
                    <TableCell className={classes.cellType} align="right">
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {comments == null ||
        (comments.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            No comments assigned to this student
          </Typography>
        ))}
    </Section>
  );
}
