import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { TextField, FormControl, MenuItem, Select, Input, InputLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
// import { createNewCourse } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, createNewCourse, setRefreshAlgolia } = props;

  // const {courses, setCourses, createNewCourse} = React.useContext(AllCoursesContext);

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [referenceNumber, setReferenceNumber] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [descriptionError, setDescriptionError] = React.useState(null);
  const [mqfError, setMqfError] = React.useState(null);
  const [referenceError, setReferenceError] = React.useState(null);


  const mqfLevels = ["4", "5", "6", "7", "8"]

  const handleSaveOwnerDetails = () => {
    if (user && user != null) {
      if (
        title == null ||
        description == null ||
        mqfLevel == null ||
        referenceNumber == null
      ) {
        toast.error("You have missing information for this course");
        if (title === null) {
          setTitleError("Title is required.");
        }

        if (description == null) {
          setDescriptionError("Description is required.");
        }

        if (mqfLevel == null) {
          setMqfError("MQF Level is required.");
        }

        if (referenceNumber == null) {
          setReferenceError("Reference Number is required.");
        }
      } else {
        let data = {
          title: title,
          description: description,
          mqfLevel: mqfLevel,
          referenceNumber: referenceNumber,
        };

        setSaveLoading(true);
        toast.dark(`Creating ${data.title}`);
        createNewCourse(data, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Successfully created ${data.title}`);
            setTitle(null);
            setDescription(null);
            setMqfLevel(null);
            setReferenceNumber(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setTitleError(null);
    setDescriptionError(null);
    setMqfError(null);
    setReferenceError(null);
  }, [title, description, mqfLevel, referenceNumber, isOpen]);

  return (
    <Popup
      title="Add a new course"
      subtitle="Please fill in the details below to create a new course."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Course Title"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Course Description"}
              disabled={saveLoading}
              required
              helperText={descriptionError}
              error={descriptionError != null}
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>

          <FormControl className={classes.formControl}>
            <InputLabel required disabled={saveLoading}>
              MQF Level
            </InputLabel>
            <Select
              elevation={0}
              input={<Input id="type-menu" />}
              onChange={(event) => setMqfLevel(event.target.value)}
              value={mqfLevel}
              required
              disabled={saveLoading}
            >
              {mqfLevels.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
            {/* <TextField
              placeholder={"Ex: MQF Level"}
              disabled={saveLoading}
              required
              helperText={mqfError}
              error={mqfError != null}
              label="MQF Level"
              value={mqfLevel}
              onChange={(event) => setMqfLevel(event.target.value)}
              margin="normal"
            ></TextField> */}
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: M1263"}
              disabled={saveLoading}
              required
              helperText={referenceError}
              error={referenceError != null}
              label="Reference Number"
              value={referenceNumber}
              onChange={(event) => setReferenceNumber(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
