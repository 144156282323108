import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AllLecturesContext } from "repository/stores/dashboard/lectures/lectures-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";
import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";

//Children
import Day from "./day";
import Controller from "./controller";
import moment from "moment";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { getAllLecturesByQuery, editLecture, createNewLecture } = useContext(AllLecturesContext);
  const { getAllLocations } = React.useContext(AllLocationsContext);
  const { getCohorts, getCohort } = React.useContext(AllCohortsContext);
  const { findAllPersonnel } = React.useContext(AllPersonnelContext);

  const [locationMap, setLocationMap] = React.useState(null);
  const [locations, setLocations] = React.useState(null);

  const [cohortMap, setCohortMap] = React.useState({});
  const [cohortOptions, setCohortOptions] = React.useState(null);

  const [personnelMap, setPersonnelMap] = React.useState([]);

  const [lectures, setLectures] = React.useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
  });

  const [weekText, setWeekText] = React.useState(null);
  const [selectedWeek, setSelectedWeek] = React.useState(new Date());

  const [isLoading, setIsLoading] = React.useState(true);

  const getData = async () => {
    await findAllPersonnel(user.idToken).then((result) => {
      let newPersonnelMap = {};
      for (let p of result) {
        newPersonnelMap[p._id] = p;
      }
      setPersonnelMap(newPersonnelMap);
    });

    await getAllLocations(user.idToken).then((result) => {
      setLocations(result.data)
      let newLocationMap = {};
      for (let l of result.data) {
        newLocationMap[l._id] = l;
      }
      setLocationMap(newLocationMap);
    });

    await getCohorts(user.idToken).then((result) => {
      let newCohortMap = {};
      let newCohortOptions = []
      for (let c of result.data) {
        newCohortMap[c._id] = c;

        let option = {
          value: c._id,
          label: c.title,
        };
        newCohortOptions.push(option);
      }
      setCohortMap(newCohortMap);
      setCohortOptions(newCohortOptions)
    });

    let today = moment();
    await getAllLecturesByQuery({
      lectureDate: {
        $gt: today.startOf("week").valueOf(),
        $lt: today.endOf("week").valueOf(),
      },
    }).then((result) => {
      handleLecturesByDay(result);
    });

    
    today.startOf("week")
    let newWeek = []
    weekDays.map((item,index)=>{
      newWeek.push(moment(today).add(index+1, "days").format("DD MMM YYYY"))
    })
    setWeekDates(newWeek)
  };

  useEffect(() => {
    let today = moment();
    let from = today.startOf("week").format("DD MMM");
    let to = today.endOf("week").format("DD MMM");

    setWeekText(`${from} - ${to}`);
    setSelectedWeek(today.startOf("week"));
    getData().then(() => setIsLoading(false));
  }, []);

  const handleWeekChange = (isForward) => {
    let newDate = selectedWeek;
    if (isForward) {
      newDate = selectedWeek.add(7, "days");
    } else {
      newDate = selectedWeek.subtract(7, "days");
    }

    let from = newDate.startOf("week").format("DD MMM");
    let to = newDate.endOf("week").format("DD MMM");

    setWeekText(`${from} - ${to}`);
    setSelectedWeek(moment(newDate).startOf("week"));

    getAllLecturesByQuery({
      lectureDate: {
        $gt: newDate.startOf("week").valueOf(),
        $lt: newDate.endOf("week").valueOf(),
      },
    }).then((result) => {
      handleLecturesByDay(result);
    });


    newDate.startOf("week")
    let newWeek = []
    weekDays.map((item,index)=>{
      newWeek.push(moment(selectedWeek).add(index+1, "days").format("DD MMM YYYY"))
    })
    setWeekDates(newWeek)
  };

  const handleWeekSelect = (date) => {
    let selectedDate = moment(date);
    let from = selectedDate.startOf("week").format("DD MMM");
    let to = selectedDate.endOf("week").format("DD MMM");

    setWeekText(`${from} - ${to}`);
    setSelectedWeek(moment(selectedDate).startOf("week"));

    getAllLecturesByQuery({
      lectureDate: {
        $gt: selectedDate.startOf("week").valueOf(),
        $lt: selectedDate.endOf("week").valueOf(),
      },
    }).then((result) => {
      handleLecturesByDay(result);
    });


    selectedDate.startOf("week")
    let newWeek = []
    weekDays.map((item,index)=>{
      newWeek.push(moment(selectedDate).add(index+1, "days").format("DD MMM YYYY"))
    })
    setWeekDates(newWeek)
  };

  const handleLecturesByDay = (lectures) => {
    let lectureData = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
    };

    lectures.map((item) => {
      switch (new Date(item.lectureDate).getDay()) {
        case 1:
          lectureData.monday.push(item);
          break;
        case 2:
          lectureData.tuesday.push(item);
          break;
        case 3:
          lectureData.wednesday.push(item);
          break;
        case 4:
          lectureData.thursday.push(item);
          break;
        case 5:
          lectureData.friday.push(item);
          break;
      }
    });


    setLectures(lectureData);
  };


  const [weekDates, setWeekDates] = React.useState([])
  const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];

  return (
    <div>
      <Controller
        user={user}
        loading={isLoading}
        weekText={weekText}
        cohortMap={cohortMap}
        getCohort={getCohort}
        cohorts={cohortOptions}
        locations={locations}
        selectedWeek={selectedWeek}
        handleWeekChange={handleWeekChange}
        handleWeekSelect={handleWeekSelect}
        createNewLecture={createNewLecture}
      />
      <div style={{ marginTop: 30 }}></div>

      {weekdays.map((item, index) => {
        return (
          <div style={{ marginBottom: 30 }}>
            <Day
              weekDay={weekDays[index]}
              panelDescription={weekDates[index]}
              user={user}
              loading={isLoading}
              personnelMap={personnelMap}
              locationMap={locationMap}
              cohortMap={cohortMap}
              lectures={lectures[item]}
              editLecture={editLecture}
              getCohort={getCohort}
              locations={locations||[]}
              handleWeekSelect={handleWeekSelect}
            />
          </div>
        );
      })}
    </div>
  );
});
