import React from "react";
import { withRouter } from "react-router-dom";
import Section from "components/section";

export default withRouter(function (props) {
  return (
    <Section
      title={"Associated Students"}
      loading={false}
      sectionDescription={"All students associated to this module."}
    ></Section>
  );
});
