import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { Typography } from "@material-ui/core";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();

  const { setClose, isOpen, broadcast, user } = props;

  const [title, setTitle] = React.useState("Loading...");
  const [body, setBody] = React.useState("");

  useEffect(() => {
    if (broadcast != null) {
      setTitle(broadcast.subject);
      setBody(broadcast.body);
    }
  }, [broadcast, isOpen]);

  return (
    <Popup
      title={title || "loading..."}
      subtitle="This is a copy of the broadcast sent to your students."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => setClose()}
      actionButtonTitle="Ok"
      disabled={false}
      content={
        <div className={classes.root}>
          <Typography className={classes.inputField}>{body}</Typography>
        </div>
      }
    />
  );
});
