import apiClient from "utils/api";

export const backupData = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/backup").create({},{
          query: {
            firebaseToken: token,
          },
        });

        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};
