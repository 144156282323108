import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllCostings from './routes/all-costings';
import SingleCosting from './routes/single-costing';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/programme-costings/all' component={() => <AllCostings />} />
                <Route exact path='/programme-costings/:id' component={() => <SingleCosting />} />
                <Route exact path='/programme-costings' component={() => <Redirect to={`/programme-costings/all`} />} />
            </Switch>
            
        </div>
    )
}