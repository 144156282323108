import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper, Grid, Divider } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import Moment from "react-moment";
import { IdTokenContext } from "repository/stores/global/token-provider";
// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { client, user, loading, setShowEditClientDialogue } = props;
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [businessLines, setBusinessLines] = useState("");

  const { idToken } = useContext(IdTokenContext);

  useEffect(() => {
    if (client) {
      setTitle(client.title);
      setBusinessLines(client.businessLines.join(", "));
    }
  }, [client]);

  return (
    <Section
      title={title}
      loading={!client}
      actionName="Edit Client"
      handleAction={() => {
        setShowEditClientDialogue(true);
      }}
      sectionDescription={"Detailed information about the cohort."}
    >
      {client != null && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Title</Typography>
                <Typography className={classes.fontRegular}>
                  {client.title}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Industry</Typography>
                <Typography className={classes.fontRegular}>
                  {client.industry}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Website</Typography>
                <Typography className={classes.fontRegular}>
                  {client.website}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Address</Typography>
                <Typography className={classes.fontRegular}>
                  {client.address}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Vacancies</Typography>
                <Typography className={classes.fontRegular}>
                  {client.vacancies ? client.vacancies.length : 0}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Proposals</Typography>
                <Typography className={classes.fontRegular}>
                  {client.proposals ? client.proposals.length : 0}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Projects</Typography>
                <Typography className={classes.fontRegular}>
                  {client.projects ? client.projects.length : 0}
                </Typography>
              </Paper>

              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Business Lines
                </Typography>
                <Typography className={classes.fontRegular}>
                  {businessLines}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <div style={{overflowX : 'auto', marginTop: 30 }}>
            {client.contacts.map((item, index) => {
              return (
                <Paper
                  className={classes.miniSection}
                  style={{
                    width: 400,
                    padding: 25,
                    float: "left",
                    marginRight: 30,
                    borderRadius: 20,
                    border: "1px solid #f7f7f7",
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Typography variant="caption">Full Name</Typography>
                      <Divider />
                      <Typography variant="caption">{item.fullName}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="caption">Role</Typography>
                      <Divider />
                      <Typography variant="caption">{item.role}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="caption">Contact Number</Typography>{" "}
                      <Divider />
                      <Typography variant="caption">
                        {item.contactNumber}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="caption">Email</Typography>
                      <Divider />
                      <Typography variant="caption">{item.email}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </div>
        </div>
      )}
    </Section>
  );
});
