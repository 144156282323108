import React, { useState } from "react";
import { DialogContentText } from "@material-ui/core";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-reset-password-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();
  const { isOpen, setClose, userId, user, resetPassword } = props;

  const [saveLoading, setSaveLoading] = useState(false);

  const handleResetPassword = () => {
    setSaveLoading(true);

    resetPassword(user.email, user.idToken).then((result) => {
      setClose();
      setSaveLoading(false);
    });
  };

  return (
    <Popup
      title="Reset password"
      isOpen={isOpen}
      handleCancel={() => setClose()}
      handleAction={() => handleResetPassword()}
      actionButtonTitle="Yes, I'm sure"
      disabled={saveLoading}
      content={
        <div>
          <DialogContentText>
            Are you sure you want to reset this user's password?
          </DialogContentText>
        </div>
      }
    />
  );
});
