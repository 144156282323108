

import React, {  useState } from "react";
import apiClient from "utils/api";

const ContextProps = {
    broadcasts: null,
    setBroadcasts: {},
    getBroadcasts: {},
    createNewBroadcast: {},
};

export const AllBroadcastsContext = React.createContext(ContextProps);

export const AllBroadcastsProvider = ({ children }) => {
    const [broadcasts, setBroadcasts] = useState(null);

    const getBroadcasts = (cohortId, token) => {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await apiClient.service("v1/broadcasts").find({
                    query: {
                        cohortId: cohortId,
                        firebaseToken: token,
                    },
                });

                setBroadcasts(result.data);
                resolve(result);
            } catch (e) {
                reject(e);
            }
        });
    };

    const createNewBroadcast = async (
        broadcast,
        token
      ) => {
        return new Promise(async (resolve, reject) => {
          try {
            let result = await apiClient.service("v1/broadcasts").create(broadcast, {
              query: {
                firebaseToken: token,
              },
            });

            if (broadcasts == null) {
                setBroadcasts(result);
            } else {
                let newBroadcasts = broadcasts
                newBroadcasts.push(result)
                setBroadcasts(newBroadcasts);
            }
            resolve(result);
          } catch (e) {
            reject(e);
          }
        });
      };
      




    return (
        <AllBroadcastsContext.Provider
            value={{
                broadcasts,
                setBroadcasts,
                getBroadcasts,
                createNewBroadcast,

            }}
        >
            {children}
        </AllBroadcastsContext.Provider>
    );
};
