import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'administration',
      startPath: 'personnel',
      routes:[
        { name: 'Personnel', path: 'personnel', icon: 'person'},
        { name: 'Locations', path: 'locations', icon: 'near_me'}
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});