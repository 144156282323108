import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ListItem, List, Paper } from "@material-ui/core";
import styles from "./single-person-interests-lecturing-styles";
import Panel from "components/panel";
import DialogueEditInterestedLecturing from "routes/dashboard/dialogues/personnel/edit-interested-lecturing";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { person, setPerson, user } = props;
  const [interestingLecturing, setInterestedLecturing] = useState([]);
  const [showEditInterestsDialogue, setShowEditInterestsDialogue] =
    useState(false);

  useEffect(() => {
    setInterestedLecturing(person.interestedInLecturing);
  }, [person]);

  return (
    <div>
      <Panel
        title="Interested in Lecturing"
        loading={false}
        sectionDescription="Topics this person is interested in lecturing."
        actionName="Edit"
        handleAction={() => setShowEditInterestsDialogue(true)}
      >
        {interestingLecturing != null && interestingLecturing.length > 0 && (
          <List>
            {interestingLecturing.map((role) => (
              <ListItem className={classes.labelText}>• {role}</ListItem>
            ))}
          </List>
        )}

        {interestingLecturing == null && (
          <Typography className={classes.loading}>Loading...</Typography>
        )}
        {interestingLecturing != null && (
          <div>
            {interestingLecturing.length == 0 && (
              <Typography className={classes.loading}>
                This person has no other lecturing interests listed to them
              </Typography>
            )}
          </div>
        )}

        <DialogueEditInterestedLecturing
          isOpen={showEditInterestsDialogue}
          setClose={() => setShowEditInterestsDialogue(false)}
          personId={person._id}
          person={person}
          setInterestedLecturing={setInterestedLecturing}
          setPerson={setPerson}
          user={user}
        />
      </Panel>
    </div>
  );
}
