import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectTasksView from "./single-project-tasks-view";


export default withRouter(function (props) {
  let {
    loading,
    project,
    setProject,
    projectId,
    isNewProject,
    user,
  } = props;



  return (
    <div>
      <SingleProjectTasksView
        loading={loading}
        panelDescription={"Manage the Project Tasks"}
        user={user}
        project={project}
        setProject={setProject}
        isNewProject={isNewProject}
      />

    </div>
  );
});
