import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Popper,
  TextField,
  Grid,
  Link,
} from "@material-ui/core";
import { Visibility, Delete, Edit } from "@material-ui/icons";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
} from "react-instantsearch-dom";

// Navigation
import NavCrumbs from "./content-all-courses-nav-crumbs";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddCourseForm,
    onPressedItem,
    courses,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    user,
    refreshAlgolia
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a course"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellReference}
                align="left"
              >
                {row.title}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellMqf}
                align="left"
              >
                {row.mqfLevel}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellReference}
                align="left"
              >
                {row.referenceNumber}
              </TableCell>
              <TableCell className={classes.cellButtons} align="right">
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedEditItem(index, row.objectID);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR COURSES"
        loading={loading}
        actionName="Add course"
        handleAction={toggleAddCourseForm}
        sectionDescription={sectionDescription}
      >
        {courses.length > 0 && (
          <InstantSearch indexName="Courses" searchClient={searchClient} refresh={refreshAlgolia}>
            <Paper elevation={3} className={classes.header}>
              <CustomSearchBox />
            </Paper>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        MQF Level
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Internal Reference Number
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {courses.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any courses yet.
          </Typography>
        )}
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      onClick={() => {
                        onPressedItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      View
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedEditItem(selectedIndex, selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedDeleteItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Section>
    </NavCrumbs>
  );
}
