import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-followups-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import DeleteIcon from "@material-ui/icons/Delete";
import Moment from "react-moment";

//Actions
import { editLead } from "repository/actions/data/dashboard/leads";

const useStyles = makeStyles(styles);

function EditFollowUpsDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, leads, setLeads, lead, leadId, user, editLead } = props;
  const [followUps, setFollowUps] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newFollowUp, setNewFollowUp] = React.useState(null);
  const [newFollowUpError, setNewFollowUpError] = React.useState(null);

  useEffect(() => {
    if (lead && lead != null) {
      if (lead.followUps) {
        setFollowUps(lead.followUps);
      }
    }
  }, [lead]);

  const handleAddNewFollowUp = () => {
    if (newFollowUp == null) {
      setNewFollowUpError("Cannot have an empty follow up");
    } else {
      let newFollowUpObject = {
        content: newFollowUp,
        createdAt: new Date(),
        createdBy: user._id,
        createdByFullName : `${user.firstName} ${user.lastName}`
      };
      let newFollowUps = [newFollowUpObject].concat(followUps);
      setFollowUps(newFollowUps);
      setNewFollowUpError(null);
      handleSaveFollowUps();
    }
  };

  const handleSaveFollowUps = () => {
    let patchData = { ...lead, followUps: followUps };

    setSaveLoading(true);
    editLead( patchData, leadId, user.idToken).then((result) => {
      toast.success(`Follow Ups for ${lead.firstName} ${lead.lastName} updated`);
      // handleClose();
      setSaveLoading(false);
    }).catch(error => {
      toast.error('Oops! Something went wrong. Check that you have the correct information.');
      toast.dark(error.message);
      setSaveLoading(false);
    });
  };


  const handleSaveAndClose = () => {
    handleSaveFollowUps();
    handleClose();
  }

  const onPressedDeleteFollowUp = index => {
    if(followUps.length > index){
      let newFollowUps = [];

      for(let [i,e] of followUps.entries()){
        if( i !== index){
          newFollowUps.push(e);
        }
      }
      setFollowUps(newFollowUps);
    }
  };

  const handleClose = () => {
    setClose();
  }

  return (
    <Popup
      title="Lead Follow Ups"
      subtitle="Edit the follow ups for this lead below"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveAndClose()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Follow up call made"}
                    disabled={saveLoading}
                    required
                    helperText={newFollowUpError}
                    error={newFollowUpError != null}
                    label="New Follow Up"
                    value={newFollowUp}
                    onChange={(event) => setNewFollowUp(event.target.value)}
                    margin="normal"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    onClick={() => handleAddNewFollowUp()}
                  >
                    Add Follow Up
                  </Button>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <List className={classes.root}>
                  {followUps.map((entry, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <SpeakerNotesIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={entry.content}
                          secondary={
                            <span>
                              {entry.createdByFullName} | 
                              <Moment
                                format="D MMMM, YYYY"
                                date={entry.createdAt}
                              />
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={(event) => {
                                  onPressedDeleteFollowUp(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default withSnackbar(EditFollowUpsDialogue);
