import React, { useContext, useState, useEffect } from "react";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import { AuthenticationContext } from 'repository/stores/global/authentication-provider';
import { withRouter } from "react-router-dom";

// Children
import LocationsView from "./administration-all-locations-view";

// Dialogues
import DialogueCreateLocation from "routes/dashboard/dialogues/locations/create-location";
import DialogueEditLocation from "routes/dashboard/dialogues/locations/edit-location";
import DialogueDeleteLocation from "routes/dashboard/dialogues/locations/delete-location";

// Actions
import {
  // findAllLocations,
  getSingleLocation,
} from "repository/actions/data/dashboard/locations";

export default withRouter(function (props) {
  const {user} = useContext( AuthenticationContext);

  const {locations, setLocations, getAllLocations, deleteLocation, editLocation, createNewLocation} = useContext(AllLocationsContext);

  const [showNewLocationDialogue, setShowNewLocationDialogue] = useState(false);
  const [showEditLocationDialogue, setShowEditLocationDialogue] =
    useState(false);
  const [showDeleteLocationDialogue, setShowDeleteLocationDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  // Data Fetching
  useEffect(() => {
    if (locations == null) {
      getAllLocations(user.idToken);
    }
  }, [locations]);

  // Editing Item
  useEffect(() => {
    if (locations && selectedId && editingItem == null) {
      getSingleLocation(selectedId, user.idToken).then((result) => {
        setEditingItem(result);
      });
    }
  }, [selectedId]);

  return (
    <div>
      <LocationsView
        loading={!locations}
        onPressedItem={(id) => props.history.push(`locations/${id}`)}
        toggleAddLocationForm={() => setShowNewLocationDialogue(true)}
        sectionDescription={"You can manage all of your locations below."}
        locations={locations || []}
        refreshAlgolia={refreshAlgolia}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditLocationDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteLocationDialogue(true);
        }}
      />
      <DialogueCreateLocation
        user={user}
        isOpen={showNewLocationDialogue}
        setClose={() => setShowNewLocationDialogue(false)}
        createNewLocation={createNewLocation}
        setRefreshAlgolia={setRefreshAlgolia}

      />
      <DialogueEditLocation
        isOpen={showEditLocationDialogue && editingItem && editingItem != null}
        setRefreshAlgolia={setRefreshAlgolia}
        setClose={() => {
          setShowEditLocationDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
          
        }}
        data={editingItem}
        itemId={selectedId}
        editLocation={editLocation}
        user={user}
      />
      <DialogueDeleteLocation
        isOpen={showDeleteLocationDialogue}
        setClose={() => setShowDeleteLocationDialogue(false)}
        itemId={selectedId}
        deleteLocation={deleteLocation}
        setRefreshAlgolia={setRefreshAlgolia}
        user={user}

      />
    </div>
  );
});
