import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { student } = props;

  let crumbs = [
        {name: 'General', path: 'general'},
        {name: 'Extensions', path: 'general/extensions'},
        {name: '...', path: '#'},
  ];

  if (student != null) {
    crumbs[2] =  { name: `${student.firstName} ${student.lastName}`, path: `general/extensions/${student._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
