import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SubjectIcon from "@material-ui/icons/Subject";
import styles from "./dialogues-create-mentor-contract-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getSearchClient } from "utils/algolia";
import { convertDateToUnixTimestamp } from "utils/helpers/date";
import AsyncSelect from "react-select/async";
import DateFnsUtils from "@date-io/date-fns";
import Popup from "components/popup";
import Moment from "react-moment";
//Actions
import { createPersonnelMentorContract } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function AddMentorContract(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    person,
    contracts,
    setContracts,
    role,
    roleAssociation,
    personnelId,
    student,
    course,
    user,
  } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [moduleAmount, setModuleAmount] = React.useState(null);
  const [hoursPerModule, setHoursPerModule] = React.useState(null);
  const [hourlyRate, setHourlyRate] = React.useState(null);
  const [totalRemuneration, setTotalRemuneration] = React.useState(0);

  const [totalRemunerationError, setTotalRemunerationError] =
    React.useState(null);
  const [hoursPerModuleError, setHoursPerModuleError] = React.useState(null);
  const [moduleAmountError, setModuleAmountError] = React.useState(null);
  const [hourlyRateError, setHourlyRateError] = React.useState(null);
  const [limitError, setLimitError] = React.useState(null);
  const [activeContractCount, setActiveContractCount] = React.useState(0);

  const handleSaveMentorContract = () => {
    if (user && user != null) {
      if (
        moduleAmount == null ||
        hoursPerModule == null ||
        hourlyRate == null ||
        totalRemuneration == null
      ) {
        toast.error(
          "You have missing information for creating a mentor contract"
        );
        if (moduleAmount == null) {
          setModuleAmountError("Number of modules is required");
        }

        if (hoursPerModule == null) {
          setHoursPerModuleError("Amount of hours per module is required");
        }

        if (hourlyRate == null) {
          setHourlyRateError("Hourly Rate is required.");
        }
      } else {
        let data = {
          personnelId: personnelId,
          roleId: role._id,
          courseId: course._id,
          studentId: student._id,
          totalRemuneration: totalRemuneration,
          roleAssociationId: roleAssociation._id,
          startDate: roleAssociation.startsAt,
          expiryDate: roleAssociation.expiresAt,
          hourlyRate: hourlyRate,
          hoursPerModule: hoursPerModule,
          moduleAmount: moduleAmount,
        };

        setSaveLoading(true);
        createPersonnelMentorContract(data, user.idToken)
          .then((result) => {
            let currentContracts = [...contracts];
            currentContracts.push(result);
            setContracts(currentContracts);
            setClose();
            toast.success("Mentor contract successfully created");
            setSaveLoading(false);
            setTotalRemunerationError(null);
            setHoursPerModuleError(null);
            setModuleAmountError(null);
            setHourlyRateError(null);
            setTotalRemuneration(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (moduleAmount != null && hourlyRate != null && hoursPerModule != null) {
      setTotalRemuneration(
        parseFloat(moduleAmount * hourlyRate * hoursPerModule)
      );
    }
  }, [moduleAmount, hourlyRate, hoursPerModule]);

  useEffect(() => {
    setTotalRemunerationError(null);
    setHoursPerModuleError(null);
    setModuleAmountError(null);
    setHourlyRateError(null);

    let acc = 0;
    let now = new Date();
    let nowTimestamp = convertDateToUnixTimestamp(now);

    for (let c of contracts) {
      if (
        c.startDateTimestamp <= nowTimestamp &&
        c.expiryDateTimestamp >= nowTimestamp
      ) {
        acc++;
      }
    }

    if (acc >= 6) {
      setLimitError("Mentors can only have 6 active students");
    }
    setActiveContractCount(acc);
  }, [isOpen]);

  return (
    <Popup
      title="Add a new mentor contract"
      subtitle="Please fill in the details to create a new mentor contract"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveMentorContract()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className={classes.fullColumn}>
              <Typography variant="body2">
                {activeContractCount} / 6 mentor contracts are active with this
                person.
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={saveLoading}
                  required
                  helperText={moduleAmountError}
                  error={moduleAmountError != null}
                  label="Module Amount"
                  value={moduleAmount}
                  margin="normal"
                  type="number"
                  placeholder={"Ex: 5"}
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(event) =>
                    setModuleAmount(parseFloat(event.target.value))
                  }
                ></TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={saveLoading}
                  required
                  helperText={hoursPerModuleError}
                  error={hoursPerModuleError != null}
                  label="Hours Per Module"
                  value={hoursPerModule}
                  margin="normal"
                  type="number"
                  placeholder={"Ex: 5"}
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(event) =>
                    setHoursPerModule(parseFloat(event.target.value))
                  }
                ></TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={saveLoading}
                  required
                  helperText={hourlyRateError}
                  error={hourlyRateError != null}
                  label="Hourly Rate (€)"
                  value={hourlyRate}
                  margin="normal"
                  type="number"
                  placeholder={"Ex: 20"}
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(event) =>
                    setHourlyRate(parseFloat(event.target.value))
                  }
                ></TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={true}
                  required
                  helperText={totalRemunerationError}
                  error={totalRemunerationError != null}
                  label="Total Remuneration (€)"
                  value={totalRemuneration}
                  margin="normal"
                  type="number"
                  placeholder={"Ex: 50"}
                  InputProps={{ inputProps: { min: 0, max: 500 } }}
                  onChange={(event) =>
                    setTotalRemuneration(parseFloat(event.target.value))
                  }
                ></TextField>
              </FormControl>
            </Paper>
          </MuiPickersUtilsProvider>
        </div>
      }
    ></Popup>
  );
}

export default withSnackbar(AddMentorContract);
