import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper, List, ListItem, ListItemAvatar, Avatar, Grid, Button, TextField, FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import anime from 'animejs/lib/anime.es.js';
import { toast } from "react-toastify";

// import { createTimestamp } from "../../../../../../../../repository/actions/data/dashboard/timesheets";
import {AllTimesheetsContext } from "repository/stores/dashboard/timesheets/timesheets-all-provider"

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    users
  } = props;
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = useState(null);

  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [reason, setReason] = useState(null);

  const [sortedUsers, setSortedUsers] = useState(null)


  const{createTimestamp} = useContext(AllTimesheetsContext)

  useEffect(() => {
    if (users) {
      let sorted = users.sort((a, b) => (a.roles[0] > b.roles[0]) ? 1 : ((b.roles[0] > a.roles[0]) ? -1 : 0))
      setSortedUsers(sorted)
    }
  }, [users])





  const Users = () => {
    return (
      <List className={classes.avatarList}>
        {sortedUsers && sortedUsers.map((item, index) => {
          return <ListItem className={classes.singleAvatar} id={index}>
            <ListItemAvatar >
              <Avatar alt={item.firstName} src={item.displayPhoto.url} className={classes.avatar} onClick={(evt) => { setSelectedUser(item) }} />
            </ListItemAvatar>
            <Typography
              variant="body2"
              color="text.primary"
            >
              {item.firstName + " " + item.lastName}
            </Typography>
          </ListItem>
        })}
      </List>
    )
  }



  const handleTimestamps = async (isPunchIn) => {

    let selectedTime = new Date(time)
    let prefixHour = ""
    let prefixMinutes= ""

    if (selectedTime.getHours().toString().length < 2) {
      prefixHour = "0"
    }
    if (selectedTime.getMinutes().toString().length < 2) {
      prefixMinutes = "0"
    }

    let payload = {
      time: prefixHour + selectedTime.getHours() + ":" + prefixMinutes + selectedTime.getMinutes(),
      date: date,
      reason: reason,
      userId: selectedUser._id,
      isPunchIn: isPunchIn,
      isCustom: true
    }

    await createTimestamp(payload, user.idToken).then(() => {
      anime({
        targets: "#selectedAvatar",
        width: {
          value: '0px',
        },
        height: {
          value: '0px',
        },
        duration: 800
      });
      setReason(null)
      setSelectedUser(null)
      setDate(null)
      setTime(null)




      setTimeout(() => {
        anime({
          targets: "#selectedAvatar",
          width: {
            value: '220px',
          },
          height: {
            value: '220px',
          },
          duration: 800
        });
      }, 2000)
    }).catch((e) => {
      toast.error(e.message)
    })
  }

  return (
    <div>
      <Section
        title={"Custom Timesheet"}
        loading={!users}
        sectionDescription={"Input missing punch timings"}
      >
        <Paper className={classes.miniSection}>
          <Users />
        </Paper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={8} className={classes.grid}>
            <Grid item xs={4} >
              <Avatar id="selectedAvatar" alt="user" src={selectedUser ? selectedUser.displayPhoto.url : null} className={classes.selectedAvatarCutom} />
              {selectedUser && <Typography className={classes.avatarName}>
                {selectedUser.firstName}{" "}{selectedUser.lastName}
              </Typography>}

            </Grid>
            <Grid item xs={8} >

              <Grid container spacing={8} className={classes.gridDetails} justifyContent="space-evenly"
                alignItems="center">
                <Grid item xs={5} >
                  <FormControl className={classes.formControl}>
                    <DatePicker
                      label="Punch Date*"
                      inputFormat="MM/dd/yyyy"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue)
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} >
                  <FormControl className={classes.formControl}>
                    <TimePicker
                      required
                      ampmInClock={true}
                      format="HH:mm"
                      margin="normal"
                      id="date-picker-inline"
                      label="Punch Time"
                      value={time}
                      onChange={(newValue) => {
                        setTime(newValue);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  <span style={{color:"red"}}>** Makes sure to select the correct AM / PM **</span>

                  </FormControl>
                </Grid>

                <Grid container style={{ justifyContent: "center", margin: "30px 0" }}>
                  <Grid item xs={10} >
                    <FormControl className={classes.formControl}>
                      <TextField
                        required
                        label="Reason"
                        value={reason}
                        onChange={(newValue) => {
                          setReason(newValue.target.value);
                        }}

                      ></TextField>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} >
                  <Grid container spacing={4}>
                    <Grid item xs={6} >
                      <Button className={classes.timeBtnCustom} onClick={() => { handleTimestamps(true) }} disabled={selectedUser == null || time == null || date == null}>
                        Punch In
                      </Button>
                    </Grid>
                    <Grid item xs={6} >
                      <Button className={classes.timeBtnCustom} onClick={() => { handleTimestamps(false) }} disabled={selectedUser == null || time == null || date == null}>
                        Punch Out
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </MuiPickersUtilsProvider >

      </Section>
    </div>
  );
});
