import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Extension from './routes/extensions';
import Tickets from './routes/tickets';
import Referrals from './routes/referrals';



export default function (props) {
  const {campus} = props

    return(
        <div>
            <Switch>
                <Route path='/general/extensions' component={() => <Extension />} />
                <Route path='/general/tickets' component={() => <Tickets />} />
                <Route path='/general/referrals' component={() => <Referrals />} />
                <Route exact path='/general' component={() => <Redirect to={`/general/extensions`} />} />
            </Switch>
        </div>
    )
}