import apiClient from "utils/api";
import { assignNewCohort } from "./students";
import { getIdToken } from "../authentication"

// export const findAllEnquiries = async (dispatch, token) => {
//   return new Promise(async (resolve, reject) => {
//     try {
       
//       getIdToken().then(async (token) => {
//         let result = await apiClient.service("v1/enquiries").find({
//           query: {
//             firebaseToken: token,
//           },
//         });

//         dispatch({
//           type: "UpdateEnquiries",
//           payload: result.data,
//         });

//         resolve(result.data);
//       })
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

// export const createNewEnquiry = async (dispatch, enquiry, token) => {
//   return new Promise(async (resolve, reject) => {
//     try {
       
//       getIdToken().then(async (token) => {
//         let result = await apiClient.service("v1/enquiries").create(enquiry, {
//           query: {
//             firebaseToken: token,
//           },
//         });

//         dispatch({
//           type: "AddEnquiry",
//           payload: result,
//         });

//         resolve(result);
//       })
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

// export const editEnquiry = async (dispatch, data, enquiryId, token) => {
//   return new Promise(async (resolve, reject) => {
//     try {
       
//       getIdToken().then(async (token) => {
//         let result = await apiClient
//           .service("v1/enquiries")
//           .patch(enquiryId, data, {
//             query: {
//               firebaseToken: token,
//             },
//           });

//         dispatch({
//           type: "EditEnquiry",
//           payload: {
//             enquiryId: enquiryId,
//             data: result,
//           },
//         });

//         resolve();
//       })
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

// export const deleteEnquiry = async (dispatch, enquiryId, token) => {
//   return new Promise(async (resolve, reject) => {
//     try {
       
//       getIdToken().then(async (token) => {
//         let result = await apiClient.service("v1/enquiries").remove(enquiryId, {
//           query: {
//             firebaseToken: token,
//           },
//         });

//         dispatch({
//           type: "RemoveEnquiry",
//           payload: result,
//         });

//         resolve(result);
//       })
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

export const getSingleEnquiry = async (enquiryId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/enquiries").get(enquiryId, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createEnquiryDocument = async (
  enquiryId,
  data,
  documents,
  token,
  setDocuments
) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").create(
          {
            attachmentURI: data.attachment,
            title: data.title,
            type: "enquiry",
            subtype: "transcript",
            enquiryId: enquiryId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = [...documents];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createEnquiryDocumentPersonal = async (
  enquiryId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").create(
          {
            attachmentURI: data.attachment,
            title: data.title,
            type: "enquiry",
            subtype: "personal",
            enquiryId: enquiryId,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let currentDocuments = [...documents];
        currentDocuments.push(result);
        setDocuments(currentDocuments);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocuments = async (enquiryId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").find({
          query: {
            enquiryId: enquiryId,
            type: "enquiry",
            subtype: "transcript",
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocumentsPersonal = async (enquiryId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").find({
          query: {
            enquiryId: enquiryId,
            type: "enquiry",
            subtype: "personal",
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocumentsLetter = async (enquiryId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").find({
          query: {
            enquiryId: enquiryId,
            type: "student",
            subtype: "letter",
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const changeEnquiryStatus = async (
  enquiryId,
  status,
  cohortId,
  cohort,
  selectedModuleIds,
  selectedMembership,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let studentResult = await apiClient.service("v1/enquiries").patch(
          enquiryId,
          {
            status: status,
          },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        await assignNewCohort(
          studentResult,
          cohortId,
          cohort,
          selectedModuleIds,
          selectedMembership
        );
        resolve(studentResult);
    } catch (e) {
      reject(e);
    }
  });
};
