import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";


import NewProposalDetialsView from "./new-proposal-details-view"

export default withRouter(function (props) {
  let {
    user,
  } = props;


  return (
    <div>
      <NewProposalDetialsView
        loading={!true}
        user={user}
      />

    </div>
  );
});
