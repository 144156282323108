import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Grid, TextField, Typography } from "@material-ui/core";
import { getSearchClient } from "utils/algolia";
import Loading from "components/loading";
const searchClient = getSearchClient();

export default withRouter(function (props) {
  let { user } = props;

  const [course, setCourse] = useState("");
  const [courseId, setCourseId] = useState("");

  const [hits, setHits] = useState([]);
  const [cohorts, setCohorts] = useState([]);

  const [cohortStudents, setCohortStudents] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user && user != null) {
    }
  }, [user, course]);

  const handleCourse = (query) => {
    setCourse(query);
    searchClient
      .initIndex("Courses")
      .search(query, { hitsPerPage: 3 })
      .then(({ hits }) => {
        setHits(hits);
      });
  };

  const loadStudents = async (cohorts) => {
    const students = searchClient.initIndex("Students");
    const applicants = searchClient.initIndex("Applicants");

    let idx = 0;

    while (idx < cohorts.length) {
      await students
        .search("", {
          filters: `cohortIds:${cohorts[idx].id}`,
          attributesToRetrieve: ["firstname", "lastname", "status"],
        })
        .then(({ hits }) => {
          cohorts[idx].students = hits;
          cohorts[idx].studentsTotal = hits.length;
        });

      await applicants
        .search("", {
          filters: `cohortId:${cohorts[idx].id}`,
          attributesToRetrieve: ["firstname", "lastname", "status"],
        })
        .then(({ hits }) => {
          cohorts[idx].applicants = hits;
          cohorts[idx].applicantsTotal = hits.length;
        });

      if (idx == cohorts.length - 1) {
        setCohortStudents(cohorts);
        setIsLoading(false);
      }

      idx++;
    }
  };

  const handleSelectCourse = async (courseId) => {
    setIsLoading(true);
    let cohortStudents = [];

    await searchClient
      .initIndex("Cohorts")
      .search("", {
        filters: `courseId:${courseId}`,
        attributesToRetrieve: ["title", "objectID"],
      })
      .then(({ hits }) => {
        let cohorts = hits
        for (let cohort of cohorts) {
          cohortStudents.push({
            cohort: cohort.title,
            id: cohort.objectID,
            students: [],
            applicants: [],
          });
        }
      });

    loadStudents(cohortStudents);
  };

  const Totals = ({ title, value }) => {
    return (
      <div style={{ marginRight: 10, width: "fit-content" }}>
        <p
          style={{
            margin: 0,
            fontWeight: 700,
            color: "#FFBF00",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          {value}
        </p>
        <p style={{ marginTop: 5 }}> {title}</p>
      </div>
    );
  };

  return (
    <Panel title="Cohorts Population" loading={false}>
      <div style={{ padding: 20 }}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid items xs={4}>
            <p>Search Course</p>
            <TextField
              type="text"
              style={{ width: "90%" }}
              value={course}
              onChange={(event) => handleCourse(event.target.value)}
            />
            <ul>
              {hits.map((item) => {
                return (
                  <li
                    key={item.title}
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #cecece",
                      padding: 7,
                    }}
                    onClick={() => {
                      handleSelectCourse(item.objectID);
                      setCourseId(item.objectID);
                      setCourse(item.title);
                      setHits([]);
                    }}
                  >
                    • {item.title}
                  </li>
                );
              })}
            </ul>

            {cohortStudents.length > 0 && (
              <Totals title={"Total Cohorts"} value={cohortStudents.length} />
            )}
          </Grid>
          <Grid items xs={8}>
            {isLoading && <Loading />}
            {!isLoading && (
              <div>
                {cohortStudents.map((item) => {
                  return (
                    <div style={{ marginBottom: 20 }}>
                      <Panel title={item.cohort} key={item.cohort}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Totals
                            title={"Students"}
                            value={item.studentsTotal}
                          />
                          <Totals
                            title={"Applicants"}
                            value={item.applicantsTotal}
                          />
                          <Totals
                            title={"Total"}
                            value={
                              parseInt(item.studentsTotal) +
                              parseInt(item.applicantsTotal)
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          {item.applicants.map(() => {
                            return (
                              <div
                                style={{
                                  borderRadius: "100%",
                                  width: 20,
                                  hight: 15,
                                  margin: 10,
                                  backgroundColor: "#FFBB00",
                                  color: "#FFBB00",
                                }}
                              >
                                .
                              </div>
                            );
                          })}

                          {item.applicantsTotal > 0 && <div>||</div>}
                          {item.students.map((student) => {
                            let color = "#564181";
                            console.log(student.status);
                            if (student.status == "Completed")
                              color = "#00ff00";
                            if (student.status == "Cancelled")
                              color = "#ff0000";

                            return (
                              <div
                                style={{
                                  borderRadius: "100%",
                                  width: 20,
                                  hight: 15,
                                  margin: 10,
                                  backgroundColor: color,
                                  color: color,
                                }}
                              >
                                .
                              </div>
                            );
                          })}
                        </div>
                      </Panel>
                    </div>
                  );
                })}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Panel>
  );
});
