import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Paper,
  Button,
  Badge,
  Typography,
  Grid,
  Chip,
  OutlinedInput,
  Box,
  Divider,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import DeleteIcon from "@material-ui/icons/Delete";

import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

// import { getClient } from "repository/actions/data/dashboard/clients";

// Actions
import { getIndustries } from "repository/actions/data/dashboard/industries";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { client, clientId, setClose, setClient, isOpen, user, editClient } =
    props;

  const { getClient } = useContext(AllClientsContext);

  const [title, setTitle] = React.useState(null);
  const [industry, setIndustry] = React.useState(null);
  const [website, setWebsite] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [contacts, setContacts] = React.useState([]);
  const [businessLines, setBusinessLines] = React.useState([]);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [industries, setIndustries] = React.useState(null);
  const [error, setError] = React.useState(null);

  const businessLinesList = [
    "Sales",
    "Academy",
    "Advisory",
    "Resourcing",
    "Intelligence",
    "Other",
  ];

  useEffect(() => {
    if (user) {
      getIndustries(user.idToken).then((result) => {
        setIndustries(result);
      });
    }
  }, [user]);

  useEffect(() => {
    if (client) {
      setTitle(client.title);
      setIndustry(client.industry);
      setWebsite(client.website);
      setAddress(client.address);
      setBusinessLines(client.businessLines ? client.businessLines : []);
      setContacts(client.contacts);
    }
  }, [client]);

  const handleSaveOwnerDetails = () => {
    if (title == null || industry == null || contacts.length == 0 || businessLines.length == 0) {
      toast.error("You have missing information for creating a new client");
      if (title === null) {
        setError("Title is required.");
      }
      if (industry == null) {
        setError("Industry is required.");
      }
      if (contacts.length == 0) {
        setError("Contact is required.");
      }
      if (businessLines.length == 0) {
        setError("Business Lines is required.");
      }
    } else {
      let data = {
        title: title,
        industry: industry,
        website: website,
        address: address,
        contacts: contacts,
        businessLines: businessLines,
      };

      setSaveLoading(true);
      toast.dark(`Editing Client : ${data.title}`);
      editClient(clientId, data, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`Client edited : ${data.title}`);
          setTitle(null);
          setIndustry(null);
          setWebsite(null);
          setAddress(null);
          setContacts([]);
          setBusinessLines([]);

          setError(null);
          getClient(clientId, user.idToken).then((result) => {
            setClient(result);
          });
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const addContact = () => {
    let newContacts = [...contacts];

    newContacts.push({
      fullName: "",
      email: "",
      contactNumber: "",
      role: "",
    });
    setContacts(newContacts);
  };

  const handleEditContact = (event, index, key) => {
    let newContacts = [...contacts];
    newContacts[index][key] = event.target.value;
    setContacts(newContacts);
  };

  const removeContact = (index) => {
    let newContacts = [...contacts];
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  return (
    <Popup
      title="Edit Client"
      subtitle="Please fill in the details below to edit client."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"lg"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Client"}
                  disabled={saveLoading}
                  required
                  label="Title"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
              <div></div>
              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Industry
                </InputLabel>
                {industries && industries != null && (
                  <Select
                    elevation={0}
                    onChange={(event) => setIndustry(event.target.value)}
                    value={industry}
                    required
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                   {industries.map((item, index) => {
                      return (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
              <div></div>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Website"}
                  disabled={saveLoading}
                  required
                  label="Website"
                  value={website}
                  onChange={(event) => setWebsite(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>

              <div></div>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Address"}
                  disabled={saveLoading}
                  required
                  label="Address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
              
            </Grid>

            <Grid item xs={6}>
              <FormControl
                className={classes.formControl}
                style={{ margin: "20px 0" }}
              >
                <InputLabel required style={{ marginLeft: "20px" }}>
                  Business Lines
                </InputLabel>
                <Select
                  multiple
                  label="Business Lines"
                  variant="standard"
                  value={businessLines}
                  onChange={(event) => {
                    setBusinessLines(event.target.value);
                  }}
                  // disabled={user.roles.indexOf("admin") < 0}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {businessLines.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {businessLinesList.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {/* {user.roles.indexOf("admin") < 0 && <p>Ask Admin To Edit Business Lines</p>} */}
              </FormControl>
            <Divider style={{ margin: "30px 0" }} />

              <Typography className={classes.title}>Contacts</Typography>
              {contacts.map((item, index) => {
                return (
                  <Paper
                    className={classes.miniSection}
                    style={{ paddingTop: 15 }}
                  >
                    <Badge
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      badgeContent={
                        <DeleteIcon
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            removeContact(index);
                          }}
                        />
                      }
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              placeholder={"Ex: Full Name"}
                              disabled={saveLoading}
                              required
                              label="Full Name"
                              value={item.fullName}
                              onChange={(event) =>
                                handleEditContact(event, index, "fullName")
                              }
                              margin="none"
                            ></TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              placeholder={"Ex: Role"}
                              disabled={saveLoading}
                              required
                              label="Role"
                              value={item.role}
                              onChange={(event) =>
                                handleEditContact(event, index, "role")
                              }
                              margin="none"
                            ></TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              placeholder={"Ex: Contact Number"}
                              disabled={saveLoading}
                              required
                              label="Contact Number"
                              value={item.contactNumber}
                              onChange={(event) =>
                                handleEditContact(event, index, "contactNumber")
                              }
                              margin="Contact Number"
                            ></TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              placeholder={"Ex: Email"}
                              disabled={saveLoading}
                              required
                              label="Email"
                              value={item.email}
                              onChange={(event) =>
                                handleEditContact(event, index, "email")
                              }
                              margin="none"
                            ></TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Badge>
                  </Paper>
                );
              })}
              <Button
                onClick={() => {
                  addContact();
                }}
                style={{ backgroundColor: "#f7f7f7" }}
              >
                Add Contact
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
