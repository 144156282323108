import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Badge,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const {
    date,
    user,
    isOpen,
    setClose,
    consultant,
    createProjectTiming,
    getActiveProjects,
    findActiveProjectsbyConsultant,
  } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [selectedProjectOption, setSelectedProjectOption] = useState(null);

  const [consultantProjects, setConsultantProjects] = useState(null);

  const [task, setTask] = React.useState(null);
  const [duration, setDuration] = React.useState(null);

  const projectCodes = [
    {
      id: 0,
      color: "#417D7A",
    },

    {
      id: 1,
      color: "#C97C5D",
    },
    {
      id: 2,
      color: "#56638A",
    },
    {
      id: 3,
      color: "#798478",
    },
    {
      id: 4,
      color: "#F3B700",
    },
    {
      id: 5,
      color: "#A23E48",
    },
    {
      id: 6,
      color: "#8FB571",
    },
    {
      id: 7,
      color: "#907074",
    },
    {
      id: 8,
      color: "#907074",
    },
    {
      id: 9,
      color: "#907074",
    },
    {
      id: 10,
      color: "#DA667B",
    },
    {
      id: 11,
      color: "#9d9c85",
    },
    {
      id: 12,
      color: "#f1e0c5",
    },
  ];

  const StyledBadge = styled(Badge)(({ theme, index }) => ({
    "& .MuiBadge-badge": {
      left: -10,
      top: 12,
      padding: "0 4px",
      backgroundColor: projectCodes[index].color,
    },
  }));

  useEffect(() => {
    if (consultant) {
      findActiveProjectsbyConsultant(consultant._id).then((result)=>{
        setConsultantProjects(result.data)
      })
    }
  }, [consultant]);

  const handleSaveDetails = () => {
    if (user && user != null) {
      if (selectedProject == null || task == null || duration == null) {
        toast.error("You have missing information");
      } else {
        let payload = {
          date: moment(date).add(5, "hours"),
          projectId: selectedProject._id,
          task: task,
          consultantId: consultant._id,
          duration: parseFloat(duration),
          projectCode: selectedProjectOption,
          month: moment(date).format("MM-YYYY")
        };

        createProjectTiming(payload)
          .then(() => {
            setClose();
            setSaveLoading(false);
            toast.success(`Successfully created`);
            setSelectedProjectOption(null);
            setSelectedProject(null);
            setTask(null);
            setDuration(null);
            window.location.reload()

          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleSelectedProject = (index) => {
    setSelectedProjectOption(index);
    setSelectedProject(consultantProjects[index]);
  };

  return (
    <Popup
      title="Add a new Project Timing"
      subtitle={`Selected Date --- ${moment(date).format("DD MMM YYYY")}`}
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <InputLabel>Projects</InputLabel>
            <Select
              id="project"
              value={selectedProjectOption}
              onChange={(event) => {
                handleSelectedProject(event.target.value);
              }}
              style={{ marginBottom: 20 }}
              label="Project"
            >
              {consultantProjects &&
                consultantProjects.map((item, index) => {
                  return (
                    <MenuItem value={index} style={{ paddingLeft: 30 }}>
                      <StyledBadge
                        index={index}
                        variant="dot"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {item.title}
                      </StyledBadge>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <div></div>
          <FormControl sx={{ m: 1 }} className={classes.formControl}>
            <InputLabel>Tasks</InputLabel>
            <Select
              id="task"
              value={task}
              onChange={(event) => {
                setTask(event.target.value);
              }}
              label="Task"
            >
              {selectedProject &&
                selectedProject.tasks.map((item, index) => {
                  return <MenuItem value={item.title}>{item.title}</MenuItem>;
                })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: 8"}
              required
              type="number"
              label="Hours"
              value={duration}
              onChange={(event) => {
                setDuration(event.target.value);
              }}
              margin="normal"
            ></TextField>
          </FormControl>

          {selectedProject && (
            <div style={{ marginTop: 70 }}>
              <Typography
                className={classes.title}
                style={{ marginBottom: 20 }}
              >
                Due Date -{" "}
                {moment(new Date(selectedProject.endDate)).format(
                  "DD MMM YYYY"
                )}
              </Typography>
            </div>
          )}
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
