import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";


import TicketsNotesView from "./single-ticket-notes-view";


// Dialogues
import DialogueDeleteNote from "routes/dashboard/dialogues/tickets/delete-note";


export default withRouter(function (props) {
  let {
    ticket,
    ticketId,
    editTicket,
    setTicket,
    getTicket,
    user,
  } = props;


  const [showDeleteNoteDialogue, setShowDeleteNoteDialogue] = useState(false);
  const [selectedNoteIdx, setSelectedNoteIdx] = useState(false);

  return (
    <div>
      <TicketsNotesView
        loading={!ticket}
        sectionDescription={"Notes."}
        user={user}
        onPressDeleteNoteIdx={(idx) => {setSelectedNoteIdx(idx);
          setShowDeleteNoteDialogue(true)}}
          ticket={ticket}
        ticketId={ticketId}
        editTicket={editTicket}

      />


      <DialogueDeleteNote
        isOpen={showDeleteNoteDialogue}
        setClose={() => setShowDeleteNoteDialogue(false)}
        user={user}
        ticket={ticket}
        ticketId={ticketId}
        setTicket={setTicket}
        getTicket={getTicket}
        editTicket={editTicket}
        selectedNoteIdx={selectedNoteIdx}
      />

    </div>
  );
});
