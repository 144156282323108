import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import DocumentView from "./single-location-slots-view";

// Actions
import { getAssociatedLectureSlots } from "repository/actions/data/dashboard/locations";

export default withRouter(function (props) {
  const { locationData, user } = props;

  const [lectures, setLectures] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (locationData != null) {
      getAssociatedLectureSlots(locationData._id, user.idToken).then((result) => {
        setLectures(result.data);
      });
    }
  }, [locationData, lectures]);

  return (
    <div>
      <DocumentView
        loading={!lectures}
        sectionDescription={"All lecture slots related to this location"}
        lectures={lectures || []}
        setLectures={setLectures}
        location={locationData}
      />
    </div>
  );
});
