import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Icon } from "@material-ui/core";

import styles from "./navigation-tabs-styles";
import clsx from "clsx";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Tabs
          value={props.value}
          onChange={props.handleChange}
          variant="scrollable"
          scrollButtons="off"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
            label: classes.tabsLabel,
          }}
          TabIndicatorProps={{ children: <div /> }}
        >
          {props.tabRoutes.map((route) => (
            <Tab
              disableRipple
              wrapped
              value={route.path}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                indicator: classes.tabIndicator,
              }}
              icon={
                <div className={classes.tabIconRoot}>
                  <div className={classes.tabIcon}>
                    <Icon
                      className={clsx(classes.icon, "material-icons-outlined")}
                    >
                      {route.icon}
                    </Icon>
                  </div>
                </div>
              }
              label={<div className={classes.tabLabel}>{route.name}</div>}
            />
          ))}
        </Tabs>
      </div>
      <div className={classes.spacing}></div>
    </div>
  );
}
