import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllTicketsContext } from "repository/stores/dashboard/tickets/tickets-all-provider";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";

import DetailsView from "./details";
import NotesView from "./notes";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { editTicket, getTicket } = useContext(AllTicketsContext);
  const { getCohort } = useContext(AllCohortsContext);


  const ticketId = props.match.params.id;

  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    if (user) {
      getTicket(ticketId).then((result) => {
        setTicket(result);
      });
    }
  }, [user]);

  return (
    <div>
      <DetailsView
        loading={!ticket}
        ticket={ticket}
        sectionDescription={"You can manage all of your tickets below."}
        user={user}
        getCohort={getCohort}
        editTicket={editTicket}
        setTicket={setTicket}
        ticketId={ticketId}
      />
      <NotesView
        loading={!ticket}
        ticket={ticket}
        onPressedItem={(id) => props.history.push(`tickets/${id}`)}
        sectionDescription={"You can manage all of your tickets below."}
        user={user}
        ticketId={ticketId}
        getTicket={getTicket}
        editTicket={editTicket}
        setTicket={setTicket}
      />
    </div>
  );
});
