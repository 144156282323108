import React, { useState, useEffect } from 'react';
import {loginWithFirebaseEmail} from 'repository/actions/data/authentication';
import LoginCenterContentView from "./center-content-view";

const LoginCenterContentContainer = () => {
  const [errorText, setErrorText] = useState(false);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(null);

  const onLogin = () => {
    setLoading(true);
    loginWithFirebaseEmail(email,password).then(() => {
      window.location.href = `/`;
    }).catch(e => {
      console.error(e);
      setErrorText(e.message);
      setLoading(false);
    });
  };

  useEffect(()=> {
    setErrorText(null);
  },[email,password]);

  const onKeyPressDown = event => {
		if (event.keyCode === 13) {
			onLogin();
		}
	};

  return (
    <div onKeyDown={event => onKeyPressDown(event)}>
      <LoginCenterContentView
				onLogin={onLogin}
				errorText={errorText}
				password={password}
				setPassword={setPassword}
				email={email}
				setEmail={setEmail}
				loading={loading}
			/>
    </div>
  )
}

export default LoginCenterContentContainer;