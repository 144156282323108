import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

import { Button } from "@material-ui/core";

// Children
import PaymentsView from "./payments-all-payments-view";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();

  const { user } = useContext(AuthenticationContext);
  const { getClients } = useContext(AllClientsContext);
  const { findProjectPayment, editProjectPayment, getProjects } =
    useContext(AllProjectsContext);

  const [payments, setPayments] = useState(null);

  const [projectMap, setProjectMap] = useState(null);
  const [clientMap, setClientMap] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      findProjectPayment(user.idToken).then((result) => {
        setPayments(result.data);
      });

      getProjects(user.idToken).then((result) => {
        let projectMap = {};
        for (let p of result.data) {
          projectMap[p._id] = p.title;
        }
        console.log(projectMap)
        setProjectMap(projectMap);
      });
      getClients(user.idToken).then((result) => {
        let clientMap = {};
        for (let c of result.data) {
          clientMap[c._id] = c.title;
        }
        setClientMap(clientMap);
      });
    }
  }, [user]);

  return (
    <div>
      <Button
        className={classes.projectBtn}
        style={{ width: "25%", marginBottom: 30 }}
        onClick={() => {
          window.location.href = "payments-gantt";
        }}
      >
        View Payment Gantt
      </Button>
      <PaymentsView
        user={user}
        loading={!payments || !projectMap || !clientMap}
        onPressedItem={(id) => props.history.push(`${id}`)}
        sectionDescription={"You can View all pending payments below."}
        clientMap={clientMap}
        projectMap={projectMap}
        payments={payments || []}
        setPayments={setPayments}
        editProjectPayment={editProjectPayment}
      />
    </div>
  );
});
