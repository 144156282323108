import React from 'react';

// Components
import LoginView from './login-view';


const LoginContainer = (props) => {
  return <LoginView {...props} />
}

export default LoginContainer;