import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import PaymentPlansView from "./calculators-payment-plans-view";

// Actions
// import {
//   findAllCourses
// } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const {courses, setCourses, getCourses} = useContext(AllCoursesContext);
  const { user } = useContext(AuthenticationContext);
  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses( user.idToken);
      }
    }
  }, [courses]);

  return (
    <div>
      <PaymentPlansView
        loading={!courses}
        sectionDescription={"You can create payment plans below."}
        courses={courses || []}
        user={user}
      />
    </div>
  );
});
