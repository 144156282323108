import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { project } = props;

  let crumbs = [
        {name: 'Projects', path: 'projects'},
        {name: 'All Projects', path: 'projects/active'},
        {name: 'New Project', path: '#'},
  ];

  if (project && project.title != null) {
    crumbs[2] =  { name: `${project.title}`, path: `projects/${project._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
