import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";

// Children
import CandidatesView from "./candidates-all-candidates-view";

// Dialogues
import DialogueCreateCandidate from "routes/dashboard/dialogues/candidates/create-candidate";
import DialogueDeleteCandidate from "routes/dashboard/dialogues/candidates/delete-candidate";


// Actions

// import { findAllCourses } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { candidates, getCandidates, createNewCandidate, deleteCandidate, loadingCandidates } = useContext(AllCandidatesContext);


  const [showNewCandidateDialogue, setShowNewCandidateDialogue] = useState(false);
  const [showDeleteCandidateDialogue, setShowDeleteCandidateDialogue] = useState(false);



  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (candidates == null) {
        getCandidates(user.idToken)
      }
    }
  }, [candidates]);


  return (
    <div>
      <CandidatesView
        loading={!candidates}
        onPressedItem={(id) => props.history.push(`${id}`)}
        toggleAddCandidateForm={() => setShowNewCandidateDialogue(true)}
        sectionDescription={"You can manage all of your candidates below."}
        candidates={candidates || []}
        
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteCandidateDialogue(true);
        }}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueCreateCandidate
        isOpen={showNewCandidateDialogue}
        setClose={() => setShowNewCandidateDialogue(false)}
        user={user}
        createNewCandidate={createNewCandidate}
        setRefreshAlgolia={setRefreshAlgolia}
      />


      <DialogueDeleteCandidate
        isOpen={showDeleteCandidateDialogue}
        setClose={() => setShowDeleteCandidateDialogue(false)}
        user={user}
        deleteCandidate={deleteCandidate}
        candidateId={selectedId}
        setRefreshAlgolia={setRefreshAlgolia}
      />

    </div>
  );
});
