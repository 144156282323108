import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  Typography,
  FormControl,
  TextField,
  Button,
  Grid,
  MenuItem,
  Switch,
  Select,
} from "@material-ui/core";
// import Select from "react-select";
import { styled } from "@material-ui/core/styles";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    project,
    setProject,
    isNewProject,
    createNewProject,
    editProject,
    findClientsByQuery,
  } = props;
  const classes = useStyles();

  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [isSelectingClients, setIsSelectingClients] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [initialProject, setInitialProject] = useState(null);

  const [daysLeft, setDaysLeft] = useState(null);

  const handleSaveProject = async () => {
    if (
      project.title == null ||
      project.clientId == null ||
      project.startDate == null ||
      project.endDate == null ||
      project.duration == null ||
      // project.consultants == null ||
      project.tasks == null
    ) {
      if (project.title == null) {
        toast.error("Missing Title");
      }
      if (project.startDate == null) {
        toast.error("Missing Start Date");
      }
      if (project.endDate == null) {
        toast.error("Missing End Date");
      }
      if (project.duration == null) {
        toast.error("Missing Duration");
      }
      // if (project.consultants == null) {
      //   toast.error("Missing Consultants")
      // }
      if (project.tasks == null) {
        toast.error("Missing Tasks");
      }
      if (project.clientId == null) {
        toast.error("Missing Client");
      }
    } else {
      setIsLoading(true);
      await createNewProject(project)
        .then((result) => {
          toast.success("Project Created");
          window.location.href = `/projects/${result._id}`;
        })
        .catch((e) => {
          toast.error(e.message);
          setIsLoading(false);
        });
    }
  };

  const handleEditProject = async () => {
    if (
      project.title == null ||
      project.clientId == null ||
      project.startDate == null ||
      project.endDate == null ||
      project.duration == null ||
      // project.consultants == null ||
      project.tasks == null ||
      project.payments == null
    ) {
      if (project.title == null) {
        toast.error("Missing Title");
      }
      if (project.startDate == null) {
        toast.error("Missing Start Date");
      }
      if (project.endDate == null) {
        toast.error("Missing End Date");
      }
      if (project.duration == null) {
        toast.error("Missing Duration");
      }
      // if (project.consultants == null) {
      //   toast.error("Missing Consultants")
      // }
      if (project.tasks == null) {
        toast.error("Missing Tasks");
      }
      if (project.payments == null) {
        toast.error("Missing Payments");
      }
      if (project.clientId == null) {
        toast.error("Missing Client");
      }
    } else {
      setIsLoading(true);
      await editProject(project._id, project)
        .then((result) => {
          toast.success("Project Updated");
          setProject(result);
          setInitialProject(result);
        })
        .catch((e) => {
          toast.error(e.message);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!loading) {
      findClientsByQuery({
        $limit: 100,
        $select: ["_id", "title"],
        businessLines: {
          $in: ["Advisory"],
        },
      }).then((result) => {
        setClients(result.data);
      });

      if (!isNewProject) {
        setInitialProject(project);
        setIsSelectingClients(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (project) {
      setClient(project.clientId);
    }
  }, [project]);

  const getDaysLeftColor = (daysLeft) => {
    if (daysLeft > 7) {
      return ["#EBF9F4", "#3AB589"];
    }
    if (daysLeft <= 7 && daysLeft > 0) {
      return ["#FFF7E6", "#FFB822"];
    }
    if (daysLeft < 0) {
      return ["#FDF4F6", "#DD4166"];
    }
  };

  const handleChangeClient = (item, index) => {
    setProject({
      ...project,
      clientId: item._id,
      clientTitle: item.title,
    });
    setClient(item._id);
    setIsSelectingClients(false);
  };

  const handleProjectStatus = async () => {
    let status = project.status == "Active" ? "Complete" : "Active";

    await editProject(project._id, { status: status })
      .then((result) => {
        toast.success("Project set as " + status);
        setProject(result);
        setInitialProject(result);
      })
      .catch((e) => {
        toast.error(e.message);
        setIsLoading(false);
      });
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#95CC97",
      "&:hover": {
        backgroundColor: "#95CC9780",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#95CC97",
    },
  }));



  return (
    <div>
      <FormControl className={classes.inputType}>
        <TextField
          placeholder="Project Title"
          type="text"
          value={project ? project.title : null}
          inputProps={{
            style: {
              fontFamily: "proximaNova-bold",
              color: "#564181",
              fontWeight: 900,
              fontSize: 20,
              width: "90%",
            },
          }}
          InputLabelProps={{ style: { fontSize: 20 } }}
          onChange={(event) => {
            setProject({ ...project, title: event.target.value });
          }}
        />
      </FormControl>

      {isSelectingClients ? (
        <div>
          <Select
            elevation={0}
            variant={"outlined"}
            value={client}
            placeholder="Client"
            style={{ marginTop: 10, width: "30%", borderRadius: 10 }}
            required
          >
            {clients.map((item, index) => {
              return (
                <MenuItem
                  value={item._id}
                  onClick={() => {
                    handleChangeClient(item);
                  }}
                >
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      ) : (
        <Typography
          className={classes.projectTitle}
          onClick={() => setIsSelectingClients(true)}
        >
          {project.clientTitle ? project.clientTitle : ""}
        </Typography>
      )}

      {isNewProject && (
        <Button
          className={classes.projectBtn}
          onClick={() => handleSaveProject()}
          disabled={isLoading}
        >
          SAVE
        </Button>
      )}

      {!isNewProject && (
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "spacing-between", alignItems: "end" }}
        >
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Button
                  className={classes.projectBtn}
                  onClick={() => handleProjectStatus()}
                  style={{ width: "100%" }}
                >
                  Mark as{" "}
                  {project
                    ? project.status == "Active"
                      ? "Complete"
                      : "Active"
                    : ""}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.projectBtn}
                  onClick={() => handleEditProject()}
                  disabled={initialProject == project}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>
      )}
    </div>
  );
});
