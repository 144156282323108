import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { Button } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();
  const {
    setClose,
    isOpen,
    cohort,
    setCohort,
    getCohort,
    user,
    bulkAssignLecturers,
  } = props;

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedFileName, setSelectedFileName] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleCreateLecturer = () => {
    if (user && user != null) {
      if (selectedFile == null) {
        toast.error(`Oops! Something went wrong`);
      } else {
        let data = {
          file: selectedFile,
          cohortId: cohort._id,
        };

        setSaveLoading(true);

        toast.dark(`Assigning Lecturers ...`);

        bulkAssignLecturers(data)
          .then((result) => {
            getCohort(cohort._id, user.idToken).then((refreshedCohort) => {
              setSaveLoading(false);
              toast.success(`Lecturers Assigned ✔`);
              setCohort(refreshedCohort);
              setSelectedFile(null);
              setClose();
            });
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. "
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleCapture = ({ target }) => {
    if (target.files && target.files.length > 0) {
      if (target.files[0].size > 15728640) {
        alert("File is too big");
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        setSelectedFileName(target.files[0].name);
        fileReader.onload = (e) => {
          setSelectedFile(e.target.result);
        };
      }
    }
  };

  return (
    <Popup
      title="Bulk Lecturer Upload "
      subtitle="Please upload file to create bulk Lecturers."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateLecturer()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <div style={{ textAlign: "center" }}>
            <input
              accept=".csv"
              style={{ display: "none" }}
              id="lecturer-button-file"
              type="file"
              onChange={handleCapture}
            />
            <div style={{ margin: 30 }}>
              Upload file to bulk assign Lecturers
            </div>
            <div>{selectedFileName}</div>
            <label htmlFor="lecturer-button-file">
              <Button
                variant="raised"
                component="span"
                style={{ backgroundColor: "#f7f7f7" }}
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
