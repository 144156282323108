import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'general',
      startPath: 'all',
      routes:[
        { name: 'Extensions', path: 'extensions', icon: 'watch_later'},
        { name: 'Tickets', path: 'tickets', icon: 'flag'},
        { name: 'Learning Agr...', path: 'learning-agreements', icon: 'flag'},
        { name: 'Referrals', path: 'referrals', icon: 'group_add'},

      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});