import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";



import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";


// Children
import ClientsView from "./clients-all-clients-view";

// Dialogues
import DialogueCreateClient from "routes/dashboard/dialogues/clients/create-client";
import DialogueDeleteClient from "routes/dashboard/dialogues/clients/delete-client";


// Actions

// import { findAllCourses } from "repository/actions/data/dashboard/courses";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { clients, getClients, createNewClient, deleteClient, loadingClients } = useContext(AllClientsContext);


  const [showNewClientDialogue, setShowNewClientDialogue] = useState(false);
  const [showDeleteClientDialogue, setShowDeleteClientDialogue] = useState(false);


  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (clients == null) {
        getClients(user.idToken);
      }
    }
  }, [clients]);


  return (
    <div>
      <ClientsView
        loading={!clients}
        onPressedItem={(id) => props.history.push(`${id}`)}
        toggleAddClientForm={() => setShowNewClientDialogue(true)}
        sectionDescription={"You can manage all of your clients below."}
        clients={clients || []}

        onPressedDeleteItem={(id) => {
          setSelectedId(id);
          setShowDeleteClientDialogue(true);
        }}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}

      />

      <DialogueCreateClient
        isOpen={showNewClientDialogue}
        setClose={() => setShowNewClientDialogue(false)}
        user={user}
        clients={clients || []}
        createNewClient={createNewClient}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueDeleteClient
        isOpen={showDeleteClientDialogue}
        setClose={() => setShowDeleteClientDialogue(false)}
        user={user}
        clientId={selectedId}
        deleteClient={deleteClient}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
