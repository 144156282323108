import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  TextField,
  FormControl,
  Grid,
  Button,
  InputAdornment,
  Typography,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    programme,
    setProgramme,
    panelDescription,
    studentTotalCost,
    moduleTotalCost,
    programmeTotalCosts,
  } = props;
  const classes = useStyles();

  const [totalCosts, setTotalCosts] = useState(null);
  const [contingency, setContingency] = useState(null);
  const [discounts, setDiscounts] = useState(null);


 


  useEffect(() => {
    if (programme) {
      // console.log(studentTotalCost.studentTotalCosts ,
      //   moduleTotalCost.moduleTotalCosts ,
      //   programmeTotalCosts.programmeTotalCosts)


      let sum =
        studentTotalCost.studentTotalCosts +
        moduleTotalCost.moduleTotalCosts +
        programmeTotalCosts.programmeTotalCosts;

      if (sum) {
        let contingency = (sum * 0.1).toFixed(2);
        sum = sum + parseFloat(contingency);

        let discounts = (sum * 0.1).toFixed(2);
        sum = sum + parseFloat(discounts);

        setContingency(contingency);
        setDiscounts(discounts);
      }

      setTotalCosts(sum ? sum : null);
      // setProgramme({...programme, totalCosts: sum});
    }
  }, [programme]);

  function numberWithCommas(x) {
    if (!x) return "";
    return "€" + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }



  return (
    <div>
      <Grid container style={{ justifyContent: "center", marginTop: 15 }}>
        <Grid item xs={6} style={{ textAlign: "center"}}>
          {contingency && (
            <div>
              <Typography style={{ color: "#717171", fontSize: 16 }}>
                {`+ ${numberWithCommas(contingency)}`}
              </Typography>
              <Typography style={{ color: "#717171", fontSize: 16 }}>
                10% contingency
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center"}}>
          {discounts && (
            <div>
              <Typography style={{ color: "#717171", fontSize: 16 }}>
                {`+ ${numberWithCommas(discounts)}`}
              </Typography>
              <Typography style={{ color: "#717171", fontSize: 16 }}>
                10% discounts
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container style={{ justifyContent: "center", marginTop: 40 }}>
        <Grid item xs={5} >
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Total Cost"
              type="text"
              value={numberWithCommas(parseInt(totalCosts))}
              size="small"
              style={{ textAlign: "center", width: "100%" }}
              className={classes.costings}
              color="secondary"
              focused
              inputProps={{
                style: {
                  fontFamily: "proximaNova-bold",
                  color: "#800000",
                  fontWeight: 900,
                  fontSize: 26,
                  width: "90%",
                  textAlign: "center",
                },
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {totalCosts && (
        <Typography style={{ color: "#717171", fontSize: 16, textAlign: "center"}}>
          {`${numberWithCommas(
            parseInt(
              totalCosts / (programme.studentCount ? programme.studentCount : 1)
            )
          )} / student`}
        </Typography>
      )}
      {totalCosts && (
        <Typography style={{ color: "#717171", marginTop: 40, textAlign: "center" }}>
          Markup
        </Typography>
      )}
      {totalCosts && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl className={classes.inputType}>
              <TextField
                type="text"
                variant="outlined"
                value={numberWithCommas(
                  parseInt(
                    (totalCosts /
                      (programme.studentCount ? programme.studentCount : 1)) *
                      1.35
                  )
                )}
                size="small"
                label="35%"
                style={{ textAlign: "center", width: "100%" }}
                className={classes.costings}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.inputType}>
              <TextField
                type="text"
                variant="outlined"
                value={numberWithCommas(
                  parseInt(
                    (totalCosts /
                      (programme.studentCount ? programme.studentCount : 1)) *
                      1.5
                  )
                )}
                size="small"
                label="50%"
                style={{ textAlign: "center", width: "100%" }}
                className={classes.costings}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl className={classes.inputType}>
              <TextField
                type="text"
                variant="outlined"
                value={numberWithCommas(
                  parseInt(
                    (totalCosts /
                      (programme.studentCount ? programme.studentCount : 1)) *
                      1.75
                  )
                )}
                size="small"
                label="75%"
                style={{ textAlign: "center", width: "100%" }}
                className={classes.costings}
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  );
});
