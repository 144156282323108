import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import PaymentPlans from './routes/payment-plans';

export default function () {
    return(
        <div>
            <Switch>
                <Route path='/calculators/payment-plans' component={() => <PaymentPlans />} />
                <Route exact path='/calculators' component={() => <Redirect to={`/calculators/payment-plans`} />} />
            </Switch>
        </div>
    )
}