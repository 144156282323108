const styles = theme => ({
    logo: {
        width:'auto',
        height:40,
        marginLeft:24,
        paddingReft:0,
        paddingTop:-2,
        position:'fixed',
        fontSize: 17,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
          height: 34,
          marginTop: -2,
          marginLeft: 35,
        },
        [theme.breakpoints.up('md')]: {
          height:45,
          marginTop: -2.5,
          marginBottom: 3,
          marginLeft: 3,
          display: 'inline',
        }
    }
});
  
export default styles;
  