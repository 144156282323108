import React, { useEffect } from "react";
import Panel from "components/panel";
import { Chart } from "react-google-charts";
import { MenuItem, Select, Grid, FormGroup } from "@material-ui/core";

export default function (props) {
  const {
    loading,
    lectures,
    personnelMap,
    locations,
    locationMap,
    cohortMap,
    weekDay,
    panelDescription,
    onPressedItem,
  } = props;

  const [lectureData, setLectureData] = React.useState(null);
  const [optionData, setOptionData] = React.useState([]);
  const [lastIndex, setLastIndex] = React.useState(null);

  useEffect(() => {
    if (!loading) {
      getRows(lectures).then((rows) => {
        setLectureData(rows);
      });
    }
  }, [loading, lectures]);

  const html = (personel, start, end, zoomlink, module, students) => {
    return `
    <div style="width:500px; height: 105%; padding: 10px 20px; background-color:white; margin-top:-7px; margin-left:-7px; border-radius:10px; border:1px solid #f7f7f7; box-shadow: 0 20px 30px -10px rgba(0,0,0,0.2)" >
       
        <div style="display: flex; justify-content: space-between;">
          <div style=" padding:20px">
            <div><b>${personel}</b></div>
            <div style="margin-left:-7px; margin-top: 3px; background-color: #ff4e0026; padding: 7px 10px;  border-radius: 10px; width:fit-content">
              <div ><b>${module}</b></div>
            </div>
          </div>
          <div>
            <div style="margin:10px 20px; padding: 10px; color:white; background-color: #ff4e00;
            background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 65%); box-shadow: 0 7px 15px -5px #ff4e0080 ; border-radius: 20px"><b>${students} Students</b></div>
          </div>
       
        </div>

        <div style="padding:20px;border-top:1px solid #B0B0B0" >
          <div> <b>Duration</b></div>
          <div style="margin-left:-7px; margin-top: 3px; background-color: #ff4e0026; padding: 7px 10px;  border-radius: 10px; width:fit-content">
            ${start.getHours()}:${start.getMinutes()} - ${end.getHours()}:${end.getMinutes()}
          </div>
        </div>
                
        <div style="padding: 20px;border-top:1px solid #B0B0B0">
          <div><b>Zoom Code</b></div>
          <div style="margin-left:-7px; margin-top: 3px; background-color: #ff4e0026; padding: 7px 10px;  border-radius: 10px; width:fit-content">
            ${zoomlink?zoomlink: "No Code"}
          </div>
        </div>

    </div>`;
  };

  let colors = ["#e63b6f"];
  var colorMap = {
    Level1: "#e63b6f",
    blank: "#ffffff00",
    CategoryB: "#19c362",
    CategoryC: "#592df7",
  };

  const populateRooms = (rows) => {
    let allRooms = [...locations];

    for (let room of allRooms) {
      rows.push([
        room.title,
        ``,
        ``,
        new Date(0, 0, 0, 9, 0, 0),
        new Date(0, 0, 0, 9, 0, 1),
      ]);

      colors.push("#00f");
    }

    return rows;
  };

  const getRows = (hits) => {
    return new Promise((resolve, reject) => {
      let rows = [];
      colors = [];

      if (hits.length > 0) {
        hits.map((hit, index) => {
          let start = new Date(hit.lectureStartTime);
          let end = new Date(hit.lectureEndTime);

          let startTime = new Date(
            0,
            0,
            0,
            start.getHours(),
            start.getMinutes()
          );
          let endTime = new Date(0, 0, 0, end.getHours(), end.getMinutes());

          let location =
            locationMap && locationMap != null
              ? locationMap[hit.locationId]
                ? locationMap[hit.locationId].title
                : "N/A"
              : "N/A";

          let personel = personnelMap[hit.personnelId]
            ? `${personnelMap[hit.personnelId].firstName} ${
                personnelMap[hit.personnelId].lastName
              }`
            : "No Lecturer";

          let cohortTitle = cohortMap[hit.cohortId]
          ? `${cohortMap[hit.cohortId].title}`
          : "No Cohort";

          let students = cohortMap[hit.cohortId]
          ? `${(cohortMap[hit.cohortId].studentIds).length}`
          : "0";

          rows.push([
            location,
            `${hit.title} - ${cohortTitle} - ${personel}`,
            html(personel, start, end, hit.zoomlink, hit.module, students),
            startTime,
            endTime,
          ]);
          colors.push(colorMap["Level1"]);
        });
      }

      rows = populateRooms(rows);

      let data = [
        [
          { type: "string", id: "Title" },
          { type: "string", id: "Type" },
          { type: "string", role: "tooltip", p: { html: true } },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ],
        ...rows,
      ];

      resolve(data);
    });
  };

  const dayOfWeek = new Date(
    lectures[0] ? lectures[0].lectureStartTime : new Date()
  );

  const options = {
    timeline: {
      groupByRowLabel: true,
    },
    tooltip: { isHtml: true },
    hAxis: {
      minValue: new Date(0, 0, 0, 9, 0),
      maxValue: new Date(0, 0, 0, 20, 0),
      format: "HH:mm",
    },
    colors: colors,
    allowHtml: true,
    backgroundColor: "white",
    alternatingRowStyle: false,
  };

  return (
    <Panel
      title={weekDay}
      loading={loading}
      panelDescription={panelDescription}
    >
      <Grid container>
        <Grid item xs={8}></Grid>

        <Grid item xs={4} style={{ marginBottom: 20 }}>
          <FormGroup>
            <Select
              value={null}
              onChange={(event) => {
                onPressedItem(event.target.value);
              }}
            >
              {lectures.map((item, index) => {
                 let cohortTitle = cohortMap[item.cohortId]
                 ? `${cohortMap[item.cohortId].title}`
                 : "No Cohort";
                 
                return <MenuItem value={index}>{`${item.title} - ${cohortTitle}`}</MenuItem>;
              })}
            </Select>
          </FormGroup>
        </Grid>
      </Grid>
      {lectureData && (
        <Chart
          chartType="Timeline"
          data={lectureData}
          width="100%"
          height={100 + 50 * locations.length}
          options={options}
        />
      )}
    </Panel>
  );
}
