import React, { useState, useEffect } from "react";
import { getAssociatedModules } from "repository/actions/data/dashboard/courses";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { course, setCourse, user } = props;
  const classes = useStyles();
  const [modules, setModules] = useState(null);

  useEffect(() => {
    if (course != null) {
      getAssociatedModules(course._id, user.idToken).then((result) => {
        setModules(result.data);
      });
    }
  }, [course]);

  return (
    <Section
      title={"Associated Modules"}
      loading={!modules}
      sectionDescription={"All modules associated with this course."}
    >
      {modules != null && (
        <div>
          {modules.length > 0 && (
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        MQF Level
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        ECTS
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Compulsory
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Module Reference Number
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Assigned Course
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modules.map((row, index) => {
                    return (
                      <TableRow hover key={row._id} className={classes.row}>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">{row.mqfLevel}</TableCell>
                        <TableCell align="left">{row.ects}</TableCell>
                        <TableCell align="left">
                          {row.isCompulsory ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="left">
                          {row.referenceNumber}
                        </TableCell>
                        <TableCell align="left">
                          {row.assignedCourse.title}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {modules.length === 0 && (
            <Typography className={classes.emptyText} align="center">
              There are no modules associated with this course.
            </Typography>
          )}
        </div>
      )}
    </Section>
  );
});
