import React, { useState } from "react";
import { withRouter } from "react-router-dom";

//Children
import Controller from "./controller-timetables-view";

import DialoguesNewLecture from "routes/dashboard/dialogues/lectures/new-lecture-new";

export default withRouter(function (props) {
  const {
    user,
    weekText,
    cohorts,
    cohortMap,
    getCohort,
    selectedWeek,
    handleWeekChange,
    handleWeekSelect,
    createNewLecture,
    locations,
  } = props;

  const [showNewLectureDialogue, setShowNewLectureDialogue] = useState(false);

  return (
    <div>
      <Controller
        weekText={weekText}
        selectedWeek={selectedWeek}
        handleWeekChange={handleWeekChange}
        handleWeekSelect={handleWeekSelect}
        addNewLecture={()=>{
          setShowNewLectureDialogue(true)
        }}
      />

      <DialoguesNewLecture
        isOpen={showNewLectureDialogue}
        setClose={() => {
          setShowNewLectureDialogue(false);
        }}
        user={user}
        createNewLecture={createNewLecture}
        cohortMap={cohortMap}
        getCohort={getCohort}
        cohorts={cohorts||[]}
        locations={locations||[]}
        handleWeekSelect={handleWeekSelect}
      />
    </div>
  );
});
