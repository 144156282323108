import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from 'repository/stores/global/authentication-provider';

// Children
import PeopleView from "./administration-all-people-view";

// Dialogues
import CreatePersonnalDialogue from "routes/dashboard/dialogues/personnel/create-personnel";

export default withRouter(function (props) {
  const [showNewPersonDialogue, setShowNewPersonDialogue] = useState(false);
  const {user} = useContext( AuthenticationContext);
  return (
    <div>
      <PeopleView
        loading={false}
        onPressedItem={(id) => props.history.push(`personnel/${id}`)}
        toggleAddPersonForm={() => setShowNewPersonDialogue(true)}
        sectionDescription={
          "You can manage all of your administration personnel below."
        }
        people={[]}
        user={user}
      />
      <CreatePersonnalDialogue
        isOpen={showNewPersonDialogue}
        setClose={() => setShowNewPersonDialogue(false)}
        user={user}
      />
    </div>
  );
});
