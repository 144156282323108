import apiClient from "utils/api";


export const createIndustry = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/industries").create(data, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};



export const getIndustries = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/industries").find({
        query: {
          firebaseToken: token,
        },
      });

      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};



export const deleteIndustry = async (id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/industries").remove(id, {
        query: {
          firebaseToken: token,
        },
      });

      resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};




