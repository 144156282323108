import React from 'react';

// Parents
import UsersRoutes from './consultants-routes';

export default function (props) {

  return (
      <UsersRoutes />
  )
}
