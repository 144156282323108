import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Paper,
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { ticket, ticketId, user, setTicket, editTicket } = props;
  const classes = useStyles();

  const [date, setDate] = React.useState(null);
  const [title, setTitle] = React.useState(null);

  useEffect(() => {
    if (ticket) {
      setTitle(ticket.title);
      let created = new Date(ticket.createdAt);
      setDate(moment(created).format("DD MMM YYYY"));
    }
  }, [ticket]);

  const handleStatusChange = (value) => {
    let payload = {
      status: value,
    };

    editTicket(ticketId, payload).then((result) => {
      toast.success("Status Updated");
      setTicket({ ...ticket, status: value });
    });
  };

  const handleTypeChange = (value) => {
    let payload = {
      type: value,
    };

    editTicket(ticketId, payload).then((result) => {
      toast.success("Type Updated");
      setTicket({ ...ticket, type: value });
    });
  };

  const handleTitleChange = () => {
    let payload = {
      title: title,
    };

    editTicket(ticketId, payload).then((result) => {
      toast.success("Title Updated");
      setTicket({ ...ticket, title: title });
    });
  };

  const ticketTypes = [
    "Failed Assessments",
    "Non-Submissions",
    "Plagiarism Issues",
    "Attendance Issues",
    "Personal Issues",
    "Other Issues",
  ];

  return (
    <Section
      className={classes.root}
      title="Ticket Details"
      loading={!ticket}
      sectionDescription={"Manage ticket details."}
    >
      {ticket != null && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Title</Typography>
                <TextField
                  placeholder={"Ex: Ticket Title"}
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  className={classes.fontRegular}
                ></TextField>
                <Button
                disabled={ticket.title == title}
                  onClick={() => handleTitleChange()}
                  style={{
                    backgroundColor: "#f7f7f7",
                    margin: "5px 0 0 10px",
                    fontSize: 12,
                  }}
                >
                  Save
                </Button>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Student Name
                </Typography>
                <Typography className={classes.fontRegular}>
                  {ticket.student.studentName}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Created At</Typography>
                <Typography className={classes.fontRegular}>{date}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Status</Typography>

                <Select
                  id="ticket-status"
                  label="Ticket Status"
                  value={ticket.status}
                  className={classes.fontRegular}
                  style={{ width: 170 }}
                  onChange={(event) => {
                    handleStatusChange(event.target.value);
                  }}
                >
                  <MenuItem key="Pending" value={"Pending"}>
                    Pending
                  </MenuItem>
                  <MenuItem key="Resolved" value={"Resolved"}>
                    Resolved
                  </MenuItem>
                </Select>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Type</Typography>

                <Select
                  id="ticket-type"
                  label="Ticket Type"
                  value={ticket.type}
                  className={classes.fontRegular}
                  style={{ width: 170 }}
                  onChange={(event) => {
                    handleTypeChange(event.target.value);
                  }}
                >
                  {ticketTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </Section>
  );
});
