import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  TextField,
  FormControl,
  Grid,
  Typography,
  InputAdornment,
  ListItem,
  Divider,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    programme,
    setProgramme,
    isNewProgramme,
    panelDescription,
    moduleTotalCost,
    setModuleTotalCost,
  } = props;
  const classes = useStyles();

  const moduleCostings = [
    { title: "Lecturing Cost", cost: 50 },
    { title: "Video Production", cost: 250 },
  ];

  const [moduleCosts, setModuleCosts] = useState(moduleCostings);

  const [moduleSum, setModuleSum] = useState(0);
  // const [moduleCount, setModuleCount] = useState(5);
  const [hoursPerModule, setHoursPerModule] = useState(30);
  const [lecturingCost, setLecturingCost] = useState(30);
  const [videoProduction, setVideoProduction] = useState(250);
  const [simulatorRate, setSimulatorRate] = useState(0);
  const [simulatorHours, setSimulatorHours] = useState(0);

  const [moduleTypes, setModuleTypes] = useState([{ modules: 5, hours: 30 }]);

  useEffect(() => {
    if (!isNewProgramme) {
      setModuleTypes(programme.moduleTypes);
      setLecturingCost(programme.moduleCosts.lecturingCost);
      setVideoProduction(programme.moduleCosts.videoProduction);

      setSimulatorRate(programme.moduleCosts.simulatorRate ? programme.moduleCosts.simulatorRate : 0);
      setSimulatorHours(programme.moduleCosts.simulatorHours ? programme.moduleCosts.simulatorHours : 0);
    }
  }, [isNewProgramme]);

  useEffect(() => {
    let moduleCount = moduleTypes.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.modules);
    }, 0);

    let moduleHours = moduleTypes.reduce((accumulator, object) => {
      return (
        accumulator + parseFloat(object.modules) * parseFloat(object.hours)
      );
    }, 0);

    setModuleSum(
      lecturingCost * moduleHours +
        videoProduction * moduleCount +
        simulatorRate * simulatorHours
    );

    setModuleTotalCost({
      ...moduleTotalCost,
      moduleTypes: moduleTypes,
      moduleCosts: {
        lecturingCost: lecturingCost,
        videoProduction: videoProduction,
        simulatorRate: simulatorRate,
        simulatorHours: simulatorHours,
      },
      moduleTotalCosts:
        lecturingCost * moduleHours +
        videoProduction * moduleCount +
        simulatorRate * simulatorHours,
    });

    setProgramme({
      ...programme,

      moduleCosts: {
        lecturingCost: lecturingCost,
        videoProduction: videoProduction,

        simulatorRate: simulatorRate,
        simulatorHours: simulatorHours,
      },
      // moduleTotalCosts:
      //   lecturingCost * moduleHours + videoProduction * moduleCount,
    });
  }, [moduleTypes, lecturingCost, videoProduction, simulatorHours, simulatorRate]);

  const handleModuleAmountChange = (event, index) => {
    let modules = [...moduleTypes];
    modules[index].modules = event.target.value
      ? parseFloat(event.target.value)
      : 0;
    setModuleTypes(modules);
  };
  const handleModuleHourChange = (event, index) => {
    let modules = [...moduleTypes];
    modules[index].hours = event.target.value
      ? parseFloat(event.target.value)
      : 0;
    setModuleTypes(modules);
  };

  const addModuleType = () => {
    let newModuleTypes = [...moduleTypes];
    newModuleTypes.push({ modules: 5, hours: 30 });
    setModuleTypes(newModuleTypes);
  };

  function numberWithCommas(x) {
    if (!x) return "";
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Panel
      title={`Cost Per Module`}
      loading={loading}
      panelDescription={panelDescription}
    >
      {moduleTypes.map((item, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={7}>
              <FormControl className={classes.inputType}>
                <TextField
                  placeholder="Number of modules"
                  label="Number of modules"
                  variant="outlined"
                  type="number"
                  value={item.modules}
                  size="small"
                  onChange={(event) => handleModuleAmountChange(event, index)}
                  className={classes.costings}
                  inputProps={{
                    style: {
                      fontSize: 15,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.inputType}>
                <TextField
                  placeholder="Module Hrs"
                  label="Module Hrs"
                  variant="outlined"
                  type="number"
                  value={item.hours}
                  onChange={(event) => handleModuleHourChange(event, index)}
                  size="small"
                  className={classes.costings}
                  inputProps={{
                    style: {
                      fontSize: 15,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              {moduleTypes.length - 1 == index && (
                <AddCircleIcon
                  onClick={() => {
                    addModuleType();
                  }}
                  style={{ marginTop: 25, fontSize: 19, cursor: "pointer" }}
                />
              )}
            </Grid>
          </Grid>
        );
      })}
      <hr style={{ marginTop: 16, border: "1px solid #ececec" }} />
      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid item xs={7}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Expense"
              variant="outlined"
              type="text"
              value={"Lecturing Cost"}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  readOnly: true,
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="number"
              value={lecturingCost}
              onChange={(event) => {
                setLecturingCost(
                  event.target.value ? parseFloat(event.target.value) : 0
                );
              }}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Video */}
      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid item xs={7}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Expense"
              variant="outlined"
              type="text"
              value={"Video Production"}
              size="small"
              className={classes.costings}
              inputProps={{
                readOnly: true,
                style: {
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="number"
              value={videoProduction}
              onChange={(event) => {
                setVideoProduction(
                  event.target.value ? parseFloat(event.target.value) : 0
                );
              }}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Simulator */}
      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Expense"
              variant="outlined"
              type="text"
              value={"Simulator"}
              size="small"
              className={classes.costings}
              inputProps={{
                readOnly: true,
                style: {
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="hours"
              variant="outlined"
              label="Hours"
              type="number"
              value={simulatorHours}
              onChange={(event) => {
                setSimulatorHours(
                  event.target.value ? parseFloat(event.target.value) : 0
                );
              }}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              label="Rate / hr"
              variant="outlined"
              type="number"
              value={simulatorRate}
              onChange={(event) => {
                setSimulatorRate(
                  event.target.value ? parseFloat(event.target.value) : 0
                );
              }}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ marginTop: 30, justifyContent: "space-around" }}
      >
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="text"
              value={numberWithCommas(parseInt(moduleSum))}
              size="small"
              className={classes.costings}
              color="secondary"
              focused
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Panel>
  );
});
