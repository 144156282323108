import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Grid, TextField, Typography } from "@material-ui/core";
import { getSearchClient } from "utils/algolia";
import Loading from "components/loading";

import { Chart } from "react-google-charts";

const searchClient = getSearchClient();

export default withRouter(function (props) {
  let { user } = props;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getActiveCohorts();
  }, []);

  const getActiveCohorts = async () => {
    searchClient
      .initIndex("Cohorts")
      .search("", {
        filters: `status:"In Progress"`,
        attributesToRetrieve: ["title", "objectID"],
        hitsPerPage: 100,
      })
      .then(({ hits }) => {
        let cohorts = hits.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
        let data = [["Cohort", "Students", { role: "style" }]];
        loadStudents(cohorts, data);
      });
  };

  const loadStudents = async (cohorts, data) => {
    const students = searchClient.initIndex("Students");

    let idx = 0;
    while (idx < cohorts.length) {
      await students
        .search("", {
          filters: `cohortIds:${cohorts[idx].objectID}`,
          attributesToRetrieve: [""],
        })
        .then(({ hits }) => {
          data.push([cohorts[idx].title, hits.length, "#0077B6"]);
        });

      if (idx == cohorts.length - 1) {
        setData(data);
        setIsLoading(false);
      }
      idx++;
    }
  };

  return (
    <Panel title="Active Cohorts" loading={false}>
      {!isLoading && data && (
        <div style={{ padding: 10 }}>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={{
              legend: "top",
              animation: {
                startup: true,
                easing: "out",
                duration: 800,
              },
            }}
          />
        </div>
      )}
      {isLoading && <Loading />}
    </Panel>
  );
});
