import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import StudentsView from "./single-cohort-students-view";

// Actions

export default withRouter(function (props) {
  const { cohort, setCohort, user, convertStudentsToComplete } = props;

  const [students, setStudents] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (cohort != null) {
      setStudents(cohort.students);
    }
  }, [cohort]);

  return (
    <div>
      <StudentsView
        loading={!students}
        sectionDescription={"All students associated to this cohort."}
        students={students || []}
        user={user}
        cohort={cohort}
        convertStudentsToComplete={convertStudentsToComplete}
      />
    </div>
  );
});
