import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'utils/service-worker';
import App from './components/app';
import styled from 'styled-components';


const Wrap = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	padding: 0px;
`;

const RootElement = () => {
	return (
		// <Wrap>
			<App />
		// </Wrap>
	);
};


// Render main component
ReactDOM.render(<RootElement />, document.getElementById('root'));

// Disable Caching
serviceWorker.unregister();