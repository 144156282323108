import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import Section from 'components/section';
import { Typography, TableContainer, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Paper, ClickAwayListener,Grow, MenuList, MenuItem, Divider, Popper } from '@material-ui/core';
import { MoreVert, CloudDownload } from '@material-ui/icons';
import {getSignedUrlFromAbsoluteUrl} from 'utils/helpers/signedurls';
// Styles
import styles from 'routes/dashboard/dashboard-styles';
const useStyles = makeStyles(styles);

export default function (props) {

  const classes = useStyles();
  const { loading, toggleAddCourseForm, lecturer, letters, onPressedDeleteItem, sectionDescription } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId ] = React.useState(null);  
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Section title="Associated Letters" loading={loading} actionName="Add letter" handleAction={toggleAddCourseForm} sectionDescription={sectionDescription}>
            {(letters.length > 0) &&
              <TableContainer  component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"> <Typography className={classes.labelText}>Name</Typography></TableCell>
                      <TableCell align="center"> <Typography className={classes.labelText}>Date Added</Typography></TableCell>
                      <TableCell align="center"> <Typography className={classes.labelText}>Attachment</Typography></TableCell>
                      <TableCell align="right"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {letters.map((row, index) => {
                      return (
                        <TableRow hover key={row._id} className={classes.row}>
                          <TableCell className={classes.cellName} align="left">{row.title}</TableCell>
                          <TableCell className={classes.cellType} align="center">
                            <Typography className={classes.text}><Moment format="D MMMM, YYYY">{row.createdAt}</Moment></Typography>
                            <div></div>
                            <Typography className={classes.textBold}><Moment format="@HH:mm">{row.createdAt}</Moment></Typography>
                          </TableCell>
                          <TableCell className={classes.cellType} align="center">
                              <IconButton onClick={() =>  { getSignedUrlFromAbsoluteUrl(row.attachment.url).then( signedUrl => window.open(signedUrl, '_blank'))}}>
                                  <CloudDownload />
                              </IconButton>
                          </TableCell>
                          <TableCell className={classes.selectRight} align="right">
                              <IconButton className={classes.rightRowButton} onClick={(event) => { setAnchorEl(event.currentTarget); setSelectedIndex(index); setSelectedId(row._id); setOpen(true) }} aria-owns={open ? 'menu-list-grow' : null} aria-haspopup="true">
                                  <MoreVert className={classes.iconRight}/>
                              </IconButton> 
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            {(letters.length === 0) &&
              <Typography className={classes.emptyText} align='center'>There are no associated letters with this student.</Typography>
            }
            <Popper open={open} anchorEl={anchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper elevation={0} className={classes.menu}>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList className={classes.menuList}>
                        <MenuItem  onClick={() => {onPressedDeleteItem(selectedId); setOpen(false);}} className={classes.menuItemTop}>Delete</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          
        </Section>
  )
}