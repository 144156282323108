import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  cohort: null,
  setCohort: {},
  loadingCohort: false,
  getCohort: {},
  editCohort: {},
  assignLecturer: {},
  deleteLecturer: {},
  generateTranscript: {},
  generateMissingSubmissions: {},
  bulkAssignLecturers: {},
  bulkGenerateTranscript: {},
  bulkDiplomaSupplement: {},
  bulkRecordOfWork: {},
  bulkTranscriptPdf: {},
  bulkCompletionLetter: {},
  bulkCertificate: {},
  convertStudentsToComplete:{}
};

export const SingleCohortContext = React.createContext(ContextProps);

export const SingleCohortProvider = ({ children }) => {
  const [cohort, setCohort] = useState(null);
  const [loadingCohort, setLoadingCohort] = useState(false);

  const { getIdToken } = useContext(IdTokenContext)



  const getCohort = (id, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCohort(true);

          let result = await apiClient.service("v1/cohorts").get(id, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingCohort(false);
          setCohort(result);
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const editCohort = (id, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCohort(true);

          let editResult = await apiClient.service("v1/cohorts").patch(
            id,
            {
              ...data,
              syncCanvas: false,
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          let getResult = await getCohort(id, token);
          setCohort(getResult);
          setLoadingCohort(false);
          resolve(getResult);
      } catch (e) {
        reject(e);
      }
    });
  };

  const convertStudentsToComplete = async (id, cohortData,token) => {
    return new Promise(async (resolve, reject) => {
      try {

          let result = await apiClient.service("v1/cohorts").patch(id, {studentIds:cohortData.studentIds},{
            query: {
              firebaseToken: token,
              provider: "convertStudentsToComplete"
            },
          });
          
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const bulkAssignLecturers = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-lecturers"
            },
          });
          
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const assignLecturer = (
    cohortId,
    personnelId,
    startDate,
    expiryDate,
    token
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient
            .service("v1/personnel-role-associations")
            .create(
              {
                roleId: "lecturer",
                personnelId: personnelId,
                startsAt: startDate,
                expiresAt: expiryDate,
                cohortId: cohortId,
              },
              {
                query: {
                  firebaseToken: token,
                },
              }
            );

            console.log(result)
          let getResult = await getCohort(cohortId, token);
          setCohort(getResult);
          setLoadingCohort(false);
          resolve(getResult);
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteLecturer = (cohortId, personnelId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

          let result = await apiClient
            .service("v1/personnel-role-associations")
            .find({
              query: {
                personnelId: personnelId,
                cohortId: cohortId,
                firebaseToken: token,
              },
            });

          if (result.data.length == 1) {
            let roleAssociationId = result.data[0]._id;
            let deletedResult = await apiClient
              .service("v1/personnel-role-associations")
              .remove(roleAssociationId, {
                query: {
                  firebaseToken: token,
                },
              });
          }
          let getResult = await getCohort(cohortId, token);
          setCohort(getResult);
          setLoadingCohort(false);
          resolve(getResult);
      } catch (e) {
        reject(e);
      }
    });
  };

  const generateTranscript = (canvasCourseId, recipientEmailAddress, token) => {
    return new Promise(async (resolve, reject) => {
      try {

          let result = await apiClient.service("v1/autotasks").create(
            {
              type: "process-course-grades",
              data: {
                courseId: canvasCourseId,
                sendTo: recipientEmailAddress,
              },
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const generateMissingSubmissions = (
    canvasCourseId,
    recipientEmailAddress,
    token
  ) => {
    return new Promise(async (resolve, reject) => {
      try {

          let result = await apiClient.service("v1/autotasks").create(
            {
              type: "process-late-submissions",
              data: {
                courseId: canvasCourseId,
                sendTo: recipientEmailAddress,
              },
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const extractAssignmentActivity = (
    canvasCourseId,
    recipientEmailAddress,
    token
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/autotasks").create(
            {
              type: "extract-student-activity",
              data: {
                courseId: canvasCourseId,
                sendTo: recipientEmailAddress,
              },
            },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };


  const bulkGenerateTranscript = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {

          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-transcript"
            },
          });
          
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  const bulkDiplomaSupplement = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-diploma-supplement"
            },
          });
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };


  const bulkRecordOfWork = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-record-of-work"
            },
          });
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const bulkTranscriptPdf = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-student-transcript-pdf"
            },
          });
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };
  const bulkCompletionLetter = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-completion-letter"
            },
          });
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };
  const bulkCertificate= async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
          let result = await apiClient.service("v1/bulk").create(data, {
            query: {
              firebaseToken: token,
              provider: "bulk-certificate"
            },
          });
          resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <SingleCohortContext.Provider
      value={{
        cohort,
        setCohort,
        loadingCohort,
        getCohort,
        editCohort,
        assignLecturer,
        deleteLecturer,
        generateTranscript,
        generateMissingSubmissions,
        extractAssignmentActivity,
        bulkAssignLecturers,
        bulkGenerateTranscript,
        bulkDiplomaSupplement,
        bulkRecordOfWork,
        bulkCompletionLetter,
        bulkCertificate,
        bulkTranscriptPdf,
        convertStudentsToComplete
      }}
    >
      {children}
    </SingleCohortContext.Provider>
  );
};
