import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    students,
    sectionDescription,
    convertStudentsToComplete,
    cohort,
    user
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onPressedItem = (id, campus) => {
    window.location.href = `/students/all-${campus.toLowerCase()}/${id}`;
  };

  const handleConvertStudentsToComplete = () => {
    convertStudentsToComplete(cohort._id, cohort, user.idToken).then(() => {
      toast.success("Students set to complete");
    });
  };
  return (
    <Panel
      title="Enrolled Students"
      loading={loading}
      actionName={"Convert to Complete"}
      handleAction={() => {
        handleConvertStudentsToComplete();
      }}
      sectionDescription={sectionDescription}
    >
      {students.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    First Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Email</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Exit Point
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((row, index) => {
                var exitpoint = row.enrollments.filter((r) => {
                  return r.cohortId === cohort._id;
                });
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell
                      className={classes.cellName}
                      align="left"
                      onClick={(event) => onPressedItem(row._id, row.campus)}
                    >
                      {row.firstName}
                    </TableCell>
                    <TableCell
                      className={classes.cellName}
                      align="left"
                      onClick={(event) => onPressedItem(row._id, row.campus)}
                    >
                      {row.lastName}
                    </TableCell>
                    <TableCell
                      className={classes.cellName}
                      align="left"
                      onClick={(event) => onPressedItem(row._id, row.campus)}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      className={classes.cellName}
                      align="left"
                      onClick={(event) => onPressedItem(row._id, row.campus)}
                    >
                      {exitpoint.length > 0 && exitpoint[0].exitPoint.title}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {students.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          There are no associated students with this cohort.
        </Typography>
      )}
    </Panel>
  );
}
