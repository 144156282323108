import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  ListItemAvatar,
  Avatar,
  ListItem,
  Grid,
  ListItemText,
  Typography,
} from "@material-ui/core";
// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { proposal, consultantMap, timings } = props;
  const classes = useStyles();

  const [timingTotal, setTimingTotal] = useState(null);


  useEffect(()=>{
    if(timings){
      let total = timings.map(item => item.duration).reduce((a, b) => parseFloat(a) +  parseFloat(b), 0);
      setTimingTotal(total)
    }
  },[timings])


  return (
    <Panel
      title={"Timings"}
      loading={!proposal}
      sectionDescription={"Detailed information about the cohort."}
    >
      {timings && consultantMap && (
        <div>
          {timings.map((item, index) => {
            let c = consultantMap[item.consultantId];

            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar alt={c.firstName} src={c.directURL} />
                </ListItemAvatar>
                <Grid container>
                  <Grid item xs={6}>
                    <ListItemText primary={`${c.firstName} ${c.lastName}`} />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <ListItemText
                      primary={`${item.task} -  ${item.duration} hrs`}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
          
          <Grid container style={{padding:30}}>
            <Grid item xs={6}>
              <Typography className={classes.title}>Total</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Typography  className={classes.title}>{timingTotal} hrs</Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </Panel>
  );
});
