import React, {useContext} from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

// import { getClient } from "repository/actions/data/dashboard/clients";

import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider"


export default withSnackbar(
  withRouter(function (props) {

    const { isOpen, setClose, vacancyId, user, deleteVacancy, setRefreshAlgolia, setClient, clientId } = props;
    const { getClient } = useContext(AllClientsContext)


    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteVacancy = () => {
      if (user && user != null) {

        setSaveLoading(true);

        deleteVacancy(vacancyId, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Vacancy successfully deleted`);

            getClient(clientId, user.idToken).then((result) => {
              setClient(result)
            })
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected vacancy"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteVacancy()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this vacancy?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
