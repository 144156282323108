import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  TextField,
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
} from "@material-ui/core";
import { Visibility, Edit, Delete, CloudDownload } from "@material-ui/icons";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";

// Navigation
import NavCrumbs from "./training-all-proposals-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
} from "react-instantsearch-dom";

// Styles
import styles from "./training-all-proposals-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddLocationForm,
    onPressedItem,
    proposals,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    user
  } = props;

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {/* {nbPages > 2 &&
            currentRefinement <
              nbPages -
                1(
                  <li className={classes.paginationListItem}>
                    <Link
                      href={createURL(nbPages - 1)}
                      onClick={(event) => {
                        event.preventDefault();
                        refine(nbPages - 1);
                      }}
                    >
                      ‹
                    </Link>
                  </li>
                )} */}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a Proposal"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell className={classes.cellName} align="left">
                {row.title}
              </TableCell>
              <TableCell className={classes.cellType} align="left">
                {row.description}
              </TableCell>
              <TableCell className={classes.cellAttachment} align="left">
                <IconButton
                  onClick={() => {
                    getSignedUrlFromAbsoluteUrl(row.attachment.url, user && user != null ? user.idToken : null).then(
                      (signedUrl) => window.open(signedUrl, "_blank")
                    );
                  }}
                >
                  <CloudDownload />
                </IconButton>
              </TableCell>
              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="view"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedEditItem(index, row.objectID);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR TRAINING PROPOSALS"
        loading={loading}
        actionName="Add proposal"
        handleAction={toggleAddLocationForm}
        sectionDescription={sectionDescription}
      >
        {proposals.length > 0 && (
          <InstantSearch indexName="Proposals" searchClient={searchClient}>
            <CustomSearchBox />
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Attachment
                      </Typography>
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {proposals.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any proposals yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
