import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider";
import { IdTokenContext } from "repository/stores/global/token-provider";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";

const ContextProps = {
  consultants: null,
  setConsultants: {},
  getConsultants: {},
  getInternalConsultants: {},
  getConsultant: {},
  loadingConsultants: false,
  deleteConsultant: {},
  createNewConsultant: {},
  editConsultant: {},
  findConsultantByEmail:{}
};

export const AllConsultantsContext = React.createContext(ContextProps);

export const AllConsultantsProvider = ({ children }) => {
  const [consultants, setConsultants] = useState(null);
  const [loadingConsultants, setLoadingConsultants] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext);

  const getConsultants = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingConsultants(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/consultants").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingConsultants(false);
          setConsultants(result.data);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const findConsultantByEmail = (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingConsultants(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/consultants").find({
            query: {
              email: email,
              firebaseToken: token,
            },
          });

          setLoadingConsultants(false);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const getInternalConsultants = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingConsultants(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/consultants").find({
            query: {
              type: "Internal",
              firebaseToken: token,
            },
          });

          setLoadingConsultants(false);
          resolve(result.data);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const getEternalConsultants = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingConsultants(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/consultants").find({
            query: {
              type: "External",
              firebaseToken: token,
            },
          });

          setLoadingConsultants(false);
          resolve(result.data);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const getConsultant = (consultantId, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingConsultants(true);
        getIdToken().then(async (token) => {
          let result = await apiClient
            .service("v1/consultants")
            .get(consultantId, {
              query: {
                firebaseToken: token,
                provider: params,
              },
            });

          setLoadingConsultants(false);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewConsultant = async (consultant, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/consultants").create(
            { ...consultant },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          if (consultants == null) {
            setConsultants(result);
          } else {
            let newData = consultants;
            newData.push(result);
            setConsultants(newData);
          }
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const editConsultant = async (consultantId, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let updated = await apiClient
            .service("v1/consultants")
            .patch(consultantId, data, {
              query: {
                firebaseToken: token,
              },
            });

          resolve(updated);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteConsultant = async (consultantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient
            .service("v1/consultants")
            .remove(consultantId, {
              query: {
                firebaseToken: token,
              },
            });
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllConsultantsContext.Provider
      value={{
        consultants,
        setConsultants,
        createNewConsultant,
        getConsultants,
        getConsultant,
        editConsultant,
        deleteConsultant,
        getInternalConsultants,
        getEternalConsultants,
        loadingConsultants,
        findConsultantByEmail
      }}
    >
      {children}
    </AllConsultantsContext.Provider>
  );
};
