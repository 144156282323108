import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper } from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { user, authUser } = props;
  const classes = useStyles();

  let title = "...";
  let email = "";

  if (user) {
    title = `${user.firstName} ${user.lastName}`;
    email = user.email;
  }
  return (
    <Section
      title={title}
      loading={!user}
      sectionDescription={"Personal information about the user."}
    >
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Title</Typography>
        <Typography className={classes.fontRegular}>{title}</Typography>
      </Paper>
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Email</Typography>
        <Typography className={classes.fontRegular}>{email}</Typography>
      </Paper>
    </Section>
  );
});
