import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";

import { Grid } from "@material-ui/core";

// Navigation
import NavCrumbs from "./candidates-single-candidates-nav-crumbs";

// Components
import Details from "./details";
import Vacancies from "./vacancy-list";
import Notes from "./notes";


// import { getCandidate } from "repository/actions/data/dashboard/candidates"

export default withRouter(function (props) {
  const candidateId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const { getCandidate,editCandidate } = useContext(AllCandidatesContext);

  const [candidate, setCandidate] = useState(null)


  useEffect(() => {
    if (
      user &&
      user != null &&
      candidateId &&
      candidateId != null

    ) {
      try {
        getCandidate(candidateId, user.idToken).then((result) => {
          setCandidate(result);
        });
      } catch (e) {
        alert("Can't get this candidate.");
      }
    }
  }, []);




  return (
    <NavCrumbs candidate={candidate}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Details
            candidate={candidate}
            candidateId={candidateId}
            user={user}
            setCandidate={setCandidate}
            editCandidate={editCandidate}
          />
        </Grid>
        <Grid item xs={6}>
          <Vacancies
            candidate={candidate}
            candidateId={candidateId}
            user={user}
          />
        </Grid>
      </Grid>

      <Notes
        candidate={candidate}
        candidateId={candidateId}
        setCandidate={setCandidate}
        user={user} />
    </NavCrumbs>
  );
});
