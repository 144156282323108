import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'timesheets',
      startPath: 'today',
      routes:[
        { name: 'Today', path: 'today', icon: 'subject_icon'},
        { name: 'Custom', path: 'custom', icon: 'subject_icon'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});