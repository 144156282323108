import React, { useContext, useState, useEffect } from "react";
import NavCrumbs from "./leave-all-requests-nav-crumbs";
import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";

// Children
import TimesheetsView from "./leave-all-requests-view";
// import { findPendingLeaveRequests } from "repository/actions/data/dashboard/timesheets"
import {AllLeavesContext } from "repository/stores/dashboard/leaves/leaves-all-provider"

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const [leaveRequests, setLeaveRequests] = useState([])
  const{findPendingLeaveRequests } = useContext(AllLeavesContext)


  const getPendingLeaves = (user) =>{
    findPendingLeaveRequests(user.idToken).then((result)=>{
      setLeaveRequests(result)
    })
  }

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      getPendingLeaves(user)
    }
  }, [user]);


  return (
    <div>
      <TimesheetsView
        loading={!leaveRequests}
        sectionDescription={"You can apprive or decline leave requests."}
        user={user}
        leaveRequests={leaveRequests}
        getPendingLeaves={getPendingLeaves}
       />
    </div>
  );
});
