const styles = theme => ({
    navIcon: {
        marginLeft: -7,
        marginTop: -2.5,
        [theme.breakpoints.up('sm')]: {
          marginLeft: 0,
          marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
    }
});
  
export default styles;
  