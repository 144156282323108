import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import SingleCohortLecturersView from "./single-cohort-lecturers-view";

//Dialogues
import DialogueAssignLecturer from "routes/dashboard/dialogues/cohorts/assign-lecturer";
import DialogueDeleteLecturer from "routes/dashboard/dialogues/cohorts/delete-lecturer";
import DialogueBulkAssignLecturers from "routes/dashboard/dialogues/cohorts/bulk-assign-lecturer";
import DialogueAssignLecturersOptions from "routes/dashboard/dialogues/cohorts/assign-lecturer-options";

export default withRouter(function (props) {
  let {
    cohort,
    setCohort,
    user,
    editCohort,
    getCohort,
    assignLecturer,
    deleteLecturer,
    bulkAssignLecturers,
  } = props;

  const [lecturers, setLecturers] = useState(null);
  const [showDeleteLecturerDialogue, setShowDeleteLecturerDialogue] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [showAssignLecturerDialogue, setShowAssignLecturerDialogue] =
    useState(false);

  const [showBulkAssignLecturerDialogue, setShowBulkAssignLecturerDialogue] =
    useState(false);
  const [
    showOptionsAssignLecturerDialogue,
    setShowOptionsAssignLecturerDialogue,
  ] = useState(false);

  //Data Fetching
  useEffect(() => {
    if (cohort != null) {
      setLecturers(cohort.lecturers);
    }
  }, [lecturers, cohort]);

  return (
    <div>
      <SingleCohortLecturersView
        loading={!lecturers}
        sectionDescription={"All assigned lecturers"}
        lecturers={lecturers || []}
        toggleAssignLecturerForm={() => setShowOptionsAssignLecturerDialogue(true)}
        cohort={cohort}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteLecturerDialogue(true);
        }}
      />
      <DialogueAssignLecturer
        isOpen={showAssignLecturerDialogue}
        setClose={() => setShowAssignLecturerDialogue(false)}
        cohort={cohort}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        assignLecturer={assignLecturer}
      />

      <DialogueBulkAssignLecturers
        isOpen={showBulkAssignLecturerDialogue}
        setClose={() => setShowBulkAssignLecturerDialogue(false)}
        cohort={cohort}
        setCohort={setCohort}
        getCohort={getCohort}
        user={user}
        bulkAssignLecturers={bulkAssignLecturers}
      />

      <DialogueAssignLecturersOptions
        isOpen={showOptionsAssignLecturerDialogue}
        setClose={() => setShowOptionsAssignLecturerDialogue(false)}
        setShowAssignLecturerDialogue={setShowAssignLecturerDialogue}
        setShowBulkAssignLecturerDialogue={setShowBulkAssignLecturerDialogue}
      />

      <DialogueDeleteLecturer
        isOpen={showDeleteLecturerDialogue}
        setClose={() => setShowDeleteLecturerDialogue(false)}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        cohort={cohort}
        lecturerIndex={selectedIndex}
        lecturers={lecturers}
        deleteLecturer={deleteLecturer}
      />
    </div>
  );
});
