import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import StatusProjects from './status-projects';

export default function () {
    return(
        <div>
            <Switch>
                <Route path='/projects/active' component={() => <StatusProjects status={"active"}/>} />
                <Route path='/projects/complete' component={() => <StatusProjects status={"complete"}/>} />
                <Route exact path='/projects' component={() => <Redirect to={`/projects/active`} />} />
            </Switch>
        </div>
    )
}