import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-user-styles";
import {
  Link,
  TextField,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Switch,
  InputLabel,
  FormControlLabel,
  Input,
  Box,
  Chip,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import ImageFadeIn from "react-image-fade-in";
import EmptyProfileImage from "assets/images/empty-profile.png";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
// import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
// import { editUser } from "repository/actions/data/dashboard/users";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, editUser } = props;

  // const {users, setUsers,editUser} = React.useContext(AllUsersContext);

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [surname, setSurname] = React.useState(null);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [surnameError, setSurnameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [imageError, setImageError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [newImage, setNewImage] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [leave, setLeave] = React.useState(null);
  const [leaveError, setLeaveError] = React.useState(null);
  const [department, setDepartment] = React.useState(null);
  const [departmentError, setDepartmentError] = React.useState(null);

  const [active, setActive] = React.useState(true);

  const departments = [
    "Sales",
    "Studio",
    "Finance",
    "Advisory",
    "Academy",
    "Intelligence",
    "Front Office",
  ];

  useEffect(() => {
    if (data) {
      setName(data.firstName);
      setEmail(data.email);
      setImage(data.displayPhoto ? data.displayPhoto.url : "");
      setSurname(data.lastName);
      setRoles(data.roles);
      setLeave(data.leave);
      setDepartment(data.department);
      setActive(data.active);
    }
  }, [data]);

  const rolesList = [
    "admin",
    "sales",
    "academy",
    "advisory",
    "resourcing",
    "Studio",
    "Finance",
  ];

  const handleEditUser = () => {
    if (user && user != null) {
      if (name == null || surname == null || email == null || leave == null) {
        toast.error(`You have missing information to edit this user`);
        if (email === null) {
          setEmailError("Email is required.");
        }
        if (surname === null) {
          setSurnameError("Surname is required.");
        }
        if (name === null) {
          setNameError("Name is required.");
        }
        if (leave === null) {
          setLeaveError("Leave is required.");
        }
        if (department === null) {
          setDepartmentError("Department is required.");
        }
      } else {
        let saveData = {
          firstName: name,
          lastName: surname,
          email: email,
          imageURI: newImage,
          roles: roles,
          leave: leave,
          department: department,
          active: active,
        };

        setSaveLoading(true);

        editUser(saveData, itemId, user.idToken).then((result) => {
          toast.success(
            `User ${saveData.firstName} ${saveData.lastName} successfully edited`
          );
          setClose();
          setSaveLoading(false);
          window.location.reload();
        });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 5242880) {
      toast.error("File is too big. The maximum size allowed is 5MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setNewImage(e.target.result);
      };
    }
  };

  const handleRole = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Popup
      title="Edit User"
      subtitle="You can edit the details of your user below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleEditUser()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <div className={classes.coverPhoto}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              disabled={saveLoading}
              onChange={handleCapture}
            />
            <label htmlFor="contained-button-file" disabled={saveLoading}>
              {newImage && (
                <ImageFadeIn className={classes.photo} src={newImage} />
              )}
              {image && !newImage && (
                <ImageFadeIn className={classes.photo} src={image} />
              )}
              {!image && !newImage && (
                <ImageFadeIn
                  className={classes.photo}
                  src={EmptyProfileImage}
                />
              )}
              <Typography className={classes.error}>{imageError}</Typography>
              <Link
                className={classes.link}
                disabled={saveLoading}
                component="span"
                variant="body2"
              >
                <div>Change Photo (max: 5mb)</div>
              </Link>
            </label>
          </div>

          <div style={{ display: "flex", justifyContent: "center", marginBottom:-10 }}>
            <Switch checked={active} onChange={(event)=>setActive(event.target.checked)}/>
          </div>
          <div style={{ display: "flex", justifyContent: "center", fontSize:12 }}>
            <p>Is Active</p>
          </div>

          <div></div>
          <Grid container>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: John"}
                  disabled={saveLoading}
                  required
                  helperText={nameError}
                  error={nameError != null}
                  label="First Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Doe"}
                  disabled={saveLoading}
                  required
                  helperText={surnameError}
                  error={surnameError != null}
                  label="Last Name"
                  value={surname}
                  onChange={(event) => setSurname(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: johndoe@example.com"}
              disabled={saveLoading}
              required
              helperText={emailError}
              error={emailError != null}
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: 170 hours"}
                  disabled={saveLoading}
                  required
                  helperText={leaveError}
                  error={leaveError != null}
                  label="Leave Hours"
                  type="number"
                  value={leave}
                  onChange={(event) => setLeave(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <div></div>
              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Department
                </InputLabel>
                <Select
                  elevation={0}
                  onChange={(event) => setDepartment(event.target.value)}
                  value={department}
                  required
                  disabled={saveLoading}
                  input={<Input id="type-menu" />}
                >
                  {departments.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <FormControl
            className={classes.formControl}
            style={{ margin: "20px 0" }}
          >
            <InputLabel required style={{ marginLeft: "20px" }}>
              Role
            </InputLabel>
            <Select
              multiple
              value={roles}
              variant="standard"
              onChange={handleRole}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              {rolesList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
