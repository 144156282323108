import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllCohorts from './routes/all-cohorts';
import SingleCohort from './routes/single-cohort';

export default function () {
    return(
        <div>
            <Switch>
                <Route exact path='/cohorts/all' component={() => <AllCohorts />} />
                <Route exact path='/cohorts/:id' component={() => <SingleCohort />} />
                <Route exact path='/cohorts' component={() => <Redirect to={`/cohorts/all`} />} />
            </Switch>
        </div>
    )
}