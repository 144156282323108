import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Select from "react-select";
// import ls from 'local-storage'

// Navigation
import NavCrumbs from "./candidates-all-candidates-nav-crumbs";

//InstantSearch
import { getSearchClient } from "utils/algolia";

import {
  InstantSearch,
  connectSearchBox,
  connectRefinementList,
  Highlight,
  SearchBox,
  connectHits,
  connectPagination,
  Stats,
  RefinementList,
} from "react-instantsearch-dom";
import { toast } from "react-toastify";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddCandidateForm,
    onPressedItem,
    candidates,
    onPressedDeleteItem,
    sectionDescription,
    user,
    refreshAlgolia,
  } = props;

  const [defaultRefinement, setDefaultRefinement] = useState("");
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [selectedNationalities, setSelectedNationalities] = useState([]);

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  useEffect(() => {
    searchClient.clearCache();

    let searchQuery = localStorage.getItem("candidateSearch");
    setDefaultRefinement(searchQuery);
  }, []);

  
  const RefinementListCustom = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };


  // const updateSearch = (query) => {
  //   localStorage.setItem('candidateSearch', query);
  //   setDefaultRefinement(query)
  // }

  // const SearchBox = ({ currentRefinement, refine }) => {
  //   // currentRefinement=defaultRefinement
  //   return (
  //     <TextField
  //       type="search"
  //       variant="outlined"
  //       label="Search for a candidates"
  //       value={currentRefinement}
  //       fullWidth
  //       onChange={(event) => {
  //         refine(event.currentTarget.value);
  //         setDefaultRefinement(event.currentTarget.value)
  //       }}
  //       className={classes.searchBar}
  //     />
  //   );
  // };

  // const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          var day1 = new Date(row.createdAt);
          var day2 = new Date();
          var Difference_In_Time = day2.getTime() - day1.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          let diffDays = Difference_In_Days.toFixed(0);

          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {diffDays} d ago
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.firstName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.lastName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.contactNumber}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.email}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {/* {row.applications.length} */}
              </TableCell>

              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    if (row.applications.length > 0) {
                      toast.error(
                        "Make sure that the candidate has no applications. Remove candidate from all vacancies"
                      );
                    } else {
                      onPressedDeleteItem(row.objectID);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  const [isLocalData, setIsLocalData] = useState(null);

  const getIsLocalData = async () => {
    let isLocalFacet = await searchClient.initIndex("Candidates").search("", {
      facets: ["isLocal"],
    });
    setIsLocalData(isLocalFacet.facets.isLocal);
  };

  useEffect(() => {
    getIsLocalData();

    searchClient
      .initIndex("Candidates")
      .searchForFacetValues("nationality", "")
      .then(({ facetHits }) => {
        let nationalities = facetHits.map(function (obj) {
          return {
            value: obj.value,
            label: obj.value,
          };
        });
        setNationalityOptions(nationalities);
      });
  }, []);

  const handleNationalitySelectionChange = (SelectedNationalityOptions) => {
    let newSelectedNationalities = [];
    if (
      SelectedNationalityOptions &&
      SelectedNationalityOptions.length > 0
    ) {
      for (let sco of SelectedNationalityOptions) {
      console.log(sco)

        newSelectedNationalities.push(sco.value);
      }
      console.log(newSelectedNationalities)
    }
    setSelectedNationalities(newSelectedNationalities);
  };

  
  const CustomRefinementList = connectRefinementList(RefinementListCustom);


  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR CANDIDATES"
        loading={loading}
        actionName="Add Candidate"
        handleAction={toggleAddCandidateForm}
        sectionDescription={sectionDescription}
      >
        {candidates.length > 0 && (
          <InstantSearch
            indexName="Candidates"
            searchClient={searchClient}
            refresh={refreshAlgolia}
            onSearchStateChange={(searchState) => {
              if (typeof searchState.query != "undefined") {
                setDefaultRefinement(searchState.query);
                localStorage.setItem("candidateSearch", defaultRefinement);
              }
            }}
          >
            <RefinementList
              attribute="isLocal"
              facetOrdering
              defaultRefinement={["true"]}
              transformItems={(items) =>
                items.map((item) => {
                  let custom = "";
                  if (item.label == "true") {
                    custom = "In Malta";
                  } else {
                    custom = "Abroad";
                  }
                  return {
                    ...item,
                    label: custom,
                  };
                })
              }
            />

            <RefinementList
              attribute="isActive"
              facetOrdering
              // defaultRefinement={["true"]}
              transformItems={(items) =>
                items.map((item) => {
                  let custom = "";
                  if (item.label == "true") {
                    custom = "Active";
                  } else {
                    custom = "Archived";
                  }
                  return {
                    ...item,
                    label: custom,
                  };
                })
              }
            />
            <CustomRefinementList
              attribute="nationality"
              operator="or"
              defaultRefinement={selectedNationalities}
            />

            <Grid container spacing={4} justify="space-between" style={{marginBottom: 10}}>
              <Grid item xs={6}>
                <Select
                  options={nationalityOptions}
                  isMulti
                  isClearable
                  placeholder="Select Nationality"
                  onChange={handleNationalitySelectionChange}
                  className={classes.selectContainer}
                />
              </Grid>
              <Grid item xs={5}>
                <Stats
                  translations={{
                    stats(nbHits, processingTimeMS) {
                      return (
                        <div>
                          <Typography className={classes.stat}>
                            {isLocalData.true} in Malta, {isLocalData.false}{" "}
                            abroad
                          </Typography>
                          <Typography className={classes.statSmall}>
                            {nbHits} Candidates in {processingTimeMS} ms
                          </Typography>
                        </div>
                      );
                    },
                  }}
                />
              </Grid>
            </Grid>
            <SearchBox
              defaultRefinement={defaultRefinement}
              className={classes.searchBar}
            />
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Created At
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Surname
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Contact Number
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Applications
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {candidates.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any candidates yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
