
import React, { useState, useContext } from "react";
import apiClient from "utils/api";

import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  locations: null,
  setLocations: {},
  getAllLocations: {},
  createNewLocation: {},
  editLocation: {},
  deleteLocation: {}
};

export const AllLocationsContext = React.createContext(ContextProps);

export const AllLocationsProvider = ({ children }) => {
  const [locations, setLocations] = useState(null);

  const { getIdToken } = useContext(IdTokenContext)


  const getAllLocations = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/locations").find({
          query: {
            firebaseToken: token,
          },
        });

        setLocations(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };



  const createNewLocation = async (location, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/locations").create(location, {
          query: {
            firebaseToken: token,
          },
        });

        if (locations == null) {
          setLocations(result);
        } else {
          let newData = locations
          newData.push(result)
          setLocations(newData);
        }

        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editLocation = async (data, locationId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let updatedLocation = await apiClient
          .service("v1/locations")
          .patch(locationId, data, {
            query: {
              firebaseToken: token,
            },
          });

        let newData = locations
        for (const [i, v] of newData.entries()) {
          if (v._id === locationId) {
            newData[i] = data;
          }
        }
        setLocations(newData);

        resolve();
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteLocation = async (locationId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/locations").remove(locationId, {
          query: {
            firebaseToken: token,
          },
        });

        let newData = locations
        for (const [i, v] of newData.entries()) {
          if (v._id === locationId) {
            newData.splice(i, 1);
          }
        }
        setLocations(newData);


        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllLocationsContext.Provider
      value={{
        locations,
        setLocations,
        getAllLocations,
        createNewLocation,
        editLocation,
        deleteLocation
      }}
    >
      {children}
    </AllLocationsContext.Provider>
  );
};
