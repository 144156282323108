import apiClient from "utils/api";
import { getIdToken } from "../authentication"



export const createVacancy = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/vacancies").create(data, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};



export const getVacancy = async (vacancyId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/vacancies").get(vacancyId, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
};




// export const findVacancies = async (query, token) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let result = await apiClient.service("v1/vacancies").get(vacancyId, {
//         query: {
//           firebaseToken: token,
//         },
//       });

//       resolve(result);
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

