import React from 'react';

// Parents
// import IndustriesTabs from './dash-industries-tabs';
import IndustriesRoutes from './dash-industries-routes';

export default function (props) {

  return (
    // <IndustriesTabs>
      <IndustriesRoutes />
    // </IndustriesTabs>
  )
}
