import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Locations from './routes/locations';
import People from './routes/people';

export default function () {
    return(
        <div>
            <Switch>
                <Route path='/administration/personnel' component={() => <People />} />
                <Route path='/administration/locations' component={() => <Locations />} />
                <Route exact path='/administration' component={() => <Redirect to={`/administration/personnel`} />} />
            </Switch>
        </div>
    )
}