import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Panel from "components/panel";

// import { ColumnChart  } from "react-chartkick";
// import "chartkick/chart.js";

import { Chart } from "react-google-charts";


export default withRouter(function (props) {
  let { projects } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    let rows = [];
    rows.push(["Project", "Estimated", "Actual"]);

    for (let p of projects) {
      let estimate = p.estimatedHours?p.estimatedHours:0
      let actual = p.actualHours?p.actualHours:0
      rows.push([p.title, estimate, actual]);
    }
    setData(rows);
  }, [projects]);

  var options = {
    vAxis: {
      title: "Hours",
    },
    colors: ["#f2a600", "#00796b"],
  };

  return (
    <Panel title={"Project Hours"}>
      {data && ( 
        <Chart 
          chartType="BarChart"
          data={data}
          width="100%"
          height={"100vh"}
          options={options}
        />
      )}
    </Panel>
  );
});
