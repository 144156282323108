import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
// Navigation
import NavCrumbs from "./projects-all-projects-nav-crumbs";
import DeleteIcon from "@material-ui/icons/Delete";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  Stats,
  RefinementList,
} from "react-instantsearch-dom";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    handleNewProject,
    onPressedItem,
    projects,
    status,
    sectionDescription,
    refreshAlgolia,
    onPressedDeleteItem,
  } = props;

  React.useEffect(() => {
    searchClient.clearCache();
  }, []);

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a projects"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const getDaysLeftColor = (daysLeft) => {
    if (daysLeft >= 7) {
      return ["#EBF9F4", "#3AB589"];
    }
    if (daysLeft <= 15 && daysLeft >= 1) {
      return ["#FFF7E6", "#FFB822"];
    }
    if (daysLeft < 1) {
      return ["#FDF4F6", "#DD4166"];
    }
    return ["#ffffff", "#000000"];

  };

  const Hits = ({ hits, refineNext }) => {
    console.log(hits)

    return (
      <TableBody>
        {hits.map((row, index) => {
          var day1 = new Date(row.endDate);
          var day2 = new Date();
          var Difference_In_Time = day1.getTime() - day2.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          let daysLeft = Difference_In_Days.toFixed(0);

          let pillColor = getDaysLeftColor(daysLeft);

          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellWide}
                align="left"
                style={{ height: 70 }}
              >
                {row.title}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.clientTitle}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.consultants ? row.consultants.length : 0}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.estimatedHours ? row.estimatedHours : 0}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.actualHours ? row.actualHours : 0}
              </TableCell>
              {status == "active" && (
                <TableCell
                  onClick={(event) => onPressedItem(row.objectID)}
                  className={classes.cellType}
                  align="left"
                >
                  <span
                    style={{
                      backgroundColor: pillColor[0],
                      width: "auto",
                      padding: 10,
                      borderRadius: 10,
                      color: pillColor[1],
                    }}
                  >
                    {daysLeft} days
                  </span>
                </TableCell>
              )}

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {moment(day1).format("DD/MM/YYYY")}
              </TableCell>

              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  React.useEffect(() => {
    searchClient.clearCache();
  }, []);

  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR PROJECTS"
        loading={loading}
        actionName="Add Project"
        handleAction={handleNewProject}
        sectionDescription={sectionDescription}
      >
        {projects.length > 0 && (
          <InstantSearch
            indexName="Projects"
            searchClient={searchClient}
            refresh={refreshAlgolia}
          >
            <Stats
              translations={{
                stats(nbHits, processingTimeMS) {
                  return (
                    <Typography className={classes.stat}>
                      {nbHits} Projects in {processingTimeMS} ms
                    </Typography>
                  );
                },
              }}
            />
            <RefinementList
              attribute="status"
              operator="or"
              defaultRefinement={status}
            />
            <CustomSearchBox />
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Client
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Consultants
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Est. Hrs
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Act. Hrs
                      </Typography>
                    </TableCell>
                    {status == "active" && (
                      <TableCell align="left">
                        {" "}
                        <Typography className={classes.labelText}>
                          Days Left
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Due Date
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {projects.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any projects yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
