import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { TrainingSessionsContext } from "repository/stores/dashboard/training/training-sessions-provider";
// import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
// import { AllContactsContext } from "repository/stores/dashboard/contacts/contacts-all-provider";
// import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";
import styles from "./dialogues-edit-session-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions
// import { editSession } from "repository/actions/data/dashboard/sessions";
// import { findAllContacts } from "repository/actions/data/dashboard/contacts";
// import { findAllLocations } from "repository/actions/data/dashboard/locations";
// import { findAllPersonnelAlgolia } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, editSession, contacts, locations, personnel } = props;

  // const {sessions, setSessions, editSession} = React.useContext(TrainingSessionsContext);
  // const {contacts, setContacts} = React.useContext(AllContactsContext);
  // const {locations, getAllLocations} = React.useContext(AllLocationsContext);
  // const {personnel, findAllPersonnelAlgolia} = React.useContext(AllPersonnelContext);

  const [title, setTitle] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [lecturer, setLecturer] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [dateError, setDateError] = React.useState(null);
  const [clientError, setClientError] = React.useState(null);
  const [locationError, setLocationError] = React.useState(null);
  const [lecturerError, setLecturerError] = React.useState(null);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDate(data.date);
      setClient(data.contactId);
      setLocation(data.locationId);
      setLecturer(data.lecturerId);
    }
  }, [data]);

  // useEffect(() => {
  //   if (user && user != null) {
  //     if (contacts == null) {
  //       getContacts( user.idToken);
  //     }
  //     if (locations == null) {
  //       getAllLocations( user.idToken);
  //     }
  //     if (personnel == null) {
  //       findAllPersonnelAlgolia();
  //     }
  //   }
  // }, []);

  const handleEditSession = () => {
    if (user && user != null) {
      if (
        date == null ||
        title == null ||
        client == null ||
        location == null ||
        lecturer == null
      ) {
        toast.error(`You have missing information for editing this session`);
        if (date === null) {
          setDateError("Date is required.");
        }

        if (title == null) {
          setTitleError("Title is required.");
        }

        if (client == null) {
          setClientError("Client is required.");
        }

        if (location == null) {
          setLocationError("Location is required.");
        }

        if (lecturer == null) {
          setLecturerError("Lecturer is required.");
        }
      } else {
        let saveData = {
          title: title,
          date: date,
          contactId: client,
          locationId: location,
          lecturerId: lecturer,
        };

        setSaveLoading(true);

        editSession( saveData, itemId, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Session ${saveData.title} successfully edited`);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Add a new training session"
      subtitle="Please fill in the details below to create a new training session."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleEditSession()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Teambuilding, HR sessions"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: 12th May 2018"}
              disabled={saveLoading}
              required
              helperText={dateError}
              error={dateError != null}
              label="Date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl
            className={classes.inputType}
            error={clientError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Client
            </InputLabel>
            {contacts != null && (
              <Select
                elevation={0}
                onChange={(event) => setClient(event.target.value)}
                value={client}
                required
                error={clientError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {contacts.map((item) => {
                  return (
                    <MenuItem value={item._id}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            <FormHelperText>{clientError}</FormHelperText>
          </FormControl>
          <div></div>
          <FormControl
            className={classes.inputType}
            error={locationError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Location
            </InputLabel>
            {locations != null && (
              <Select
                elevation={0}
                onChange={(event) => setLocation(event.target.value)}
                value={location}
                required
                error={locationError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {locations.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            )}
            <FormHelperText>{locationError}</FormHelperText>
          </FormControl>
          <div></div>
          <FormControl
            className={classes.inputType}
            error={lecturerError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Lecturer
            </InputLabel>
            {personnel != null && (
              <Select
                elevation={0}
                onChange={(event) => setLecturer(event.target.value)}
                value={lecturer}
                required
                error={lecturerError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {personnel.map((item) => {
                  return (
                    <MenuItem value={item._id}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            <FormHelperText>{lecturerError}</FormHelperText>
          </FormControl>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
