import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-view-payment-plan-styles";
import {
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
// import { AllPaymentPlansContext } from "repository/stores/dashboard/payment-plans/payment-plans-all-provider";
// import {AllStudentsContext} from 'repository/stores/dashboard/students/students-all-provider';
import EuroIcon from "@material-ui/icons/Euro";
import Moment from "react-moment";

const useStyles = makeStyles(styles);

function ViewPaymentPlanDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, student, data, itemId } = props;
  // const {paymentPlans, setPaymentPlans} = React.useContext(
  //   AllPaymentPlansContext
  // );

  const [newEntry, setNewEntry] = React.useState({
    title: null,
    amount: null,
    date: null,
  });

  const [saveLoading, setSaveLoading] = React.useState(null);

  const [titleError, setTitleError] = React.useState(null);
  const [dateError, setDateError] = React.useState(null);
  const [amountError, setAmountError] = React.useState(null);
  const [openDate, setOpenDate] = React.useState(false);

  const [entries, setEntries] = React.useState([]);

  useEffect(() => {
    if (data && entries && entries.length == 0) {
      let uiEntries = [];

      for (let [i, e] of data.entries.entries()) {
        let newEntry = {};
        newEntry.title = e.title;
        let millis = e.date * 1000;
        newEntry.date = new Date(millis);
        newEntry.amount = e.amount + "";
        uiEntries.push(newEntry);
      }
      setEntries(uiEntries);
    }
  }, [data]);

  useEffect(() => {
    setNewEntry({
      title: "",
      amount: "",
      date: null,
    });
  }, [entries]);

  return (
    <Popup
      title="View payment plan"
      subtitle="Breakdown of payments"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => {
        setClose();
      }}
      handleAction={() => {
        setClose();
      }}
      actionButtonTitle="OK"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Typography variant="h6">
            Cohort : {data ? data.cohort.title : ""}
          </Typography>
          <List className={classes.root}>
            {entries.map((entry, index) => {
              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <EuroIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={entry.title}
                    secondary={
                      <span>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          €{entry.amount} due on{" "}
                          <Moment format="D MMMM, YYYY" date={entry.date} />
                        </Typography>
                      </span>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      }
    />
  );
}

export default withSnackbar(ViewPaymentPlanDialogue);
