import React from 'react';

// Routes
import Routes from './students-all-routes';

export default function (props) {

  const {campus} = props
  
  return (
   <div>
       <Routes campus={campus}/>
   </div>
  )
}
