import React from 'react';
import { withRouter } from 'react-router-dom';

// Children
import DashboardLoader from './dashboard-loader';
import DashboardProviders from './dashboard-providers';
import DashboardView from './dashboard-view';
import DashboardDialogues from './dashboard-dialogues';

export default withRouter(function (props) {

  return (
    <DashboardProviders>
        <DashboardView />
        <DashboardDialogues />
    </DashboardProviders>
  )
});