import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Chip,
  CardContent,
  CardHeader,
  Card,
} from "@material-ui/core";
import Loader from "components/loading";
import FlashIcon from "@material-ui/icons/FlashOnOutlined";

// Styles
import styles from "./section-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    title,
    loading,
    actionName,
    handleAction,
    inputFile,
    handleCapture,
    sectionDescription,
    greyActionButton,
    chipTitle,
    chipState,
    chipHandle,
    handleChipAction,
    fabFunctions,
    backupData
  } = props;

  let inputF;
  if (inputFile != null) {
    if (inputFile === true) {
      inputF = true;
    } else if (inputFile === false) {
      inputF = false;
    }
  } else {
    inputF = false;
  }

  let actionButtonClass = classes.button;

  if (greyActionButton) {
    actionButtonClass = classes.buttonGrey;
  }

  return (
    <Card elevation={0} className={classes.smallCard}>
      <CardHeader
        className={classes.header}
        action={
          actionName &&
          !loading && (
            <div>
              {inputF === true && (
                <div>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id={title}
                    multiple={false}
                    type="file"
                    onChange={handleCapture}
                  />
                  <label htmlFor={title}>
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      className={actionButtonClass}
                      disableElevation
                    >
                      {actionName}
                    </Button>
                  </label>
                </div>
              )}
              {inputF === false && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAction()}
                  className={actionButtonClass}
                  disableElevation
                >
                  {actionName}
                </Button>
              )}
            </div>
          )
        }
        title={
          <span>
            <Typography className={classes.title} display="inline">
              {title.toUpperCase()}
            </Typography>
            {chipHandle == null && chipTitle && chipTitle != null && (
              <Chip label={chipTitle} variant="outlined" />
            )}
            {chipHandle && chipHandle != null && chipTitle && chipTitle != null && (
              <Chip label={chipTitle} variant="outlined" onClick={() => { chipHandle() }} />
            )}
            {/* {backupData && (
               <Button className={classes.backupBtn}>
               Backup All Data
               <svg xmlns="http://www.w3.org/2000/svg" className={classes.spacing} width="20" fill="none" viewBox="0 0 25 25" stroke="currentColor" strokeWidth={2}>
                 <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" className={classes.spacing}  width="20" viewBox="0 0 25 25" fill="currentColor">
                 <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
               </svg>
             </Button>
            )} */}
          </span>
        }
      />
      <CardContent>
        {!loading && (
          <Typography className={classes.dummyText}>
            {sectionDescription}
          </Typography>
        )}
      </CardContent>
      {!loading && fabFunctions && fabFunctions != null && (fabFunctions.length > 0) && (
        <CardContent>
          
          {fabFunctions.map(fun => {
            return (
          <Button variant="outlined" size="small" onClick={fun.handleAction} className={classes.buttonLightweight}>
            <FlashIcon sx={{ mr: 1 }} />
            {fun.name}
          </Button>)
          })}
        </CardContent>

      )}
      <CardContent>
        {loading && <Loader />}
        {!loading && <div>{props.children}</div>}
      </CardContent>
    </Card>
  );
}
