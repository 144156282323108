import React from 'react';

// Parents
import ApplicantsTabs from './dash-applicants-tabs';
import ApplicantsRoutes from './dash-applicants-routes';

export default function (props) {

  return (
    <ApplicantsTabs>
      <ApplicantsRoutes />
    </ApplicantsTabs>
  )
}
