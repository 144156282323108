import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Link, Typography, Paper, Grid } from "@material-ui/core";
import EditDocumentsDialogue from "routes/dashboard/dialogues/personnel/edit-files";
import FileImg from "./file.png";


export default withRouter(function (props) {
  let { person, setPerson, user } = props;
  const [showEditPersoneDialogue, setShowEditPersoneDialogue] = useState(false);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (person) {
      setDocuments(person.files ? person.files : []);
    }
  }, [person]);
  return (
    <div>
      <Panel
        title={"Documents"}
        loading={!person}
        sectionDescription={"Personal information about the person."}
        actionName="Edit"
        handleAction={() => setShowEditPersoneDialogue(true)}
      >
        <Grid
          container
          spacing={6}
          style={{ padding: 10, textAlign: "center" }}
        >
          {documents.map((item) => {
            return (
              <Grid item>
                <Link href={item.link} style={{cursor:"pointer"}} target="blank">
                  <img width="100px" src={FileImg} />
                  <Typography>{item.title}</Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Panel>

      <EditDocumentsDialogue
        isOpen={showEditPersoneDialogue}
        setClose={() => setShowEditPersoneDialogue(false)}
        person={person}
        setPerson={setPerson}
        user={user}
      />
    </div>
  );
});
