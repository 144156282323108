import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";

// Children
import ProjectsView from "./projects-all-projects-view";

import DialogueDeleteProject from "routes/dashboard/dialogues/projects/delete-project";


export default withRouter(function (props) {

  let {status} = props

  const { user } = useContext(AuthenticationContext);
  const { projects, getProjects, deleteProject, setProject } = useContext(AllProjectsContext);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [showDeleteProjectDialogue, setShowDeleteProjectDialogue] =
    React.useState(false);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (projects == null) {
        getProjects(user.idToken);
      }
    }
  }, [projects]);

  return (
    <div>
      <ProjectsView
        loading={!projects}
        onPressedItem={(id) => props.history.push(`${id}`)}
        handleNewProject={() => props.history.push(`${0}`)}
        sectionDescription={"You can manage all of your projects below."}
        projects={projects || []}
        onPressedDeleteItem={(id) => {
          setSelectedId(id);
          setShowDeleteProjectDialogue(true);
        }}
        user={user}
        status={status}
        refreshAlgolia={refreshAlgolia}
      />

      <DialogueDeleteProject
        isOpen={showDeleteProjectDialogue}
        setClose={() => setShowDeleteProjectDialogue(false)}
        user={user}
        projectId={selectedId}
        deleteProject={deleteProject}
        setProject={setProject}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
