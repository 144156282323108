import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Grid,
  List,
  Input,
  Select,
  Button,
  Divider,
  MenuItem,
  ListItem,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";
import Panel from "components/panel";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { withRouter } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const {
    user,
    data,
    isOpen,
    setClose,
    getCohort,
    locations,
    editLecture,
    handleWeekSelect,
    getAllLocations
  } = props;

  const [id, setId] = React.useState(null);

  const [title, setTitle] = React.useState(null);
  const [code, setCode] = React.useState(null);
  const [lecturer, setLecturer] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);

  const [course, setCourse] = React.useState(null);
  const [cohort, setCohort] = React.useState(null);

  const [module, setModule] = React.useState(null);
  const [zoomlink, setZoomlink] = React.useState(null);
  const [type, setType] = React.useState("Attention");
  const [comments, setComments] = React.useState([]);
  const [currentComment, setCurrentComment] = React.useState("");

  const [isLocal, setIsLocal] = React.useState(null);

  const [availableLecturers, setAvailableLecturers] = React.useState([]);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [cohortLoading, setCohortLoading] = React.useState(false);


  const [openDate, setOpenDate] = React.useState(false);
  const [openStartTime, setOpenStartTime] = React.useState(false);
  const [openEndTime, setOpenEndTime] = React.useState(false);

  useEffect(() => {
    if (data) {
      
      setId(data._id)
      setTitle(data.title);
      setDate(data.lectureDate);
      setLocation(data.locationId);
      setType(data.type ? data.type : "");
      setCode(data.code ? data.code : "");
      setModule(data.module ? data.module : "");
      setEndTime(data.lectureEndTime);
      setStartTime(data.lectureStartTime);
      setCohort(data.cohortId ? data.cohortId : "");
      setZoomlink(data.zoomlink ? data.zoomlink : "");
      setComments(data.comments ? data.comments : []);
      setLecturer(data.personnelId ? data.personnelId : "");
      setCohortLoading(true)
      getCohort(data.cohortId).then((results) => {
        setAvailableLecturers(results.lecturers);
        setCohortLoading(false)
      });
      if(locations == null || locations.length == 0){
        getAllLocations()

      }
    }
  }, [data]);

  const handleSaveOwnerDetails = () => {
    if (
      date == null ||
      startTime == null ||
      endTime == null ||
      location == null ||
      title == null
    ) {
      toast.error(`You have missing information for editing this lecture`);
      if (date == null) {
        toast.error("Lecture date is required.");
      }
      if (startTime == null) {
        toast.error("Lecture start time is required.");
      }
      if (endTime == null) {
        toast.error("Lecture end time is required.");
      }
      if (location == null) {
        toast.error("Location is required.");
      }
      if (title == null) {
        toast.error("Title is required.");
      }
    } else {
      let dateObject = new Date(date);
      let startTimeObject = new Date(startTime);
      let endTimeObject = new Date(endTime);

      let dateObj = moment(dateObject).format("YYYY-MM-DD");
      let startTimeObj = moment(startTimeObject).format("HH:mm") + ":00";
      let endTimeObj = moment(endTimeObject).format("HH:mm") + ":00";

      let lectureStartTime = dateObj + "T" + startTimeObj;
      let lectureEndTime = dateObj + "T" + endTimeObj;

      let saveData = {
        code: code,
        type: type,
        title: title,
        module: module,
        isLocal: isLocal,
        comments: comments,
        zoomlink: zoomlink,
        locationId: location,
        personnelId: lecturer,
        cohortId: cohort,
        lectureEndTime: lectureEndTime,
        lectureStartTime: lectureStartTime,
        lectureDate: moment(dateObject).valueOf(),
      };

      setSaveLoading(true);

      editLecture(saveData, id, user.idToken)
        .then((result) => {
          toast.success(`Lecture ${saveData.title} successfully edited`);
          setClose();
          setSaveLoading(false);
          if(handleWeekSelect)handleWeekSelect(dateObject);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleNewComment = () => {
    let newComments = [...comments];
    newComments.push(currentComment);
    setComments(newComments);
    setCurrentComment("");
  };
  const handleRemoveComment = (index) => {
    let newComments = [...comments];
    newComments.splice(index, 1);
    setComments(newComments);
  };

  return (
    <Popup
      title="Edit existing Lecture"
      subtitle="You can modify the following Lecture details."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"xl"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div style={{ height: "80vh", padding: "20px 10px" }}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ borderRight: "solid 1px gray" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Panel>
                    <Grid container spacing={4} style={{ padding: 30 }}>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            placeholder={"Ex: Lecture Name"}
                            disabled={saveLoading}
                            required
                            label="Title"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            margin="normal"
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            placeholder={"Ex: Lecture Code"}
                            disabled={saveLoading}
                            label="Code"
                            value={code}
                            onChange={(event) => setCode(event.target.value)}
                            margin="normal"
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            placeholder={"Ex: Module"}
                            disabled={saveLoading}
                            label="Module"
                            value={module}
                            onChange={(event) => setModule(event.target.value)}
                            margin="normal"
                          ></TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
              </Grid>
              <div style={{ marginTop: 30 }}></div>

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Panel>
                    <div style={{ padding: 20 }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className={classes.formControl}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            required
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Lecture Date"
                            value={date}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            onClick={() => setOpenDate(true)}
                            onOpen={() => setOpenDate(true)}
                            onClose={() => setOpenDate(false)}
                            open={openDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </FormControl>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                              <TimePicker
                                variant="inline"
                                required
                                format="HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Lecture Start Time"
                                value={startTime}
                                open={openStartTime}
                                onChange={(newValue) => {
                                  setStartTime(newValue);
                                }}
                                onClick={() => setOpenStartTime(true)}
                                onOpen={() => setOpenStartTime(true)}
                                onClose={() => setOpenStartTime(false)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                              <TimePicker
                                variant="inline"
                                required
                                format="HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Lecture End Time"
                                value={endTime}
                                open={openEndTime}
                                onChange={(newValue) => {
                                  setEndTime(newValue);
                                }}
                                onClick={() => setOpenEndTime(true)}
                                onOpen={() => setOpenEndTime(true)}
                                onClose={() => setOpenEndTime(false)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Panel>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Panel>
                    <Grid container spacing={4} style={{ padding: 20 }}>
                      <Grid item xs={6}>
                        <FormControl className={classes.inputType}>
                          <InputLabel required disabled={saveLoading}>
                            Lecturer
                          </InputLabel>

                          <Select
                            elevation={0}
                            label="Lecturer"
                            onChange={(event) => {
                              setLecturer(event.target.value);
                            }}
                            value={lecturer}
                            disabled={saveLoading}
                            input={<Input id="type-menu" />}
                          >
                            <MenuItem value={"no-lecturer"}>
                              No Lecturer
                            </MenuItem>

                            {availableLecturers.map((item) => {
                              return (
                                <MenuItem value={item.person._id}>
                                  {item.person.firstName} {item.person.lastName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {cohortLoading && <p>Loading...</p>}
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl className={classes.inputType}>
                          <InputLabel required disabled={saveLoading}>
                            Location
                          </InputLabel>
                          <Select
                            elevation={0}
                            label="location"
                            onChange={(event) =>
                              setLocation(event.target.value)
                            }
                            value={location}
                            disabled={saveLoading}
                            input={<Input id="type-menu" />}
                          >
                            <MenuItem value={"no-room"}>No Room</MenuItem>
                            {locations.map((item) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div></div>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.inputType}>
                          <InputLabel required disabled={saveLoading}>
                            Type
                          </InputLabel>
                          <Select
                            elevation={0}
                            onChange={(event) => setType(event.target.value)}
                            value={type}
                            disabled={saveLoading}
                            input={<Input id="type-menu" />}
                          >
                            <MenuItem value="Tutorial">Tutorial</MenuItem>;
                            <MenuItem value="First Lecture">
                              First Lecture
                            </MenuItem>
                            ;
                            <MenuItem value="Last Lecture">
                              Last Lecture
                            </MenuItem>
                            ;<MenuItem value="Attention">Attention</MenuItem>;
                          </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            placeholder={"Ex: http://....."}
                            disabled={saveLoading}
                            label="Zoom Code"
                            value={zoomlink}
                            onChange={(event) =>
                              setZoomlink(event.target.value)
                            }
                            margin="normal"
                          ></TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
              </Grid>

              <div style={{ marginTop: 30 }}></div>
              <Grid container>
                <Grid item xs={12}>
                  <Panel>
                    <Grid container style={{ padding: 20 }}>
                      <Grid item xs={7}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            placeholder={"Add New Comment"}
                            disabled={saveLoading}
                            label="Comment"
                            value={currentComment}
                            onChange={(event) =>
                              setCurrentComment(event.target.value)
                            }
                            margin="normal"
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            handleNewComment();
                          }}
                          disabled={currentComment == ""}
                          style={{
                            marginTop: 30,
                            marginLeft: 10,
                            background: "#f7f7f7",
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    <List>
                      {comments.map((item, index) => {
                        return (
                          <Grid container>
                            <Grid item xs={8}>
                              <ListItem key={index}>• {item}</ListItem>
                            </Grid>
                            <Grid item>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  handleRemoveComment(index);
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </List>
                  </Panel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
