import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./proposals-single-proposals-nav-crumbs";
import { Grid } from "@material-ui/core";

// Components
import Details from "./details";
import Time from "./time";
import Comments from "./comments";
import Prices from "./prices";

import { AllProposalsContext } from "repository/stores/dashboard/proposals/proposals-all-provider";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";

export default withRouter(function (props) {
  const proposalId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const [proposal, setProposal] = useState(null);

  const { getConsultants } = useContext(AllConsultantsContext);
  const { getProposal, editProposal, findProposalTimings } =
    useContext(AllProposalsContext);

  useEffect(() => {
    if (user && user != null && proposalId && proposalId != null) {
      try {
        getProposal(proposalId, user.idToken).then((result) => {
          setProposal(result);
        });
      } catch (e) {
        alert("Can't get this proposal.");
      }
    }
  }, []);

  return (
    <NavCrumbs proposal={proposal}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Details
            proposal={proposal}
            setProposal={setProposal}
            user={user}
            editProposal={editProposal}
          />
          <div style={{ marginBottom: 30 }}></div>
          <Comments
            proposal={proposal}
            setProposal={setProposal}
            user={user}
            editProposal={editProposal}
          />
        </Grid>
        <Grid item xs={5}>
          <Time
            proposal={proposal}
            user={user}
            getConsultants={getConsultants}
            findProposalTimings={findProposalTimings}
          />
          {user.roles.indexOf("admin") >= 0 && (
            <Prices
              proposal={proposal}
              setProposal={setProposal}
              user={user}
              getConsultants={getConsultants}
              editProposal={editProposal}
              findProposalTimings={findProposalTimings}
            />
          )}
        </Grid>
      </Grid>
    </NavCrumbs>
  );
});
