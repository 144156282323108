import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// import { SingleCohortContext } from "repository/stores/dashboard/cohorts/cohorts-single-provider";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, cohort, user, generateTranscript } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);
    // const { generateTranscript } = React.useContext(SingleCohortContext);

    const handleGenerateTranscript = () => {
      setSaveLoading(true);

      let canvasCourseId = cohort.canvasUrl.substring(
        cohort.canvasUrl.lastIndexOf("/") + 1
      );

      generateTranscript(canvasCourseId, user.email, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`Canvas Transcript will be sent to your e-mail.`);
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong.");
          toast.dark(error.message);
          setSaveLoading(false);
        });
    };

    return (
      <Popup
        title="Generate Transcript"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleGenerateTranscript()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Ready to generate a transcript for this cohort?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
