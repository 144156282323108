import React from 'react';

// Parents
import StudentsTabs from './dash-general-tabs';
import StudentsRoutes from './dash-general-routes';

export default function (props) {

  return (
    <StudentsTabs>
      <StudentsRoutes />
    </StudentsTabs>
  )
}
