import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../dialogue-styles";
import {
  MenuItem,
  TextField,
  Typography,
  FormControl,
  Grid,
  Select,
  InputLabel,
  Input,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import moment from "moment";

import { getSearchClient } from "utils/algolia";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const searchClient = getSearchClient();
  let proposalsIndex = searchClient.initIndex("Proposals");

  const { setClose, isOpen, user, consultant, date, createProposalTiming } =
    props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [existingProposals, setExistingProposals] = React.useState(null);
  const [showHits, setShowHits] = React.useState(false);
  const [proposalTitle, setProposalTitle] = React.useState(null);

  const [selectedProposal, setSelectedProposal] = React.useState(null);
  const [notSelected, setNotSelected] = React.useState(true);

  const [task, setTask] = React.useState(null);
  const [duration, setDuration] = React.useState(null);

  const handleCreateTime = () => {
    if (user && user != null) {
      if (selectedProposal == null || task == null || duration == null) {
        toast.error("Missing Info");
      } else {
        let data = {
          proposalId: selectedProposal.objectID,
          consultantId: consultant._id,
          task: task,
          duration: parseFloat(duration),
          date: moment(date).format("YYYY-MM-DD")+"T09:00:00",
          month: moment(date).format("MM-YYYY")
        };

        setSaveLoading(true);

        createProposalTiming(data, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Created Timing :  ${selectedProposal.title} `);
            setSelectedProposal(null);
            setTask(null);
            setDuration(null);
            window.location.reload()
          })
          .catch((e) => {
            setSaveLoading(false);
            console.log(e.message);
          });
      }
    }
  };

  const onSearch = (query) => {
    setShowHits(true);
    setNotSelected(true);
    setSelectedProposal(null);

    proposalsIndex.search(query, { hitsPerPage: 3 }).then(({ hits }) => {
      setExistingProposals(hits);
    });
  };

  return (
    <Popup
      title="Add a new Proposal Time"
      subtitle={`Selected Date --- ${moment(date).format("DD MMM YYYY")}`}
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateTime()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Proposal"}
                  required
                  label="Title"
                  value={proposalTitle}
                  onChange={(event) => {
                    setProposalTitle(event.target.value);
                    onSearch(event.target.value);
                  }}
                  margin="normal"
                ></TextField>
              </FormControl>

              <FormControl className={classes.formControl}>
                {existingProposals && showHits && notSelected && (
                  <>
                    <p>Existing Proposals:</p>
                    <Typography
                      variant="caption"
                      style={{ marginTop: "-15px" }}
                    ></Typography>

                    {existingProposals.map((item, index) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            setSelectedProposal(item);
                            setProposalTitle(item.title);
                            setNotSelected(false);
                          }}
                          value={`${item.title} `}
                          style={{ fontSize: "15px" }}
                        >{`· ${item.title}`}</MenuItem>
                      );
                    })}
                  </>
                )}
              </FormControl>

              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Task
                </InputLabel>
                <Select
                  elevation={0}
                  onChange={(event) => setTask(event.target.value)}
                  value={task}
                  required
                  disabled={saveLoading}
                  input={<Input id="type-menu" />}
                >
                  <MenuItem value={"Meeting"}>{"Meeting"}</MenuItem>
                  <MenuItem value={"Research"}>{"Research"}</MenuItem>
                  <MenuItem value={"Writing"}>{"Writing"}</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: 8"}
                  required
                  type="number"
                  label="Hours"
                  value={duration}
                  onChange={(event) => {
                    setDuration(event.target.value);
                  }}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {selectedProposal && (
                <div>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Type - 
                    <span
                      style={{
                        color: "#564181",
                      }}
                    >  {" "}
                      {selectedProposal.type}
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 18,
                      marginBottom: 20,
                    }}
                  >
                    Submission Date - 
                    <span
                      style={{
                        color: "#564181",
                      }}
                    >
                      {" "}
                      {moment(selectedProposal.submissionDate).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
