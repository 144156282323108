
import React, { useState, useContext } from "react";
import apiClient from "utils/api";

import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  lectures: null,
  setLectures: {},
  getAllLecturesAllCohorts: {},
  createNewLecture: {},
  editLecture: {},
  deleteLecture: {},
  getAllLecturesByQuery: {},
  bulkCreateLecture: {},
  bulkDeleteLecture: {}
};

export const AllLecturesContext = React.createContext(ContextProps);

export const AllLecturesProvider = ({ children }) => {
  const [lectures, setLectures] = useState(null);

  const { getIdToken } = useContext(IdTokenContext)


  const getAllLecturesAllCohorts = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/lectures").find({
            query: {
              firebaseToken: token,
            },
          });

          setLectures(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const getAllLecturesByQuery = (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/lectures").find({
            query: {
              ...query,
              firebaseToken: token,
            },
          });

          setLectures(result.data);
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewLecture = async (lecture, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/lectures").create(lecture, {
            query: {
              firebaseToken: token,
            },
          });

          if (lectures == null) {
            setLectures(result);
          } else {
            let newLecture = lectures
            newLecture.push(result)
            setLectures(newLecture);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  

  const bulkCreateLecture = async (lectureUri) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/bulk").create(lectureUri, {
            query: {
              firebaseToken: token,
              provider: "bulk-lectures"
            },
          });
          
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const bulkDeleteLecture = async (cohortId) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/bulk").create({cohortId:cohortId},{
            query: {
              firebaseToken: token,
              provider: "bulk-delete-lectures"
            },
          });
          
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editLecture = async (
    data,
    lectureId,
    token
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedLecture = await apiClient
            .service("v1/lectures")
            .patch(lectureId, data, {
              query: {
                firebaseToken: token,
              },
            });

          if(lectures){
            let newData = lectures
            for (const [i, v] of newData.entries()) {
              if (v._id === lectureId) {
                newData[i] = data;
              }
            }
            setLectures(newData);
          }
         

          resolve(updatedLecture);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteLecture = async (
    lectureId,
    token
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/lectures").remove(lectureId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };





  return (
    <AllLecturesContext.Provider
      value={{
        lectures,
        setLectures,
        getAllLecturesAllCohorts,
        createNewLecture,
        editLecture,
        deleteLecture,
        getAllLecturesByQuery,
        bulkCreateLecture,
        bulkDeleteLecture
      }}
    >
      {children}
    </AllLecturesContext.Provider>
  );
};
