import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Input,
  Typography,
  TextField,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";

import { toast } from "react-toastify";

import { getSearchClient } from "utils/algolia";
const searchClient = getSearchClient();
const studentsIndex = searchClient.initIndex('Students');

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();
  const {
    setClose,
    isOpen,
    user,
    createNewTicket
  } = props;

  const [title, setTitle] = React.useState(null);
  const [studentName, setStudentName] = React.useState(null);
  // const [cohortTitle, setCohortTitle] = React.useState(null);


  const [students, setStudents] = React.useState([]);
  const [showHits, setShowHits] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(null);


  const [saveLoading, setSaveLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");


  const handleCreateTicket = () => {
    if (user && user != null) {
      if (
        selectedStudent == null ||
        // cohortTitle == null ||
        title == null
      ) {
        toast.error(`Oops! Something went wrong`);
        let errorMessage = ""
        if (selectedStudent == null) {
          errorMessage = "Student is required."
        }

        // if (cohortTitle == null) {
        //   errorMessage = "Cohort is required."
        // }

        if (title == null) {
          errorMessage = "Title is required."
        }
        toast.error(errorMessage);

      } else {
       
        let payload = {
          title: title,
          student:{
            studentId: selectedStudent.objectID,
            studentName: selectedStudent.firstName+" "+selectedStudent.lastName,
            cohortIds: selectedStudent.cohortIds
          },
          notes:[]
        }
        setSaveLoading(true);
        toast.dark(`Creating ${payload.title}`);

        createNewTicket(payload, user.idToken)
          .then((result) => {
            setTimeout(()=>{
              window.location.href = "tickets/"+result._id
            },700)
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };


 const handleSelectedStudent = (item) => {
  setSelectedStudent(item); 
  setShowHits(false)
 }

  const onSearch = (query) => {
    setShowHits(true)
    setQuery(query)
    studentsIndex.search(query,
      {hitsPerPage: 5}).then(({ hits }) => {
      setStudents(hits)
    });
  }

 
  return (
    <Popup
      title="Open a new Ticket"
      subtitle="Please fill in the details below to add a new ticket."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateTicket()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
           <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Ticket Title"}
              disabled={saveLoading}
              required
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
         
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: John Doe"}
              disabled={saveLoading}
              required
              label="Search for students"
              value={query}
              onChange={(event) => onSearch(event.target.value)}
              margin="normal"
              className={classes.searchBar}

            ></TextField>
          </FormControl>
          <FormControl className={classes.formControl}>
            {students && showHits &&
              <>
                {students.map((item, index) => {
                  return <MenuItem value={`${item.firstName} ${item.lastName}`} onClick={() => { handleSelectedStudent(item)}}>{`${item.firstName} ${item.lastName} - ${item.email}`}</MenuItem>;
                })}</>
            }
          </FormControl>

          {selectedStudent && <Typography variant={"h6"}>
            Selected Student:  {`${selectedStudent.firstName} ${selectedStudent.lastName}`}
          </Typography>}

        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
