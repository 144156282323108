import React, { useState,useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectTimingsView from "./single-project-timings-view";


export default withRouter(function (props) {
  let { loading, project,setProject, projectId, isNewProject, user } = props;
  


  return (
    <div>
      <SingleProjectTimingsView
        loading={loading}
        panelDescription={"Manage Project Timings"}
        user={user}
        project={project}
        setProject={setProject}
        isNewProject={isNewProject}
      />

     
    </div>
  );
});
