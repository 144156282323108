import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllApplicantsContext } from "repository/stores/dashboard/applicants/applicants-all-provider";
import { SingleApplicantContext } from "repository/stores/dashboard/students/students-single-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";

// Children
import ApplicantsView from "./students-all-applicants-view";

// Dialogues
import DialogueCreateApplicant from "routes/dashboard/dialogues/applicants/create-applicants";

export default withRouter(function (props) {
  const { campus } = props;
  const {
    applicants,
    getApplicant,
    getAllApplicants,
    createNewApplicant,
    bulkExportApplicants,
  } = useContext(AllApplicantsContext);
  const { courses, getCourses } = React.useContext(AllCoursesContext);

  const { user } = useContext(AuthenticationContext);

  const [showNewApplicantDialogue, setShowNewApplicantDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (applicants == null) {
        getAllApplicants(user.idToken);
      }
    }
  }, [applicants, user]);

  // Editing Item
  useEffect(() => {
    if (user && user != null) {
      if (applicants) {
        if (selectedId && editingItem == null) {
          getApplicant(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId, user]);

  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, [user]);

  const handleExtract = async () => {
    bulkExportApplicants()
      .then((data) => {

        var a = document.createElement("a"); 
        a.href = "data:application/octet-stream;base64," + data; 
        a.download = "applicants.xlsx"; 
        a.click(); 
      })
      .catch((e) => {
        console.log(`Error: ${e.message}`);
      });
  };

  return (
    <div>
      <ApplicantsView
        loading={!applicants}
        onPressedItem={(id) => props.history.push(`all/${id}`)}
        sectionDescription={"You can manage all of your applicants below."}
        applicants={applicants || []}
        bulkExportApplicants={handleExtract}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
        handleNewApplicant={() => setShowNewApplicantDialogue(true)}
      />

      <DialogueCreateApplicant
        isOpen={showNewApplicantDialogue}
        setClose={() => setShowNewApplicantDialogue(false)}
        user={user}
        createNewApplicant={createNewApplicant}
        setRefreshAlgolia={setRefreshAlgolia}
      />
    </div>
  );
});
