import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleModulesCostView from "./single-module-cost-view";


export default withRouter(function (props) {
  let {
    loading,
    user,
    programme,
    setProgramme,
    isNewProgramme,
    moduleTotalCost,
    setModuleTotalCost
  } = props;



  return (
    <div>
      <SingleModulesCostView
        loading={loading}
        programme={programme}
        setProgramme={setProgramme}
        isNewProgramme={isNewProgramme}
        panelDescription={"Manage Module Costs"}
        user={user}
        moduleTotalCost={moduleTotalCost}
        setModuleTotalCost={setModuleTotalCost}
      />

    </div>
  );
});
