import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  contact: null,
  setContact: {},
  loadingContact: false,
  getContact: {},
};

export const SingleContactContext = React.createContext(ContextProps);

export const SingleContactProvider = ({ children }) => {
  const [contact, setContact] = useState(null);
  const [loadingContact, setLoadingContact] = useState(false);

  const { getIdToken } = useContext(IdTokenContext)


  const getContact = (id, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingContact(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/contacts").get(id, {
            query: {
              firebaseToken: token,
            },
          });
          setLoadingContact(false);
          setContact(result);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  }



  return (
    <SingleContactContext.Provider
      value={{
        contact,
        setContact,
        loadingContact,
        getContact,
      }}
    >
      {children}
    </SingleContactContext.Provider>
  );
};


