import React from 'react';

// Parents
import AdministrationTabs from './dash-administration-tabs';
import AdministrationRoutes from './dash-administration-routes';

export default function (props) {

  return (
    <AdministrationTabs>
      <AdministrationRoutes />
    </AdministrationTabs>
  )
}
