import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProjectPersonnelView from "./single-project-personnel-view-new";


export default withRouter(function (props) {
  let {
    loading,
    project,
    setProject,
    isNewProject,
    consultants, getConsultants, getConsultant, editConsultant,
    user,
  } = props;



  
  useEffect(() => {
    if (!loading) {
        getConsultants()
    }
  }, [loading])


  return (
    <div>
      <SingleProjectPersonnelView
        loading={loading}
        panelDescription={"Assign Personnel working on this project"}
        user={user}
        allConsultants={consultants}
        getConsultant={getConsultant}
        editConsultant={editConsultant}
        project={project}
        setProject={setProject}
        isNewProject={isNewProject}
      />

    </div>
  );
});
