import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { Typography, Paper, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import { Lens } from "@material-ui/icons";
// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { proposal, user, editProposal, setProposal, setShowEditProposalDialogue } =
    props;
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editStatus, setEditStatus] = React.useState(false);
  const [proposalStatus, setProposalStatus] = useState(null);

  useEffect(() => {
    if (proposal) {
      setTitle(proposal.title);
      setProposalStatus(proposal.status);
    }
  }, [proposal]);

  const handleStatusChange = (item) => {
    setProposalStatus(item);
    setProposal({ ...proposal, status: item });

    let payload = {
      status: item,
    };

    if (item == "Successful") {
      payload = { ...payload, successfulAt: moment().valueOf() };
    } else {
      payload = { ...payload, successfulAt: null };
    }

    editProposal(proposal._id, payload).then((result) => {
      setEditStatus(false);
    });
  };

  const onSetStatus = (val) => (event) => {
    setEditStatus(true);
    setAnchorEl(event.currentTarget);
    setProposalStatus(val);
  };

  const statusColourSelector = (colour, fill) => {
    if (colour === "Pending") {
      return fill ? "#e8e8e8" : "#e8e8e8";
    }
    if (colour === "Successful") {
      return fill ? "#64e986" : "#00d18b80";
    }
    if (colour === "Revised") {
      return fill ? "#ffbf00" : "#ffbf0080";
    }
    if (colour === "Unsuccessful") {
      return fill ? "#800000" : "#80000070";
    }
  };

  return (
    <Panel
      title={title}
      loading={!proposal}
      actionName="Edit proposal"
      handleAction={() => {
        setShowEditProposalDialogue(true);
      }}
      sectionDescription={"Detailed information about the proposal."}
    >
      {proposal != null && (
        <div>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Title</Typography>
            <Typography className={classes.fontRegular}>
              {proposal.title}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Client</Typography>
            <Typography className={classes.fontRegular}>
              {proposal.clientTitle}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Type</Typography>
            <Typography className={classes.fontRegular}>
              {proposal.type}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>
              Submission Date
            </Typography>
            <Typography className={classes.fontRegular}>
              {moment(proposal.submissionDate).format("DD MMM YYYY")}
            </Typography>
          </Paper>

          <div
            onClick={onSetStatus(proposalStatus)}
            style={{
              width: 120,
              padding: "10px 0",
              borderRadius: 10,
              textAlign: "center",
              backgroundColor: statusColourSelector(proposalStatus, false),
            }}
          >
            {proposal.status}
          </div>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={editStatus}
            onClose={() => setEditStatus(false)}
            PaperProps={{
              style: {
                maxHeight: 45 * 4.5,
                width: 200,
              },
            }}
          >
            {["Pending", "Successful", "Revised","Unsuccessful"].map((item) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleStatusChange(item);
                  }}
                >
                  <Lens
                    className={classes.statusIcon}
                    style={{
                      fill: statusColourSelector(item, true),
                      borderRadius: 10,
                      marginRight: 10,
                    }}
                  />
                  {item}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )}
    </Panel>
  );
});
