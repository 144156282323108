import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { person } = props;

  let crumbs = [
        {name: 'Administration', path: 'administration'},
        {name: 'Personnel', path: 'administration/personnel'},
        {name: '...', path: '#'},
  ];


  if (person != null) {
    crumbs[2] =  { name: `${person.firstName} ${person.lastName}`, path: `administration/personnel/${person._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
