const styles = theme => ({
    divider: {
        marginTop: -18,
        marginBottom: 18,
        width: 15,
        height: 2,
        backgroundColor: theme.palette.secondary.main,
    },
    smallCard: {
        minWidth: 120,
        padding: "0 10px",
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
        marginBottom: 24,
        height:"100%"
    },
    title: {
        fontFamily: 'proximaNova-bold',
        color: theme.palette.primary.light,
        fontSize: 18,
        marginTop: -5,
        marginRight: 15
    },
    bottomContent: {
        marginBottom: -36
    }, 
    button: {
        marginLeft: 'auto',
        boxShadow: 'none',
        marginRight: 5,
        marginTop: 3,
        paddingTop: 8,
        fontSize: 12,
        width: 95,
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'proximaNova-bold',
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
    buttonGrey: {
        marginLeft: 'auto',
        boxShadow: 'none',
        marginRight: 5,
        marginTop: 3,
        paddingTop: 8,
        fontSize: 12,
        width: 95,
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'proximaNova-bold',
        backgroundColor: 'rgba(130,130,130,0.4)',
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: 'rgba(130,130,130,0.6)',
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
    buttonLightweight: {
      marginLeft: 'auto',
      boxShadow: 'none',
      marginRight: 5,
      marginTop: 3,
      paddingTop: 8,
      fontSize: 12,
      
  },
    input: {
        display: 'none'
    },
    dummyText: {
        marginTop: -25,
        marginBottom: 0,
        marginRight: '30%',
        fontSize: 13,
        fontFamily: 'proximaNova-italic',
        lineHeight: 1.25,
        color: 'rgba(134,134,134,1)'
    },
    header: {
        minHeight: 65
    },
    backupBtn: {
        boxShadow: 'none',
        marginRight: 2,
        marginLeft: 35,
        marginTop: 3,
        marginBottom: 3,
        paddingTop: 8,
        fontSize: 15,
        width: 200,
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'proximaNova-bold',
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
    spacing:{
      marginRight: 5,
      marginLeft: 5,
  
    }
  });
  
  export default styles;
  