import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleLocation } from "repository/actions/data/dashboard/locations";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./administration-single-location-nav-crumbs";

// Components
import Details from "./details";
import Lectures from "./lectures";
import Sessions from "./sessions";

export default withRouter(function (props) {
  const locationId = props.match.params.id;
  const {user} = useContext( AuthenticationContext);


  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    getSingleLocation(locationId, user.idToken).then((result) => {
      setLocationData(result);
    });
  }, []);

  return (
    <NavCrumbs locationData={locationData}>
      <Details locationData={locationData} />
      <Lectures locationData={locationData} />
      <Sessions locationData={locationData} />
    </NavCrumbs>
  );
});
