import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let crumbs = [
        {name: 'Vacancies', path: 'vacancies'},
        {name: 'All', path: 'vacancies/all'},

  ];

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
