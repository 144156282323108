import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";

import CandidatesView from "./candidate-single-status-view"
import DialogueAssignCandidate from "routes/dashboard/dialogues/candidates/assign-candidate";
import DialogueRemoveCandidate from "routes/dashboard/dialogues/candidates/remove-candidate";

export default withRouter(function (props) {

  let { user,
    vacancy,
    vacancyId,
    status,
    columnTitle,
    refreshAlgolia,
    setRefreshAlgolia,
    setHeadCounts,
    setVacancy,
    editVacancy,
    getVacancy
  } = props


  const { editCandidate, loadingCandidates } = useContext(AllCandidatesContext);

  const [showAssignCandidateDialogue, setShowAssignCandidateDialogue] = useState(false);
  const [showRemoveCandidateDialogue, setShowRemoveCandidateDialogue] = useState(false);

  const [applications, setApplications] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(false);



  return (
    <div>
      <CandidatesView
        loading={!vacancy}
        sectionDescription={"You can manage all of your clients below."}
        vacancy={vacancy}
        vacancyId={vacancyId}
        user={user}
        status={status}
        columnTitle={columnTitle}
        setHeadCounts={setHeadCounts}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}
        setShowAssignCandidateDialogue={setShowAssignCandidateDialogue}
        setApplications={setApplications}
        onPressedDeleteItem={(id) => {setShowRemoveCandidateDialogue(true);
          setSelectedCandidateId(id)}}
      />



      <DialogueAssignCandidate
        isOpen={showAssignCandidateDialogue}
        setClose={() => setShowAssignCandidateDialogue(false)}
        user={user}
        vacancy={vacancy}
        vacancyId={vacancyId}
        editVacancy={editVacancy}
        editCandidate={editCandidate}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueRemoveCandidate
        isOpen={showRemoveCandidateDialogue}
        setClose={() => setShowRemoveCandidateDialogue(false)}
        user={user}
        vacancy={vacancy}
        vacancyId={vacancyId}
        editVacancy={editVacancy}
        editCandidate={editCandidate}
        applications={applications}
        candidateId={selectedCandidateId}
        setRefreshAlgolia={setRefreshAlgolia}
      />

    </div>
  );
});
