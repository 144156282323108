import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleVacancyDetailsView from "./single-vacancy-details-view";

// Dialogues
import DialogueEditVacancy from "routes/dashboard/dialogues/vacancies/edit-vacancy";



export default withRouter(function (props) {
  let {
    vacancy,
    setVacancy,
    vacancyId,
    headCounts,
    user,
    editVacancy, 
    getVacancy
  } = props;


  const [showEditVacancyDialogue, setShowEditVacancyDialogue] = useState(false);


  return (
    <div>
      <SingleVacancyDetailsView
        loading={!vacancy}
        sectionDescription={"Detailed information about the vacancy."}
        user={user}
        headCounts={headCounts}
        vacancy={vacancy}
        vacancyId={vacancyId}
        editVacancy={editVacancy}
        setVacancy={setVacancy}
        setShowEditVacancyDialogue={setShowEditVacancyDialogue}
      />

      <DialogueEditVacancy
        isOpen={showEditVacancyDialogue}
        setClose={() => setShowEditVacancyDialogue(false)}
        user={user}
        vacancy={vacancy}
        vacancyId={vacancyId}
        editVacancy={editVacancy}
        getVacancy={getVacancy}
        setVacancy={setVacancy}
      />

    </div>
  );
});
