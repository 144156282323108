export default [
  { date: "1 Jan", title: "New Year's Day" },
  { date: "10 Feb", title: "Feast of St Paul's Shipwreck" },
  { date: "19 Mar", title: "Feast of St Joseph" },
  { date: "31 Mar", title: "Freedom Day" },
  { date: "15 Apr", title: "Good Friday" },
  { date: "1 May", title: "Workers' Day" },
  { date: "7 Jun", title: "Sette Giugno" },
  { date: "29 Jun", title: "Feast of St Peter and St Paul" },
  { date: "15 Aug", title: "Feast of the Assumption" },
  { date: "8 Sep", title: "Victory Day" },
  { date: "21 Sep", title: "Independence Day" },
  { date: "8 Dec", title: "Immaculate Conception" },
  { date: "13 Dec", title: "Republic Day" },
  { date: "25 Dec", title: "Christmas Day" },
];
