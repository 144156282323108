import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllTicketsContext } from "repository/stores/dashboard/tickets/tickets-all-provider";

// Children
import TicketsView from "./tickets-all-students-view";

// Dialogues
import DialogueCreateTicket from "routes/dashboard/dialogues/tickets/create-ticket";
import DialogueEditStudent from "routes/dashboard/dialogues/students/edit-student";
import DialogueDeleteStudent from "routes/dashboard/dialogues/students/delete-student";


export default withRouter(function (props) {
  const {createNewTicket} = useContext(AllTicketsContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewTicketDialogue, setShowNewTicketDialogue] = useState(false);
  const [showDeleteTicketDialogue, setShowDeleteTicketDialogue] =
    useState(false);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)

  let tickets=[""]
  return (
    <div>
      <TicketsView
        loading={!tickets}
        onPressedItem={(id) => props.history.push(`tickets/${id}`)}
        sectionDescription={"You can manage all of your tickets below."}
        tickets={tickets || []}
        toggleAddTicketForm={() => setShowNewTicketDialogue(true)}
        user={user}
        refreshAlgolia={refreshAlgolia}
      />

      <DialogueCreateTicket
       isOpen={showNewTicketDialogue}
       user={user}
       setClose={() => setShowNewTicketDialogue(false)}
       createNewTicket={createNewTicket}
       setRefreshAlgolia={setRefreshAlgolia}
      />
     
    </div>
  );
});
