import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Paper
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { locationData } = props;
  const classes = useStyles();

  let title = "...";
  let address = "";
  if (locationData) {
    title = `${locationData.title}`;
    address = locationData.address;
  }
  return (
    <Section
      title={title}
      loading={false}
      sectionDescription={"Information about the selected location."}
    >
      <Paper className={classes.miniSection}>
        <Typography className={classes.fontBold}>Address</Typography>
        <Typography className={classes.fontRegular}>{address}</Typography>
      </Paper>
    </Section>
  );
});
