import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import SingleCohortLectureView from "./single-cohort-lectures-view";

// Dialogues
import DialogueCreateLecture from "routes/dashboard/dialogues/lectures/create-lecture";
import DialogueEditLecture from "routes/dashboard/dialogues/lectures/edit-lecture-new";
import DialogueDeleteLecture from "routes/dashboard/dialogues/lectures/delete-lecture";
import DialogueBulkDeleteLecture from "routes/dashboard/dialogues/lectures/delete-bulk-lectures";

export default withRouter(function (props) {
  let {
    cohort,
    setCohort,
    user,
    editCohort,
    getCohort,
    bulkCreateLecture,
    bulkDeleteLecture,
    getAllLocations,
    locations,
    personnel,
    findAllPersonnelAlgolia,
    editLecture,
    deleteLecture,
  } = props;

  const [lectures, setLectures] = useState(null);
  const [lecturers, setLecturers] = useState(null);

  const [showNewLectureDialogue, setShowNewLectureDialogue] = useState(false);
  const [showEditLectureDialogue, setShowEditLectureDialogue] = useState(false);
  const [showDeleteLectureDialogue, setShowDeleteLectureDialogue] =
    useState(false);
  const [showBulkDeleteLecturesDialogue, setShowBulkDeleteLecturesDialogue] =
    useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (cohort && cohort != null) {
      if (cohort.lectures) {
        setLectures(cohort.lectures);
      } else {
        setLecturers([]);
      }
    }
  }, [lectures, cohort]);

  useEffect(() => {
    if (cohort && cohort != null) {
      setLecturers(cohort.lecturers);
    }
  }, [lecturers, cohort]);

  // Editing Item
  let editingItem = null;
  if (lectures) {
    if (lectures[selectedIndex]) {
      editingItem = lectures[selectedIndex];
    }
  }

  return (
    <div>
      <SingleCohortLectureView
        loading={!lectures}
        onPressedItem={(id) => props.history.push(`${id}`)}
        toggleAddLectureForm={() => setShowNewLectureDialogue(true)}
        sectionDescription={
          "All associated lecture date, time and location slots."
        }
        lectures={lectures || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditLectureDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteLectureDialogue(true);
        }}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        setShowBulkDeleteLecturesDialogue={setShowBulkDeleteLecturesDialogue}
      />
      <DialogueCreateLecture
        isOpen={showNewLectureDialogue}
        setClose={() => setShowNewLectureDialogue(false)}
        lectures={lectures || []}
        setLectures={setLectures}
        lecturers={lecturers || []}
        cohort={cohort}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        locations={locations}
        getAllLocations={getAllLocations}
        bulkCreateLecture={bulkCreateLecture}
      />

      

      <DialogueEditLecture
        isOpen={showEditLectureDialogue}
        setClose={() => {
          setShowEditLectureDialogue(false);
          setSelectedIndex(null);
        }}
        user={user}
        data={editingItem}
        itemId={selectedId}
        getCohort={getCohort}
        setLectures={setLectures}
        editLecture={editLecture}
        locations={locations||[]}
        getAllLocations={getAllLocations}
        handleWeekSelect={null}
      />
      <DialogueDeleteLecture
        isOpen={showDeleteLectureDialogue}
        setClose={() => setShowDeleteLectureDialogue(false)}
        itemId={selectedId}
        lectures={lectures || []}
        setLectures={setLectures}
        cohort={cohort}
        setCohort={setCohort}
        editCohort={editCohort}
        getCohort={getCohort}
        user={user}
        deleteLecture={deleteLecture}
      />

      <DialogueBulkDeleteLecture
        isOpen={showBulkDeleteLecturesDialogue}
        setClose={() => setShowBulkDeleteLecturesDialogue(false)}
        cohort={cohort}
        setCohort={setCohort}
        getCohort={getCohort}
        user={user}
        bulkDeleteLecture={bulkDeleteLecture}
      />
    </div>
  );
});
