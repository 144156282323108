import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
// Children
import ExtensionsView from "./single-student-extensions-view";

//Dialogues
import DialogueCreateExtension from "routes/dashboard/dialogues/students/create-extension";
import DialogueDeleteExtension from "routes/dashboard/dialogues/students/delete-extension";

export default withRouter(function (props) {
  const { student, setStudent, user, editStudent, getStudent } = props;

  const [extensions, setExtensions] = useState([]);
  const [cohorts, setCohorts] = useState([]);

  const [showCreateExtensionDialogue, setShowCreateExtensionDialogue] =
    useState(false);
  const [showDeleteExtensionDialogue, setShowDeleteExtensionDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (user && user != null) {
      if (student != null) {
        if (student.extensions) {
          for (let ext of student.extensions) {
            let duration = moment(ext.to).diff(moment(ext.from), "months");
  
            if (duration < 1) {
              duration = moment(ext.to).diff(moment(ext.from), "weeks");
              ext.duration = `${duration} weeks`;
            } else {
              ext.duration = `${duration} months`;
            }
          }
        }
  
        setExtensions(student.extensions ? student.extensions : []);
        setCohorts(student.cohorts);
      }
    }
  }, [student]);

  //Editing Item
  let editingItem = null;
  if (extensions) {
    if (extensions[selectedIndex]) {
      editingItem = extensions[selectedIndex];
    }
  }

  return (
    <div>
      <ExtensionsView
        loading={!extensions}
        toggleCreateExtensionForm={() => setShowCreateExtensionDialogue(true)}
        sectionDescription={"You can manage all related extensions below."}
        extensions={extensions || []}
        setExtensions={setExtensions}
        student={student}
        onPressedDeleteItem={(index) => {
          setSelectedIndex(index);
          setShowDeleteExtensionDialogue(true);
        }}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />
      <DialogueCreateExtension
        isOpen={showCreateExtensionDialogue}
        setClose={() => setShowCreateExtensionDialogue(false)}
        extensions={extensions || []}
        cohorts={cohorts || []}
        setExtensions={setExtensions}
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />
      <DialogueDeleteExtension
        isOpen={showDeleteExtensionDialogue}
        setClose={() => setShowDeleteExtensionDialogue(false)}
        index={selectedIndex}
        extensions={extensions || []}
        setExtensions={setExtensions}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        student={student}
        user={user}
        isResolved={false}
      />

    </div>
  );
});
