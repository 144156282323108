import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleModule } from "repository/actions/data/dashboard/modules";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./content-single-module-nav-crumbs";

// Components
import Details from "./details";
import Students from "./students";

export default withRouter(function (props) {
  const moduleId = props.match.params.id;

  const [moduleData, setModuleData] = useState(null);
  const { user } = useContext(AuthenticationContext);

  useEffect(() => {
    if (user && user != null) {
      getSingleModule(moduleId, user.idToken).then((result) => {
        setModuleData(result);
      });
    }
  }, []);

  return (
    <NavCrumbs moduleData={moduleData}>
      <Details
        moduleData={moduleData}
        setModuleData={setModuleData}
        user={user}
      />
      {/* <Students moduleData={moduleData} setModuleData={setModuleData} user={user}/> */}
    </NavCrumbs>
  );
});
