import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";

import { getSearchClient } from "utils/algolia";

const searchClient = getSearchClient();
const candidateIndex = searchClient.initIndex('Candidates');


const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, editCandidate, vacancy, setRefreshAlgolia,
    vacancyId,
    editVacancy,
  } = props;

  const [selectedCandidate, setSelectedCandidate] = React.useState(null);
  const [candidates, setCandidates] = React.useState(null);

  const [query, setQuery] = React.useState("");

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [error, setError] = React.useState(null);



  const handleSaveOwnerDetails = () => {
    if (
      selectedCandidate == null
    ) {
      toast.error("You have missing information for creating a new note");
      if (selectedCandidate === null) {
        setError("Candidate is required.");
      }


    } else {

      let applications = selectedCandidate.applications
      let industry = selectedCandidate.industry

      let existingApplication = applications.find((o) => o.vacancyId === vacancyId);


      if (existingApplication == null) {
        applications.push({
          vacancyId: vacancyId,
          status: "interested",
          index: `${vacancyId}-interested`
        })

        if (!industry.includes(vacancy.industry)) {
          industry.push(vacancy.industry)
        }


        let candidatePayload = {
          applications: applications,
          industry: industry,
        }


        let candidates = vacancy.candidates
        candidates.push(selectedCandidate.objectID)

        let vacancyPayload = {
          candidates: candidates,
        }

        setSaveLoading(true);
        toast.dark(`Assigning Candidate : ${selectedCandidate.firstName}`);

        editCandidate(selectedCandidate.objectID, candidatePayload, user.idToken)
          .then((result) => {

            editVacancy(vacancyId, vacancyPayload, user.idToken).then(()=>{
              setTimeout(() => { setRefreshAlgolia(true) }, 2000)
              setTimeout(() => { setRefreshAlgolia(false) }, 2000)
              setClose();
              setSaveLoading(false);
              toast.success(`Candidate assigned : ${selectedCandidate.firstName}`);
              setSelectedCandidate(null);
              setError(null);
            })
            .catch((error) => {
              toast.error(
                "Oops! Something went wrong. Check that you have the correct information"
              );
              toast.dark(error.message);
              setSaveLoading(false);
            });

          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });


      } else {
        toast.error(`${selectedCandidate.firstName} already applied for this vacancy`);
      }


    
    }

  }


  useEffect(() => {
    candidateIndex.search("").then(({ hits }) => {
      setCandidates(hits)
    });
  }, [])

  const onSearch = (query) => {
    setQuery(query)
    candidateIndex.search(query).then(({ hits }) => {
      setCandidates(hits)
    });
  }

  return (
    <Popup
      title="Add a Candidate"
      subtitle="Please fill in the details below to create a new Candidate."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          {selectedCandidate && <Typography variant={"h5"}>
            Assigning {selectedCandidate.firstName}
          </Typography>}
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: John Doe"}
              disabled={saveLoading}
              required
              label="Search for candidates"
              value={query}
              onChange={(event) => onSearch(event.target.value)}
              margin="normal"
              className={classes.searchBar}

            ></TextField>
          </FormControl>
          <FormControl className={classes.formControl}>
            {candidates &&
              <>
                {candidates.map((item, index) => {
                  return <MenuItem value={item.firstName} onClick={() => { setSelectedCandidate(item) }}>{item.firstName}</MenuItem>;
                })}</>
            }
          </FormControl>

        </div>
      }
    />
  );

}
export default withSnackbar(AddMenuDialogue);
