import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-enquiries-status-styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { changeEnquiryStatus } from "repository/actions/data/dashboard/enquiries";
import { getAssociatedCohorts } from "repository/actions/data/dashboard/courses";

// Actions
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();

  const { setClose, isOpen, enquiry, user } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [status, setStatus] = React.useState("pending");

  useEffect(() => {
    if (enquiry) {
      if (enquiry.status) {
        setStatus(enquiry.status);
        setCurrentSelectedCourseIdError(enquiry.courseId);
      }
    }
  }, [enquiry]);

  //automatic provision of cohorts when converting from enquiry to student
  const memberships = [
    {
      value: "onsite",
      text: "On Site",
    },
    {
      value: "online",
      text: "Online",
    },
    {
      value: "blended",
      text: "Blended",
    },
  ];
  const [cohortOptions, setCohortOptions] = React.useState([]);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [currentSelectedCourseId, setCurrentSelectedCourseIdError] =
    React.useState([]);
  const [membershipOptions, setMembershipOptions] = React.useState([]);
  const [newCohort, setNewCohort] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [selectedMembership, setSelectedMembership] = React.useState(null);
  const [cohortOptionsError, setCohortOptionsError] = React.useState(null);
  const [selectedMembershipError, setSelectedMembershipError] =
    React.useState(null);
  const [newCohortError, setNewCohortError] = React.useState(null);

  //cohort to choose from after course is identified
  useEffect(() => {
    if (enquiry && currentSelectedCourseId && currentSelectedCourseId != null) {
      getAssociatedCohorts(currentSelectedCourseId, user.idToken).then((result) => {
        setCohortOptions(result.data);
        setMembershipOptions(memberships);
      });
    }
  }, [currentSelectedCourseId]);

  useEffect(() => {
    if (newCohort && newCohort != null) {
      for (let c of cohortOptions) {
        if (c._id === newCohort) {
          setModuleOptions(c.modules);
        }
      }
    }
  }, [newCohort]);

  const handleSelectedModuleToggle = (moduleId, isChecked) => {
    if (isChecked) {
      if (selectedModules.indexOf(moduleId) < 0) {
        let newSelectedModules = selectedModules;
        newSelectedModules.push(moduleId);
        setSelectedModules(newSelectedModules);
      }
    } else {
      if (selectedModules.indexOf(moduleId) > 0) {
        let newSelectedModules = [];
        for (let s of selectedModules) {
          if (s !== moduleId) {
            newSelectedModules.push(s);
          }
        }
        setSelectedModules(newSelectedModules);
      }
    }
  };

  const handleSaveOwnerDetails = () => {
    if (user && user != null) {
      setSaveLoading(true);
      let fullNewCohort = {};
      changeEnquiryStatus(
        enquiry._id,
        status,
        newCohort,
        fullNewCohort,
        selectedModules,
        selectedMembership,
        user.idToken
      )
        .then((result) => {
          if (
            status === "accepted Unconditionally" ||
            status === "accepted Conditionally"
          ) {
            toast.success(
              `${enquiry.firstName} ${enquiry.lastName}has been converted to a student.`
            );
            window.location.href = `/students/all/${result._id}`;
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Change enquiry status"
      subtitle="You can edit the status of your enquiry below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.inputType} error={cohortOptionsError}>
            <InputLabel required disabled={saveLoading}>
              Select Cohort
            </InputLabel>
            {cohortOptions && cohortOptions != null && (
              <Select
                elevation={0}
                onChange={(event) => setNewCohort(event.target.value)}
                value={newCohort}
                required
                error={newCohortError != null}
                disabled={saveLoading}
                input={<Input />}
              >
                {cohortOptions.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            )}
            <FormHelperText>{cohortOptionsError}</FormHelperText>
          </FormControl>
          <FormControl component="fieldset">
            <RadioGroup
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            >
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="accepted Unconditionally"
                control={<Radio />}
                label="Accepted Unconditionally"
              />
              <FormControlLabel
                value="accepted Conditionally"
                control={<Radio />}
                label="Accepted Conditionally"
              />
              <FormControlLabel
                value="rejected"
                control={<Radio />}
                label="Rejected"
              />
            </RadioGroup>
          </FormControl>
          {membershipOptions &&
            membershipOptions != null &&
            newCohort &&
            newCohort != null && (
              <FormControl
                className={classes.inputType}
                error={selectedMembershipError}
              >
                <InputLabel id="membership-type-label">
                  Membership Type
                </InputLabel>
                <Select
                  labelId="membership-type-label"
                  id="membership-type"
                  value={selectedMembership}
                  onChange={(event) =>
                    setSelectedMembership(event.target.value)
                  }
                >
                  {membershipOptions.map((item) => {
                    return <MenuItem value={item.value}>{item.text}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}
          {moduleOptions && moduleOptions != null && (
            <FormControl className={classes.formControl}>
              <List>
                {moduleOptions.map((mod) => {
                  return (
                    <ListItem key={mod._id}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          onChange={(event) => {
                            handleSelectedModuleToggle(
                              mod._id,
                              event.target.checked
                            );
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${mod.title}`} />
                    </ListItem>
                  );
                })}
              </List>
            </FormControl>
          )}
        </div>
      }
    />
  );
});
