import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllLeavesContext } from "repository/stores/dashboard/leaves/leaves-all-provider";

// Children
import LeavesView from "./leaves-view";
import moment from "moment";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { getLeave } = useContext(AllLeavesContext);

  const [leaves, setLeaves] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (leaves == null) {
        let thisMonth = moment().format("MM-YYYY");
        let nextMonth = moment().add(1, "months").format("MM-YYYY");

        getLeave({
          status: "approved",
          leaveMonth: {
            $in: [thisMonth, nextMonth],
          },
        }).then((result) => {
          let upcoming = []
          for(let r of result.data){
            if(moment(r.timestamp).isSameOrAfter(moment())){
              upcoming.push(r)
            }
          }
          setLeaves(upcoming);
        });
      }
    }
  }, [user, leaves]);

  return (
        <LeavesView
          user={user}
          loading={!leaves}
          sectionDescription={"You can manage all of your clients below."}
          leaves={leaves || []}
        />
     
  );
});
