const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    width: '100%',
    height:'100vh',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: 8,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 16,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop:16,
    },
  },
  logo: {
    width: '50%',
    marginTop: '25vh',
    marginBottom: 30,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 60,
    },
  },
  typography: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 25,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 56,
    },
  }
});

export default styles;
