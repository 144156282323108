import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import ProjectView from "./single-client-projects-view";

// Dialogues
import DialogueCreateVacancy from "routes/dashboard/dialogues/vacancies/create-vacancy";
import DialogueDeleteVacancy from "routes/dashboard/dialogues/vacancies/delete-vacancy";


export default withRouter(function (props) {

  let { user, client, clientId, setClient } = props



  return (
    <div>
      <ProjectView
        loading={!client}
        onPressedItem={(id) => window.location.href = `/projects/${id}`}
        sectionDescription={client ? `You can manage all of ${client.title}'s peojects below.` : ""}
        client={client || null}
        user={user}
        clientId={clientId}
      />


    </div>
  );
});
