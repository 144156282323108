import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Panel from "components/panel";
import { toast } from "react-toastify";

import {
  Typography,
  Paper,
  MenuItem,
  Select,
  Link,
  Grid,
  Input,
  Button
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleCreateExtensionForm,
    referral,
    sectionDescription,
    user,
    student,
    editReferral,
    deleteReferral,
    toggleEditReferralForm,
    toggleDeleteReferralForm
  } = props;

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (referral) {
      setStatus(referral.status);
    }
  }, [referral]);

  const handleStatus = (value) => {
    setStatus(value);

    editReferral(referral._id, { status: value }, user.idToken)
      .then((result) => {
        toast.success(`Status updated: ${value}`);
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong.");
        toast.dark(error.message);
        console.log(error);
      });
  };

 

  return (
    <div>
      {referral && student && (
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Panel
              title={"Referrer"}
              loading={!referral}
              actionName="Edit"
              handleAction={toggleEditReferralForm}
            >
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Referral Name
                </Typography>
                <Typography className={classes.fontRegular}>
                  {referral.firstName} {referral.lastName}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Referral Email
                </Typography>
                <Typography className={classes.fontRegular}>
                  {referral.email}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Payment Date
                </Typography>
                <Typography className={classes.fontRegular}>
                  {moment(referral.paymentDate).format("DD MMM YYYY")}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Payment Value
                </Typography>
                <Typography className={classes.fontRegular}>
                  € {referral.paymentValue}
                </Typography>
              </Paper>

              <Paper
                className={classes.miniSection}
                style={{
                  border: "solid 1px",
                  borderColor: status == "paid" ? "#8fce00" : "#f4c569",
                }}
              >
                <Typography className={classes.fontBold}>Status</Typography>
                <Select
                  elevation={0}
                  onChange={(event) => handleStatus(event.target.value)}
                  value={status}
                  required
                  className={classes.fontRegular}
                  style={{ width: "40%" }}
                  input={<Input id="type-menu" />}
                >
                  <MenuItem value={"pending"}>{"Pending"}</MenuItem>
                  <MenuItem value={"paid"}>{"Paid"}</MenuItem>
                </Select>
              </Paper>
            </Panel>
            <Button onClick={toggleDeleteReferralForm} style={{marginTop:20, border:"1px solid #FF0000",color: "#FF0000"}}>
              DELETE
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Panel title={"Student"} loading={!referral}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Student Name
                </Typography>
                <Link
                  href={`/students/all-malta/${referral.studentId}`}
                  underline="always"
                >
                  <Typography className={classes.fontRegular}>
                    {referral.studentName}
                  </Typography>
                </Link>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Student Number
                </Typography>
                <Typography className={classes.fontRegular}>
                  {student.studentNumber}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>
                  Student Email
                </Typography>
                <Typography className={classes.fontRegular}>
                  {student.email}
                </Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Cohorts</Typography>
                <Typography className={classes.fontRegular}>
                  {student.cohorts.map((item) => {
                    return <li>{item.title}</li>;
                  })}
                </Typography>
              </Paper>
            </Panel>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
