import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
// import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";

// Actions
// import { deleteUser } from "repository/actions/data/dashboard/users";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, itemId, user, deleteUser } = props;
    // const {users, setUsers, deleteUser} = React.useContext(AllUsersContext);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteItem = () => {
      setSaveLoading(true);

      deleteUser( itemId, user.idToken).then((result) => {
        setClose();
        setSaveLoading(false);
      });
    };

    return (
      <Popup
        title="Delete selected user"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteItem()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this user?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
