import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import Panel from "components/panel";

import {
  Grid,
  ListItemAvatar,
  List,
  ListItem,
  Avatar,
  Typography,
  Divider,
  ListItemText,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { user, loading, project, setShowProjectTimeDialogue,setProject, consultants, panelDescription } =
    props;
  const classes = useStyles();

  const [actualHours, setActualHours] = useState(0);

  const getConsultant = (consultantId) => {
    return consultants.find((obj) => {
      return obj._id === consultantId;
    });
  };

  useEffect(() => {
    if (project) {
      setActualHours(project.actualHours ? project.actualHours : 0);
    }
  }, project);

  return (
    <Panel
      title={`${actualHours} Actual Hours`}
      loading={loading}
      panelDescription={panelDescription}
    >
      <button onClick={() => setShowProjectTimeDialogue(true)}>Add Time</button>

      {project && consultants && (
        <List style={{ paddingRight: 20 }}>
          {project.tasks.map((task) => {
            let total = 0;
            return (
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item xs={5}>
                  <ListItem style={{ marginBottom: 50 }}>
                    <Typography className={classes.title} variant="button">
                      {task.title}
                    </Typography>
                  </ListItem>
                </Grid>
                <Grid item xs={6}>
                  {Object.entries(task.actualHours).map((item) => {
                    total = total + parseFloat(item[1]);
                    let consultant = getConsultant(item[0]);
                    return (
                      <ListItem
                        style={{ float: "left", width: 30, marginRight: 30 }}
                      >
                        <ListItemAvatar style={{ margin: 0 }}>
                          <Avatar
                            style={{
                              height: "30px",
                              width: "30px",
                              marginBottom: 10,
                            }}
                            alt={consultant.firstName}
                            src={consultant.directURL}
                          />
                          {`${item[1]} hrs`}
                        </ListItemAvatar>
                      </ListItem>
                    );
                  })}
                </Grid>
                <Grid item xs={1}>
                  <ListItem
                    style={{ float: "left", width: 30, marginRight: 30 }}
                  >
                    <ListItemAvatar style={{ margin: 0 }}>
                      <Avatar
                        style={{
                          height: "35px",
                          width: "35px",
                          marginBottom: 10,
                          fontSize: 12,
                          backgroundColor: "#FFA500",
                        }}
                        sx={{ bgcolor: "#FFA500" }}
                      >
                        Total
                      </Avatar>
                      {`${total} hrs`}
                    </ListItemAvatar>
                  </ListItem>
                </Grid>
              </Grid>
            );
          })}
        </List>
      )}
    </Panel>
  );
});
