import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

// Routes
import AllModules from "./all-modules";
import SingleModule from "./single-module";

export default function () {
  return (
    <div>
      <Switch>
        <Route exact path="/content/modules" component={() => <AllModules />} />
        <Route
          exact
          path="/content/modules/:id"
          component={() => <SingleModule />}
        />
      </Switch>
    </div>
  );
}
