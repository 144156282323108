import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  TextareaAutosize,
  Checkbox,
  InputLabel,
  FormControlLabel,
  Paper,
  Button,
  Grid
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
// Actions

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, createNewCandidate, setRefreshAlgolia } = props;

  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [contactNumber, setContactNumber] = React.useState(null);
  const [isLocal, setIsLocal] = React.useState(true);



  const [selectedFile, setSelectedFile] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState();

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [error, setError] = React.useState(null);


  const handleSaveOwnerDetails = () => {
    if (
      firstName == null ||
      lastName == null ||
      contactNumber == null ||
      email == null
    ) {
      toast.error("You have missing information for creating a new note");
      if (firstName === null) {
        setError("First Name is required.");
      }
      if (lastName == null) {
        setError("Last Name is required.");
      }
      if (contactNumber == null) {
        setError("Contact Number is required.");
      }
      if (email == null) {
        setError("Email is required.");
      }


    } else {

      let data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactNumber: contactNumber,
        isLocal: isLocal,
        // jobIndustry: vacancy.industry,
        // jobId: vacancy._id,
        resumeUri: selectedFile,
        applications: [],
        industry: [],
        notes: [],
      }


      setSaveLoading(true);
      toast.dark(`Creating Candidate : ${data.firstName}`);
      createNewCandidate(data, user.idToken)
        .then((result) => {
          setTimeout(() => { setRefreshAlgolia(true) }, 2000)
          setTimeout(() => { setRefreshAlgolia(false) }, 2000)
          setClose();
          setSaveLoading(false);
          toast.success(`Candidate created : ${data.firstName}`);
          setFirstName(null);
          setLastName(null);
          setEmail(null);
          setContactNumber(null);
          setSelectedFile(null)
          setSelectedFileName()
          setError(null);

        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }

  }
  const handleCapture = ({ target }) => {
    if (target.files && target.files.length > 0) {
      if (target.files[0].size > 15728640) {
        alert("File is too big");
      } else {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        setSelectedFileName(target.files[0].name);
        fileReader.onload = (e) => {
          setSelectedFile(e.target.result);
        };
      }
    };
  }

  return (
    <Popup
      title="Add a Candidate"
      subtitle="Please fill in the details below to create a new Candidate."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: John"}
                  disabled={saveLoading}
                  required
                  label="First Name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Doe"}
                  disabled={saveLoading}
                  required
                  label="Last Name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  margin="normal"
                ></TextField >
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: john@doe.com"}
                  disabled={saveLoading}
                  required
                  label="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <div></div>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: 79123456"}
                  disabled={saveLoading}
                  required
                  label="Contact Number"
                  value={contactNumber}
                  onChange={(event) => setContactNumber(event.target.value)}
                  margin="normal"
                ></TextField >
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.spacing}></div>
          <Grid container >

            <Grid item xs={12}>

              <InputLabel required >
                Upload Resume
              </InputLabel>

            </Grid>
            <Grid item xs={6}>

              {selectedFileName != null && (
                <p>{selectedFileName}</p>
              )}
              <input
                accept="application/pdf"
                //   className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleCapture}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="raised"
                  component="span"
                  className="resume_button"
                  style={{
                    padding: "10px",
                    backgroundColor: "rgba(177, 187, 202, 0.103)"
                  }}
                >
                  Upload
                </Button>
              </label>

            </Grid>
            <Grid item xs={6}>
              <FormControlLabel control={<Checkbox defaultChecked onChange={(event) => {
                setIsLocal(
                  event.target.checked
                );
              }} />} label="In Malta" />
            </Grid>

          </Grid>

        </div>
      }
    />
  );

}
export default withSnackbar(AddMenuDialogue);
