import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import LeaveRequests from './routes/all/leave-requests';



export default function () {
    return(
        <div>
            <Switch>
                <Route path='/backoffice/leave-requests' component={() => <LeaveRequests />} />
                <Route exact path='/backoffice' component={() => <Redirect to={`/backoffice/leave-requests`} />} />
            </Switch>
        </div>
    )
}