import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();

  const { setClose, isOpen, student, setStudent, user, editStudent } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [status, setStatus] = React.useState("Active");
  const [stateOptions, setStateOptions] = React.useState([]);

  const states = [
    {
      value: "Inactive",
      text: "Inactive",
    },
    {
      value: "Active",
      text: "Active",
    },
    {
      value: "Cancelled",
      text: "Cancelled",
    },
    {
      value: "Completed",
      text: "Completed",
    },
    {
      value: "Pending",
      text: "Pending",
    },
  ];

  useEffect(() => {
    if (student) {
      if (student.status) {
        setStatus(student.status);
      }
    }
    setStateOptions(states);
  }, []);

  const handleClose = () => {
    setClose();
  };

  const handleSaveStatus = () => {
    if (user && user != null) {
      setSaveLoading(true);

      const patchData = {
        status: status,
      };

      editStudent(student._id, patchData, user.idToken)
        .then(() => {
          toast.success(`${student.firstName}'s status updated to ${status}`);
          handleClose();
        })
        .catch((error) => {
          toast.error(`Could not update ${student.firstName}'s  status.`);
        })
        .finally(() => {
          setSaveLoading(false);
        });

      // changeStudentStatus(setStudent, student._id, status, user.idToken)
      //   .then((result) => {
      //     toast.success(`${student.firstName}'s status updated to ${status}`);
      //   })
      //   .catch((error) => {
      //     toast.error(`Could not update ${student.firstName}'s  status.`);
      //   })
      //   .finally(() => {
      //     setSaveLoading(false);
      //   });
    }
  };

  return (
    <Popup
      title={
        student && student.status
          ? `Change ${student.firstName}'s status`
          : `Change Student Status`
      }
      subtitle="You can edit his/her status below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveStatus()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.inputType}>
            <InputLabel require disabled={saveLoading}>
              Select Status
            </InputLabel>
            {stateOptions && stateOptions != null && (
              <Select
                elevation={0}
                onChange={(event) => setStatus(event.target.value)}
                value={status}
                required
                disabled={saveLoading}
                input={<Input />}
              >
                {stateOptions.map((item) => {
                  return <MenuItem value={item.value}>{item.text}</MenuItem>;
                })}
              </Select>
            )}
          </FormControl>
        </div>
      }
    ></Popup>
  );
});
