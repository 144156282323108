import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { Grid, TextField, FormControl } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";

const useStyles = makeStyles(styles);

function EditCommentsDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, student, editStudent, setStudent } = props;

  const [comments, setComments] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newComment, setNewComment] = React.useState(null);
  const [newCommentError, setNewCommentError] = React.useState(null);

  useEffect(() => {
    if (student && student != null) {
      if (student.comments) {
        setComments(student.comments);
      }
    }
  }, [student]);

  const handleAddSaveAndClose = () => {
    if (newComment == null) {
      setNewCommentError("Cannot have an empty comment");
    } else {
      let newCommentPayload = {
        content: newComment,
        createdAt: new Date(),
        createdBy: user._id,
        createdByFullName: `${user.firstName} ${user.lastName}`,
      };

      let newComments = [newCommentPayload].concat(comments);
      setComments(newComments);

      let patchData = { ...student, comments: newComments };

      setSaveLoading(true);
      toast.dark(`Creating comment`);
      editStudent(student._id, patchData, user.idToken)
        .then((result) => {
          setStudent(result);
          setClose();
          setSaveLoading(false);
          setNewCommentError(null);
          toast.success(`New comment created`);
          handleClose();
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="New Comment"
      subtitle="Add a new comment for this student below"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => handleClose()}
      handleAction={() => handleAddSaveAndClose()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={
                    "Ex: Full Scholarship - Correspond with Business Development"
                  }
                  disabled={saveLoading}
                  required
                  helperText={newCommentError}
                  error={newCommentError != null}
                  label="New Comment"
                  value={newComment}
                  onChange={(event) => setNewComment(event.target.value)}
                  margin="normal"
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditCommentsDialogue);
