import apiClient from "utils/api";
import { getIdToken } from "../authentication"

export const deleteDocument = async (
  documentId,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/documents").remove(documentId, {
          query: {
            firebaseToken: token,
          },
        });

        let existing = [...documents];
        for (const [i, v] of existing.entries()) {
          if (v._id === documentId) {
            existing.splice(i, 1);
          }
        }
        setDocuments(existing);

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
