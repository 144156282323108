import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
// import { AuthenticationContext } from "repository/stores/global/authentication-provider";

import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  const {
    loading,
    title,
    toggleAddUserForm,
    onPressedItem,
    consultants,
    onPressedEditItem,
    sectionDescription,
  } = props;


  return (
      <Panel
        title={title}
        loading={loading}
        actionName="Add consultant"
        handleAction={toggleAddUserForm}
        sectionDescription={sectionDescription}
      >
        {consultants.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {" "}
                    <Typography className={classes.labelText}></Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consultants.map((row, index) => {
                  return (
                    <TableRow hover key={row._id} className={classes.row}>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellPhoto}
                        align="center"
                      >
                        <img
                          src={row.directURL ? row.directURL : ""}
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellType}
                        align="left"
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellName}
                        align="left"
                      >
                        {row.lastName}
                      </TableCell>
                     

                      <TableCell
                        className={classes.cellName}
                        align="right"
                      >
                        <IconButton
                          onClick={(event) => onPressedEditItem(index)}
                        >
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {consultants.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any consultants yet.
          </Typography>
        )}
      </Panel>
  );
}
