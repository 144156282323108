import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  TextField,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Divider,
  Typography,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    programme,
    editProgramme,
    setProgramme,
    isNewProgramme,
    months,
    setMonths,
    years,
    setYears,
    createNewProgramme,

    moduleTotalCost,
    studentTotalCost,
    programmeTotalCosts
  } = props;
  const classes = useStyles();

  const [title, setTitle] = useState(null);
  const [courseType, setCourseType] = useState(null);

  useEffect(() => {
    if (!isNewProgramme) {
      setTitle(programme.title);
      setCourseType(programme.courseType);
      setMonths(programme.months);
      setYears(Math.ceil(programme.months / 12));
    }
  }, [isNewProgramme]);

  const handleSaveProgramme = async () => {

    let payload = {
      title: title,
      courseType: courseType,
      courseLevel: programmeTotalCosts.courseLevel,
      months: months,
      studentCount: studentTotalCost.studentCount,
      studentCosts: studentTotalCost.studentCosts,
      moduleCosts: moduleTotalCost.moduleCosts,
      moduleTypes: moduleTotalCost.moduleTypes,
      programmeCosts: programmeTotalCosts.programmeCosts,
    };


    if (isNewProgramme) {
      await createNewProgramme(payload)
        .then((result) => {
          toast.success("Programme Profile Created");
          window.location.href = "/programme-costings/" + result._id;
        })
        .catch((e) => {
          console.log(e.message);
        });
    }

    if (!isNewProgramme) {
      await editProgramme(programme._id, payload)
        .then((result) => {
          toast.success("Programme Profile Updated");
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7} style={{ textAlign: "left" }}>
        <FormControl fullWidth style={{ paddingLeft: 7 }}>
          <TextField
            placeholder="Programme Title"
            type="text"
            value={title}
            inputProps={{
              style: {
                fontFamily: "proximaNova-bold",
                color: "#564181",
                fontWeight: 900,
                fontSize: 20,
                width: "90%",
              },
            }}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </FormControl>
        <Button
          className={classes.actionButton}
          style={{ marginTop: 10, padding: 5 }}
          onClick={() => {
            handleSaveProgramme();
          }}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={3} style={{ textAlign: "end" }}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label" variant="outlined">
            Course Type
          </InputLabel>
          <Select
            variant="outlined"
            label="Course Type"
            value={courseType}
            onChange={(event) => {
              setCourseType(event.target.value);
            }}
            style={{ textAlign: "left" }}
            className={classes.costings}
          >
            <MenuItem value={0}>Part Time</MenuItem>
            <MenuItem value={1}>Full Time</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginTop: 10 }}>
          <div style={{ display: "flex" }}>
            <Typography className={classes.title} style={{ marginTop: 4 }}>
              {years} yrs
            </Typography>
            <TextField
              placeholder="No. of Months"
              label="No. of Months"
              variant="outlined"
              type="number"
              value={months}
              onChange={(event) => {
                setYears(Math.ceil(event.target.value / 12));
                setMonths(event.target.value);
              }}
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
            />
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
});
