import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider";

const ContextProps = {
  students: null,
  setStudents: {},
  getAllStudents: {},
  createNewStudent: {},
  deleteStudent: {},
};

export const AllStudentsContext = React.createContext(ContextProps);

export const AllStudentsProvider = ({ children }) => {
  const [students, setStudents] = useState(null);
  const { getIdToken, refreshToken } = useContext(IdTokenContext);

  const getAllStudents = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/students").find({
          query: {
            firebaseToken: token,
          },
        });
        setStudents(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewStudent = async (studentData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // force refresh token
        await refreshToken();

        getIdToken().then(async (token) => {
          let result = await apiClient
            .service("v1/students")
            .create(studentData, {
              query: {
                firebaseToken: token,
              },
            });
          if (students == null) {
            setStudents(result);
          } else {
            let newData = students;
            newData.push(result);
            setStudents(newData);
          }
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteStudent = async (studentId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/students").remove(studentId, {
          query: {
            firebaseToken: token,
          },
        });

        let newData = students;

        for (const [i, v] of newData.entries()) {
          if (v._id === studentId) {
            newData.splice(i, 1);
          }
        }
        setStudents(newData);

        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllStudentsContext.Provider
      value={{
        students,
        setStudents,
        getAllStudents,
        createNewStudent,
        deleteStudent,
      }}
    >
      {children}
    </AllStudentsContext.Provider>
  );
};
