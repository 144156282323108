const styles = theme => ({
    appBar: {
        position: 'fixed',
        backgroundColor: theme.palette.primary.main,
        paddingTop:3,
        height: 60,
        marginLeft: 270,
        zIndex: 1000,
        [theme.breakpoints.up('sm')]: {
          height: 60,
          zIndex: 1000,
          width: '100%',
        },
        [theme.breakpoints.up('md')]: {
          zIndex: 1000,
          width: `100%`,
          height: 50,
        }
    }
});
  
export default styles;
  