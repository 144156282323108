import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import moment  from "moment"
import { toast } from "react-toastify";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  RefinementList,
} from "react-instantsearch-dom";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import projects from "routes/dashboard/routes/advisory/projects";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    onPressedItem,
    sectionDescription,
    clientId,
  } = props;

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a Vacancy"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const getDaysLeftColor = (daysLeft) => {

    if(daysLeft>7){
      return ["#EBF9F4", "#3AB589"]
    }
    if(daysLeft <= 15 && daysLeft>1){
      return ["#FFF7E6", "#FFB822"]
    }
    if(daysLeft < 1){
      return ["#FDF4F6", "#DD4166"]
    }
  }

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
      {hits.map((row, index) => {
        var day1 = new Date(row.endDate);
        var day2 = new Date();
        var Difference_In_Time = day1.getTime() - day2.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let daysLeft = Difference_In_Days.toFixed(0)

        let pillColor = getDaysLeftColor(daysLeft)

        return (
          <TableRow hover key={row.objectID} className={classes.row}>
            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellWide}
              align="left"
            >
              {row.title}
            </TableCell>
            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >
              {row.clientId}
            </TableCell>
            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >
              {row.consultants.length}
            </TableCell>

            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >
              {row.estimatedHours}
            </TableCell>
            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >
              {row.actualHours}
            </TableCell>
            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >

              <span style={{backgroundColor:pillColor[0], width: "auto", padding:10, borderRadius: 10, color: pillColor[1]}}>
                {daysLeft} days
              </span>
            </TableCell>

            <TableCell
              onClick={(event) => onPressedItem(row.objectID)}
              className={classes.cellType}
              align="left"
            >
              {moment(day1).format("DD/MM/YYYY")}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <Section
      title="Projects"
      loading={loading}
      sectionDescription={sectionDescription}
    >
      <InstantSearch indexName="Projects" searchClient={searchClient}>
        <RefinementList
          attribute="clientId"
          operator="or"
          defaultRefinement={clientId}
        />
        <CustomSearchBox />
        <Paper className={classes.tablePaper}>
          <TableContainer >
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Project Title
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Consultants
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                     Est. Hours
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Act. Hours
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Days Left
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Due Date
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <CustomHits />
            </Table>
          </TableContainer>
        </Paper>
        <CustomPagination />
      </InstantSearch>

      {projects.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          You haven't created any vacancies yet.
        </Typography>
      )}

    </Section>
  );
}
