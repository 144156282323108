import React, { useState, useContext } from 'react';
import apiClient from "utils/api";
import { getSearchClient } from "utils/algolia";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  contacts: null,
  setContacts: {},
  getSingleContact: {},
  getContacts: {},
  createNewContact: {},
  editContact: {},
  deleteContact: {}
};



export const AllContactsContext = React.createContext(ContextProps);

export const AllContactsProvider = ({ children }) => {
  const [contacts, setContacts] = useState(null);
  const searchClient = getSearchClient();

  const { getIdToken } = useContext(IdTokenContext)


  const getContacts = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/contacts").find({
            query: {
              firebaseToken: token,
            },
          });
          setContacts(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  }


  const createNewContact = async (contact, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/contacts").create(contact, {
            query: {
              firebaseToken: token,
            },
          });

          if (contacts == null) {
            setContacts(result);
          } else {
            let newData = contacts
            newData.push(result)
            setContacts(newData);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

   const getSingleContact = async (contactId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
         
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/contacts").get(contactId, {
            query: {
              firebaseToken: token,
            },
          });
  
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  

  const editContact = async (data, contactId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedContact = await apiClient
            .service("v1/contacts")
            .patch(contactId, data, {
              query: {
                firebaseToken: token,
              },
            });

          let newData = contacts
          for (const [i, v] of newData.entries()) {
            if (v._id === contactId) {
              newData[i] = data;
            }
          }
          setContacts(newData);

          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const deleteContact = async (contactId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/contacts").remove(contactId, {
            query: {
              firebaseToken: token,
            },
          });

          let newData = contacts
          for (const [i, v] of newData.entries()) {
            if (v._id === contactId) {
              newData.splice(i, 1);
            }
          }
          setContacts(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  return (
    <AllContactsContext.Provider
      value={{
        contacts,
        setContacts,
        getSingleContact,
        getContacts,
        createNewContact,
        editContact,
        deleteContact
      }}
    >
      {children}
    </AllContactsContext.Provider>
  );


}
