import React, { useContext, useState, useEffect } from "react";
import { TrainingSessionsContext } from "repository/stores/dashboard/training/training-sessions-provider";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import { AllContactsContext } from "repository/stores/dashboard/contacts/contacts-all-provider";
import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";

// Children
import AllSessionsView from "./training-all-sessions-view";

// Dialogues
import DialogueCreateSession from "routes/dashboard/dialogues/training/sessions/create-session";
import DialogueEditSession from "routes/dashboard/dialogues/training/sessions/edit-session";
import DialogueDeleteSession from "routes/dashboard/dialogues/training/sessions/delete-session";

// Actions
import {
  // findAllSessions,
  getSingleSession,
} from "repository/actions/data/dashboard/sessions";
// import { findAllContacts } from "repository/actions/data/dashboard/contacts";

export default withRouter(function (props) {
  const {sessions, setSessions, findAllSessions, createNewSession, editSession, deleteSession} = useContext(TrainingSessionsContext);
  const {locations, getAllLocations} = useContext(AllLocationsContext);
  const {contacts, getContacts} = useContext(AllContactsContext);
  const {personnel, findAllPersonnelAlgolia} = useContext(AllPersonnelContext);
  const { user } = useContext(AuthenticationContext);

  const [showNewSessionsDialogue, setShowNewSessionDialogue] = useState(false);
  const [showEditSessionDialogue, setShowEditSessionDialogue] = useState(false);
  const [showDeleteSessionDialogue, setShowDeleteSessionDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      findAllSessions( user.idToken);
      getAllLocations(user.idToken);
      getContacts( user.idToken);
      findAllPersonnelAlgolia();
    }
  }, []);

  // Editing Item
  useEffect(() => {
    if (sessions) {
      if (selectedId && editingItem == null) {
        getSingleSession(selectedId).then((result) => {
          setEditingItem(result);
        });
      }
    }
  }, [selectedId]);

  return (
    <div>
      <AllSessionsView
        loading={!sessions}
        toggleAddLocationForm={() => setShowNewSessionDialogue(true)}
        sectionDescription={"You can manage all of your sessions below."}
        sessions={sessions || []}
        contacts={contacts || []}
        locations={locations || []}
        personnel={personnel || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditSessionDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteSessionDialogue(true);
        }}
        user={user}
      />
      <DialogueCreateSession
        isOpen={showNewSessionsDialogue}
        setClose={() => setShowNewSessionDialogue(false)}
        user={user}
        createNewSession={createNewSession}
        locations={locations}
        getAllLocations={getAllLocations}
        contacts={contacts}
        getContacts={getContacts}
        personnel={personnel}
        findAllPersonnelAlgolia={findAllPersonnelAlgolia}
      />
      <DialogueEditSession
        isOpen={showEditSessionDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditSessionDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        editSession={editSession}
        contacts={contacts}
        locations={locations}
        personnel={personnel}

      />
      <DialogueDeleteSession
        isOpen={showDeleteSessionDialogue}
        setClose={() => setShowDeleteSessionDialogue(false)}
        itemId={selectedId}
        user={user}
        deleteSession={deleteSession}
      />
    </div>
  );
});
