import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";



import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllVacanciesContext } from "repository/stores/dashboard/vacancies/vacancies-all-provider";


// Children
import VacanciesView from "./vacancies-all-vacancies-view";

// Dialogues
import setShowNewVacancyDialogue from "routes/dashboard/dialogues/clients/create-client";

// Actions


export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  const { vacancies, getVacancies, createNewVacancy, loadingVacancies } = useContext(AllVacanciesContext);


  const [showNewClientDialogue, setShowNewClientDialogue] = useState(false);


  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (vacancies == null) {
        getVacancies(user.idToken);
      }
    }
  }, [vacancies]);


  return (
    <div>
      <VacanciesView
        loading={!vacancies}
        onPressedItem={(id) => props.history.push(`${id}`)}
        sectionDescription={"You can manage all of your vacancies below."}
        vacancies={vacancies || []}
        user={user}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}

      />

      {/* <DialogueCreateClient
        isOpen={showNewClientDialogue}
        setClose={() => setShowNewClientDialogue(false)}
        user={user}
        clients={clients || []}
        createNewCohort={createNewClient}
        setRefreshAlgolia={setRefreshAlgolia}
      /> */}

    </div>
  );
});
