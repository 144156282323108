import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { SingleStudentContext } from "repository/stores/dashboard/students/students-single-provider";
// Navigation
import NavCrumbs from "./students-single-student-nav-crumbs";

// Components
import Extensions from "./extensions";
import ResolvedExtensions from "./resolved-extensions";


import { Divider } from "@material-ui/core";


export default withRouter(function (props) {
  const studentId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);
  const { student, setStudent, getStudent, editStudent, loadingStudent } =
    useContext(SingleStudentContext);

  useEffect(() => {
    if (user && user != null) {
      if (studentId && studentId != null) {
        getStudent(studentId, user.idToken);
      }
    }
  }, [user, studentId]);

  return (
    <NavCrumbs student={student} setStudent={setStudent}>
      <Extensions
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
      <Divider style={{margin: "100px 0"}}/>

      <ResolvedExtensions
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        loadingStudent={loadingStudent}
      />
    </NavCrumbs>
  );
});
