import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions
import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
import {
  // findAllCourses,
  getAssociatedCohorts,
} from "repository/actions/data/dashboard/courses";
import { assignNewCohort } from "repository/actions/data/dashboard/students";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const classes = useStyles();
  // const { getCourses } = React.useContext(AllCoursesContext);

  const { setClose, isOpen, student, user, setStudent, getStudent, getCourseModules, getCourses } = props;

  const memberships = [
    {
      value: "onsite",
      text: "On Site",
    },
    {
      value: "online",
      text: "Online",
    },
    {
      value: "blended",
      text: "Blended",
    },
  ];

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [cohortOptions, setCohortOptions] = React.useState([]);
  const { courses, setCourses } = React.useContext(AllCoursesContext);
  const [moduleOptions, setModuleOptions] = React.useState([]);
  const [electiveModules, setElectiveModules] = React.useState([]);
  const [compulsoryModules, setCompulsoryModules] = React.useState([]);

  const [membershipOptions, setMembershipOptions] = React.useState(memberships);
  const [currentSelectedCourseId, setCurrentSelectedCourseId] =
    React.useState(null);
  const [newCohort, setNewCohort] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [selectedMembership, setSelectedMembership] = React.useState(null);
  const [cohortOptionsError, setCohortOptionsError] = React.useState(null);
  const [selectedMembershipError, setSelectedMembershipError] =
    React.useState(null);
  const [currentSelectedCourseIdError, setCurrentSelectedCourseIdError] =
    React.useState(null);
  const [newCohortError, setNewCohortError] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [exitPoint, setExitPoint] = React.useState(null);
  const [exitPoints, setExitPoints] = React.useState(null);
  const [isModulesChecked, setIsModulesChecked] = React.useState(false);
  const [groupedCourses, setGroupedCourses] = React.useState([]);


  //Data Fetching

  //courses to choose from
  useEffect(() => {
    if (student && student != null) {
      getCourses(user.idToken);

    }
  }, [student]);

  useEffect(() => {
    if (courses) {
      let grouped = groupArrayOfObjects(courses, "mqfLevel");
      setGroupedCourses(Object.entries(grouped))
    }
  }, [courses]);


  //cohorts to choose from after course is selected
  useEffect(() => {
    if (student && currentSelectedCourseId && currentSelectedCourseId != null) {
      getAssociatedCohorts(currentSelectedCourseId, user.idToken).then(
        (result) => {
          setCohortOptions(result.data);
        }
      );

      getCourseModules(currentSelectedCourseId, user.idToken).then(
        (result) => {
          let compulsoryModules = []
          let electiveModules = []

          for (let r of result) {
            if (r.isCompulsory == "TRUE") {
              compulsoryModules.push(r)
            }
            if (r.isCompulsory == "FALSE") {
              electiveModules.push(r)
            }
          }
          setCompulsoryModules(compulsoryModules)
          setElectiveModules(electiveModules)
          setModuleOptions(result);

        }
      );
    }
  }, [currentSelectedCourseId]);

  useEffect(() => {
    if (newCohort && newCohort != null) {


    }
  }, [newCohort]);

  //End Data Fetching

  const handleSaveNewCohort = () => {
    if (user && user != null) {
      setSaveLoading(true);
      let fullNewCohort = {};

      for (let c of cohortOptions) {
        if (newCohort == c._id) {
          fullNewCohort = JSON.parse(JSON.stringify(c));
        }
      }

      let enrolment = {
        courseId: currentSelectedCourseId,
        cohortId: newCohort,
        exitPoint: exitPoint,
        membership: selectedMembership,
      }
      assignNewCohort(
        student, enrolment,
        newCohort,
        fullNewCohort,
        selectedModules,
        selectedMembership,
        user.idToken
      )
        .then((result) => {
          getStudent(student._id, user.idToken).then((getStudentResult) => {
            setStudent(getStudentResult);
            setSaveLoading(false);
            handleClose()
            toast.success(
              `${student.firstName} ${student.lastName} has been assigned to ${fullNewCohort.title}`
            );
            // window.location.reload();
          });
        })
        .catch((error) => {
          toast.error(`${error}`);
          setSaveLoading(false);
        });
    }
  };

  const handleClose = () => {
    setClose();
  };


  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  const handleSelectedModuleToggle = (moduleId, isChecked) => {
    if (isChecked) {
      if (selectedModules.indexOf(moduleId) < 0) {
        let newSelectedModules = selectedModules;
        newSelectedModules.push(moduleId);
        setSelectedModules(newSelectedModules);
      }
    } else {
      if (selectedModules.indexOf(moduleId) >= 0) {
        let newSelectedModules = [];
        for (let s of selectedModules) {
          if (s != moduleId) {
            newSelectedModules.push(s);
          }
        }
        setSelectedModules(newSelectedModules);
      }
    }
  };



  let allExitPoints = [
    ["Award", "Certificate", "Custom"],
    ["Certificate", "Diploma", "Higher Diploma", "Custom"],
    ["Certificate", "Diploma", "Higher Diploma", "B.Sc", "Custom"],
    ["Postgraduate Certificate", "Postgraduate Diploma", "M.Sc", "Custom"],
  ]

  const handleExitPoints = (mqfLevel) => {
    if (mqfLevel == "4") {
      setExitPoints(allExitPoints[0])
    }
    if (mqfLevel == "5") {
      setExitPoints(allExitPoints[1])
    }
    if (mqfLevel == "6") {
      setExitPoints(allExitPoints[2])
    }
    if (mqfLevel == "7") {
      setExitPoints(allExitPoints[3])
    }
  }

  const handleSelectExitPoint = (value) => {
    setExitPoint(null)
    setSelectedModules([])

    if (value == "Custom") {
      setIsModulesChecked(false)
    } else {
      setIsModulesChecked(true)
      let compulsoryIds = compulsoryModules.map(m => m._id);
      setSelectedModules(compulsoryIds)
    }

    setTimeout(() => {
      setExitPoint(value)
    }, 100)

  }





  return (
    <Popup
      title="Assign this student to a cohort"
      subtitle="You can assign a new cohort to this student below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveNewCohort()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          {/* <Typography>
            Select a course.
          </Typography> */}
          <FormControl
            className={classes.inputType}
            error={currentSelectedCourseIdError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Select Course
            </InputLabel>
            {courses && courses != null && (
              <Select
                elevation={0}
                onChange={(event) => {
                  let id = event.target.value
                  handleExitPoints(courses.find(x => x._id === id).mqfLevel);
                  setCurrentSelectedCourseId(event.target.value)
                }
                }
                value={currentSelectedCourseId}
                required
                error={currentSelectedCourseIdError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {/* {courses.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })} */}
                {groupedCourses.flat(2).map((item, index) => {
                  if (typeof item === 'object') {
                    return <MenuItem value={item._id}>{item.title}</MenuItem>;

                  } else {
                    return <ListSubheader> MQF Level {item}</ListSubheader>;
                  }
                })}
              </Select>
            )}
            <FormHelperText>{currentSelectedCourseIdError}</FormHelperText>
          </FormControl>
          <FormControl className={classes.inputType} error={cohortOptionsError}>
            <InputLabel required disabled={saveLoading}>
              Select Cohort
            </InputLabel>
            {cohortOptions && cohortOptions != null && (
              <Select
                elevation={0}
                onChange={(event) => {
                  setNewCohort(event.target.value)
                }}
                value={newCohort}
                required
                error={newCohortError != null}
                disabled={saveLoading}
                input={<Input />}
              >
                {cohortOptions.map((item, index) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}

              </Select>
            )}
            <FormHelperText>{cohortOptionsError}</FormHelperText>
          </FormControl>
          {newCohort &&
            newCohort != null &&
            (
              <FormControl
                className={classes.inputType}
                error={selectedMembershipError}
              >
                <InputLabel id="membership-type-label">
                  Exit Point
                </InputLabel>
                <Select
                  labelId="membership-type-label"
                  id="membership-type"
                  value={exitPoint}
                  onChange={(event) => {
                    handleSelectExitPoint(event.target.value)
                  }
                  }
                >
                  {exitPoints.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}
          {membershipOptions &&
            membershipOptions != null &&
            newCohort &&
            newCohort != null && (
              <FormControl
                className={classes.inputType}
                error={selectedMembershipError}
              >
                <InputLabel id="membership-type-label">
                  Membership Type
                </InputLabel>
                <Select
                  labelId="membership-type-label"
                  id="membership-type"
                  value={selectedMembership}
                  onChange={(event) =>
                    setSelectedMembership(event.target.value)
                  }
                >
                  {membershipOptions.map((item) => {
                    return <MenuItem value={item.value}>{item.text}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}

          {exitPoint && exitPoint != null && moduleOptions && moduleOptions != null && (<>
            <InputLabel id="membership-type-label" className={classes.inputModels}>
              Models
            </InputLabel>
            <FormControl className={classes.formControl}>
              <h5>Electives</h5>
              <List>
                {electiveModules.map((mod) => {
                  return (
                    <ListItem key={mod._id} >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          onChange={(event) => {
                            handleSelectedModuleToggle(
                              mod._id,
                              event.target.checked
                            );
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${mod.title}`} />
                    </ListItem>
                  );
                })}
              </List>
              <h5>Compulsory</h5>
              <List>
                {compulsoryModules.map((mod) => {
                  return (
                    <ListItem key={mod._id} >
                      <ListItemIcon>
                        <Checkbox
                          defaultChecked={isModulesChecked}
                          edge="start"
                          tabIndex={-1}
                          onChange={(event) => {
                            handleSelectedModuleToggle(
                              mod._id,
                              event.target.checked
                            );
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${mod.title}`} />
                    </ListItem>
                  );
                })}
              </List>
            </FormControl></>
          )}
        </div>
      }
    ></Popup>
  );
});
