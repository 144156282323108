import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
// import { getSingleCohort } from "repository/actions/data/dashboard/cohorts";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { SingleCohortContext } from "repository/stores/dashboard/cohorts/cohorts-single-provider";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";

import { AllLecturesContext } from "repository/stores/dashboard/lectures/lectures-all-provider";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";

import { Grid } from "@material-ui/core";
// Navigation
import NavCrumbs from "./cohorts-single-cohort-nav-crumbs";

// Components
import Details from "./details";
import Students from "./students";
import Lectures from "./lectures";
import Broadcast from "./broadcast";
import Modules from "./modules";
import Lecturers from "./lecturers";
import Assignments from "./assignmnets";

export default withRouter(function (props) {
  const cohortId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const { bulkCreateLecture, bulkDeleteLecture, deleteLecture, editLecture } =
    useContext(AllLecturesContext);
  const { locations, setLocations, getAllLocations } =
    React.useContext(AllLocationsContext);
  const { personnel, setPersonnel, findAllPersonnelAlgolia } =
    React.useContext(AllPersonnelContext);
  const { getCohort, bulkAssignLecturers } = React.useContext(AllCohortsContext);
  const {
    cohort,
    setCohort,
    editCohort,
    assignLecturer,
    deleteLecturer,
    generateTranscript,
    generateMissingSubmissions,
    extractAssignmentActivity,
    bulkGenerateTranscript,
    bulkDiplomaSupplement,
    bulkRecordOfWork,
    bulkTranscriptPdf,
    bulkCompletionLetter,
    bulkCertificate,
    convertStudentsToComplete
  } = useContext(SingleCohortContext);

  useEffect(() => {
    if (
      user &&
      user != null &&
      cohortId &&
      cohortId != null &&
      cohortId.length > 0
    ) {
      try {
        getCohort(cohortId, user.idToken).then((result) => {
          setCohort(result);
        });
      } catch (e) {
        alert("Can't get this cohort.");
      }
    }
  }, []);

  
  return (
    <NavCrumbs cohort={cohort}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Details
            cohort={cohort}
            user={user}
            setCohort={setCohort}
            getCohort={getCohort}
            editCohort={editCohort}
            generateTranscript={generateTranscript}
            generateMissingSubmissions={generateMissingSubmissions}
            extractAssignmentActivity={extractAssignmentActivity}
          />
        </Grid>
        <Grid item xs={5}>
          <Lecturers
            cohort={cohort}
            user={user}
            setCohort={setCohort}
            getCohort={getCohort}
            editCohort={editCohort}
            assignLecturer={assignLecturer}
            deleteLecturer={deleteLecturer}
            bulkAssignLecturers={bulkAssignLecturers}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: 30 }}></div>

      <Assignments
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        bulkCertificate={bulkCertificate}
        bulkRecordOfWork={bulkRecordOfWork}
        bulkTranscriptPdf={bulkTranscriptPdf}
        bulkCompletionLetter={bulkCompletionLetter}
        bulkDiplomaSupplement={bulkDiplomaSupplement}
        bulkGenerateTranscript={bulkGenerateTranscript}
      />

      <div style={{ marginTop: 30 }}></div>

      <Students
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        convertStudentsToComplete={convertStudentsToComplete}
      />
      <div style={{ marginTop: 30 }}></div>

      <Modules
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
      />
      <div style={{ marginTop: 30 }}></div>
     
      <Lectures
        cohort={cohort}
        user={user}
        setCohort={setCohort}
        getCohort={getCohort}
        editCohort={editCohort}
        assignLecturer={assignLecturer}
        locations={locations}
        getAllLocations={getAllLocations}
        bulkCreateLecture={bulkCreateLecture}
        bulkDeleteLecture={bulkDeleteLecture}
        editLecture={editLecture}
        deleteLecture={deleteLecture}
        findAllPersonnelAlgolia={findAllPersonnelAlgolia}
      />
     
    </NavCrumbs>
  );
});
