

import React, { useContext, useEffect, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  tickets: null,
  setTickets: {},
  getTickets: {},
  getTicket: {},
  loadingTickets: false,
  deleteTicket: {},
  createNewTicket: {},
  editTicket: {}

};

export const AllTicketsContext = React.createContext(ContextProps);

export const AllTicketsProvider = ({ children }) => {
  const [tickets, setTickets] = useState(null);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const { user } = useContext(AuthenticationContext);

  const { getIdToken } = useContext(IdTokenContext)


  const getTickets = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingTickets(true);

        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/clients").find({
            query: {
              firebaseToken: token,
            },
          });

          setTickets(result.data);
          resolve(result);
        })

      } catch (e) {
        setLoadingTickets(false);
        reject(e);
      }
    });
  };

  const getTicket = (ticketId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingTickets(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/tickets").get(ticketId, {
            query: {
              firebaseToken: token,
            },
          });
          setLoadingTickets(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const createNewTicket = async (ticket, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/tickets").create(
            { ...ticket },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          if (tickets == null) {
            setTickets(result);
          } else {
            let newTickets = tickets
            newTickets.push(result)
            setTickets(newTickets);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editTicket = async (ticketId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedTickets = await apiClient
            .service("v1/tickets")
            .patch(ticketId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteTicket = async (ticketId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/tickets").remove(ticketId, {
            query: {
              firebaseToken: token,
            },
          });

          let newData = tickets
          for (const [i, v] of newData.entries()) {
            if (v._id === ticketId) {
              newData.splice(i, 1);
            }
          }
          setTickets(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllTicketsContext.Provider
      value={{
        tickets,
        setTickets,
        createNewTicket,
        getTicket,
        getTickets,
        editTicket,
        deleteTicket,
        loadingTickets
      }}
    >
      {children}
    </AllTicketsContext.Provider>
  );
};
