import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper, Grid } from "@material-ui/core";
import moment from "moment";
// Navigation

import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  Toolbar,
  AppointmentForm,
  ViewSwitcher,
} from "@devexpress/dx-react-scheduler-material-ui";
import styles from "routes/dashboard/dashboard-styles";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { loading, user, hourInfo, leaveTaken, leaveRemaining } = props;

  const currentDate = new Date().toISOString().slice(0, 10);

  const [workingHours, setWorkingHours] = useState(null);
  const [breakHours, setBreakHours] = useState(null);
  // const [leaveTaken, setLeaveTaken] = useState(null)

  const formatHours = (hours) => {
    var h = Math.floor(hours / 3600);
    var m = Math.floor((hours % 3600) / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
    return hDisplay + mDisplay;
  };

  useEffect(() => {
    if (hourInfo) {
      let total = hourInfo.working;
      let totalBreak = hourInfo.break;

      setWorkingHours(formatHours(total));
      setBreakHours(formatHours(total - totalBreak));
    }
  }, [hourInfo]);

  return (
    <>
      {hourInfo != null && (
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Paper style={{ padding: 25 }}>
              <Typography variant="h4" color="grey" align="center">
                {workingHours}
              </Typography>
              <Typography variant="body2" color="text.primary" align="center">
                Total Hours
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper style={{ padding: 25 }}>
              <Typography variant="h4" color="grey" align="center">
                {breakHours}
              </Typography>
              <Typography variant="body2" color="text.primary" align="center">
                Working Hours
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper style={{ padding: 18 }}>
              <Typography variant="h3" color="grey" align="center">
                {leaveTaken}
              </Typography>
              <Typography variant="body2" color="text.primary" align="center">
                Leave Taken
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper style={{ padding: 18 }}>
              <Typography variant="h3" color="grey" align="center">
                {leaveRemaining ? leaveRemaining.toFixed(2) : leaveRemaining}
              </Typography>
              <Typography variant="body2" color="text.primary" align="center">
                Leave Remaining
              </Typography>
              
              {/* <Typography variant="body1" color="grey" align="center">
                {leaveRemaining ? (parseFloat(leaveRemaining) - 80).toFixed(2) : leaveRemaining}
              </Typography> */}
              {/* <Typography variant="body2" color="text.primary" align="center">
                - minus Bridge Holidays (16hrs) & Shutdowns (~64hrs) -
              </Typography> */}
             
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
