import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { toast } from "react-toastify";
import { FormControl, TextField } from "@material-ui/core";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(styles);

function AssignSharepointUrlDialogue(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    user,
    student,
    setStudent,
    editStudent,
    getStudent,
  } = props;

  const [sharepointUrl, setSharepointUrl] = useState(null);
  const [saveLoading, setSaveLoading] = useState(null);
  const [sharepointUrlError, setSharepointUrlError] = useState(null);

  const handleSaveUrl = () => {
    if (user && user != null) {
      if (sharepointUrl && sharepointUrl != null && sharepointUrl.length > 0) {
        toast.dark(
          `Assigning Sharepoint URL to ${student.firstName} ${student.lastName}`
        );

        setSaveLoading(true);

        let patchData = {
          sharepointUrl : sharepointUrl.indexOf('http') >= 0 ? sharepointUrl : `https://${sharepointUrl}`,
        };

        editStudent(student._id, patchData, user.idToken)
          .then((result) => {
            setSaveLoading(false);
            toast.success(`Sharepoint URL has been updated`);
            setClose();
          })
          .catch((e) => {
            toast.error(`Oops! Something went wrong. ${e.message}`);
          });
      } else {
        toast.error(`You have missing information for assigning a Sharepoint URL`);
        if (sharepointUrl.length == 0 || sharepointUrl == null) {
          setSharepointUrlError("Enter a valid url");
        }
      }
    }
  };

  useEffect(() => {
    if (student && user && student != null && user != null) {
      setSharepointUrl(student.sharepointUrl);
    }
  }, [student, user]);

  return (
    <Popup
      title="Assign Sharepoint URL"
      subtitle="This is the URL to access this student's Sharepoint folder"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveUrl()}
      actionButtonTitle="Assign"
      disabled={saveLoading}
      content={
        <div className={classes.popup}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: https://ideaed.instructure.com/users/XXXX "}
              disabled={saveLoading}
              required
              helperText={sharepointUrlError}
              error={sharepointUrlError != null}
              label="Sharepoint URL"
              value={sharepointUrl}
              onChange={(event) => setSharepointUrl(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
        </div>
      }
    />
  );
}

export default withRouter(AssignSharepointUrlDialogue);
