import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SingleCohortBroadcastView from "./single-cohort-broadcast-view";
import { AllBroadcastsContext } from "repository/stores/dashboard/broadcast/broadcast-all-provider";

// Dialogues
import DialogueCreateBroadcast from "routes/dashboard/dialogues/broadcasts/create-broadcast";
import DialogueViewBroadcast from "routes/dashboard/dialogues/broadcasts/view-broadcast";

// Actions
// import { findAllBroadcasts } from "repository/actions/data/dashboard/broadcasts";

export default withRouter(function (props) {
  let { cohort, setCohort, user } = props;

  const {broadcasts, setBroadcasts, getBroadcasts, createNewBroadcast} = useContext(AllBroadcastsContext);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // const [broadcasts, setBroadcasts] = useState(null);
  const [showNewBroadcastDialogue, setShowNewBroadcastDialogue] =
    useState(false);
  const [showViewBroadcastDialogue, setShowViewBroadcastDialogue] =
    useState(false);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (broadcasts == null) {
        if (cohort != null) {
          getBroadcasts(cohort._id, user.idToken)
        }
      }
    }
  }, [broadcasts, cohort]);

  // Editing Item
  let editingItem = null;
  if (broadcasts != null) {
    if (broadcasts[selectedIndex]) {
      editingItem = broadcasts[selectedIndex];
    }
  }

  return (
    <div>
      <SingleCohortBroadcastView
        loading={!broadcasts}
        toggleAddBroadcastForm={() => setShowNewBroadcastDialogue(true)}
        onPressedItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowViewBroadcastDialogue(true);
        }}
        sectionDescription={
          "All emails send to students will be displayed here."
        }
        broadcasts={broadcasts || []}
        setBroadcasts={setBroadcasts}
        setCohort={setCohort}
        user={user}
      />
      <DialogueCreateBroadcast
        isOpen={showNewBroadcastDialogue}
        setClose={() => setShowNewBroadcastDialogue(false)}
        broadcasts={broadcasts}
        setBroadcasts={setBroadcasts}
        cohort={cohort}
        setCohort={setCohort}
        user={user}
        createNewBroadcast={createNewBroadcast}
      />
      <DialogueViewBroadcast
        isOpen={showViewBroadcastDialogue}
        setClose={() => {
          setShowViewBroadcastDialogue(false);
          setSelectedIndex(null);
        }}
        cohort={cohort}
        broadcast={editingItem}
        itemId={selectedId}
        setCohort={setCohort}
        user={user}
      />
    </div>
  );
});
