import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
// import { AllModulesContext } from "repository/stores/dashboard/modules/modules-all-provider";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
// import { editModule } from "repository/actions/data/dashboard/modules";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, editModule, courses, getCourses } = props;

  // const {modules, setModules, editModule} = React.useContext(AllModulesContext);
  // const {courses, setCourses} = React.useContext(AllCoursesContext);

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [referenceNumber, setReferenceNumber] = React.useState(null);
  const [assignedCourse, setAssignedCourse] = React.useState(null);
  const [ects, setEcts] = React.useState(null);
  const [isCompulsory, setIsCompulsory] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [descriptionError, setDescriptionError] = React.useState(null);
  const [mqfError, setMqfError] = React.useState(null);
  const [referenceError, setReferenceError] = React.useState(null);
  const [assignedCourseError, setAssignedCourseError] = React.useState(null);
  const [ectsError, setEctsError] = React.useState(null);
  const [isCompulsoryError, setIsCompulsoryError] = React.useState(null);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
      setMqfLevel(data.mqfLevel);
      setReferenceNumber(data.referenceNumber);
      setAssignedCourse(data.assignedCourseId);
      setEcts(data.ects);
      setIsCompulsory(data.isCompulsory);
    }
  }, [data]);

  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, []);

  const handleEditModule = () => {
    if (user && user != null) {
      if (
        title == null ||
        description == null ||
        mqfLevel == null ||
        referenceNumber == null ||
        assignedCourse == null
      ) {
        toast.error(`You have missing information for editing this module`);
        if (title === null) {
          setTitleError("Title is required.");
        }

        if (description == null) {
          setDescriptionError("Description is required.");
        }

        if (mqfLevel == null) {
          setMqfError("MQF Level is required.");
        }

        if (referenceNumber == null) {
          setReferenceError("Reference Number is required.");
        }

        if (ects == null) {
          setEctsError("ECTS quantity is required.");
        }

        if (assignedCourse == null) {
          setAssignedCourseError("Assigned Courses is required.");
        }
      } else {
        let saveData = {
          title: title,
          description: description,
          mqfLevel: mqfLevel,
          referenceNumber: referenceNumber,
          assignedCourseId: assignedCourse,
          ects: ects,
          isCompulsry: isCompulsory,
        };

        setSaveLoading(true);

        editModule( saveData, itemId, user.idToken)
          .then((result) => {
            toast.success(`Module ${saveData.title} successfully edited`);
            setClose();
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Edit existing module"
      subtitle="You can modify the following course details."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleEditModule()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Module Title"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Module Description"}
              disabled={saveLoading}
              required
              helperText={descriptionError}
              error={descriptionError != null}
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: MQF Level"}
              disabled={saveLoading}
              required
              helperText={mqfError}
              error={mqfError != null}
              label="MQF Level"
              value={mqfLevel}
              onChange={(event) => setMqfLevel(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: M1263"}
              disabled={saveLoading}
              required
              helperText={referenceError}
              error={referenceError != null}
              label="Reference Number"
              value={referenceNumber}
              onChange={(event) => setReferenceNumber(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: 6"}
              disabled={saveLoading}
              required
              helperText={ectsError}
              error={ectsError != null}
              label="ECTS"
              value={ects}
              onChange={(event) => setEcts(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl
            className={classes.inputType}
            error={assignedCourseError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Assigned Course
            </InputLabel>
            {courses != null && (
              <Select
                elevation={0}
                onChange={(event) => setAssignedCourse(event.target.value)}
                value={assignedCourse}
                required
                error={assignedCourseError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {courses.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            )}
            <FormHelperText>{assignedCourseError}</FormHelperText>
          </FormControl>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
