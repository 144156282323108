import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  TextField,
  FormControl,
  Grid,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    months,
    programme,
    setProgramme,
    isNewProgramme,
    panelDescription,
    programmeTotalCosts,
    setProgrammeTotalCosts,
  } = props;
  const classes = useStyles();

  const programmeCostings = [
    { title: "Rent (Per Month)", cost: 250 },
    { title: "Marketing", cost: 3500 },
    { title: "Admin", cost: 10000 },
    { title: "Commissions", cost: 2500 },
  ];

  const [programmeCosts, setProgrammeCosts] = useState(programmeCostings);
  const [programmeSum, setProgrammeSum] = useState(0);

  const [applicaitonCost, setApplictionCost] = useState(0);
  const [curriculumCost, setCurriculumCost] = useState(0);

  const [selectedLevel, setSelectedLevel] = useState(null);

  useEffect(() => {
    if (!isNewProgramme) {
      setSelectedLevel(programme.courseLevel);
      handleSelectedLevel(programme.courseLevel);
      setProgrammeCosts(programme.programmeCosts);
    }
  }, [isNewProgramme]);

  useEffect(() => {
    let sum = programmeCosts.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.cost);
    }, 0);

    sum = sum + curriculumCost + applicaitonCost;

    sum = sum + programmeCosts[0].cost * (months-1) ;

    setProgrammeSum(sum);

    setProgrammeTotalCosts({
      ...programmeTotalCosts,
      programmeTotalCosts: sum,
      programmeCosts: programmeCosts,
      courseLevel: selectedLevel,
    });

    
    setProgramme({
      ...programme,
      programmeCosts: programmeCosts,
      programmeTotalCosts: sum,
      courseLevel: selectedLevel,
    });

    
  }, [programmeCosts, curriculumCost, applicaitonCost, months]);

  const handleCostChange = (event, index) => {
    let costs = [...programmeCosts];
    costs[index].cost = event.target.value ? parseFloat(event.target.value) : 0;
    setProgrammeCosts(costs);
  };

  const handleSelectedLevel = (level) => {
    if (level == 0) {
      setCurriculumCost(1000);
      setApplictionCost(500);
    }
    if (level == 1) {
      setCurriculumCost(1500);
      setApplictionCost(700);
    }
    if (level == 2) {
      setCurriculumCost(2000);
      setApplictionCost(1200);
    }
    if (level == 3) {
      setCurriculumCost(1500);
      setApplictionCost(1000);
    }
    if (level == 4) {
      setCurriculumCost(3500);
      setApplictionCost(2500);
    }
    if (level == 5) {
      setCurriculumCost(2000);
      setApplictionCost(2500);
    }
    if (level == 6) {
      setCurriculumCost(10000);
      setApplictionCost(8000);
    }
  };

  function numberWithCommas(x) {
    if (!x) return "";
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Panel
      title={`Cost Per Programme`}
      loading={loading}
      panelDescription={panelDescription}
    >
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" variant="outlined">
          Course Level
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          label="Course Level"
          value={selectedLevel || ""}
          onChange={(event) => {
            setSelectedLevel(event.target.value);
            handleSelectedLevel(event.target.value);
          }}
          style={{ textAlign: "left" }}
          className={classes.costings}
        >
          <MenuItem value={0}>Certificate</MenuItem>
          <MenuItem value={1}>Diploma</MenuItem>
          <MenuItem value={2}>Higher Diploma</MenuItem>
          <MenuItem value={3}>B.Sc Top Up</MenuItem>
          <MenuItem value={4}>B.Sc</MenuItem>
          <MenuItem value={5}>M.Sc</MenuItem>
          <MenuItem value={6}>PhD</MenuItem>

        </Select>
      </FormControl>

      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid item xs={7}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Expense"
              variant="outlined"
              type="text"
              value={"Curriculum"}
              size="small"
              className={classes.costings}
              inputProps={{
                readOnly: true,
                style: {
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="number"
              value={curriculumCost}
              onChange={(event) =>
                setCurriculumCost(parseFloat(event.target.value))
              }
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid item xs={7}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Expense"
              variant="outlined"
              type="text"
              value={"MFHEA Application"}
              size="small"
              className={classes.costings}
              inputProps={{
                readOnly: true,
                style: {
                  fontSize: 15,
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="number"
              value={applicaitonCost}
              onChange={(event) =>
                setApplictionCost(parseFloat(event.target.value))
              }
              size="small"
              className={classes.costings}
              inputProps={{
                style: {
                  fontSize: 15,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {programmeCosts.map((item, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={7}>
              <FormControl className={classes.inputType}>
                <TextField
                  placeholder="Expense"
                  variant="outlined"
                  type="text"
                  value={item.title}
                  size="small"
                  className={classes.costings}
                  inputProps={{
                    readOnly: true,
                    style: {
                      fontSize: 15,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl className={classes.inputType}>
                <TextField
                  placeholder="Cost"
                  variant="outlined"
                  type="number"
                  value={item.cost}
                  onChange={(event) => handleCostChange(event, index)}
                  size="small"
                  className={classes.costings}
                  inputProps={{
                    style: {
                      fontSize: 15,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      })}

      <Grid
        container
        spacing={2}
        style={{ marginTop: 30, justifyContent: "space-around" }}
      >
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="text"
              value={numberWithCommas(parseInt(programmeSum))}
              size="small"
              className={classes.costings}
              color="secondary"
              focused
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Panel>
  );
});
