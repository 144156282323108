import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  Paper,
} from "@material-ui/core";
import styles from "./single-person-qualifications-styles";
import Panel from "components/panel";
import DialogueEditHighestQualifications from "routes/dashboard/dialogues/personnel/edit-highest-qualifications";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { person, setPerson, user } = props;
  const [qualifications, setQualifications] = useState([]);
  const [
    showEditHighestQualificationsDialogue,
    setShowEditHighestQualificationsDialogue,
  ] = useState(false);

  useEffect(() => {
    setQualifications(person.highest_qualifications);
  }, [person]);

  return (
    <div>
      <Panel
        title="Highest Qualifications"
        loading={false}
        sectionDescription="Highest qualifications related to the person above."
        actionName="Edit"
        handleAction={() => setShowEditHighestQualificationsDialogue(true)}
      >
        {qualifications != null && qualifications.length > 0 && (
          <List>
            {qualifications.map((role) => (
              <ListItem className={classes.labelText}>• {role}</ListItem>
            ))}
          </List>
        )}
        {qualifications == null && (
          <Typography className={classes.loading}>Loading...</Typography>
        )}
        {qualifications != null && (
          <div>
            {qualifications.length == 0 && (
              <Typography className={classes.loading}>
                This person has no highest qualifications listed to them
              </Typography>
            )}
          </div>
        )}

        <DialogueEditHighestQualifications
          isOpen={showEditHighestQualificationsDialogue}
          setClose={() => setShowEditHighestQualificationsDialogue(false)}
          personId={person._id}
          person={person}
          setQualifications={setQualifications}
          user={user}
          setPerson={setPerson}
        />
      </Panel>
    </div>
  );
}
