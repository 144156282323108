import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Link,
  Popper,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { Visibility, Delete, Edit } from "@material-ui/icons";
import { capitalizeFirstLetter } from "utils/helpers/string";
import Moment from "react-moment";
import { getSearchClient } from "utils/algolia";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";
//InstantSearch
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  Highlight,
  Stats,
} from "react-instantsearch-dom";
import Select from "react-select";

// Navigation
import NavCrumbs from "./students-all-students-nav-crumbs";

import BackupIcon from "@material-ui/icons";
import ElectricBoltIcon from "@material-ui/icons";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { backupData } from "repository/actions/data/dashboard/backup";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();

  const {
    loading,
    toggleAddStudentForm,
    onPressedItem,
    students,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    user,
    refreshAlgolia,
    campus,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { cohorts, setCohorts, getCohorts } =
    React.useContext(AllCohortsContext);
  const [cohortMap, setCohortMap] = React.useState(null);
  const [cohortOptions, setCohortOptions] = React.useState([]);
  const [selectedCohorts, setSelectedCohorts] = React.useState([]);

  const [loadingBackupData, setLoadingBackupData] = React.useState(false);

  const [cohortMapLoaded, setCohortMapLoaded] = React.useState(false);

  const [internationalOptions, setInternationalOptions] = React.useState([
    { value: "true", label: "International" },
    { value: "false", label: "Local" },
  ]);
  const [selectedInternationalOptions, setSelectedInternationalOptions] =
    React.useState([]);

  const [statusOptions, setStatusOptions] = React.useState([
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ]);
  const [selectedStatusOptions, setSelectedStatusOptions] = React.useState([
    "Active",
  ]);

  const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const Pagination = ({
    currentRefinement,
    nbPages,
    refine,
    createURL,
    padding = 3,
  }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {range(
            Math.max(1, currentRefinement - padding),
            Math.min(nbPages, currentRefinement + padding)
          ).map((page) => {
            // const page = index + 1;
            return (
              <li
                key={page}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a student"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, currentRefinement, refine, createURL }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let cohortTitle = cohortMap[row.cohortIds[0]]
            ? cohortMap[row.cohortIds[0]].title
            : "--";
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {cohortTitle}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellTitle}
                align="left"
              >
                {row.title && capitalizeFirstLetter(row.title)}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.firstName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.lastName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                <Moment format="D MMMM, YYYY" date={row.createdAt} />
              </TableCell>
              <TableCell
                // onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedEditItem(index, row.objectID);
                  }}
                >
                  <Edit />
                </IconButton>
                {/* <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <Delete />
                </IconButton> */}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  //Begin Filtering

  const getCohortsMap = async () => {
    return new Promise(async (resolve, reject) => {
      if (cohorts == null) {
        await getCohorts(user.idToken);
      } else {
        let newCohortMap = {};
        let newCohortOptions = [];

        for (let l of cohorts) {
          newCohortMap[l._id] = l;

          let option = {
            value: l._id,
            label: l.title,
          };
          newCohortOptions.push(option);
        }
        setCohortMap(newCohortMap);
        setCohortOptions(newCohortOptions);
        resolve(newCohortMap);
      }
    });
  };

  React.useEffect(() => {
    if (user && user != null) {
      getCohortsMap().then(() => {
        setCohortMapLoaded(true);
      });
    }
  }, [cohorts]);

  const handleCohortSelectionChange = (selectedCohortOptions) => {
    let newSelectedCohorts = [];
    let newSelectedCohortIds = [];
    if (
      selectedCohortOptions &&
      selectedCohortOptions != null &&
      selectedCohortOptions.length > 0
    ) {
      for (let sco of selectedCohortOptions) {
        newSelectedCohorts.push(sco);
        newSelectedCohortIds.push(sco.value);
      }
    }
    setSelectedCohorts(newSelectedCohortIds);
  };

  const handleInternationalSelectionChange = (
    updatedSelectedInternationalOptions
  ) => {
    let newSelectedInternationals = [];
    if (
      updatedSelectedInternationalOptions &&
      updatedSelectedInternationalOptions != null &&
      updatedSelectedInternationalOptions.length > 0
    ) {
      for (let si of updatedSelectedInternationalOptions) {
        newSelectedInternationals.push(si.value);
      }
    }
    setSelectedInternationalOptions(newSelectedInternationals);
  };

  const handleStatusSelectionChange = (updatedStatusSelectionOptions) => {
    let newSelectedStatuses = [];
    if (
      updatedStatusSelectionOptions &&
      updatedStatusSelectionOptions != null &&
      updatedStatusSelectionOptions.length > 0
    ) {
      for (let si of updatedStatusSelectionOptions) {
        newSelectedStatuses.push(si.value);
      }
    }
    setSelectedStatusOptions(newSelectedStatuses);
  };

  const RefinementList = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementList);

  //End Filtering

  //Backup Data
  const backupDataAction = async () => {
    setLoadingBackupData(true);

    await backupData(user.idToken)
      .then(() => {
        setLoadingBackupData(false);
        toast.success("Back up Complete");
      })
      .catch((e) => {
        toast.error("Back up Failed");
        console.log(e);
        setLoadingBackupData(false);
      });
  };

  return (
    <NavCrumbs>
      <Button
        className={classes.backupBtn}
        onClick={() => {
          backupDataAction();
        }}
      >
        {!loadingBackupData && <span>Backup All Data</span>}
        {loadingBackupData && <span>Loading ... </span>}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.spacing}
          width="20"
          fill="none"
          viewBox="0 0 25 25"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.spacing}
          width="20"
          viewBox="0 0 25 25"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
            clip-rule="evenodd"
          />
        </svg>
      </Button>
      <Section
        title="MANAGE YOUR STUDENTS"
        loading={!cohortMapLoaded}
        actionName="Add student"
        handleAction={toggleAddStudentForm}
        sectionDescription={sectionDescription}
        backupData={true}
        backupDataAction={backupDataAction}
      >
        {students.length > 0 && cohortMapLoaded && (
          <InstantSearch
            indexName="Students"
            searchClient={searchClient}
            refresh={refreshAlgolia}
          >
            <CustomRefinementList
              attribute="campus"
              operator="or"
              defaultRefinement={campus}
            />
            <Paper elevation={3} className={classes.header}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <CustomSearchBox />
                </Grid>
                <Grid item xs={2}>
                  <Stats
                    translations={{
                      stats(
                        nbHits,
                        processingTimeMS,
                        nbSortedHits,
                        areHitsSorted
                      ) {
                        return (
                          <Typography className={classes.stat}>
                            {nbHits} students in {processingTimeMS} ms
                          </Typography>
                        );
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="cohortIds"
                    operator="or"
                    defaultRefinement={selectedCohorts}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Cohorts
                  </Typography>
                  <Select
                    options={cohortOptions}
                    isMulti
                    isClearable
                    placeholder="Select cohorts"
                    onChange={handleCohortSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="isInternational"
                    operator="or"
                    defaultRefinement={selectedInternationalOptions}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    International / Local
                  </Typography>
                  <Select
                    options={internationalOptions}
                    isMulti
                    isClearable
                    placeholder="Select international status"
                    onChange={handleInternationalSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomRefinementList
                    attribute="status"
                    operator="or"
                    defaultRefinement={selectedStatusOptions}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.filterHeading}
                  >
                    Status
                  </Typography>
                  <Select
                    options={statusOptions}
                    isMulti
                    isClearable
                    defaultValue={{ value: "Active", label: "Active" }}
                    placeholder="Select student activity"
                    onChange={handleStatusSelectionChange}
                    className={classes.selectContainer}
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Cohort
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        First Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Last Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Added on
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
            <CustomPagination />
          </InstantSearch>
        )}
        {students.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You don't have any students yet.
          </Typography>
        )}
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      onClick={() => {
                        onPressedItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      View
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedEditItem(selectedIndex, selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedDeleteItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Section>
    </NavCrumbs>
  );
}
