import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { getSearchClient } from "utils/algolia";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { SingleStudentContext } from "repository/stores/dashboard/students/students-single-provider";
const searchClient = getSearchClient();
const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    user,
    editApplicant,
    getApplicant,
    applicant,
    setApplicant,
    setRefreshAlgolia,
  } = props;

  let localStatus = [
    "Awaiting Docs",
    "Awaiting Signature",
    "Confirmed LOA",
    "Course Started",
    "Cancelled",
    "Deferred",
  ];
  let internationalStatus = [
    "Applied",
    "Pending Interview",
    "Conditional Letter Issued",
    "Unconditional Letter Issued",
    "Sent CV",
    "VISA Accepted",
    "VISA Rejected",
    "Problem with Application",
    "Cancelled",
    "Course Started",
  ];

  const { findStudent } = useContext(SingleStudentContext);
  const [studentNumber, setStudentNumber] = React.useState(null);
  const [identificationNumber, setIdentificationNumber] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [gender, setGender] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [cohortId, setCohortId] = React.useState(null);
  const [applicationDate, setApplicationDate] = React.useState(null);
  const [travellingToMaltaDate, setTravellingToMaltaDate] =
    React.useState(null);

  const [selectedStatus, setSelectedStatus] = React.useState(localStatus);
  const [isInternational, setIsInternational] = React.useState(false);

  const [addressHouse, setAddressHouse] = React.useState(null);
  const [addressCity, setAddressCity] = React.useState(null);
  const [addressStreet, setAddressStreet] = React.useState(null);
  const [addressPostcode, setAddressPostcode] = React.useState(null);
  const [addressCountry, setAddressCountry] = React.useState(null);
  const [campus, setCampus] = React.useState(null);
  const [mobile, setMobile] = React.useState(null);

  const [hits, setHits] = React.useState([]);
  const [query, setQuery] = React.useState("");

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [openDob, setOpenDob] = React.useState(false);

  useEffect(() => {
    if (isInternational) setSelectedStatus(internationalStatus);
    if (!isInternational) setSelectedStatus(localStatus);
  }, [isInternational]);

  useEffect(() => {
    if (applicant) {
      setFirstName(applicant.firstName);
      setLastName(applicant.lastName);
      setDob(applicant.dob);
      setGender(applicant.gender);
      setStatus(applicant.status);
      setStudentNumber(applicant.studentNumber);
      setEmail(applicant.email);
      setIdentificationNumber(applicant.identificationNumber);
      setApplicationDate(applicant.applicationDate);
      setTravellingToMaltaDate(applicant.travellingToMaltaDate);
      setCohortId(applicant.cohortId);
      setIsInternational(applicant.isInternational);
      setAddressHouse(applicant.addressHouse);
      setAddressCity(applicant.addressCity);
      setAddressStreet(applicant.addressStreet);
      setAddressPostcode(applicant.addressPostcode);
      setAddressCountry(applicant.addressCountry);
      setCampus(applicant.campus);
      setMobile(applicant.mobile);

      searchClient
        .initIndex("Cohorts")
        .search(applicant.cohortId, { hitsPerPage: 1 })
        .then(({ hits }) => {
          if (hits.length > 0) {
            setQuery(hits[0].title);
          }
        });
    }
  }, [applicant]);

  const handleCreateApplicant = () => {
    if (user && user != null) {
      if (
        firstName == null ||
        lastName == null ||
        dob == null ||
        gender == null ||
        status == null ||
        email == null ||
        identificationNumber == null
      ) {
        toast.error(`You have missing information for this applicant.`);
        if (identificationNumber == null) {
          toast.error("ID Number is required");
        }
        if (firstName == null) {
          toast.error("First name is required");
        }
        if (lastName == null) {
          toast.error("Last name is required.");
        }
        if (status == null) {
          toast.error("Status is required");
        }
        if (dob == null) {
          toast.error("Date of Birth is required.");
        }
        if (gender == null) {
          toast.error("Gender is required.");
        }
        if (email == null) {
          toast.error("Email is required.");
        }
      } else {
        let data = {
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          gender: gender,
          status: status,
          email: email,
          studentNumber: studentNumber,
          identificationNumber: identificationNumber,
          travellingToMaltaDate: travellingToMaltaDate,
          applicationDate: applicationDate,
          cohortId: cohortId,
          addressHouse: addressHouse,
          addressCity: addressCity,
          addressStreet: addressStreet,
          addressPostcode: addressPostcode,
          addressCountry: addressCountry,
          campus: campus,
          isInternational: isInternational,
          mobile: mobile,
        };

        setSaveLoading(true);
        toast.dark(`Editing Applicant : ${data.firstName} ${data.lastName}`);

        findStudent({ studentNumber: studentNumber }).then((res) => {
          if (res.data.length > 0) {
            toast.error("Student Number already exists");
          } else {
            editApplicant(applicant._id, data, user.idToken)
              .then((result) => {
                setTimeout(() => {
                  setRefreshAlgolia(true);
                }, 2000);
                setTimeout(() => {
                  setRefreshAlgolia(false);
                }, 2000);
                setClose();
                setSaveLoading(false);
                toast.success(
                  `Applicant successfully created : ${data.firstName} ${data.lastName}`
                );
                if (status == "Course Started") {
                  window.location.href = "/applicants/all";
                }

                setFirstName(null);
                setLastName(null);
                setDob(null);
                setGender(null);
                setEmail(null);
                setIdentificationNumber(null);
                setApplicationDate(null);
                setTravellingToMaltaDate(null);
                setCohortId(null);
                setStatus(null);
                setStudentNumber(null);
                getApplicant(applicant._id, user.idToken).then((res) => {
                  setApplicant(res);
                });
              })
              .catch((error) => {
                toast.error(
                  "Oops! Something went wrong. Check that you have the correct information"
                );
                toast.dark(error.message);
                setSaveLoading(false);
              });
          }
        });
      }
    }
  };

  const handleCohortId = (query) => {
    setQuery(query);
    setCohortId("");

    searchClient
      .initIndex("Cohorts")
      .search(query, { hitsPerPage: 3 })
      .then(({ hits }) => {
        setHits(hits);
      });
  };

  return (
    <Popup
      title="Edit applicant"
      subtitle="Please fill in the details below to edit applicant."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateApplicant()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <span>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      defaultChecked={isInternational}
                      onChange={(event) => {
                        setIsInternational(event.target.checked);
                      }}
                    />
                    Is International
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justify: "between",
              }}
            >
              {/* status */}
              <div style={{ width: "100%" }}>
                <div>
                  <p>Status</p>

                  <Select
                    style={{ width: "95%" }}
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    required
                    input={<Input id="type-menu" />}
                  >
                    {selectedStatus.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <p>Cohort</p>

                <TextField
                  type="text"
                  style={{ width: "100%" }}
                  value={query}
                  onChange={(event) => handleCohortId(event.target.value)}
                />
                <ul>
                  {hits.map((item) => {
                    return (
                      <li
                        key={item.title}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCohortId(item.objectID);
                          setQuery(item.title);
                          setHits([]);
                        }}
                      >
                        • {item.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: John"}
                    disabled={saveLoading}
                    required
                    label="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Doe"}
                    disabled={saveLoading}
                    required
                    label="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"22389(M)"}
                    disabled={saveLoading}
                    required
                    label="Identification Number"
                    value={identificationNumber}
                    onChange={(event) =>
                      setIdentificationNumber(event.target.value)
                    }
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: S948392"}
                    disabled={saveLoading}
                    label="Student Number"
                    value={studentNumber}
                    onChange={(event) => setStudentNumber(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    required
                    format="dd/MM/yyyy"
                    openTo="year"
                    views={["year", "month", "date"]}
                    margin="normal"
                    id="date-picker-inline"
                    label="Date of Birth"
                    value={dob}
                    onChange={(date) => {
                      setDob(date);
                    }}
                    onClick={() => setOpenDob(true)}
                    onOpen={() => setOpenDob(true)}
                    onClose={() => setOpenDob(false)}
                    open={openDob}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.inputType}>
                  <InputLabel required disabled={saveLoading}>
                    Gender
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setGender(event.target.value)}
                    value={gender}
                    required
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <div></div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: john@doe.com"}
                    disabled={saveLoading}
                    required
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 79123456"}
                    disabled={saveLoading}
                    required
                    label="Mobile"
                    value={mobile}
                    onChange={(event) => setMobile(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.inputType}>
                  <InputLabel required disabled={saveLoading}>
                    Campus
                  </InputLabel>
                  <Select
                    elevation={0}
                    onChange={(event) => setCampus(event.target.value)}
                    value={campus}
                    required
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    <MenuItem value={"Malta"}>Malta</MenuItem>
                    <MenuItem value={"Dubai"}>Dubai</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Address */}
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 1, Ave Maria"}
                    disabled={saveLoading}
                    required
                    label="House & House Number"
                    value={addressHouse}
                    onChange={(event) => setAddressHouse(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Triq Sant Antnin"}
                    disabled={saveLoading}
                    required
                    label="Street"
                    value={addressStreet}
                    onChange={(event) => setAddressStreet(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Mosta"}
                    disabled={saveLoading}
                    required
                    label="City"
                    value={addressCity}
                    onChange={(event) => setAddressCity(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Malta"}
                    disabled={saveLoading}
                    required
                    label="Country"
                    value={addressCountry}
                    onChange={(event) => setAddressCountry(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: MST 1234"}
                    required
                    disabled={saveLoading}
                    label="Postcode"
                    value={addressPostcode}
                    onChange={(event) => setAddressPostcode(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            {/* Dates */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <p>Application Date</p>

                <TextField
                  type="date"
                  style={{ width: "90%" }}
                  value={applicationDate}
                  onChange={(event) => setApplicationDate(event.target.value)}
                />
              </div>

              <div style={{ width: "100%" }}>
                <p>Travelling to Malta Date</p>
                <TextField
                  type="date"
                  style={{ width: "90%" }}
                  value={travellingToMaltaDate}
                  onChange={(event) =>
                    setTravellingToMaltaDate(event.target.value)
                  }
                />
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
