import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import AssignmentsView from "./single-cohort-assignments-view";

// Actions

export default withRouter(function (props) {
  const {
    cohort,
    user,
    editCohort,
    bulkCertificate,
    bulkRecordOfWork,
    bulkTranscriptPdf,
    bulkCompletionLetter,
    bulkGenerateTranscript,
    bulkDiplomaSupplement,
  } = props;

  const [assignments, setAssignments] = useState(null);
  const [students, setStudents] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (cohort != null) {
      setStudents(cohort.resultStudentsss);
      setAssignments(cohort.assignmentGroups);
    }
  }, [cohort]);

  return (
    <div>
      <AssignmentsView
        loading={!cohort}
        sectionDescription={"All assignments associated to this cohort."}
        user={user}
        cohort={cohort}
        editCohort={editCohort}
        students={students || []}
        assignments={assignments || []}
        bulkCertificate={bulkCertificate}
        bulkRecordOfWork={bulkRecordOfWork}
        bulkTranscriptPdf={bulkTranscriptPdf}
        bulkCompletionLetter={bulkCompletionLetter}
        bulkDiplomaSupplement={bulkDiplomaSupplement}
        bulkGenerateTranscript={bulkGenerateTranscript}
      />
    </div>
  );
});
