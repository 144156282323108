import React from 'react';

// Parents
import CohortsTabs from './dash-cohorts-tabs';
import CohortsRoutes from './dash-cohorts-routes';

export default function (props) {

  return (
    <CohortsTabs>
      <CohortsRoutes />
    </CohortsTabs>
  )
}
