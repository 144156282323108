import React from 'react';

// Parents
import ProjectsRoutes from './dash-payments-routes';

export default function (props) {

  return (
      <ProjectsRoutes />
  )
}
