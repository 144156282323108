import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  student: null,
  setStudent: {},
  loadingStudent: false,
  getStudent: {},
  editStudent: {},
  deleteStudent: {},
  findStudent:{}
};

export const SingleStudentContext = React.createContext(ContextProps);

export const SingleStudentProvider = ({ children }) => {
  const [student, setStudent] = useState(null);
  const [loadingStudent, setLoadingStudent] = useState(false);

  const { getIdToken } = useContext(IdTokenContext)


  const getStudent = async(id, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingStudent(true);
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/students").get(id, {
          query: {
            firebaseToken: token,
          },
        });

        setLoadingStudent(false);
        setStudent(result);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };
  const findStudent = async(query, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingStudent(true);
        getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/students").find({
          query: {
            ...query,
            firebaseToken: token,
          },
        });

        setLoadingStudent(false);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  const editStudent = (id, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingStudent(true);
        getIdToken().then(async (token) => {

        let editResult = await apiClient.service("v1/students").patch(
          id,
          { ...data, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        let getResult = await getStudent(id, token);
        setStudent(getResult);
        setLoadingStudent(false);
        resolve(getResult);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteStudent = async (studentId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let student = await getStudent(studentId, token);

        student.status = "Inactive";
        let editResult = await apiClient.service("v1/students").patch(
          studentId,
          { student, syncCanvas: false },
          {
            query: {
              firebaseToken: token,
            },
          }
        );

        setStudent(student);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <SingleStudentContext.Provider
      value={{
        student,
        setStudent,
        loadingStudent,
        getStudent,
        editStudent,
        deleteStudent,
        findStudent
        // createAuthToken: generateCustomUserAuthToken,
      }}
    >
      {children}
    </SingleStudentContext.Provider>
  );
};
