import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import All from './routes/all-vacancies';
import Single from './routes/single-vacancy';



export default function () {
    return(
        <div>
            <Switch>
                <Route path='/vacancies/all' component={() => <All />} />
                <Route path='/vacancies/:id' component={() => <Single />} />
                <Route exact path='/vacancies' component={() => <Redirect to={`/vacancies/all`} />} />
            </Switch>
        </div>
    )
}