import apiClient from "utils/api";
import { sleep } from "utils/helpers/sleep";
import { getSearchClient } from "utils/algolia";
import { getIdToken } from "../authentication"


export const findAllLecturers = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/lecturers").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdateLecturers",
          payload: result.data,
        });

        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewLecturer = async (dispatch, lecturer, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/lecturers").create(lecturer, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "AddLecturer",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editLecturer = async (dispatch, data, lecturerId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let updatedLecturer = await apiClient
        .service("v1/lecturers")
        .patch(lecturerId, data, {
          query: {
            firebaseToken: token,
          },
        });

      dispatch({
        type: "EditLecturer",
        payload: {
          contactId: lecturerId,
          data: updatedLecturer,
        },
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteLecturer = async (dispatch, lecturerId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/lecturers").remove(lecturerId, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "RemoveLecturer",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleLecturer = async (lecturerId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/lecturers").get(lecturerId, {
        query: {
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createLecturerDocument = async (
  lecturerId,
  data,
  documents,
  setDocuments,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/documents").create(
        {
          attachmentURI: data.attachment,
          title: data.title,
          type: "lecturer",
          lecturerId: lecturerId,
        },
        {
          query: {
            firebaseToken: token,
          },
        }
      );

      let currentDocuments = [...documents];
      currentDocuments.push(result);
      setDocuments(currentDocuments);

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedDocuments = async (personnelId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/documents").find({
        query: {
          personnelId: personnelId,
          type: "lecturer",
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedLectureSlots = async (personnelId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/lectures").find({
        query: {
          personnelId: personnelId,
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
