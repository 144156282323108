import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import styles from "./single-person-other-qualifications-styles";
import Panel from "components/panel";
import DialogueEditOtherQualifications from "routes/dashboard/dialogues/personnel/edit-other-qualifications";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { person, setPerson, user } = props;
  const [qualifications, setQualifications] = useState([]);
  const [
    showEditOtherQualificationsDialogue,
    setShowEditOtherQualificationsDialogue,
  ] = useState(false);

  useEffect(() => {
    setQualifications(person.other_qualifications);
  }, [person]);

  return (
    <div>
      <Panel
        title="Other Qualifications"
        loading={false}
        sectionDescription="Other qualifications related to the person above."
        actionName="Edit"
        handleAction={() => setShowEditOtherQualificationsDialogue(true)}
      >
        {qualifications != null && qualifications.length > 0 && (
          <List>
            {qualifications.map((role) => (
              <ListItem className={classes.labelText}>{role}</ListItem>
            ))}
          </List>
        )}

        {qualifications == null && (
          <Typography className={classes.loading}>Loading...</Typography>
        )}
        {qualifications != null && (
          <div>
            {qualifications.length == 0 && (
              <Typography className={classes.loading}>
                This person has no other qualifications listed to them
              </Typography>
            )}
          </div>
        )}

        <DialogueEditOtherQualifications
          isOpen={showEditOtherQualificationsDialogue}
          setClose={() => setShowEditOtherQualificationsDialogue(false)}
          personId={person._id}
          person={person}
          setQualifications={setQualifications}
          setPerson={setPerson}
          user={user}
        />
      </Panel>
    </div>
  );
}
