import React, { useState, useEffect, useContext } from "react";
import anime from 'animejs/lib/anime.es.js';
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Button,
  Avatar,
  ListItemAvatar,
  ListItem,
  List,
  Grid,
  Paper
} from "@material-ui/core";
import "./styles.css"
import { toast } from "react-toastify";
import moment from "moment";

import {AllTimesheetsContext } from "repository/stores/dashboard/timesheets/timesheets-all-provider"
// import { createTimestamp, findPunchDayByUser } from "../../../../../../../repository/actions/data/dashboard/timesheets";


// Navigation
import NavCrumbs from "./timesheets-all-timesheets-nav-crumbs";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { rejects } from "assert";
const useStyles = makeStyles(styles);


export default function (props) {
  const classes = useStyles();
  const {
    loading,
    users,
    user,
    sectionDescription,
  } = props;


  const{createTimestamp, findPunchDayByUser} = useContext(AllTimesheetsContext)

  const [avartarURL, setAvatarURL] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("Select Your User");

  const [selectedUser, setSelectedUser] = React.useState(null);

  const [sortedUsers, setSortedUsers] = React.useState(null)

  const [isPunchOutAvailable, setIsPunchOutAvailable] = React.useState(false)
  const [isPunchInAvailable, setIsPunchInAvailable] = React.useState(false)


  const [dt, setDt] = useState(new Date().getHours() + ":" + new Date().getMinutes());


  useEffect(() => {
    let secTimer = setInterval(() => {
      let time = new Date()
      let prefixMinutes = ""
      let prefixHours = ""

      if (time.getMinutes().toString().length < 2) {
        prefixMinutes = "0"
      }
      if (time.getHours().toString().length < 2) {
        prefixHours = "0"
      }
      setDt(prefixHours + time.getHours() + ":" + prefixMinutes + time.getMinutes())
    }, 1000)

    return () => clearInterval(secTimer);

  }, [users]);

  useEffect(() => {
    if (users) {
      let sorted = users.sort((a, b) => (a.roles[0] > b.roles[0]) ? 1 : ((b.roles[0] > a.roles[0]) ? -1 : 0))
      setSortedUsers(sorted)
    }
  }, [users])



  const handleTimestamps = async (isPunchIn) => {
    let currentDate = new Date()
    let prefixMonth = ""
    let prefixDay= ""

    if (currentDate.getMonth().toString().length < 2) {
      prefixMonth = "0"
    }
    if (currentDate.getDay().toString().length < 2) {
      prefixDay = "0"
    }
    let payload = {
      userId: selectedUser._id,
      timestamp: currentDate,
      currentTime: dt,
      payMonth: prefixMonth + currentDate.getMonth() + "-" + currentDate.getFullYear(),
      punchId: prefixDay + currentDate.getDay() + "-" + prefixMonth + currentDate.getMonth() + "-" + currentDate.getFullYear(),
      isPunchIn: isPunchIn,
      isCustom: false
    }

    await createTimestamp(payload, user.idToken).then(() => {
      let wave = document.getElementById("wave")
      let avatar = document.getElementsByClassName("anim-avatar")[0]
      var avatarPos = avatar.getBoundingClientRect();
      var wavePos = wave.getBoundingClientRect();

      let topOff = avatarPos.top - wavePos.top
      let leftOff = avatarPos.left - wavePos.left

      wave.style.marginLeft = leftOff + "px"
      wave.style.marginTop = topOff + 10 + "px"
      wave.style.opacity = "1";

      setTimeout(() => {
        setSelectedUser(null)
        setAvatarURL("")
        wave.style.opacity = "0";

        anime({
          targets: "#" + avatar.id,
          width: {
            value: '0px',
          },
          height: {
            value: '0px',
          },
          duration: 800
        });
      }, 2000)

      setTimeout(() => {
        window.location.reload()
      }, 2200)

    }).catch(e => {
      console.log(e)
      toast.error(e.message)
    })
  }


  function selectAvatar(evt, id, user) {
    evt.preventDefault();
    evt.stopPropagation();

    setSelectedUser(user)
    setSelectedName(user.firstName+" "+user.lastName)

    let punchId = new moment().format('DD-MM-YYYY').toString();  

    findPunchDayByUser(user._id ,punchId).then((result)=>{
      if(result.data.length>0){
        setIsPunchOutAvailable(true)
        setIsPunchInAvailable(false)

      }else{
        setIsPunchInAvailable(true)
        setIsPunchOutAvailable(false)
      }
    })



    var paras = document.getElementsByClassName('anim-avatar');
    while (paras[0]) {
      paras[0].parentNode.removeChild(paras[0]);
    }

    var el = document.getElementById(id)
    var parent = document.getElementById("selectedAvatar")

    var parentPos = parent.getBoundingClientRect();
    var elPos = el.getBoundingClientRect();

    let parentTopOffset = elPos.top - parentPos.top + 15;
    let parentLeftOffset = elPos.left - parentPos.left;

    let newAvatar = document.createElement("img")
    newAvatar.src = user.displayPhoto.url;
    newAvatar.setAttribute('id', "new" + id);
    newAvatar.setAttribute('class', "anim-avatar");
    document.getElementById("avatarParent").appendChild(newAvatar);

    var newAvatarPos = newAvatar.getBoundingClientRect();

    newAvatar.classList.add("active")
    newAvatar.style.position = 'absolute';
    newAvatar.style.left = elPos.left + "px";
    newAvatar.style.width = "90px"
    newAvatar.style.height = "90px"
    newAvatar.style.zIndex = "90"
    newAvatar.style.transition = "top 2s ";


    var newAvatarPos = newAvatar.getBoundingClientRect();

    anime({
      targets: "#new" + id,
      translateY: -parentTopOffset + 4,
      translateX: -parentLeftOffset - 2,
      width: {
        value: '195px',
      },
      height: {
        value: '195px',
      },
      duration: 800
    });


    setTimeout(() => {
      setAvatarURL(user.displayPhoto.url)
    }, 500)

  };

  // const generateTimesheets = () => {
  //   console.log("generating now")
  // }


  return (
    <div>
      <Section
        title="Timesheets"
        loading={loading}
        // actionName="Generate Timesheets"
        // handleAction={generateTimesheets}
        sectionDescription={sectionDescription}
        className={classes.miniSection}
      >

        <Grid container spacing={2} justifyContent="center"
          alignItems="center">
          <Grid item xs={6} id="avatarParent">
            <img class="wave" id="wave" src="https://i.ibb.co/YcWMk6F/pngegg.png"></img>
            <Avatar id="selectedAvatar" alt="Remy Sharp" src={avartarURL} className={classes.selectedAvatar} />

              <Typography className={classes.avatarName}>
               {selectedName}
              </Typography>
          
            <div className={classes.buttonGroup}>
              <Button className={classes.timeBtn} onClick={() => { handleTimestamps(true) }} disabled={!isPunchInAvailable}>
                Punch In
              </Button>
              <Button className={classes.timeBtn} onClick={() => { handleTimestamps(false) }} disabled={!isPunchOutAvailable}>
                Punch Out
              </Button>
            </div>
          </Grid>
          <Grid item xs={6} >
            <h1 className={classes.time}>{dt}</h1>
          </Grid>
        </Grid>

        <Grid container spacing={2}>

          {/* {sortedUsers && sortedUsers.map((item, index) => {
            return <Grid item xs={12} >
              <List className={classes.avatarList}>
                {item[1].map((person, index) => {
                  return <ListItem className={classes.singleAvatar} id={`${item[1]}-${index}`}>
                    <ListItemAvatar >
                      <Avatar alt="Remy Sharp" src={person.displayPhoto.url} className={classes.avatar} onClick={(evt) => { selectAvatar(evt, index, item) }} />
                    </ListItemAvatar>
                    <Typography
                      variant="body2"
                      color="text.primary"
                    >
                      {person.firstName + " " + person.lastName}
                    </Typography>
                  </ListItem>
                })}
              </List>
            </Grid>
          })
          } */}

        </Grid>


        <List className={classes.avatarList}>
          {sortedUsers && sortedUsers.map((item, index) => {
            return <ListItem className={classes.singleAvatar} id={index}>
              <ListItemAvatar >
                <Avatar alt="Remy Sharp" src={item.displayPhoto.url} className={classes.avatar} onClick={(evt) => { selectAvatar(evt, index, item) }} />
              </ListItemAvatar>

              <Typography
                variant="body2"
                color="text.primary"
              >
                {item.firstName + " " + item.lastName}
              </Typography>
            </ListItem>
          })}
        </List>
      </Section>


    </div>
  );
}