import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { enquiry } = props;

  let crumbs = [
        {name: 'Enquiries', path: 'enquiries'},
        {name: 'All', path: 'enquiries/all'},
        {name: '...', path: '#'},
  ];

  if (enquiry != null) {
    crumbs[2] =  { name: `${enquiry.firstName} ${enquiry.lastName}`, path: `enquiries/all/${enquiry._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
