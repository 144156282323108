import React, { useContext} from "react";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import Loading from "../../components/loading";

// Children
import { authenticateUser } from "repository/actions/data/authentication";

export default function (props) {
  const {loadingAuthState} = useContext(AuthenticationContext);

  if(loadingAuthState === true){
    return <Loading full/>
  }else{
    return props.children;
  }
}
