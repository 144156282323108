import React from "react";
import { withRouter } from "react-router-dom";

import ProposalsPieView from "./proposals-pie-view";


export default withRouter(function (props) {
let {user, currentYear, title, type} = props

 
  return (
    <div>
        <ProposalsPieView
          user={user}
          title={title}
          type={type}
          currentYear={currentYear}
        />
    </div>
  );
});
