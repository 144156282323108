import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { Link, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { createStudentDocument } from "repository/actions/data/dashboard/students";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    documents,
    setDocuments,
    student,
    setStudent,
    user,
    getStudent,
  } = props;

  const [title, setTitle] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [attachmentName, setAttachmentName] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [attachmentError, setAttachmentError] = React.useState(null);

  const handleCreateDocument = () => {
    if (user && user != null) {
      if (title == null || attachment == null) {
        toast.error(`You have missing information for this document`);
        if (attachment == null) {
          setAttachmentError("Attachment is required.");
        }
        if (title == null) {
          setTitleError("Title is required.");
        }
      } else {
        let data = {
          title: title,
          attachment: attachment,
        };

        setSaveLoading(true);

        createStudentDocument(
          student._id,
          data,
          documents,
          setDocuments,
          user.idToken
        )
          .then((result) => {
            getStudent(student._id, user.idToken).then((getStudentResult) => {
              toast.success(
                `Document successfully added for ${student.firstName} ${student.lastName}`
              );

              setStudent(getStudentResult);
              setClose();
              setSaveLoading(false);
              setTitle(null);
              setAttachment(null);
              setAttachmentName(null);
            });
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setTitleError(null);
    setAttachmentError(null);
  }, [title, attachment, isOpen]);

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 15728640) {
      toast.error("File is too big. The maximum size allowed is 15MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setAttachment(e.target.result);
        setAttachmentName(target.files[0].name);
        setTitle(target.files[0].name);
      };
    }
  };

  return (
    <Popup
      title="Add a new document"
      subtitle="Please fill in the details below to create a new document."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateDocument()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <input
            accept="image/* pdf/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            disabled={saveLoading}
            onChange={handleCapture}
          />
          <label htmlFor="contained-button-file" disabled={saveLoading}>
            {!attachment && (
              <div className={classes.attachmentText}>
                <CloudUpload />
              </div>
            )}
            {attachmentName && (
              <Typography className={classes.attachmentText}>
                Attachment : {attachmentName}
              </Typography>
            )}

            <Typography className={classes.error}>{attachmentError}</Typography>
            <Link
              className={classes.link}
              disabled={saveLoading}
              component="span"
              variant="body2"
            >
              {attachment && <div>Change Attachment (Max: 15mb)</div>}
              {!attachment && <div>Upload Attachment (Max: 15mb)</div>}
            </Link>
          </label>
          <div></div>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
