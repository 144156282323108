import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Input,
  Grid
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import MUIRichTextEditor from "mui-rte";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider"


// Actions
import { createVacancy } from "repository/actions/data/dashboard/vacancies"
// import { getClient } from "repository/actions/data/dashboard/clients";
import { getIndustries } from "repository/actions/data/dashboard/industries"


const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, setRefreshAlgolia, clientId, client, setClient } = props;
  
  const { getClient } = useContext(AllClientsContext)

  const [positionName, setPositionName] = useState(null)
  const [minSalary, setMinSalary] = useState(null)
  const [maxSalary, setMaxSalary] = useState(null)
  const [industry, setIndustry] = useState(null)
  const [location, setLocation] = useState(null)
  const [description, setDescription] = useState(null)
  const [headCount, setHeadCount] = useState(null)
  const [contractType, setContractType] = useState(null)
  const [excerpt, setExcerpt] = useState(null)


  const [saveLoading, setSaveLoading] = React.useState(false);
  const [industries, setIndustries] = React.useState(null);
  const [error, setError] = React.useState(null);


  useEffect(() => {
    if (user) {
      getIndustries(user.idToken).then((result) => {
        setIndustries(result)
      })
    }
  }, [user])



  const handleCreateVacancy = () => {
    if (
      positionName == null ||
      // minSalary == null ||
      // maxSalary == null ||
      location == null ||
      excerpt == null ||
      industry == null ||
      description == null ||
      headCount == null ||
      contractType == null
    ) {
      toast.error("You have missing information for creating a new vacancy");
      if (positionName === null) {
        setError("Position Name is required.");
      }
      // if (minSalary == null) {
      //   setError("Minimum Salary is required.");
      // }
      // if (maxSalary == null) {
      //   setError("Maximum Salary is required.");
      // }
      if (location == null) {
        setError("Location is required.");
      }
      if (industry == null) {
        setError("Industry is required.");
      }
      if (description == null) {
        setError("Description is required.");
      }
      if (headCount == null) {
        setError("Head Count is required.");
      }
      if (excerpt == null) {
        setError("Excerpt is required.");
      }
      if (contractType == null) {
        setError("Contact Type is required.");
      }
      toast.error(error);

    } else {
      let payload = {
        positionName: positionName,
        minSalary: minSalary ? minSalary.replace(",", "") : minSalary,
        maxSalary: maxSalary ? maxSalary.replace(",", "") : maxSalary,
        industry: industry,
        location: location,
        specification: draftToHtml((convertToRaw(description.getCurrentContent()))),
        specificationRaw: (convertToRaw(description.getCurrentContent())),
        contractType: contractType,
        headCount: headCount,
        candidates: [],
        isPublished: false,
        excerpt: excerpt,
        clientId: clientId,
        clientTitle: client.title
      }


      setSaveLoading(true);
      toast.dark(`Creating Vacancy : ${payload.positionName}`);


      createVacancy(payload, user.idToken)
        .then((result) => {
          setTimeout(() => { setRefreshAlgolia(true) }, 2000)
          setTimeout(() => { setRefreshAlgolia(false) }, 2000)
          setClose();
          setSaveLoading(false);
          toast.success(`Vacancy created : ${payload.positionName}`);
          setPositionName(null)
          setMinSalary(null)
          setMaxSalary(null)
          setIndustry(null)
          setLocation(null)
          setDescription(null)
          setHeadCount(null)
          setContractType(null)
          setExcerpt(null)
          setError(null);

          getClient(clientId, user.idToken).then((result) => {
            setClient(result)
          })


        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };



  function formatCurrancy(value) {
    let newValue = value.replace(",", "")
    newValue = newValue.replace(/\D/g, '')
    if (newValue.length > 3 && newValue.indexOf(",") == -1) {
      let format = newValue.substring(0, newValue.length - 3) + "," + newValue.substring(newValue.length - 3, newValue.length)
      return format
    }
    else {
      return newValue
    }
  }

  return (
    <Popup
      title="Add a new Vacancy"
      subtitle="Please fill in the details below to create a new Vacancy."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateVacancy()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      isOnKeyPressDown={false}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Project Manager"}
              required
              label="Position Name"
              value={positionName}
              onChange={(event) => setPositionName(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: €18,000"}
                  
                  label="Min Salary"
                  value={minSalary ? formatCurrancy(minSalary) : minSalary}
                  onChange={(event) => setMinSalary(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>

              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: €20,000"}
                  
                  label="Max Salary"
                  value={maxSalary ? formatCurrancy(maxSalary) : maxSalary}
                  onChange={(event) => setMaxSalary(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Mosta"}
                  required
                  label="Location"
                  value={location}
                  onChange={(event) => setLocation(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} >
                <TextField
                  placeholder={"Ex: 10"}
                  required
                  type="number"
                  label="Head Count"
                  value={headCount}
                  onChange={(event) => setHeadCount(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.inputType}>
                <InputLabel >
                  Contract Type
                </InputLabel>
                <Select
                  elevation={0}
                  onChange={(event) => setContractType(event.target.value)}
                  value={contractType}
                  required
                  input={<Input id="type-menu" />}
                >
                  <MenuItem value={"Full Time"}>{"Full Time"}</MenuItem>
                  <MenuItem value={"Reduced Hours"}>{"Reduced Hours"}</MenuItem>
                  <MenuItem value={"Part Time"}>{"Part Time"}</MenuItem>
                  <MenuItem value={"Shifts"}>{"shifts"}</MenuItem>
                  <MenuItem value={"Temporal"}>{"Temporal"}</MenuItem>
                  <MenuItem value={"Part / Full Time"}>{"Part / Full Time"}</MenuItem>


                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Industry
                </InputLabel>
                {industries && industries != null && (
                  <Select
                    elevation={0}
                    onChange={(event) => setIndustry(event.target.value)}
                    value={industry}
                    required
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                    {industries.map((item, index) => {
                       return <MenuItem value={item.title}>{item.title}</MenuItem>;
                    })}
                  </Select>
                )}

              </FormControl>
            </Grid></Grid>
          <div></div>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <FormControl className={classes.formControl} >
                <TextField
                  required
                  label="Excerpt"
                  value={excerpt}
                  inputProps={{ maxLength: 50 }}
                  onChange={(event) => setExcerpt(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={1} style={{ display: "flex", alignItems: "end" }}>
              {excerpt ? excerpt.length : 0}/50
            </Grid>
          </Grid>
          <div></div>
          <FormControl className={classes.inputType} style={{ margin: "40px 0", height: 200 }}>
            <MUIRichTextEditor
              label={`Job description...`}
              className={classes.richTextEditor}
              controls={["title", "bold", "italic", "underline", "highlight", "link", "numberList", "bulletList", "quote", "code", "save"]}
              onChange={(event) => { setDescription(event) }}
            />

          </FormControl>

        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
