import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, index, student, user, isResolved, editStudent } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteItem = () => {
      setSaveLoading(true);

      let newExtensions = [];
    
      
      let patchData = null
      
      if(isResolved){
        for (let [extIndex, ext] of student.resolvedExtensions.entries()) {
          if (extIndex != index) {
            newExtensions.push(ext);
          }
        }      
        patchData = {
          resolvedExtensions: newExtensions,
        };
      }else{
        for (let [extIndex, ext] of student.extensions.entries()) {
          if (extIndex != index) {
            newExtensions.push(ext);
          }
        }
        patchData = {
          extensions: newExtensions,
        };
      }

      editStudent(student._id, patchData, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`Extension Deleted`);
        })
        .catch((error) => {
          toast.error(
            `Oops! Something went wrong. Check that you have the correct information`
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    };

    return (
      <Popup
        title="Delete selected extensions"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteItem()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this document?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
