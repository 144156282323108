import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import ConsultantsView from "./consultants-all-consultants-view";

import DialogueCreateConsultant from "routes/dashboard/dialogues/consultants/create-consultants";
import DialogueEditConsultant from "routes/dashboard/dialogues/consultants/edit-consultant";
import { Grid } from "@material-ui/core";

export default withRouter(function (props) {
  const {
    consultants,
    setConsultants,
    getInternalConsultants,
    getEternalConsultants,
    getConsultants,
    createNewConsultant,
    editConsultant,
  } = useContext(AllConsultantsContext);

  const { user } = useContext(AuthenticationContext);

  const [showNewConsultantsDialogue, setShowNewConsultantsDialogue] =
    useState(false);
  const [showEditConsultantsDialogue, setShowEditConsultantsDialogue] =
    useState(false);

  const [selectedIdx, setSelectedIdx] = useState(null);

  const [internalConsultants, setInternalConsultants] = useState(null);
  const [externalConsultants, setExternalConsultants] = useState(null);

  useEffect(() => {
    if (user) {
      getInternalConsultants().then((res) => {
        setInternalConsultants(res);
      });
     getEternalConsultants().then((res) => {
        setExternalConsultants(res);
      });
    }
  }, [user]);

  
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <ConsultantsView
            title={"Internal Conultants"}
            loading={!internalConsultants}
            consultants={internalConsultants || []}
            onPressedItem={(id) =>
              (window.location.href = `/consultants/${id}`)
            }
            toggleAddUserForm={() => setShowNewConsultantsDialogue(true)}
            onPressedEditItem={(index) => {
              setSelectedIdx(internalConsultants[index]);
              setShowEditConsultantsDialogue(true);
            }}
            user={user}
          />
        </Grid>

        <Grid item xs={6}>
          <ConsultantsView
            title={"External Conultants"}
            loading={!externalConsultants}
            consultants={externalConsultants || []}
            onPressedItem={(id) =>
              (window.location.href = `/consultants/${id}`)
            }
            toggleAddUserForm={() => setShowNewConsultantsDialogue(true)}
            onPressedEditItem={(index) => {
              setSelectedIdx(externalConsultants[index]);
              setShowEditConsultantsDialogue(true);
            }}
            user={user}
          />
        </Grid>
      </Grid>

      <DialogueCreateConsultant
        isOpen={showNewConsultantsDialogue}
        setClose={() => setShowNewConsultantsDialogue(false)}
        user={user}
        createNewConsultant={createNewConsultant}
      />

      <DialogueEditConsultant
        isOpen={showEditConsultantsDialogue}
        setClose={() => setShowEditConsultantsDialogue(false)}
        user={user}
        consultant={selectedIdx}
        editConsultant={editConsultant}
      />
    </div>
  );
});
