import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider";

const ContextProps = {
  courses: null,
  setCourses: {},
  courseOptions: null,
  setCourseOptions: {},
  getCourses: {},
  loadingCourses: false,
  createNewCourse: {},
  editCourse: {},
  deleteCourse: {},
};

export const AllCoursesContext = React.createContext(ContextProps);

export const AllCoursesProvider = ({ children }) => {
  const [courses, setCourses] = useState(null);
  const [loadingCourses, setLoadingCourses] = useState(false);

  const { getIdToken } = useContext(IdTokenContext);

  const getCourses = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCourses(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/courses").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingCourses(false);
          setCourses(result.data);
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewCourse = async (course, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/courses").create(course, {
            query: {
              firebaseToken: token,
            },
          });

          if (courses == null) {
            setCourses(result);
          } else {
            let newCourses = courses;
            newCourses.push(result);
            setCourses(newCourses);
          }
          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const editCourse = async (data, courseId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // let apiClient = getClient();
        getIdToken().then(async (token) => {
          let updatedCourse = await apiClient
            .service("v1/courses")
            .patch(courseId, data, {
              query: {
                firebaseToken: token,
              },
            });

          if (courses) {
            let newData = courses;
            for (const [i, v] of newData.entries()) {
              if (v._id === courseId) {
                newData[i] = updatedCourse;
              }
            }
            setCourses(newData);
          }

          resolve(updatedCourse);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteCourse = async (courseId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          // let apiClient = getClient();
          let result = await apiClient.service("v1/courses").remove(courseId, {
            query: {
              firebaseToken: token,
            },
          });

          let newData = courses;
          for (const [i, v] of newData.entries()) {
            if (v._id === courseId) {
              newData.splice(i, 1);
            }
          }
          setCourses(newData);

          resolve(result);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllCoursesContext.Provider
      value={{
        courses,
        setCourses,
        loadingCourses,
        getCourses,
        createNewCourse,
        deleteCourse,
        editCourse,
      }}
    >
      {children}
    </AllCoursesContext.Provider>
  );
};
