import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

import { getAssociatedTrainingSessions } from "repository/actions/data/dashboard/locations";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { locationData, user } = props;

  const classes = useStyles();

  let [sessions, setSessions] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (locationData != null) {
      getAssociatedTrainingSessions(locationData._id, user.idToken).then((result) => {
        setSessions(result.data);
        setLoading(false);
      });
    }
  }, [locationData]);

  return (
    <Section
      title={"Training Sessions"}
      loading={loading}
      sectionDescription={"All associated training sessions."}
    >
      {sessions.length > 0 && (
        <TableContainer className={classes.miniSection} component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Title</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Date</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>Client</Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Location
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Typography className={classes.labelText}>
                    Lecturer
                  </Typography>
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">
                      {row.contact.firstName} {row.contact.lastName}
                    </TableCell>
                    <TableCell align="left">{row.location.title}</TableCell>
                    <TableCell align="left">
                      {row.lecturer.firstName} {row.lecturer.lastName}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {sessions.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          This location has no associated training sessions.
        </Typography>
      )}
    </Section>
  );
});
