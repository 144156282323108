

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  projects: null,
  setProjects: {},
  getProjects: {},
  getProject: {},
  loadingProjects: false,
  deleteProject: {},
  createNewProject: {},
  editProject: {},
  createProjectPayment: {},
  deleteProjectPayment: {},
  findProjectPayment: {},
  findProjectPaymentByYear: {},

  editProjectPayment: {},
  createProjectTiming: {},
  getProjectsByDate: {},
  findActiveProjectsbyConsultant: {},
  findCompleteProjectsbyConsultant: {},
  findProjectTimingsbyConsultant: {},
  findProjectPaymentByProject: {},
  getProjectsByYearTag:{},
  getActiveProjects:{}
};

export const AllProjectsContext = React.createContext(ContextProps);

export const AllProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext)



  const getProjects = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProjects(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProjects(false);
          setProjects(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const getProject = (projectId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingProjects(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").get(projectId, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingProjects(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewProject = async (project, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").create(
            { ...project },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editProject = async (projectId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updated = await apiClient
            .service("v1/projects")
            .patch(projectId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve(updated);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteProject = async (projectId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").remove(projectId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  
  const getProjectsByDate = (start, end) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProjects(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").find({
            query: {
              firebaseToken: token,
              startDate: {
                $gt: start,
                $lt: end
              },
              $sort:{
                startDate: 1
              }
             
            },
          });

          setLoadingProjects(false);
          setProjects(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const getProjectsByYearTag = (year) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProjects(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/projects").find({
            query: {
              firebaseToken: token,
              yearTags: {
                $in: [year] 
              },
              $sort:{
                startDate: 1
              }
             
            },
          });

          setLoadingProjects(false);
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

   
  const getActiveProjects = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingProjects(true);
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/projects").find({
            query: {
              firebaseToken: token,
              status: "Active",
            },
          });
          setLoadingProjects(false);
          setProjects(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };




  const createProjectPayment = async (projectPayment, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-payments").create(
            { ...projectPayment },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findProjectPayment = async (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-payments").find(
            {
              query: {
                $sort:{
                  date: 1
                },
                status: "pending",
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findProjectPaymentByYear = async (year) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-payments").find(
            {
              query: {
                year: year,
                $sort:{
                  date: 1
                },
                firebaseToken: token,
              },
            }
          );

          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  
  const findProjectPaymentByProject = async (projectId) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-payments").find(
            {
              query: {
                $sort:{
                  date: 1
                },
                projectId: projectId,
                firebaseToken: token,
              },
            }
          );

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  
  const editProjectPayment = async (paymentId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updated = await apiClient
            .service("v1/project-payments")
            .patch(paymentId, data, {
              query: {
                firebaseToken: token,
              },
            });


          resolve(updated);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteProjectPayment = async (projectPaymentId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/project-payments").remove(projectPaymentId, {
            query: {
              firebaseToken: token,
            },
          });

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createProjectTiming = async (payload, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-timings").create(payload, {
            query: {
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  
  const findActiveProjectsbyConsultant = async (consultantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/projects").find({
            query: {
              $limit:50,
              consultants: {
                $in: [consultantId]
              },
              status: "Active",
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  
  const findCompleteProjectsbyConsultant = async (consultantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/projects").find({
            query: {
              consultants: {
                $in: [consultantId]
              },
              status: "Complete",
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const findProjectTimingsbyConsultant = async (currentMonth, consultantId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/project-timings").find({
            query: {
              month:currentMonth,
              consultantId: consultantId,
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllProjectsContext.Provider
      value={{
        projects,
        setProjects,
        createNewProject,
        getProjects,
        getProject,
        editProject,
        deleteProject,
        createProjectPayment,
        deleteProjectPayment,
        findProjectPayment,
        getActiveProjects,
        editProjectPayment,
        createProjectTiming,
        findProjectTimingsbyConsultant,
        findProjectPaymentByProject,
        findProjectPaymentByYear,
        getProjectsByDate,
        findActiveProjectsbyConsultant,
        findCompleteProjectsbyConsultant,
        getProjectsByYearTag,
        loadingProjects
      }}
    >
      {children}
    </AllProjectsContext.Provider>
  );
};
