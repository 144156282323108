import React, { useEffect } from "react";
// import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-designated-user-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  IconButton,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import Select from "react-select";

//Actions
// import { editLead } from "repository/actions/data/dashboard/leads";
// import { findAllUsers } from "repository/actions/data/dashboard/users";

const useStyles = makeStyles(styles);

function EditDesignatedUserDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, leads, setLeads, lead, leadId, user, editLead, findAllUsers, users } = props;
  const [designatedUser, setDesignatedUser] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState([]);
  // const {users, setUsers, findAllUsers} = React.useContext(AllUsersContext);
  const [userMap, setUserMap] = React.useState({});

  const [newDesignatedUser, setNewDesignatedUser] = React.useState(null);

  useEffect(() => {
    if (lead && lead != null) {
      if (lead.designatedUser) {
        setDesignatedUser(lead.designatedUser);
      }
    }
  }, [lead]);

  useEffect(() => {
    if (users == null) {
      findAllUsers( user.idToken);
    }
  }, [users]);

  useEffect(() => {
    if (users && users != null) {
      let newUserOptions = [];
      let newUserMap = {};
      for (let u of users) {
        newUserOptions.push({
          value: u._id,
          label: `${u.firstName} ${u.lastName} | ${u.email}`,
        });

        newUserMap[u._id] = u;
      }

      setUserOptions(newUserOptions);
      setUserMap(newUserMap);
    }
  }, [users]);

  const handleSelectNewUser = (selectedUser) => {
    let newUser = userMap[selectedUser.value];

    setNewDesignatedUser(newUser);
  };

  const handleSaveDesignatedUser = () => {
    let patchData = { ...lead, delegatedUserId: newDesignatedUser._id };

    editLead(patchData, leadId, user.idToken)
      .then((result) => {
        toast.success(
          `${newDesignatedUser.firstName} ${newDesignatedUser.lastName} is now handling ${lead.firstName} ${lead.lastName}`
        );
        handleClose();
        setSaveLoading(false);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information."
        );
        toast.dark(error.message);
        setSaveLoading(false);
      });
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Lead Designated User"
      subtitle="Edit the designated user for this lead below"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveDesignatedUser()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.fullColumn}>
                <FormControl className={classes.formControl}>
                  <Select
                    options={userOptions}
                    isClearable
                    placeholder="Select new user"
                    onChange={handleSelectNewUser}
                    className={classes.selectContainer}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    default
                  />
                </FormControl>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditDesignatedUserDialogue);
