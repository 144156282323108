import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import { getSearchClient } from "utils/algolia";

// import { PieChart } from "react-chartkick";
// import "chartkick/chart.js";
import { Chart } from "react-google-charts";


const searchClient = getSearchClient();

export default withRouter(function (props) {
  let { currentYear, title, type } = props;

  const [data, setData] = useState(null);
  const [dataPercentage, setDataPercentage] = useState(null);
  const [totalProposals, setTotalProposals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
    
  }, [currentYear]);


  const getTotals = () => {
    
    return new Promise(async (resolve, reject) => {
      try {
        let start = currentYear.startOf("year").valueOf();
        let end = currentYear.endOf("year").valueOf();


        let pending = 0;
        let successful = 0;
        let revised = 0;
        let unsuccessful = 0;

        let total = 0;

        await searchClient
          .initIndex("Proposals")
          .searchForFacetValues("type", type, { filters: `status:Pending AND createdAt:${start} TO ${end}`  })
          .then(({ facetHits }) => {
            if (facetHits[0]) {
              pending = facetHits[0].count;
              total = total + facetHits[0].count;
            }
          });
        await searchClient
          .initIndex("Proposals")
          .searchForFacetValues("type", type, { filters: `status:Successful AND createdAt:${start} TO ${end}` })
          .then(({ facetHits }) => {
            if (facetHits[0]) {
              successful = facetHits[0].count;
              total = total + facetHits[0].count;
            }
          });
        await searchClient
          .initIndex("Proposals")
          .searchForFacetValues("type", type, { filters: `status:Revised AND createdAt:${start} TO ${end}` })
          .then(({ facetHits }) => {
            if (facetHits[0]) {
              revised = facetHits[0].count;
              total = total + facetHits[0].count;
            }
          });
        await searchClient
          .initIndex("Proposals")
          .searchForFacetValues("type", type, {
            filters: `status:Unsuccessful AND createdAt:${start} TO ${end}`,
          })
          .then(({ facetHits }) => {
            if (facetHits[0]) {
              unsuccessful = facetHits[0].count;
              total = total + facetHits[0].count;
            }
          });

        let status = {
          Pending: pending,
          Successful: successful,
          Revised: revised,
          Unsuccessful: unsuccessful,
          total: total,
        };
        resolve(status);
      } catch (e) {
        reject(e);
      }
    });
  };

  const getData = async () => {
    let status = await getTotals();

    setData(status);

    // let percentage = {
    //   Pending: ((status.Pending / total) * 100).toFixed(0),
    //   Successful: ((status.Successful / total) * 100).toFixed(0),
    //   Revised: ((status.Revised / total) * 100).toFixed(0),
    //   Unsuccessful: ((status.Unsuccessful / total) * 100).toFixed(0),
    // };
    // setDataPercentage(percentage);

    let percentage = [
      ["Status", "Percentage"],
      ["Pending", status.Pending],
      ["Successful",status.Successful],
      ["Revised",status.Revised],
      ["Unsuccessful", status.Unsuccessful],
    ];

 
    setDataPercentage(percentage);
    setTotalProposals(status.total);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Panel title={`${title} (${totalProposals})`}>
      {!isLoading && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: -25,
              marginBottom: 0,
              gap: 26,
              marginLeft: 60,
              marginRight: 60,
              fontWeight: 500,
            }}
          >
            <div style={{ textAlign: "center", color: "#e8e8e8" }}>
              <div>{data.Pending} </div>
              {/* <div>Pending</div> */}
            </div>
            <div style={{ textAlign: "center", color: "#3FD186" }}>
              <div>{data.Successful} </div>
              {/* <div>Successful</div> */}
            </div>
            <div style={{ textAlign: "center", color: "#ffbf00" }}>
              <div>{data.Revised} </div>
              {/* <div>Revised</div> */}
            </div>
            <div style={{ textAlign: "center", color: "#C70039" }}>
              <div>{data.Unsuccessful} </div>
              {/* <div>Unsuccessful</div> */}
            </div>
          </div>
          {dataPercentage && (
            <Chart
              data={dataPercentage}
              width="500px"
              height="300px"
              chartType="PieChart"
              options={{colors: ['#e8e8e8', "#3FD186" ,"#ffbf00", "#C70039"], legend: {
                position: 'top',
                alignment: 'center',
              
            },}}
              // suffix="%"
              // label="Value"
              // legend={false}
            />
          )}
        </div>
      )}
    </Panel>
  );
});
