import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'content',
      startPath: 'courses',
      routes:[
        { name: 'Courses', path: 'courses', icon: 'casino'},
        { name: 'Modules', path: 'modules', icon: 'local_movies'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});