import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";



import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import IndustryView from "./industries-all-industries-view";


// Actions
import {getIndustries} from "repository/actions/data/dashboard/industries"

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);


  const [industries, setIndustries] = React.useState(null)


  const getData = () => {
    getIndustries(user.idToken).then((result)=>{
      setIndustries(result)
    });
  }
 

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (industries == null) {
        getData()
      }
    }
  }, [user]);


  return (
    <div>
      <IndustryView
        loading={!industries}
        onPressedItem={(id) => props.history.push(`${id}`)}
        sectionDescription={"You can manage all of your industries below."}
        industries={industries}
        user={user}
        getData={getData}
      />

    </div>
  );
});
