import React from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  Typography,
  TableContainer,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Badge,
} from "@material-ui/core";

import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";

import moment from "moment";

// Navigation
import NavCrumbs from "./payments-all-payments-nav-crumbs";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    user,
    loading,
    clientMap,
    projectMap,
    payments,
    setPayments,
    onPressedItem,
    sectionDescription,
    editProjectPayment,
  } = props;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -10,
      top: 12,
      padding: "0 4px",
    },
  }));

  const handlePaymentStatus = async (row, index) => {
    let paymentId = row._id;

    let payload = {
      status: "sent",
    };

    await editProjectPayment(paymentId, payload)
      .then((result) => {
        let newPayment = [...payments];
        newPayment.splice(index, 1);
        setPayments(newPayment);

        toast.success(`Payment for ${row.projectTitle} marked as Sent`);
      })
      .catch((e) => {
        toast.error(e.massage);
      });
  };

  const Hits = () => {
    return (
      <TableBody>
        {payments.map((row, index) => {
          let date = new Date(row.date);
          let month = moment(date).format("MMMM");
          let thisMonth = moment().format("MMMM");

          let isThisMonth = false;
          if (thisMonth == month) {
            isThisMonth = true;
          }
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell className={classes.cellWide} align="left">
                <Checkbox
                  color="primary"
                  onChange={() => handlePaymentStatus(row, index)}
                  icon={<DoneOutlineRoundedIcon />}
                  checkedIcon={<DoneOutlineRoundedIcon />}
                />

                {isThisMonth && (
                  <StyledBadge variant="dot" color="secondary">
                    {month}
                  </StyledBadge>
                )}
                {!isThisMonth && <span> {month} </span>}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.projectId)}
                className={classes.cellType}
                align="left"
              >
                {projectMap[row.projectId]
                  ? projectMap[row.projectId]
                  : ""}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.projectId)}
                className={classes.cellType}
                align="left"
              >
                {clientMap[row.clientId] ? clientMap[row.clientId] : ""}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.projectId)}
                className={classes.cellType}
                align="left"
              >
                {row.amount}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <NavCrumbs>
      <Panel
        title="PENDING PAYMENTS"
        loading={loading}
        sectionDescription={sectionDescription}
      >
        {payments.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* <TableCell align="left">
                    <Typography className={classes.labelText}>
                      Paid
                    </Typography>
                  </TableCell> */}
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>Month</Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Project
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Client
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <Hits />
            </Table>
          </TableContainer>
        )}
        {payments.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            No pending payments.
          </Typography>
        )}
      </Panel>
    </NavCrumbs>
  );
}
