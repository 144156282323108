const styles = theme => ({
  root: {
    position: 'fixed',
    left: '50%',
    display: 'flex',
    flexDirectin: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh'
  },
  card: {
    position: 'absolute',
    paddingLeft: 65,
    paddingRight: 65,
    zIndex: 1500,
    width: 570,
    height: 380,
    padding: 10,
    boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .1)',
    marginBottom: 24
  },
  title: {
    marginTop: -10,
    fontFamily: 'proximaNova-bold',
    fontSize: 28,
    marginBottom: 20
  },
  description: {
    fontSize: 14,
    marginBottom: -5
  },
  verifyText: {
    position: 'absolute',
    fontSize: 12,
    marginTop: -14,
    marginLeft: 70,
    color: 'red'
  },
  facebookLogo: {
    position: 'absolute',
    borderRadius: 9,
    marginLeft: -24,
    width: 17,
    height: 'auto'
  },
  icon: {
    marginTop: 30,
    fontSize: 38,
    marginBottom: 0
  },
  positionCheckbox: {
    marginTop: -2,
  },
  checkboxRoot: {
    '&$checked': {
      color: theme.palette.button.green.active,
    },
  },
  checked: {
    color: 'white'
  },
  textField: {
    width: '75%',

  },
  facebookButtonIcon: {
    position: 'absolute',
    fontFamily: 'proximaNova-bold',
    width: 16,
    height: 'auto',
    marginLeft: 27,
    marginTop: 1
  },
  space: {
    marginTop: 12
  },
  facebookButton: {
    fontSize: 12,
    display: 'inline-block',
    fontFamily: 'proximaNova-bold',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    textTransform: 'none',
    width: 250,
    height: 40,
    borderRadius: 4,
    paddingTop: 10,
    marginLeft: 0,
    marginTop: 40,
    marginBottom: 25,
    '&:hover': {
        backgroundColor: '#5f478d',
        borderColor: '#5f478d',
        boxShadow: 'none',
      },
  },
  terms: {
    fontSize: 12,
    marginLeft: -5
  },
  link: {
    marginLeft: 3,
    marginRight: 3
  },
  logo: {
    height: 'auto',
    width: 150,
    marginTop: 20,
    marginBottom: 20
  }
});
  
export default styles;
  