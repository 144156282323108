import React from 'react';
import withMemo from 'utils/helpers/withMemo';

// Children
import Layout from './dashboard-layout';
import DashboardRoutes from './dashboard-routes';

export default withMemo(function (props) {

  return (
    <Layout>
        <DashboardRoutes />
    </Layout>
  )
},[])
