import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AllVacanciesContext } from "repository/stores/dashboard/vacancies/vacancies-all-provider";

// Children
import VacancyView from "./single-client-vacancies-view";

// Dialogues
import DialogueCreateVacancy from "routes/dashboard/dialogues/vacancies/create-vacancy";
import DialogueDeleteVacancy from "routes/dashboard/dialogues/vacancies/delete-vacancy";


export default withRouter(function (props) {

  let { user, client, clientId, setClient } = props


  const { createVacancy, deleteVacancy } = useContext(AllVacanciesContext);

  const [selectedId, setSelectedId] = useState(null);
  const [showNewVacancyDialogue, setShowNewVacancyDialogue] = useState(false);
  const [showDeleteVacancyDialogue, setShowDeleteVacancyDialogue] = useState(false);
  const [refreshAlgolia, setRefreshAlgolia] = React.useState(false)





  return (
    <div>
      <VacancyView
        loading={!client}
        onPressedItem={(id) => window.location.href = `/vacancies/${id}`}
        toggleAddVacancyForm={() => setShowNewVacancyDialogue(true)}
        sectionDescription={client ? `You can manage all of ${client.title}'s vacancies below.` : ""}
        client={client || null}
        onPressedDeleteItem={(id) => {
          setSelectedId(id);
          setShowDeleteVacancyDialogue(true);
        }}
        user={user}
        clientId={clientId}
        refreshAlgolia={refreshAlgolia}
        setRefreshAlgolia={setRefreshAlgolia}

      />

      <DialogueCreateVacancy
        isOpen={showNewVacancyDialogue}
        setClose={() => setShowNewVacancyDialogue(false)}
        user={user}
        clientId={clientId}
        client={client}
        setClient={setClient}
        createVacancy={createVacancy}
        setRefreshAlgolia={setRefreshAlgolia}
      />

      <DialogueDeleteVacancy
        isOpen={showDeleteVacancyDialogue}
        setClose={() => setShowDeleteVacancyDialogue(false)}
        user={user}
        vacancyId={selectedId}
        deleteVacancy={deleteVacancy}
        setClient={setClient}
        clientId={clientId}
        setRefreshAlgolia={setRefreshAlgolia}
      />


    </div>
  );
});
