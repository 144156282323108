import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'projects',
      startPath: 'projects',
      routes:[
        { name: 'Active', path: 'active', icon: 'timeline'},
        { name: 'Complete', path: 'complete', icon: 'check'},
      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});