import React, { memo } from 'react';

// Children
import Navigation from 'routes/dashboard/nav-tabs';

export default memo( function (props) {
 
  let tabs = {
      transferPath: 'proposals',
      startPath: 'all',
      routes:[
        { name: 'Pending', path: 'pending', icon: 'pending'},
        { name: 'Successful', path: 'successful', icon: 'thumb_up'},
        { name: 'Revised', path: 'revised', icon: 'change_circle'},
        { name: 'Unsuccessful', path: 'unsuccessful', icon: 'thumb_down'},

      ]
  }

  return (
    <div>
        <Navigation tabs={tabs}/>
        {props.children}  
    </div>
  );
});