import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  InputAdornment,
  Grid,
  Divider
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import MUIRichTextEditor from "mui-rte";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, convertFromHTML } from 'draft-js';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// Actions
import { getIndustries } from "repository/actions/data/dashboard/industries"

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, getVacancy, setVacancy, editVacancy, vacancy, vacancyId } = props;

  const [positionName, setPositionName] = useState(null)
  const [minSalary, setMinSalary] = useState(null)
  const [maxSalary, setMaxSalary] = useState(null)
  const [industry, setIndustry] = useState(null)
  const [location, setLocation] = useState(null)
  const [description, setDescription] = useState(null)
  const [descriptionRaw, setDescriptionRaw] = useState(null)

  const [excerpt, setExcerpt] = useState(null)
  const [contractType, setContractType] = useState(null)
  const [headCount, setHeadCount] = useState(null)

  const [rtf, setRTF] = useState(null)





  const [saveLoading, setSaveLoading] = React.useState(false);

  const [industries, setIndustries] = React.useState(null);
  const [error, setError] = React.useState(null);


  useEffect(() => {
    if (user) {
      getIndustries(user.idToken).then((result) => {
        setIndustries(result)
      })
    }
  }, [user])

  
  useEffect(() => {
    if (vacancy) {

      // let content = htmlToDraft(vacancy.specification)


      // console.log(content)
      // content.blocks = content.contentBlocks
      // console.log(content)
      // setRTF(JSON.stringify(content))


      setDescriptionRaw(JSON.stringify(vacancy.specificationRaw))

      setPositionName(vacancy.positionName);
      setMinSalary(vacancy.minSalary ? vacancy.minSalary.toString() : vacancy.minSalary);
      setMaxSalary(vacancy.maxSalary ? vacancy.maxSalary.toString() : vacancy.maxSalary);
      setIndustry(vacancy.industry);
      setLocation(vacancy.location);
      setDescription(vacancy.specification)
      setExcerpt(vacancy.excerpt)
      setContractType(vacancy.contractType)
      setHeadCount(vacancy.headCount)
    }
  }, [vacancy]);


  const handleCreateVacancy = () => {
    if (
      positionName == null ||
      // minSalary == null ||
      // maxSalary == null ||
      location == null ||
      industry == null ||
      description == null ||
      excerpt == null ||
      headCount == null ||
      contractType == null
    ) {
      toast.error("You have missing information for creating a new vacancy");
      if (positionName === null) {
        positionName("Position Name is required.");
      }
      // if (minSalary == null) {
      //   setError("Minimum Salary is required.");
      // }
      // if (maxSalary == null) {
      //   setError("Maximum Salary is required.");
      // }
      if (location == null) {
        setError("Location is required.");
      }
      if (industry == null) {
        setError("Industry is required.");
      }
      if (description == null) {
        setError("Description is required.");
      }
      if (headCount == null) {
        setError("Head Count is required.");
      }
      if (contractType == null) {
        setError("Contact Type is required.");
      }
      toast.error(error);

    } else {
      let payload = {
        positionName: positionName,
        minSalary: minSalary ? minSalary.replace(",", "") : minSalary,
        maxSalary: maxSalary ? maxSalary.replace(",", "") : maxSalary,
        industry: industry,
        location: location,
        headCount: headCount,
        excerpt: excerpt,
        specification: draftToHtml((convertToRaw(description.getCurrentContent()))),
        specificationRaw: (convertToRaw(description.getCurrentContent())),

        contractType: contractType,
      }

      setSaveLoading(true);
      toast.dark(`Creating Vacancy : ${payload.positionName}`);

      editVacancy(vacancyId, payload, user.idToken)
        .then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`Vacancy created : ${payload.positionName}`);
          setPositionName(null)
          setMinSalary(null)
          setMaxSalary(null)
          setIndustry(null)
          setLocation(null)
          setDescription(null)
          setContractType(null)
          setHeadCount(null)
          setError(null);

          getVacancy(vacancyId, user.idToken).then((result) => {
            setVacancy(result)
          })
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };


  function formatCurrancy(value) {
    let newValue = value.replace(",", "")
    newValue = newValue.replace(/\D/g, '')
    if (newValue.length > 3 && newValue.indexOf(",") == -1) {
      let format = newValue.substring(0, newValue.length - 3) + "," + newValue.substring(newValue.length - 3, newValue.length)
      return format
    }
    else {
      return newValue
    }
  }



  return (
    <Popup
      title="Edit Vacancy"
      subtitle="Please fill in the details below to edit Vacancy."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateVacancy()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      isOnKeyPressDown={false}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Project Manager"}
              required
              label="Position Name"
              value={positionName}
              onChange={(event) => setPositionName(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: €18000"}
                  label="Min Salary"
                  value={minSalary ? formatCurrancy(minSalary) : minSalary}
                  onChange={(event) => setMinSalary(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>

              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: €20000"}
                  label="Max Salary"
                  value={maxSalary ? formatCurrancy(maxSalary) : maxSalary}
                  onChange={(event) => setMaxSalary(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  placeholder={"Ex: Mosta"}
                  required
                  label="Location"
                  value={location}
                  onChange={(event) => setLocation(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} >
                <TextField
                  placeholder={"Ex: 10"}
                  required
                  type="number"
                  label="Head Count"
                  value={headCount}
                  onChange={(event) => setHeadCount(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.inputType}>
                <InputLabel >
                  Contract Type
                </InputLabel>
                <Select
                  elevation={0}
                  onChange={(event) => setContractType(event.target.value)}
                  defaultValue={contractType}
                  required
                  input={<Input id="type-menu" />}
                >
                  <MenuItem value={"Full Time"}>{"Full Time"}</MenuItem>
                  <MenuItem value={"Reduced Hours"}>{"Reduced Hours"}</MenuItem>
                  <MenuItem value={"Part Time"}>{"Part Time"}</MenuItem>
                  <MenuItem value={"Shifts"}>{"shifts"}</MenuItem>
                  <MenuItem value={"Temporal"}>{"Temporal"}</MenuItem>
                  <MenuItem value={"Part / Full Time"}>{"Part / Full Time"}</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Industry
                </InputLabel>
                {industries && industries != null && (
                  <Select
                    elevation={0}
                    onChange={(event) => setIndustry(event.target.value)}
                    value={industry}
                    required
                    disabled={saveLoading}
                    input={<Input id="type-menu" />}
                  >
                     {industries.map((item, index) => {
                       return <MenuItem value={item.title}>{item.title}</MenuItem>;
                    })}
                  </Select>
                )}

              </FormControl>
            </Grid></Grid>
          <div></div>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <FormControl className={classes.formControl} >
                <TextField
                  required
                  label="Excerpt"
                  value={excerpt}
                  inputProps={{ maxLength: 50 }}
                  onChange={(event) => setExcerpt(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={1} style={{display:"flex",alignItems:"end"}}>
              {excerpt ? excerpt.length : 0}/50
            </Grid>
          </Grid>
          <div></div>
          <FormControl className={classes.inputType} style={{ margin: "40px 0" }}>
            {description && <MUIRichTextEditor
              label="Job description..."
              className="rich-text-editor"
              controls={["title", "bold", "italic", "underline", "highlight", "link", "numberList", "bulletList", "quote", "code", "save"]}
              onChange={(event) => { setDescription(event)}}
              defaultValue={descriptionRaw}
            />}
          </FormControl>

          {/* <Grid container style={{ margin: "30px 0px" }}>
            <Grid item xs={4} style={{ padding: "10px" }}>
              <Divider />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              Current Version
            </Grid>
            <Grid item xs={4} style={{ padding: "10px" }}>
              <Divider />
            </Grid>
          </Grid> */}



{/* 
          <div dangerouslySetInnerHTML={{
            __html: vacancy.specification
          }} /> */}

        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
