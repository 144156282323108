import apiClient from "utils/api";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";
import { getIdToken } from "../authentication"


export const findAllUsers = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/users").find({
          query: {
            firebaseToken: token,
          },
        });

        if (result.data.length > 0) {
          for (let u of result.data) {
            if (u.displayPhoto) {
              let signedPhotoUrl =
                u.displayPhoto.url.indexOf("st-1") > -1
                  ? await getSignedUrlFromAbsoluteUrl(u.displayPhoto.url, token)
                  : u.displayPhoto.url;
              u.displayPhoto.url = signedPhotoUrl;
            } else {
              u.displayPhoto = {
                url: null,
              };
            }
          }
        }

        dispatch({
          type: "UpdateUser",
          payload: result.data,
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewUser = async (dispatch, user, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/users").create(user, {
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "AddUser",
          payload: result,
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteUser = async (dispatch, userId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/users").remove(userId, {
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "RemoveUser",
          payload: result,
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const editUser = async (dispatch, data, userId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let updatedUser = await apiClient
          .service("v1/users")
          .patch(userId, data, {
            query: {
              firebaseToken: token,
            },
          });

        dispatch({
          type: "EditUser",
          payload: {
            userId: userId,
            data: updatedUser,
          },
        });

        resolve();
      })
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleUser = async (userId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      // let apiClient = getClient();
       
      getIdToken().then(async (token) => {
        let result = await apiClient.service("v1/users").get(userId, {
          query: {
            firebaseToken: token,
          },
        });

        resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};
