import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AllLocationsContext } from "repository/stores/dashboard/locations/locations-all-provider";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import { TextField, FormControl } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

// Actions
// import { editLocation } from "repository/actions/data/dashboard/locations";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, data, itemId, user, editLocation, setRefreshAlgolia } = props;

  const {location, setLocations} = React.useContext(AllLocationsContext);

  const [address, setAddress] = React.useState(null);
  const [zoomCode, setZoomCode] = React.useState(null);
  const [title, setTitle] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [addressError, setAddressError] = React.useState(null);
  const [titleError, setTitleError] = React.useState(null);

  useEffect(() => {
    if (data) {
      setAddress(data.address);
      setTitle(data.title);
      setZoomCode(data.zoomCode?data.zoomCode:null)
    }
  }, [data]);

  const handleEditLocation = () => {
    if (user && user != null) {
      if (address == null || title == null) {
        toast.error(`You have missing information for editing this location`);
        if (address === null) {
          setAddressError("Address is required.");
        }
        if (title == null) {
          setTitleError("Title is required.");
        }
      } else {
        let saveData = {
          address: address,
          zoomCode: zoomCode,
          title: title,
        };

        setSaveLoading(true);

        editLocation(saveData, itemId, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            toast.success(`${saveData.title} successfully edited`);
            setClose();
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Edit location"
      subtitle="You can edit the details of your location below."
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleEditLocation()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Student Room"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: XXXXXXX"}
              disabled={saveLoading}
              required
              label="Zoom Code"
              value={zoomCode}
              onChange={(event) => setZoomCode(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Hard Rocks, Naxxar"}
              disabled={saveLoading}
              required
              helperText={addressError}
              error={addressError != null}
              label="Address"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
        </div>
      }
    />
  );
}

export default withSnackbar(withRouter(AddMenuDialogue));
