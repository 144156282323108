import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllProposalsContext } from "repository/stores/dashboard/proposals/proposals-all-provider";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import CalendarView from "./calendar";

export default withRouter(function (props) {
  const consultantId = props.match.params.id;

  const { getConsultant } = useContext(AllConsultantsContext);
  const {
    createProjectTiming,
    findProjectTimingsbyConsultant,
    getActiveProjects,
    findActiveProjectsbyConsultant,
    findCompleteProjectsbyConsultant
  } = useContext(AllProjectsContext);

  const {
    getProposals,
    createProposalTiming,
    findProposalTimingsbyConsultant,
  } = useContext(AllProposalsContext);

  const { user } = useContext(AuthenticationContext);

  const [consultant, setConsultant] = useState(null);

  useEffect(() => {
    if (user) {
      if (consultant == null) {
        getConsultant(consultantId).then((result) => {

          // if (user.email == result.email || user.roles.indexOf("admin") >= 0) {
            setConsultant(result);
          // } else if (user.email != result.email) {
          //   toast.error("You are not this consultant");

          //   setTimeout(() => {
          //     window.history.back();
          //   }, 2000);
          // }
        });
      }
    }
  }, [user]);

  return (
    <div>
      {consultant && (
        <CalendarView
          loading={!consultant}
          consultant={consultant}
          user={user}
          getProposals={getProposals}
          consultantId={consultantId}
          createProjectTiming={createProjectTiming}
          getActiveProjects={getActiveProjects}
          createProposalTiming={createProposalTiming}
          findActiveProjectsbyConsultant={findActiveProjectsbyConsultant}
          findProjectTimingsbyConsultant={findProjectTimingsbyConsultant}
          findProposalTimingsbyConsultant={findProposalTimingsbyConsultant}
          findCompleteProjectsbyConsultant={findCompleteProjectsbyConsultant}
        />
      )}
    </div>
  );
});
