import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  TextField,
  FormControl,
  Grid,
  Typography,
  Slider,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    panelDescription,
    years,
    programme,
    setProgramme,
    isNewProgramme,
    studentTotalCost,
    setStudentTotalCost,
  } = props;
  const classes = useStyles();

  const studentCostings = [
    { title: "Canvas", cost: 50 },
    { title: "Technical Support", cost: 50 },
    { title: "Plagiarism Detection", cost: 25 },
    { title: "Online Library ", cost: 100 },
    // { title: "Mentoring", cost: 0, options: [25, 35, 333] },
    { title: "Mentoring", cost: 0 },
    { title: "Supervision", cost: 35 },
    // { title: "Dissertation Supervisor ", cost: 0, options: [250, 350, 750] },
    { title: "Dissertation Supervisor ", cost: 0 },
  ];

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 25,
      label: "25",
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const [studentCosts, setStudentCosts] = useState(studentCostings);

  const [sumPerStudent, setSumPerStudent] = useState(0);
  const [sumStudentCount, setSumStudentCount] = useState(0);
  const [studentCount, setStudentCount] = useState(1);

  useEffect(() => {
    if (!isNewProgramme) {
      setStudentCount(programme.studentCount ? 1 : 1);
      setStudentCosts(programme.studentCosts);
    }
  }, [isNewProgramme]);

  useEffect(() => {
    let sum = [...studentCosts].reduce((accumulator, object) => {
      return accumulator + object.cost;
    }, 0);

    sum = sum + studentCosts[0].cost * (years == 0 ? 0 : years - 1);
    sum = sum + studentCosts[1].cost * (years == 0 ? 0 : years - 1);
    sum = sum + studentCosts[2].cost * (years == 0 ? 0 : years - 1);
    sum = sum + studentCosts[3].cost * (years == 0 ? 0 : years - 1);

    setSumPerStudent(sum);
    setSumStudentCount(sum * studentCount);

    setStudentTotalCost({
      ...studentTotalCost,
      studentCount: studentCount,
      studentCosts: studentCosts,
      studentTotalCosts: sum * studentCount,
    });

    setProgramme({
      ...programme,
      studentCosts: studentCosts,
      // studentTotalCosts: sum * studentCount,
      studentCount: studentCount,
    });
  }, [studentCosts, years, studentCount]);

  const handleStudentCount = (event, newValue) => {
    // setSumStudentCount(sumPerStudent * newValue);
    setStudentCount(newValue);
  };

  const handleCostChange = (event, index) => {
    let costs = [...studentCosts];
    costs[index].cost = event.target.value ? parseFloat(event.target.value) : 0;
    setStudentCosts(costs);
  };
  const handleTitleChange = (event, index) => {
    let costs = [...studentCosts];
    costs[index].title = event.target.value ;
    setStudentCosts(costs);
  };

  

  function numberWithCommas(x) {
    if (!x) return "";
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const addStudentExp = () => {
    let newStudentCosts = [...studentCosts];
    newStudentCosts.push({ title: "Cost Per Student", cost: 0 });
    setStudentCosts(newStudentCosts);
  };

  return (
    <Panel
      title={`Cost Per Student`}
      loading={loading}
      panelDescription={panelDescription}
    >
      {studentCosts.map((item, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={7}>
              <FormControl className={classes.inputType}>
                <TextField
                  placeholder="Expense"
                  variant="outlined"
                  type="text"
                  value={item.title}
                  size="small"
                  className={classes.costings}
                  onChange={(event) => {
                    handleTitleChange(event, index);
                  }}
                  inputProps={{
                    style: {
                      // readOnly: true,
                      fontSize: 15,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {!item.options && (
                <FormControl className={classes.inputType}>
                  <TextField
                    placeholder="Cost"
                    variant="outlined"
                    type="number"
                    value={item.cost}
                    onChange={(event) => {
                      handleCostChange(event, index);
                    }}
                    size="small"
                    className={classes.costings}
                    inputProps={{
                      style: {
                        readOnly: false,
                        fontSize: 15,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )}
              {item.options && (
                <FormControl
                  fullWidth
                  size="small"
                  className={classes.inputType}
                >
                  <Select
                    variant="outlined"
                    value={item.cost}
                    onChange={(event) => {
                      handleCostChange(event, index);
                    }}
                    style={{ textAlign: "left" }}
                    className={classes.costings}
                    inputProps={{
                      style: {
                        fontSize: 15,
                      },
                    }}
                  >
                    {item.options.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        );
      })}

      <AddCircleIcon
        onClick={() => {
          addStudentExp();
        }}
        style={{ marginTop: 25, fontSize: 19, cursor: "pointer" }}
      />
      <Grid
        container
        spacing={2}
        style={{ marginTop: 30, justifyContent: "space-around" }}
      >
        <Grid item xs={7} style={{ textAlign: "left" }}>
          <Typography style={{ fontSize: 12 }}>Number of Students</Typography>
          <Slider
            defaultValue={1}
            getAriaValueText={valuetext}
            step={null}
            valueLabelDisplay="auto"
            marks={marks}
            min={1}
            max={25}
            onChangeCommitted={handleStudentCount}
            style={{ marginTop: 20, width: "95%" }}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl className={classes.inputType}>
            <TextField
              placeholder="Cost"
              variant="outlined"
              type="text"
              value={numberWithCommas(parseInt(sumStudentCount))}
              size="small"
              className={classes.costings}
              color="secondary"
              focused
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Panel>
  );
});
