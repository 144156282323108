import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllModules = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/modules").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdateModules",
          payload: result.data,
        });

        resolve(result.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewModule = async (dispatch, moduleData, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      let result = await apiClient.service("v1/modules").create(moduleData, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "AddModule",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editModule = async (dispatch, data, moduleId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      let updatedModule = await apiClient
        .service("v1/modules")
        .patch(moduleId, data, {
          query: {
            firebaseToken: token,
          },
        });

      dispatch({
        type: "EditModule",
        payload: {
          moduleId: moduleId,
          data: updatedModule,
        },
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteModule = async (dispatch, moduleId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      let result = await apiClient.service("v1/modules").remove(moduleId, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "RemoveModule",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleModule = async (moduleId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
      let result = await apiClient.service("v1/modules").get(moduleId, {
        query: {
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
