import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AllPersonnelContext } from "repository/stores/dashboard/personnel/personnel-all-provider";
import styles from "./dialogues-edit-experience-styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";

//Actions
import { updateNewPersonnel } from "repository/actions/data/dashboard/personnel";
const useStyles = makeStyles(styles);

function EditExperienceDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, personId, person, setComments, user } = props;
  const {personnel, setPersonnel} = React.useContext(AllPersonnelContext);

  const [commentEntries, setCommentEntries] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [newCommentEntry, setNewCommentEntry] = React.useState(null);
  const [newCommentEntryError, setNewCommentEntryError] =
    React.useState(null);

  useEffect(() => {
    if (person && person != null) {
      if (person.comments) {
        setCommentEntries(person.comments);
      }
    }
  }, [person]);

  const handleAddNewCommentEntry = () => {
    if (newCommentEntry == null) {
      setNewCommentEntryError("Cannot have an empty Comment");
    } else {
      let newCommentEntries = [newCommentEntry].concat(commentEntries);
      setCommentEntries(newCommentEntries);
      setNewCommentEntryError(null);
    }
  };

  const handleSaveExperienceEntries = () => {
    if (user && user != null) {
      let patchData = { ...person, comments: commentEntries };
      updateNewPersonnel(personId, patchData, user.idToken)
        .then((result) => {
          toast.success(
            `Comments for ${person.firstName} ${person.lastName} updated`
          );
          setComments(commentEntries);
          handleClose();
          setSaveLoading(false);
        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information."
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  const onPressedDeleteCommentEntry = (index) => {
    if (commentEntries.length > index) {
      let newCommentEntries = [];

      for (let [i, e] of commentEntries.entries()) {
        if (i != index) {
          newCommentEntries.push(e);
        }
      }
      setCommentEntries(newCommentEntries);
    }
  };

  const handleClose = () => {
    setClose();
  };

  return (
    <Popup
      title="Comments"
      subtitle="Edit this person's Comments"
      isOpen={isOpen}
      handleCancel={() => handleClose()}
      handleAction={() => handleSaveExperienceEntries()}
      actionButtonTitle="Save"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Is Abroad"}
                    disabled={saveLoading}
                    required
                    helperText={newCommentEntryError}
                    error={newCommentEntryError != null}
                    label="New Comment"
                    value={newCommentEntry}
                    onChange={(event) =>
                      setNewCommentEntry(event.target.value)
                    }
                    margin="normal"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    onClick={() => handleAddNewCommentEntry()}
                  >
                    Add Comment
                  </Button>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.fullColumn}>
                <List className={classes.root}>
                  {commentEntries.map((entry, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <SubjectIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Grid container spacing={3}>
                              <Grid item xs={9}>
                                {entry}
                              </Grid>
                              <Grid item xs={3}>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={(event) => {
                                    onPressedDeleteCommentEntry(index);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withSnackbar(EditExperienceDialogue);
