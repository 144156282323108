import React, {useContext} from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";



export default withSnackbar(
  withRouter(function (props) {

    const { isOpen, setClose, ticketId, setTicket, getTicket, ticket, user, editTicket,  selectedNoteIdx } = props;


    const [saveLoading, setSaveLoading] = React.useState(false);


    const handleDeleteNote = () => {
      if (user && user != null) {
        setSaveLoading(true);


        let newNotes = ticket.notes
        newNotes.splice(selectedNoteIdx, 1)

        editTicket(ticketId, { notes: newNotes }, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Note successfully deleted`);

            getTicket(ticketId, user.idToken).then((result) => {
              setTicket(result)
            })
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected Note"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteNote()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this note?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
