import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Timetables from './routes/timetable'

export default function () {
  return (
    <div>
      <Switch>
        <Route path='/today/timetables' component={() => <Timetables />} />
        <Route exact path='/today' component={() => <Redirect to={`/today/timetables`} />} />
      </Switch>
    </div>
  )
}