import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleClientDetailsView from "./single-client-details-view";
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

// Dialogues
import DialogueEditClient from "routes/dashboard/dialogues/clients/edit-client";


export default withRouter(function (props) {
  let {
    client,
    setClient,
    clientId,
    user,
  } = props;

  const { editClient, getClient } = useContext(AllClientsContext);

  const [showEditClientDialogue, setShowEditClientDialogue] = useState(false);


  return (
    <div>
      <SingleClientDetailsView
        loading={!client}
        sectionDescription={"Detailed information about the client."}
        user={user}
        client={client}
        setShowEditClientDialogue={setShowEditClientDialogue}
      />

      <DialogueEditClient
        isOpen={showEditClientDialogue}
        setClose={() => setShowEditClientDialogue(false)}
        user={user}
        client={client}
        clientId={clientId}
        editClient={editClient}
        getClient={getClient}
        setClient={setClient}
      />


    </div>
  );
});
