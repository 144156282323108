import React from 'react';

// Parents
// import ClientsTabs from './dash-clients-tabs';
import ClientsRoutes from './dash-clients-routes';

export default function (props) {

  return (
    // <ClientsTabs>
      <ClientsRoutes />
    // </ClientsTabs>
  )
}
