import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import Panel from "components/panel";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  TextField,
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Divider,
  Typography,
  Badge,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    user,
    loading,
    project,
    setProject,
    isNewProject,
    panelDescription,
    createProjectPayment,
    deleteProjectPayment,
    findProjectPaymentByProject,
  } = props;
  const classes = useStyles();

  const [totalPayment, setTotalPayment] = useState(0);

  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [paymentTitle, setPaymentTitle] = useState(null);

  const [allPayments, setAllPayments] = useState([]);

  const getPayments = async () => {
    await findProjectPaymentByProject(project._id).then((result) => {
      setAllPayments(result.data);

      let total = result.data.reduce(
        (acc, curr) => acc + parseFloat(curr.amount),
        0
      );
      setTotalPayment(total);
    });
  };

  useEffect(() => {
    if (project) {
      if (!isNewProject) {
        getPayments();
      }
    }
  }, [project]);

  const handlePaymentDate = (date) => {
    let endOfMonth = moment(date).endOf("month").valueOf();
    setPaymentDate(endOfMonth);
  };

  const handleCreatePayment = async () => {
    let newPayment = {
      amount: paymentAmount,
      title: paymentTitle,
      date: paymentDate,
      year: moment(paymentDate).format("YYYY"),
      projectId: project._id,
      clientId: project.clientId,
      status: "pending",
    };

    await createProjectPayment(newPayment)
      .then((result) => {
        let updatedPayments = [...allPayments];
        updatedPayments.push(result);

        let totalPaymentAmount = totalPayment + parseFloat(result.amount);
        setTotalPayment(totalPaymentAmount);

        setAllPayments(updatedPayments);
      })
      .catch((e) => {
        toast.error(e.message);
      });

    setPaymentDate(null);
    setPaymentAmount("");
    setPaymentTitle("");
  };

  const removePayment = async (index) => {
    let payments = [...allPayments];
    let paymentToBeRemoved = payments[index];

    await deleteProjectPayment(paymentToBeRemoved._id)
      .then((result) => {
        let updatedPayments = [...allPayments];
        updatedPayments.splice(index, 1);
        setAllPayments(updatedPayments);

        let totalPaymentAmount = totalPayment - parseFloat(result.amount);
        setTotalPayment(totalPaymentAmount);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const dimIsNewProject = (isNewProject) => {
    return {
      opacity: isNewProject ? 0.25 : 1,
    };
  };

  const StyledBadge = styled(Badge)(({ theme, status }) => ({
    "& .MuiBadge-badge": {
      left: -10,
      top: 12,
      padding: "0 4px",
      backgroundColor: status ? "#00FF00" : "#808080",
    },
  }));

  return (
    <div style={dimIsNewProject(isNewProject)}>
      <Panel
        title={"Payments"}
        loading={loading}
        panelDescription={panelDescription}
      >
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "space-between" }}
            >
              <Grid item xs={3}>
                <FormControl className={classes.inputType}>
                  <TextField
                    label="Amount"
                    type="number"
                    value={paymentAmount}
                    onChange={(event) => {
                      setPaymentAmount(event.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    disabled={isNewProject}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl className={classes.inputType}>
                  <TextField
                    label="Title"
                    placeholder="(Shows on invoice)"
                    type="text"
                    value={paymentTitle}
                    disabled={isNewProject}
                    onChange={(event) => {
                      setPaymentTitle(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    views={["year", "month"]}
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Due Date"
                    value={paymentDate}
                    onChange={(date) => {
                      handlePaymentDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isNewProject}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  disabled={
                    paymentDate == null ||
                    paymentAmount == null ||
                    paymentAmount == "" ||
                    paymentTitle == null ||
                    paymentTitle == ""
                  }
                  onClick={() => handleCreatePayment()}
                >
                  <DoneIcon />
                </IconButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>

          {allPayments.length > 0 && (
            <div
              style={{
                borderRadius: 10,
                boxShadow: "0 10px 70px -20px rgba(0,0,0,0.15)",
                padding: 20,
                paddingTop: 0,
                marginTop: 30,
              }}
            >
              <List>
                {allPayments.map((item, index) => {
                  return (
                    <div>
                      <ListItem style={{ paddingLeft: "10px" }}>
                        <Grid container>
                          <Grid item xs={3}>
                            <StyledBadge
                              status={item.status == "sent"}
                              variant="dot"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              €{" "}
                              {item.amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </StyledBadge>
                          </Grid>
                          <Grid item xs={6}>
                            {item.title}
                          </Grid>
                          <Grid item xs={3}>
                            {moment(item.date).format("DD/MM/yyyy")}
                          </Grid>
                        </Grid>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            removePayment(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </ListItem>
                    </div>
                  );
                })}
              </List>

              <div>
                <Divider />
                <Divider style={{ marginTop: 1 }} />
                <Typography
                  align="left"
                  variant="h6"
                  style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
                >
                  €{" "}
                  {totalPayment
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
                <Divider />
                <Divider style={{ marginTop: 1 }} />
              </div>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
});
