
import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  sessions: null,
  setSessions: {},
  findAllSessions: {},
  createNewSession: {},
  editSession: {},
  deleteSession: {}
};

export const TrainingSessionsContext = React.createContext(ContextProps);

export const TrainingSessionsProvider = ({ children }) => {
  const [sessions, setSessions] = useState(null);
  const { getIdToken } = useContext(IdTokenContext)


  const findAllSessions = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/training/sessions").find({
            query: {
              firebaseToken: token,
            },
          });
          setSessions(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewSession = async (location, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/training/sessions").create(location, {
            query: {
              firebaseToken: token,
            },
          });

          if (sessions == null) {
            setSessions(result);
          } else {
            let newData = sessions
            newData.push(result)
            setSessions(newData);
          }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editSession = async (data, locationId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        getIdToken().then(async (token) => {

          let updatedProposal = await apiClient
            .service("v1/training/sessions")
            .patch(locationId, data, {
              query: {
                firebaseToken: token,
              },
            });


          let newData = sessions
          for (const [i, v] of newData.entries()) {
            if (v._id === locationId) {
              newData[i] = data;
            }
          }
          setSessions(newData);


          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const deleteSession = async (proposalId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient
            .service("v1/training/sessions")
            .remove(proposalId, {
              query: {
                firebaseToken: token,
              },
            });

          let newData = sessions

          for (const [i, v] of newData.entries()) {
            if (v._id === proposalId) {
              newData.splice(i, 1);
            }
          }
          setSessions(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <TrainingSessionsContext.Provider
      value={{
        sessions,
        setSessions,
        findAllSessions,
        createNewSession,
        editSession,
        deleteSession
      }}
    >
      {children}
    </TrainingSessionsContext.Provider>
  );
};
