
import React, {  useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  proposals: null,
  setProposals: {},
  findAllProposals: {},
  createNewProposal: {},
  editProposal: {},
  deleteProposal: {}
};

export const TrainingProposalsContext = React.createContext(ContextProps);

export const TrainingProposalsProvider = ({ children }) => {
  const [proposals, setProposals] = useState(null);
  const { getIdToken } = useContext(IdTokenContext)

  
  const findAllProposals = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

        let result = await apiClient.service("v1/training/proposals").find({
          query: {
            firebaseToken: token,
          },
        });
        setProposals(result.data);
        resolve(result);
      })
      } catch (e) {
        reject(e);
      }
    });
  };

  
 const createNewProposal = async (location, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      getIdToken().then(async (token) => {

      let result = await apiClient.service("v1/training/proposals").create(location, {
        query: {
          firebaseToken: token,
        },
      });


      if(proposals == null){
        setProposals(result);
      }else{
        let newData = proposals
        newData.push(result)
        setProposals(newData);
      }
      resolve(result);
    })
    } catch (e) {
      reject(e);
    }
  });
};


const editProposal = async (data, locationId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      getIdToken().then(async (token) => {


      let updatedProposal = await apiClient
        .service("v1/training/proposals")
        .patch(locationId, data,{
          query: {
            firebaseToken: token,
          },
        });

     
        let newData = proposals
        for (const [i, v] of newData.entries()) {
          if (v._id === locationId) {
            newData[i] = data;
          }
        }
        setProposals(newData);
     

      resolve();
      })
    } catch (e) {
      reject(e);
    }
  });
};



const deleteProposal = async (proposalId,token) => {
  return new Promise(async (resolve, reject) => {
    try {
      getIdToken().then(async (token) => {

      let result = await apiClient
        .service("v1/training/proposals")
        .remove(proposalId,{
          query: {
            firebaseToken: token,
          },
        });

        let newData = proposals

        for (const [i, v] of newData.entries()) {
          if (v._id === proposalId) {
            newData.splice(i, 1);
          }
        }
        setProposals(newData);

      resolve(result);
      })
    } catch (e) {
      reject(e);
    }
  });
};


  return (
    <TrainingProposalsContext.Provider
      value={{
        proposals,
        setProposals,
        findAllProposals,
        createNewProposal,
        editProposal,
        deleteProposal
      }}
    >
      {children}
    </TrainingProposalsContext.Provider>
  );
};
