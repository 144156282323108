import React from 'react';

// Parents
import UsersTabs from './dash-users-tabs';
import UsersRoutes from './dash-users-routes';

export default function (props) {

  return (
    // <UsersTabs>
      <UsersRoutes />
    // </UsersTabs>
  )
}
