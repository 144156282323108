import React, { useContext, useState, useEffect } from "react";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { withRouter } from "react-router-dom";

// Children
import InfoView from "./info-view";

export default withRouter(function (props) {
  let {hourInfo, leaveTaken, leaveRemaining}=props
  const { user } = useContext(AuthenticationContext);

  return (
    <div>
      <InfoView
        loading={!user}
        user={user}
        hourInfo={hourInfo}
        leaveTaken={leaveTaken}
        leaveRemaining={leaveRemaining}
       />
    </div>
  );
});
