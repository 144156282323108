import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { Grid } from "@material-ui/core";


// Components
import CohortSize from "./cohorts/cohorts-size-view";
import AllCohorts from "./cohorts/all-cohorts";


export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AllCohorts user={user} />
      </Grid>
      <Grid item xs={12}>
        <CohortSize user={user} />
      </Grid>
    </Grid>
  );
});
