import React from 'react';

// Parents
import DashboardRoutes from './dash-dashboard-routes';

export default function (props) {

  return (
      <DashboardRoutes />
  )
}
