import algoliasearch from "algoliasearch/lite";
const assert = require("assert");
require('dotenv').config()

let searchClient;

export function initSearchClient() {
  return new Promise(async (resolve, reject) => {
    try {
      //dev key
      // searchClient = algoliasearch(
      //   "7PJI7UP209",
      //   "90837195d3d02e6bcd7f32dc477c5658"
      // );

      //prod key
      // searchClient = algoliasearch(
      //   "WWOTWAXL1Q",
      //   "e6daca3a02f5986c567830ba31497c49"
      // );

      searchClient=algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID,
        process.env.REACT_APP_ALGOLIA_ADMIN_KEY
      )

      console.log(`Algolia client : ${process.env.REACT_APP_ALGOLIA_APP_ID} connected.`)
      resolve(searchClient);
    } catch (e) {
      reject(e);
    }
  });

  // return callback(null, searchClient);
}

export function getSearchClient() {
  if (!searchClient || searchClient == null) {
    initSearchClient(null).then(() => {
      assert.ok(
        searchClient,
        "Algolia Client has not been initialized. Please called init first."
      );
    });
  }
  return searchClient;
}
