import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Assets
import Logo from 'assets/images/logo_ilmi.png';

// Styling
import styles from './not-found-styles.js';
const useStyles = makeStyles(styles);

export default function () {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <img src={Logo} alt="Logo" className={classes.logo}></img>
        <Typography variant="h2" className={classes.typography}>404 - Page Not Found</Typography>
      </div>
    );
}