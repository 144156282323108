import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllProjects from './routes/all-projects';
import SingleProjects from './routes/single-projects';
import PaymentGantt from './routes/payment-gantt';
import ProjectsGantt from './routes/projects-gantt';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/projects/active' component={() => <AllProjects />} />
                <Route exact path='/projects/complete' component={() => <AllProjects />} />

                <Route exact path='/projects/payment-gantt' component={() => <PaymentGantt />} />
                <Route exact path='/projects/projects-gantt' component={() => <ProjectsGantt />} />
                
                
                <Route path="/projects/:id" component={() => <SingleProjects />} />
                <Route path="/projects/:id" component={() => <SingleProjects />} />
                <Route exact path='/projects' component={() => <Redirect to={`/projects/active`} />} />
            </Switch>
            
        </div>
    )
}