import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
// import { AuthenticationContext } from "repository/stores/global/authentication-provider";

import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Divider,
  Popper,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

// Navigation
import NavCrumbs from "./users-all-users-nav-crumbs";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import Panel from "components/panel";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  const {
    loading,
    toggleAddUserForm,
    onPressedItem,
    activeUsers,
    inactiveUsers,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    onResetPassword,
    setEditingItem,
    user,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <NavCrumbs>
      <Panel
        title="MANAGE YOUR USERS"
        loading={loading}
        actionName="Add user"
        handleAction={toggleAddUserForm}
        sectionDescription={sectionDescription}
      >
        {activeUsers.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {" "}
                    <Typography className={classes.labelText}></Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>Email</Typography>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeUsers.map((row, index) => {
                  return (
                    <TableRow hover key={row._id} className={classes.row}>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellPhoto}
                        style={{ padding: "5px 10px" }}
                        align="center"
                      >
                        <img
                          src={row.displayPhoto ? row.displayPhoto.url : ""}
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellType}
                        style={{ padding: 5 }}
                        align="left"
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellName}
                        style={{ padding: 5 }}
                        align="left"
                      >
                        {row.lastName}
                      </TableCell>
                      <TableCell
                        onClick={(event) => onPressedItem(row._id)}
                        className={classes.cellType}
                        style={{ padding: 5 }}
                        align="left"
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        className={classes.selectRight}
                        style={{ padding: 5 }}
                        align="right"
                      >
                        {user.roles.indexOf("admin") >= 0 && (
                          <IconButton
                            className={classes.rightRowButton}
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setSelectedIndex(index);
                              setSelectedId(row._id);

                              setEditingItem(row);
                              setSelectedRow(row);
                              setOpen(true);
                            }}
                            aria-owns={open ? "menu-list-grow" : null}
                            aria-haspopup="true"
                          >
                            <MoreVert className={classes.iconRight} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {activeUsers.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any users yet.
          </Typography>
        )}

        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper elevation={0} className={classes.menu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      onClick={() => {
                        onPressedItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      View
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedEditItem(selectedIndex, selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onPressedDeleteItem(selectedId);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Delete
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onResetPassword(selectedRow);
                        setOpen(false);
                      }}
                      className={classes.menuItemTop}
                    >
                      Reset Password
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Panel>
      <hr style={{ margin: 20 }} />

      <div style={{ display: "flex" }}>
        {inactiveUsers.map((item, index) => {
          return (
            <div
              key={`inactive-${index}`}
              style={{
                display: "flex",
                margin: 5,
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                backgroundColor: "#ffffff",
                boxShadow: "0 10px 10px rgba(0,0,0,0.1)",
              }}
              onClick={() => {
                setSelectedId(item._id);
                setEditingItem(item);
                onPressedEditItem(selectedIndex, item._id);
              }}
            >
              <img
                src={item.displayPhoto ? item.displayPhoto.url : ""}
                style={{
                  width: 40,
                  height: 40,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              <p>{`${item.firstName} ${item.lastName}`}</p>
            </div>
          );
        })}
      </div>
    </NavCrumbs>
  );
}
