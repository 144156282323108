import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SingleProposalTimeView from "./single-proposal-time-view";

export default withRouter(function (props) {
  let { proposal, user, findProposalTimings,getConsultants } = props;

  const [timings, setTimings] = useState(null)
  const [consultantMap, setConsultantMap] = useState(null)



  useEffect(() => {
    if (proposal) {
      findProposalTimings(proposal._id).then((result)=>{
        setTimings(result.data)
      });
    }
  }, [proposal]);

  useEffect(() => {
    getConsultants().then((result) => {
      let newConsultantMap = {};
      for (let c of result.data) {
        newConsultantMap[c._id] = c;
      }
      setConsultantMap(newConsultantMap);
    });
  }, []);

  return (
    <div>
      <SingleProposalTimeView
        loading={!proposal}
        user={user}
        proposal={proposal}
        timings={timings}
        consultantMap={consultantMap}
      />
    </div>
  );
});
