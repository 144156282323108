import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./clients-single-clients-nav-crumbs";

// Components
import Details from "./details";
import Projects from "./projects-list";

// import {getClient} from "repository/actions/data/dashboard/clients"

import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider"


export default withRouter(function (props) {
  const clientId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const [client, setClient] = useState(null)

  const { getClient } = useContext(AllClientsContext)


  useEffect(() => {
    if (
      user &&
      user != null &&
      clientId &&
      clientId != null

    ) {
      try {
        getClient(clientId, user.idToken).then((result) => {
          setClient(result);
        });
      } catch (e) {
        alert("Can't get this vacancy.");
      }
    }
  }, []);

 


  return (
    <NavCrumbs client={client}>
      <Details
        client={client}
        setClient={setClient}
        clientId={clientId}
        user={user}
      />
       <Projects
        client={client}
        clientId={clientId}
        user={user}
        setClient={setClient}
      />
    </NavCrumbs>
  );
});
