const assert = require("assert");
const io = require("socket.io-client");
const feathers = require("@feathersjs/client");

const socket = io(process.env.REACT_APP_API_URL);
// let apiClient;

// function initClient(callback) {

//     let APIEndpoint = 'http://localhost:3912';
//     // let APIEndpoint = 'https://admin.ideaapis.live'
//     const socket = io(APIEndpoint);
//     apiClient = feathers();
//     apiClient.configure(feathers.socketio(socket,{
//         timeout: 20000
//     }));
//     apiClient.configure(feathers.authentication());
//     console.log(`API Endpoint : ${APIEndpoint}`);
//     return callback(null, apiClient);
// }

// function getClient() {
//     assert.ok(apiClient, "API has not been initialized. Please called init first.");
//     return apiClient;
// }

// module.exports = {
//     initClient,
//     getClient,
//     apiClient
// };

const apiClient = feathers();

apiClient.configure(
  feathers.socketio(socket, {
    timeout: 200000,
  })
);

console.log(`API Endpoint : ${process.env.REACT_APP_API_URL}`);

export default apiClient;
