import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
} from "@material-ui/core";
import { Edit, Visibility, Delete } from "@material-ui/icons";

//InstantSearch
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectInfiniteHits,
} from "react-instantsearch-dom";

// Navigation
import NavCrumbs from "./administration-all-locations-nav-crumbs";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddLocationForm,
    onPressedItem,
    locations,
    onPressedEditItem,
    onPressedDeleteItem,
    sectionDescription,
    refreshAlgolia
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a location"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          console.log(row)
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellName}
                align="left"
              >
                {row.title}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellName}
                align="left"
              >
                {row.zoomCode?row.zoomCode:""}
              </TableCell>

              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellType}
                align="left"
              >
                {row.address}
              </TableCell>

              <TableCell className={classes.selectRight} align="right">
                <IconButton
                  aria-label="view"
                  color="primary"
                  onClick={(event) => {
                    onPressedItem(row.objectID);
                  }}
                >
                  <Visibility />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={(event) => {
                    onPressedEditItem(index, row.objectID);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={(event) => {
                    onPressedDeleteItem(row.objectID);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectInfiniteHits(Hits);
  return (
    <NavCrumbs>
      <Section
        title="MANAGE YOUR LOCATIONS"
        loading={loading}
        actionName="Add location"
        handleAction={toggleAddLocationForm}
        sectionDescription={sectionDescription}
      >
        {locations.length > 0 && (
          <InstantSearch indexName="Locations" searchClient={searchClient} refresh={refreshAlgolia} >
            <CustomSearchBox />

            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Zoom Code
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Typography className={classes.labelText}>
                        Address
                      </Typography>
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <CustomHits />
              </Table>
            </TableContainer>
          </InstantSearch>
        )}

        {locations.length === 0 && (
          <Typography className={classes.emptyText} align="center">
            You haven't created any contacts yet.
          </Typography>
        )}
      </Section>
    </NavCrumbs>
  );
}
