import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { AllStudentsContext } from "repository/stores/dashboard/students/students-all-provider";

// Actions
// import { deleteStudent } from "repository/actions/data/dashboard/students";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, itemId, user, setRefreshAlgolia } = props;
    const {students, setStudents, deleteStudent} = React.useContext(AllStudentsContext);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteStudent = () => {
      if (user && user != null) {
        setSaveLoading(true);

        deleteStudent( itemId, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            toast.success("Successfully deleted student");
            setClose();
            setSaveLoading(false);
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete selected student"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteStudent()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to delete this student?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
