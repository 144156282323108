

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  leaves: null,
  setLeaves: {},
  createLeave: {},
  getLeave: {},
  findLeavesByUser: {},
  findPendingLeaveRequests: {},
  leaveRequestHandling:{}
};

export const AllLeavesContext = React.createContext(ContextProps);

export const AllLeavesProvider = ({ children }) => {
  const [leaves, setLeaves] = useState(null);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken} = useContext(IdTokenContext)

   const createLeave = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        getIdToken().then(async (token) => {
  
          let result = await apiClient.service("v1/leaves").create(data, {
            query: {
              firebaseToken: token,
            },
          });
  
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  
  const getLeave = async (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
  
          let result = await apiClient.service("v1/leaves").find({
            query: {
              ...query,
              $sort:{
                timestamp: 1
              },
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

   const findLeavesByUser = async (month, userId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {
  
          let result = await apiClient.service("v1/leaves").find({
            query: {
              userId: userId,
              leaveMonth: month,
              firebaseToken: token,
            },
          });
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  
  
   const findPendingLeaveRequests = async (token) => {
    return new Promise(async (resolve, reject) => {
      try {  
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/leaves").find({
            query: {
              status: "pending",
              firebaseToken: token,
            },
          });
  
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };
  
  
   const leaveRequestHandling = async (leaveId, payload, token) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        getIdToken().then(async (token) => {
  
          let result = await apiClient.service("v1/leaves").patch(leaveId, payload, {
            query: {
              firebaseToken: token,
            },
          });
  
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  



  return (
    <AllLeavesContext.Provider
      value={{
        leaves,
        setLeaves,
        getLeave,
        createLeave,
        findLeavesByUser,
        findPendingLeaveRequests,
        leaveRequestHandling
      }}
    >
      {children}
    </AllLeavesContext.Provider>
  );
};
