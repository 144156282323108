import React from 'react';

// Routes
import Routes from './training-session-routes';

export default function (props) {

  return (
   <div>
       <Routes />
   </div>
  )
}
