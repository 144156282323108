import React from 'react';

export const SideNavigationContext = React.createContext();

const initialState = {
    isOpen: false
};

const reducer = (state, action) => {
    switch (action.type) {
      case 'OpenSideNavigation':
        return {
            isOpen: action.payload.isOpen
        };
      default: throw new Error('Unexpected action');
    }
};

export const SideNavigationProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <SideNavigationContext.Provider value={contextValue}>
      {children}
    </SideNavigationContext.Provider>
  );
};