

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  timesheets: null,
  setTimesheets: {},
  createTimestamp: {},
  findTimestampByUser: {},
  findPunchDayByUser: {},
 

};

export const AllTimesheetsContext = React.createContext(ContextProps);

export const AllTimesheetsProvider = ({ children }) => {
  const [timesheets, setTimesheets] = useState(null);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken,refreshToken } = useContext(IdTokenContext)



  const createTimestamp = async (data, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        // force refresh token
        await refreshToken()
  
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/timesheets").create(data, {
            query: {
              firebaseToken: token,
            },
          });
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  

 const findPunchDayByUser = async (userId, punchId) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        // force refresh token
        await refreshToken()
  
        getIdToken().then(async (token) => {
          let result = await apiClient.service("v1/timesheets").find({
            query: {
              userId: userId,
              punchId: punchId,
              firebaseToken: token,
            },
          });
          resolve(result);
  
        })
      } catch (e) {
        console.log(e)
        reject(e);
      }
    });
  };


  const findTimestampByUser = async (month, userId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
  
  
        getIdToken().then(async (token) => {
  
          let result = await apiClient.service("v1/timesheets").find({
            query: {
              userId: userId,
              payMonth: month,
              firebaseToken: token,
            },
          });
          resolve(result);
  
        })
      } catch (e) {
        console.log(e)
        reject(e);
      }
    });
  };
  

  return (
    <AllTimesheetsContext.Provider
      value={{
        timesheets,
        setTimesheets,
        createTimestamp,
        findTimestampByUser,
        findPunchDayByUser,
      }}
    >
      {children}
    </AllTimesheetsContext.Provider>
  );
};
