import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Button,
  IconButton,
  Box,
  Divider,
  Paper,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Delete, SettingsApplications } from "@material-ui/icons";

//InstantSearch
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getSearchClient } from "utils/algolia";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  RefinementList,
  MenuSelect
} from "react-instantsearch-dom";

import { AllCandidatesContext } from "repository/stores/dashboard/candidates/candidates-all-provider";

//Actions
// import { editCandidate } from "repository/actions/data/dashboard/candidates";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    setApplications,
    vacancy,
    vacancyId,
    status,
    columnTitle,
    setHeadCounts,
    onPressedDeleteItem,
    setShowAssignCandidateDialogue,
    user,
    refreshAlgolia,
    setRefreshAlgolia
  } = props;

  const { editCandidate } = useContext(AllCandidatesContext);


  const handleStatus = (candidate, change) => {

    let displacement = change ? 1 : -1

    let status = ["interested", "contacted", "interviewed", "refered", "hired"]

    let applicationIdx = candidate.applications.findIndex((element) => element.vacancyId === vacancyId);

    let currentStatusId = status.indexOf(candidate.applications[applicationIdx].status)

    let newApplication = candidate.applications

    newApplication[applicationIdx].status = status[currentStatusId + displacement]

    newApplication[applicationIdx].index = vacancyId + "-" + status[currentStatusId + displacement]


    let payload = {
      applications: newApplication
    }

    editCandidate(candidate.objectID, payload, user.idToken).then((result) => {

      // setTimeout(() => { setRefreshAlgolia(true) }, 1000)
      // setTimeout(() => { setRefreshAlgolia(false) }, 1000)

      window.location.href = `/vacancies/${vacancyId}`
    })

  }


  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <li
                key={index}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a Candidate"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, refineNext }) => {
    if (status == "hired") {
      setHeadCounts(hits.length)
    }
    return (
      <>
        {hits.map((row, index) => {
          var day1 = new Date(row.createdAt);
          var day2 = new Date();
          var diffDays = day2.getDate() - day1.getDate()

          return (
            <Paper className={classes.candidateSection}>
              <Grid container className={classes.gridContainer} style={{ alignItems: "center", justifyContent: "space-between" }}>
                <Grid item xs={9}>
                  <Link onClick={() => { window.location.href = `/candidates/${row.objectID}` }}>
                    <Typography className={classes.candidateName}>
                      {row.firstName}{" "}{row.lastName}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={3} style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                  <Box
                    style={{ alignItems: "end", justifyContent: "end" }}

                  >
                    <AccessTimeIcon style={{ fontSize: "small", alignItems: "center" }} /> {diffDays}{" "}d
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={(event) => {
                        onPressedDeleteItem(row.objectID);
                        setApplications(row.applications)
                      }}
                    >
                      <Delete />
                    </IconButton>

                  </Box>
                </Grid>
              </Grid>
              <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Grid item xs={5}>
                  <Typography className={classes.candidateEmail}>
                    {row.contactNumber}
                  </Typography>
                  <Typography className={classes.candidateEmail}>
                    {row.email}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "end" }}>
                  <IconButton onClick={() => { handleStatus(row, false) }} disabled={status == "interested"}>
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton onClick={() => { handleStatus(row, true) }} disabled={status == "hired"}>
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </>
    );
  };

  const CustomHits = connectHits(Hits);



  return (
    <Paper
      className={classes.candidateStatusSection}
    >
      {status == "interested" && <Grid container>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.candidateStatusTitle}>
            SHOWED INTEREST
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => { setShowAssignCandidateDialogue(true) }}
            className={classes.actionButton}
          >
            New Candidate
          </Button>
        </Grid>
      </Grid>}
      {status != "interested" && <Box className={classes.spacing}>
        <Typography className={classes.candidateStatusTitle}>
          {columnTitle}
        </Typography>
        <div></div>
      </Box>}
      {vacancy && (
        <InstantSearch indexName="Candidates" searchClient={searchClient} refresh={refreshAlgolia}>

          <CustomSearchBox />

          <RefinementList
            attribute="applications.index"
            operator="and"
            defaultRefinement={`${vacancyId}-${status}`}
          />

          <CustomHits />
          <CustomPagination />
        </InstantSearch>
      )}
      {vacancy == null && (
        <Typography className={classes.emptyText} align="center">
          No candidates applied yet.
        </Typography>
      )}
    </Paper>
  );
}
