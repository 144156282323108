import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import All from './routes/applicants';


export default function (props) {
  const {campus} = props

    return(
        <div>
            <Switch>
                <Route path='/applicants/all' component={() => <All />} />
                {/* <Route path='/general/tickets' component={() => <Tickets />} />
                <Route path='/general/referrals' component={() => <Referrals />} /> */}
                <Route exact path='/applicants' component={() => <Redirect to={`/applicants/all`} />} />
            </Switch>
        </div>
    )
}