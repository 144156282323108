import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { Visibility, Delete, Edit } from "@material-ui/icons";

//Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const {
    loading,
    toggleAddPaymentPlanForm,
    paymentPlans,
    sectionDescription,
    student,
    onPressedItem,
    onPressedEditItem,
    onPressedDeleteItem,
    onPressedViewItem,
  } = props;

  return (
    <Section
      title="Associated Payment Plans"
      loading={loading}
      actionName="Add Payment Plan"
      handleAction={toggleAddPaymentPlanForm}
      sectionDescription={sectionDescription}
    >
      {paymentPlans.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.cellType}>
                  <Typography className={classes.labelText}>Cohort</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cellType}>
                  <Typography className={classes.labelText}>
                    Created At
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.labelText}>Total</Typography>
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentPlans.map((row, index) => {
                return (
                  <TableRow hover key={row._id} className={classes.row}>
                    <TableCell className={classes.cellName} align="left">
                      {row.cohort.title}
                    </TableCell>
                    <TableCell className={classes.cellType} align="left">
                      <Typography className={classes.text}>
                        <Moment format="D MMMM, YYYY">{row.createdAt}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cellName} align="left">
                      €{row.paymentSum}
                    </TableCell>
                    <TableCell className={classes.selectRight} align="right">
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={(event) => {
                          onPressedViewItem(index, row.objectID);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={(event) => {
                          onPressedEditItem(index, row.objectID);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={(event) => {
                          onPressedDeleteItem(index, row.objectID);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {paymentPlans.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          There are no associated payment plans with this student.
        </Typography>
      )}
    </Section>
  );
}
