import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SubjectIcon from "@material-ui/icons/Subject";
import styles from "./dialogues-create-lecturer-contract-styles";
import {
  Grid,
  TextField,
  FormControl,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { withSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Popup from "components/popup";
import Moment from "react-moment";
//Actions
import { createPersonnelLecturerContract } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function AddLecturerContractDialoague(props) {
  const classes = useStyles();

  const {
    setClose,
    isOpen,
    person,
    contracts,
    setContracts,
    role,
    cohort,
    student,
    roleAssociation,
    personnelId,
    lectureSessions,
    user,
  } = props;

  const [title, setTitle] = React.useState(null);
  const [expiryDate, setExpiryDate] = React.useState(null);
  const [hourlyRate, setHourlyRate] = React.useState(null);
  const [totalLectureSessionHours, setTotalLectureSessionHours] =
    React.useState(null);
  const [contactHours, setContactHours] = React.useState(0);

  const [assessmentBriefSubmissionDate, setAssessmentBriefSubmissionDate] =
    React.useState(null);
  const [
    assessmentBriefSubmissionWeeksBefore,
    setAssessmentBriefSubmissionWeeksBefore,
  ] = React.useState(null);
  const [
    assessmentResultsSubmissionWeeksBefore,
    setAssessmentResultsSubmissionWeeksBefore,
  ] = React.useState(null);

  const [totalBillableHours, setTotalBillableHours] = React.useState(null);
  const [totalRemuneration, setTotalRemuneration] = React.useState(null);
  const [
    openAssessmentBriefSubmissionDate,
    setOpenAssessmentBriefSubmissionDate,
  ] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [expiryDateError, setExpiryDateError] = React.useState(null);
  const [totalLectureSessionHoursError, setTotalLectureSessionHoursError] =
    React.useState(null);
  const [hourlyRateError, setHourlyRateError] = React.useState(null);
  const [contactHoursError, setContactHoursError] = React.useState(null);
  const [
    assessmentBriefSubmissionDateError,
    setAssessmentBriefSubmissionDateError,
  ] = React.useState(null);
  const [
    assessmentBriefSubmissionWeeksBeforeError,
    setAssessmentBriefSubmissionWeeksBeforeError,
  ] = React.useState(null);
  const [
    assessmentResultsSubmissionWeeksBeforeError,
    setAssessmentResultsSubmissionWeeksBeforeError,
  ] = React.useState(null);
  const [totalRemunerationError, seTotalRemunerationError] =
    React.useState(null);
  const [selectedLectureSessions, setSelectedLectureSessions] =
    React.useState(null);

  const handleSaveLecturerContract = () => {
    if (user && user != null) {
      if (hourlyRate == null) {
        toast.error(
          "You have missing information for creating a lecturer contract"
        );
        if (hourlyRate == null) {
          setHourlyRateError("Hourly rate is required.");
        }
      } else {
        let data = {
          // title: title,
          // expiryDate: expiryDate,
          personnelId: personnelId,
          roleId: role._id,
          cohortId: cohort._id,
          hourlyRate: hourlyRate,
          totalLectureSessionHours: totalLectureSessionHours,
          contactHours: contactHours,
          assessmentBriefSubmissionDate: assessmentBriefSubmissionDate,
          assessmentBriefSubmissionWeeksBefore:
            assessmentBriefSubmissionWeeksBefore,
          assessmentResultsSubmissionWeeksBefore:
            assessmentResultsSubmissionWeeksBefore,
          totalRemuneration: totalRemuneration,
          totalBillableHours: totalBillableHours,
          roleAssociationId: roleAssociation._id,
          startDate: roleAssociation.startsAt,
          expiryDate: roleAssociation.expiresAt,
        };

        setSaveLoading(true);
        createPersonnelLecturerContract(data, user.idToken)
          .then((result) => {
            let currentContracts = [...contracts];
            currentContracts.push(result);
            setContracts(currentContracts);
            setClose();
            setSaveLoading(false);
            toast.success(`Lecturer contract successfully created`);
            setTitle(null);
            setExpiryDate(null);
            setHourlyRate(null);
            setTitleError(null);
            setExpiryDateError(null);
            setHourlyRateError(null);
            setTotalLectureSessionHoursError(null);
            setAssessmentBriefSubmissionDateError(null);
            setAssessmentBriefSubmissionWeeksBeforeError(null);
            setAssessmentResultsSubmissionWeeksBeforeError(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setTitleError(null);
    setExpiryDateError(null);
    setHourlyRateError(null);
    setHourlyRateError(null);
    setContactHoursError(null);
    setTotalLectureSessionHoursError(null);
    setAssessmentBriefSubmissionDateError(null);
    setAssessmentBriefSubmissionWeeksBeforeError(null);
    setAssessmentResultsSubmissionWeeksBeforeError(null);
  }, [isOpen]);

  useEffect(() => {
    if (totalLectureSessionHours >= 0 && contactHours >= 0 && hourlyRate >= 0) {
      setTotalBillableHours(contactHours + totalLectureSessionHours);
      setTotalRemuneration(
        (contactHours + totalLectureSessionHours) * hourlyRate
      );
    }
  }, [totalLectureSessionHours, contactHours, hourlyRate]);

  useEffect(() => {
    let total = 0;
    for (let ls of lectureSessions) {
      total += ls.durationHours;
    }
    setTotalLectureSessionHours(parseFloat(total));
  }, [lectureSessions]);

  return (
    <Popup
      title="Add a new lecturer contract"
      subtitle="Please fill in the details to create a new contract"
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"md"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveLecturerContract()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper className={classes.fullColumn}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={true}
                      required
                      helperText={totalLectureSessionHoursError}
                      error={totalLectureSessionHoursError != null}
                      label="Total Lecture Session Hours"
                      value={totalLectureSessionHours}
                      margin="normal"
                      type="number"
                    ></TextField>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={saveLoading}
                      required
                      helperText={hourlyRateError}
                      error={hourlyRateError != null}
                      label="Hourly Rate (€)"
                      value={hourlyRate}
                      margin="normal"
                      type="number"
                      placeholder={"Ex: 50"}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      onChange={(event) =>
                        setHourlyRate(parseFloat(event.target.value))
                      }
                    ></TextField>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={saveLoading}
                      required
                      helperText={contactHoursError}
                      error={contactHoursError != null}
                      label="Contact Hours"
                      value={contactHours}
                      margin="normal"
                      placeholder={"Ex: 3"}
                      InputProps={{ inputProps: { min: 0, max: 30 } }}
                      type="number"
                      onChange={(event) =>
                        setContactHours(parseFloat(event.target.value))
                      }
                    ></TextField>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={true}
                      required
                      helperText={totalRemunerationError}
                      error={totalRemunerationError != null}
                      label="Total Remuneration"
                      value={totalRemuneration}
                      margin="normal"
                      type="number"
                    ></TextField>
                  </FormControl>
                  <br />
                  <Typography variant="subtitle2">
                    Assessment Brief Submission
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={saveLoading}
                      required
                      helperText={assessmentBriefSubmissionWeeksBeforeError}
                      error={assessmentBriefSubmissionWeeksBeforeError != null}
                      label="Minimum Weeks before Brief Submission Date"
                      value={assessmentBriefSubmissionWeeksBefore}
                      margin="normal"
                      type="number"
                      placeholder={"Ex: 4"}
                      InputProps={{ inputProps: { min: 0, max: 20 } }}
                      onChange={(event) =>
                        setAssessmentBriefSubmissionWeeksBefore(
                          parseFloat(event.target.value)
                        )
                      }
                    ></TextField>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      required
                      format="dd/MM/yyyy"
                      openTo="year"
                      views={["year", "month", "date"]}
                      margin="normal"
                      id="date-picker-inline"
                      label="Assessment Brief Submission Date"
                      value={assessmentBriefSubmissionDate}
                      helperText={assessmentBriefSubmissionDateError}
                      error={assessmentBriefSubmissionDateError != null}
                      onChange={(date) => {
                        setAssessmentBriefSubmissionDate(date);
                      }}
                      onClick={() => setOpenAssessmentBriefSubmissionDate(true)}
                      onError={(error) =>
                        setAssessmentBriefSubmissionDateError(error.message)
                      }
                      onOpen={() => setOpenAssessmentBriefSubmissionDate(true)}
                      onClose={() =>
                        setOpenAssessmentBriefSubmissionDate(false)
                      }
                      open={openAssessmentBriefSubmissionDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </FormControl>
                  <br />
                  <Typography variant="subtitle2">
                    Assessment Result Submission
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled={saveLoading}
                      required
                      helperText={assessmentResultsSubmissionWeeksBeforeError}
                      error={
                        assessmentResultsSubmissionWeeksBeforeError != null
                      }
                      label="Minimum Weeks before Results Submission"
                      value={assessmentResultsSubmissionWeeksBefore}
                      margin="normal"
                      type="number"
                      placeholder={"Ex: 4"}
                      InputProps={{ inputProps: { min: 0, max: 20 } }}
                      onChange={(event) =>
                        setAssessmentResultsSubmissionWeeksBefore(
                          parseFloat(event.target.value)
                        )
                      }
                    ></TextField>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.fullColumn}>
                  <List className={classes.root}>
                    {lectureSessions.map((entry, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar>
                              <SubjectIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span>
                                {entry.title}({entry.durationHours} Hours)
                              </span>
                            }
                            secondary={
                              <span>
                                <Moment
                                  format="D MMMM, YYYY"
                                  date={entry.lectureDate}
                                />
                                {"  "}
                                <Moment
                                  format="HH:mm"
                                  date={entry.lectureStartTime}
                                />
                                -
                                <Moment
                                  format="HH:mm"
                                  date={entry.lectureEndTime}
                                />
                              </span>
                            }
                          ></ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(AddLecturerContractDialoague);
