import { CenterFocusWeakOutlined } from "@material-ui/icons";

const styles = theme => ({
    smallCard: {
        minWidth: 120,
        borderRadius: 10,
        // padding: 5,
        boxShadow: '0 10px 70px -20px rgba(0,0,0,0.15)',
        // marginBottom: 15,
        height:"100%",
        // textAlign: "center",

    },
    title: {
        fontFamily: 'proximaNova-bold',
        color: theme.palette.primary.light,
        textAlign: "center",
        fontSize: 18,
    },
    titleLeft: {
        fontFamily: 'proximaNova-bold',
        color: theme.palette.primary.light,
        fontSize: 18,
    },
    description: {
        fontFamily: 'proximaNova',
        color: "#808080",
        textAlign: "center",
        fontSize: 12,
    },

    button: {
        marginLeft: 'auto',
        boxShadow: 'none',
        marginRight: 5,
        marginTop: 3,
        paddingTop: 8,
        fontSize: 12,
        width: 95,
        paddingLeft: 0,
        paddingRight: 0,
        fontFamily: 'proximaNova-bold',
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: '#0062cc',
            boxShadow: 'none',
        }
    },
   
  });
  
  export default styles;
  