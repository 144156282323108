const styles = theme => ({
  formControl: {
    minWidth: '100%',
    marginRight:25,
    marginTop: 0
  },
  formControlPrice: {
    marginTop: -6,
    minWidth: '100%'
  },
  inputType: {
    marginTop:0,
    minWidth: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  spacing: {
    marginTop: 10
  },
  divider: {
    marginLeft: 24,
    marginRight:24,
    marginBottom: 10,
    width: 15,
    height: 1.5,
    backgroundColor: theme.palette.secondary.main
  },
  picker: {
    margin: theme.spacing(1),
    minWidth: 170,
    maxWidth: 190
  },
  secondHours: {
    display: 'none'
  },
  icon: {
    marginTop: 18,
    marginLeft: 2
  },
  bottomButtons: {
    marginTop: 30
  },
  addPhotoButton: {
    marginTop: 35,
    border: '1px dashed',
    width: '100%',
    height: 125,
    borderRadius: 20
  },
  dialog: {
    padding: 40
  },
  submitButton: {
    marginBottom: 15,
    marginRight: 15,
    borderRadius: 18,
    boxShadow: 'none',
    fontSize: 12
  },
  cancelButton: {
    marginBottom: 15,
    marginLeft: -15,
    borderRadius: 18,
    boxShadow: 'none',
    fontSize: 12
  }
});
  
export default styles;
  