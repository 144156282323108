import apiClient from 'utils/api';
export const getSignedUrlFromAbsoluteUrl = (absoluteUrl, firebaseToken) => {
  return new Promise(async (resolve, reject) => {
    try{

      //https://storage.googleapis.com/ia-st-1/letters/6373276540-1606485202.pdf
      absoluteUrl = absoluteUrl.replace('https://storage.googleapis.com/','');
      let bucketname = absoluteUrl.substring(0,absoluteUrl.indexOf('/'));
      let filename = absoluteUrl.substring(absoluteUrl.indexOf('/')+1,absoluteUrl.length);

      let signedUrl = apiClient.service('v1/signedurls').create({
        filename : filename,
        bucketname : bucketname
      },{
        query:{
          firebaseToken : firebaseToken
        }
      });

      resolve(signedUrl);
      
    }catch(e){
      reject(e);
    }
  });
}
