import React from 'react';

export const DashboardDialoguesContext = React.createContext();

const initialState = {
    "projectPublishFormIsOpen": false,
    "projectUnpublishFormIsOpen": false,
    'projectWelcomeDialogueIsOpen': true
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'ProjectPublishFormDisplay':
            return {
                ...state,
                projectPublishFormIsOpen: action.payload.isOpen
            };

        case 'ProjectUnpublishFormDisplay':
            return {
                ...state,
                projectUnpublishFormIsOpen: action.payload.isOpen
            };

        case 'ProjectWelcomeFormDisplay':
          return {
              ...state,
              projectWelcomeDialogueIsOpen: action.payload.isOpen
          };

            
      default:
        return state;
    }
};

export const DashboardDialoguesProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <DashboardDialoguesContext.Provider value={contextValue}>
      {children}
    </DashboardDialoguesContext.Provider>
  );
};