import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Section from "components/section";
import Panel from "components/panel";
import { getSearchClient } from "utils/algolia";

import {
  Grid,
  Typography,
  Paper,
  Select,
  Button,
  InputLabel,
  Input,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
import { toast } from "react-toastify";
const useStyles = makeStyles(styles);
const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();
  const { applicant, handleAction, deleteApplicant } = props;

  const [cohort, setCohort] = React.useState("");

  useEffect(() => {
    if (applicant != null) {
      searchClient
        .initIndex("Cohorts")
        .search(applicant.cohortId, { hitsPerPage: 1 })
        .then(({ hits }) => {
          if(hits.length>0){
          setCohort(hits[0].title);}
        });
    }
  }, [applicant]);

  const handleConverToStudents = () => {
    deleteApplicant(applicant._id)
      .then(() => {
        window.location.href = "/applicants/all";
      })
      .catch((e) => {
        toast.error("error");
      });
  };

  return (
    <Section
      actionName="Edit"
      handleAction={handleAction}
      title={applicant ? `${applicant.firstName} ${applicant.lastName}` : ""}
      loading={!applicant}
    >
      {applicant && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Student Number
              </Typography>
              <Typography className={classes.fontRegular}>
                {applicant.studentNumber}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Name</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.firstName} {applicant.lastName}
              </Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Cohort</Typography>
              <Typography className={classes.fontRegular}>{cohort}</Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>ID No.</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.identificationNumber}
              </Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Email</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.email}
              </Typography>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Gender</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.gender}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Status</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.isInternational ? "(International)" : "(Local)"}{" "}
                {" - "} {applicant.status}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Date Of Birth
              </Typography>
              <Typography className={classes.fontRegular}>
                {applicant.dob
                  ? moment(applicant.dob).format("DD MMM YYYY")
                  : ""}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>Address</Typography>
              <Typography className={classes.fontRegular}>
                {applicant.addressHouse}
                {", "}
                {applicant.addressStreet}
                {", "}
                {applicant.addressCity} {applicant.addressPostcode}
                {", "}
                {applicant.addressCountry}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Applicant Date
              </Typography>
              <Typography className={classes.fontRegular}>
                {applicant.appliationDate}
              </Typography>
            </Paper>

            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Travelling To Malta Date
              </Typography>
              <Typography className={classes.fontRegular}>
                {applicant.travellingToMaltaDate}
              </Typography>
            </Paper>
            {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button  className={classes.backupBtn} onClick={() => handleConverToStudents()}>
                Convert to Student
              </Button>
            </div> */}
          </Grid>
        </Grid>
      )}
    </Section>
  );
}
