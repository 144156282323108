import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { cohort } = props;

  let crumbs = [
        {name: 'Cohorts', path: 'cohorts'},
        {name: 'All Cohorts', path: 'cohorts/all'},
        {name: '...', path: '#'},
  ];

  if (cohort != null) {
    crumbs[2] =  { name: `${cohort.title}`, path: `cohorts/${cohort._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
