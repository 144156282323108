

import React, { useContext, useState } from "react";
import apiClient from "utils/api";
import { AuthenticationContext } from "../../global/authentication-provider"
import { IdTokenContext } from "repository/stores/global/token-provider"

const ContextProps = {
  vacancies: null,
  setVacancies: {},
  getVacancies: {},
  getVacancy: {},
  loadingVacancies: false,
  deleteVacancy: {},
  createNewVacancy: {},
  findVacanciesByDateRange: {},
  findFilledVacanciesByDateRange: {},
  editVacancy: {}

};

export const AllVacanciesContext = React.createContext(ContextProps);

export const AllVacanciesProvider = ({ children }) => {
  const [vacancies, setVacancies] = useState(null);
  const [loadingVacancies, setLoadingVacancies] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { getIdToken } = useContext(IdTokenContext)



  const getVacancies = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingVacancies(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingVacancies(false);
          setVacancies(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const getVacancy = (vacancyId, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingVacancies(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").get(vacancyId, {
            query: {
              firebaseToken: token,
            },
          });

          setLoadingVacancies(false);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const findVacanciesByDateRange = (startDate, endDate, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingVacancies(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").find({
            query: {
              firebaseToken: token,
              createdAt: {
                $gte: startDate,
                $lte: endDate
              }
            },
          });

          setLoadingVacancies(false);
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const findFilledVacanciesByDateRange = (startDate, endDate, token) => {
    return new Promise(async (resolve, reject) => {
      try {

        setLoadingVacancies(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").find({
            query: {
              firebaseToken: token,
              filledAt: {
                $gte: startDate,
                $lte: endDate
              }
            },
          });

          setLoadingVacancies(false);
          resolve(result.data);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const createNewVacancy = async (vacancy, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").create(
            { ...vacancy },
            {
              query: {
                firebaseToken: token,
              },
            }
          );

          // if (vacancies == null) {
          //   setVacancies(result);
          // } else {
          //   let newVacancies = vacancies
          //   newVacancies.push(result)
          //   setVacancies(newVacancies);
          // }
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const editVacancy = async (vacancyId, data, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let updatedCohort = await apiClient
            .service("v1/vacancies")
            .patch(vacancyId, data, {
              query: {
                firebaseToken: token,
              },
            });

          // let newData = vacancies
          // for (const [i, v] of newData.entries()) {
          //   if (v._id === vacancyId) {
          //     newData[i] = data;
          //   }
          // }
          // setVacancies(newData);

          resolve(updatedCohort);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteVacancy = async (vacancyId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/vacancies").remove(vacancyId, {
            query: {
              firebaseToken: token,
            },
          });

          // let newData = vacancies
          // for (const [i, v] of newData.entries()) {
          //   if (v._id === vacancyId) {
          //     newData.splice(i, 1);
          //   }
          // }
          // setVacancies(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <AllVacanciesContext.Provider
      value={{
        vacancies,
        setVacancies,
        createNewVacancy,
        getVacancies,
        getVacancy,
        editVacancy,
        deleteVacancy,
        findVacanciesByDateRange,
        findFilledVacanciesByDateRange,
        loadingVacancies
      }}
    >
      {children}
    </AllVacanciesContext.Provider>
  );
};
