import React, { useContext, useState, useEffect } from "react";
import { TrainingProposalsContext } from "repository/stores/dashboard/training/training-proposals-provider";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Children
import AllProposalsView from "./training-all-proposals-view";

// Dialogues
import DialogueCreateProposal from "routes/dashboard/dialogues/training/proposals/create-proposal";
import DialogueEditProposal from "routes/dashboard/dialogues/training/proposals/edit-proposal";
import DialogueDeleteProposal from "routes/dashboard/dialogues/training/proposals/delete-proposal";

// Actions
import {
  // findAllProposals,
  getSingleProposal,
} from "repository/actions/data/dashboard/proposals";

export default withRouter(function (props) {
  const {proposals, setProposals, findAllProposals, createNewProposal, editProposal, deleteProposal} = useContext(TrainingProposalsContext);

  const { user } = useContext(AuthenticationContext);
  const [showNewProposalDialogue, setShowNewProposalDialogue] = useState(false);
  const [showEditProposalDialogue, setShowEditProposalDialogue] =
    useState(false);
  const [showDeleteProposalDialogue, setShowDeleteProposalDialogue] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      if (proposals == null) {
        findAllProposals(user.idToken);
      }
    }
  }, [proposals]);

  // Editing Item
  useEffect(() => {
    if (user && user != null) {
      if (proposals) {
        if (selectedId && editingItem == null) {
          getSingleProposal(selectedId, user.idToken).then((result) => {
            setEditingItem(result);
          });
        }
      }
    }
  }, [selectedId]);

  return (
    <div>
      <AllProposalsView
        loading={!proposals}
        onPressedItem={(id) => props.history.push(`proposals/${id}`)}
        toggleAddLocationForm={() => setShowNewProposalDialogue(true)}
        sectionDescription={"You can manage all of your proposals below."}
        proposals={proposals || []}
        onPressedEditItem={(index, id) => {
          setSelectedIndex(index);
          setSelectedId(id);
          setShowEditProposalDialogue(true);
        }}
        onPressedDeleteItem={(index) => {
          setSelectedId(index);
          setShowDeleteProposalDialogue(true);
        }}
        user={user}
      />
      <DialogueCreateProposal
        isOpen={showNewProposalDialogue}
        setClose={() => setShowNewProposalDialogue(false)}
        user={user}
        createNewProposal={createNewProposal}
      />
      <DialogueEditProposal
        isOpen={showEditProposalDialogue && editingItem && editingItem != null}
        setClose={() => {
          setShowEditProposalDialogue(false);
          setSelectedIndex(null);
          setSelectedId(null);
          setEditingItem(null);
        }}
        data={editingItem}
        itemId={selectedId}
        user={user}
        editProposal={editProposal}
      />
      <DialogueDeleteProposal
        isOpen={showDeleteProposalDialogue}
        setClose={() => setShowDeleteProposalDialogue(false)}
        itemId={selectedId}
        user={user}
        deleteProposal={deleteProposal}
      />
    </div>
  );
});
