const styles = theme => ({
  root: {
    paddingTop: 74,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 80,
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 70,
      paddingLeft: 200,
      paddingRight: 20
    }
  },
  '@global': {
    '*::-webkit-scrollbar': {
      height: "5px",
      width: "5px"

    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#564181',
      width: "5px",
      borderRadius: "20px"
    }
  },



  // Global Styles


  // Text & Fonts
  fontBold: {
    position: 'absolute',
    fontSize: 16,
    fontFamily: 'proximaNova-bold'
  },
  fontRegular: {
    marginLeft: 202,
    marginTop: 2,
    marginBottom: 5,
    fontSize: 15,
    color: 'rgba(0,0,0,0.5)'
  },

  emptyText: {
    marginTop: 40,
    fontSize: 14,
    fontType: 'proximaNova-regular'
  },
  labelText: {
    fontFamily: 'proximaNova-bold',
    fontSize: 14
  },

  fontRegularDescription: {
    marginLeft: 10,
    marginBottom: 5,
    fontSize: 16,
    color: 'rgba(0,0,0,0.7)'
  },

  title: {
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 18,
    marginTop: -5,
    marginRight: 15
  },



  // Tables 


  cellType: {
    width: 150,
  },

  cellStandard: {
    width: 150
  },
  cellWide: {
    width: 200
  },
  cellPhoto: {
    width: 40
  },
  cellTitle: {
    width: 30
  },
  hiddenRefinementList: {
    display: 'none'
  },
  paginationList: {
    textAlign: 'center',
  },
  paginationListItem: {
    display: 'inline',
    marginRight: '1rem',
    '&& g': {
      stroke: theme.palette.primary.light
    }
  },
  paginationListItemActive: {
    display: 'inline',
    fontWeight: 'bold',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.light,
    marginRight: '1rem'
  },
  searchBar: {
    border: "none",
    boxShadow: "none",
    marginBottom: 20,
    fontSize: "1rem",
  },
  row: {
    cursor: 'pointer'
  },
  selectRight: {
    width: 200,
    padding: "10px 10px"
  },

  stat: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textAlign: 'right',
    color: theme.palette.primary.light,
    // textTransform : 'uppercase',
    marginRight: 20
  },
  statSmall: {
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'right',
    color: theme.palette.primary.light,
    // textTransform : 'uppercase',
    marginRight: 20
  },
  table: {
    marginTop: 0,
    paddingLeft: 20
  },
  tablePaper: {
    paddingLeft: 1
  },

  menuList: {
    borderRadius: 20,
  },
  menuItemTop: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  menuItemBottom: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  menu: {
    borderRadius: 15,
    marginTop: 15,
    borderStyle: 'solid',
    borderColor: 'rgba(230,230,230,0.7)',
    borderWidth: 1,
  },

  // miniSection: {
  //   paddingLeft: 0,
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   marginBottom: 18,
  //   boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
  // },
  miniSection: {
    minHeight: 65,
    paddingLeft: 32,
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 18,
    boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
  },


  spacing: {
    marginTop: 10,
    marginBottom: 5
  },


  gridContainer: {
    marginBottom: 50,
  },

  // Resourcing

  candidateName: {
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 20,
    marginTop: -5,
    marginRight: 15
  },
  candidateEmail: {
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 17,
    marginTop: -5,
    marginRight: 15
  },

  candidateStatusContainer: {
    overflowX: "scroll",
    width: "2700px",
    justifyContent: "start",
    padding: 0
  },
  candidateStatusItem: {
    width: "450px",
    marginRight: 20,
    display: "inline-block"
  },
  candidateSection: {
    padding: 22,
    marginBottom: 22,
    marginTop: 22,
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgb(130 130 130 / 40%)',
    // transition: "all 0.7s",
    "&:hover": {
      boxShadow: '0 20px 40px rgb(0 0 0 / 8%);',
    }
  },
  switch: {
    marginLeft: 202,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 15,
    color: 'rgba(0,0,0,0.5)',
    "&.Mui-checked": {
      color: "#95cc97!important",
      backgroundColor: "#95cc97!important",
    },
    '&$checked': {
      color: "#00FF00!important",
      backgroundColor: "#95cc97!important",

    },

    '&$checked + $track': {
      backgroundColor: "#00FF00!important",
    },
  },

  noteArea: {
    backgroundColor: "#f7f7f7",
    padding: 15,
    borderRadius: 10
  },
  noteContent: {
    height: "100%",
    backgroundColor: "#f7f7f7",
    padding: 15,
    borderRadius: 10
  },
  inputType: {
    padding: 15,
    minWidth: '100%',
    marginTop: 16,
    marginBottom: 0,
  },
  formControlNotes: {
    minWidth: '95%',
    marginRight: 25,
    marginTop: 0
  },
  fontBoldNote: {
    fontSize: 16,
    marginBottom: 15,
    fontFamily: 'proximaNova-bold'
  },
  fontRegularNote: {
    marginTop: 2,
    marginBottom: 5,
    fontSize: 15,
    color: 'rgba(0,0,0,0.5)'
  },

  candidateStatusSection: {
    padding: 18,
    marginBottom: 18,
    minHeight: 500,
    height: "100%",
    boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
  },
  candidateStatusContainer: {
    overflowX: "scroll",
    width: "2700px",
    justifyContent: "start",
    padding: 0
  },
  candidateStatusItem: {
    width: "400px",
    marginRight: 10,
    display: "inline-block"
  },

  candidateStatusTitle: {
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 18,
    marginTop: -5,
    marginRight: 15,
    marginBottom: 15

  },
  actionButton: {
    marginLeft: 'auto',
    boxShadow: 'none',
    marginRight: 5,
    marginTop: 3,
    marginBottom: 15,
    paddingTop: 8,
    fontSize: 12,
    width: 95,
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'proximaNova-bold',
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    textTransform: 'none',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
      borderColor: '#0062cc',
      boxShadow: 'none',
    }
  },


  // Advisory


  proposalPaper: {
    // width: "80%",
    height: "100vw",
    backgroundColor: "white",
    padding: 30,
    boxShadow: "0 20px 70px -10px rgba(0,0,0,0.2)"
  },

 
  proposalTitle:{
    width: "80%",
    marginRight: 5
  },
  
  proposalTextarea:{
    width: "100%",
    marginRight: 5,
    marginBottom: 20
  },
  proposalSection:{
    marginTop: 20,
    marginBottom: 20
  },
  proposalSubheading:{
    width: "55%",
    marginRight: 5,
    marginBottom: 20
  },
  proposalSubheadingText:{
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 22,
    marginTop: -5,
    marginBottom: 15
  },

  iconButton:{
    marginTop: 25, 
    backgroundColor:"#564181", 
    color:"white", 
    boxShadow:"0 10px 30px -5px #56418190", 
    width: 40, 
    height: 40,
    transition: "all 0.5s",
    '&:hover': {
      backgroundColor:"#56418190", 
      color:"white",
      boxShadow:"0 10px 20px 5px #56418133", 
    }
  },
  projectBtn:{
    marginTop: 15, 
    width: 90, 
    backgroundColor: theme.palette.tertiary.main, 
    color:"white", 
    boxShadow:"0 10px 20px -15px "+ theme.palette.tertiary.main, 
    transition: "all 0.3s",
    '&:hover': {
      backgroundColor: "rgba(243, 185, 3, 0.7)", 
      color:"white",
    },
    '&:disabled': {
      backgroundColor: "rgba(243, 185, 3, 0.2)!important",
      boxShadow:"0 00px 0px 0px "+ theme.palette.tertiary.main, 

    }
  },

  projectTitle:{
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontWeight:900,
    fontSize: 20,
    width: "60%",
    marginTop: 10
  },

  // Academy
  fontRegularDescription: {
    marginLeft: 10,
    marginBottom: 5,
    fontSize: 16,
    color: 'rgba(0,0,0,0.7)'
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  backupBtn: {
    boxShadow: 'none',
    marginRight: 5,
    marginTop: 3,
    marginBottom: 5,
    paddingTop: 8,
    fontSize: 15,
    width: 200,
    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: 'proximaNova-bold',
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    textTransform: 'none',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
      borderColor: '#0062cc',
      boxShadow: 'none',
    }
  },
  spacing: {
    marginRight: 5,
    marginLeft: 5,

  },
  header: {
    marginBottom: 36,
    padding: 24
  },
  filterHeading: {
    fontWeight: 900,
    letterSpacing: '0.06rem'
  },
  selectContainer: {
    fontFamily: 'proximaNova-regular',
    zIndex: 12
  },
  menuList: {
    borderRadius: 20,
  },
  menuItemTop: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  menuItemBottom: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  menu: {
    borderRadius: 15,
    marginTop: 15,
    borderStyle: 'solid',
    borderColor: 'rgba(230,230,230,0.7)',
    borderWidth: 1,
  },




  // Timesheets

  avatarList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    overflowY: "scroll",

  },
  singleAvatar: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: "center",
    maxWidth: "200px",
    marginBottom: "50px",
    transition: "all ease-in-out 2s",
    position: "relative"
  },
  avatar: {
    width: 90, height: 90,
    margin: "0 0 10px 8px"
  },
  time: {
    fontSize: "100px",
    textAlign: "center",
    marginBottom: "150px"
  },
  selectedAvatar: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "30px",

    width: 190, height: 190,
  },
  buttonGroup: {
    margin: "auto",
    textAlign: "center",
    marginBottom: "150px",
    justifyContent: "center"
  },

  timeBtn: {
    backgroundColor: "#7B69A2",
    margin: "0 20px",
    padding: "7px 30px",
    color: "white",
    "&:disabled": {
      backgroundColor: "#f5f5f5",
      margin: "0 20px",
      padding: "7px 30px",
      color: "white",
    }
  },
  avatarName: {
    textAlign: "center",
    marginBottom: 20,
    justifyContent: "center",
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 22,
    // marginRight: 15
  },

  formControl: {
    minWidth: '100%',
    marginRight: 25,
    marginTop: 0
  },

  inputType: {
    marginTop: 16,
    marginBottom: 0,
    minWidth: '100%'
  },

  timeBtnCustom: {
    backgroundColor: "#7B69A2",
    margin: "0 20px",
    padding: "7px 30px",
    color: "white",
    width: "97%",
    "&:disabled": {
      backgroundColor: "#f5f5f5",
      margin: "0 20px",
      padding: "7px 30px",
      color: "white",
    }
  },
  grid: {
    marginTop: "50px"
  },
  gridDetails: {
    justifyContent: "space-evenly"

  },

  selectedAvatarCutom: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "30px",
    width: 220, height: 220,
  },
  avatarName: {
    textAlign: "center",
    marginBottom: 20,
    justifyContent: "center",
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 22,
    // marginRight: 15
  },


  appointmentFormInput: {
    width: "100%",
    margin: "20px 0"
  },
  inputIcon: {
    margin: "30px 0 0 0"
  },
  appointmentForm: {
    padding: 25
  },
  "& .memo-button-163": {
    display: "none!important"
  },
  appointmentTooltip: {
    padding: 20,
    borderColor: "#564181!important",
    borderLeft: "7px solid!important",
    marginTop: "-50px",
  },
  appointmentFormTimeInput: {
    width: "45%",
    margin: "20px 15px 0 0"
  },
  leaveDuration: {
    marginTop: "30px",
    fontFamily: 'proximaNova-bold',
    color: theme.palette.primary.light,
    fontSize: 20,
  },
  timeList: {
    margin: "7px 0 ",
    padding: 0,
    display: "flex",
    // "& *":{
    //     margin: "0 5px"
    // }
  },



  costings:{
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },

  "& .google-visualization-tooltip":{
    border: "1px solid red"
  }

  

});

export default styles;
