import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, cohort, user, extractAssignmentActivity } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleExtractAssignmentActivity = () => {
      setSaveLoading(true);

      if (cohort.canvasUrl) {
        let canvasCourseId = cohort.canvasUrl.substring(
          cohort.canvasUrl.lastIndexOf("/") + 1
        );

        extractAssignmentActivity(canvasCourseId, user.email, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`A download link will be sent to your e-mail.`);
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      } else {
        toast.error("Please set the canvas url for this cohort first");
        setSaveLoading(false);
        setClose(true);
      }
    };

    return (
      <Popup
        title="Extract Assignment Activity"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleExtractAssignmentActivity()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Ready to extract all assignment submissions and files for this
              cohort? Please note this takes several minutes; you will receive
              an e-mail with a download link.
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
