import React, {useContext, useEffect, useState} from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


// Academy
import Enquiries from './routes/academy/enquiries';
import Students from './routes/academy/students';
import Applicants from './routes/academy/applicants';
import Content from './routes/academy/content';
import Cohorts from './routes/academy/cohorts';
import Training from './routes/academy/training';
import Administration from './routes/academy/administration';
import Today from './routes/academy/today';
import General from './routes/academy/general'
import AcademyDashboard from './routes/academy/dashboard-academy';

// Resourcing
// import DashboardResourcing from './routes/resourcing/dashboard-resourcing';
import ClientsResourcing from './routes/resourcing/clients';
import Vacancies from './routes/resourcing/vacancies';
import Candidates from './routes/resourcing/candidates';
import  Industries from './routes/resourcing/industries';


// Advisory
import AdvisoryDashboard from './routes/advisory/dashboard-advisory';
import AdvisoryClients from './routes/advisory/clients';
import Proposals from './routes/advisory/proposals';
import Projects from './routes/advisory/projects';
import Consultants from './routes/advisory/consultants';
import Payments from './routes/advisory/payments'

// Sales
import Leads from './routes/sales/leads';
import Calculators from './routes/sales/calculators';


// Group
// import Contacts from './routes/contacts';
import Clients from './routes/group/clients';
import OutOfOffice from './routes/group/out-of-office'

// Admin
import Users from './routes/admin/users';
import Timesheets from './routes/admin/timesheets';
import Backoffice from './routes/admin/backoffice';
import ProgrammeCosts from './routes/admin/pragramme-costings';


// Group 
// import Contacts from './routes/contactsOld';

import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Styling
import styles from './dashboard-styles';
const useStyles = makeStyles(styles);

export default function Routes() {

    const { admin, academy, sales, resourcing, advisory, user, timesheets } = useContext(AuthenticationContext);

    const classes = useStyles();

    
    return(
        <div className={classes.root}>
            <Switch>
                {/* Academy */}
                <Route path='/today' component={() => academy && <Today/>} />
                <Route path='/enquiries' component={() => academy && <Enquiries/>} />
                <Route path='/students' component={() => academy && <Students/>} />
                <Route path='/content' component={() => academy && <Content/>} />
                <Route path='/cohorts' component={() => academy && <Cohorts/>} />
                <Route path='/training' component={() => academy && <Training/>} />
                <Route path='/administration' component={() => academy && <Administration/>} />
                <Route path='/general' component={() => academy && <General/>} />
                <Route path='/applicants' component={() => academy && <Applicants/>} />
                <Route path='/academy-dashboard' component={() => academy && <AcademyDashboard/>} />

  


                {/* Sales */}
                <Route path='/leads' component={() => sales && <Leads/>} />
                <Route path='/calculators' component={() => sales &&  <Calculators/>} />
                {/* <Route path='/contacts' component={() => <Contacts/>} /> */}

                {/* Admin */}
                <Route path='/users' component={() => <Users/>} />
                <Route path='/timesheets' component={() => (admin || timesheets) && <Timesheets/>} />
                <Route path='/backoffice' component={() => admin && <Backoffice/>} />
                <Route path='/programme-costings' component={() => admin && <ProgrammeCosts/>} />


                {/* Group */}
                <Route path='/clients' component={() =>  <Clients/>} />
                <Route path='/out-of-office' component={() =>  <OutOfOffice/>} />
                

                {/* Advisory */}
                <Route path='/advisory-dashboard' component={() => advisory && <AdvisoryDashboard/>} />
                <Route path='/advisory-clients' component={() => advisory && <AdvisoryClients/>} />
                <Route path='/proposals' component={() => advisory && <Proposals/>} />
                <Route path='/projects' component={() => advisory && <Projects/>} />
                <Route path='/consultants' component={() => advisory && <Consultants/>} />
                <Route path='/payments' component={() => advisory && <Payments/>} />


                {/* Resourcing */}
                {/* <Route path='/resourcing-dashboard' component={() => resourcing && <DashboardResourcing/>} /> */}
                <Route path='/resourcing-clients' component={() => resourcing && <ClientsResourcing/>} />
                <Route path='/vacancies' component={() => resourcing && <Vacancies/>} />
                <Route path='/candidates' component={() => resourcing && <Candidates/>} />
                <Route path='/industries' component={() => resourcing && <Industries/>} />


                <Route exact path='/' component={() => <Redirect to={`users/${user._id}`} />} />
            </Switch>
        </div>
    )
}