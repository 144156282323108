import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import { Typography, Paper, Grid } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import Moment from "react-moment";
import {IdTokenContext} from "repository/stores/global/token-provider"
// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    client,
    user,
    loading,
    setShowEditClientDialogue
  } = props;
  const classes = useStyles();


  const [title, setTitle] = useState("")
  const {idToken } = useContext(IdTokenContext)


  useEffect(() => {
    if (client) {
      setTitle(client.title)
    }
  }, [client])


  return (
    <Section
      title={title}
      loading={!client}
      actionName="Edit Client"
      handleAction={() => { setShowEditClientDialogue(true) }}
      sectionDescription={"Detailed information about the cohort."}
    >
      {client != null &&
        <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Title</Typography>
                <Typography className={classes.fontRegular}>{client.title}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Industry</Typography>
                <Typography className={classes.fontRegular}>{client.industry}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Website</Typography>
                <Typography className={classes.fontRegular}>{client.website}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Address</Typography>
                <Typography className={classes.fontRegular}>{client.address}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Vacancies</Typography>
                <Typography className={classes.fontRegular}>{client.vacancies.length}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Contact Person</Typography>
                <Typography className={classes.fontRegular}>{client.contacts[0].fullName}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Contact Role</Typography>
                <Typography className={classes.fontRegular}>{client.contacts[0].role}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Contact Email</Typography>
                <Typography className={classes.fontRegular}>{client.contacts[0].email}</Typography>
              </Paper>
              <Paper className={classes.miniSection}>
                <Typography className={classes.fontBold}>Contact Number</Typography>
                <Typography className={classes.fontRegular}>{client.contacts[0].contactNumber}</Typography>
              </Paper>
            </Grid>
          </Grid>

        </div>
      }
    </Section>
  );
});
