import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

//Actions
import { deleteCohortMembership } from "repository/actions/data/dashboard/students";

export default withSnackbar(
  withRouter(function (props) {
    const { isOpen, setClose, student, cohortId, cohorts, setCohorts, user, setStudent, getStudent } =
      props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteCohort = () => {
      if (user && user != null) {
        setSaveLoading(true);

        let fullCohort = null;
        for (let c of cohorts) {
          if (c._id == cohortId) {
            fullCohort = JSON.parse(JSON.stringify(c));
          }
        }

        deleteCohortMembership(student, cohortId, fullCohort, user.idToken)
          .then((result) => {
            getStudent(student._id, user.idToken).then(getStudentResult => {
              setStudent(getStudentResult);
              toast.success(
                `Successfully removed ${student.firstName} ${student.lastName} from ${fullCohort.title}`
              );
              setClose();
              setSaveLoading(false);
  
            })
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Delete cohort membership"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteCohort()}
        actionButtonTitle="Yes, I'm sure."
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to remove membership from this cohort ?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
