import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import LeavesView from "./leaves";
import PublicHolidaysView from "./public-holidays";

import { Grid } from "@material-ui/core";

export default withRouter(function (props) {

  return (
    <Grid container spacing={6}>
      <Grid item xs={7}>
        <LeavesView
        />
      </Grid><Grid item xs={5}>
        <PublicHolidaysView
        />
      </Grid>
    </Grid>
  );
});
