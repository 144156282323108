import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-edit-personnel-styles";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { toast } from "react-toastify";
import Popup from "components/popup";
import DateFnsUtils from "@date-io/date-fns";
import { updateNewPersonnel } from "repository/actions/data/dashboard/personnel";

const useStyles = makeStyles(styles);

function PersonnelDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, person, user } = props;

  const [firstName, setFirstName] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [openDob, setOpenDob] = React.useState(false);

  const [workplace, setWorkplace] = React.useState(null);
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [mobileNumber, setMobileNumber] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [lastNameError, setLastNameError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [addressError, setAddressError] = React.useState(null);
  const [mqfLevelError, setMqfLevelError] = React.useState(null);
  const [titleError, setTitleError] = React.useState(null);
  const [idError, setIdError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [dobError, setDobError] = React.useState(null);

  const handleEditPersonnel = () => {
    if (user && user != null) {
      if (
        firstName == null ||
        lastName == null ||
        mqfLevel == null ||
        email == null
      ) {
        toast.error(
          `You have missing information for editing ${person.firstName} ${person.lastName}`
        );
        if (firstName === null) {
          setFirstNameError("First Name is required.");
        }
        if (lastName === null) {
          setLastNameError("Last Name is required.");
        }
        if (mqfLevel === null) {
          setMqfLevelError("MQF Level is required.");
        }
        if (email == null) {
          setEmailError("Email is required.");
        }
        if (dob == null) {
          setDobError("DOB is required.");
        }
      } else {
        let data = {
          id_card: id,
          firstName: firstName,
          lastName: lastName,
          mqfLevel: mqfLevel,
          address: address,
          email: email,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          workplace: workplace,
          currentPosition: currentPosition,
        };

        setSaveLoading(true);

        updateNewPersonnel(person._id, data, user.idToken)
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(
              `Successfully edited ${person.firstName} ${person.lastName}`
            );
            setFirstName(null);
            setLastName(null);
            setMqfLevel(null);
            setId(null);
            setAddress(null);
            setEmail(null);
            setDob(null);
            window.location.href = `/administration/personnel/${result._id}`;
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
    
  };

  useEffect(() => {
    setFirstNameError(null);
    setLastNameError(null);
    setTitleError(null);
    setMqfLevelError(null);
    setIdError(null);
    setEmailError(null);
    setDobError(null);
    setAddressError(null);
  }, [firstName, lastName, mqfLevel, dob, id, title, email, isOpen]);

  useEffect(() => {
    setDob(person.dob);
    setFirstName(person.firstName);
    setLastName(person.lastName);
    setMqfLevel(person.mqfLevel);
    setEmail(person.email);
    setAddress(person.address);
    setId(person.id_card);
    setPhoneNumber(person.phoneNumber);
    setMobileNumber(person.mobileNumber);
    setWorkplace(person.workplace);
    setCurrentPosition(person.currentPosition);
  }, [person]);

  return (
    <Popup
      title="Update personnel information"
      subtitle="You are allowed to change any of the following details below."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleEditPersonnel()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div></div>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: John"}
                    disabled={saveLoading}
                    required
                    helperText={firstNameError}
                    error={firstNameError != null}
                    label="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Doe"}
                    disabled={saveLoading}
                    required
                    helperText={lastNameError}
                    error={lastNameError != null}
                    label="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <div></div>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: Email"}
                    disabled={saveLoading}
                    required
                    helperText={emailError}
                    error={emailError != null}
                    label="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: ID Card"}
                    disabled={saveLoading}
                    label="Id Card"
                    value={id}
                    onChange={(event) => setId(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <div></div>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 21000000"}
                    disabled={saveLoading}
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 79000000"}
                    disabled={saveLoading}
                    label="Mobile Number"
                    value={mobileNumber}
                    onChange={(event) => setMobileNumber(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <div></div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    placeholder={"Ex: 1, Ave Maria, Mosta, MST 1234, Malta"}
                    disabled={saveLoading}
                    required
                    helperText={addressError}
                    error={addressError != null}
                    label="Address"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    margin="normal"
                  ></TextField>
                </FormControl>
              </Grid>
            </Grid>
            <div />
            <FormControl
              className={classes.inputType}
              error={mqfLevelError != null}
            >
              <InputLabel required disabled={saveLoading}>
                Highest Qualification
              </InputLabel>
              <Select
                elevation={0}
                onChange={(event) => setMqfLevel(event.target.value)}
                value={mqfLevel}
                required
                error={mqfLevelError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                <MenuItem value={"6"}>
                  Bachelor Degree (MQF6)
                </MenuItem>
                <MenuItem value={"7"}>
                  Masters Degree (MQF7)
                </MenuItem>
                <MenuItem value={"8"}>
                  Doctoral Degree (MQF8)
                </MenuItem>
              </Select>
              <FormHelperText>{mqfLevelError}</FormHelperText>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: ABC Ltd"}
                disabled={saveLoading}
                label="Workplace"
                value={workplace}
                onChange={(event) => setWorkplace(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: Manager"}
                disabled={saveLoading}
                label="Current Position"
                value={currentPosition}
                onChange={(event) => setCurrentPosition(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(PersonnelDialogue);
