import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import AllOutOfOffice from './routes/out-of-office';

export default function () {
    return (
        <div>
            <Switch>
                <Route exact path='/out-of-office' component={() => <AllOutOfOffice />} />
            </Switch>
        </div>
    )
}