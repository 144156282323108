import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import CohortsView from "./single-student-cohorts-view";

//Dialogues
import DialogueAssignCohort from "routes/dashboard/dialogues/students/assign-cohort";
import DialogueDeleteCohortMembership from "routes/dashboard/dialogues/students/delete-cohort-membership";
import DialogueEditCohortModules from "routes/dashboard/dialogues/students/edit-cohort-modules";
//Actions

export default withRouter(function (props) {
  const { student, setStudent, user, editStudent, getStudent,getCourses, getCourseModules } = props;

  const [cohorts, setCohorts] = useState(null);
  const [showAssignCohortDialogue, setShowAssignCohortDialogue] =
    useState(false);
  const [showEditCohortModulesDialogue, setShowEditCohortModulesDialogue] =
    useState(false);
  const [
    showDeleteCohortAssociationDialogue,
    setShowDeleteCohortAssociationDialogue,
  ] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (student != null) {
      setCohorts(student.cohorts);
    }
  }, [student]);
  //Editing Item

  let editingItem = null;
  if (cohorts) {
    if (cohorts[selectedIndex]) {
      editingItem = cohorts[selectedIndex];
    }
  }

  return (
    <div>
      <CohortsView
        loading={!cohorts}
        toggleAssignCohortDialogue={() => setShowAssignCohortDialogue(true)}
        sectionDescription={
          "You can view and delete all related cohorts with this student."
        }
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        onPressedDeleteItem={(index) => {
          setSelectedId(student.cohorts[index]._id);
          setShowDeleteCohortAssociationDialogue(true);
        }}
        onPressedEditModules={(index) => {
          setSelectedId(student.cohorts[index]._id);
          setShowEditCohortModulesDialogue(true);
        }}
        user={user}
      />
      <DialogueAssignCohort
        isOpen={showAssignCohortDialogue}
        setClose={() => setShowAssignCohortDialogue(false)}
        itemId={selectedId}
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
        getCourses={getCourses}
        getCourseModules={getCourseModules}
      />
      <DialogueDeleteCohortMembership
        isOpen={showDeleteCohortAssociationDialogue}
        setClose={() => setShowDeleteCohortAssociationDialogue(false)}
        cohortId={selectedId}
        cohorts={cohorts || []}
        setCohorts={setCohorts}
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        user={user}
      />
      <DialogueEditCohortModules
        isOpen={showEditCohortModulesDialogue}
        setClose={() => setShowEditCohortModulesDialogue(false)}
        cohortId={selectedId}
        cohorts={cohorts || []}
        student={student}
        setStudent={setStudent}
        editStudent={editStudent}
        getStudent={getStudent}
        getCourseModules={getCourseModules}
        user={user}
      />
    </div>
  );
});
