import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";

import PublicHolidaysView from "./public-holidays-view";
import holidayList from "./public-holidays";

import moment from "moment";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);


  const [holidays, setHolidays] = useState(null);

  // Data Fetching
  useEffect(() => {
   
    let upcoming = []
    let currenteYear = moment().year()
    for(let h of holidayList){

      let date = moment(h.date+" "+currenteYear)

      if(date.isAfter(moment())){
        h.date = date.format("ddd DD MMM YYYY")
        upcoming.push(h)
      }
    }
    setHolidays(upcoming)
  }, []);

  return (
    <PublicHolidaysView
      user={user}
      loading={!holidays}
      sectionDescription={"You can manage all of your clients below."}
      holidays={holidays || []}
    />
  );
});
