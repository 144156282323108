const styles = theme => ({
    breadcrumbs: {
        textColor: theme.palette.primary,
        color: theme.palette.primary,
        marginLeft: 0,
        marginTop: 0,
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
          marginLeft: 0,
          marginTop: 0,
          fontSize: 18
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 0,
            fontSize: 18
        }
    },
    paper: {
        paddingLeft: 24,
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: 'rgba(255,255,255,0.4)',
        boxShadow: '0 1px 3px 0px rgba(180, 180, 180, .4)',
    },
    individualText: {
        fontSize: 13,
        color: 'black',
        fontFamily: 'proximaNova-regular'
    },
    individualTextInitial: {
        fontSize: 13,
        color: 'black',
        fontFamily: 'proximaNova-bold'
    },
    bottomContent: {
        paddingBottom: 24
    },
    separator: {
        marginTop: 11,
        width: 18,
        height: 18,
        [theme.breakpoints.up('sm')]: {
            marginTop: 11
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 11
        },
    }
  });
  
  export default styles;
  