import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormHelperText,
  ListSubheader,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
// import { createNewCohort } from "repository/actions/data/dashboard/cohorts";
import { getAssociatedModules } from "repository/actions/data/dashboard/courses";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Actions

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, courses, createNewCohort, setRefreshAlgolia } = props;

  const [moduleOptions, setModuleOptions] = React.useState([]);

  const [title, setTitle] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [groupedCourses, setGroupedCourses] = React.useState([]);


  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [selectedCourseIdError, setSelectedCourseIdError] =
    React.useState(null);
  const [startDateError, setStartDateError] = React.useState(null);
  const [endDateError, setEndDateError] = React.useState(null);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [openEndDate, setOpenEndDate] = React.useState(false);
  const [selectedModulesError, setSelectedModulesError] = React.useState(null);



  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    if (courses) {
      let grouped = groupArrayOfObjects(courses, "mqfLevel");
      setGroupedCourses(Object.entries(grouped))
    }
  }, [courses])


  const handleSaveOwnerDetails = () => {
    if (
      title == null ||
      startDate == null ||
      endDate == null ||
      selectedCourseId == null
    ) {
      toast.error("You have missing information for creating a new cohort");
      if (title === null) {
        setTitleError("Title is required.");
      }

      if (startDate == null) {
        setStartDateError("Start date is required.");
      }

      if (endDate == null) {
        setEndDateError("End date is required.");
      }

      if (selectedCourseId == null) {
        setSelectedCourseIdError("Course is required.");
      }
    } else {
      let data = {
        title: title,
        startDate: startDate,
        endDate: endDate,
        courseId: selectedCourseId,
        moduleIds: selectedModules,
        studentIds: [],
      };

      setSaveLoading(true);
      toast.dark(`Creating cohort : ${data.title}`);
      createNewCohort(data, user.idToken)
        .then((result) => {
          setTimeout(() => { setRefreshAlgolia(true) }, 2000)
          setTimeout(() => { setRefreshAlgolia(false) }, 2000)
          setClose();
          setSaveLoading(false);
          toast.success(`Cohort created : ${data.title}`);
          setTitle(null);
          setStartDate(null);
          setEndDate(null);
          setSelectedCourseId(null);
          setSelectedModules([]);
          setModuleOptions([])

        })
        .catch((error) => {
          toast.error(
            "Oops! Something went wrong. Check that you have the correct information"
          );
          toast.dark(error.message);
          setSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    setTitleError(null);
    setStartDateError(null);
    setEndDateError(null);
  }, [title, startDate, endDate, isOpen]);



  useEffect(() => {
    if (selectedCourseId && selectedCourseId != null) {
      getAssociatedModules(selectedCourseId, user.idToken).then((result) => {
        setModuleOptions(result.data);
      });
    }
  }, [selectedCourseId]);

  const handleSelectedModuleToggle = (moduleId, isChecked) => {
    if (isChecked) {
      if (selectedModules.indexOf(moduleId) < 0) {
        let newSelectedModules = selectedModules;
        newSelectedModules.push(moduleId);
        setSelectedModules(newSelectedModules);
      }
    } else {
      if (selectedModules.indexOf(moduleId) > 0) {
        let newSelectedModules = [];
        for (let s of selectedModules) {
          if (s != moduleId) {
            newSelectedModules.push(s);
          }
        }
        setSelectedModules(newSelectedModules);
      }
    }
  };

  return (
    <Popup
      title="Add a new cohort"
      subtitle="Please fill in the details below to create a new cohort."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveOwnerDetails()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder={"Ex: Course Title"}
                disabled={saveLoading}
                required
                helperText={titleError}
                error={titleError != null}
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                margin="normal"
              ></TextField>
            </FormControl>
            <div></div>
            <FormControl
              className={classes.inputType}
              error={selectedCourseIdError != null}
            >
              <InputLabel required disabled={saveLoading}>
                Assigned Course
              </InputLabel>
              {courses && courses != null && (
               <Select 
                  elevation={0}
                  onChange={(event) => setSelectedCourseId(event.target.value)}
                  value={selectedCourseId}
                  required
                  error={selectedCourseIdError != null}
                  disabled={saveLoading}
                  input={<Input id="type-menu" />}
                >
                  {groupedCourses.flat(2).map((item, index) => {
                    if (typeof item === 'object') {
                      return <MenuItem value={item._id}>{item.title}</MenuItem>;

                    } else {
                      return <ListSubheader> MQF Level {item}</ListSubheader>;
                    }
                  })}
                </Select>
              )}
              <FormHelperText>{selectedCourseIdError}</FormHelperText>
            </FormControl>
            {/* {moduleOptions && moduleOptions != null && (
              <FormControl className={classes.formControl}>
                <List>
                  {moduleOptions.map((mod) => {
                    return (
                      <ListItem key={mod._id}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            onChange={(event) => {
                              handleSelectedModuleToggle(
                                mod._id,
                                event.target.checked
                              );
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={`${mod.title}`} />
                      </ListItem>
                    );
                  })}
                </List>
              </FormControl>
            )} */}
            <div></div>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                required
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Start Date"
                value={startDate}
                helperText={startDateError}
                error={startDateError != null}
                onChange={(date) => {
                  setStartDate(date);
                  setOpenStartDate(false);
                }}
                onClick={() => setOpenStartDate(true)}
                onError={(error) => setStartDateError(error.message)}
                onOpen={() => setOpenStartDate(true)}
                onClose={() => setOpenStartDate(false)}
                open={openStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
            <div></div>
            <FormControl className={classes.formControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                required
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="End Date"
                value={endDate}
                helperText={endDateError}
                open={openEndDate}
                error={endDateError != null}
                onChange={(date) => {
                  setEndDate(date);
                  setOpenEndDate(false);
                }}
                onError={(error) => setEndDateError(error.message)}
                onClick={() => setOpenEndDate(true)}
                onOpen={() => setOpenEndDate(true)}
                onClose={() => setOpenEndDate(false)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
