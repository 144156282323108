import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Paper,
  Button,
  Switch,
  Grid,
  Checkbox,
  MenuItem,
  Input,
  Link,
  Select,
  Chip,
  TextField,
  ListSubheader,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { capitalizeFirstLetter } from "utils/helpers/string";
import Moment from "react-moment";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
import Panel from "components/panel";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    student,
    toggleChangeStatusDialogue,
    toggleAssignCanvasUrlDialogue,
    toggleAssignSharepointUrlDialogue,
    editStudent,
    setStudent,
    user,
    onPressedDeleteItem,
    referral,
    setShowEditStudentDialogue,
  } = props;
  const classes = useStyles();

  const [status, setStatus] = useState("#");
  const [statusColourClass, setStatusColourClass] = useState("#");
  const [title, setTitle] = useState("#");
  const [canvasUrl, setCanvasUrl] = useState("#");
  const [sharepointUrl, setSharepointUrl] = useState("#");
  const [addressHouse, setAddressHouse] = useState("#");
  const [addressStreet, setAddressStreet] = useState("#");
  const [addressCity, setAddressCity] = useState("#");
  const [addressPostcode, setAddressPostcode] = useState("#");
  const [addressCountry, setAddressCountry] = useState("#");
  const [gender, setGender] = useState("#");
  const [identificationNumber, setIdentificationNumber] = useState("#");
  const [email, setEmail] = useState("#");
  const [mobile, setMobile] = useState("#");
  const [dob, setDob] = useState(null);
  const [role, setRole] = useState("#");
  const [lead, setLead] = useState("#");
  const [company, setCompany] = useState("#");
  const [isInternational, setIsInternational] = useState("#");
  const [receiveMarketing, setReceiveMarketing] = useState("#");
  const [letterOfAcceptance, setLetterOfAcceptance] = useState(null);
  const [dissertationDetails, setDissertationDetails] = useState({});

  const [studentNumber, setStudentNumber] = useState("#");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [gdprApproved, setGdprApproved] = useState(false);
  const [cohorts, setCohorts] = useState([]);

  const handleGdprChange = (event) => {
    let patchData = { ...student, isGdprApproved: event.target.checked };
    editStudent(student._id, patchData, user.idToken).then((result) => {
      setStudent(result);
    });
    // setGdprApproved(event.target.checked);
  };

  const handleLetterOfAcceptance = (value) => {
    let patchData = { ...student, letterOfAcceptance: value };
    editStudent(student._id, patchData, user.idToken).then((result) => {
      setStudent(result);
      toast.success("Letter of Acceptance updated ", value);
    });
  };

  const handleChangeDissertationTitles = (value, cohortId) => {
    let newdissertationDetails = { ...dissertationDetails };
    newdissertationDetails[cohortId] = {
      ...newdissertationDetails[cohortId],
      title: value,
    };
    setDissertationDetails(newdissertationDetails);
  };
  const handleChangeDissertationScores = (score, cohortId) => {
    let newdissertationDetails = { ...dissertationDetails };
    newdissertationDetails[cohortId] = {
      ...newdissertationDetails[cohortId],
      score: score,
    };
    setDissertationDetails(newdissertationDetails);
  };
  const handleChangeDissertationSpecialisation = (value, cohortId) => {
    let newdissertationDetails = { ...dissertationDetails };
    newdissertationDetails[cohortId] = {
      ...newdissertationDetails[cohortId],
      specialisation: value,
    };
    setDissertationDetails(newdissertationDetails);
  };

  const handleChangeDissertationECTS = (value, cohortId) => {
    let newdissertationDetails = { ...dissertationDetails };
    newdissertationDetails[cohortId] = {
      ...newdissertationDetails[cohortId],
      ects: value,
    };
    setDissertationDetails(newdissertationDetails);
  };

  const handleSaveDissertationDetails = () => {
    let patchData = { ...student, dissertationDetails: dissertationDetails };
    editStudent(student._id, patchData, user.idToken).then((result) => {
      setStudent(result);
      toast.success("Dissertaiton Titles updated");
    });
  };

  useEffect(() => {
    if (student) {
      setTitle(`${student.title} ${student.firstName} ${student.lastName}`);
      setAddressHouse(student.addressHouse);
      setAddressCity(student.addressCity);
      setAddressCountry(student.addressCountry);
      setAddressStreet(student.addressStreet);
      setIdentificationNumber(student.identificationNumber);
      setAddressPostcode(student.addressPostcode);
      setIsInternational(student.isInternational);
      setReceiveMarketing(student.receiveMarketing);
      setGender(student.gender ? capitalizeFirstLetter(student.gender) : "N/A");
      setEmail(student.email);
      setDob(student.dob);
      setMobile(student.mobile);
      setRole(student.role);
      setStatus(student.status);
      setLead(student.lead ? capitalizeFirstLetter(student.lead) : "N/A");
      setCompany(student.company);
      setStudentNumber(student.studentNumber);
      if (student.createdAt) {
        setCreatedAt(student.createdAt);
      }
      setDissertationDetails(student.dissertationDetails);

      setCanvasUrl(student.canvasUrl);
      setSharepointUrl(student.sharepointUrl);
      setGdprApproved(student.isGdprApproved);
      setLetterOfAcceptance(student.letterOfAcceptance);
      setCohorts(student.cohorts);
    }
  }, [student]);

  useEffect(() => {
    let newStatusColour = "";
    if (status === "Active") {
      newStatusColour = "success";
    } else if (status === "Cancelled") {
      newStatusColour = "error";
    } else if (status === "Inactive") {
      newStatusColour = "warning";
    } else {
      newStatusColour = "info";
    }
    setStatusColourClass(newStatusColour);
  }, [status]);

  return (
    <div>
      <Section
        title={title}
        loading={!student}
        // handleAction={toggleChangeStatusDialogue}
        handleAction={() => {
          setShowEditStudentDialogue(true);
        }}
        setShowEditStudentDialogue
        actionName="Edit Student"
        sectionDescription={"Detailed information about this student."}
        chipTitle={status}
        chipState={statusColourClass}
        chipHandle={toggleChangeStatusDialogue}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            {student && student.extensions.length > 0 && (
              <Chip
                label={`${student.extensions.length} extensions`}
                style={{
                  backgroundColor: "#8B0000",
                  color: "white",
                  marginBottom: 10,
                  marginRight: 10,
                }}
              />
            )}
            {student && referral != null && (
              <Link href={`/general/referrals/${referral._id}`}>
                <Chip
                  label={`Referral - ${referral.firstName}`}
                  style={{
                    backgroundColor: "#f4c569",
                    color: "white",
                    marginBottom: 10,
                  }}
                />
              </Link>
            )}

            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Student Number
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {studentNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    ID Number
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {identificationNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>Address</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {addressHouse}, {addressStreet}, {addressCity},{" "}
                    {addressCountry}, {addressPostcode}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>Gender</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {gender}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>Email</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {email}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>Mobile</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {mobile}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Canvas URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {canvasUrl ? (
                      <span>
                        <a href={canvasUrl} target="_blank">
                          {canvasUrl}
                        </a>
                        <Button
                          onClick={() => {
                            toggleAssignCanvasUrlDialogue();
                          }}
                          className={classes.button}
                        >
                          Assign
                        </Button>
                      </span>
                    ) : (
                      <Button
                        onClick={() => {
                          toggleAssignCanvasUrlDialogue();
                        }}
                        color="primary"
                      >
                        Assign
                      </Button>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Sharepoint URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {sharepointUrl ? (
                      <span>
                        <a href={sharepointUrl} target="_blank">
                          {sharepointUrl}
                        </a>
                        <Button
                          onClick={() => {
                            toggleAssignSharepointUrlDialogue();
                          }}
                          className={classes.button}
                        >
                          Assign
                        </Button>
                      </span>
                    ) : (
                      <Button
                        onClick={() => {
                          toggleAssignSharepointUrlDialogue();
                        }}
                        color="primary"
                      >
                        Assign
                      </Button>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    GDPR Approval
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Switch
                    checked={gdprApproved}
                    onChange={handleGdprChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/*  */}

          <Grid item xs={6}>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>DOB</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    <Moment format="D MMMM, YYYY" date={dob} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>Added on</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    <Moment format="D MMMM, YYYY" date={createdAt} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Is International
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {isInternational ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Lead Source
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.fontRegular}>
                    {lead}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.miniSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.fontBold}>
                    Marketing Consent
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <span>
                    <Checkbox
                      disabled={true}
                      className={classes.fontRegular}
                      checked={receiveMarketing}
                    />
                    {receiveMarketing ? "Yes" : "No"}
                  </span>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.miniSection}>
              <Typography className={classes.fontBold}>
                Letter of Acceptance
              </Typography>
              <Select
                elevation={0}
                onChange={(event) =>
                  handleLetterOfAcceptance(event.target.value)
                }
                value={letterOfAcceptance}
                required
                className={classes.fontRegular}
                style={{ width: "40%" }}
                input={<Input id="type-menu" />}
              >
                <MenuItem value={"v1"}>{"Version 1 (2021)"}</MenuItem>
                <MenuItem value={"v2"}>{"Version 2 (2022)"}</MenuItem>
              </Select>
            </Paper>
            <div>
              <Panel>
                {cohorts.map((item, index) => {
                  let title = "";
                  let score = "";
                  let specialisation = "";
                  let ects = "";

                  if (dissertationDetails) {
                    if (dissertationDetails[item._id]) {
                      if (dissertationDetails[item._id].score) {
                        score = dissertationDetails[item._id].score;
                      }
                      if (dissertationDetails[item._id].title) {
                        title = dissertationDetails[item._id].title;
                      }
                      if (dissertationDetails[item._id].specialisation) {
                        specialisation =
                          dissertationDetails[item._id].specialisation;
                      }
                      if (dissertationDetails[item._id].ects) {
                        ects =
                          dissertationDetails[item._id].ects;
                      }
                    }
                  }
                  return (
                    <div key={`student-cohorts-${index}`}>
                      <p style={{ margin: 5, marginTop: 10 }}>{item.title}</p>
                      <TextField
                        placeholder={"Dissertaiton Title"}
                        variant="outlined"
                        size="small"
                        label="Title"
                        value={title}
                        onChange={(event) => {
                          handleChangeDissertationTitles(
                            event.target.value,
                            item._id
                          );
                        }}
                        margin="none"
                        InputProps={{
                          style: { fontSize: 12, padding: 0 },
                        }}
                        style={{ width: "70%", marginBottom: 10 }}
                      ></TextField>
                      
                      <TextField
                        placeholder={"Score"}
                        variant="outlined"
                        size="small"
                        label="Score"
                        type={"number"}
                        value={score}
                        onChange={(event) => {
                          handleChangeDissertationScores(
                            event.target.value,
                            item._id
                          );
                        }}
                        margin="none"
                        InputProps={{
                          style: { fontSize: 12, padding: 0 },
                        }}
                        style={{
                          width: "12%",
                          marginBottom: 10,
                          marginLeft: 10,
                        }}
                      ></TextField>
                         <TextField
                        placeholder={"ECTS"}
                        variant="outlined"
                        size="small"
                        label="ECTS"
                        type={"number"}
                        value={ects}
                        onChange={(event) => {
                          handleChangeDissertationECTS(
                            event.target.value,
                            item._id
                          );
                        }}
                        margin="none"
                        InputProps={{
                          style: { fontSize: 12, padding: 0 },
                        }}
                        style={{
                          width: "12%",
                          marginBottom: 10,
                          marginLeft: 10,
                        }}
                      ></TextField>
                      <FormControl
                        style={{
                          width: "350px",
                          marginBottom: 10,
                        }}
                        size="small"
                      >
                        <InputLabel
                          id="specialisation-select-label"
                          style={{ fontSize: 16 }}
                        >
                          Specialisation
                        </InputLabel>
                        <Select
                          labelId="specialisation-select-label"
                          id="specialisation-select"
                          label="Specialisation"
                          placeholder={"Specialisation"}
                          value={specialisation}
                          onChange={(event) => {
                            handleChangeDissertationSpecialisation(
                              event.target.value,
                              item._id
                            );
                          }}
                        >
                          <ListSubheader>Fashion</ListSubheader>
                          <MenuItem
                            value={"Fashion Management"}
                            style={{ fontSize: 14 }}
                          >
                            Fashion Management
                          </MenuItem>
                          <MenuItem
                            value={"Sustainable Fashion"}
                            style={{ fontSize: 14 }}
                          >
                            Sustainable Fashion
                          </MenuItem>
                          <MenuItem
                            value={"Fashion Marketing "}
                            style={{ fontSize: 14 }}
                          >
                            Fashion Marketing
                          </MenuItem>

                          <ListSubheader>Finance Management</ListSubheader>
                          <MenuItem value={"Banking"} style={{ fontSize: 14 }}>
                            Banking
                          </MenuItem>
                          <MenuItem
                            value={"Corporate Finance"}
                            style={{ fontSize: 14 }}
                          >
                            Corporate Finance
                          </MenuItem>
                          <MenuItem
                            value={"Financial Analysis"}
                            style={{ fontSize: 14 }}
                          >
                            Financial Analysis
                          </MenuItem>
                          <ListSubheader>Management</ListSubheader>
                          <MenuItem
                            value={"Human Resources Management"}
                            style={{ fontSize: 14 }}
                          >
                            Human Resources Management
                          </MenuItem>
                          <MenuItem
                            value={"Project Management"}
                            style={{ fontSize: 14 }}
                          >
                            Project Management
                          </MenuItem>
                          <MenuItem
                            value={"Blockchain"}
                            style={{ fontSize: 14 }}
                          >
                            Blockchain
                          </MenuItem>
                          <MenuItem
                            value={"Marketing "}
                            style={{ fontSize: 14 }}
                          >
                            Marketing{" "}
                          </MenuItem>
                          <MenuItem
                            value={"Entrepreneurship  "}
                            style={{ fontSize: 14 }}
                          >
                            Entrepreneurship
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  );
                })}
                <Button
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#f7f7f7",
                  }}
                  onClick={() => {
                    handleSaveDissertationDetails();
                  }}
                >
                  Save
                </Button>
              </Panel>
            </div>
          </Grid>
        </Grid>
        {/*  */}

        {/* <Button
          className={classes.deleteButton}
          onClick={() => onPressedDeleteItem(student._id)}
          variant="outlined"
        >
          DEACTIVATE STUDENT
        </Button> */}
      </Section>
    </div>
  );
});
