import React, { useEffect } from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
import { withRouter } from "react-router-dom";

// Actions

export default withSnackbar(
  withRouter(function (props) {

    const { setClose, isOpen, user, editCandidate, vacancy, setRefreshAlgolia,
      vacancyId,
      editVacancy,
      applications,
      candidateId
    } = props;
  
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteItem = () => {
      if (user && user != null) {
        setSaveLoading(true);

        let candidateApplications = applications

        let newApplications = []

        for(let application of candidateApplications){
          if(application.vacancyId != vacancyId){
            newApplications.push(application)
          }
        }

        let candidatePayload = {
          applications: newApplications
        }


        let candidates = vacancy.candidates
        candidates.splice(candidates.indexOf(candidateId),1);


        let vacancyPayload = {
          candidates: candidates
        }

        editCandidate(candidateId, candidatePayload, user.idToken).then((result)=>{

          editVacancy(vacancyId, vacancyPayload, user.idToken).then(()=>{
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Candidate successfully removed`);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong."
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });

        })
         
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Remove selected candidate"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteItem()}
        actionButtonTitle="Yes, I'm sure"
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to remove this candidate?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
