import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Section from 'components/section';
import { Typography, TableContainer, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Paper, ClickAwayListener,Grow, MenuList, MenuItem, Divider, Popper } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Moment from 'react-moment';

// Styles
import styles from 'routes/dashboard/dashboard-styles';
const useStyles = makeStyles(styles);

export default function (props) {

  const classes = useStyles();
  const { loading, toggleAddBroadcastForm, broadcasts, sectionDescription, onPressedItem } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId ] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
      <Section title="BROADCASTS" loading={loading} actionName="Write email" handleAction={toggleAddBroadcastForm} sectionDescription={sectionDescription}>
          {(broadcasts.length > 0) &&
            <TableContainer className={classes.miniSection} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left"> <Typography className={classes.labelText}>Email Subject</Typography></TableCell>
                    <TableCell align="left"> <Typography className={classes.labelText}>Date Sent</Typography></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {broadcasts.map((row, index) => {
                    return (
                      <TableRow hover key={row._id} className={classes.row}>
                        <TableCell className={classes.cellName} align="left">{row.subject}</TableCell>
                        <TableCell className={classes.cellName} align="left"><Moment format="D MMMM, YYYY" date={row.createdAt} /></TableCell>
                        <TableCell className={classes.selectRight} align="right">
                              <IconButton className={classes.rightRowButton} onClick={(event) => { setAnchorEl(event.currentTarget); setSelectedIndex(index); setSelectedId(row._id); setOpen(true) }} aria-owns={open ? 'menu-list-grow' : null} aria-haspopup="true">
                                  <MoreVert className={classes.iconRight}/>
                              </IconButton> 
                          </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {(broadcasts.length === 0) &&
            <Typography className={classes.emptyText} align='center'>You haven't created any broadcasts yet.</Typography>
          }
          <Popper open={open} anchorEl={anchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper elevation={0} className={classes.menu}>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList className={classes.menuList}>
                        <MenuItem  onClick={() =>{ onPressedItem(selectedIndex, selectedId); setOpen(false)}} className={classes.menuItemTop}>View</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
      </Section>
  )
}