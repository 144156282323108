import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SingleProposalDetailsView from "./single-proposal-details-view";

// Dialogues
import DialogueEditProposal from "routes/dashboard/dialogues/proposals/edit";


export default withRouter(function (props) {
  let {
    proposal,
    setProposal,
    user,
    editProposal
  } = props;


  const [showEditProposalDialogue, setShowEditProposalDialogue] = useState(false);


  return (
    <div>
      <SingleProposalDetailsView
        loading={!proposal}
        sectionDescription={"Detailed information about the proposal."}
        user={user}
        proposal={proposal}
        setProposal={setProposal}
        editProposal={editProposal}
        setShowEditProposalDialogue={setShowEditProposalDialogue}
      />

      <DialogueEditProposal
        isOpen={showEditProposalDialogue}
        setClose={() => setShowEditProposalDialogue(false)}
        user={user}
        proposal={proposal}
        setProposal={setProposal}
        editProposal={editProposal}
      />


    </div>
  );
});
