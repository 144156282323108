import React, { useEffect, useState, useContext, useRef } from "react";
import apiClient from "utils/api";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";

import firebaseClient from "../../../utils/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "firebase/auth";




import {IdTokenContext} from "repository/stores/global/token-provider"


const ContextProps = {
  user: null,
  authenticated: false,
  setUser: {},
  loadingAuthState: false,
  // emailVerified: firebase.User.emailVerified | false,
  // createAuthToken: generateCustomUserAuthToken,
};

export const AuthenticationContext = React.createContext(ContextProps);
const auth = getAuth();

export const AuthenticationProvider = ({ children }) => {
  // firebaseClient();
  const [user, setUser] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  const [admin, setAdmin] = useState(false)
  const [academy, setAcademy] = useState(false)
  const [sales, setSales] = useState(false)
  const [advisory, setAdvisory] = useState(false)
  const [resourcing, setResourcing] = useState(false)
  const [timesheets, setTimesheets] = useState(false)


  const [redirectTo, setRedirectTo] = useState("/users")


  const { getIdToken, setIdToken } = useContext(IdTokenContext)


  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();

        resolve(user);
      }, reject);
    });
  }

  function updateToken() {
    if (auth && auth.currentUser && auth.currentUser != null) {
      auth.currentUser.getIdToken(true).then((idToken) => {
        // setUser({ ...user, idToken });
        // setIdToken(idToken)
      });
    }
  }

  function engageTokenCycle() {
    if (user && user != null) {
      setInterval(() => {
        // updateToken();
        // getIdToken()
      }, 10000);
    }
  }

  function getRoleAccess(user) {

    if (user.email == "timesheets@ideamalta.com") {
      setTimesheets(true)
    }

    if (user.roles.indexOf("resourcing") >= 0) {
      setResourcing(true)
    }
    if (user.roles.indexOf("advisory") >= 0) {
      setAdvisory(true)
    }
    if (user.roles.indexOf("academy") >= 0) {
      setAcademy(true)
    }
    if (user.roles.indexOf("sales") >= 0) {
      setSales(true)
    }
    if (user.roles.indexOf("admin") >= 0) {
      setAdmin(true)
      setAcademy(true)
      setSales(true)
      setAdvisory(true)
      setResourcing(true)
    }
  }

  useEffect(() => {
    if (user != null) {
      getRoleAccess(user)
      engageTokenCycle()
    }
  }, [user])


  useEffect(() => {

    onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {
          try {
            const idToken = await fbUser.getIdToken();
            const result = await apiClient.service("v1/users").get(fbUser.uid, {
              query: {
                firebaseToken: idToken,
              },
            });

            if (result.displayPhoto) {
              let signedPhotoUrl = result.displayPhoto.url ?

                result.displayPhoto.url.indexOf("st-1") > -1
                  ? await getSignedUrlFromAbsoluteUrl(
                    result.displayPhoto.url,
                    idToken
                  )
                  : result.displayPhoto.url

                : null;

              result.displayPhoto.url = signedPhotoUrl;
            } else {
              result.displayPhoto = {
                url: null,
              };
            }

            setUser({
              ...result,
              idToken,
            });

            setFirebaseUser(fbUser);
            setLoadingAuthState(false);
            // engageTokenCycle();
          } catch (e) {
            console.error(e);
          }
        // }
      } else {
        setUser(null);
        setFirebaseUser(null);
        setLoadingAuthState(false);
      }


    });
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
        admin, academy, sales, resourcing, advisory, redirectTo, timesheets
        // createAuthToken: generateCustomUserAuthToken,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => useContext(AuthenticationContext);