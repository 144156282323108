import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./dialogues-create-user-styles";
import { Link, TextField, Typography, FormControl, Select, OutlinedInput, InputLabel, Box, Chip, MenuItem, Grid, Input } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import ImageFadeIn from "react-image-fade-in";
import EmptyProfileImage from "assets/images/empty-profile.png";
// import { AllUsersContext } from "repository/stores/dashboard/users/users-all-provider";
// import { createNewUser } from "repository/actions/data/dashboard/users";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, createNewUser } = props;

  // const {users, setUsers, createNewUser} = React.useContext(AllUsersContext);

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [surname, setSurname] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [leave, setLeave] = React.useState(null);
  const [department, setDepartment] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [surnameError, setSurnameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [imageError, setImageError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [leaveError, setLeaveError] = React.useState(null);
  const [departmentError, setDepartmentError] = React.useState(null);

  const [roles, setRoles] = React.useState([]);


  const departments = ["Sales", "Studio", "Finance", "Advisory", "Academy", "Intelligence", "Front Office"]

  const handleCreateUser = () => {
    if (user && user != null) {
      if (
        name == null ||
        surname == null ||
        password == null ||
        email == null ||
        leave == null ||
        department == null ||
        image == null
      ) {
        toast.error(`You have missing information to create a new user`);
        if (email === null) {
          setEmailError("Email is required.");
        }
        if (surname === null) {
          setSurnameError("Surname is required.");
        }
        if (name === null) {
          setNameError("Name is required.");
        }
        if (image == null) {
          setImageError("A photo is required.");
        }
        if (password == null) {
          setPasswordError("Password is required.");
        }
        if (leave == null) {
          setLeaveError("Leave is required.");
        }
        if (department == null) {
          setLeaveError("Department is required.");
        }
      } else {
        let data = {
          firstName: name,
          lastName: surname,
          password: password,
          email: email,
          imageURI: image,
          leave: leave,
          roles: roles,
          department: department,

        };

        setSaveLoading(true);

        toast.dark(`Creating ${data.firstName} ${data.lastName}`);
        createNewUser(data, user.idToken).then((result) => {
          setClose();
          setSaveLoading(false);
          toast.success(`User Created :  ${data.firstName} ${data.lastName}`);
          setImage(null);
          setName(null);
          setSurname(null);
          setEmail(null);
          setPassword(null);
          setRoles(null);
          setLeave(null);


        }).catch((e) => {
          setSaveLoading(false);
          console.log(e.message)
        });
      }
    }
  };

  const rolesList = [
    'admin',
    'sales',
    'academy',
    'advisory',
    'resourcing',
    'Studio',
    'Finance'
  ];

  useEffect(() => {
    setNameError(null);
    setSurnameError(null);
    setImageError(null);
    setEmailError(null);
    setPasswordError(null);
    setLeaveError(null);

  }, [name, surname, email, isOpen, image]);

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    if (target.files[0].size > 5242880) {
      toast.error("File is too big. The maximum size allowed is 5MB.");
    } else {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setImage(e.target.result);
      };
    }
  };

  const handleRole = (event) => {
    const {
      target: { value },
    } = event;

    setRoles(typeof value === 'string' ? value.split(',') : value,);
  };

  return (
    <Popup
      title="Add a new user"
      subtitle="Please fill in the details below to create a new user."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateUser()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <div className={classes.coverPhoto}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              disabled={saveLoading}
              onChange={handleCapture}
            />
            <label htmlFor="contained-button-file" disabled={saveLoading}>
              {image && <ImageFadeIn className={classes.photo} src={image} />}
              {!image && (
                <ImageFadeIn
                  className={classes.photo}
                  src={EmptyProfileImage}
                />
              )}
              <Typography className={classes.error}>{imageError}</Typography>
              <Link
                className={classes.link}
                disabled={saveLoading}
                component="span"
                variant="body2"
              >
                {image && <div>Change Photo (max: 5mb)</div>}
                {!image && <div>Add Photo (max: 5mb)</div>}
              </Link>
            </label>
          </div>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: John"}
                  disabled={saveLoading}
                  required
                  helperText={nameError}
                  error={nameError != null}
                  label="First Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Doe"}
                  disabled={saveLoading}
                  required
                  helperText={surnameError}
                  error={surnameError != null}
                  label="Last Name"
                  value={surname}
                  onChange={(event) => setSurname(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: johndoe@example.com"}
                  disabled={saveLoading}
                  required
                  helperText={emailError}
                  error={emailError != null}
                  label="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: XXXXXXXX"}
                  disabled={saveLoading}
                  required
                  helperText={passwordError}
                  error={passwordError != null}
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: 170 hours"}
                  disabled={saveLoading}
                  required
                  helperText={leaveError}
                  error={leaveError != null}
                  label="Leave Hours"
                  type="number"
                  value={leave}
                  onChange={(event) => setLeave(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <div></div>
              <FormControl className={classes.inputType}>
                <InputLabel required disabled={saveLoading}>
                  Department
                </InputLabel>
                <Select
                  elevation={0}
                  onChange={(event) => setDepartment(event.target.value)}
                  value={department}
                  required
                  disabled={saveLoading}
                  input={<Input id="type-menu" />}
                >
                  {departments.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div></div>

          <FormControl className={classes.formControl} style={{ margin: "20px 0" }}>
            <InputLabel required style={{ marginLeft: "20px" }}>
              Role
            </InputLabel>
            <Select
              multiple
              variant="standard"
              value={roles}
              onChange={handleRole}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {rolesList.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
