import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import {
  FormControl,
  Input,
  MenuItem,
  Select,
  ListItemText,
  Avatar,
  ListItemAvatar,
  List,
  ListItem,
  IconButton,
  TextField,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    project,
    user,
    loading,
    isNewProject,
    setProject,
    getConsultant,
    allConsultants,
    editConsultant,
    panelDescription,
  } = props;
  const classes = useStyles();

  const [consultantMap, setConsultantMap] = useState([]);
  const [consultants, setConsultants] = useState([]);

  const [selectedConsultants, setSelectedConsultants] = useState([]);


  useEffect(() => {
    if (allConsultants) {
      setConsultants(allConsultants)
      let newConsultantMap = {};
      for (let c of allConsultants) {
        newConsultantMap[c._id] = c;
      }
      setConsultantMap(newConsultantMap);
    }
  }, [allConsultants]);





  const handleSelectedConsultants = (event) => {
  
    let newConsultants = [...selectedConsultants];
    newConsultants.push(consultants[event.target.value]._id);
    setSelectedConsultants(newConsultants);

    setProject({ ...project, consultants: newConsultants });

    newConsultants = [...consultants];
    newConsultants.splice(
      newConsultants.indexOf(consultants[event.target.value]),
      1
    );
    setConsultants(newConsultants);
  };

  const removeSelectedConsultants = (index) => {
    let assignedConsultants = [...selectedConsultants];
   
    let availableConsultants = [...consultants];
    availableConsultants.push(assignedConsultants[index]);
    setConsultants(availableConsultants);

    assignedConsultants.splice(index, 1);
    setSelectedConsultants(assignedConsultants);
    setProject({ ...project, consultants: assignedConsultants });
  };

 
  useEffect(() => {
    if (project && allConsultants) {
      if (!isNewProject) {
        setSelectedConsultants(project.consultants ? project.consultants : []);
      }
    }
  }, [project, allConsultants]);

  const dimIsNewProject = (isNewProject) => {
    return {
      opacity: isNewProject ? 0.25 : 1,
    };
  };

  return (
    <div style={dimIsNewProject(isNewProject)}>
      
      <Panel
        title={"Personnel"}
        loading={loading}
        panelDescription={panelDescription}
      >
        {loading != null && (
          <div>
            <List>
              {selectedConsultants.map((item, index) => {
                let c = consultantMap[item]
                return (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt={c.firstName}
                        src={c.directURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${c.firstName} ${c.lastName}`}
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        removeSelectedConsultants(index);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
            <FormControl className={classes.inputType}>
              <Select
                elevation={0}
                variant="outlined"
                value={null}
                onChange={(event) => handleSelectedConsultants(event)}
                required
                input={<Input id="type-menu" />}
                disabled={isNewProject}
              >
                {consultants.map((item, index) => {
                  return (
                    <MenuItem value={index} key={index}>
                      <img
                        src={item.directURL}
                        width="40px"
                        style={{ marginRight: "10px" }}
                      />{" "}
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  );
                })}

               
              </Select>
            </FormControl>
          </div>
        )}
      </Panel>
    </div>
  );
});
