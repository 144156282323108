import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import ProjectsGantt from "./projects-gantt-view";
import ProjectHours from "./project-hours";

import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";

import { Grid } from "@material-ui/core";

export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { getProjects, getProjectsByDate } = useContext(AllProjectsContext);
  const { getConsultants } = useContext(AllConsultantsContext);


  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(moment());
  const [currentYear, setCurrentYear] = useState(moment());


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      let yearStart = year.startOf("year").valueOf();
      let yearEnd = year.endOf("year").valueOf();

      getProjectsByDate(yearStart, yearEnd).then((result) => {
        setProjects(result.data);
        setIsLoading(true);
      });
    }
  }, [user, year]);

  return (
    <div>
      {isLoading && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ProjectsGantt
              user={user}
              loading={isLoading}
              setProjects={setProjects}
              projects={projects}
              getProjectsByDate={getProjectsByDate}
              setYear={setYear}
              getConsultants={getConsultants}

            />
          </Grid>
          <Grid item xs={6}>
            <ProjectHours
              user={user}
              loading={isLoading}
              setProjects={setProjects}
              projects={projects}
              getProjectsByDate={getProjectsByDate}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
});
