import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { vacancy } = props


  let crumbs = [
    { name: 'Vacancies', path: 'vacancies' },
    { name: "vacancy", path: `vacancies` },
    { name: '...', path: '#' },
  ];


  if (vacancy) {
    crumbs[1] = { name: vacancy.positionName, path: `vacancies/${vacancy._id}` }
  }
  return (
    <div>
      {vacancy && (<>
        <NavCrumbs crumbs={crumbs} />
        {props.children}
      </>)}
    </div>
  )
}
