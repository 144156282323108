import React from 'react';

// Parents
import LeadsTabs from './dash-leads-tabs';
import LeadsRoutes from './dash-leads-routes';

export default function (props) {

  return (
    <LeadsTabs>
      <LeadsRoutes />
    </LeadsTabs>
  )
}
