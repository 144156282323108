import apiClient from "utils/api";
import { getIdToken } from "../authentication"


export const findAllLocations = async (dispatch, token) => {
  return new Promise(async (resolve, reject) => {
    try {
       
        let result = await apiClient.service("v1/locations").find({
          query: {
            firebaseToken: token,
          },
        });

        dispatch({
          type: "UpdateLocations",
          payload: result.data,
        });

        resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const createNewLocation = async (dispatch, location, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/locations").create(location, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "AddLocation",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const editLocation = async (dispatch, data, locationId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let updatedLocation = await apiClient
        .service("v1/locations")
        .patch(locationId, data, {
          query: {
            firebaseToken: token,
          },
        });

      dispatch({
        type: "EditLocation",
        payload: {
          locationId: locationId,
          data: updatedLocation,
        },
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteLocation = async (dispatch, lecturerId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/locations").remove(lecturerId, {
        query: {
          firebaseToken: token,
        },
      });

      dispatch({
        type: "RemoveLocation",
        payload: result,
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getSingleLocation = async (locationId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/locations").get(locationId, {
        query: {
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedTrainingSessions = async (locationId, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await apiClient.service("v1/training/sessions").find({
        query: {
          locationId: locationId,
          firebaseToken: token,
        },
      });

      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

export const getAssociatedLectureSlots = async (locationId, token) => {
  return new Promise(async (resolve, reject) => {
    let result = await apiClient.service("v1/lectures").find({
      query: {
        locationId: locationId,
        firebaseToken: token,
      },
    });

    resolve(result);
  });
};
