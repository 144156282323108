import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Children
import CalendarView from "./calendar-view";

import DialogueProposalTime from "routes/dashboard/dialogues/proposals/proposal-timings";
import DialogueProjectTime from "routes/dashboard/dialogues/projects/create-timings";

export default withRouter(function (props) {
  let {
    user,
    consultant,
    consultantId,
    getProposals,
    createProjectTiming,
    findProjectTimingsbyConsultant,
    findProposalTimingsbyConsultant,
    findActiveProjectsbyConsultant,
    findCompleteProjectsbyConsultant,
    getActiveProjects,
    createProposalTiming,
  } = props;

  const [showProposalTimeDialogue, setShowProposalTimeDialogue] =
    useState(false);
  const [showProjectTimeDialogue, setShowProjectTimeDialogue] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <div>
      <CalendarView
        loading={!consultant}
        user={user}
        consultant={consultant}
        consultantId={consultantId}
        getProposals={getProposals}
        setShowProjectTimeDialogue={setShowProjectTimeDialogue}
        setShowProposalTimeDialogue={setShowProposalTimeDialogue}
        setSelectedDate={setSelectedDate}
        createProjectTiming={createProjectTiming}
        getActiveProjects={getActiveProjects}
        findProjectTimingsbyConsultant={findProjectTimingsbyConsultant}
        findProposalTimingsbyConsultant={findProposalTimingsbyConsultant}
        findActiveProjectsbyConsultant={findActiveProjectsbyConsultant}
        findCompleteProjectsbyConsultant={findCompleteProjectsbyConsultant}


      />

      <DialogueProposalTime
        isOpen={showProposalTimeDialogue}
        setClose={() => setShowProposalTimeDialogue(false)}
        consultant={consultant}
        user={user}
        date={selectedDate}
        setProposalTime={showProposalTimeDialogue}
        createProposalTiming={createProposalTiming}
      />

      <DialogueProjectTime
        isOpen={showProjectTimeDialogue}
        setClose={() => setShowProjectTimeDialogue(false)}
        consultant={consultant}
        user={user}
        date={selectedDate}
        showProjectTimeDialogue={showProjectTimeDialogue}
        createProjectTiming={createProjectTiming}
        findActiveProjectsbyConsultant={findActiveProjectsbyConsultant}
        getActiveProjects={getActiveProjects}

      />
    </div>
  );
});
