import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { Typography, Grid, TextField, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Navigation
import NavCrumbs from "./projects-single-projects-nav-crumbs";

// Components
import Details from "./details";
import Tasks from "./tasks";
import Personnel from "./personnel";
import Timings from "./timings";
import Payments from "./payments";
import Actual from "./actual";

import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";
import { AllConsultantsContext } from "repository/stores/dashboard/consultants/consultants-all-provider";
import { AllClientsContext } from "repository/stores/dashboard/clients/clients-all-provider";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const projectId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const {
    getProject,
    createNewProject,
    editProject,
    createProjectPayment,
    deleteProjectPayment,
    createProjectTiming,
    findProjectPaymentByProject,
  } = useContext(AllProjectsContext);
  const {
    consultants,
    findConsultantByEmail,
    getConsultants,
    getConsultant,
    editConsultant,
  } = useContext(AllConsultantsContext);

  const { findClientsByQuery } = useContext(AllClientsContext);

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewProject, setIsNewProject] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (user) {
      if (projectId != 0) {
        try {
          getProject(projectId, user.idToken).then((result) => {
            setProject(result);
            setIsNewProject(false);
            setIsLoading(false);
          });
        } catch (e) {
          alert("Can't get this Project.");
        }
      } else {
        setIsNewProject(true);
        setIsLoading(false);
      }
    }
  }, [user]);

  return (
    <NavCrumbs project={project}>
      <Grid container spacing={6}>
        <Grid item xs={7}>
          <Details
            user={user}
            loading={isLoading}
            isNewProject={isNewProject}
            project={project}
            setProject={setProject}
            createNewProject={createNewProject}
            editProject={editProject}
            findClientsByQuery={findClientsByQuery}
          />

          <div style={{ marginTop: 40 }}></div>

          <Timings
            user={user}
            loading={isLoading}
            isNewProject={isNewProject}
            project={project}
            setProject={setProject}
          />

          <div style={{ marginTop: 40 }}></div>

          {user.roles.indexOf("admin") >= 0 && (
            <Payments
              user={user}
              loading={isLoading}
              isNewProject={isNewProject}
              project={project}
              setProject={setProject}
              createProjectPayment={createProjectPayment}
              deleteProjectPayment={deleteProjectPayment}
              findProjectPaymentByProject={findProjectPaymentByProject}
            />
          )}

          <div style={{ marginTop: 40 }}></div>

          <Actual
            user={user}
            loading={isLoading}
            isNewProject={isNewProject}
            project={project}
            setProject={setProject}
            consultants={consultants}
            findConsultantByEmail={findConsultantByEmail}
            createProjectTiming={createProjectTiming}
          />
        </Grid>
        <Grid item xs={5}>
          <Personnel
            user={user}
            loading={isLoading}
            isNewProject={isNewProject}
            project={project}
            setProject={setProject}
            consultants={consultants}
            getConsultants={getConsultants}
            getConsultant={getConsultant}
            editConsultant={editConsultant}
          />

          <div style={{ marginTop: 40 }}></div>

          <Tasks
            user={user}
            loading={isLoading}
            isNewProject={isNewProject}
            project={project}
            setProject={setProject}
          />
        </Grid>
      </Grid>
    </NavCrumbs>
  );
});
