import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Grid,
  FormControl,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";

import { toast } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
// Actions
import { getSearchClient } from "utils/algolia";
const searchClient = getSearchClient();
const studentsIndex = searchClient.initIndex("Students");

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();
  const { setClose, isOpen, user, createNewReferral, findReferralByStudentId } =
    props;

  const [email, setEmail] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [paymentDate, setPaymentDate] = React.useState(null);
  const [paymentValue, setPaymentValue] = React.useState(null);
  const [openPaymentDate, setOpenPaymentDate] = React.useState(false);

  const [students, setStudents] = React.useState([]);
  const [showHits, setShowHits] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const handleCreateReferral = () => {
    if (user && user != null) {
      if (
        selectedStudent == null ||
        email == null ||
        firstName == null ||
        lastName == null ||
        paymentDate == null ||
        paymentValue == null
      ) {
        toast.error(`Oops! Something went wrong`);
        let errorMessage = "";
        if (selectedStudent == null) {
          errorMessage = "Student is required.";
        }
        if (paymentValue == null) {
          errorMessage = "Payment Value is required.";
        }
        if (paymentDate == null) {
          errorMessage = "Payment Date is required.";
        }
        if (firstName == null) {
          errorMessage = "Referrer Name is required.";
        }
        toast.error(errorMessage);
      } else {
        findReferralByStudentId(selectedStudent.objectID).then((res) => {
          if (res.data.length > 0) {
            toast.error("This student already has a referral");
          } else {
            let payload = {
              email: email,
              lastName: lastName,
              firstName: firstName,
              status: "pending",
              paymentDate: moment(paymentDate).valueOf(),
              paymentValue: paymentValue,
              studentName:
                selectedStudent.firstName + " " + selectedStudent.lastName,
              studentId: selectedStudent.objectID,
            };
            setSaveLoading(true);
            toast.dark(`Creating referral ${payload.paymentValue}`);

            createNewReferral(payload, user.idToken)
              .then((result) => {
                setTimeout(() => {
                  window.location.href = "referrals/" + result._id;
                }, 700);
              })
              .catch((error) => {
                toast.error(
                  "Oops! Something went wrong. Check that you have the correct information"
                );
                toast.dark(error.message);
                setSaveLoading(false);
              });
          }
        });
      }
    }
  };

  const handleSelectedStudent = (item) => {
    setSelectedStudent(item);
    setShowHits(false);
  };

  const onSearch = (query) => {
    setShowHits(true);
    setQuery(query);
    studentsIndex.search(query, { hitsPerPage: 5 }).then(({ hits }) => {
      setStudents(hits);
    });
  };

  return (
    <Popup
      title="Open a new Referral"
      subtitle="Please fill in the details below to add a new Referral."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateReferral()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: John"}
                  disabled={saveLoading}
                  required
                  label="First Name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: Doe"}
                  disabled={saveLoading}
                  required
                  label="Last Name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>

          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: john@doe.com"}
              disabled={saveLoading}
              required
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>

          <Grid container spacing={6}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  placeholder={"Ex: 123"}
                  disabled={saveLoading}
                  required
                  label="Payment Value"
                  value={paymentValue}
                  onChange={(event) => setPaymentValue(event.target.value)}
                  margin="normal"
                ></TextField>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    required
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Payment Date"
                    value={paymentDate}
                    onChange={(date) => {
                      setPaymentDate(date);
                      setOpenPaymentDate(false);
                    }}
                    onClick={() => setOpenPaymentDate(true)}
                    onOpen={() => setOpenPaymentDate(true)}
                    onClose={() => setOpenPaymentDate(false)}
                    open={openPaymentDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>

          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: John Doe"}
              disabled={saveLoading}
              required
              label="Search for students"
              value={query}
              onChange={(event) => onSearch(event.target.value)}
              margin="normal"
              className={classes.searchBar}
            ></TextField>
          </FormControl>
          <FormControl className={classes.formControl}>
            {students && showHits && (
              <>
                {students.map((item, index) => {
                  return (
                    <MenuItem
                      value={`${item.firstName} ${item.lastName}`}
                      onClick={() => {
                        handleSelectedStudent(item);
                      }}
                    >{`${item.firstName} ${item.lastName} - ${item.email}`}</MenuItem>
                  );
                })}
              </>
            )}
          </FormControl>

          {selectedStudent && (
            <Typography variant={"h6"}>
              Selected Student:{" "}
              {`${selectedStudent.firstName} ${selectedStudent.lastName}`}
            </Typography>
          )}
        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
