import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import PaymentGantt from "./payment-gantt-view";

import {AuthenticationContext} from "repository/stores/global/authentication-provider"
import {AllProjectsContext} from "repository/stores/dashboard/projects/projects-all-provider"


export default withRouter(function (props) {
  const { user } = useContext(AuthenticationContext);
  const { getProjects, findProjectPayment } = useContext(AllProjectsContext);

  const [projects, setProjects] = useState(null);
  const [payments, setPayments] = useState(null);

  const [isLoading, setIsLoading] = useState(false);


  // Data Fetching
  useEffect(() => {
    if (user && user != null) {
      findProjectPayment(user.idToken).then((result) => {
        setPayments(result.data);
      });

      getProjects(user.idToken).then((result) => {
        setProjects(result.data);
        setIsLoading(true);
      });
    }
  }, [user]);

  return (
    <div>
      {isLoading && (
        <PaymentGantt
          user={user}
          loading={isLoading}
          projects={projects}
          payments={payments}
        />
      )}
    </div>
  );
});
