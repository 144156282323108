import React from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

// Routes
import Proposals from './routes/proposals';
import Sessions from './routes/sessions';

export default function () {
    return(
        <div>
            <Switch>
                <Route exact path='/training/proposals' component={() => <Proposals />} />
                <Route exact path='/training/sessions' component={() => <Sessions />} />
                <Route exact path='/training' component={() => <Redirect to={`/training/proposals`} />} />
            </Switch>
        </div>
    )
}