import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let crumbs = [
        {name: 'Users', path: 'users'},
        {name: 'All Users', path: 'users/all'}
  ];

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
