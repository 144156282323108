import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleProgrammeCostView from "./single-programme-cost-view";

export default withRouter(function (props) {
  let {
    loading,
    user,
    months,
    programme,
    setProgramme,
    isNewProgramme,
    programmeTotalCosts,
    setProgrammeTotalCosts
  } = props;



  return (
    <div>
      <SingleProgrammeCostView
        loading={loading}
        months={months}
        programme={programme} 
        setProgramme={setProgramme}
        isNewProgramme={isNewProgramme}
        panelDescription={"Manage Programme Costs"}
        programmeTotalCosts={programmeTotalCosts}
        setProgrammeTotalCosts={setProgrammeTotalCosts}
        user={user}
      />

    </div>
  );
});
