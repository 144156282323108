import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
} from "@material-ui/core";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { user, holidays, loading, sectionDescription } = props;

  return (
    <Panel
      title="Public Holidays"
      loading={loading}
      sectionDescription={sectionDescription}
    >
      <List>
        {holidays.map((item, index) => {
          let days = moment(item.date).diff(moment(), "days");
          let month = moment(item.date).diff(moment(), "months");

          let diff = days > 30 ? `in ${month} months`: `in ${days} days`

          return (
            <ListItem key={`holiday-${index}`}>
              <ListItemText
                id={`holiday-text-${index}`}
                primary={
                  <React.Fragment>
                    <Typography style={{ color: "#564181", fontWeight: 700 }}>
                      {moment(item.date).format("ddd DD MMM YYYY")}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {`${item.title} - (${diff}) `}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Panel>
  );
}
