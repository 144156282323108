import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SingleStudentDetailsView from "./single-student-details-view";
import DialogueAssignCanvasUrl from "routes/dashboard/dialogues/students/assign-canvas-url";
import DialogueAssignSharepointUrl from "routes/dashboard/dialogues/students/assign-sharepoint-url";
import DialogueChangeStatus from "routes/dashboard/dialogues/students/change-status";

export default withRouter(function (props) {
  let { student, setStudent, user, getStudent, editStudent, setShowEditStudentDialogue, findReferralByStudentId } = props;

  const [showAssignSharepointUrlDialogue, setShowAssignSharepointUrlDialogue] =
    useState(false);
  const [showAssignCanvasUrlDialogue, setShowAssignCanvasUrlDialogue] =
    useState(false);

  const [showChangeStatusDialogue, setShowChangeStatusDialogue] =
    useState(false);
    

    const [referral, setReferral] = useState(null);

    useEffect(()=>{
      if(student){
        findReferralByStudentId(student._id).then((res)=>{
          if(res.data.length > 0){
            setReferral(res.data[0])
          }
        })
      }
    },[student])
  return (
    <div>
      <SingleStudentDetailsView
        loading={!student}
        sectionDescription={"Detailed information about the student"}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        referral={referral}
        student={student}
        toggleAssignSharepointUrlDialogue={() =>
          setShowAssignSharepointUrlDialogue(true)
        }
        toggleAssignCanvasUrlDialogue={() =>
          setShowAssignCanvasUrlDialogue(true)
        }
        toggleChangeStatusDialogue={() => setShowChangeStatusDialogue(true)}
        setShowEditStudentDialogue={setShowEditStudentDialogue}
      />

      <DialogueAssignCanvasUrl
        isOpen={showAssignCanvasUrlDialogue}
        setClose={() => setShowAssignCanvasUrlDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
      />

      <DialogueAssignSharepointUrl
        isOpen={showAssignSharepointUrlDialogue}
        setClose={() => setShowAssignSharepointUrlDialogue(false)}
        setStudent={setStudent}
        getStudent={getStudent}
        editStudent={editStudent}
        user={user}
        student={student}
      />

      <DialogueChangeStatus
        student={student}
        setStudent={setStudent}
        isOpen={showChangeStatusDialogue}
        setClose={() => setShowChangeStatusDialogue(false)}
        user={user}
        editStudent={editStudent}
        getStudent={getStudent}
      />

    </div>
  );
});
