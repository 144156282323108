import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

//Children
import SingleCohortModulesView from "./single-cohort-modules-view";

//Dialogues
import DialogueEditModules from "routes/dashboard/dialogues/cohorts/edit-modules";
//Actions

export default withRouter(function (props) {
  let { cohort, setCohort, user, editCohort} = props;

  const [modules, setModules] = useState(null);

  const [showEditModulesDialogue, setShowEditModulesDialogue] = useState(false);

  //Data Fetching
  useEffect(() => {
    if (modules == null) {
      if (cohort != null) {
        setModules(cohort.modules);
      }
    }
  }, [modules, cohort]);

  return (
    <div>
      <SingleCohortModulesView
        loading={!modules}
        sectionDescription={"All associated modules."}
        modules={modules || []}
        toggleEditModulesForm={() => setShowEditModulesDialogue(true)}
        setCohort={setCohort}
        user={user}
      />
      <DialogueEditModules
        isOpen={showEditModulesDialogue}
        setClose={() => {
          setShowEditModulesDialogue(false);
        }}
        cohort={cohort}
        setCohort={setCohort}
        user={user}
        editCohort={editCohort}
      />
    </div>
  );
});
