import React from 'react';

// Navigation
import NavCrumbs from 'routes/dashboard/nav-crumbs';

export default function (props) {

  let { course } = props;

  let crumbs = [
        {name: 'Content', path: 'content'},
        {name: 'Courses', path: 'content/courses'},
        {name: '...', path: '#'},
  ];

  if (course != null) {
    crumbs[2] =  { name: `${course.title}`, path: `content/courses/${course._id}` }
  };

  return (
   <div>
       <NavCrumbs crumbs={crumbs} />
       {props.children}
   </div>
  )
}
