import { async } from "@firebase/util";
import React, { useContext, useEffect, useState } from "react";
// import ls from 'local-storage'
import { getAuth } from "firebase/auth";


const ContextProps = {
    idToken: null,
    getIdToken: {},
    refreshToken: {}
};

export const IdTokenContext = React.createContext(ContextProps);
const auth = getAuth();

export const IdTokenProvider = ({ children }) => {

    const [idToken, setIdToken] = useState(null);


    useEffect(() => {

        refreshToken()
        setInterval(async () => {
            refreshToken()
        }, 45 * 60 * 1000)
        // return () => clearInterval(handle)
    }, [])



    const refreshToken = async () => {
        return new Promise(async (resolve, reject) => {
            // console.log("refetching token")

            const user = auth.currentUser;
            if (user) await user.getIdToken(true).then((token) => {
                setIdToken(token)
                resolve(token)
            })
        })
    }

    const getIdToken = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (idToken) {
                    resolve(idToken)
                } else {
                    await refreshToken().then(token => {
                        resolve(token)
                    })
                }
            } catch (e) {
                reject(e)
            }

            // const dbRequest = window.indexedDB.open("firebaseLocalStorageDb");

            // dbRequest.onsuccess = () => {
            //     const db = dbRequest.result;
            //     const stores = ['firebaseLocalStorage'];

            //     const tx = db.transaction(stores);

            //     let req = tx.objectStore(stores[0]).getAll()

            //     req.onsuccess = () => {
            //         let result = req.result;
            //         let idToken = result[0].value.stsTokenManager.accessToken
            //         setIdToken(idToken)
            //         console.log(idToken)
            //         setIdToken(idToken)

            //         resolve(idToken)
            //     };
            // }
        })
    }


    return (
        <IdTokenContext.Provider
            value={{
                idToken,
                getIdToken,
                refreshToken
            }}
        >
            {children}
        </IdTokenContext.Provider>
    );
};