import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  Paper,
  Link,
  Switch,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { getSignedUrlFromAbsoluteUrl } from "utils/helpers/signedurls";
import {toast} from "react-toastify"
import countries from "./countryList";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import { styled } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let {
    candidate,
    candidateId,
    setCandidate,
    user,
    loading,
    toggleEditCandidateForm,
    editCandidate,
  } = props;
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [country, setCountry] = useState(null);



  useEffect(() => {
    if (candidate) {
      setCountry(countries.indexOf(candidate.nationality))
      setTitle(`${candidate.firstName} ${candidate.lastName}`);
    }
  }, [candidate]);

  const handleIsActive = (event) => {
    let payload = {
      isActive: event.target.checked,
    };

    editCandidate(candidateId, payload, user.idToken)
      .then((result) => {
        console.log(candidateId);
        setCandidate(result);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#95CC97",
      "&:hover": {
        backgroundColor: "#95CC9780",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#95CC97",
    },
  }));


  const handleNationality = (value) => {

      let country = countries[value]
      setCountry(value)

      editCandidate(candidateId, { nationality: country }, user.idToken)
      .then((result) => {
        toast.success(`Nationality updated: ${country}`);
      })
      .catch((error) => {
        toast.error(
          "Oops! Something went wrong. Check that you have the correct information"
        );
        toast.dark(error.message);
        console.log(error)
      });
  

  }

  return (
    <Section
      className={classes.root}
      title={title}
      loading={!candidate}
      actionName="Edit Candidate"
      handleAction={toggleEditCandidateForm}
      sectionDescription={"Detailed information about the candidate."}
    >
      {candidate != null && (
        <div>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Active</Typography>
            <GreenSwitch
              defaultChecked
              className={classes.switch}
              color="#95CC97"
              onClick={(event) => {
                handleIsActive(event);
              }}
              checked={candidate.isActive}
            />
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Name</Typography>
            <Typography className={classes.fontRegular}>
              {candidate.firstName} {candidate.lastName}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Email</Typography>
            <Typography className={classes.fontRegular}>
              {candidate.email}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Contact Number</Typography>
            <Typography className={classes.fontRegular}>
              {candidate.contactNumber}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Industries</Typography>
            <Typography className={classes.fontRegular}>
              {candidate.industry.join(", ")}
            </Typography>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Resume</Typography>
            <Link
              onClick={() => {
                getSignedUrlFromAbsoluteUrl(
                  candidate.resumeUrl,
                  user.idToken
                ).then((signedUrl) => window.open(signedUrl, "_blank"));
              }}
            >
              <Typography className={classes.fontRegular}>
                {candidate.resumeUrl}
              </Typography>
            </Link>
          </Paper>
          <Paper className={classes.miniSection}>
            <Typography className={classes.fontBold}>Nationality</Typography>
            <FormControl variant="standard" className={classes.fontRegular} style={{width:250}}>
              <Select
                labelId="nationality-label"
                id="Nationality"
                value={country}
                onChange={(event)=>{handleNationality(event.target.value)}}
                label="Nationality"
              >
                {countries.map((item, index) => {
                  return <MenuItem value={index}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Paper>
        </div>
      )}
    </Section>
  );
});
