import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "routes/dashboard/dashboard-styles";
import { toast } from "react-toastify";
import { FormControl } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getSearchClient } from "utils/algolia";
import AsyncSelect from "react-select/async";

//Actions

const useStyles = makeStyles(styles);
const searchClient = getSearchClient();
const personnelIndex = searchClient.initIndex("Personnel");

function AssignLecturerDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, cohort, user, assignLecturer} = props;

  const [personnelOptions, setPersonnelOptions] = React.useState([]);

  const [selectedPersonnelId, setSelectedPersonnelId] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [expiryDate, setExpiryDate] = React.useState(null);
  const [openExpiryDate, setOpenExpiryDate] = React.useState(false);
  const [openStartDate, setOpenStartDate] = React.useState(false);

  const [selectedPersonnelIdError, setSelectedPersonnelIdError] =
    React.useState(null);
  const [expiryDateError, setExpiryDateError] = React.useState(null);
  const [startDateError, setStartDateError] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const handleSaveAssignment = () => {
    if (user && user != null) {
      if (
        selectedPersonnelId == null ||
        expiryDate == null ||
        startDate == null
      ) {
        toast.error("You have missing information for assigning a lecturer");
        if (selectedPersonnelId == null) {
          setSelectedPersonnelIdError("A person is required to be chosen");
        }
        if (expiryDate == null) {
          setExpiryDateError("An expiry date to this role is required");
        }
        if (startDate == null) {
          setStartDateError("A start date to this role is required");
        }
      } else {
        setSaveLoading(true);

        toast.dark(`Assigning lecturer to ${cohort.title}`);
        assignLecturer(
          cohort._id,
          selectedPersonnelId,
          startDate,
          expiryDate,
          user.idToken
        )
          .then((result) => {
            setClose();
            setSaveLoading(false);
            toast.success(`Successfully assigned lecturer to ${cohort.title}`);
            setSelectedPersonnelIdError(null);
            setExpiryDateError(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            console.log(error)
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setSelectedPersonnelIdError(null);
    setExpiryDateError(null);
  }, [selectedPersonnelId, expiryDate]);

  const handleSelectPersonnel = (personnel) => {
    setSelectedPersonnelId(personnel.value);
  };

  const handlePersonnelSearchChange = (searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newPersonnelOptions = [];
        personnelIndex.search(searchQuery).then(({ hits }) => {
          for (let hit of hits) {
            newPersonnelOptions.push({
              label: `${hit.firstName} ${hit.lastName}`,
              value: hit.objectID,
            });
          }

          setPersonnelOptions(newPersonnelOptions);
          resolve(newPersonnelOptions);
        });
      } catch (e) {
        reject([]);
      }
    });
  };

  return (
    <Popup
      title="Assign a lecturer"
      subtitle="Select a lecturer and set the expiry date for the contract."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleSaveAssignment()}
      actionButtonTitle="Assign"
      disabled={saveLoading}
      content={
        <div style={{height: "30vh"}}>
          {
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handlePersonnelSearchChange}
                onChange={handleSelectPersonnel}
              />
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  disabled={saveLoading}
                  required
                  format="dd/MM/yyyy"
                  margin="normal"
                  openTo="year"
                  views={["year", "month", "date"]}
                  id="expiry-date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  helperText={startDateError}
                  error={startDateError != null}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  onClick={() => setOpenStartDate(true)}
                  onError={(error) => setStartDateError(error.message)}
                  onOpen={() => setOpenStartDate(true)}
                  onClose={() => setOpenStartDate(false)}
                  open={openStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  disabled={saveLoading}
                  required
                  format="dd/MM/yyyy"
                  margin="normal"
                  openTo="year"
                  views={["year", "month", "date"]}
                  id="expiry-date-picker-inline"
                  label="Expiry Date"
                  value={expiryDate}
                  helperText={expiryDateError}
                  error={expiryDateError != null}
                  onChange={(date) => {
                    setExpiryDate(date);
                  }}
                  onClick={() => setOpenExpiryDate(true)}
                  onError={(error) => setExpiryDateError(error.message)}
                  onOpen={() => setOpenExpiryDate(true)}
                  onClose={() => setOpenExpiryDate(false)}
                  open={openExpiryDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          }
        </div>
      }
    />
  );
}

export default withSnackbar(AssignLecturerDialogue);
