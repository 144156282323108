import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";
import { Typography, Grid, TextField, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";

// Navigation
import NavCrumbs from "./costings-single-costings-nav-crumbs";

import { AllProjectsContext } from "repository/stores/dashboard/projects/projects-all-provider";

// Components
import Totals from "./totals";
import Details from "./details";
import StudentCosts from "./student-costs";
import ModuleCosts from "./module-costs";
import ProgrammeCosts from "./programme-costs";

import { AllProgrammesContext } from "repository/stores/dashboard/programme-costings/programme-costings-all-provider";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  const programmeId = props.match.params.id;

  const { user } = useContext(AuthenticationContext);
  const { createNewProgramme, getProgramme, editProgramme } =
    useContext(AllProgrammesContext);

  const [programme, setProgramme] = useState(null);
  const [isNewProgramme, setIsNewProgramme] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const [months, setMonths] = useState(null);
  const [years, setYears] = useState(0);

  const [studentTotalCost, setStudentTotalCost] = useState(null);
  const [moduleTotalCost, setModuleTotalCost] = useState(null);
  const [programmeTotalCosts, setProgrammeTotalCosts] = useState(null);

  useEffect(() => {
    if (user) {
      if (programmeId != 0) {
        try {
          getProgramme(programmeId, user.idToken).then((result) => {
            setIsNewProgramme(false);

            setProgramme(result);

            setStudentTotalCost({
              ...studentTotalCost,
              studentTotalCost: result.studentTotalCosts,
            });
            setModuleTotalCost({
              ...moduleTotalCost,
              moduleTotalCost: result.moduleTotalCosts,
            });
            setProgrammeTotalCosts({
              ...programmeTotalCosts,
              programmeTotalCosts: result.programmeTotalCosts,
            });
            setIsLoading(false);
          });
        } catch (e) {
          console.log(e.message);
          alert("Can't get this Programme.");
        }
      } else {
        // setProgramme(true);
        setIsNewProgramme(true);

        setIsLoading(false);
      }
    }
  }, [user]);


  return (
    // <NavCrumbs programme={programme}>
    <Panel loading={isLoading}>
      <Details
        months={months}
        setMonths={setMonths}
        years={years}
        setYears={setYears}
        isNewProgramme={isNewProgramme}
        programme={programme}
        setProgramme={setProgramme}
        editProgramme={editProgramme}
        createNewProgramme={createNewProgramme}
        moduleTotalCost={moduleTotalCost}
        studentTotalCost={studentTotalCost}
        programmeTotalCosts={programmeTotalCosts}
      />

      <div></div>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item xs={4}>
          <StudentCosts
            programme={programme}
            setProgramme={setProgramme}
            isNewProgramme={isNewProgramme}
            years={years}
            studentTotalCost={studentTotalCost}
            setStudentTotalCost={setStudentTotalCost}
          />
        </Grid>
        <Grid item xs={4}>
          <ModuleCosts
            programme={programme}
            setProgramme={setProgramme}
            isNewProgramme={isNewProgramme}
            moduleTotalCost={moduleTotalCost}
            setModuleTotalCost={setModuleTotalCost}
          />
          <Totals
            programme={programme}
            setProgramme={setProgramme}
            isNewProgramme={isNewProgramme}
            studentTotalCost={studentTotalCost}
            moduleTotalCost={moduleTotalCost}
            programmeTotalCosts={programmeTotalCosts}
          />
        </Grid>
        <Grid item xs={4} style={{ alignItems: "space-between" }}>
          <ProgrammeCosts
            months={months}
            programme={programme}
            setProgramme={setProgramme}
            isNewProgramme={isNewProgramme}
            programmeTotalCosts={programmeTotalCosts}
            setProgrammeTotalCosts={setProgrammeTotalCosts}
          />
        </Grid>
      </Grid>
    </Panel>
    // </NavCrumbs>
  );
});
