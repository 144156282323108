import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ListItem, List } from "@material-ui/core";
import styles from "routes/dashboard/dashboard-styles";
import Panel from "components/panel";
import DialogueEditComments from "routes/dashboard/dialogues/personnel/edit-comments";

const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();
  const { person, setPerson, user } = props;
  const [comments, setComments] = useState([]);

  const [showEditCommentDialogue, setShowEditCommentDialogue] =
    useState(false);

  useEffect(() => {
    setComments(person.comments||[]);
  }, [person]);

  return (
    <div>
      <Panel
        title="Comments"
        loading={false}
        sectionDescription="Comments on this person."
        actionName="Edit"
        handleAction={() => setShowEditCommentDialogue(true)}
      >
        {comments.length > 0 && (
          <List>
            {comments.map((item) => (
              <ListItem className={classes.labelText}>{item}</ListItem>
            ))}
          </List>
        )}

      
        {comments != null && (
          <div>
            {comments.length == 0 && (
              <Typography className={classes.loading}>
                This person has no comments listed to them
              </Typography>
            )}
          </div>
        )}

        <DialogueEditComments
          isOpen={showEditCommentDialogue}
          setClose={() => setShowEditCommentDialogue(false)}
          personId={person._id}
          person={person}
          setComments={setComments}
          setPerson={setPerson}
          user={user}
        />
      </Panel>
    </div>
  );
}
