import React from "react";
import { DialogContentText } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Popup from "components/popup";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { deleteSupervisorMentor } from "repository/actions/data/dashboard/students";

export default withSnackbar(
  withRouter(function (props) {
    const {
      isOpen,
      setClose,
      student,
      supervisorIndex,
      supervisors,
      user,
      setStudent,
      getStudent,
      editStudent,
    } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);

    const handleDeleteSupervisor = () => {
        
      if (user && user != null) {
        setSaveLoading(true);

        setSaveLoading(true);
        toast.dark(`Removing Supervisor`);
        deleteSupervisorMentor(
          student._id,
          supervisors[supervisorIndex].person._id,
          supervisors[supervisorIndex].course._id,
          "supervisor",
          user.idToken
        )
          .then((result) => {
            getStudent(student._id, user.idToken).then(getStudentResult => {
              setStudent(getStudentResult);
              
            })
            setSaveLoading(false);
            toast.success(`Supervisor Removed`);
            setClose();
            // setStudent(result);
            
          })
          .catch((error) => {
            toast.error("Oops! Something went wrong.");
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    };

    return (
      <Popup
        title="Remove Supervisor"
        isOpen={isOpen}
        handleCancel={() => setClose()}
        handleAction={() => handleDeleteSupervisor()}
        actionButtonTitle="Yes, I'm sure."
        disabled={saveLoading}
        content={
          <div>
            <DialogContentText>
              Are you sure you want to remove this supervisor ?
            </DialogContentText>
          </div>
        }
      />
    );
  })
);
