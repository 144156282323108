import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import styles from "routes/dashboard/dialogues/dialogue-styles";
import {
  Link,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { toast } from "react-toastify";
import Popup from "components/popup";
// import { AllModulesContext } from "repository/stores/dashboard/modules/modules-all-provider";
// import { AllCoursesContext } from "repository/stores/dashboard/courses/courses-all-provider";
// import { createNewModule } from "repository/actions/data/dashboard/modules";
// import { findAllCourses } from "repository/actions/data/dashboard/courses";

const useStyles = makeStyles(styles);

function AddMenuDialogue(props) {
  const classes = useStyles();

  const { setClose, isOpen, user, createNewModule, getCourses, courses, setRefreshAlgolia } = props;

  // const {modules, setModules, createNewModule} = React.useContext(AllModulesContext);
  // const {courses, setCourses, getCourses} = React.useContext(AllCoursesContext);

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [mqfLevel, setMqfLevel] = React.useState(null);
  const [ects, setEcts] = React.useState(null);
  const [referenceNumber, setReferenceNumber] = React.useState(null);
  const [assignedCourse, setAssignedCourse] = React.useState(null);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const [titleError, setTitleError] = React.useState(null);
  const [descriptionError, setDescriptionError] = React.useState(null);
  const [mqfError, setMqfError] = React.useState(null);
  const [referenceError, setReferenceError] = React.useState(null);
  const [assignedCourseError, setAssignedCourseError] = React.useState(null);
  const [ectsError, setEctsError] = React.useState(null);
  const [isCompulsory, setIsCompulsory] = React.useState(true);


  useEffect(() => {
    if (user && user != null) {
      if (courses == null) {
        getCourses(user.idToken);
      }
    }
  }, []);

  const handleCreateModule = () => {
    if (user && user != null) {
      if (
        title == null ||
        description == null ||
        mqfLevel == null ||
        referenceNumber == null ||
        assignedCourse == null
      ) {
        toast.error(`You have missing information for creating a new module`);
        if (title === null) {
          setTitleError("Title is required.");
        }

        if (description == null) {
          setDescriptionError("Description is required.");
        }

        if (mqfLevel == null) {
          setMqfError("MQF Level is required.");
        }

        if (ects == null) {
          setEctsError("ECTS quantity is required.");
        }

        if (referenceNumber == null) {
          setReferenceError("Reference Number is required.");
        }

        if (assignedCourse == null) {
          setAssignedCourseError("Assigned Courses is required.");
        }
      } else {
        let data = {
          title: title,
          description: description,
          mqfLevel: mqfLevel,
          ects:ects,
          referenceNumber: referenceNumber,
          assignedCourseId: assignedCourse,
          isCompulsory: isCompulsory ? "TRUE" : "FALSE"
        };

        setSaveLoading(true);
        toast.dark(`Creating ${data.title}`);
        createNewModule(data, user.idToken)
          .then((result) => {
            setTimeout(() => { setRefreshAlgolia(true) }, 2000)
            setTimeout(() => { setRefreshAlgolia(false) }, 2000)
            setClose();
            setSaveLoading(false);
            toast.success(`Created Module : ${data.title}`);
            setTitle(null);
            setDescription(null);
            setMqfLevel(null);
            setReferenceNumber(null);
            setAssignedCourse(null);
          })
          .catch((error) => {
            toast.error(
              "Oops! Something went wrong. Check that you have the correct information"
            );
            toast.dark(error.message);
            setSaveLoading(false);
          });
      }
    }
  };


  const mqfLevels = ["4", "5", "6", "7", "8"]


  useEffect(() => {
    setTitleError(null);
    setDescriptionError(null);
    setMqfError(null);
    setEcts(null);
    setReferenceError(null);
    setAssignedCourseError(null);
  }, [title, description, mqfLevel, referenceNumber, assignedCourse, isOpen]);

  return (
    <Popup
      title="Add a new module"
      subtitle="Please fill in the details below to create a new module."
      isOpen={isOpen}
      fullWidth={true}
      maxWidth={"sm"}
      handleCancel={() => setClose()}
      handleAction={() => handleCreateModule()}
      actionButtonTitle="Submit"
      disabled={saveLoading}
      content={
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Module Title"}
              disabled={saveLoading}
              required
              helperText={titleError}
              error={titleError != null}
              label="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: Module Description"}
              disabled={saveLoading}
              required
              helperText={descriptionError}
              error={descriptionError != null}
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <InputLabel required disabled={saveLoading}>
              MQF Level
            </InputLabel>
            <Select
              elevation={0}
              helperText={mqfError}
              error={mqfError != null}
              input={<Input id="type-menu" />}
              onChange={(event) => setMqfLevel(event.target.value)}
              value={mqfLevel}
              required
              disabled={saveLoading}
            >
              {mqfLevels.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: 6"}
              disabled={saveLoading}
              required
              helperText={ectsError}
              error={ectsError != null}
              label="ECTS"
              value={ects}
              onChange={(event) => setEcts(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl className={classes.formControl}>
            <TextField
              placeholder={"Ex: M1263"}
              disabled={saveLoading}
              required
              helperText={referenceError}
              error={referenceError != null}
              label="Reference Number"
              value={referenceNumber}
              onChange={(event) => setReferenceNumber(event.target.value)}
              margin="normal"
            ></TextField>
          </FormControl>
          <div></div>
          <FormControl
            className={classes.inputType}
            error={assignedCourseError != null}
          >
            <InputLabel required disabled={saveLoading}>
              Assigned Course
            </InputLabel>
            {courses != null && (
              <Select
                elevation={0}
                onChange={(event) => setAssignedCourse(event.target.value)}
                value={assignedCourse}
                required
                error={assignedCourseError != null}
                disabled={saveLoading}
                input={<Input id="type-menu" />}
              >
                {courses.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            )}
            <FormHelperText>{assignedCourseError}</FormHelperText>
          </FormControl>
          <FormControl
            className={classes.inputType}
            error={assignedCourseError != null}
          >
            <FormControlLabel control={<Checkbox defaultChecked onChange={(event) => {
              setIsCompulsory(
                event.target.checked
              );
            }} />} label="Is Compulsory" />
          </FormControl>

        </div>
      }
    />
  );
}

export default withSnackbar(AddMenuDialogue);
