export const showSideNavigation = (state, setNavigationContext) => {

    setNavigationContext({
        type: 'OpenSideNavigation',
        payload: {
            isOpen: !state.isOpen
        }
    });  
}

export const showSimulatorNavigation = (state, setSimulatorNavigation) => {

    setSimulatorNavigation({
        type: 'OpenSimulatorNavigation',
        payload: {
            isOpen: !state.isOpen
        }
    });  
}