import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Section from "components/section";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
  Grid,
  TextField,
} from "@material-ui/core";

import { getSearchClient } from "utils/algolia";
import { AllCohortsContext } from "repository/stores/dashboard/cohorts/cohorts-all-provider";
//InstantSearch
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  connectPagination,
  connectRefinementList,
  RefinementList,
  Highlight,
  Stats,
} from "react-instantsearch-dom";
import Select from "react-select";

// Styles
import styles from "routes/dashboard/dashboard-styles";
import moment from "moment";
const useStyles = makeStyles(styles);

const searchClient = getSearchClient();

export default function (props) {
  const classes = useStyles();

  const { loading, onPressedItem, students, user, refreshAlgolia } = props;

  const { cohorts, setCohorts, getCohorts } =
    React.useContext(AllCohortsContext);
  const [cohortMap, setCohortMap] = React.useState({});
  const [cohortOptions, setCohortOptions] = React.useState([]);
  const [selectedCohorts, setSelectedCohorts] = React.useState([]);
  const [availableCohorts, setAvailableCohorts] = React.useState(null);

  
  const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const Pagination = ({
    currentRefinement,
    nbPages,
    refine,
    createURL,
    padding = 3,
  }) => (
    <Grid container spacing={3}>
      <Grid item xs={2}></Grid>

      <Grid item xs={8} className={classes.pagination}>
        <ul className={classes.paginationList}>
          {currentRefinement > 1 && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement - 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement - 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M9 5H1M5 9L1 5l4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
          {range(
            Math.max(1, currentRefinement - padding),
            Math.min(nbPages, currentRefinement + padding)
          ).map((page) => {
            // const page = index + 1;
            return (
              <li
                key={page}
                className={
                  currentRefinement === page
                    ? classes.paginationListItemActive
                    : classes.paginationListItem
                }
              >
                <Link
                  href={createURL(page)}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
          {nbPages > 1 && currentRefinement < nbPages && (
            <li className={classes.paginationListItem}>
              <Link
                href={createURL(currentRefinement + 1)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(currentRefinement + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.143"
                  >
                    <path d="M1 5h8M5 9l4-4-4-4" />
                  </g>
                </svg>
              </Link>
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
  const CustomPagination = connectPagination(Pagination);

  const SearchBox = ({ currentRefinement, refine }) => {
    return (
      <TextField
        type="search"
        variant="outlined"
        label="Search for a student"
        value={currentRefinement}
        fullWidth
        onChange={(event) => refine(event.currentTarget.value)}
        className={classes.searchBar}
      />
    );
  };

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits, currentRefinement, refine, createURL }) => {
    return (
      <TableBody>
        {hits.map((row, index) => {
          let date = row.extensions.sort(function (a, b) {
            return new Date(a.to) - new Date(b.to);
          });
          return (
            <TableRow hover key={row.objectID} className={classes.row}>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {cohortMap[row.cohortIds[0]]
                  ? cohortMap[row.cohortIds[0]].title
                  : "--"}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.firstName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.lastName}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {row.extensions ? row.extensions.length : 0}
              </TableCell>
              <TableCell
                onClick={(event) => onPressedItem(row.objectID)}
                className={classes.cellStandard}
                align="left"
              >
                {moment(date[0].to).format("DD MMM YYYY")}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const CustomHits = connectHits(Hits);

  //Begin Filtering
  React.useEffect(() => {
    if (user && user != null) {
      if (cohorts == null) {
        getCohorts();
      } else if (availableCohorts != null && cohorts) {
        let newCohortMap = {};
        let newCohortOptions = [];

        for (let l of cohorts) {
          newCohortMap[l._id] = l;
          if (availableCohorts.indexOf(l._id) >= 0) {
            let option = {
              value: l._id,
              label: l.title,
            };
            newCohortOptions.push(option);
          }
        }
        setCohortOptions(newCohortOptions);
        setCohortMap(newCohortMap);
      }
    }

    searchClient.clearCache();
  }, [availableCohorts, cohorts]);

  React.useEffect(() => {
    searchClient
      .initIndex("Students")
      .searchForFacetValues("cohortIds", "", {
        filters: `extensions.isActive:true`,
      })
      .then(({ facetHits }) => {
        let avail = facetHits.map(function (obj) {
          return obj.value;
        });
        setAvailableCohorts(avail);
      });
  }, []);

  const RefinementListCustom = ({
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
  }) => {
    return (
      <ul className={classes.hiddenRefinementList}>
        <li>
          <input
            type="search"
            onChange={(event) => searchForItems(event.currentTarget.value)}
          />
        </li>
        {items.map((item) => (
          <li key={item.label}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                item.label
              )}{" "}
              ({item.count})
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const CustomRefinementList = connectRefinementList(RefinementListCustom);

  //End Filtering

  const handleCohortSelectionChange = (selectedCohortOptions) => {
    let newSelectedCohorts = [];
    let newSelectedCohortIds = [];
    if (
      selectedCohortOptions &&
      selectedCohortOptions != null &&
      selectedCohortOptions.length > 0
    ) {
      for (let sco of selectedCohortOptions) {
        newSelectedCohorts.push(sco);
        newSelectedCohortIds.push(sco.value);
      }
    }
    setSelectedCohorts(newSelectedCohortIds);
  };

  return (
    <Section title="MANAGE EXTENSIONS" loading={loading}>
      {students.length > 0 && cohorts && (
        <InstantSearch
          indexName="Students"
          searchClient={searchClient}
          refresh={refreshAlgolia}
        >
          <CustomRefinementList attribute="extensions.type" operator="or" />
          <CustomRefinementList
            attribute="extensions.isActive"
            operator="or"
            defaultRefinement={"true"}
          />

          <RefinementList
            attribute="extensions.type"
            facetOrdering
            // defaultRefinement={["true"]}
          />
          <CustomRefinementList
            attribute="cohortIds"
            operator="or"
            defaultRefinement={selectedCohorts}
          />

          <Grid container spacing={3}>
            <Grid item xs={10}>
              <CustomSearchBox />
              <Typography
                variant="overline"
                display="block"
                className={classes.filterHeading}
              >
                Cohorts
              </Typography>
              <Select
                options={cohortOptions}
                isMulti
                isClearable
                placeholder="Select cohorts"
                onChange={handleCohortSelectionChange}
                className={classes.selectContainer}
              />
            </Grid>

            <Grid item xs={2}>
              <Stats
                translations={{
                  stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                    return (
                      <Typography className={classes.stat}>
                        {nbHits} students in {processingTimeMS} ms
                      </Typography>
                    );
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{marginTop:70}}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Cohort
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      Extensions
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Typography className={classes.labelText}>
                      First Due Date
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <CustomHits />
            </Table>
          </TableContainer>
          <CustomPagination />
        </InstantSearch>
      )}
      {students.length === 0 && (
        <Typography className={classes.emptyText} align="center">
          You don't have any students yet.
        </Typography>
      )}
    </Section>
  );
}
