import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import SingleStudentCostView from "./single-student-cost-view";

export default withRouter(function (props) {
  let { loading, user, years, programme, setProgramme, studentTotalCost, setStudentTotalCost,isNewProgramme } = props;

  return (
    <div>
      <SingleStudentCostView
        loading={loading}
        years={years}
        programme={programme}
        setProgramme={setProgramme}
        isNewProgramme={isNewProgramme}
        panelDescription={"Manage Student Costs"}
        user={user}
        studentTotalCost={studentTotalCost}
        setStudentTotalCost={setStudentTotalCost}
        
      />
    </div>
  );
});
