import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";
import {
  IconButton,
  Paper,
  Input,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";

// Styles
import styles from "routes/dashboard/dashboard-styles";

const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { proposal, user, editProposal, setProposal } = props;
  const classes = useStyles();

  const [price, setPrice] = React.useState({ title: "", value: 0 });
  const [total, setTotal] = React.useState(0);

  const [proposalPrices, setProposalPrices] = React.useState([]);

  useEffect(() => {
    if (proposal) {
      if (proposal.prices) {
        setProposalPrices(proposal.prices);
        if (proposal.prices.length > 0) {
          setTotal(
            proposal.prices
              .map((item) => item.value)
              .reduce((prev, next) => parseInt(prev) + parseInt(next))
          );
        }
      }
    }
  }, [proposal]);
  const handleAddPrice = async () => {
    let newPrices = [...proposalPrices];
    newPrices.push(price);
    let payload = {
      prices: newPrices,
    };
    edit(payload);
  };

  const handleRemovePrice = (index) => {
    let newPrices = [...proposalPrices];
    newPrices.splice(index, 1);
    let payload = {
      prices: newPrices,
    };
    edit(payload);
  };
  const edit = async (payload) => {
    await editProposal(proposal._id, payload)
      .then((result) => {
        setPrice({ title: "", value: 0 });
        setProposal(result);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  return (
    <Panel title={`Prices (€${total})`} loading={!proposal}>
      <div>
        <div style={{ display: "flex" }}>
          <Input
            label="Title"
            placeholder="Phase 1"
            type="text"
            value={price.title}
            style={{ marginBottom: 20, marginRight: 10 }}
            onChange={(event) => {
              setPrice({ ...price, title: event.target.value });
            }}
          />
          <Input
            label="Value (€)"
            placeholder="€ XX"
            type="number"
            value={price.value}
            style={{ marginBottom: 20 }}
            onChange={(event) => {
              setPrice({ ...price, value: parseInt(event.target.value) });
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  style={{
                    boxShadow: "0 2px 2px 0px rgba(0,0,0,0.1)",
                    marginTop: -20,
                  }}
                  disabled={price.title == "" || price.value == ""}
                  onClick={() => handleAddPrice()}
                >
                  <DoneIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        {proposal &&
          proposalPrices.map((item, index) => {
            return (
              <Paper
                key={`price-${index}`}
                className={classes.miniSection}
                style={{
                  paddingRight: 10,
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {item.title} - {item.value}
                <IconButton
                  onClick={() => {
                    handleRemovePrice(index);
                  }}
                >
                  <CloseIcon
                    style={{
                      fontSize: 15,
                    }}
                  />
                </IconButton>
              </Paper>
            );
          })}
      </div>
    </Panel>
  );
});
