import React from 'react';

export const PopupDescriptionsContext = React.createContext();

const initialState = {
    "information.menus.create": "You can add, change or remove all of your businesses food & drink menus below.",
    "information.opening-hours.edit": "Edit the regular customer-facing hours of operation for a typical week."


};

const reducer = (state, action) => {
    return state;
};

export const PopupDescriptionsProvider = ({ children }) => {
  const contextValue = React.useReducer(reducer, initialState);
  return (
    <PopupDescriptionsContext.Provider value={contextValue}>
      {children}
    </PopupDescriptionsContext.Provider>
  );
};