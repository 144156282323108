import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getSingleEnquiry } from "repository/actions/data/dashboard/enquiries";
import { AuthenticationContext } from "repository/stores/global/authentication-provider";

// Navigation
import NavCrumbs from "./enquiries-single-enquiry-nav-crumbs";

// Components
import Details from "./details";
import Documents from "./documents";
import Personal from "./personal";

export default withRouter(function (props) {
  const enquiryId = props.match.params.id;
  const { user } = useContext(AuthenticationContext);

  const [enquiry, setEnquiry] = useState(null);

  useEffect(() => {
    if (user && user != null) {
      getSingleEnquiry(enquiryId, user.idToken).then((result) => {
        setEnquiry(result);
      });
    }
  }, []);

  return (
    <NavCrumbs enquiry={enquiry}>
      <Details enquiry={enquiry} user={user} />
      <Documents enquiry={enquiry} user={user} />
      <Personal enquiry={enquiry} user={user} />
    </NavCrumbs>
  );
});
