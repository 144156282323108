import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Panel from "components/panel";

// import { ColumnChart   } from "react-chartkick";
// import 'chartkick/chart.js'

// import { Chart } from "react-google-charts";

// Styles
import styles from "routes/dashboard/dashboard-styles";
const useStyles = makeStyles(styles);

export default withRouter(function (props) {
  let { user, projects, setProjects } = props;
  const classes = useStyles();

  const [data, setData] = useState(null)
  
  useEffect(()=>{
    let rows = []
    rows.push(['Project', 'Estimated', 'Actual'])

    for(let p of projects){
      rows.push([p.title, p.estimatedHours, p.actualHours])

    }
    setData(rows)

  }, [projects])




  var options = {
    vAxis: {
      title: "Hours",
    },
    colors: ["#f2a600", "#00796b"],
    
  };

  return (
    <Panel title={"Project Hours"}>
      {/* {data && 
    //  <ColumnChart 
    //     // chartType="Bar"
    //     data={data}
    //     width="100%"
    //     height={300}
    //     options={options}
    //   />
      // }*/}
    </Panel>
  );
});
