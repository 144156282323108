import React from "react";
import { withRouter } from "react-router-dom";
import CustomTimesheetView from "./custom-timesheets-view";

export default withRouter(function (props) {
  let {  user, users } = props;

    

  return (
    <div>
      <CustomTimesheetView
        loading={!users}
        users={users}
        user={user}
      />
    </div>
  );
});
