
import React, { useState, useContext } from "react";
import apiClient from "utils/api";
import { IdTokenContext } from "repository/stores/global/token-provider"


const ContextProps = {
  leads: null,
  setLeads: {},
  getLeads: {},
  createNewLead: {},
  editLead: {},
  deleteLead: {},
};

export const AllLeadsContext = React.createContext(ContextProps);

export const AllLeadsProvider = ({ children }) => {
  const [leads, setLeads] = useState(null);
  const [loadingLeads, setLoadingLeads] = useState(false);

  const { getIdToken } = useContext(IdTokenContext)


  const getLeads = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingLeads(true);
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/leads").find({
            query: {
              firebaseToken: token,
            },
          });

          setLoadingLeads(false);
          setLeads(result.data);
          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };


  const createNewLead = async (lead, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/leads").create(lead, {
            query: {
              firebaseToken: token,
            },
          });

          if (leads == null) {
            setLeads(result);
          } else {
            let newData = leads
            newData.push(result)
            setLeads(newData);
          }

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };



  const editLead = async (data, leadId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient
            .service("v1/leads")
            .patch(leadId, data, {
              query: {
                firebaseToken: token,
              },
            });

          let newData = leads

          for (const [i, v] of newData.entries()) {
            if (v._id === leadId) {
              newData[i] = data;
            }
          }
          setLeads(newData);

          resolve();
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  const deleteLead = async (leadId, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        getIdToken().then(async (token) => {

          let result = await apiClient.service("v1/leads").remove(leadId, {
            query: {
              firebaseToken: token,
            },
          });


          let newData = leads

          for (const [i, v] of newData.entries()) {
            if (v._id === leadId) {
              newData.splice(i, 1);
            }
          }
          setLeads(newData);

          resolve(result);
        })
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <AllLeadsContext.Provider
      value={{
        leads,
        setLeads,
        getLeads,
        createNewLead,
        editLead,
        deleteLead,
        loadingLeads
      }}
    >
      {children}
    </AllLeadsContext.Provider>
  );
};
